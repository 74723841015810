import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import theme from './skeleton.scss';

const classNames = ClassNames(theme);

type SkeletonProps = {
  dataTestId?: string;
  block?: boolean;
  circle?: boolean;
  className?: string;
  height?: number | string;
  width?: number | string;
  ariaLabel?: string;
};

export const Skeleton = ({
  ariaLabel,
  block,
  circle,
  className = '',
  dataTestId,
  height,
  width,
}: SkeletonProps): JSX.Element => {
  const style = { height, width };
  return (
    <span
      data-testid={dataTestId}
      className={classNames({
        'skeleton': true,
        [className]: Boolean(className),
        'skeleton--block': block,
        'skeleton--circle': circle,
      })}
      aria-label={ariaLabel}
      style={style}
    />
  );
};
