import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import { errorMapping } from '../../errorMapping';
import { SetsActionTypes } from '../../actionTypes';
import type { SetCreatedActionSuccess, SetCreatedActionError, CreateSetParams } from './types';

const setCreated = ({ data }): SetCreatedActionSuccess => {
  return actionCreator.createAction(SetsActionTypes.CREATED, data);
};

const setNotCreated = ({ errorData }): SetCreatedActionError =>
  actionCreator.createErrorAction(SetsActionTypes.CREATED, errorData, errorMapping);

const createSet = (params: CreateSetParams) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.SETS.CREATE,
    errorBinding: setNotCreated,
    loadingAction: SetsActionTypes.CREATING,
    method: 'post',
    parameters: params,
    successBinding: setCreated,
  });

export const createSetActions = {
  createSet,
};
