import { RouteConfigComponentProps } from 'react-router-config';
import loadable from 'components/dynamic-import';

const ConnectedPublicUserPage = loadable(() => import('./ConnectedPublicUserPage'));

export const PublicUserPagePage = (props: RouteConfigComponentProps): JSX.Element => {
  const { route, history } = props;

  return <ConnectedPublicUserPage route={route} history={history} />;
};
