import { ComponentType } from 'react';
import { Redirect } from 'react-router-dom';
import { urls } from 'services/routing';

export interface PermissionsFallbackProps {
  fallbackComponent?: ComponentType;
  redirectTo?: string;
  redirectToBasePage?: boolean;
}

export const PermissionsFallback = ({
  fallbackComponent,
  redirectTo,
  redirectToBasePage,
}: PermissionsFallbackProps): null | JSX.Element => {
  if (redirectTo || redirectToBasePage) {
    return <Redirect to={redirectTo || urls().USER_PAGE} />;
  }

  if (!fallbackComponent) {
    return null;
  }

  const Component = fallbackComponent;
  return <Component />;
};
