export enum OnboardingActionTypes {
  CREATED = 'onboarding/CREATED',
  CREATING = 'onboarding/CREATING',
  RECEIVED = 'onboarding/RECEIVED',
  FETCHING = 'onboarding/FETCHING',
  UPDATED = 'onboarding/UPDATED',
  UPDATING = 'onboarding/UPDATING',
  FINALIZED = 'onboarding/FINALIZED',
  FINALIZING = 'onboarding/FINALIZING',
  CHANGED = 'onboarding/CHANGED',
  CLEAN_ASYNC_STATUS = 'onboarding/CLEAN_ASYNC_STATUS',
}
