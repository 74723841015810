import type { ReactNode } from 'react';
import ClassNames from '@streamloots/classnames';
import theme from './vertical-list.scss';

const classNames = ClassNames(theme);

interface HowToPanelProps {
  children: ReactNode;
  isOrderedList?: boolean;
  className?: string;
}

export const VerticalList = ({ isOrderedList, className = '', children }: HowToPanelProps): JSX.Element => {
  const Component = isOrderedList ? 'ol' : 'ul';
  return (
    <Component className={classNames({ 'vl': true, 'vl--ordered': isOrderedList, [className]: Boolean(className) })}>
      {children}
    </Component>
  );
};
