import _ from 'lodash';
import stateCreator from 'helpers/stateCreator';
import type { ModeratorsActions, ModeratorsState } from './types';
import { PageActionTypes } from '../page';
import { actionTypes } from './actionTypes';
import { AddModeratorPayload } from './services/add';
import { UpdateModeratorPayload } from './services/update';
import { MODERATORS_GET_SUCCESS } from './services/list';
import { DeleteModeratorPayload } from './services/delete';

const initialState: ModeratorsState = {
  moderators: undefined,
};

export const reducer = (state: ModeratorsState = initialState, action: ModeratorsActions): ModeratorsState => {
  const { type, error, payload } = action;
  switch (type) {
    case actionTypes.RECEIVED: {
      if (error) {
        return stateCreator.getAsyncErrorState(action, state, initialState);
      }
      const { data } = payload as MODERATORS_GET_SUCCESS;
      return stateCreator.getInitialAsyncState(state, {
        moderators: [...data],
      });
    }
    case actionTypes.ADDED: {
      if (error) {
        return state;
      }
      const { moderator } = payload as AddModeratorPayload;
      const moderators = state.moderators || [];
      return {
        ...state,
        moderators: [...moderators, { ...moderator }],
      };
    }
    case actionTypes.UPDATED: {
      if (error) {
        return state;
      }
      const { moderator } = payload as UpdateModeratorPayload;
      const { moderators } = state;
      const moderatorsList = !moderators ? [] : [...moderators];
      const index = _.findIndex(moderatorsList, moderatorItem => moderatorItem.userId === moderator.userId);
      moderatorsList.splice(index, 1, moderator);
      return {
        ...state,
        moderators: moderatorsList,
      };
    }
    case actionTypes.FETCHING:
      return stateCreator.getAsyncLoadingState(state);
    case actionTypes.DELETED: {
      if (error) {
        return state;
      }
      const { userId } = payload as DeleteModeratorPayload;
      const { moderators } = state;
      const moderatorsList = !moderators ? [] : [...moderators];
      const index = _.findIndex(moderatorsList, moderator => moderator.userId === userId);
      moderatorsList.splice(index, 1);
      return {
        ...state,
        moderators: moderatorsList,
      };
    }
    case PageActionTypes.CHANGED:
      return initialState;
    default:
      return state;
  }
};
