import { createSelector } from 'reselect';
import type { NotificationsState } from './typesTS';
import { FloatingNotification } from './typesTS';

const notificationsStatus = (state): NotificationsState => state.notifications;

const unreadNotificationsCount = (state): number => notificationsStatus(state).unreadNotificationsCount || 0;

const selectFloatingNotifications = createSelector(
  notificationsStatus,
  // TODO Sort items and add more constraints: type, date, etc. ??
  (notifications): FloatingNotification[] => (notifications.floating ? Object.values(notifications.floating) : []),
);

export const notificationsSelectors = {
  notificationsStatus,
  unreadNotificationsCount,
  selectFloatingNotifications,
};
