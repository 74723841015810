import stateCreator from 'helpers/stateCreator';
import type { RedeemCouponActions, RedeemCouponState } from './types';
import { CouponActionTypes } from '../../actionTypes';
import couponManager from '../../couponManager';

export const getInitialState = (couponCode: string | undefined): RedeemCouponState => ({ couponCode });

export const reducer = (state: RedeemCouponState = {}, action: RedeemCouponActions): RedeemCouponState => {
  switch (action.type) {
    case CouponActionTypes.REDEEMED: {
      if (action.error) {
        const couponExpired = couponManager.isBusinessError(action.metadata);
        return stateCreator.getAsyncErrorState(action, state, {
          couponExpired,
          couponCode: couponExpired ? '' : state.couponCode,
        });
      }

      const {
        order: { quantity },
      } = action.payload;

      return stateCreator.getInitialAsyncState(state, {
        chestsReceived: quantity,
        couponCode: '',
      });
    }
    case CouponActionTypes.REDEEMING:
      return stateCreator.getAsyncLoadingState(state);
    case CouponActionTypes.REDEMPTION_RESET:
      return stateCreator.getInitialAsyncState(state, {
        couponCode: '',
      });
    default:
      return state;
  }
};
