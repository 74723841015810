import { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import theme from './layout.scss';

type OverlayProps = {
  show: boolean;
  onClick: () => void;
};

export const Overlay = ({ show, onClick }: OverlayProps) => {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [show]);

  return (
    <CSSTransition
      timeout={300}
      mountOnEnter
      unmountOnExit
      in={show}
      classNames={{
        enter: theme['overlaytransition--enter'],
        enterActive: theme['overlaytransition--enteractive'],
        exit: theme['overlaytransition--exit'],
      }}
    >
      <div className={theme.layout__overlay} onClick={onClick} />
    </CSSTransition>
  );
};
