import { createSelector } from 'reselect';
import type { SetBasicInfo, SetStatusInfo, UserSet } from 'model/indexTS';
import type { UserSetsState, UserSetsStatus } from './types';

const userSetsState = (state): UserSetsState => state.userSets;

const userSets = createSelector(
  [userSetsState],
  (userSetsStateInfo: UserSetsState): Array<UserSet> | undefined => userSetsStateInfo.userSets,
);

const userSet = createSelector(
  [userSetsState, (_state, setId: string | undefined) => setId],
  (userSetsStateInfo: UserSetsState, setId = ''): UserSet | null => {
    const { selectedUserSetId = '', userSetsById } = userSetsStateInfo;

    const setInfo = userSetsById[setId || selectedUserSetId];
    return setInfo || null;
  },
);

const selectedUserSet = createSelector([userSetsState], (userSetsStateInfo: UserSetsState): UserSet | null => {
  const { selectedUserSetId = '', userSetsById } = userSetsStateInfo;

  return userSetsById[selectedUserSetId] || null;
});

const collectionId = createSelector(
  [userSet],
  (userSetInfo: UserSet | null): string => userSetInfo?.collectionId || '',
);

const setBasicInfo = createSelector([userSet], (userSetInfo: UserSet | null): SetBasicInfo | null => {
  if (!userSetInfo) {
    return null;
  }

  const { default: defaultSet, name, craftableCards, imageUrl, order } = userSetInfo;
  return { default: defaultSet || false, name, craftableCards, imageUrl, order };
});

const status = createSelector([userSet], (userSetInfo: UserSet | null): SetStatusInfo | null => {
  if (!userSetInfo) {
    return null;
  }

  const { paused, pausedReason, resumeAt } = userSetInfo;
  return { paused, pausedReason, resumeAt };
});

const userSetsStatus = createSelector(
  [userSetsState],
  (userSetsStateInfo: UserSetsState): UserSetsStatus => {
    const { error, isLoading, userSets: loadedUserSets } = userSetsStateInfo;
    return {
      error,
      isLoading,
      userSets: loadedUserSets,
    };
  },
);

const hasLoadedUserSets = createSelector([userSets], (userSetsInfo: Array<UserSet> | undefined): boolean =>
  Array.isArray(userSetsInfo),
);

// it returns the selected set
const setId = createSelector(
  [userSetsState],
  (userSetsStateInfo: UserSetsState): string => userSetsStateInfo.selectedUserSetId || '',
);

const marathonSpecialSet = createSelector([userSetsState], (userSetsStateInfo: UserSetsState): UserSet | null => {
  const { userSets: allSets } = userSetsStateInfo;
  if (!allSets) {
    return null;
  }

  return allSets.find(userSetItem => userSetItem.marathonDefault) || null;
});

export const userSetsSelectors = {
  collectionId,
  userSet,
  selectedUserSet,
  setBasicInfo,
  setId,
  status,
  hasLoadedUserSets,
  userSetsStatus,
  userSets,
  marathonSpecialSet,
};
