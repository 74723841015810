import type { AppState, BaseQuery } from './types';

const initialState: AppState = {
  path: '',
  query: {},
};

export const getInitialState = (path: string, query: BaseQuery): AppState => ({
  path,
  query,
});

export const appReducer = (state = initialState): AppState => {
  return state;
};
