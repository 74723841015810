import { SetsActionTypes } from 'services/sets';
import { SetLootsActionTypes } from 'services/set-loots';
import { setCardsActionTypes } from 'services/set-cards';
import { PageActionTypes } from 'services/page';
import type { ReconcileState } from '../../types';

const initialState: ReconcileState = {};

export const reducer = (state: ReconcileState = initialState, action): ReconcileState => {
  const { error, type } = action;

  switch (type) {
    case SetsActionTypes.ORDER_SUCCESS:
    case SetsActionTypes.ORDER_FAILED:
      return { reconcile: true };
    case SetsActionTypes.SELECTED_SET_CHANGED: {
      const { doNotReloadSet } = action.payload;
      const reconcileSet = !doNotReloadSet;

      return { reconcile: reconcileSet, invalidating: reconcileSet };
    }
    case SetsActionTypes.CREATED:
    case SetsActionTypes.ARCHIVED:
    case SetLootsActionTypes.LOOTS_UPDATED:
    case setCardsActionTypes.CREATED:
    case setCardsActionTypes.CREATED_FROM_TEMPLATE:
    case setCardsActionTypes.DELETED:
    case setCardsActionTypes.UPDATED:
    case setCardsActionTypes.TRANSFERRED:
    case setCardsActionTypes.STATUS_CHANGED:
    case setCardsActionTypes.BULK_UPDATED:
    case PageActionTypes.UPDATE_PAGE_MODULES_SUCCESS:
      if (error) {
        return state;
      }

      return { reconcile: true };
    case SetsActionTypes.REQUEST:
      return { invalidating: state.invalidating, reconcile: false };
    case SetsActionTypes.RECEIVED:
      return { reconcile: false };
    default:
      return state;
  }
};
