import stateCreator from 'helpers/stateCreator';
import { PageActionTypes } from 'services/page';
import { MAX_MONTH_EMAILS, PageCommunicationActions, PageCommunicationState } from './types';
import { PageCommunicationActionTypes } from './actionTypes';

const initialState: PageCommunicationState = {
  emailsLeft: MAX_MONTH_EMAILS,
};

export const reducer = (
  state: PageCommunicationState = initialState,
  action: PageCommunicationActions,
): PageCommunicationState => {
  switch (action.type) {
    case PageCommunicationActionTypes.RECEIVED: {
      if (action.error) {
        return stateCreator.getAsyncErrorState(action, state, initialState);
      }

      const now = new Date();
      const actualMonth = now.getMonth();
      const actualYear = now.getFullYear();
      const emailsLeft = action.payload.reduce((acc, email) => {
        const day = new Date(email.createdAt);
        if (
          email.toViewers &&
          day.getMonth() === actualMonth &&
          actualYear === day.getFullYear() &&
          email.recipients?.length === 0
        ) {
          return acc - 1;
        }

        return acc;
      }, MAX_MONTH_EMAILS);

      return stateCreator.getInitialAsyncState(state, {
        emails: action.payload,
        emailsLeft,
      });
    }

    case PageCommunicationActionTypes.EMAIL_SENT: {
      if (action.error) {
        return state;
      }

      const newEmails = [...(state.emails || []), action.payload];
      const emailsLeft =
        action.payload.toViewers && action.payload.recipients.length === 0
          ? (state.emailsLeft || MAX_MONTH_EMAILS) - 1
          : state.emailsLeft;

      return { ...state, emailsLeft, emails: newEmails };
    }

    case PageCommunicationActionTypes.FETCHING:
      return stateCreator.getAsyncLoadingState(state);

    case PageActionTypes.CHANGED:
      return initialState;

    default:
      return state;
  }
};
