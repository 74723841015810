export enum TagsActionTypes {
  CREATE_TAG_REQUEST = 'tags/CREATE_TAG_REQUEST',
  CREATE_TAG_SUCCESS = 'tags/CREATE_TAG_SUCCESS',
  CREATE_TAG_ERROR = 'tags/CREATE_TAG_ERROR',
  TAGS_REQUEST = 'tags/TAGS_REQUEST',
  TAGS_SUCCESS = 'tags/TAGS_SUCCESS',
  TAGS_ERROR = 'tags/TAGS_ERROR',
  TAGS_PAGINATION_REQUEST = 'tags/TAGS_PAGINATION_REQUEST',
  TAGS_PAGINATION_SUCCESS = 'tags/TAGS_PAGINATION_SUCCESS',
  TAGS_PAGINATION_ERROR = 'tags/TAGS_PAGINATION_ERROR',
  TAGS_PAGINATION_NEXT_REQUEST = 'tags/TAGS_PAGINATION_NEXT_REQUEST',
  TAGS_PAGINATION_NEXT_SUCCESS = 'tags/TAGS_PAGINATION_NEXT_SUCCESS',
  TAGS_PAGINATION_NEXT_ERROR = 'tags/TAGS_PAGINATION_NEXT_ERROR',
}
