import _ from 'lodash';
import { UserSetLoot } from 'model/indexTS';
import type { CollectionLoot } from 'model/indexTS';
import type { CollectionLootsStateProps, UserSetLootsStateProps } from './types';
import { CollectionLootOpenedResponse, NormalizedOpenLootResponse } from './types';

const lootImage = (loots: Array<UserSetLoot>): string => (!loots || loots.length === 0 ? '' : loots[0].imageUrl);

export const userSetLootsState = ({
  loots = [],
  collectionId,
  collectionLoots,
  set,
  hasBuyableLoots,
}: {
  loots?: Array<UserSetLoot>;
  collectionId: string;
  set: {
    _id: string;
    name: string;
  };
  hasBuyableLoots: boolean;
  collectionLoots?: Array<CollectionLoot>;
}): UserSetLootsStateProps => {
  const collectionLootsData = collectionLoots || [];

  return {
    loots: [...loots],
    collectionLoots: [...collectionLootsData],
    collectionId,
    set,
    lootImageUrl: lootImage(loots),
    hasBuyableLoots,
  };
};

export const collectionLootsStatePropertiesAfterOpeningLoot = (
  collectionLoots: Array<CollectionLoot> = [],
  openLootIds: string[],
): Pick<CollectionLootsStateProps, 'collectionLoots'> => {
  const newCollectionLoots =
    collectionLoots.filter(collectionLoot => {
      return openLootIds.indexOf(collectionLoot._id) === -1;
    }) || [];

  return {
    collectionLoots: newCollectionLoots,
  };
};

export const normalizeResponse = (response: CollectionLootOpenedResponse): NormalizedOpenLootResponse => {
  const {
    summary: { openCards, openLoots },
    cards,
  } = response;

  // maybe not needed if they come filtered from Back-end
  const filteredLootOpenedCards = _.uniqBy(cards, 'setCardId');
  return {
    cards: filteredLootOpenedCards.map(lootOpenedCard => {
      return {
        card: lootOpenedCard.setCard,
        totalAppeared: openCards[lootOpenedCard.setCardId],
      };
    }),
    setLootsOpenedIds: openLoots,
  };
};
