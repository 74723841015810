const MOBILE_MEDIA = '(min-width: 64em)';
const desktopMediaQuery = typeof matchMedia === 'undefined' ? null : matchMedia(MOBILE_MEDIA);

const addMobileListener = (func: (isMobile: boolean) => void) => {
  if (desktopMediaQuery) {
    const mobileListener = () => func(!desktopMediaQuery.matches);
    desktopMediaQuery.addListener(mobileListener);
    return mobileListener;
  }
  return undefined;
};

const removeMobileListener = (func: undefined | (() => void)) => {
  if (desktopMediaQuery && func) {
    desktopMediaQuery.removeListener(func);
  }
};

const isMobile = (): boolean => {
  if (desktopMediaQuery) {
    return !desktopMediaQuery.matches;
  }
  return false;
};

export default {
  addMobileListener,
  isMobile,
  removeMobileListener,
};
