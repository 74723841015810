import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReturnTypeFromInterfaceWithFunction } from 'types/utils';
import { marathonActions } from './actions';
import { marathonSelectors } from './selectors';

export type UseMarathonActions = typeof marathonActions;
type SelectorType = typeof marathonSelectors;

export interface UseMarathonSelectors {
  marathonState: ReturnTypeFromInterfaceWithFunction<SelectorType, 'marathonState'>;
  marathonId: ReturnTypeFromInterfaceWithFunction<SelectorType, 'getMarathonId'>;
  marathonTrackerId: ReturnTypeFromInterfaceWithFunction<SelectorType, 'getMarathonTrackerId'>;
  marathonStatus: ReturnTypeFromInterfaceWithFunction<SelectorType, 'getMarathonStatus'>;
}

export const useMarathonSelectors = (): UseMarathonSelectors => {
  const marathonState = useSelector(marathonSelectors.marathonState);
  const marathonId = useSelector(marathonSelectors.getMarathonId);
  const marathonStatus = useSelector(marathonSelectors.getMarathonStatus);
  const marathonTrackerId = useSelector(marathonSelectors.getMarathonTrackerId);

  return {
    marathonState,
    marathonId,
    marathonStatus,
    marathonTrackerId,
  };
};

export const useMarathonActions = (): UseMarathonActions => {
  const dispatch = useDispatch();

  return useMemo(() => {
    return {
      addMarathonTime: (slug, data) => dispatch(marathonActions.addMarathonTime(slug, data)),
      createMarathon: (slug, data) => dispatch(marathonActions.createMarathon(slug, data)),
      fetchMarathon: slug => dispatch(marathonActions.fetchMarathon(slug)),
      fetchMarathonTracker: (slug: string) => dispatch(marathonActions.fetchMarathonTracker(slug)),
      startMarathon: (marathonId: string) => dispatch(marathonActions.startMarathon(marathonId)),
      stopMarathon: (marathonId: string) => dispatch(marathonActions.stopMarathon(marathonId)),
      updateMarathon: (slug, data) => dispatch(marathonActions.updateMarathon(slug, data)),
      updateMarathonStatus: (slug, isPausing) => dispatch(marathonActions.updateMarathonStatus(slug, isPausing)),
    };
  }, [dispatch]);
};
