import { Link } from 'react-router-dom';
import type { BannedFromPageInfo } from 'model/pageTS';
import { useTranslation } from 'utils/translation';
import { pages } from 'services/routing';
import Button from 'ui-library/button';

interface UserBannedFromPageInfoProps {
  banInfo: BannedFromPageInfo;
  slug: string;
}

export const UserBannedFromPageInfo = ({ slug, banInfo }: UserBannedFromPageInfoProps): JSX.Element => {
  const { t } = useTranslation(['common', 'publicUserPage']);
  return (
    <div className="margin-top align-center padding m color-error ">
      <p>{t('publicUserPage:bannedFromPage', { slug })}</p>
      <p className="font-bold margin-bottom">{t(`my-viewers:bans.banCategories.${banInfo.category.toLowerCase()}`)}</p>
      <Button label={t('common:goToMyStreamers')} component={Link} to={pages.USER_COLLECTIONS} />
    </div>
  );
};
