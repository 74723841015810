import { FunctionComponent } from 'react';
import loadable from 'components/dynamic-import';
import { staffPermissions } from 'services/permissions/constants';
import { FullLayout } from 'scenes/layout';
import { checkPermissions } from 'components/permissions';

const AdminFrames = loadable(() => import('./AdminFrames'));

export const AdminFramesPageBase = (): JSX.Element => {
  return (
    <FullLayout>
      <AdminFrames />
    </FullLayout>
  );
};

export const AdminFramesPage: FunctionComponent = checkPermissions({
  requiredPermissions: staffPermissions,
  redirectToBasePage: true,
})(AdminFramesPageBase);
