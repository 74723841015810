import { createSelector } from 'reselect';
import { DEFAULT_COUPONS_QUERY } from 'services/coupons/constants';
import type { CouponsState } from './types';
import type { CouponRedemptionStatus } from './services/redeem';
import type { CouponListStatus } from './services/list';
import couponManager from './couponManager';

const couponsState = (state): CouponsState => state.coupons;

const couponRedemptionStatus = createSelector(
  [couponsState],
  (couponsStateInfo: CouponsState): CouponRedemptionStatus => couponsStateInfo.redemption,
);

const couponListStatus = createSelector(
  [couponsState, (_state, componentKey): number => componentKey],
  (couponsStateInfo: CouponsState, componentKey: number): CouponListStatus => {
    const listStatus = couponsStateInfo.list[componentKey];
    if (!listStatus) {
      return { query: DEFAULT_COUPONS_QUERY, activeCouponsCount: 0 };
    }
    return {
      ...listStatus,
      activeCouponsCount: couponManager.getActiveCouponsCount(listStatus.data),
    };
  },
);

const activeCouponsCount = createSelector([couponListStatus], (couponListStatusInfo: CouponListStatus): number => {
  const { data } = couponListStatusInfo;
  return !data ? 0 : data.filter(coupon => !couponManager.isCouponExpired(coupon)).length;
});

export const couponsSelectors = {
  activeCouponsCount,
  couponRedemptionStatus,
  couponListStatus,
};
