import { MISCELLANEOUS_URL } from './images';

function getStaticUrl(relativeUrl: string): string {
  return `https://static.streamloots.com/99d63dc9-60b1-4376-aea9-08592f04781d/${relativeUrl}`;
}

function getDashboardUrl(relativeUrl: string): string {
  return `https://static.streamloots.com/b355d1ef-d931-4c16-a48f-8bed0076401b/resources/${relativeUrl}`;
}

function getLandingUrl(relativeUrl: string): string {
  return `https://static.streamloots.com/b355d1ef-d931-4c16-a48f-8bed0076401b/landing/${relativeUrl}`;
}

function getRewindUrl(relativeUrl: string): string {
  return `https://static.streamloots.com/b355d1ef-d931-4c16-a48f-8bed0076401b/rewind/${relativeUrl}`;
}

function getMiscellaneousUrl(relativeUrl: string): string {
  return `${MISCELLANEOUS_URL}${relativeUrl}`;
}

const AWS_STATIC = {
  CHRISTMAS_LOGO: getStaticUrl('brand/christmas-symbo.svg'),
  ACHIEVEMENTS: {
    BADGES: getStaticUrl('achievements/badges.png'),
  },
  BADGES: {
    MIDAS_BADGE: getStaticUrl('badges/badge-midas.jpg'),
    MIDAS_BADGE_LOCKED: getStaticUrl('badges/badge-midas-lock.jpg'),
    TOP_SUPPORTER: getStaticUrl('badges/top-supporter.png'),
    TOP_SUPPORTER_PREVIEW: getStaticUrl('badges/top-supporter-preview.gif'),
    TOP_SUPPORTER_NO_TEXT: getStaticUrl('badges/top-supporter-notext.png'),
  },
  DASHBOARD: {
    WEEKLY_REPORT_EN: getDashboardUrl('en-weekly-report.png'),
    WEEKLY_REPORT_ES: getDashboardUrl('es-weekly-report.png'),
    WEEKLY_REPORT: getDashboardUrl('king-midas-dashboard.png'),
    WEEKLY_REPORT_CHRISTMAS: getDashboardUrl('weekly-report-christmas.png'),
    WEEKLY_REPORT_COMING_SOON: getDashboardUrl('es-weekly-report-coming-soon.png'),
    REFERRAL: getDashboardUrl('imagen-referral_dashboard-min.png'),
    STREAMLOOTS_ACADEMY: getDashboardUrl('streamloots-academy-horizontal.jpg'),
    PARTNER_ES: getDashboardUrl('es-partner-type-image-min.png'),
    PARTNER_EN: getDashboardUrl('en-partner-type-image-min.png'),
    BLOG_ES: getDashboardUrl('ES_blog.png'),
    BLOG_EN: getDashboardUrl('EN_blog.png'),
    DBD_COLLECTION_ES: getDashboardUrl('ES-dbd_default-min.png'),
    DBD_COLLECTION_EN: getDashboardUrl('EN-dbd_default-min.png'),
    ANIMAL_CROSSING_COLLECTION_EN: getDashboardUrl('EN-animal_crossing_default-min.png'),
    ANIMAL_CROSSING_COLLECTION_ES: getDashboardUrl('ES-animal_crossing_default-min.png'),
    SEA_OF_THIEVES_COLLECTION_ES: getDashboardUrl('ES_SeaofThieves-collection-min.png'),
    SEA_OF_THIEVES_COLLECTION_EN: getDashboardUrl('EN_SeaofThieves-collection-min.png'),
    VARIETY_COLLECTION_EN: getDashboardUrl('EN-variety _default-min.png'),
    VARIETY_COLLECTION_ES: getDashboardUrl('ES-variety _default-min.png'),
    SUMMER20_ES: getDashboardUrl('ES_SUMMER20-discount.jpg'),
    SUMMER20_EN: getDashboardUrl('EN-SUMMER20-discount.jpg'),
    NEW_MINECRAFT_COLLECTION_ES: getDashboardUrl('ES_new-minecraft-collectionT.jpg'),
    MINECRAFT_WEEK_EN: getDashboardUrl('EN_Minecraft-week.jpg'),
    VALORANT_EN: getDashboardUrl('en-valorant-streamloots-originals.jpg'),
    VALORANT_ES: getDashboardUrl('es-valorant-streamloots-originals.jpg'),
    NBA2K21_EN: getDashboardUrl('DB-EN-NBA_2k21_Streamloots_Original-min.jpg'),
    NBA2K21_ES: getDashboardUrl('DB-ES-NBA_2k21_Streamloots_Original-min.jpg'),
    DBD_90_CARDS_ES: getDashboardUrl('ES_DbD-Collection-Updated_DASHBOARD.jpg'),
    FALL_GUYS_ES: getDashboardUrl('DASHBOARD-ES-Fall_Guys_Streamloots_Original.jpg'),
    FALL_GUYES_EN: getDashboardUrl('DASHBOARD-EN-Fall_Guys_Streamloots_Original.jpg'),
    FORTNITE_ES: getDashboardUrl('DASHBOARD-ES-Fortnite_Streamloots_Original.jpg'),
    FORTNITE_EN: getDashboardUrl('DASHBOARD-EN-Fortnite_Streamloots_Original.jpg'),
  },
  MISCELLANEOUS: {
    FRIENDS: getMiscellaneousUrl('link_accounts.png'),
  },
  CARDS: {
    PLACEHOLDER: getMiscellaneousUrl('card-placeholder.png'),
  },
  REWIND: {
    FLOATING_REWIND: getStaticUrl('ilustrations/rewind-floating.png'),
    STREAMER_ES: getRewindUrl('ES_SL_Streamer-Viewer-19_compact-min.png'),
    STREAMER_EN: getRewindUrl('EN_SL_Streamer-Rewind-19_compact-min.png'),
    VIEWER_ES: getRewindUrl('SL_Rewind-Viewer-19_ES-min.png'),
    VIEWER_EN: getRewindUrl('EN_SL_Streamer-Viewer-19_compact-min.png'),
  },
  PANELS: {
    es: {
      STREAMER: getStaticUrl('panels/ES-streamer.zip'),
      AFFILIATE: getStaticUrl('panels/ES-affiliate.zip'),
      PARTNER: getStaticUrl('panels/ES-partner.zip'),
    },
    en: {
      STREAMER: getStaticUrl('panels/EN-streamer.zip'),
      AFFILIATE: getStaticUrl('panels/EN-affiliate.zip'),
      PARTNER: getStaticUrl('panels/EN-partner.zip'),
    },
  },
  PANEL_PREVIEWS: {
    STREAMER: getStaticUrl('panels/panel-color-streamer.png'),
    AFFILIATE: getStaticUrl('panels/panel-color-affiliate.png'),
    PARTNER: getStaticUrl('panels/panel-color-partner.png'),
  },
  LANDING: {
    ENJOYS_WEB: getLandingUrl('both-enjoys-web.png'),
    CREATE_YOUR_WEB: getLandingUrl('create-your-page-web.png'),
    STREAMER: getLandingUrl('ill-char-streamer_sing_star2x.png'),
    VIEWER: getLandingUrl('ill-char-streamer_support-web.png'),
    BENEFIT_1: getLandingUrl('3benef-01b.png'),
    BENEFIT_2: getLandingUrl('3benef-02b.png'),
    BENEFIT_3: getLandingUrl('3benef-03b.png'),
    PORTADA: getLandingUrl('portada.jpg'),
  },
};

export default AWS_STATIC;
