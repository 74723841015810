import { Router } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { History } from 'history';
import { renderRoutes, RouteConfig } from 'react-router-config';
import AlertProvider from 'components/react-alert-provider';
import { Provider } from 'react-redux';
import { EnhancedStore } from '@reduxjs/toolkit';
import { ReduxStore } from 'store/types';
import { TranslationSSR } from './TranslationSSR';

interface AppClientProps {
  store: EnhancedStore<ReduxStore>;
  routes: Array<RouteConfig>;
  history: History<History.UnknownFacade>;
}

export const AppClient = ({ store, routes, history }: AppClientProps): JSX.Element => {
  return (
    <TranslationSSR>
      <AlertProvider>
        <Provider store={store}>
          <Router history={history}>
            <CompatRouter>{renderRoutes(routes)}</CompatRouter>
          </Router>
        </Provider>
      </AlertProvider>
    </TranslationSSR>
  );
};
