import stateCreator from 'helpers/stateCreator';
import {
  isRedeemedCardEvent,
  PAGE_EVENTS_GET_RESPONSE_SUCCESS,
  PAGE_EVENTS_PUT_REQUEST,
} from '@streamloots/streamloots-types';
import { PageActionTypes } from '../page';
import { ActivityFeedActions, ActivityFeedState, CancelRedeemedCardPayload } from './types';
import { ActivityFeedActionTypes } from './actionTypes';

const initialState: ActivityFeedState = {};

export const reducer = (state: ActivityFeedState = initialState, action: ActivityFeedActions): ActivityFeedState => {
  const { type, error, payload, metadata } = action;
  switch (type) {
    case ActivityFeedActionTypes.RECEIVED: {
      if (error) {
        return stateCreator.getAsyncErrorState(action, state, initialState);
      }

      return stateCreator.getInitialAsyncState(state, {
        activityFeed: payload as PAGE_EVENTS_GET_RESPONSE_SUCCESS,
        isEndOfFeed: false,
      });
    }

    case ActivityFeedActionTypes.FETCHING:
      return stateCreator.getAsyncLoadingState(state);

    case ActivityFeedActionTypes.RECEIVED_NEXT: {
      if (error) {
        return stateCreator.getAsyncErrorState(action, state, initialState);
      }

      const activityFeed = [...(state.activityFeed || []), ...(payload as PAGE_EVENTS_GET_RESPONSE_SUCCESS)];

      return stateCreator.getInitialAsyncState(state, {
        activityFeed,
        nextEventsLoading: false,
        isEndOfFeed: activityFeed.length === state.activityFeed?.length,
      });
    }

    case ActivityFeedActionTypes.FETCHING_NEXT:
      return {
        ...state,
        nextEventsLoading: true,
      };

    case ActivityFeedActionTypes.RECEIVED_NEW: {
      if (error) {
        return stateCreator.getAsyncErrorState(action, state, initialState);
      }

      return stateCreator.getInitialAsyncState(state, {
        activityFeed: [...(payload as PAGE_EVENTS_GET_RESPONSE_SUCCESS), ...(state.activityFeed || [])],
        newEventsLoaded: payload as PAGE_EVENTS_GET_RESPONSE_SUCCESS,
      });
    }

    case ActivityFeedActionTypes.FINISHED: {
      const { events } = metadata as PAGE_EVENTS_PUT_REQUEST;
      if (error) {
        return state;
      }

      const newEvents = state.activityFeed?.map(event => {
        if (isRedeemedCardEvent(event) && events.some(id => id === event._id)) {
          return { ...event, metadata: { ...event.metadata, completed: true } };
        }
        return event;
      });

      return {
        ...state,
        activityFeed: newEvents,
      };
    }

    case ActivityFeedActionTypes.CANCEL_REEDEMED: {
      const { cardId, collectionId } = payload as CancelRedeemedCardPayload;
      if (error) {
        return state;
      }

      const newEvents = state.activityFeed?.map(event => {
        if (
          isRedeemedCardEvent(event) &&
          event.metadata.collectionId === collectionId &&
          event.metadata.cardId === cardId
        ) {
          return { ...event, metadata: { ...event.metadata, returned: true } };
        }
        return event;
      });

      return {
        ...state,
        activityFeed: newEvents,
      };
    }

    case PageActionTypes.CHANGED: {
      return initialState;
    }

    default:
      return state;
  }
};
