import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import AlertAnimation from 'ui-library/alert-animation';
import { ButtonIcon } from 'ui-library/button';
import theme from './floating-image-alert.scss';

const classNames = ClassNames(theme);

type Props = {
  show: boolean;
  onClose: () => void;
  alertClassName?: string;
  children?: React.ReactNode;
};

const FloatingAlertContainer = ({ show, onClose, alertClassName = '', children }: Props): JSX.Element => {
  return (
    <AlertAnimation show={show}>
      <div
        className={classNames({
          imagealert: true,
          [alertClassName]: alertClassName,
        })}
      >
        <ButtonIcon
          type="button"
          className={classNames('imagealert__close')}
          asLink
          onClick={onClose}
          icon="close"
          data-testid="floating-image-alert-close"
          ariaLabel="Close floating panel"
        />
        {children}
      </div>
    </AlertAnimation>
  );
};

export default FloatingAlertContainer;
