const BRAND_FOLDER = 'https://static.streamloots.com/1ee0e8bc-5be9-4cd0-ae7f-f2ceb707ab44/brand/';

const BRAND = {
  horizontalBlack: `${BRAND_FOLDER}streamloots-hor_black.svg`,
  horizontalCoral: `${BRAND_FOLDER}streamloots-hor_coral_black.svg`,
  horizontalWhite: `${BRAND_FOLDER}streamloots-hor_white.svg`,
  symbolBlack: `${BRAND_FOLDER}streamloots-symbol_black.svg`,
  symbolCoral: `${BRAND_FOLDER}streamloots-symbol_coral.svg`,
  symbolWhite: `${BRAND_FOLDER}streamloots-symbol_white.svg`,
  verticalBlack: `${BRAND_FOLDER}streamloots-ver_black.svg`,
  verticalCoral: `${BRAND_FOLDER}streamloots-ver_coral.svg`,
  verticalWhite: `${BRAND_FOLDER}streamloots-ver_white.svg`,
  foundersPicture: `${BRAND_FOLDER}streamloots-founders.jpg`,
  creator: `${BRAND_FOLDER}streamloots-logo_creator.svg`,
  moderator: `${BRAND_FOLDER}streamloots-logo_mod.svg`,
  admin: `${BRAND_FOLDER}streamloots-logo_admin.svg`,
  primary: `${BRAND_FOLDER}streamloots-hor_neg_color_white.svg`,
  secondary: `${BRAND_FOLDER}streamloots-hor_neg_color_white.svg`,
  secondaryNoPadding: `${BRAND_FOLDER}Streamloots-hor_color_white.svg`,
  symbol: `${BRAND_FOLDER}streamloots-hor_neg_color_white.svg`,
  invertedSecondary: `${BRAND_FOLDER}streamloots-hor_neg_color_white.svg`,
  christmas_admin: `${BRAND_FOLDER}streamloots-logo_admin-navidad.svg`,
  christmas_creator: `${BRAND_FOLDER}streamloots-logo_creator-navidad.svg`,
  christmas_moderator: `${BRAND_FOLDER}streamloots-logo_mod-navidad.svg`,
  christmas_default: `${BRAND_FOLDER}Streamloots-hor_neg_color_white-navidad.svg`,
  halloween: `${BRAND_FOLDER}streamloots-hor_neg_color_white_halloween_blue.svg`,
  halloween_creator: `${BRAND_FOLDER}streamloots-logo_creator_halloween_blue.svg`,
};

export const logosZippedURLs = {
  symbolBlackZip: `${BRAND_FOLDER}icon-black.zip`,
  symbolWhiteZip: `${BRAND_FOLDER}icon-white.zip`,
  symbolCoralZip: `${BRAND_FOLDER}icon-coral.zip`,
  verticalBlackZip: `${BRAND_FOLDER}vertical-black.zip`,
  verticalWhiteZip: `${BRAND_FOLDER}vertical-white.zip`,
  verticalCoralZip: `${BRAND_FOLDER}vertical-coral.zip`,
  horizontalBlackZip: `${BRAND_FOLDER}horizontal-black.zip`,
  horizontalWhiteZip: `${BRAND_FOLDER}horizontal-white.zip`,
  horizontalCoralZip: `${BRAND_FOLDER}horizontal-coral.zip`,
};

export default BRAND;
