import { permissions } from 'services/permissions';
import loadable from 'components/dynamic-import';
import { checkPermissions } from 'components/permissions';

const ConnectedOriginals = loadable(() => import('./ConnectedOriginals'));

const Originals = () => {
  return <ConnectedOriginals />;
};

export default checkPermissions({
  redirectToBasePage: true,
  requiredPermissions: permissions.SET_CARDS_EDIT,
})(Originals);
