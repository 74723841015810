import ClassNames from '@streamloots/classnames';
import Icon from 'ui-library/icons';
import theme from './explanation-panel.scss';

const classNames = ClassNames(theme);

export enum ExplanationHeaderVariations {
  Candy = 'candy',
  Mystic = 'mystic',
  WinterSoldier = 'winter-soldier',
  Sunset = 'sunset',
  SunsetFlat = 'sunset-flat',
  Green = 'green',
}

interface ExplanationPanelHeaderProps {
  className?: string;
  icon?: string;
  variation?: ExplanationHeaderVariations;
}

export const ExplanationPanelHeader = ({
  className = '',
  icon,
  variation = ExplanationHeaderVariations.WinterSoldier,
}: ExplanationPanelHeaderProps): JSX.Element => {
  return (
    <div
      className={classNames({
        'explanation-panel__header': true,
        [`explanation-panel__header--${variation}`]: true,
        [className]: Boolean(className),
      })}
    >
      {icon && <Icon className={classNames('explanation-panel__header__icon')} id={icon} />}
    </div>
  );
};
