import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { RadioButtonIcon } from './RadioButtonIcon';
import theme from './radio-button-card.scss';

const classNames = ClassNames(theme);

export interface RadioButtonCardProps {
  children: React.ReactNode;
  id?: string;
  name: string;
  checked: boolean;
  value?: string | number;
  label: string;
  selectable?: boolean;
  className?: string;
  isPhone?: boolean;
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (evt: React.MouseEvent<HTMLInputElement>) => void;
  type?: 'radio' | 'checkbox';
}

const RadioButtonCard = ({
  children,
  id,
  checked,
  label,
  selectable = true,
  className = '',
  isPhone = false,
  type = 'radio',
  ...props
}: RadioButtonCardProps): JSX.Element => {
  if (isPhone) {
    return (
      <div
        className={classNames({
          'radio-button-card': true,
          'radio-button-card--phone': true,
          'radio-button-card--selected': checked,
          [className]: Boolean(className),
        })}
      >
        <label className={classNames('radio-button-card__label')} htmlFor={id}>
          <input id={id} type={type} {...props} checked={checked} />
          <span className="visuallyhidden">{label}</span>
        </label>
        <div>
          <RadioButtonIcon selectable={selectable} checked={checked} />
        </div>
        <div>{children}</div>
      </div>
    );
  }

  return (
    <div
      className={classNames({
        'radio-button-card': true,
        'radio-button-card--selected': checked,
        [className]: Boolean(className),
      })}
    >
      <label className={classNames('radio-button-card__label')} htmlFor={id}>
        <input id={id} type={type} {...props} checked={checked} />
        <span className="visuallyhidden">{label}</span>
      </label>
      <div className={classNames('radio-button-card__wrapper')}>
        <div className={classNames('radio-button-card__header')}>
          <RadioButtonIcon selectable={selectable} checked={checked} />
        </div>
        <div className="align-center">{children}</div>
      </div>
    </div>
  );
};

export default RadioButtonCard;
