import { Power } from 'model/indexTS';
import { createSelector } from 'reselect';
import { PowersState } from './types';

const alertState = (state: { alert: PowersState }): PowersState => state.alert;

const powers = createSelector([alertState], (state: PowersState): Power[] | undefined => state.powers);

const activePowers = createSelector([alertState], (state: PowersState): Power[] => {
  const statePowers = state.powers ?? [];

  return statePowers.filter(power => power.unlocked || new Date(power.expiresAt) > new Date());
});

export default {
  powers,
  activePowers,
};
