import * as React from 'react';
import Skeleton from 'ui-library/skeleton';
import theme from './component-skeletons.scss';

type SimplifiedCardSkeletonsProps = {
  count?: number;
  includeWrapper?: boolean;
};

const SKELETON_HEIGHT = 300;

export const SimplifiedCardSkeletons = ({ count = 20, includeWrapper }: SimplifiedCardSkeletonsProps): JSX.Element => {
  const cardsSkeletonsArray = new Array(count);
  const cards = [...cardsSkeletonsArray.keys()];
  const skeletons = cards.map(key => <Skeleton key={key} block height={SKELETON_HEIGHT} />);

  if (!includeWrapper) {
    return <React.Fragment>{skeletons}</React.Fragment>;
  }

  return <div className={theme['skeletons-grid']}>{skeletons}</div>;
};
