import { CARD_STATUS, OwnerCard } from 'model/indexTS';
import { latinize } from 'utils/latinize';
import type { SetCardListStateBySetId } from './types';

const setListState = (cards: Array<OwnerCard>): SetCardListStateBySetId => {
  const setCardsById = {};

  const normalizedCards: OwnerCard[] = [];

  const activated = cards.filter(card => {
    setCardsById[card._id] = card;

    normalizedCards.push({
      ...card,
      normalizedDescription: card.description ? latinize(card.description) : '',
    });

    return card.status === CARD_STATUS.ACTIVATED;
  });

  return {
    hasActivatedCards: activated.length > 0,
    cards: normalizedCards,
    setCardsById,
  };
};

export default {
  setListState,
};
