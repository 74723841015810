import { CouponActionTypes } from 'services/coupons';
import { SetsActionTypes } from 'services/sets';
import { paymentActionTypes } from 'services/payment';
import { SetLootsActionTypes } from 'services/set-loots';
import { UserSetLootsActionTypes, COLLECTION_LOOT_ALREADY_OPEN_ERROR } from 'services/user-set-loots';
import { UserSetsActionTypes } from 'services/user-sets';
import { CommunityGiftsActionTypes } from 'services/community-gift';
import { SubscriptionsActionTypes } from 'services/subscriptions';
import { setCardsActionTypes } from 'services/set-cards';
import type { ReconcileState } from '../../types';
import { DataConciliatorActionTypes } from '../../actionTypes';

const initialState: ReconcileState = {};

export const reducer = (state: ReconcileState = initialState, action): ReconcileState => {
  const { type, error } = action;

  switch (type) {
    case DataConciliatorActionTypes.INVALIDATE_USER_SETS:
    case setCardsActionTypes.STATUS_CHANGED:
    case setCardsActionTypes.TRANSFERRED:
    case setCardsActionTypes.UPDATED:
    case setCardsActionTypes.DELETED:
    case setCardsActionTypes.CRAFTING_CONFIGURATION_UPDATED:
    case setCardsActionTypes.BULK_UPDATED:
    case SetsActionTypes.PUBLISHED:
    case SetLootsActionTypes.LOOTS_UPDATED:
    case paymentActionTypes.EXECUTED:
    case CouponActionTypes.REDEEMED:
    case SubscriptionsActionTypes.CLAIM_PACK_SUCCESS:
    case CommunityGiftsActionTypes.CLAIMED:
    case paymentActionTypes.STRIPE_ORDER_EXECUTED:
      if (error) {
        return state;
      }
      return { reconcile: true };
    case SetsActionTypes.SELECTED_SET_CHANGED: {
      if (error) {
        return state;
      }

      const { doNotReloadSet } = action.payload;
      const reconcileSet = !doNotReloadSet;

      return { reconcile: reconcileSet, invalidating: reconcileSet };
    }
    case UserSetLootsActionTypes.OPEN_SUCCESS: {
      return { reconcile: true };
    }
    case UserSetLootsActionTypes.OPEN_ERROR: {
      const { metadata } = action;
      // TODO review error codes to see when it makes sense to re fetch user sets
      if (error && metadata.businessCode !== COLLECTION_LOOT_ALREADY_OPEN_ERROR) {
        return state;
      }
      return { reconcile: true };
    }
    case UserSetsActionTypes.FETCH_REQUEST:
      return { invalidating: state.invalidating, reconcile: false };
    case UserSetsActionTypes.FETCH_SUCCESS:
    case UserSetsActionTypes.FETCH_ERROR:
      return { reconcile: false };
    default:
      return state;
  }
};
