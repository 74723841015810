import stateCreator from 'helpers/stateCreator';
import type { UserSetLootsActions, UserSetLootsState, UserSetLootsBySetId } from './types';
import { UserSetsActionTypes } from '../user-sets';
import { PageActionTypes } from '../page';
import { UserSetLootsActionTypes } from './actionTypes';
import { userSetLootsState, collectionLootsStatePropertiesAfterOpeningLoot } from './normalizer';
import { COLLECTION_LOOT_ALREADY_OPEN_ERROR } from './constants';

const getNewKeyState = (state: UserSetLootsBySetId, action: UserSetLootsActions): UserSetLootsBySetId => {
  switch (action.type) {
    case UserSetLootsActionTypes.OPEN_SUCCESS: {
      const { collectionLoots } = state;
      const { setLootsOpenedIds } = action.payload;

      return {
        ...state,
        ...collectionLootsStatePropertiesAfterOpeningLoot(collectionLoots, setLootsOpenedIds),
      };
    }
    case UserSetLootsActionTypes.OPEN_ERROR: {
      const { collectionLoots = [] } = state;

      const {
        metadata: { businessCode, amount },
      } = action;

      if (businessCode === COLLECTION_LOOT_ALREADY_OPEN_ERROR) {
        // We removed the amount of packs opened that failed to let the user keep opening packs without waiting to the response
        return {
          ...state,
          collectionLoots: collectionLoots.slice(amount - 1),
        };
      }

      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export const reducer = (state: UserSetLootsState = {}, action): UserSetLootsState => {
  const { type, payload, metadata } = action;
  switch (type) {
    case UserSetsActionTypes.FETCH_SUCCESS: {
      const { data } = payload;
      const newStateByCollectionId = {};
      data.forEach(userSet => {
        const { _id, loots, collectionLoots, collectionId, name, hasBuyableLoots } = userSet;
        const userSetIdState = state[_id] || {};
        const normalizedLootsState = userSetLootsState({
          loots,
          collectionLoots,
          collectionId,
          set: {
            _id,
            name,
          },
          hasBuyableLoots,
        });
        newStateByCollectionId[_id] = {
          ...userSetIdState,
          ...normalizedLootsState,
        };
      });

      return {
        ...state,
        ...newStateByCollectionId,
      };
    }
    case UserSetLootsActionTypes.OPEN_SUCCESS:
    case UserSetLootsActionTypes.OPEN_ERROR: {
      return stateCreator.getUpdatedStateByKey(state, action, metadata.setId, getNewKeyState);
    }
    case PageActionTypes.CHANGED:
      return {};
    default:
      return state;
  }
};
