import stateCreator from 'helpers/stateCreator';
import { PageActionTypes } from '../page';
import { PageSubscriptionStatisticsQueriedActionTypes } from './actionTypes';
import { PageSubscriptionStatisticsQueriedActions, PageSubscriptionStatisticsQueriedState } from './types';

const initialState: PageSubscriptionStatisticsQueriedState = {};

export const reducer = (
  state = initialState,
  action: PageSubscriptionStatisticsQueriedActions,
): PageSubscriptionStatisticsQueriedState => {
  switch (action.type) {
    case PageSubscriptionStatisticsQueriedActionTypes.GET_PAGE_SUBSCRIPTION_STATISTICS_QUERIED_REQUEST: {
      return {
        ...state,
        ...stateCreator.getAsyncLoadingState(state),
      };
    }

    case PageSubscriptionStatisticsQueriedActionTypes.GET_PAGE_SUBSCRIPTION_STATISTICS_QUERIED_SUCCESS: {
      const newState: PageSubscriptionStatisticsQueriedState = {
        subscriptionStatistics: { ...action.payload },
      };
      return stateCreator.getInitialAsyncState(state, newState);
    }

    case PageSubscriptionStatisticsQueriedActionTypes.GET_PAGE_SUBSCRIPTION_STATISTICS_QUERIED_ERROR: {
      return stateCreator.getAsyncErrorState(action, state);
    }

    case PageActionTypes.CHANGED: {
      return initialState;
    }

    default:
      return state;
  }
};
