import { FullLayout } from 'scenes/layout';
import loadable from 'components/dynamic-import';

const ConnectedAuctionList = loadable(() => import('./ConnectedAuctionList'));

export const PublicAuctionListPage = (): JSX.Element => {
  return (
    <FullLayout>
      <ConnectedAuctionList />
    </FullLayout>
  );
};
