import ClassNames from '@streamloots/classnames';
import Skeleton from 'ui-library/skeleton';
import theme from './validate-email.scss';

const classNames = ClassNames(theme);

export const ValidateEmailSkeleton = () => {
  return (
    <div className={classNames('validate-email__content')}>
      <div className={classNames('validate-email__panel')}>
        <div className="margin-bottom">
          <Skeleton width={'100%'} height={375} />
        </div>
        <Skeleton width={'100%'} height={20} />
        <hr />
        <div className="margin-bottom">
          <Skeleton width={'100%'} height={40} />
        </div>
        <Skeleton width={'100%'} height={40} />
      </div>
    </div>
  );
};
