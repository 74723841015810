import { AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';
import { reducer as form } from 'redux-form';
import { ReduxStore } from 'store/types';
import { reducer as achievements } from 'services/achievement';
import { reducer as adminPagePlans } from 'services/admin-page-plans';
import { reducer as adminPayouts } from 'services/admin-payouts';
import { reducer as alert } from 'services/alert';
import { reducer as alertProviders } from 'services/alert-providers';
import { reducer as app } from 'services/app';
import { reducer as auth } from 'services/auth';
import { reducer as bannedUsers } from 'services/ban-users';
import { reducer as cooldown } from 'services/cooldown';
import { reducer as culture } from 'services/culture';
import { reducer as dataConciliator } from 'services/data-conciliator';
import { reducer as craftingMode } from 'services/crafting-mode';
import { reducer as files } from 'services/files';
import { reducer as goal } from 'services/goals';
import { reducer as layout } from 'services/layout';
import { reducer as loadingState } from 'services/loadingState';
import { reducer as moderators } from 'services/moderators';
import { reducer as orders } from 'services/orders';
import { reducer as overloadedContent } from 'services/overloaded-content';
import { reducer as page } from 'services/page';
import { reducer as pageBilling } from 'services/page-billing';
import { reducer as pagePayoutMethod } from 'services/page-payout-method';
import { reducer as pages } from 'services/pages';
import { reducer as payment } from 'services/payment';
import { reducer as permissions } from 'services/permissions';
import { reducer as usersSearch } from 'services/users-search';
import { reducer as ranking } from 'services/ranking';
import { reducer as sets } from 'services/sets';
import { reducer as setCards } from 'services/set-cards';
import { reducer as setLoots } from 'services/set-loots';
import { reducer as transactions } from 'services/transactions';
import { reducer as user } from 'services/user';
import { reducer as userSets } from 'services/user-sets';
import { reducer as userSetCards } from 'services/user-set-cards';
import { reducer as userSetLoots } from 'services/user-set-loots';
import { reducer as userCollections } from 'services/user-collections';
import { reducer as coupons } from 'services/coupons';
import { reducer as invoices } from 'services/invoices';
import { reducer as multiSelection } from 'components/multi-selection';
import { reducer as predefinedCards } from 'services/predefined-cards';
import { reducer as frames } from 'services/frames';
import { reducer as onboarding } from 'services/onboarding';
import { reducer as notifications } from 'services/notifications';
import { reducer as rewind } from 'services/rewind';
import { reducer as publicPredefinedCards } from 'services/predefined-cards-public';
import { reducer as partnershipApplications } from 'services/partnership-applications';
import { reducer as voices } from 'services/voices';
import { reducer as tags } from 'services/tags';
import { reducer as adminViewer } from 'services/admin-viewer';
import { reducer as auctions } from 'services/auction';
import { reducer as timer } from 'services/timer';
import { reducer as checkout } from 'services/checkout';
import { reducer as botConfigurations } from 'services/bot-configurations';
import { reducer as pageStatistics } from 'services/page-statistics';
import { reducer as pageSubscriptionPlans } from 'services/page-subscription-plans';
import { reducer as pageSubscriptionStatistics } from 'services/page-subscription-statistics';
import { reducer as pageSubscriptionStatisticsQueried } from 'services/page-subscription-statistics-queried';
import { reducer as pageSubscriptions } from 'services/page-subscriptions';
import { reducer as promotions } from 'services/promotions';
import { reducer as pageRanking } from 'services/page-ranking';
import { reducer as activityFeed } from 'services/activity-feed';
import { reducer as communityGift } from 'services/community-gift';
import { reducer as claimableCampaign } from 'services/claimable-campaigns';
import { reducer as subscriptions } from 'services/subscriptions';
import { reducer as subscriptionGifts } from 'services/page-subscription-gifts';
import { reducer as subscriptionAlerts } from 'services/subscription-alerts';
import { reducer as marathon } from 'services/marathon';
import { reducer as subscriptionRewards } from 'services/page-subscription-rewards';
import { reducer as pageEmotes } from 'services/page-emotes';
import { reducer as emotes } from 'services/emotes';
import { reducer as emotesCollections } from 'services/emotes-collections';
import { reducer as emotesStyles } from 'services/emotes-styles';
import { reducer as pageCommunications } from 'services/page-communication';
import { reducer as viewers } from 'services/viewers';
import { reducer as originalsPublic } from 'services/originals-public';
import { reducer as donations } from 'services/donations';
import { reducer as myCreators } from 'services/my-creators';
import { reducer as pageFeed } from 'services/page-feed';
import { reducer as donationPresets } from 'services/page-donation-presets';
import { reducer as pageFeedComments } from 'services/page-feed-comments';
import { reducer as donationsDonors } from 'services/page-donation-donors';
import { reducer as communityLoveRanking } from 'services/community-love-ranking';
import { RouterState } from 'redux-first-history';

export const createRootReducer = (router: Reducer<RouterState, AnyAction>): Reducer<ReduxStore> =>
  combineReducers<ReduxStore>({
    router,
    app,
    culture,
    auth,
    user,
    page,
    pageBilling,
    pagePayoutMethod,
    alertProviders,
    cooldown,
    coupons,
    craftingMode,
    layout,
    payment,
    permissions,
    pages,
    overloadedContent,
    sets,
    userCollections,
    setLoots,
    files,
    onboarding,
    setCards,
    timer,
    notifications,
    adminPayouts,
    ranking,
    moderators,
    multiSelection,
    userSets,
    userSetCards,
    userSetLoots,
    usersSearch,
    bannedUsers,
    transactions,
    orders,
    dataConciliator,
    adminPagePlans,
    invoices,
    predefinedCards,
    frames,
    partnershipApplications,
    goal,
    rewind,
    publicPredefinedCards,
    voices,
    viewers,
    form,
    tags,
    alert,
    achievements,
    loadingState,
    adminViewer,
    auctions,
    checkout,
    botConfigurations,
    pageStatistics,
    pageSubscriptionPlans,
    pageSubscriptionStatistics,
    pageSubscriptionStatisticsQueried,
    pageSubscriptions,
    promotions,
    pageRanking,
    activityFeed,
    communityGift,
    claimableCampaign,
    subscriptions,
    subscriptionGifts,
    subscriptionAlerts,
    marathon,
    subscriptionRewards,
    pageEmotes,
    emotes,
    emotesCollections,
    emotesStyles,
    pageCommunications,
    originalsPublic,
    donations,
    myCreators,
    pageFeed,
    donationPresets,
    pageFeedComments,
    donationsDonors,
    communityLoveRanking,
  });
