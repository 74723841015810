import { useDispatch, useSelector } from 'react-redux';
import { User, UserPreferences } from 'model/indexTS';
import { useMemo } from 'react';
import { userSelectors } from './selectors';
import { userActions } from './actions';
import {
  FetchCurrentUser,
  FetchUserProfileByProvider,
  UpdateNetPromoterUserScore,
  UpdateUserEmail,
  UpdateUserPreferences,
  UpdateUserProfile,
  UpdateUserSettings,
  UpdateUserSocialMedia,
  VerifyEmail,
  DeleteUser,
} from './types';

const EMPTY_PREFERENCES = {};

//Selectors
export interface UseUserSelectors {
  showOnboarding?: boolean;
  user: User | null;
  username: string;
  userId: string;
  userPreferences: UserPreferences;
  isLoading?: boolean;
}

export const useUserSelectors = (): UseUserSelectors => {
  const { user, showOnboarding, isLoading } = useSelector(userSelectors.userState);

  return {
    user: user || null,
    userId: user?._id || '',
    username: user?.profile.username || '',
    userPreferences: user?.settings.preferences || EMPTY_PREFERENCES,
    showOnboarding,
    isLoading,
  };
};

//Actions
export interface UseUserActions {
  fetchCurrentUser: FetchCurrentUser;
  updateUserPreferences: UpdateUserPreferences;
  fetchUserProfileByProvider: FetchUserProfileByProvider;
  updateNetPromoterUserScore: UpdateNetPromoterUserScore;
  updateUserEmail: UpdateUserEmail;
  updateUserProfile: UpdateUserProfile;
  updateUserSettings: UpdateUserSettings;
  updateUserSocialMedia: UpdateUserSocialMedia;
  verifyEmail: VerifyEmail;
  deleteUser: DeleteUser;
}
export const useUserActions = (): UseUserActions => {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      fetchCurrentUser: () => dispatch(userActions.fetchCurrentUser()),
      fetchUserProfileByProvider: provider => dispatch(userActions.fetchUserProfileByProvider(provider)),
      updateNetPromoterUserScore: parameters => dispatch(userActions.updateNetPromoterUserScore(parameters)),
      updateUserEmail: email => dispatch(userActions.updateUserEmail(email)),
      updateUserPreferences: preferences => dispatch(userActions.updateUserPreferences(preferences)),
      updateUserProfile: parameters => dispatch(userActions.updateUserProfile(parameters)),
      updateUserSettings: parameters => dispatch(userActions.updateUserSettings(parameters)),
      updateUserSocialMedia: parameters => dispatch(userActions.updateUserSocialMedia(parameters)),
      verifyEmail: verificationId => dispatch(userActions.verifyEmail(verificationId)),
      deleteUser: userId => dispatch(userActions.deleteUser(userId)),
    }),
    [dispatch],
  );
};
