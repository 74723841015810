import { Redirect, Link } from 'react-router-dom';
import { Alert } from 'ui-library/alert';
import { RouteConfigComponentProps } from 'react-router-config';
import { useTranslation } from 'utils/translation';
import { pages, getUrlWithParams } from 'services/routing';
import { useUserSelectors } from 'services/user';
import { useAuthSelectors } from 'services/auth';
import Spinner from 'ui-library/loading-spinner';
import { StreamersPage } from 'scenes/streamers';
import { AuthSection } from 'scenes/auth';
import { PAGE_SECTIONS_MAPPING } from './pageSectionsMapping';

export type RedirectToSectionProps = RouteConfigComponentProps<{
  pageKey: string;
}>;

export const RedirectToSection = ({ match, location }: RedirectToSectionProps): JSX.Element => {
  const { t } = useTranslation('common');
  const { user } = useUserSelectors();
  const { isUserAuthenticated } = useAuthSelectors();

  const currentUserPageSlug = user?.page?.slug;
  const requiredKey = match?.params?.pageKey || 'user_page';
  const queryString = location?.search || '';
  let pageToRedirect = pages[PAGE_SECTIONS_MAPPING[requiredKey]] || pages.USER_PAGE;
  const requiresSlug = pageToRedirect.includes(':slug');

  if (currentUserPageSlug && requiresSlug) {
    pageToRedirect =
      getUrlWithParams(pageToRedirect, {
        slug: currentUserPageSlug,
      }) + queryString;
  }

  if (!isUserAuthenticated) {
    return (
      <div className={'margin-top margin-bottom align-center maxwidth'}>
        <Alert>{t('common:sectionsRedirection.logToAccessSection')}</Alert>
        <AuthSection componentKey="pageRedirection" location="page redirection" />
      </div>
    );
  }

  if (!currentUserPageSlug && requiresSlug) {
    return (
      <>
        <Alert type={Alert.ALERT_TYPES.INFO} className={'margin-top maxwidth'}>
          {t('common:sectionsRedirection.notRegularUserAllowed')}
          <p>
            <Link to={pages.CREATE_PAGE}>{t('common:sectionsRedirection.createAPage')}</Link>
          </p>
        </Alert>
        <StreamersPage />
      </>
    );
  }

  return (
    <div>
      <Spinner fixed ariaLabel={`Redirecting to ${pageToRedirect}`} />
      <Redirect to={pageToRedirect} />
    </div>
  );
};
