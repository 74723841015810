import cookies from 'js-cookie';
import config from 'config';
import { Response } from 'express';
import type { Experiment } from 'model/userTS';
import { SEGMENTS_NAME } from './experiments';
import { getActiveExperiments, getExperimentsThatAreCurrentlyAssigned, generateSegment } from './cookieManagerUtils';

const EXPERIMENTS_COOKIE = 'userExperiments';
const DEFAULT_EXPIRE_DAYS = 90;

const produceExpireDate = () => {
  const expireDate = new Date();
  expireDate.setDate(expireDate.getDate() + DEFAULT_EXPIRE_DAYS);
  return expireDate;
};

function getActiveExperimentsFromUserExperiments(userExperiments: Experiment[] = []) {
  const activeExperimentNames = getActiveExperiments().map(experiment => experiment.name);

  return userExperiments.filter(experiment => activeExperimentNames.includes(experiment.name));
}

const getExperimentsFromCookie = (): Array<Record<string, string>> => cookies.get(EXPERIMENTS_COOKIE);

const getExperimentsFromRequest = (reqCookies: Record<string, string>): Array<any> => {
  try {
    return reqCookies[EXPERIMENTS_COOKIE] ? JSON.parse(reqCookies[EXPERIMENTS_COOKIE]) : [];
  } catch (err) {
    return [];
  }
};

const setExperimentsCookie = (experiments: Experiment[]) => {
  if (!experiments) {
    return;
  }

  const activeExperiments = getActiveExperimentsFromUserExperiments(experiments);

  cookies.set(EXPERIMENTS_COOKIE, JSON.stringify(activeExperiments), {
    expires: produceExpireDate(),
    domain: config.streamloots.domain,
  });
};

//FixMe: We return here createdAt as a number instead of a string
function getActiveExperimentsNotInCookie(
  userExperiments: Experiment[],
): Array<
  Omit<Experiment, 'serverConfirmationAt' | 'createdAt'> & {
    createdAt: number;
  }
> {
  const userExperimentsNames = userExperiments.map(exp => exp.name);
  const experimentsToBeAssigned = getExperimentsThatAreCurrentlyAssigned().filter(
    exp => !userExperimentsNames.includes(exp.name),
  );

  const newExperiments = experimentsToBeAssigned.map(exp => {
    const segment = generateSegment(exp.segmentsNumber);

    return {
      name: exp.name,
      createdAt: Date.now(),
      segment: SEGMENTS_NAME[segment],
    };
  });

  return newExperiments;
}

const getAllExperiments = (reqCookies: Record<string, string>): Experiment[] => {
  const experimentsInCookie = getExperimentsFromRequest(reqCookies);
  const experimentsNotInCookie = getActiveExperimentsNotInCookie(experimentsInCookie);

  return [...experimentsInCookie, ...experimentsNotInCookie];
};

function initCookie(reqCookies: Record<string, string>, res: Response): Experiment[] {
  const allExperiments = getAllExperiments(reqCookies);
  const activeExperiments = getActiveExperimentsFromUserExperiments(allExperiments);

  res.cookie(EXPERIMENTS_COOKIE, JSON.stringify(activeExperiments), {
    expires: produceExpireDate(),
    domain: config.streamloots.domain,
  });

  return allExperiments;
}

export default {
  getExperimentsFromCookie,
  setExperimentsCookie,
  initCookie,
  getActiveExperiments,
  getActiveExperimentsFromUserExperiments,
};
