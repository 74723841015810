import { Currency, TransactionStatus, TransactionType, PaymentMethods } from '@streamloots/streamloots-types';
import { PayDays } from 'model/pageTS';

export enum TransactionSource {
  PAYMENT = 'PAYMENT',
}

export enum AmountDetailsType {
  SUBTOTAL = 'SUBTOTAL',
  PAYMENT_PROCESSOR_FEE = 'PAYMENT_PROCESSOR_FEE',
  TAX = 'TAX',
  SERVICE_FEE = 'SERVICE_FEE',
}

export enum PaymentOwnerType {
  ASSOCIATE = 'ASSOCIATE',
  SELLER = 'SELLER',
}

export interface AmountDetailsInfo {
  type: AmountDetailsType;
  currency: Currency;
  amount: number;
  amountDetails?: Array<AmountDetailsInfo>;
}

export interface Transaction {
  _id: string;
  amount: number;
  amountDetails: Array<AmountDetailsInfo>;
  createdAt: string;
  currency: Currency;
  modifiedAt: string;
  net: number;
  sourceId: string;
  sourceType: TransactionSource;
  status: TransactionStatus;
  type: TransactionType;
}

export interface TransactionRow {
  _id: string;
  createdAt: string;
  gross: string;
  amount: number;
  net: string;
  paymentCommission: string;
  serviceFee: string;
  sourceId: string;
  sourceType: TransactionSource;
  paymentMethod: PaymentMethods;
  status: TransactionStatus;
  type: TransactionType;
  total: string;
}

export interface SummaryInfo {
  amount: number;
  currency: Currency;
}

export interface BalanceInfo {
  available: SummaryInfo;
  confirmed: SummaryInfo;
  pending: SummaryInfo;
  hold: SummaryInfo;
}

export interface BalanceInfoDetails {
  available?: Array<SummaryInfo>;
  confirmed?: Array<SummaryInfo>;
  pending?: Array<SummaryInfo>;
  hold?: Array<SummaryInfo>;
}

export interface TransactionsPaymentConfiguration {
  pageId: string;
  payDays: PayDays[];
}

export interface TransactionsSummaryData {
  balance: BalanceInfo;
  balanceDetails: BalanceInfoDetails;
  currentMonthBalance: BalanceInfo;
  previousMonthsBalance: BalanceInfo;
}

export interface BillingPeriodInfo {
  total: string;
  totalAmount: number;
  pending: string;
}

export interface TransactionsSummary {
  currentMonthBalance: BillingPeriodInfo;
  previousMonthsBalance: BillingPeriodInfo;
  paymentConfiguration: {
    payDays: PayDays[];
  };
  hold?: string;
}

export interface PayoutMethodInfo {
  account: string;
  provider: 'paypal' | 'bankTransfer'; // TODO review
}

// TODO Move following types to types project
export interface TRANSACTIONS_GET_RESPONSE_SUMMARY {
  balance: BalanceInfo;
  balanceDetails: BalanceInfoDetails;
  currentMonthBalance: BalanceInfo;
  currentMonthBalanceDetails: BalanceInfoDetails;
  previousMonthsBalance: BalanceInfo;
  previousMonthsBalanceDetails: BalanceInfo;
  paymentConfiguration: TransactionsPaymentConfiguration;
}

export interface TRANSACTIONS_GET_RESPONSE {
  summary: TRANSACTIONS_GET_RESPONSE_SUMMARY;
  data: Transaction[];
  pagination: {
    cursors?: {
      after: string;
    };
    next?: string;
  };
}
