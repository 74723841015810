import _ from 'lodash';
import stateCreator from 'helpers/stateCreator';
import type { ActionError, CancelToken, FixMe } from 'types/indexTS';
import type {
  PaginationRequestConfiguration,
  PaginationResponse,
  PaginationState,
  PaginationStateNormalizerConfiguration,
} from './typesTS';
import { PaginationApiResponse } from './typesTS';

const DEFAULT_CONFIGURATION: PaginationStateNormalizerConfiguration<FixMe> = {
  initialQuery: {
    limit: 50,
  },
  queryNormalizer: query => query || {},
  includeNew: true,
  timeProperty: 'createdAt',
};

const getConfiguration = <Configuration extends PaginationStateNormalizerConfiguration<FixMe>>(
  configuration: Configuration,
): Configuration => ({
  ...DEFAULT_CONFIGURATION,
  ...configuration,
});

const getLastDataFetchTime = (data: Array<FixMe>, timeProperty): number => {
  if (!data || data.length === 0) {
    return new Date().getTime();
  }

  const lastItem = data[0];
  const lastItemTime = lastItem[timeProperty];
  if (!lastItemTime) {
    return 0;
  }

  return Date.now() + 1;
};

const getInitial = <Configuration extends PaginationStateNormalizerConfiguration<FixMe>>(
  configuration: Configuration,
): FixMe => {
  const { initialQuery } = getConfiguration(configuration);
  return {
    data: null,
    query: initialQuery,
  };
};

const getReceived = (
  response: PaginationResponse<FixMe, FixMe>,
  configuration: PaginationStateNormalizerConfiguration<FixMe>,
  existingData: FixMe[] = [],
): FixMe => {
  const { data, pagination, query } = response;
  const { includeNew, timeProperty, queryNormalizer } = getConfiguration(configuration);

  const receivedState: FixMe = {
    data: [...data],
    pagination,
    lastRequestTimestamp: new Date().getTime(),
    fetchingCancelToken: null,
    fetchingNextCancelToken: null,
  };

  if (query) {
    receivedState.query = queryNormalizer ? queryNormalizer(query) : query;
  }

  if (includeNew) {
    receivedState.lastDataFetchTime = getLastDataFetchTime(data, timeProperty);
    receivedState.newItems = 0;
  }

  if (existingData) {
    receivedState.data = _.uniqBy([...data, ...existingData], '_id');
  }

  return stateCreator.getInitialAsyncState(receivedState);
};

const getReceivedError = <State extends PaginationState<FixMe, FixMe>>(
  action: ActionError<string, FixMe>,
  currentState: State,
): State => stateCreator.getAsyncErrorState(action, currentState);

const getRequest = <
  State extends PaginationState<FixMe, FixMe>,
  RequestConfiguration extends PaginationRequestConfiguration<FixMe>,
  Configuration extends PaginationStateNormalizerConfiguration<FixMe>
>(
  currentState: State,
  requestConfiguration: RequestConfiguration,
  configuration: Configuration,
): State => {
  const { queryNormalizer } = getConfiguration(configuration);
  const { query, resetLoadedData, cancelToken } = requestConfiguration;
  return stateCreator.getAsyncLoadingState(currentState as FixMe, {
    query: queryNormalizer ? queryNormalizer(query) : query,
    data: resetLoadedData ? null : currentState.data,
    invalidateData: false,
    fetchingCancelToken: !cancelToken ? null : { ...cancelToken },
  });
};

const getFetchingNextItems = <State extends PaginationState<FixMe, FixMe>>(
  currentState: State,
  cancelToken?: CancelToken,
): State =>
  stateCreator.getFetchingNextItemsState(currentState as FixMe, {
    fetchingNextCancelToken: !cancelToken ? null : { ...cancelToken },
  });

const getNextItemsReceived = <
  State extends PaginationState<FixMe, FixMe>,
  Response extends PaginationApiResponse<FixMe>
>(
  currentState: State,
  response: Response,
): State => {
  const { data, pagination } = response;
  const concatenatedData = _.concat(currentState.data, data);
  // If we dont cast as any, the second arguments doesnt work
  return stateCreator.getInitialAsyncState(currentState as FixMe, {
    pagination,
    data: _.uniqBy(concatenatedData, '_id'),
    fetchingNextCancelToken: null,
  });
};

const getNextReceivedError = <State extends PaginationState<FixMe, FixMe>>(currentState: State): State =>
  stateCreator.getInitialAsyncState(currentState);

const getFetchingNewItems = <State extends PaginationState<FixMe, FixMe>>(
  currentState: State,
  cancelToken: CancelToken,
): State => ({
  ...currentState,
  fetchingNewCancelToken: !cancelToken ? null : { ...cancelToken },
});

const getNewReceived = <State extends PaginationState<FixMe, FixMe>, Response extends PaginationResponse<FixMe, FixMe>>(
  currentState: State,
  response: Response,
): State => {
  const { data } = response;
  return {
    ...currentState,
    newItems: data.length,
    newData: [...data],
    fetchingNewCancelToken: null,
  };
};

const getShowNew = <
  State extends PaginationState<FixMe, FixMe>,
  Configuration extends PaginationStateNormalizerConfiguration<FixMe>
>(
  currentState: State,
  configuration: Configuration,
): State => {
  const { timeProperty } = getConfiguration(configuration);
  const newState = { ...currentState };
  const newData = _.concat(currentState.newData, currentState.data);
  newState.data = _.uniqBy(newData, '_id');
  newState.newItems = 0;
  newState.newData = null;
  newState.lastDataFetchTime = getLastDataFetchTime(newState.data, timeProperty);
  return newState;
};

const getNewAndShow = <
  State extends PaginationState<FixMe, FixMe>,
  Response extends PaginationResponse<FixMe, FixMe>,
  Configuration extends PaginationStateNormalizerConfiguration<FixMe>
>(
  currentState: State,
  response: Response,
  configuration: Configuration,
): State => {
  if (response.data.length === 0) {
    return currentState;
  }
  const stateWithNewData = getNewReceived(currentState, response);
  return getShowNew(stateWithNewData, configuration);
};

const getResetLoadedData = <State extends PaginationState<FixMe, FixMe>>(currentState: State): State =>
  stateCreator.getInitialAsyncState(currentState as FixMe, {
    lastDataFetchTime: 0,
    data: null,
  });

export default {
  getInitial,
  getReceived,
  getReceivedError,
  getRequest,
  getFetchingNextItems,
  getNextItemsReceived,
  getNextReceivedError,
  getFetchingNewItems,
  getNewAndShow,
  getNewReceived,
  getShowNew,
  getResetLoadedData,
};
