import ClassNames from '@streamloots/classnames';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'utils/translation';
import Panel from 'ui-library/panel';
import { Input, rules } from 'ui-library/forms';
import Icon from 'ui-library/icons/Icons';
import Form from 'components/forms/indexTS';
import { SocialMediaFormValues } from './types';
import theme from './social-media.scss';

const classNames = ClassNames(theme);

type SocialMediaFormProps = InjectedFormProps<SocialMediaFormValues>;

const BaseSocialMediaForm = ({ ...props }: SocialMediaFormProps) => {
  const { t } = useTranslation('userProfile');
  return (
    <Panel
      title={t('userProfile:socialMedia.title')}
      description={t('userProfile:socialMedia.description')}
      className="margin-bottom-x2"
    >
      <Form
        button={t('userProfile:socialMedia.submit')}
        successMessage={t('userProfile:socialMedia.submitSuccess')}
        {...props}
      >
        <Input
          id="twitterAcount"
          label={
            <>
              <Icon id="twitter" className={classNames('social-media-form__icon')} />
              {t('userProfile:socialMedia.twitterAccount')}
            </>
          }
          name="twitterAcount"
          type="text"
          placeholder={t('userProfile:socialMedia.twitterAccount')}
          validate={rules.isTwitterUrl}
        />
        <Input
          id="discordServer"
          label={
            <>
              <Icon id="discord" className={classNames('social-media-form__icon')} />
              {t('userProfile:socialMedia.discordServer')}
            </>
          }
          name="discordServer"
          type="text"
          placeholder={t('userProfile:socialMedia.discordServer')}
          validate={rules.isDiscordUrl}
        />
      </Form>
    </Panel>
  );
};

export const SocialMediaForm = reduxForm<SocialMediaFormValues>({
  form: 'socialMedia',
})(BaseSocialMediaForm);
