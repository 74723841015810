import ClassNames from '@streamloots/classnames';
import theme from './form.scss';

interface FormElementAdditionalInfoProps {
  error?: string;
  maxLength?: number;
  value?: string | number;
  helperText?: string;
  inputInfoClassName?: string;
}

const classNames = ClassNames(theme);

const FormElementAdditionalInfo = ({
  maxLength,
  error,
  value,
  helperText,
  inputInfoClassName = '',
}: FormElementAdditionalInfoProps): JSX.Element | null => {
  if (!maxLength && !error && !helperText) {
    return null;
  }

  return (
    <>
      <div
        className={classNames({
          input__info: true,
          [inputInfoClassName]: Boolean(inputInfoClassName),
        })}
      >
        {error && <p className={classNames('input__info__error')}>{error}</p>}
        {maxLength && (
          <div className={classNames('input__info__length')}>{`${
            typeof value === 'string' ? value.length : 0
          }/${maxLength}`}</div>
        )}
      </div>

      {helperText && (
        <div className={classNames('input__info')}>
          <p className={classNames('input__info__hint')}>{helperText}</p>
        </div>
      )}
    </>
  );
};

export default FormElementAdditionalInfo;
