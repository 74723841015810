import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import Icon from 'ui-library/icons';
import theme from './menu-content-components.scss';

const classNames = ClassNames(theme);

type MenuSectionHeaderProps = {
  className?: string;
  icon?: string;
  children: React.ReactNode;
};

export const MenuSectionHeader = ({ children, className = '', icon }: MenuSectionHeaderProps): JSX.Element => {
  return (
    <div
      className={classNames({
        [className]: Boolean(className),
        menu__header: true,
      })}
    >
      {icon && <Icon id={icon} />} {children}
    </div>
  );
};
