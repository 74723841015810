import config from '../../config';
import { Transformation, TransformationOptions } from './Transformation';
import CloudinaryTransformation from './CloudinaryTransformation';
import WsrvTransformation from './WsrvTransformation';

const DEFAULT_IMAGE_TRANSFORMATION_OPTIONS: TransformationOptions = {
  bestFormat: false,
  quality: 90,
  scale: true,
};

function optimizeResource(url: string, transformationOptions = {}, forceCloudinary = false): string {
  let transformationStrategy: Transformation | undefined;

  if (config.cdn.CDN_WSRV_ENABLED) {
    transformationStrategy = new WsrvTransformation(url, transformationOptions);
  }

  if (config.cdn.CDN_CLOUDINARY_ENABLED && (forceCloudinary || !transformationStrategy?.isSupported())) {
    transformationStrategy = new CloudinaryTransformation(url, transformationOptions);
  }

  if (!transformationStrategy) {
    return url;
  }

  return transformationStrategy.toUrl();
}

function optimizeImage(
  url: string,
  transformationOptions: TransformationOptions = {},
  forceCloudinary?: boolean,
): string {
  return optimizeResource(
    url,
    {
      ...DEFAULT_IMAGE_TRANSFORMATION_OPTIONS,
      ...transformationOptions,
    },
    forceCloudinary,
  );
}

function optimizeVideo(url: string, transformationOptions: TransformationOptions = {}): string {
  return optimizeResource(url, transformationOptions);
}

export default {
  optimizeImage,
  optimizeResource,
  optimizeVideo,
};
