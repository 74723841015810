import type { FunctionComponent } from 'react';
import { pages } from 'services/routing';
import ILLUSTRATIONS from 'constants/illustrations';
import { withTranslation, TranslateInterface } from 'utils/translation';
import { ValidateEmailPanel } from './ValidateEmailPanel';

const EmailNotUpdatedPanel = ({ t }: TranslateInterface) => {
  return (
    <ValidateEmailPanel
      title={t('validateEmail.error.title')}
      description={t('validateEmail.error.descriptionNotUpdatedEmail')}
      img={ILLUSTRATIONS.LOGO_ERROR_STARS}
      url={pages.MY_PROFILE}
      buttonTxt={t('validateEmail.goToProfile')}
    />
  );
};

export default withTranslation('userProfile')(EmailNotUpdatedPanel) as FunctionComponent;
