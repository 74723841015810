import { STATIC_FILES_URL_PATH } from './images';

function buildCraftingModeUrl(fileName: string) {
  return `${STATIC_FILES_URL_PATH}crafting-mode/${fileName}`;
}

export default [
  buildCraftingModeUrl('blue-gem.png'),
  buildCraftingModeUrl('amber-gem.png'),
  buildCraftingModeUrl('green-gem.png'),
  buildCraftingModeUrl('red-gem.png'),
  buildCraftingModeUrl('navy-blue-gem.png'),
  buildCraftingModeUrl('pink-gem.png'),
  buildCraftingModeUrl('fragments.png'),
  buildCraftingModeUrl('lava-fragments.png'),
  buildCraftingModeUrl('poo-gem.png'),
];
