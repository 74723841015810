import type { ReactNode, ComponentType } from 'react';
import type { ReactAlertOptions } from './ReactAlertProvider';
import { useReactAlert } from './ReactAlertProvider';

export interface WithAlertProviderProps {
  showErrorAlert: (message: ReactNode, options?: ReactAlertOptions) => void;
  showInfoAlert: (message: ReactNode, options?: ReactAlertOptions) => void;
  showSuccessAlert: (message: ReactNode, options?: ReactAlertOptions) => void;
  showWarningAlert: (message: ReactNode, options?: ReactAlertOptions) => void;
}

// the , makes the compiler understanding that is a generic
const withAlertProvider = <ComponentProps,>(WrappedComponent: ComponentType<ComponentProps>) => {
  return (
    props: Omit<
      ComponentProps,
      'showAlert' | 'showErrorAlert' | 'showInfoAlert' | 'showSuccessAlert' | 'showWarningAlert'
    >,
  ) => {
    const alert = useReactAlert();
    return (
      <WrappedComponent
        showInfoAlert={alert.showInfo}
        showErrorAlert={alert.showError}
        showWarningAlert={alert.showWarning}
        showSuccessAlert={alert.showSuccess}
        {...(props as ComponentProps)}
      />
    );
  };
};

export default withAlertProvider;
