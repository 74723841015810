import stateCreator from 'helpers/stateCreator';
import { PageActionTypes } from 'services/page';
import { BotConfigurationsActionTypes } from './actionTypes';
import { BotConfigurationsActions, BotConfigurationsState } from './types';

export const INITIAL_BOT_CONFIGURATIONS_STATE: BotConfigurationsState = {
  chatBotConfiguration: null,
};

export const reducer = (
  state: BotConfigurationsState = INITIAL_BOT_CONFIGURATIONS_STATE,
  action: BotConfigurationsActions,
): BotConfigurationsState => {
  switch (action.type) {
    case BotConfigurationsActionTypes.GET_BOT_CONFIGURATION_REQUEST: {
      return stateCreator.getAsyncLoadingState(state);
    }
    case BotConfigurationsActionTypes.GET_BOT_CONFIGURATION_SUCCESS: {
      return stateCreator.getInitialAsyncState(state, {
        chatBotConfiguration: action.payload,
      });
    }
    case BotConfigurationsActionTypes.GET_BOT_CONFIGURATION_ERROR: {
      return stateCreator.getAsyncErrorState(action, state, INITIAL_BOT_CONFIGURATIONS_STATE);
    }
    case PageActionTypes.CHANGED: {
      return INITIAL_BOT_CONFIGURATIONS_STATE;
    }
    default:
      return state;
  }
};
