import { ReduxStore } from 'types/indexTS';
import { pageSelectors } from 'services/page';
import { CurrentPageData } from './types';

let _isCurrentUserOwner = false;
let _currentPageData: CurrentPageData = null;

export const storeObserver = (state: ReduxStore): void => {
  if (typeof window === 'undefined') {
    return;
  }
  _isCurrentUserOwner = pageSelectors.isOwner(state);
  _currentPageData = {
    pageId: pageSelectors.pageId(state),
    ownerId: pageSelectors.ownerId(state) || '',
    sellerId: pageSelectors.sellerId(state),
    pageType: pageSelectors.type(state),
    isPublished: pageSelectors.publishedAt(state) !== null,
    language: pageSelectors.language(state),
    slug: pageSelectors.slug(state),
    provider: pageSelectors.ownerPrimaryAuthProvider(state),
  };
};

export default {
  isCurrentUserOwner: (): boolean => _isCurrentUserOwner,
  getPageSlug: (): string => _currentPageData?.slug ?? '',
  getPageData: (): CurrentPageData => _currentPageData,
};
