import { ReactNode } from 'react';
import ClassNames from '@streamloots/classnames';
import { ButtonIcon } from 'ui-library/button';
import Tooltip, { TooltipPlacementWithStrings } from 'ui-library/tooltip';
import theme from './tooltip-help.scss';

const classNames = ClassNames(theme);

export type TooltipHelpIndicatorProps = {
  className?: string;
  tooltip: ReactNode;
  inputLabel?: boolean;
  flex?: boolean;
  tooltipPosition?: TooltipPlacementWithStrings;
  ariaLabel?: string;
  onMouseEnter?: () => void;
  asLink?: boolean;
};

export const TooltipHelpIndicatorIcon = ({
  className = '',
  tooltipPosition = 'top',
  tooltip,
  inputLabel,
  ariaLabel,
  onMouseEnter,
  asLink,
  flex,
  ...props
}: TooltipHelpIndicatorProps): JSX.Element => {
  return (
    <Tooltip placement={tooltipPosition} tooltip={tooltip}>
      <ButtonIcon
        className={classNames({
          [className]: className,
          'tooltip-help': true,
          'tooltip-help--flex': flex,
          'tooltip-help--input': inputLabel || asLink,
        })}
        icon="help-circle"
        asLink={inputLabel || asLink}
        ariaLabel={ariaLabel}
        onMouseEnter={onMouseEnter}
        role="tooltip"
        {...props}
      />
    </Tooltip>
  );
};
