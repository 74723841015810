import { PageModules, UserSet } from 'model/indexTS';
import { UserSetLootsBySetId } from 'services/user-set-loots';

export const setIdToView = (
  modules: PageModules | undefined,
  marathonDefaultSet: UserSet | null,
  selectedSetIdRedux: string,
): string => {
  if (!modules) {
    return selectedSetIdRedux;
  }

  if (modules && !modules.cards.enabled && modules.marathons.enabled && marathonDefaultSet) {
    return marathonDefaultSet._id;
  }

  return selectedSetIdRedux;
};

export const userSetsToView = (
  userSets: Array<UserSet> | undefined,
  modules: PageModules | undefined,
  marathonSet: UserSet | null,
): Array<UserSet> => {
  if (!modules) {
    return [];
  }

  if (!modules.cards.enabled) {
    return marathonSet == null ? [] : [marathonSet];
  }

  return userSets || [];
};

export const userSetsLootsToView = (
  marathonSetId: string,
  modules: PageModules | undefined,
  marathonDefaultSet: UserSet | null,
  setLoots: Array<UserSetLootsBySetId>,
): Array<UserSetLootsBySetId> => {
  if (!modules) {
    return setLoots;
  }
  if (modules && !modules.cards.enabled && modules.marathons.enabled && marathonDefaultSet) {
    const setLoot = setLoots.find(setLootItem => setLootItem.set._id === marathonSetId);
    return setLoot == null ? [] : [setLoot];
  }

  return setLoots;
};
