import type { ChangeEvent } from 'react';
import ClassNames from '@streamloots/classnames';
import { InputText, InputTextProps } from 'ui-library/form-elements';
import Icon from 'ui-library/icons';
import { ButtonIcon } from 'ui-library/button';
import useDebounce from 'hooks/use-debounce';
import theme from './search-input.scss';

const classNames = ClassNames(theme);

export interface SearchInputProps
  extends Pick<
    InputTextProps,
    | 'id'
    | 'className'
    | 'placeholder'
    | 'onFocus'
    | 'onBlur'
    | 'onKeyDown'
    | 'error'
    | 'disabled'
    | 'name'
    | 'required'
    | 'value'
    | 'label'
  > {
  inputClassName?: string;
  onSearch: (value: string) => void;
  onChange: (value: string) => void;
  showClean?: boolean;
  showSearchIcon?: boolean;
  cleanFieldButtonAriaLabel?: string;
  debounceDelay?: number;
  noMargin?: boolean;
}

export const SearchInput = ({
  onSearch,
  onChange,
  id,
  placeholder,
  onBlur,
  onFocus,
  onKeyDown,
  className = '',
  inputClassName,
  showSearchIcon = true,
  showClean = true,
  error,
  value,
  disabled,
  name,
  label,
  cleanFieldButtonAriaLabel = 'Clean value',
  debounceDelay = 300,
  noMargin = true,
}: SearchInputProps): JSX.Element => {
  const handleSearch = useDebounce(onSearch, debounceDelay);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    onChange(newValue);

    handleSearch(newValue);
  };

  const handleClean = () => {
    handleSearch('');
    onChange('');
  };

  return (
    <div
      className={classNames({
        [className]: Boolean(className),
        'search-input': true,
        'search-input--no-margin': noMargin,
        'search-input--icon': showSearchIcon,
        'search-input--label': !!label,
        'search-input--clean': showClean,
      })}
    >
      <InputText
        id={id}
        className={inputClassName}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onChange={handleChange}
        autoComplete="off"
        value={value}
        disabled={disabled}
        name={name}
        error={error}
        label={label}
      />
      {showSearchIcon && <Icon id="search" className={classNames('search-input__icon')} />}
      {value && showClean && (
        <ButtonIcon
          className={classNames('search-input__clean')}
          icon="circle-close"
          ariaLabel={cleanFieldButtonAriaLabel}
          onClick={handleClean}
          asLink
        />
      )}
    </div>
  );
};
