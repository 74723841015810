import React from 'react';
import { AuthProviders } from '@streamloots/streamloots-types';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import memoize from 'memoize-one';
import _ from 'lodash';
import type { PagesQuery, PagesStatus } from 'services/pages';
import { User } from 'model/indexTS';
import { withTranslation, TranslateInterface } from 'utils/translation';
import { pagesActions, pagesSelectors, FetchPages } from 'services/pages';
import { pageSelectors } from 'services/page';
import { cultureSelectors } from 'services/culture';
import { userSelectors } from 'services/user';
import { MenuSectionHeader } from '../menu-content-components';
import { StreamerOnMenu } from './StreamerOnMenu';
import { StreamerOnMenuSkeleton } from './StreamerOnMenuSkeleton';
import theme from './streamer-list.scss';

interface StreamerListProps extends PagesStatus, TranslateInterface {
  fetchStreamers: FetchPages;
  language: string;
  slug: string;
  user: User | null;
}

const STREAMERS_ON_MENU_COMPONENT_KEY = 'streamersListOnMenu';
const MAX_STREAMERS_TO_LIST = 6;

class StreamerList extends React.Component<StreamerListProps> {
  getStreamers = memoize((streamers, slug) => {
    if (!streamers) {
      return null;
    }
    const filteredStreamers = streamers.filter(streamer => streamer.slug !== slug);

    return filteredStreamers.slice(0, MAX_STREAMERS_TO_LIST);
  });

  componentDidMount() {
    const { fetchStreamers, user, data, isLoading, language } = this.props;

    if (Array.isArray(data) || isLoading) {
      return;
    }

    if (!user || !user.primaryAuthProvider) {
      this.fetchStreamersByLanguage(language);
      return;
    }

    const { primaryAuthProvider } = user;

    const query: PagesQuery = {
      limit: MAX_STREAMERS_TO_LIST + 1,
      discover:
        primaryAuthProvider !== AuthProviders.MIXER // Mixer doesn't work anymore
          ? primaryAuthProvider
          : AuthProviders.TWITCH,
    };

    // Twitch shows people who you follow on these platforms so we don't want to filter by languages
    if (primaryAuthProvider !== AuthProviders.TWITCH) {
      query.languages = language;
    }

    fetchStreamers(STREAMERS_ON_MENU_COMPONENT_KEY, query).then(response => {
      if (response.error) {
        return;
      }

      const { data: resultData } = response.payload;

      if (resultData.length > 2) {
        return;
      }
      this.fetchStreamersByLanguage(language);
    });
  }

  fetchStreamersByLanguage(language: string) {
    const { fetchStreamers } = this.props;
    const secondQuery = {
      limit: MAX_STREAMERS_TO_LIST + 1,
      languages: language,
    };
    fetchStreamers(STREAMERS_ON_MENU_COMPONENT_KEY, secondQuery);
  }

  renderStreamers() {
    const { data, user, slug, t } = this.props;
    const provider = (user && user.primaryAuthProvider) || 'none';

    const streamers = this.getStreamers(data, slug);

    if (!Array.isArray(data)) {
      return (
        <React.Fragment>
          <StreamerOnMenuSkeleton />
          <StreamerOnMenuSkeleton />
          <StreamerOnMenuSkeleton />
          <StreamerOnMenuSkeleton />
          <StreamerOnMenuSkeleton />
        </React.Fragment>
      );
    }

    return streamers.map(streamer => (
      <StreamerOnMenu key={streamer._id} streamer={streamer} t={t} provider={provider} />
    ));
  }

  render() {
    const { t, error, data } = this.props;

    if (error || (data && data.length === 0)) {
      return null;
    }

    return (
      <div className={theme.menu__streamers}>
        <MenuSectionHeader icon="achievement_star">{t('common:menu.recommendedStreamers')}</MenuSectionHeader>
        {this.renderStreamers()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const preferences = userSelectors.userPreferences(state);
  const language = cultureSelectors.language(state);
  return {
    language: _.get(preferences, 'streamersRecommendations.language', language),
    user: userSelectors.user(state),
    ...pagesSelectors.pagesStatus(state, STREAMERS_ON_MENU_COMPONENT_KEY),
    slug: pageSelectors.slug(state),
  };
};

export default compose<React.ComponentType>(
  connect(mapStateToProps, {
    fetchStreamers: pagesActions.fetchPages,
  }),
  withTranslation('common'),
)(StreamerList);
