export enum OrdersActionTypes {
  DOWNLOAD_REQUEST = 'orders/DOWNLOAD_REQUEST',
  DOWNLOAD_ERROR = 'orders/DOWNLOAD_ERROR',
  DOWNLOAD_SUCCESS = 'orders/DOWNLOAD_SUCCESS',
  RECEIVED = 'order-list/RECEIVED',
  REQUEST = 'order-list/REQUEST',
  SHOW_NEW = 'order-list/SHOW_NEW',
  FILTER = 'order-list/FILTER',
  NEXT_RECEIVED = 'order-list/NEXT_RECEIVED',
  NEXT_REQUEST = 'order-list/NEXT_REQUEST',
  REQUEST_NEW = 'order-list/REQUEST_NEW',
  NEW_RECEIVED = 'order-list/NEW_RECEIVED',

  // Fetch order
  FETCH_ORDER_REQUEST = 'orders/FETCH_ORDER_REQUEST',
  FETCH_ORDER_SUCCESS = 'orders/FETCH_ORDER_SUCCESS',
  FETCH_ORDER_ERROR = 'orders/FETCH_ORDER_ERROR',

  // Create order
  CREATE_ORDER_REQUEST = 'orders/CREATE_ORDER_REQUEST',
  CREATE_ORDER_SUCCESS = 'orders/CREATE_ORDER_SUCCESS',
  CREATE_ORDER_ERROR = 'orders/CREATE_ORDER_ERROR',

  // Execute PayPal payment
  EXECUTE_PAYPAL_REQUEST = 'orders/EXECUTE_PAYPAL_REQUEST',
  EXECUTE_PAYPAL_SUCCESS = 'orders/EXECUTE_PAYPAL_SUCCESS',
  EXECUTE_PAYPAL_ERROR = 'orders/EXECUTE_PAYPAL_ERROR',
}
