import { AuthProviders, Currency, PageType, PayoutMethods } from '@streamloots/streamloots-types';
import { createSelector } from 'reselect';
import staticAWS from 'constants/aws';
import type {
  BannedFromPageInfo,
  ModulesPlans,
  PageCustomization,
  PageMetadata,
  PageModules,
  PageRankingVisibility,
  PageUsageStatistics,
} from 'model/indexTS';
import { PaymentProcessors } from 'model/indexTS';
import { HyperwalletConfigurationOutput, PageState, PageStatus } from './types';

const page = (state): PageState => state.page;

const pageId = createSelector([page], (pageInfo: PageState): string => pageInfo._id || '');

const slug = createSelector([page], (pageInfo: PageState): string => pageInfo.slug);

const type = createSelector([page], (pageInfo: PageState): PageType | undefined => pageInfo.type);

const level = createSelector([page], (pageInfo: PageState): number => pageInfo.level || 1);

const modulesPlans = createSelector(
  [page],
  (pageInfo: PageState): ModulesPlans | null => pageInfo.modulesPlans || null,
);

const sellerId = createSelector([page], (pageInfo: PageState): string => pageInfo.sellerId || '');

const ownerPrimaryAuthProvider = createSelector(
  [page],
  (pageInfo: PageState): AuthProviders | undefined => pageInfo.owner?.primaryAuthProvider,
);

const ownerId = createSelector([page], (pageInfo: PageState): string | undefined => pageInfo.ownerId || '');

const currency = createSelector([page], (pageInfo: PageState): Currency => pageInfo.currency || Currency.USD);

const banInfo = createSelector([page], (pageInfo: PageState): BannedFromPageInfo | undefined => pageInfo.ban);

const usageStatistics = createSelector(
  [page],
  (pageInfo: PageState): PageUsageStatistics | undefined => pageInfo.usageStatistics,
);

const isAffiliate = createSelector([page], (pageInfo: PageState): boolean => Boolean(pageInfo.isAffiliate));

const isPartner = createSelector([page], (pageInfo: PageState): boolean => Boolean(pageInfo.isPartner));

const isNotFoundError = createSelector([page], (pageInfo: PageState): boolean => {
  const { error } = pageInfo;
  return Boolean(error && error.code === 404);
});

const paymentProcessors = createSelector(
  [page],
  (pageInfo: PageState): Array<PaymentProcessors> => pageInfo.paymentProcessors || [],
);

const rankingVisibility = createSelector(
  [page],
  (pageInfo: PageState): PageRankingVisibility | undefined => pageInfo.rankingVisibility,
);

const timezone = createSelector([page], (pageInfo: PageState): string | undefined => pageInfo.timezone);

const pageStatus = createSelector(
  [page],
  (pageInfo: PageState): PageStatus => {
    const { isLoaded, isLoading, error } = pageInfo;
    return { isLoaded, isLoading, error };
  },
);

const customization = createSelector(
  [page],
  (pageInfo: PageState): PageCustomization | null => pageInfo.customization || null,
);

const headerImageUrl = createSelector(
  [customization],
  (customizationInfo): string | undefined => customizationInfo?.headerImageUrl,
);

const dustImageUrl = createSelector(
  [customization],
  (customizationInfo): string => customizationInfo?.dustImageUrl || '',
);

const inPageContext = createSelector([page], (pageInfo: PageState): boolean => pageInfo.inPageContext);

const inPublicPageContext = createSelector([page], (pageInfo: PageState): boolean => pageInfo.inPublicPageContext);

const isOwner = createSelector([page], (pageInfo: PageState): boolean => Boolean(pageInfo.isOwner));

const newTypeNotification = createSelector(
  [page],
  (pageInfo: PageState): PageType | undefined => pageInfo.newTypeNotification,
);

const affiliateInfo = createSelector(
  [page],
  (pageInfo: PageState): Pick<PageState, 'affiliateProgress' | 'affiliateRequirements' | 'affiliateApplication'> => {
    const { affiliateProgress, affiliateRequirements, affiliateApplication } = pageInfo;

    return {
      affiliateProgress,
      affiliateRequirements,
      affiliateApplication,
    };
  },
);

const partnershipInfo = createSelector(
  [page],
  (
    pageInfo: PageState,
  ): Pick<PageState, 'partnershipApplication' | 'partnershipProgress' | 'partnershipRequirements'> => {
    const { partnershipApplication, partnershipProgress, partnershipRequirements } = pageInfo;

    return {
      partnershipApplication,
      partnershipProgress,
      partnershipRequirements,
    };
  },
);

const metadataInfo = createSelector([page], (pageInfo: PageState): PageMetadata | undefined => {
  return pageInfo?.metadata;
});

const getBadgeKingMidas = createSelector([page], (pageInfo: PageState) => {
  const { badges } = pageInfo;

  if (!badges || Object.keys(badges).length === 0) {
    return {
      img: staticAWS.BADGES.MIDAS_BADGE_LOCKED,
    };
  }

  const { username, avatarImageUrl } = badges.kingMidas.profile;

  return {
    avatarImageUrl,
    username,
    img: staticAWS.BADGES.MIDAS_BADGE,
  };
});

const userHasCollection = createSelector([page], (pageInfo: PageState) => {
  if (!pageInfo) {
    return null;
  }

  return pageInfo.userHasCollection;
});

const hyperwalletConfiguration = createSelector(
  [page],
  (pageInfo: PageState): HyperwalletConfigurationOutput => {
    if (!pageInfo) {
      return null;
    }

    const { primaryPayoutMethod, flags, hyperwalletStreamlootsId, hyperwalletRegistered } = pageInfo;

    if (primaryPayoutMethod === undefined || primaryPayoutMethod !== PayoutMethods.HYPERWALLET) {
      return null;
    }

    return {
      showWarning: Boolean(flags && flags.showHyperwalletWarning),
      streamlootsId: hyperwalletStreamlootsId || '',
      registered: Boolean(hyperwalletRegistered),
      primaryPayoutMethod,
    };
  },
);

const primaryPayoutMethod = createSelector([page], (pageInfo: PageState) => {
  if (!pageInfo) {
    return null;
  }

  return !pageInfo ? '' : pageInfo.primaryPayoutMethod;
});

const language = createSelector([page], (pageInfo: PageState): string => {
  return pageInfo.customization?.language || '';
});

const publishedAt = createSelector([page], (pageInfo: PageState): string => {
  return pageInfo?.publishedAt || '';
});

const sellerHasSoldThisWeek = createSelector([page], (pageInfo: PageState): boolean => {
  return Boolean(pageInfo?.sellerHasSoldThisWeek);
});

// Placeholder for mate
const pageModules = createSelector([page], (pageInfo: PageState): PageModules | undefined => {
  return pageInfo.modules;
});

export const pageSelectors = {
  modulesPlans,
  affiliateInfo,
  banInfo,
  currency,
  customization,
  dustImageUrl,
  headerImageUrl,
  inPageContext,
  inPublicPageContext,
  isAffiliate,
  isNotFoundError,
  isOwner,
  isPartner,
  level,
  metadataInfo,
  newTypeNotification,
  ownerId,
  page,
  pageId,
  pageStatus,
  partnershipInfo,
  paymentProcessors,
  rankingVisibility,
  sellerId,
  slug,
  timezone,
  type,
  usageStatistics,
  getBadgeKingMidas,
  userHasCollection,
  hyperwalletConfiguration,
  language,
  primaryPayoutMethod,
  publishedAt,
  ownerPrimaryAuthProvider,
  sellerHasSoldThisWeek,
  pageModules,
};
