import { Link } from 'react-router-dom';
import ClassNames from '@streamloots/classnames';
import Button from 'ui-library/button';
import theme from './validate-email.scss';

const classNames = ClassNames(theme);

type ValidateEmailPanelProps = {
  img: string;
  title: string;
  description: string;
  url: string;
  onClick?: () => void;
  buttonTxt: string;
};

export const ValidateEmailPanel = ({
  img,
  title,
  description,
  url,
  onClick = () => undefined,
  buttonTxt,
}: ValidateEmailPanelProps) => {
  return (
    <div className={classNames('validate-email__content')}>
      <div className={classNames('validate-email__panel validate-email__panel--has-hover')}>
        <div>
          <img src={img} alt={title} />
        </div>
        <h2 className={classNames('validate-email__panel__title')}>{title}</h2>
        <hr />
        <p className={classNames('validate-email__panel__description')}>{description}</p>
        <Button primary className={classNames('validate-email__button')} onClick={onClick} component={Link} to={url}>
          {buttonTxt}
        </Button>
      </div>
    </div>
  );
};
