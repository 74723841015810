import type { PaginationStateNormalizerConfiguration } from 'services/pagination/typesTS';
import { PageFeedCommentsQuery } from './types';

export const DEFAULT_PAGE_FEED_COMMENTS_LIMIT = 3;

export const DEFAULT_PAGE_FEED_COMMENTS_QUERY: PageFeedCommentsQuery = {
  limit: DEFAULT_PAGE_FEED_COMMENTS_LIMIT,
  slug: '',
  feedId: '',
};

export const PAGINATION_STATE_NORMALIZER_CONFIGURATION: PaginationStateNormalizerConfiguration<PageFeedCommentsQuery> = {
  initialQuery: DEFAULT_PAGE_FEED_COMMENTS_QUERY,
};
