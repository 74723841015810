import Panel from './Panel';
import { BasePanelProps } from './types';

export const PanelWithoutHeader = ({ children, ...props }: BasePanelProps) => {
  return (
    <Panel title="" showHeader={false} {...props}>
      {children}
    </Panel>
  );
};
