import ClassNames from '@streamloots/classnames';
import loadable from 'components/dynamic-import';
import FullWidthPageHeader from 'components/full-width-page-header';
import REFERRAL_BANNER_EN from './assets/EN-logo-referrals.svg';
import theme from './referral.scss';

const classNames = ClassNames(theme);
const ConnectedReferral = loadable(() => import('./ConnectedReferral'));

export const ReferralPage = (): JSX.Element => {
  return (
    <div>
      <FullWidthPageHeader candy>
        <div className={classNames('referral__header')}>
          <img src={REFERRAL_BANNER_EN} className={classNames('referral__header-logo')} alt="Streamloots Referrals" />
        </div>
      </FullWidthPageHeader>
      <ConnectedReferral />
    </div>
  );
};
