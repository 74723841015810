import stateCreator from 'helpers/stateCreator';
import { DonationPreset } from 'model/donationPreset';
import { PageDonationPresetsActionTypes } from './actionTypes';
import { normalizeResponse } from './normalizer';
import { DonationPresetsState, DonationPresetAction } from './types';

const initialState: DonationPresetsState = {};

export const reducer = (state = initialState, action: DonationPresetAction): DonationPresetsState => {
  switch (action.type) {
    case PageDonationPresetsActionTypes.CREATE_PAGE_DONATION_PRESETS_LOADING:
    case PageDonationPresetsActionTypes.DELETE_PAGE_DONATION_PRESETS_LOADING:
    case PageDonationPresetsActionTypes.GET_ALL_PAGE_DONATION_PRESETS_LOADING:
    case PageDonationPresetsActionTypes.GET_ONE_PAGE_DONATION_PRESETS_LOADING:
    case PageDonationPresetsActionTypes.UPDATE_PAGE_DONATION_PRESETS_LOADING:
      return { ...state, ...stateCreator.getAsyncLoadingState(state) };

    case PageDonationPresetsActionTypes.CREATE_PAGE_DONATION_PRESETS_ERROR:
    case PageDonationPresetsActionTypes.DELETE_PAGE_DONATION_PRESETS_ERROR:
    case PageDonationPresetsActionTypes.GET_ALL_PAGE_DONATION_PRESETS_ERROR:
    case PageDonationPresetsActionTypes.GET_ONE_PAGE_DONATION_PRESETS_ERROR:
    case PageDonationPresetsActionTypes.UPDATE_PAGE_DONATION_PRESETS_ERROR:
      return { ...state, ...stateCreator.getAsyncErrorState(action, state) };

    // Create a new preset.
    case PageDonationPresetsActionTypes.CREATE_PAGE_DONATION_PRESETS_SUCCESS:
      if (action.payload) {
        const presets = [...(state.presets || []), normalizeResponse(action.payload)];
        return stateCreator.getInitialAsyncState(state, {
          ...state,
          presets: presets.sort((a, b) => a.amount - b.amount),
        });
      }
      return state;

    // Delete a preset.
    case PageDonationPresetsActionTypes.DELETE_PAGE_DONATION_PRESETS_SUCCESS:
      if (action.payload) {
        const presets: DonationPreset[] = state.presets?.filter(preset => preset._id !== action.payload._id) ?? [];
        return stateCreator.getInitialAsyncState(state, {
          ...state,
          presets,
        });
      }
      return state;

    // Get all presets.
    case PageDonationPresetsActionTypes.GET_ALL_PAGE_DONATION_PRESETS_SUCCESS:
      if (action.payload) {
        const presets = action.payload;
        return stateCreator.getInitialAsyncState(state, {
          ...state,
          presets: presets.sort((a, b) => a.amount - b.amount),
        });
      }
      return state;

    // Get one preset.
    // Update a preset.
    case PageDonationPresetsActionTypes.GET_ONE_PAGE_DONATION_PRESETS_SUCCESS:
    case PageDonationPresetsActionTypes.UPDATE_PAGE_DONATION_PRESETS_SUCCESS:
      if (action.payload) {
        const normalized = normalizeResponse(action.payload);
        const presets: DonationPreset[] = state.presets?.map(preset =>
          preset._id === action.payload._id ? normalized : preset,
        ) ?? [normalized];
        return stateCreator.getInitialAsyncState(state, {
          ...state,
          presets: presets.sort((a, b) => a.amount - b.amount),
        });
      }
      return state;

    default:
      return state;
  }
};
