import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { ButtonIcon } from 'ui-library/button';
import theme from './provider-button.scss';

const classNames = ClassNames(theme);

type ProviderButtonBuilderProps = {
  className?: string;
  icon: string;
  label: string;
  noTextOnSmallRes?: boolean;
  onClick: () => void;
};

export const ProviderButton = ({ className = '', icon, ...rest }: ProviderButtonBuilderProps) => {
  return (
    <ButtonIcon
      {...rest}
      icon={icon}
      className={classNames({
        'provider-btn': true,
        [icon]: true,
        [className]: Boolean(className),
      })}
    />
  );
};
