import { useTranslation } from 'utils/translation';
import { PageHeader } from 'ui-library/headers';
import { articlesKeys, HelpOpener } from 'components/help';
import loadable from 'components/dynamic-import';
import { checkPermissions, permissions } from 'components/permissions';
import { GridSkeleton } from 'components/table';
import { FullLayout } from 'scenes/layout';

const COUPONS_KEY = 'pageCoupons';
const Coupons = loadable(() => import('./Coupons'), true, <GridSkeleton />);

const CouponsPageInternal = () => {
  const { t } = useTranslation(['coupons', 'common']);
  return (
    <FullLayout>
      <PageHeader>
        {t('coupons:pageTitle')}{' '}
        <HelpOpener
          articleKey={articlesKeys.COUPONS_HOW_TO_USE_THEM}
          asLink
          eventPayload={{ location: 'Coupons page' }}
        />
      </PageHeader>
      <Coupons componentKey={COUPONS_KEY} t={t} />
    </FullLayout>
  );
};

export const CouponsPage = checkPermissions({
  requiredPermissions: permissions.COUPONS_READ,
  redirectToBasePage: true,
})(CouponsPageInternal);
