import { useCallback, createContext, useState, useMemo, useEffect, ReactNode } from 'react';
import {
  SidebarOpenerAttributes,
  SidebarOpenerContextType,
  SidebarOpenerOpenParameters,
  SidebarOpenerProviderProps,
} from './typesTS';

const context = {
  isOpen: false,
  component: null,
  attributes: {},
  open: () => undefined,
  close: () => undefined,
  toggle: () => undefined,
  setComponent: () => undefined,
  setAttributes: () => undefined,
};

export const SidebarOpenerContext = createContext<SidebarOpenerContextType>(context);

export const SidebarOpenerProvider = ({ children, isOpen = false }: SidebarOpenerProviderProps): JSX.Element => {
  const [openState, setOpen] = useState(isOpen);
  const [component, setComponent] = useState<ReactNode | null>(null);
  const [attributes, setAttributes] = useState<SidebarOpenerAttributes>({});
  const { componentId: activeComponentId } = attributes;

  const open = useCallback((parameters: SidebarOpenerOpenParameters) => {
    const sidebarAttributes = parameters.attributes ? { sidebarToContent: false, ...parameters.attributes } : {};
    setOpen(true);
    setComponent(parameters.component);
    setAttributes(sidebarAttributes);
  }, []);

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  const toggle = useCallback(
    (parameters: SidebarOpenerOpenParameters) => {
      const { attributes: sidebarAttributes } = parameters;
      if (
        openState &&
        sidebarAttributes &&
        sidebarAttributes.componentId &&
        activeComponentId === sidebarAttributes.componentId
      ) {
        close();
        return;
      }
      open(parameters);
    },
    [activeComponentId, openState, close, open],
  );

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const contextValue = useMemo(() => {
    return {
      isOpen: openState,
      component,
      attributes,
      open,
      close,
      toggle,
      setComponent,
      setAttributes,
    };
  }, [attributes, close, component, open, openState, toggle]);

  return <SidebarOpenerContext.Provider value={contextValue}>{children}</SidebarOpenerContext.Provider>;
};
