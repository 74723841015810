import stateCreator from 'helpers/stateCreator';
import { UserSetsActionTypes } from 'services/user-sets';
import { PageActionTypes } from 'services/page';
import { CraftingModeActionTypes } from 'services/crafting-mode';
import { RedemptionActionTypes } from 'services/redemption';
import type { UserSetCardsState, UserSetCardsStateBySetId } from './types';
import { DEFAULT_STATE_BY_SET_ID } from './constants';
import normalizer from './normalizer';
import { UserSetCardsActionTypes } from './actionTypes';

const getNewKeyState = (state: UserSetCardsStateBySetId, action) => {
  const { type, error, payload } = action;
  switch (type) {
    case UserSetCardsActionTypes.FILTERED: {
      const { filters } = payload;
      return {
        ...state,
        filters,
      };
    }
    case RedemptionActionTypes.REDEEMED: {
      const { metadata } = action;
      if (error && metadata.businessCode === 'REDEMPTION_LIMIT_REACHED') {
        return {
          ...state,
          ...normalizer.getNewCardStateAfterCardCooldownError(state, metadata.errorMetadata.redemptionLimit),
        };
      }

      if (error) {
        return state;
      }

      return {
        ...state,
        ...normalizer.getNewCardStateAfterRedemption(state, payload),
      };
    }
    case CraftingModeActionTypes.CRAFTED:
    case CraftingModeActionTypes.DISENCHANTED: {
      if (error) {
        return state;
      }
      const isDisenchant = type === CraftingModeActionTypes.DISENCHANTED;
      return {
        ...state,
        ...normalizer.getCardStateAfterCraftingAction(state, payload, isDisenchant),
      };
    }
    default:
      return state;
  }
};

export default (state: UserSetCardsState = {}, action): UserSetCardsState => {
  const { error, type, payload, metadata } = action;
  switch (type) {
    case UserSetsActionTypes.FETCH_SUCCESS: {
      const { data } = payload;
      const newStateBySetId = {};

      data.forEach(userSet => {
        const { _id, cards } = userSet;
        const setIdState = state[_id] || DEFAULT_STATE_BY_SET_ID;

        newStateBySetId[_id] = {
          ...setIdState,
          ...normalizer.getUserSetCardsState(cards),
        };
      });

      return {
        ...state,
        ...newStateBySetId,
      };
    }
    case CraftingModeActionTypes.CRAFTED:
    case CraftingModeActionTypes.DISENCHANTED:
    case UserSetCardsActionTypes.FILTERED:
    case RedemptionActionTypes.REDEEMED: {
      const setId = error ? metadata.setId : payload.setId;
      return stateCreator.getUpdatedStateByKey(state, action, setId, getNewKeyState);
    }
    case PageActionTypes.CHANGED:
      return {};
    default:
      return state;
  }
};
