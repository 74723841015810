import ClassNames from '@streamloots/classnames';
import theme from './full-width-page-header.scss';

const classNames = ClassNames(theme);

type FullWidthPageHeaderProps = {
  className?: string;
  candy?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
};

function FullWidthPageHeader({ className = '', candy, children, style }: FullWidthPageHeaderProps): JSX.Element {
  return (
    <header
      className={classNames({
        'full-width-header': true,
        'full-width-header--candy': candy,
        [className]: className,
      })}
      style={style}
    >
      {children}
    </header>
  );
}

export default FullWidthPageHeader;
