import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import type { StripePaymentMethodDeletedActionSuccess, StripePaymentMethodDeletedActionError } from './types';
import { DeleteStripePaymentMethodActionTypes } from './actionTypes';

const deleteStripePaymentMethodSuccess = ({ urlParameters }): StripePaymentMethodDeletedActionSuccess =>
  actionCreator.createAction(DeleteStripePaymentMethodActionTypes.DELETE_STRIPE_PAYMENT_METHOD_SUCCESS, urlParameters);

const deleteStripePaymentMethodError = ({ errorData }): StripePaymentMethodDeletedActionError =>
  actionCreator.createErrorAction(DeleteStripePaymentMethodActionTypes.DELETE_STRIPE_PAYMENT_METHOD_ERROR, errorData);

const deleteStripePaymentMethod = (paymentMethodId: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.SAVED_STRIPE_PAYMENT_METHODS.DELETE,
    errorBinding: deleteStripePaymentMethodError,
    loadingAction: DeleteStripePaymentMethodActionTypes.DELETE_STRIPE_PAYMENT_METHOD_REQUEST,
    urlParameters: { paymentMethodId },
    method: 'delete',
    successBinding: deleteStripePaymentMethodSuccess,
  });

export const deleteStripePaymentMethodActions = {
  deleteStripePaymentMethod,
};
