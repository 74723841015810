import ClassNames from '@streamloots/classnames';
import Button, { BaseButtonProps } from 'ui-library/button';
import ReactDOM from 'react-dom';
import FloatingAlertContainer from './FloatingAlertContainer';
import theme from './floating-image-alert.scss';

const classNames = ClassNames(theme);

type FloatingImageAlertProps = {
  title?: string;
  description?: React.ReactNode;
  subtitle?: string;
  dynamicContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  img?: string;
  videoUrl?: string;
  button?: BaseButtonProps;
  show: boolean;
  onClose: () => void;
  titleClassName?: string;
  alertClassName?: string;
  imageClassName?: string;
  children?: React.ReactNode;
};

const FloatingImageAlert = ({
  title,
  subtitle,
  description,
  dynamicContent,
  footerContent,
  img,
  button,
  show,
  onClose,
  titleClassName = '',
  alertClassName = '',
  imageClassName = '',
  children,
  videoUrl,
}: FloatingImageAlertProps): JSX.Element => {
  return ReactDOM.createPortal(
    <FloatingAlertContainer show={show} alertClassName={alertClassName} onClose={onClose}>
      <div className={classNames('imagealert__text')}>
        {dynamicContent && dynamicContent}
        {title && (
          <h2
            className={classNames({
              imagealert__title: true,
              [titleClassName]: titleClassName,
            })}
          >
            {title}
          </h2>
        )}
        {subtitle && <p className={classNames('imagealert__subtitle')}>{subtitle}</p>}
        {description && <div className={classNames('imagealert__description')}>{description}</div>}
        {children}
        {button && <Button size="sm" {...button} />}
        {footerContent && footerContent}
      </div>
      <div className={classNames({ imagealert__image: true, [imageClassName]: Boolean(imageClassName) })}>
        {img && <img src={img} alt="" />}
        {videoUrl && <video className={classNames('imagealert__video')} autoPlay loop muted src={videoUrl} />}
      </div>
    </FloatingAlertContainer>,
    document.body,
  );
};

export default FloatingImageAlert;
