import type { PaginationStateNormalizerConfiguration } from 'services/pagination/typesTS';
import { BannedUsersQuery } from 'services/ban-users/types';

export const DEFAULT_LIMIT = 30;
export const DEFAULT_QUERY: BannedUsersQuery = {
  limit: DEFAULT_LIMIT,
  active: true,
  slug: '',
};

export const PAGINATION_STATE_NORMALIZER_CONFIGURATION: PaginationStateNormalizerConfiguration<BannedUsersQuery> = {
  initialQuery: DEFAULT_QUERY,
};
