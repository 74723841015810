import ClassNames from '@streamloots/classnames';
import { TranslateInterface } from 'utils/translation';
import { articlesKeys, HelpOpener } from 'components/help';
import { PageHeader } from 'ui-library/headers';
import theme from './auction-list-header.scss';

const classNames = ClassNames(theme);

interface AuctionListHeaderProps extends TranslateInterface {
  slug: string;
}

export const AuctionListHeader = ({ t }: AuctionListHeaderProps): JSX.Element => {
  return (
    <header className={classNames('auction-list-header')}>
      <PageHeader>
        {t('auction:auctions.title')}{' '}
        <HelpOpener asLink articleKey={articlesKeys.AUCTION} eventPayload={{ location: 'Auctions page title' }} />
      </PageHeader>
    </header>
  );
};
