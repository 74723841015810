import React from 'react';
import ClassNames from '@streamloots/classnames';
import FullWidthPageHeader from 'components/full-width-page-header';
import brandUrls from 'constants/brand';
import loadable from 'components/dynamic-import';
import { FullLayout } from '../layout';
import theme from './theme.scss';

const classNames = ClassNames(theme);

const NewBrand = loadable(() => import('./NewBrand'));

const BrandPage = () => (
  <React.Fragment>
    <FullWidthPageHeader candy className={classNames('brand__header')}>
      <div className={classNames('brand__content')}>
        <img src={brandUrls.primary} alt="Streamloots" />
      </div>
    </FullWidthPageHeader>
    <FullLayout>
      <NewBrand />
    </FullLayout>
  </React.Fragment>
);

export default BrandPage;
