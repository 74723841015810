import { Component, createRef } from 'react';
import ClassNames from '@streamloots/classnames';
import Icon from 'ui-library/icons';
import AvatarImage from 'components/avatar-image';
import { ScrollToFunction } from 'components/scrollbar-panel/types';
import type { ResultItem } from './types';
import theme from './autocomplete-async.scss';

const classNames = ClassNames(theme);

interface ResultProps {
  result: ResultItem;
  focus?: boolean;
  index?: number;
  onClick: (result: ResultItem) => void;
  onHover?: (index: number) => void;
  scrollTo: ScrollToFunction;
  selected: boolean;
  setScrollToSelectedResult?: boolean;
}

class Result extends Component<ResultProps> {
  node = createRef<HTMLDivElement>();

  componentDidUpdate(prevProps) {
    const { scrollTo, setScrollToSelectedResult, focus } = this.props;
    if (setScrollToSelectedResult && focus && !prevProps.focus && this.node.current && typeof scrollTo === 'function') {
      scrollTo(this.node.current.offsetTop);
    }
  }

  handleClick = () => {
    const { result, onClick } = this.props;
    onClick(result);
  };

  handleHover = () => {
    const { index, onHover } = this.props;
    if (typeof onHover === 'function') {
      onHover(index || 0);
    }
  };

  render() {
    const {
      result: { imageUrl, name, badge },
      focus,
      selected,
    } = this.props;
    return (
      <div
        ref={this.node}
        className={classNames({
          'autocomplete-async__result': true,
          'autocomplete-async__result--selected': selected,
          'autocomplete-async__result--focus': focus,
        })}
        onMouseDown={this.handleClick}
        onMouseOver={this.handleHover}
        onFocus={this.handleHover}
        data-testid="async-autocomplete-result"
      >
        {imageUrl && (
          <div className={classNames('autocomplete-async__avatar')}>
            <AvatarImage className={classNames('autocomplete-async__avatar__img')} src={imageUrl} alt="" />
            {badge}
          </div>
        )}

        <div className={classNames('autocomplete-async__username')}>
          {name} {selected && <Icon id="check" className="color-success" />}
        </div>
      </div>
    );
  }
}

export { Result };
