import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as staticResourcesUrl from '../../tools/webpack/static-resources-url';
import i18nConfiguration from './i18n-configuration';

const options = {
  backend: {
    loadPath: `${staticResourcesUrl.getUrl({
      local: __LOCAL__,
    })}/locales/{{lng}}/{{ns}}.json`,
    jsonIndent: 2,
  },
  ...i18nConfiguration,
};

if (process && !process.release) {
  i18n.use(XHR).use(initReactI18next).use(LanguageDetector);
}

if (!i18n.isInitialized) {
  i18n.init(options);
}

export default i18n;
