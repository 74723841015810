import { Languages } from '@streamloots/streamloots-types';
import { USER_STREAMER_CATEGORIES, User, PageUsageStatistics, PageFlags, PageModules } from 'model/indexTS';
import { History } from 'history';
import userflow, { EventAttributes, TrackOptions } from 'userflow.js';
import config from 'config';
import { captureSentryError } from 'utils/sentry';
import eventsHelpers from './eventsHelpers';

export interface InitUserflowArguments {
  history: History;
  user: User;
  language: Languages;
  isMobile: boolean;
  publishedAt: string;
  usageStatistics: PageUsageStatistics;
  flags?: PageFlags;
  experiments: string;
  pageModules: PageModules;
}

const initPageModules = (pageModules: PageModules) => {
  const parsedModules: Record<string, any> = {};
  const moduleKeys = Object.keys(pageModules);
  moduleKeys.forEach(moduleKey => {
    parsedModules[`${moduleKey}Enabled`] = pageModules[moduleKey].enabled;
    parsedModules[`${moduleKey}EnabledAt`] = pageModules[moduleKey].enabledAt;
    parsedModules[`${moduleKey}DisabledAt`] = pageModules[moduleKey].disabledAt;
  });

  return parsedModules;
};

function initUserflow({
  history,
  user,
  language,
  isMobile,
  publishedAt,
  usageStatistics,
  flags = {},
  experiments,
  pageModules,
}: InitUserflowArguments): void {
  try {
    if (userflow.isIdentified()) {
      return;
    }

    const { _id, profile, createdAt, category, page } = user;
    const { username, primaryEmail: email } = profile;
    const {
      hyperwalletActivationRequired = false,
      hyperwalletVerificationRequired = false,
      plan100PromotionEnabled = false,
      buyMePacksAlertEnabled = undefined,
      chatbotEnabled = undefined,
      fragmentedCardsEnabled = undefined,
      fragmentedCardsWithoutOriginalsEnabled = undefined,
      genericChatbotMessageEnabled = undefined,
      goalsEnabled = undefined,
      legendaryCardObtainedAlertEnabled = undefined,
      legendaryCardObtainedChatbotMessageEnabled = undefined,
      limitedCardsEnabled = undefined,
      limitedCardsWithoutOriginalsEnabled = undefined,
      paidCardsEnabled = undefined,
      paidCardsWithoutOriginalsEnabled = undefined,
      rankingChatbotMessageEnabled = undefined,
    } = flags;

    // Tell userflow not to use css class names cause they are autogenerated and so, non-predictable.
    userflow.setInferenceClassNameFilter(className => RegExp(/--\w{5}\b/).test(className));

    userflow.setCustomNavigate(url => history.push(url));
    userflow.init(config.userflow.token);

    userflow.identify(_id, {
      username,
      email,
      category: USER_STREAMER_CATEGORIES[category],
      signed_up_at: createdAt,
      isMobile,
      language,
      onboardingFinishedAt: publishedAt,
      pageType: page?.type,
      pageLevel: page?.level,
      cardRedeemedByCommunityCount: usageStatistics.cardRedemptionCount,
      lootsSoldCount: usageStatistics.lootsSoldCount ?? 0,
      experiments,
      hyperwalletActivationRequired,
      hyperwalletVerificationRequired,
      plan100PromotionEnabled,
      buyMePacksAlertEnabled,
      chatbotEnabled,
      fragmentedCardsEnabled,
      fragmentedCardsWithoutOriginalsEnabled,
      genericChatbotMessageEnabled,
      goalsEnabled,
      legendaryCardObtainedAlertEnabled,
      legendaryCardObtainedChatbotMessageEnabled,
      limitedCardsEnabled,
      limitedCardsWithoutOriginalsEnabled,
      paidCardsEnabled,
      paidCardsWithoutOriginalsEnabled,
      rankingChatbotMessageEnabled,
      ...initPageModules(pageModules),
    });
  } catch (e) {
    captureSentryError('Error initializing userflow', {
      user,
      language,
      isMobile,
      publishedAt,
      usageStatistics,
      exceptionError: e.message,
    });
  }
}

function trackEvent(
  eventName: string,
  eventData: EventAttributes = {},
  options: TrackOptions = { userOnly: true },
): void {
  try {
    if (!eventsHelpers.isEventSenderAdded('userflow') || !userflow.isIdentified()) {
      return;
    }

    // TODO flatten all object properties to make it work always, but it doesn't accept dot in property names so it should be created with _
    // userflow only accepts primitive values for attributes
    const normalizedEventData = {};
    Object.keys(eventData).forEach(key => {
      const value = eventData[key];

      if (typeof value !== 'object') {
        normalizedEventData[key] = value;
        return;
      }

      // Primitive array can be useful to filter like categories
      if (Array.isArray(value)) {
        const primitiveArray = value.filter(item => {
          return typeof item !== 'object';
        });

        normalizedEventData[key] = primitiveArray.join(',');
      }
    });

    userflow.track(eventName, normalizedEventData, options);
  } catch (e) {
    captureSentryError('Error sending userflow event', {
      ...eventData,
      eventName,
      exceptionError: e.message,
    });
  }
}

export const userflowTracker = {
  initUserflow,
  trackEvent,
};
