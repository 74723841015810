import { useState, ReactElement, Children } from 'react';
import ClassNames from '@streamloots/classnames';
import type { TabInfo } from './types';
import { TabHeader } from './TabHeader';
import theme from './tabs.scss';

const classNames = ClassNames(theme);

export interface TabsProps {
  activeTab: number;
  className?: string;
  headersClassName?: string;
  contentClassName?: string;
  onChange: (index: number) => void;
  children: Array<ReactElement<TabInfo> | undefined | boolean>;
  compact?: boolean;
}

export const Tabs = ({
  activeTab,
  children,
  className = '',
  contentClassName = '',
  compact,
  headersClassName = '',
  onChange,
}: TabsProps): JSX.Element | null => {
  const getActiveTab = () => {
    if (activeTab < 0 || activeTab > children.length - 1) {
      return 0;
    }

    return activeTab;
  };

  const childrenArray = (Children.toArray(children) as unknown) as ReactElement<TabInfo>[];

  if (!Array.isArray(childrenArray) || childrenArray.length === 0) {
    return null;
  }

  const activeTabIndex = getActiveTab();

  return (
    <div className={classNames({ tabs: true, [className]: Boolean(classNames) })}>
      <div
        className={classNames({
          'tabs__headers': true,
          [headersClassName]: Boolean(headersClassName),
          'tabs__headers--compact': compact,
        })}
      >
        {childrenArray.map((tab, index) => {
          if (!tab) {
            return null;
          }

          const { activeHeaderClassName, disabled, headerClassName, icon, tabKey, label, onTabClick } = tab.props;
          return (
            <TabHeader
              active={getActiveTab() === index}
              activeClassName={activeHeaderClassName}
              className={headerClassName}
              disabled={disabled}
              icon={icon}
              index={index}
              key={tabKey}
              label={label}
              onTabClick={onTabClick}
              onTabHeaderClick={onChange}
            />
          );
        })}
      </div>
      <section
        className={classNames({ tabs__content: true, [contentClassName]: Boolean(contentClassName) })}
        role="tabpanel"
      >
        {children[activeTabIndex]}
      </section>
    </div>
  );
};
