export const IMAGE_TYPES = [
  '.ai',
  '.arw',
  '.bmp',
  '.cr2',
  '.djvu',
  '.eps',
  '.eps3',
  '.ept',
  '.flif',
  '.gif',
  '.hdp',
  '.heic',
  '.heif',
  '.ico',
  '.jp2',
  '.jpe',
  '.jpeg',
  '.jpg',
  '.jxr',
  '.pdf',
  '.png',
  '.ps',
  '.psd',
  '.svg',
  '.tga',
  '.tif',
  '.tiff',
  '.wdp',
  '.webp',
];

export const VIDEO_AND_AUDIO_TYPES = [
  '.3g2',
  '.3gp',
  '.aac',
  '.aiff',
  '.amr',
  '.avi',
  '.flac',
  '.flv',
  '.m2ts',
  '.m3u8',
  '.m4a',
  '.mkv',
  '.mov',
  '.mp3',
  '.mp4',
  '.mpd',
  '.mpeg',
  '.mts',
  '.ogg',
  '.ogv',
  '.ts',
  '.wav',
  '.webm',
  '.wmv',
];

export function getExtension(url: string): string {
  let pathName = url;

  try {
    const newUrl = new URL(url);
    pathName = newUrl.pathname;
  } catch (error) {
    console.error(error);
    // eslint-disable-next-line prefer-destructuring
    pathName = url.split('?')[0];
  }

  const re = /(?:\.([^.]+))?$/;
  const fileType = re.exec(pathName);

  if (!fileType) {
    return '';
  }

  return fileType[0];
}

export type TransformationOptions = {
  bestFormat?: boolean;
  f_auto?: boolean; // eslint-disable-line
  quality?: number;
  scale?: boolean;
  width?: number;
};

export interface Transformation {
  toUrl(): string;
  isSupported(): boolean;
}
