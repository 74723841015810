import { isBefore } from 'date-fns';
import { PageModules, Permissions } from 'model/indexTS';
import { MenuItemSpecialRenderConditions, MenuItemRenderRequirements } from 'scenes/menu/links';
import { areModulesRequiredEnabled } from 'components/page-modules-checker';
import { hasPermissions } from 'services/permissions';

export interface SpecialConditionsProps {
  setPublished: boolean;
  hasPublishedSets: boolean;
  registeredAt: string;
  hasSets: boolean;
  hasPowers: boolean;
  activeAchievements: boolean;
  pageModules?: PageModules;
}

export const meetSpecialConditions = (
  linkSpecialConditions: MenuItemSpecialRenderConditions | undefined,
  props?: Partial<SpecialConditionsProps>,
): boolean => {
  if (!linkSpecialConditions) {
    return true;
  }

  if (!props) {
    return false;
  }

  const { setPublished, hasPublishedSets, registeredAt, hasSets, pageModules, activeAchievements, hasPowers } = props;
  const {
    setPublished: requiresSetPublished,
    hasPublishedSets: requiresSetsPublished,
    hasSets: requiresSets,
    isOldUser,
    showUntil,
    modulesRequired,
    activeAchievements: requiresAchievements,
    hasPowers: requirePowers,
  } = linkSpecialConditions;

  if (modulesRequired && !areModulesRequiredEnabled(pageModules, modulesRequired)) {
    return false;
  }

  const isBeforeNow = showUntil ? isBefore(Date.now(), showUntil) : true;

  const isBeforeCreated = isOldUser && registeredAt ? isBefore(registeredAt, isOldUser) : true;

  if (requiresSets && !hasSets) {
    return false;
  }

  if (requiresSetsPublished && !hasPublishedSets) {
    return false;
  }

  if (requiresSetPublished && !setPublished) {
    return false;
  }

  if (requirePowers && !hasPowers) {
    return false;
  }

  if (requiresAchievements && !activeAchievements) {
    return false;
  }

  if (!isBeforeNow) {
    return false;
  }

  if (!isBeforeCreated) {
    return false;
  }

  return true;
};

export const canRenderMenuItem = (
  itemPermissions: MenuItemRenderRequirements,
  permissions?: Permissions,
  specialConditionsProps?: Partial<SpecialConditionsProps>,
) => {
  const {
    specialConditions,
    enforcePermissions: linkEnforcePermissions,
    requiredPermissions: linkPermissions,
  } = itemPermissions;

  if (!linkPermissions && !specialConditions && !linkEnforcePermissions) {
    return true;
  }

  if (!permissions && linkPermissions) {
    return false;
  }

  if (!meetSpecialConditions(specialConditions, specialConditionsProps)) {
    return false;
  }

  if (linkEnforcePermissions && !hasPermissions(permissions || {}, linkEnforcePermissions)) {
    return false;
  }

  if (linkPermissions && !hasPermissions(permissions || {}, linkPermissions)) {
    return false;
  }

  return true;
};
