import { connect } from 'react-redux';
import { setsSelectors } from 'services/sets';
import { SetsRightPanel } from './SetsRightPanel';

interface MapStateToProps {
  hasLoadedSets: boolean;
  setsPublishedCount: number;
  setsCount: number;
}

const ConnectedSetsRightPanelInternal = ({
  hasLoadedSets,
  setsPublishedCount,
  setsCount,
}: MapStateToProps): JSX.Element | null => {
  if (!hasLoadedSets) {
    return null;
  }

  return <SetsRightPanel setsPublishedCount={setsPublishedCount} setsCount={setsCount} />;
};

const mapStateToProps = (state): MapStateToProps => {
  return {
    hasLoadedSets: setsSelectors.hasLoadedSets(state),
    setsPublishedCount: setsSelectors.setsPublishedCount(state),
    setsCount: setsSelectors.setsCount(state),
  };
};

export const ConnectedSetsRightPanel = connect<MapStateToProps>(mapStateToProps)(ConnectedSetsRightPanelInternal);
