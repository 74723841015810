import { Goal } from 'model/indexTS';
import { GoalsTypeStates, GoalsState } from './types';

export const getGoalsStateFromGoalsArray = (goals: Goal[]): GoalsTypeStates & Pick<GoalsState, 'hasLoadedGoals'> => {
  const stateByTypes: GoalsTypeStates = {};

  goals.forEach(goal => {
    stateByTypes[goal.type] = {
      goal,
    };
  });

  return { ...stateByTypes, hasLoadedGoals: true };
};
