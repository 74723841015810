import React from 'react';

/*
  It seems that in IOS safari animateTransform is not working because we are using the svg as sprite.
  So the workaround is using the svg directly
*/
const LoadingBar = ({ ...props }): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 100 100"
      {...props}
    >
      <path fill="#fff" d="M31.5 35H38.5V60H31.5z">
        <animateTransform
          attributeName="transform"
          attributeType="xml"
          begin="0s"
          dur="0.7s"
          repeatCount="indefinite"
          type="translate"
          values="0 0; 0 10; 0 0"
        />
      </path>
      <path fill="#fff" d="M47.7 35H54.7V60H47.7z">
        <animateTransform
          attributeName="transform"
          attributeType="xml"
          begin="0.2s"
          dur="0.7s"
          repeatCount="indefinite"
          type="translate"
          values="0 0; 0 10; 0 0"
        />
      </path>
      <path fill="#fff" d="M64.25 35H71.25V60H64.25z">
        <animateTransform
          attributeName="transform"
          attributeType="xml"
          begin="0.4s"
          dur="0.7s"
          repeatCount="indefinite"
          type="translate"
          values="0 0; 0 10; 0 0"
        />
      </path>
    </svg>
  );
};

export default LoadingBar;
