import React from 'react';
import { connect } from 'react-redux';
import Dialog from 'ui-library/dialog';
import type { CloseOverloadedContent, OverloadedContentStateProps } from 'services/overloaded-content';
import { overloadedContentActions, overloadedContentSelectors } from 'services/overloaded-content';
import { OverloadedContentWrapperProps } from 'services/overloaded-content/typesTS';

interface MapDispatchToProps {
  closeOverloadedContent: CloseOverloadedContent;
}

interface ConnectedDialogProps extends OverloadedContentStateProps, MapDispatchToProps {}

class ConnectedDialog extends React.Component<ConnectedDialogProps> {
  shouldComponentUpdate(nextProps) {
    const { isOpen, component, wrapperProps } = this.props;
    return isOpen !== nextProps.isOpen || component !== nextProps.component || wrapperProps !== nextProps.wrapperProps;
  }

  handleClose = () => {
    const { closeOverloadedContent, wrapperProps } = this.props;

    if (wrapperProps && wrapperProps.preventClose) {
      return;
    }

    if (wrapperProps && typeof wrapperProps.onClose === 'function') {
      wrapperProps.onClose();
      return;
    }

    closeOverloadedContent();
  };

  getDialogProps = (): OverloadedContentWrapperProps & { active?: boolean } => {
    const { wrapperProps, isOpen } = this.props;

    if (!wrapperProps) {
      return {};
    }

    return { ...wrapperProps, active: isOpen };
  };

  render() {
    const { component: Component, componentProps, isOpen, wrapperProps } = this.props;
    const dialogProps = this.getDialogProps();
    const preventClose = wrapperProps && wrapperProps.preventClose;
    const preventCloseOnOutsideClick = wrapperProps && wrapperProps.preventCloseOnOutsideClick;
    const ignoreOverlayClick = preventClose || preventCloseOnOutsideClick;

    return (
      <Dialog
        active={isOpen}
        fullOnSmall
        preventClose={ignoreOverlayClick}
        onClose={this.handleClose}
        onEscKeyDown={this.handleClose}
        onOverlayClick={ignoreOverlayClick ? undefined : this.handleClose}
        {...dialogProps}
        renderDialogBody={props => Component && <Component {...componentProps} {...props} />}
      />
    );
  }
}

const mapStateToProps = (state): OverloadedContentStateProps => overloadedContentSelectors.dialogProps(state);

export default connect<OverloadedContentStateProps, MapDispatchToProps>(mapStateToProps, {
  closeOverloadedContent: overloadedContentActions.closeOverloadedContent,
})(ConnectedDialog);
