import type { FunctionComponent } from 'react';
import { withTranslation, TranslateInterface } from 'utils/translation';
import ILLUSTRATIONS from 'constants/illustrations';
import { pages } from 'services/routing';
import { ValidateEmailPanel } from './ValidateEmailPanel';

const UpdatedPanel = ({ t }: TranslateInterface) => {
  return (
    <ValidateEmailPanel
      title={t('validateEmail.updated.title')}
      description={t('validateEmail.updated.description')}
      img={ILLUSTRATIONS.LOGO_HELLO}
      url={pages.USER_COLLECTIONS}
      buttonTxt={t('validateEmail.goToStreamloots')}
    />
  );
};

export default withTranslation('userProfile')(UpdatedPanel) as FunctionComponent;
