import ClassNames from '@streamloots/classnames';
import { useTranslation } from 'utils/translation';
import { PageHeader } from 'ui-library/headers';
import { CardCreatorLikeSkeleton } from 'components/component-skeletons';
import loadable from 'components/dynamic-import';
import { HelpOpener, articlesKeys } from 'components/help';
import { FullLayout } from 'scenes/layout';
import theme from './timer.scss';

const classNames = ClassNames(theme);

const ConnectedTimerConfigurationForm = loadable(
  () => import('./ConnectedTimerConfiguration'),
  true,
  <CardCreatorLikeSkeleton />,
);

const TimerPage = (): JSX.Element => {
  const { t } = useTranslation('timer');

  return (
    <FullLayout>
      <header className={classNames('timer__header')}>
        <PageHeader>
          {t('timer:pageTitle')}
          <HelpOpener asLink articleKey={articlesKeys.TIMER} panelHeader />
        </PageHeader>
      </header>
      <ConnectedTimerConfigurationForm />
    </FullLayout>
  );
};

export default TimerPage;
