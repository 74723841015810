import loadable from 'components/dynamic-import';
import { FunctionComponent } from 'react';
import { FullLayout } from 'scenes/layout';
import { PageHeader } from 'ui-library/headers';
import { checkPermissions, staffPermissions } from 'components/permissions';
import { useTranslation } from 'utils/translation';

const ManageEmotes = loadable(() => import('./ManageEmotes'));

const AdminEmotes: FunctionComponent = (): JSX.Element => {
  const { t } = useTranslation('pageSubscription');

  return (
    <FullLayout>
      <PageHeader>{t('pageSubscription:staff.emotes.title')}</PageHeader>
      <ManageEmotes />
    </FullLayout>
  );
};

export default checkPermissions({
  requiredPermissions: [...staffPermissions],
  redirectToBasePage: true,
})(AdminEmotes);
