import actionCreator from 'helpers/actionCreator';
import type { LayoutStructureChangedAction } from './types';
import { LayoutActionTypes } from './actionTypes';

const changeLayoutStructure = (isMobile: boolean): LayoutStructureChangedAction =>
  actionCreator.createAction(LayoutActionTypes.STRUCTURE_CHANGED, {
    isMobile,
  });

export const layoutActions = {
  changeLayoutStructure,
};
