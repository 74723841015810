import type { Location, History } from 'history';
import { CancelTokenSource } from 'axios';
import type { Request, Response } from 'express';
import { ReduxStore, ActionError, ActionSuccess } from 'store/types';

export type FixMe = any;

export type DropdownOption<Value = string> = {
  label: string;
  value: Value;
};

export type ValueOf<T> = T[keyof T];

export type Localized = {
  en: string;
  es: string;
};

// async-actions start here
export type AsyncActionError = {
  businessCode?: string;
  code: number;
  message: string;
};

export type AsyncComponentStateError = AsyncActionError | null | undefined;

export type AsyncComponentState = {
  isLoading?: boolean;
  succeeded?: boolean;
  error?: AsyncComponentStateError;
};

export interface AsyncActionState {
  error?: AsyncComponentStateError;
  isLoading?: boolean;
}

export type AsyncComponentProps = AsyncActionState;

// Async iterators types
export interface AsyncActionIteratorQuery {
  limit?: number;
  since?: number;
}

export type Pagination = {
  next?: string;
};

export interface AsyncIterationState<B> extends AsyncActionState {
  pagination?: Pagination;
  isFetchingNextItems?: boolean;
  resetData?: boolean;
  query: B;
}

// thunks
type ResponseError = {
  message: string;
  stack: string;
};

export interface FetchResponseError {
  error: boolean;
  metadata: {
    businessCode: string;
    code: number;
    errorMetadata: Record<string, unknown>;
  };
  payload: ResponseError;
  type: string;
}

export interface FetchResponseSuccess<Payload = any, Metadata = Record<string, unknown>> {
  payload: Payload;
  metadata: Metadata;
  type: string;
}

export const isOfType = <T>(varToBeChecked: unknown, propertyToCheckFor: keyof T): varToBeChecked is T =>
  (varToBeChecked as T)[propertyToCheckFor] !== undefined;

export const isResponseError = (response: unknown): response is FetchResponseError => {
  return isOfType<FetchResponseError>(response, 'error') && response.error;
};

export const isActionError = <T>(response: unknown): response is ActionError<T> => {
  return isOfType<ActionError<T>>(response, 'error') && response.error;
};

export type CancelToken = {
  cancel: () => void;
};

export type RouteLocationGlobalState = {
  noScroll?: boolean;
};

// Facades
export type RouteLocation<State = RouteLocationGlobalState> = Location<State>;

export type { History };

export type ReqType = Request & {
  language: string;
};

export type ResType = Response;
export type ReqQuery = FixMe;

export type CancelRequestInfo = CancelTokenSource;

export interface DateRange {
  since: number;
  until: number;
}

export * from './utils';
export * from './redux-form';
export * from 'store/types';

export type ApiMethod = 'get' | 'post' | 'put' | 'delete' | 'patch';

type AsyncActionResponse = {
  parameters: Record<string, unknown>;
  response: Record<string, unknown>;
  urlParameters: Record<string, unknown>;
  additionalData?: Record<string, unknown>;
};

export type AsyncActionSuccessResponse = AsyncActionResponse & {
  data: unknown;
};

export type AsyncActionIteratorData = {
  data: Array<Record<string, unknown>>;
  pagination: Pagination;
};

export type AsyncActionIteratorSuccess = AsyncActionResponse & {
  data: AsyncActionIteratorData;
};

export type AsyncActionErrorResponse = AsyncActionResponse & {
  errorData: Record<string, unknown> | Error;
  errorMessage: string;
};

export type ApiBusinessError = {
  code: string;
  message: string;
  metadata?: Record<string, string>;
};

export type ApiError = {
  code: number;
  errors?: Array<ApiBusinessError>;
  message: string;
};

export type ApiErrorResponseData = {
  code: number;
  status: number;
  statusText: string;
  data: {
    error: ApiError;
  };
};

export type ApiErrorResponse = {
  config: Record<string, unknown>;
  request: XMLHttpRequest;
  response: ApiErrorResponseData;
  status: number;
  statusText: string;
};

export type CancelError = {
  __CANCEL__: boolean;
  message: string;
  response?: ApiErrorResponseData;
};

export type RequestCancelError = {
  isCancelError: boolean;
  message?: string;
};

export type ApiSuccessResponse = {
  data: FixMe;
  status: number;
  statusText: string;
};

type AsyncLoadingActionSuccess = ActionSuccess<string, FixMe, FixMe>;
export type AsyncLoadingAction = string | ((cancelToken: CancelTokenSource) => AsyncLoadingActionSuccess);

export type AsyncActionUrlParameters = Record<string, string | undefined | number | boolean>;
export type AsyncActionQueryParameters = AsyncActionUrlParameters;

export interface SuccessBindingParams<
  Data = FixMe,
  AdditionalData extends Record<string, FixMe> = Record<string, FixMe>,
  Parameters extends Record<string, FixMe> = Record<string, FixMe>,
  UrlParameters extends Record<string, FixMe> = Record<string, FixMe>
> {
  additionalData: AdditionalData;
  parameters: Parameters;
  response: ApiSuccessResponse;
  data: Data;
  urlParameters: UrlParameters;
}

interface ErrorBindingParams<
  AdditionalData extends Record<string, FixMe> = Record<string, FixMe>,
  Parameters extends Record<string, FixMe> = Record<string, FixMe>,
  UrlParameters extends Record<string, FixMe> = Record<string, FixMe>
> {
  errorData: Error | ApiError | RequestCancelError;
  errorMessage: string;
  additionalData: AdditionalData;
  parameters: Parameters;
  response?: ApiErrorResponseData;
  urlParameters: UrlParameters;
}

export interface AsyncActionParams<
  Data = FixMe,
  AdditionalData extends Record<string, FixMe> = Record<string, FixMe>,
  Parameters extends Record<string, FixMe> = Record<string, FixMe>,
  UrlParameters extends Record<string, FixMe> = Record<string, FixMe>,
  SuccessAction = AsyncLoadingActionSuccess,
  ErrorAction = ActionError<string, Record<string, any>>
> {
  additionalData?: AdditionalData;
  absoluteEndPoint?: string;
  allowAnonymous?: boolean;
  contentType?: string;
  endpoint?: string;
  errorBinding: (params: ErrorBindingParams<AdditionalData, Parameters, UrlParameters>) => ErrorAction;
  loadingAction?: AsyncLoadingAction;
  method: ApiMethod;
  parameters?: Parameters;
  successBinding: (params: SuccessBindingParams<Data, AdditionalData, Parameters, UrlParameters>) => SuccessAction;
  urlParameters?: UrlParameters;
  queryParameters?: AsyncActionQueryParameters;
  cancelTokenSelector?: (state: ReduxStore) => CancelTokenSource | undefined;
  includeExperiments?: boolean;
  successAsyncSideEffects?: Array<AsyncLoadingActionSuccess>;
}

export type getStoreFn = () => ReduxStore;
