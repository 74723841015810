import loadable from 'components/dynamic-import';
import { CardCreatorLikeSkeleton } from 'components/component-skeletons';
import { RouteTabsPageProps } from 'components/route-tabs';
import { FullLayout } from 'scenes/layout';
import { checkPermissions, permissions } from 'components/permissions';

const Goals = loadable(() => import('./Goals'), true, <CardCreatorLikeSkeleton />);

const GoalsPageInternal = ({ history, match }: RouteTabsPageProps): JSX.Element => {
  return (
    <FullLayout>
      <Goals history={history} match={match} />
    </FullLayout>
  );
};

export const GoalsPage = checkPermissions({
  requiredPermissions: permissions.GOALS_EDIT,
  redirectToBasePage: true,
})(GoalsPageInternal);
