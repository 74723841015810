const capitalizeFirstLetter = (string: string): string =>
  typeof string !== 'string' || !string ? '' : string[0].toUpperCase() + string.slice(1);

const format = (...rest: Array<string>): string => {
  const string = rest[0];
  return string.replace(/{(\d+)}/g, (match: string, number: number) => {
    const argIndex = parseInt(String(number), 10) + 1;
    return typeof rest[argIndex] !== 'undefined' ? rest[argIndex] : match;
  });
};

const getFormattedPrice = (price: number, currency: string): string => {
  const formattedPrice = (price || 0).toFixed(2);
  if (currency === 'EUR') {
    return `${formattedPrice}€`;
  }
  return `$${formattedPrice}`;
};

const removeWhiteSpaces = (text: string) => {
  if (typeof text !== 'string') {
    return '';
  }
  return text.replace(/ /g, '');
};

export default {
  capitalizeFirstLetter,
  format,
  getFormattedPrice,
  removeWhiteSpaces,
};
