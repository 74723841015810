import { renderRoutes, RouteConfig } from 'react-router-config';
import { PagesModules } from '@streamloots/streamloots-types';
import { PageModulesChecker } from 'components/page-modules-checker';
import type { AsyncActionError } from 'types/indexTS';
import type { PageMetadata } from 'model/indexTS';
import stringFormat from 'helpers/stringFormat';
import MetaTags from 'components/meta-tags';
import { PageTypeApplicationApproved } from 'components/page-type-info';
import { AsyncComponentLoader } from 'components/loading';
import ZendeskWidget from 'components/zendesk-widget';
import { IgnoreChildrenOnFatalError } from 'components/error-boundary';
import Render from 'components/render';
import { NewBuyerStreamerCoupon } from 'components/flash-coupons';
import { TimerPanelContainer } from 'scenes/marathon';
// import { CampaingActive, Campaings } from 'domain/campaing-active';
// import { SubPromo90ChipiChipi } from 'components/campaign-sub-promo-90';
import { SetsProvider } from '../sets';
import { UserSetsProvider } from '../user-sets';
import { useFeedItemEnsurer } from './useFeedItemEnsurer';
import { NewBuyerCouponFetcher } from './NewBuyerCouponFetcher';

type UserPageProps = {
  error?: AsyncActionError | null;
  ogpImage?: string;
  isLoaded: boolean;
  isOwner?: boolean;
  metadata?: PageMetadata;
  route: RouteConfig;
  showSupportChat: boolean;
  slug: string;
  level: number;
};

const textReplacerUser = (text = '', slug: string): string =>
  text.replace(/{{username}}/g, stringFormat.capitalizeFirstLetter(slug));

export const UserPage = (props: UserPageProps): JSX.Element => {
  const { isLoaded, route, isOwner, showSupportChat, error, ogpImage, slug, metadata } = props;
  const metaUserTitle = metadata && textReplacerUser(metadata.title, slug);
  const metaUserDescription = metadata && textReplacerUser(metadata.description, slug);

  useFeedItemEnsurer();

  return (
    <AsyncComponentLoader
      fullPageLoading
      isLoaded={isLoaded}
      renderComponent={() => (
        <>
          {metadata && (
            <>
              <MetaTags description={metaUserDescription} title={metaUserTitle} imageUrl={ogpImage} urlPath={slug} />
              {!error && (
                <>
                  <SetsProvider />
                  <UserSetsProvider />
                </>
              )}
              <IgnoreChildrenOnFatalError>
                <Render.Client>
                  {isOwner && (
                    <>
                      <PageTypeApplicationApproved />
                    </>
                  )}
                  <NewBuyerCouponFetcher />
                  <TimerPanelContainer />
                  <PageModulesChecker modulesRequired={PagesModules.Cards}>
                    <NewBuyerStreamerCoupon />
                  </PageModulesChecker>
                </Render.Client>
              </IgnoreChildrenOnFatalError>
            </>
          )}
          {renderRoutes(route.routes)}
          {showSupportChat && isOwner && <ZendeskWidget />}
        </>
      )}
    />
  );
};
