import { createSelector } from 'reselect';
import type { PermissionsState } from './types';

const permissionsState = (state): PermissionsState => state.permissions;

const permissions = createSelector([permissionsState], permissionsStateInfo => permissionsStateInfo.permissions);

const hasPagePermissions = createSelector(
  [permissionsState],
  permissionsStateInfo => permissionsStateInfo.hasPagePermissions,
);

const isStaff = createSelector([permissionsState], permissionsStateInfo => Boolean(permissionsStateInfo.isStaff));

const hasPermissions = createSelector(
  [permissions, (_state, permissionsParam) => permissionsParam],
  (permissionsInfo, permissionsParam) => permissionsInfo[permissionsParam],
);

export const permissionsSelectors = {
  permissionsState,
  hasPermissions,
  hasPagePermissions,
  isStaff,
  permissions,
};
