export const errorMapping = {
  PAGE_NOT_EXISTS: 'coupons:errors.pageNotExists',
  SET_NOT_EXISTS: 'coupons:errors.setNotExists',
  COUPON_CAN_NOT_START_IN_THE_PAST: 'coupons:errors.CouponCanNotStartInThePast',
  COUPON_ENDS_AT_NOT_GREATER_THAN_STARTS_AT: 'coupons:errors.couponEndsNotGreaterThanStart',
  COUPON_CODE_NOT_UNIQUE: 'coupons:errors.notUnique',
  COUPON_NOT_EXISTS: 'coupons:errors.notExists',
  COUPON_ALREADY_REVOKED: 'coupons:errors.alreadyRevoked',
  CUSTOMER_EQUALS_TO_SELLER_NOT_ALLOWED: 'coupons:errors.notCouponsInOwnPage',
  CUSTOMER_PAYABLE_AMOUNT_LOWER_THAN_MINIMUM: 'coupons:errors.notMinimumPayableAmount',
  COUPON_NOT_VALID: 'coupons:errors.coupon',
  COUPON_USAGE_LIMIT_REACHED: 'lootPacks:couponUsageLimitReached',
  COUPON_NOT_APPLICABLE: 'lootPacks:couponNotApplicable',
  NOT_FOUND: 'lootPacks:invalidCoupon',
};
