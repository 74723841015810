import { createSelector } from 'reselect';
import type { CollectionLoot, Loot, UserSetLoot } from 'model/indexTS';
import type { UserSetLootsState, UserSetLootsBySetId } from './types';

const userSetLootsState = (state): UserSetLootsState => state.userSetLoots;

const userSetIdState = createSelector(
  [userSetLootsState, (_state, setId) => setId],
  (userSetLootsStateInfo: UserSetLootsState, setId: string): UserSetLootsBySetId => userSetLootsStateInfo[setId] || {},
);

const loots = createSelector(
  [userSetIdState],
  (userSetIdStateInfo: UserSetLootsBySetId): UserSetLoot[] | undefined => userSetIdStateInfo.loots,
);

const collectionLoots = createSelector(
  [userSetIdState],
  (userSetIdStateInfo: UserSetLootsBySetId): Array<CollectionLoot> | undefined => userSetIdStateInfo.collectionLoots,
);

const unopenedLootsCount = createSelector(
  [userSetIdState],
  (userSetIdStateInfo: UserSetLootsBySetId): number => userSetIdStateInfo.collectionLoots?.length || 0,
);

const lootImageUrl = createSelector([userSetIdState], (userSetIdStateInfo: UserSetLootsBySetId): string =>
  userSetIdStateInfo ? userSetIdStateInfo.lootImageUrl : '',
);

const hasBuyableLoots = createSelector([userSetIdState], (userSetIdStateInfo: UserSetLootsBySetId): boolean =>
  userSetIdStateInfo ? userSetIdStateInfo.hasBuyableLoots : false,
);

const collectionLootsFromAllUserSets = createSelector([userSetLootsState], (state): UserSetLootsBySetId[] => {
  return Object.values(state);
});

const totalUnopenedLootsCount = createSelector(
  [collectionLootsFromAllUserSets],
  (state: UserSetLootsBySetId[]): number => {
    return state.reduce((acc, curr) => acc + (curr.collectionLoots?.length || 0), 0);
  },
);

const collectionId = createSelector([userSetIdState], (userSetIdStateInfo: UserSetLootsBySetId): string =>
  userSetIdStateInfo ? userSetIdStateInfo.collectionId : '',
);

export const userSetLootsSelectors = {
  lootImageUrl,
  collectionLootsFromAllUserSets,
  totalUnopenedLootsCount,
  loots,
  collectionId,
  collectionLoots,
  hasBuyableLoots,
  unopenedLootsCount,
};
