import { FullLayout } from 'scenes/layout';
import loadable from 'components/dynamic-import';

const ConnectedAdminPayouts = loadable(() => import('./ConnectedAdminPayouts'));

export const AdminPayoutsPage = (): JSX.Element => (
  <FullLayout>
    <ConnectedAdminPayouts />
  </FullLayout>
);
