export enum UserCollectionsActionTypes {
  REQUEST = 'user-collections/REQUEST',
  RECEIVED = 'user-collections/RECEIVED',
  NEXT_RECEIVED = 'user-collections/NEXT_RECEIVED',
  NEXT_REQUEST = 'user-collections/NEXT_REQUEST',
  REQUEST_START_WATCHING_COLLECTION = 'user-collections/REQUEST_START_WATCHING_COLLECTION',
  START_WATCHING_COLLECTION = 'user-collections/START_WATCHING_COLLECTION',
  REQUEST_STOP_WATCHING_COLLECTION = 'user-collections/REQUEST_STOP_WATCHING_COLLECTION',
  STOP_WATCHING_COLLECTION = 'user-collections/STOP_WATCHING_COLLECTION',
}
