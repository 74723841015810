import { useContext, ReactNode } from 'react';
import ClassNames from '@streamloots/classnames';
import { ButtonIcon } from 'ui-library/button';
import { CSSTransition } from 'react-transition-group';
import { OpenContext } from 'contexts/open-provider';
import { Overlay } from './Overlay';
import theme from './layout.scss';

interface LayoutLeftSidebarProps {
  isMobile: boolean;
  children: ReactNode;
}

const classNames = ClassNames(theme);

export const LayoutLeftSidebar = ({ children, isMobile }: LayoutLeftSidebarProps): JSX.Element => {
  const { isOpen, toggle } = useContext(OpenContext);

  return (
    <>
      <Overlay onClick={toggle} show={isOpen && isMobile} />
      <CSSTransition
        timeout={300}
        mountOnEnter
        unmountOnExit
        in={isOpen}
        classNames={{
          enter: classNames('lefttransition--enter'),
          enterActive: classNames('lefttransition--enteractive'),
          exit: classNames('lefttransition--exit'),
        }}
      >
        <aside className={classNames('layout__sidebar__left layout__content')}>
          {isMobile && (
            <ButtonIcon
              icon="publish"
              onClick={toggle}
              simple
              ariaLabel="Close the menu"
              className={classNames('rotate-minus90 layout__sidebar__left__close')}
            />
          )}
          {children}
        </aside>
      </CSSTransition>
    </>
  );
};
