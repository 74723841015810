import { PageFeed as PageFeedResponse } from '@streamloots/streamloots-types';
import { PageFeed } from 'model/indexTS';

export const getPageFeedFromResponse = (pageFeedFromResponse: PageFeedResponse): PageFeed => {
  return {
    ...pageFeedFromResponse,
    createdAt: new Date(pageFeedFromResponse.createdAt),
  };
};

export const getNormalizedPageFeedFromResponse = (pageFeedFromResponse: PageFeedResponse[]): PageFeed[] =>
  pageFeedFromResponse.map(getPageFeedFromResponse);
