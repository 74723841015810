import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import Skeleton from 'ui-library/skeleton';
import { GridRow } from './GridRow';
import { GridHeader } from './GridHeader';
import { GridColumn } from './GridColumn';
import theme from './grid.scss';

const classNames = ClassNames(theme);

interface GridSkeletonProps {
  rowsCount?: number;
  columnsCount?: number;
  className?: string;
}

export const GridSkeleton = ({ rowsCount = 6, columnsCount = 5, className = '' }: GridSkeletonProps): JSX.Element => {
  const rowsArray = new Array(rowsCount);
  const columnsArray = new Array(columnsCount);
  const rows = [...rowsArray.keys()];
  const columns = [...columnsArray.keys()];

  const rowsComponents = rows.map(rowKey => (
    <GridRow key={rowKey}>
      {columns.map(key => (
        <GridColumn key={key} className={classNames('grid__skeleton')}>
          <Skeleton />
        </GridColumn>
      ))}
    </GridRow>
  ));

  return (
    <div className={classNames({ grid: true, [className]: Boolean(className) })}>
      <GridHeader>
        {columns.map(key => (
          <GridColumn key={key}>
            <Skeleton className={classNames('grid__skeleton__header')} />
          </GridColumn>
        ))}
      </GridHeader>
      {rowsComponents}
    </div>
  );
};
