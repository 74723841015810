import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import theme from './two-column-panel.scss';

const classNames = ClassNames(theme);

interface TwoColumnLayoutProps {
  reverse?: boolean;
  largeBreak?: boolean;
  children: React.ReactNode;
}

export const TwoColumnLayout = ({ reverse, children, largeBreak }: TwoColumnLayoutProps) => {
  return (
    <div
      className={classNames({
        'two-column-panel': true,
        'two-column-panel__start': !reverse,
        'two-column-panel__reverse': reverse,
        'two-column-panel__reverse__desktop-break': reverse && !largeBreak,
        'two-column-panel__reverse__large-break': reverse && largeBreak,
        'two-column-panel__desktop-break': !largeBreak && !reverse,
        'two-column-panel__large-break': largeBreak && !reverse,
      })}
    >
      {children}
    </div>
  );
};
