import { DonationPreset } from 'model/donationPreset';
import { DONATIONS_PRESET_ENTITY_RESPONSE } from '@streamloots/streamloots-types';

export const normalizeResponse = (response: DONATIONS_PRESET_ENTITY_RESPONSE): DonationPreset => {
  return {
    _id: response._id,
    active: response.active,
    amount: response.amount,
    currency: response.currency,
    default: response.default,
    imageUrl: response.imageUrl,
    pageId: response.pageId,
  };
};
