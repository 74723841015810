import { checkPermissions, permissions } from 'components/permissions';
import loadable from 'components/dynamic-import';
import { FullLayout } from 'scenes/layout';

const Invoices = loadable(() => import('./components/list'));

const InvoicesPageInternal = () => (
  <FullLayout>
    <Invoices />
  </FullLayout>
);

export const InvoicesPage = checkPermissions({
  requiredPermissions: permissions.BILLING_READ,
  redirectToBasePage: true,
})(InvoicesPageInternal);
