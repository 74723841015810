import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { TranslateInterface } from 'utils/translation';
import { ExplanationPanel } from 'ui-library/explanation-panel';
import Icon from 'ui-library/icons/Icons';
import { ButtonIcon } from 'ui-library/button';
import logo from './assets/logo_powers.svg';
import theme from './powers-explanation.scss';

const classNames = ClassNames(theme);

interface PowersExplanationProps extends TranslateInterface {
  onClick: () => void;
}

export const PowersExplanation = ({ onClick, t }: PowersExplanationProps): JSX.Element => {
  return (
    <ExplanationPanel
      renderImageHeader={() => {
        return (
          <div className={classNames('powers-explanation__logo')}>
            <Icon id="flash" className={classNames('powers-explanation__logo__icon')} />
            <img src={logo} alt="Streamloots Power" />
          </div>
        );
      }}
      horizontal
    >
      <div className={classNames('powers-explanation__container')}>
        <h3 className="margin-bottom-s">{t('powers:whatArePowers')}</h3>
        <p>{t('powers:description')}</p>
      </div>
      <div className={classNames('powers-explanation__button-container')}>
        <ButtonIcon
          className={classNames('powers-explanation__button-container__button')}
          icon="circle-alert"
          onClick={onClick}
          label={t('powers:howItWorks')}
          small
        />
      </div>
    </ExplanationPanel>
  );
};
