import { useState, ReactNode } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import ClassNames from '@streamloots/classnames';
import Icon from 'ui-library/icons';
import theme from './menu-content-components.scss';

const classNames = ClassNames(theme);

export interface MenuExpandableSectionProps {
  children: ReactNode;
  onToggle?: (isOpening: boolean) => void;
  label: string;
  icon: string;
  isSubpageSelected?: boolean;
  isForcedOpen?: boolean;
}

export const MenuExpandableSection = ({
  children,
  onToggle,
  label,
  icon,
  isSubpageSelected,
  isForcedOpen = false,
}: MenuExpandableSectionProps): JSX.Element | null => {
  const [isOpen, setIsOpen] = useState(isSubpageSelected || isForcedOpen);

  const handleToggle = () => {
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);

    if (onToggle) {
      onToggle(newIsOpen);
    }
  };

  return (
    <div className={classNames({ 'menu__expandable': true, 'menu__expandable--expanded': isOpen })}>
      <button
        type="button"
        className={classNames({
          'menu__expandable__header': true,
          'menu__expandable__header--active': !isOpen && isSubpageSelected,
        })}
        onClick={handleToggle}
      >
        <Icon className={theme.menu__link__icon} id={icon} />
        {label}
        <motion.span
          className={classNames('menu__expandable__icon-wrapper')}
          animate={{
            rotate: isOpen ? 180 : 0,
          }}
        >
          <Icon id="chevron-down" />
        </motion.span>
      </button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0, overflow: 'hidden' },
            }}
            transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
