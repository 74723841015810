import type { ReactNode } from 'react';
import ClassNames from '@streamloots/classnames';
import { ExplanationHeaderVariations, ExplanationPanel, ExplanationPanelHeader } from 'ui-library/explanation-panel';
import theme from './auction-explanation-panel.scss';

const classNames = ClassNames(theme);

export interface AuctionExplanationPanelProps {
  title: string;
  description: string;
  children?: ReactNode;
}

export const AuctionExplanationPanel = ({
  title,
  description,
  children,
}: AuctionExplanationPanelProps): JSX.Element => {
  return (
    <ExplanationPanel horizontal largeVertical className="margin-bottom-x2">
      <ExplanationPanelHeader
        icon="auction"
        variation={ExplanationHeaderVariations.WinterSoldier}
        className={classNames('auction-explanation-panel__image')}
      />
      <div>
        <h3 className="margin-bottom-s">{title}</h3>
        <p>{description}</p>
        {children}
      </div>
    </ExplanationPanel>
  );
};
