import { memo } from 'react';
import ClassNames from '@streamloots/classnames';
import Icon from 'ui-library/icons';
import { BaseButtonProps } from './types';
import { BaseButtonComponent } from './BaseButtonComponent';
import theme from './buttons.scss';

const classNames = ClassNames(theme);

export interface ButtonIconProps extends BaseButtonProps {
  icon: string;
  simple?: boolean;
  ariaLabel?: string;
  iconClass?: string;
  description?: string;
  noTextOnSmallRes?: boolean;
  labelClass?: string;
  grey?: boolean;
  iconRight?: boolean;
  role?: string;
  orientantion?: 'vertical' | 'horizontal';
}

const ButtonIcon = ({
  className = '',
  iconClass = '',
  labelClass = '',
  type = 'button',
  component = 'button',
  simple,
  label,
  noTextOnSmallRes,
  icon,
  ariaLabel,
  description,
  grey,
  children,
  iconRight = false,
  loading,
  disabled,
  orientantion = 'horizontal',
  ...rest
}: ButtonIconProps) => {
  const hasLabel = Boolean(label || children);
  const buttonClassNames = classNames({
    'button--icon': true,
    'button--icon--simple': simple,
    'button--icon--nolabel': !hasLabel,
    'button--icon--responsive': noTextOnSmallRes,
    'button--icon--grey': grey,
    'button--icon--vertical': orientantion === 'vertical',
    [className]: Boolean(className),
  });

  // sin label => paddings especiales
  // con label a la izquierda => padding normales - margin derecha, icono
  // con label a la derecha  => padding normales -

  const iconClassNames = classNames({
    [iconClass]: Boolean(iconClass),
    'button--icon__icon': true,
    'button--icon__icon--right': hasLabel && iconRight,
    'button--icon__icon--left': hasLabel && !iconRight,
  });

  const iconToShow = loading ? 'loading' : icon;

  return (
    <BaseButtonComponent
      type={type}
      component={component}
      className={buttonClassNames}
      aria-label={ariaLabel}
      title={description}
      disabled={loading || disabled}
      {...rest}
    >
      {!hasLabel ? (
        <span className="inline-flex">
          <Icon id={iconToShow} className={iconClassNames} description={description} />
        </span>
      ) : (
        <>
          {!iconRight && (
            <span>
              <Icon id={iconToShow} className={iconClassNames} description={description} />
            </span>
          )}
          <span className={classNames({ 'button--icon__label': true, [labelClass]: labelClass })}>
            {label || children}
          </span>
          {iconRight && (
            <span>
              <Icon id={iconToShow} className={iconClassNames} description={description} />
            </span>
          )}
        </>
      )}
    </BaseButtonComponent>
  );
};

export default memo(ButtonIcon);
