import ClassNames from '@streamloots/classnames';
import type { FormElementBaseProps } from './types';
import { Label } from './Label';
import FormElementAdditionalInfo from './FormElementAdditionalInfo';
import theme from './form.scss';

const classNames = ClassNames(theme);

export interface InputTextProps extends FormElementBaseProps {
  autoComplete?: string;
  placeholder?: string;
  type?: string;
  maxLength?: number;
  showError?: boolean;
  grey?: boolean;
  small?: boolean;
  big?: boolean;
  min?: string;
  additionalClassName?: string;
}

export const InputText = ({
  required,
  id,
  disabled,
  placeholder,
  error,
  maxLength,
  value,
  grey,
  showError = true,
  small,
  big,
  className = '',
  labelClassName = '',
  type = 'text',
  label = '',
  additionalClassName = '',
  ariaLabel,
  ...rest
}: InputTextProps): JSX.Element => (
  <>
    {label && (
      <Label className={labelClassName} id={id} error={error} required={required}>
        {label}
      </Label>
    )}
    <input
      id={id}
      className={classNames({
        'input': true,
        'input--grey': grey,
        'input--small': small,
        'input--big': big,
        [className]: className,
      })}
      type={type}
      required={required}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      maxLength={maxLength}
      aria-label={ariaLabel}
      {...rest}
    />
    {showError && (
      <FormElementAdditionalInfo
        inputInfoClassName={additionalClassName}
        error={error}
        maxLength={maxLength}
        value={value}
      />
    )}
  </>
);
