import { PaginationStateNormalizerConfiguration } from 'services/pagination/typesTS';
import { PageFeedQuery } from './types';

export const DEFAULT_PAGE_FEED_QUERY_LIMIT = 20;

export const DEFAULT_PAGE_FEED_QUERY: PageFeedQuery = {
  limit: DEFAULT_PAGE_FEED_QUERY_LIMIT,
  slug: '',
};

export const PAGINATION_STATE_NORMALIZER_CONFIGURATION: PaginationStateNormalizerConfiguration<PageFeedQuery> = {
  initialQuery: DEFAULT_PAGE_FEED_QUERY,
};
