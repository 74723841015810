import React from 'react';
import { pages } from 'services/routing';
import { PageHeader } from 'ui-library/headers';
import loadable from 'components/dynamic-import';
import { checkPermissions, permissions } from 'components/permissions';
import { FullLayout } from '../layout';

const ConnectedAdminViewer = loadable(() => import('./ConnectedAdminViewer'));

const AdminViewer = props => (
  <FullLayout>
    <PageHeader>{'Viewer Information'}</PageHeader>
    <ConnectedAdminViewer {...props} />
  </FullLayout>
);

export default checkPermissions({
  requiredPermissions: [permissions.ADMIN, permissions.SUPPORT],
  redirectTo: pages.STREAMERS,
})(AdminViewer);
