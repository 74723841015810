import _clean from 'lodash-clean';
import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import { UpdateSetActionError, UpdateSetActionSuccess, UpdateSetActionRequest } from '../../types';
import normalizer from '../../normalizer';
import { SetsActionTypes } from '../../actionTypes';
import { SetEditableInfo } from './types';

const updateSetSuccess = ({ data, urlParameters }): UpdateSetActionSuccess =>
  actionCreator.createAction(
    SetsActionTypes.UPDATED,
    {
      set: normalizer.set(data),
    },
    urlParameters,
  );

const updateSetError = ({ errorData, urlParameters }): UpdateSetActionError =>
  actionCreator.createErrorAction(SetsActionTypes.UPDATED, errorData, {}, urlParameters);

const updatingSet = (setId: string): UpdateSetActionRequest =>
  actionCreator.createAction(SetsActionTypes.UPDATING, { setId });

const updateSet = (setEditableInfo: SetEditableInfo, setId: string) => {
  const updatedSet = {
    ...setEditableInfo,
    tags: setEditableInfo.tags?.map(tag => tag.internalName),
  };

  return actionCreator.createAsyncAction({
    endpoint: endpoints.SETS.UPDATE,
    errorBinding: updateSetError,
    loadingAction: () => updatingSet(setId),
    method: 'put',
    parameters: _clean(updatedSet),
    urlParameters: { setId },
    successBinding: updateSetSuccess,
  });
};

const updateCraftableCardsInSet = (craftableCards: boolean, set: SetEditableInfo, setId: string) => {
  const updatedSet = {
    ...set,
    craftableCards,
  };

  return updateSet(updatedSet, setId);
};

export const updateSetActions = {
  updateSet,
  updateCraftableCardsInSet,
};
