import { Dialog } from './Dialog';
import { DialogOpener } from './DialogOpener';
import { DialogContent } from './DialogContent';
import { DialogContentImageColumn } from './DialogContentImageColumn';
import { CleanDialogContent } from './CleanDialogContent';
import { CloseDialogButton } from './CloseDialogButton';
import { ResultDialogContent } from './ResultDialogContent';
import { ResultDialogFooter } from './ResultDialogFooter';
import { SimpleDialogContent } from './SimpleDialogContent';
import { DialogTypes } from './types';

export {
  DialogTypes,
  DialogOpener,
  DialogContent,
  DialogContentImageColumn,
  CleanDialogContent,
  CloseDialogButton,
  ResultDialogContent,
  ResultDialogFooter,
  SimpleDialogContent,
};
export default Dialog;

export type { RenderDialogBodyParams, RenderOpenerDialogParams } from './types';
