import { useEffect, useRef } from 'react';
import { FixMe } from 'types/indexTS';

type CallbackType = () => void;
export const useInterval = (callback: CallbackType, delay: number): void => {
  const savedCallback = useRef<CallbackType>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // eslint-disable-next-line consistent-return
  useEffect((): FixMe => {
    function tick() {
      if (savedCallback?.current) {
        savedCallback.current();
      }
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [delay, callback]);
};
