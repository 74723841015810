export enum PageDonationPresetsActionTypes {
  // Deleting a preset.
  DELETE_PAGE_DONATION_PRESETS_LOADING = 'DELETE_PAGE_DONATION_PRESETS_LOADING',
  DELETE_PAGE_DONATION_PRESETS_ERROR = 'DELETE_PAGE_DONATION_PRESETS_ERROR',
  DELETE_PAGE_DONATION_PRESETS_SUCCESS = 'DELETE_PAGE_DONATION_PRESETS_SUCCESS',

  // Creating a new preset.
  CREATE_PAGE_DONATION_PRESETS_LOADING = 'CREATE_PAGE_DONATION_PRESETS_LOADING',
  CREATE_PAGE_DONATION_PRESETS_ERROR = 'CREATE_PAGE_DONATION_PRESETS_ERROR',
  CREATE_PAGE_DONATION_PRESETS_SUCCESS = 'CREATE_PAGE_DONATION_PRESETS_SUCCESS',

  // Loading all presets.
  GET_ALL_PAGE_DONATION_PRESETS_LOADING = 'GET_ALL_PAGE_DONATION_PRESETS_LOADING',
  GET_ALL_PAGE_DONATION_PRESETS_ERROR = 'GET_ALL_PAGE_DONATION_PRESETS_ERROR',
  GET_ALL_PAGE_DONATION_PRESETS_SUCCESS = 'GET_ALL_PAGE_DONATION_PRESETS_SUCCESS',

  // Loading a single preset.
  GET_ONE_PAGE_DONATION_PRESETS_LOADING = 'GET_ONE_PAGE_DONATION_PRESETS_LOADING',
  GET_ONE_PAGE_DONATION_PRESETS_ERROR = 'GET_ONE_PAGE_DONATION_PRESETS_ERROR',
  GET_ONE_PAGE_DONATION_PRESETS_SUCCESS = 'GET_ONE_PAGE_DONATION_PRESETS_SUCCESS',

  // Updating a preset.
  UPDATE_PAGE_DONATION_PRESETS_LOADING = 'UPDATE_PAGE_DONATION_PRESETS_LOADING',
  UPDATE_PAGE_DONATION_PRESETS_ERROR = 'UPDATE_PAGE_DONATION_PRESETS_ERROR',
  UPDATE_PAGE_DONATION_PRESETS_SUCCESS = 'UPDATE_PAGE_DONATION_PRESETS_SUCCESS',
}
