import type { ReactNode } from 'react';
import ClassNames from '@streamloots/classnames';
import theme from './vertical-list.scss';

const classNames = ClassNames(theme);

export interface VerticalListItemProps {
  children: ReactNode;
  className?: string;
}

export const VerticalListItemDescription = ({ children, className = '' }: VerticalListItemProps): JSX.Element => {
  return <p className={classNames({ vl__item__description: true, [className]: Boolean(className) })}>{children}</p>;
};
