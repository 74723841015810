import { createSelector } from 'reselect';
import type { OverloadedContentState, OverloadedContentStateProps } from './typesTS';

const overloadedContentState = (state): OverloadedContentState => state.overloadedContent;

const dialogProps = createSelector(
  [overloadedContentState],
  (overLoadedContentStateInfo): OverloadedContentStateProps => {
    const { component, componentProps, isOpen, wrapperProps } = overLoadedContentStateInfo;

    return {
      component,
      componentProps,
      wrapperProps,
      isOpen,
    };
  },
);

export const overloadedContentSelectors = {
  dialogProps,
};
