import * as Sentry from '@sentry/react';
import { History } from 'history';
import createSentryMiddleware from 'redux-sentry-middleware';
import { createReduxHistoryContext } from 'redux-first-history';
import { configureStore } from '@reduxjs/toolkit';
import axios from 'axios';
import { currentUserStateListener, pageStateListener } from 'utils/store-state-providers';
import { setUserContext, filterState } from 'utils/sentry';
import { urlsUpdaterMiddleware } from 'services/routing';
import { createStoreObserver } from './store-observers';
import type { ReduxStore } from './types';
import { createRootReducer } from './root-reducer';

declare const window: any;

export default (history: History, initialState: Partial<ReduxStore> = {}) => {
  const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({ history });

  const middlewares = [
    createSentryMiddleware(Sentry, {
      getUserContext: setUserContext,
      stateTransformer: filterState,
    }),
    routerMiddleware,
    urlsUpdaterMiddleware,
  ];

  const devTools =
    __DEV__ && typeof window === 'object' && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
      ? window.__REDUX_DEVTOOLS_EXTENSION__({
          trace: true,
          traceLimit: 25,
        })
      : false;

  const rootReducer = createRootReducer(routerReducer);
  const store = configureStore({
    reducer: rootReducer,
    devTools,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: axios,
        },
        serializableCheck: false,
        immutableCheck: false,
      }).concat(middlewares),
    preloadedState: initialState,
  });

  createStoreObserver(store, pageStateListener);
  createStoreObserver(store, currentUserStateListener);

  if (module.hot) {
    module.hot.accept('./root-reducer', () => {
      try {
        const nextReducer = createRootReducer(routerReducer);

        store.replaceReducer(nextReducer);
      } catch (error) {
        console.error(`==> 😭  Reducer hot reloading error ${error}`);
      }
    });
  }
  return { store, history: createReduxHistory(store) };
};
