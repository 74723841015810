import { differenceInDays } from 'date-fns';
import { StrictOmit } from '@streamloots/streamloots-types';
import type { CardRedemptionLimit, UserCard } from 'model/indexTS';
import { latinize } from 'utils/latinize';
import { CraftingModeActionsSuccessPayload } from 'services/crafting-mode';
import type { UserSetCardsStateBySetId } from './types';
import { DEFAULT_COUNT } from './constants';

type ReturnCardStateBySetIdShape = StrictOmit<UserSetCardsStateBySetId, 'filters' | 'filteredCards'>;

function normalizeCardCount(card: UserCard): UserCard {
  const { count } = card;

  return {
    ...card,
    count: {
      ...DEFAULT_COUNT,
      ...count,
    },
  };
}

function normalizeCardField(fieldValue: string) {
  return fieldValue ? latinize(fieldValue) : '';
}

function getCardStateShape(
  cards: Array<UserCard> = [],
  updateCardFunction: (card: UserCard) => UserCard,
): ReturnCardStateBySetIdShape {
  const cardsById = {};
  let achievedCards = 0;

  const normalizedCards = cards.map(card => {
    const updatedCard = updateCardFunction(card);
    const { firstActivatedAt, count } = updatedCard;

    updatedCard.normalizedDescription = normalizeCardField(updatedCard.description);
    updatedCard.normalizedName = normalizeCardField(updatedCard.name);

    if (count.total > 0) {
      achievedCards += 1;
    }

    if (firstActivatedAt) {
      updatedCard.recentlyPublished = differenceInDays(Date.now(), firstActivatedAt) < 15;
    } else {
      updatedCard.recentlyPublished = false;
    }

    cardsById[card._id] = updatedCard;

    return updatedCard;
  });

  return {
    achievedCards,
    cards: normalizedCards,
    cardsById,
  };
}

function getUserSetCardsState(cards: Array<UserCard>): ReturnCardStateBySetIdShape {
  return getCardStateShape(cards, normalizeCardCount);
}

function getCardAfterRedemption(card, payload) {
  const {
    card: { setCardId, redemptionLimit },
  } = payload;
  if (card._id !== setCardId) {
    return card;
  }
  const count = { ...card.count };
  const { fragmented, fragments } = card;
  count.redeemable -= 1;

  if (fragmented) {
    count.availableFragments -= fragments;
  }

  return {
    ...card,
    redemptionLimit,
    count,
  };
}

function getNewCardStateAfterRedemption(state: UserSetCardsStateBySetId, payload): ReturnCardStateBySetIdShape {
  return getCardStateShape(state.cards, card => getCardAfterRedemption(card, payload));
}

function getNewCardStateAfterCardCooldownError(
  state: UserSetCardsStateBySetId,
  redemptionLimit: CardRedemptionLimit,
): ReturnCardStateBySetIdShape {
  return getCardStateShape(state.cards, card => ({ ...card, redemptionLimit }));
}

function getDropLimitRemainingAfterCraftAction({ dropLimit, dropLimitRemaining, successCount, isDisenchant }) {
  const newDropLimitRemaining = isDisenchant ? dropLimitRemaining + successCount : dropLimitRemaining - successCount;

  if (newDropLimitRemaining > dropLimit) {
    return dropLimit;
  }

  if (newDropLimitRemaining < 0) {
    return 0;
  }

  return newDropLimitRemaining;
}

function getCardAfterCraftAction(card, payload: CraftingModeActionsSuccessPayload, isDisenchant) {
  const { setCardId, collectionCardIds } = payload;

  if (card._id !== setCardId) {
    return card;
  }

  const successCount = collectionCardIds.length;
  const count = { ...card.count };
  const { fragmented, fragments, dropLimitRemaining, dropLimit, dropLimited } = card;

  if (isDisenchant) {
    if (fragmented) {
      count.availableFragments -= successCount;
      count.redeemable = Math.floor(count.availableFragments / fragments);
    } else {
      count.redeemable -= successCount;
    }
  } else if (fragmented) {
    const previousRedeemable = count.redeemable;
    count.availableFragments += successCount;
    count.redeemable = Math.floor(count.availableFragments / fragments);
    count.total = count.total - previousRedeemable + count.redeemable;
  } else {
    count.redeemable += successCount;
    count.total += successCount;
  }

  return {
    ...card,
    dropLimitRemaining: dropLimited
      ? getDropLimitRemainingAfterCraftAction({
          dropLimit,
          dropLimitRemaining,
          successCount,
          isDisenchant,
        })
      : dropLimitRemaining,
    count,
  };
}

function getCardStateAfterCraftingAction(
  state: UserSetCardsStateBySetId,
  payload: CraftingModeActionsSuccessPayload,
  isDisenchant: boolean,
): ReturnCardStateBySetIdShape {
  return getCardStateShape(state.cards, card => getCardAfterCraftAction(card, payload, isDisenchant));
}

export default {
  getCardStateAfterCraftingAction,
  getNewCardStateAfterRedemption,
  getNewCardStateAfterCardCooldownError,
  getUserSetCardsState,
};
