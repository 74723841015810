import { CountdownShapeFromSeconds, getCountdownFormatted, getCountdownShapeFromSeconds } from 'utils/countdown-utils';
import Countdown from 'react-countdown-now';

interface TimerCountdownProps {
  expectedEndingAt: string;
  onFinish?: () => void;
  isPaused: boolean;
  remainingSeconds?: number;
}

const getFormattedTime = ({ days, hours, minutes, seconds }: CountdownShapeFromSeconds) => {
  return getCountdownFormatted({ hours, minutes, seconds, days, daysInHours: true });
};

export const TimerCountdown = ({
  expectedEndingAt,
  onFinish,
  isPaused,
  remainingSeconds = 0,
}: TimerCountdownProps): JSX.Element => {
  if (isPaused) {
    const { hours, minutes, seconds } = getCountdownShapeFromSeconds(remainingSeconds, { daysInHours: true });
    return <>{getFormattedTime({ hours, minutes, seconds })}</>;
  }

  return <Countdown daysInHours onComplete={onFinish} date={expectedEndingAt} renderer={getFormattedTime} />;
};
