import ClassNames from '@streamloots/classnames';
import { RouteComponentProps } from 'react-router-dom';
import { Trans, useTranslation } from 'utils/translation';
import { PanelSkeletons } from 'ui-library/panel';
import { PageHeader } from 'ui-library/headers';
import loadable from 'components/dynamic-import';
import { FullLayout } from 'scenes/layout';
import { BigColumnPanel, SmallColumnPanel, TwoColumnLayout } from 'ui-library/two-column-panel';
import { ExplanationPanel } from 'ui-library/explanation-panel';
import Icon from 'ui-library/icons/Icons';
import { LOCAL_STORAGE_KEYS } from 'constants/local-storage-keys';
import { TipsForAlerts } from './TipsForAlerts';
import theme from './alert-provider.scss';

const classNames = ClassNames(theme);

const ConnectedAlertConfigurationForm = loadable(
  () => import('./ConnectedAlertConfigurationForm'),
  true,
  <PanelSkeletons count={1} />,
);

const AlertProviderPage = ({ history }: RouteComponentProps) => {
  const { t } = useTranslation(['alert', 'common']);

  return (
    <FullLayout>
      <PageHeader>{t('alert:pageTitle')}</PageHeader>
      <TwoColumnLayout>
        <BigColumnPanel>
          <>
            <ExplanationPanel
              className="margin-bottom"
              horizontal
              storageKey={LOCAL_STORAGE_KEYS.ALERTS_HELP}
              renderImageHeader={() => (
                <div className={classNames('alert-provider__explanation-header')}>
                  <Icon id="bell" />
                </div>
              )}
            >
              <div>
                <h3 className="margin-bottom-s">{t('alert:explanation.title')}</h3>
                <p>
                  <Trans i18nKey="alert:explanation.text">
                    {
                      'Alerts are the way we have to connect your Streamloots configuration with your stream. Create a browser source in your broadcasting software and paste the URL below to show packs purchased, cards played and more on stream.'
                    }
                    <a href={t('alert:explanation.knowMoreUrl')}>{t('alert:explanation.knowMore')}</a>
                  </Trans>
                </p>
              </div>
            </ExplanationPanel>
            <div className="margin-bottom">
              <ConnectedAlertConfigurationForm history={history} t={t} />
            </div>
          </>
        </BigColumnPanel>
        <SmallColumnPanel>
          <div className={classNames('alert-provider__tips-panel')}>
            <TipsForAlerts />
          </div>
        </SmallColumnPanel>
      </TwoColumnLayout>
    </FullLayout>
  );
};

export default AlertProviderPage;
