import type { FunctionComponent } from 'react';
import { FullLayout } from 'scenes/layout';
import loadable from 'components/dynamic-import';
import { checkPermissions, permissions } from 'components/permissions';

const ConnectedAdminPartnershipApplications = loadable(() => import('./ConnectedAdminPartnershipApplications'));

const AdminPartnershipApplicationsPageInternal = () => (
  <FullLayout>
    <ConnectedAdminPartnershipApplications />
  </FullLayout>
);

export const AdminPartnershipApplicationsPage: FunctionComponent = checkPermissions({
  requiredPermissions: [permissions.ADMIN, permissions.PARTNERSHIP_MANAGER],
})(AdminPartnershipApplicationsPageInternal);
