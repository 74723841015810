export enum ArticleKey {
  ACHIEVEMENTS = 'ACHIEVEMENTS',
  COOLDOWN = 'COOLDOWN',
  RANKINGS = 'RANKINGS',
  USER_PROFILE = 'USER_PROFILE',
  INTEGRATIONS = 'INTEGRATIONS',
  MAIN_STREAMING_PLATFORM = 'MAIN_STREAMING_PLATFORM',
  BALANCE = 'BALANCE',
  COLLECTIONS = 'COLLECTIONS',
  HYPERWALLET = 'HYPERWALLET',
  SALES_AND_PAYMENTS = 'SALES_AND_PAYMENTS',
  CARD_TYPE_CONFIGURATION = 'CARD_TYPE_CONFIGURATION',
  HOLD_MONEY = 'HOLD_MONEY',
  PROBABILITIES = 'PROBABILITIES',
  POWERS = 'POWERS',
  ALERT_POWERS_CONFIGURATION = 'ALERT_POWERS_CONFIGURATION',
  CHRISTMAS_CAMPAIGN = 'CHRISTMAS_CAMPAIGN',
  TIMER = 'TIMER',
  AUCTION = 'AUCTION',
  OBS_INTEGRATION = 'OBS_INTEGRATION',
  CRAFTING = 'CRAFTING',
  PAYOUT_FREQUENCY = 'PAYOUT_FREQUENCY',
  BILLING_INFORMATION = 'BILLING_INFORMATION',
  HOW_TO_PARTNER = 'HOW_TO_PARTNER',
  COUPONS = 'COUPONS',
  COMMUNITY_RANKING = 'COMMUNITY_RANKING',
  HOW_TO_ADD_BROWSER_SOURCES = 'HOW_TO_ADD_BROWSER_SOURCES',
  ADD_ALERTS_OBS = 'ADD_ALERTS_OBS',
  COUPONS_HOW_TO_USE_THEM = 'COUPONS_HOW_TO_USE_THEM',
}
