/* eslint-disable camelcase */
import { ReqType, ResType } from 'types/indexTS';
import cookies from 'js-cookie';
import trackEventManager from '../event-manager';

export const UTM_COOKIE = 'UTM_CAMPAIGN';

export interface Utm {
  utm_campaign?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_content?: string;
  utm_term?: string;
}

const setUtmCookie = (query: Utm | undefined | Record<string, string>, res: ResType, domain: string): void => {
  if (!query || !query.utm_medium) {
    return;
  }

  const { utm_source, utm_medium, utm_campaign, utm_content, utm_term } = query;
  const newUtmObject = { utm_source, utm_campaign, utm_medium, utm_content, utm_term };
  const nextWeek = new Date();
  nextWeek.setDate(nextWeek.getDate() + 7);
  res.cookie(UTM_COOKIE, JSON.stringify(newUtmObject), {
    expires: nextWeek,
    domain,
    encode: String,
  });
};

const getUtmObject = (reqCookies?: ReqType): Utm | null => {
  const utmString = reqCookies ? reqCookies[UTM_COOKIE] : cookies.get(UTM_COOKIE);

  if (!utmString) {
    return null;
  }

  return JSON.parse(utmString);
};

const removeUtmObject = (): void => {
  cookies.remove(UTM_COOKIE);
};

const sendComeFromCampaignEvent = (): void => {
  if (!window) {
    return;
  }
  if (window.location.href.indexOf('utm_campaign') > -1) {
    const utm = getUtmObject() as Utm;
    trackEventManager.comeFromCampaign(utm);
  }
};

const sendComeFromCampaignCategorizedEvent = (baseOnUrl?: boolean): void => {
  if (typeof window === 'undefined') {
    return;
  }

  if (baseOnUrl && window.location.href.indexOf('utm_campaign') === -1) {
    return;
  }

  const utm = getUtmObject();

  if (!utm) {
    return;
  }
  trackEventManager.comeFromCampaignCategorized(utm);
};

export default {
  getUtmObject,
  removeUtmObject,
  setUtmCookie,
  sendComeFromCampaignEvent,
  sendComeFromCampaignCategorizedEvent,
};
