import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import { SuccessBindingParams } from 'types/indexTS';
import { PageStatics } from 'model/indexTS';
import {
  AdditionalData,
  FetchPageStatisticsByComponentKeyParams,
  GET_PAGE_STATISTICS_ERROR_ACTION,
  GET_PAGE_STATISTICS_SUCCESS_ACTION,
  SUMMARY_KEY,
} from './types';
import { PageStatisticsActionTypes } from './actionTypes';

function getPageStatisticsSuccess({
  data,
  additionalData,
}: SuccessBindingParams<PageStatics, AdditionalData>): GET_PAGE_STATISTICS_SUCCESS_ACTION {
  return actionCreator.createAction(PageStatisticsActionTypes.GET_PAGE_STATISTICS_SUCCESS, data, { ...additionalData });
}

function getPageStatisticsError({ errorData, additionalData }): GET_PAGE_STATISTICS_ERROR_ACTION {
  return actionCreator.createErrorAction(PageStatisticsActionTypes.GET_PAGE_STATISTICS_ERROR, errorData, {
    ...additionalData,
  });
}

const fetchPageStatistics = (pageId: string, fromDate: string = SUMMARY_KEY) => {
  const additionalData: AdditionalData = {
    fromDate,
  };

  const queryParameters = fromDate !== SUMMARY_KEY ? { since: fromDate } : {};

  return actionCreator.createAsyncAction({
    method: 'get',
    endpoint: endpoints.PAGE_STATISTICS.GET,
    errorBinding: getPageStatisticsError,
    urlParameters: { pageId },
    queryParameters,
    loadingAction: PageStatisticsActionTypes.GET_PAGE_STATISTICS_REQUEST,
    successBinding: getPageStatisticsSuccess,
    additionalData,
  });
};

const fetchPageStatisticsByComponentKey = ({
  pageId,
  componentKey,
  fromDate,
}: FetchPageStatisticsByComponentKeyParams) => {
  const additionalData: AdditionalData = {
    fromDate: componentKey,
  };

  const queryParameters = fromDate ? { since: fromDate } : {};

  return actionCreator.createAsyncAction({
    method: 'get',
    endpoint: endpoints.PAGE_STATISTICS.GET,
    errorBinding: getPageStatisticsError,
    urlParameters: { pageId },
    queryParameters,
    loadingAction: PageStatisticsActionTypes.GET_PAGE_STATISTICS_REQUEST,
    successBinding: getPageStatisticsSuccess,
    additionalData,
  });
};

export const pageStatisticsActions = {
  fetchPageStatistics,
  fetchPageStatisticsByComponentKey,
};
