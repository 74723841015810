import React from 'react';
import brandImages from 'constants/brand';

type ImagotypoProps = {
  className?: string;
  src?: string;
};

const Imagotypo = ({ className = '', src = brandImages.secondary }: ImagotypoProps): JSX.Element => (
  <img alt="Streamloots" className={className} src={src} />
);

Imagotypo.defaultProps = {
  className: '',
};

export default Imagotypo;
