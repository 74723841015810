import { SubscriptionStatus } from '@streamloots/streamloots-types';
import stateCreator from 'helpers/stateCreator';
import { Subscription } from 'model/subscription';
import { PageActionTypes } from '../page';
import { SubscriptionsActionTypes } from './actionTypes';
import { SubscriptionsState, SubscriptionActions } from './types';

const initialState: SubscriptionsState = {};

export const reducer = (state: SubscriptionsState = initialState, action: SubscriptionActions): SubscriptionsState => {
  switch (action.type) {
    case SubscriptionsActionTypes.FETCHING:
    case SubscriptionsActionTypes.FETCH_PAYPAL_LOADING:
    case SubscriptionsActionTypes.APPROVE_PAYPAL_LOADING:
      return {
        ...stateCreator.getAsyncLoadingState(state),
        ...state,
      };

    case SubscriptionsActionTypes.FETCH_PAYPAL_ERROR:
    case SubscriptionsActionTypes.ERROR:
    case SubscriptionsActionTypes.APPROVE_PAYPAL_ERROR:
      return stateCreator.getAsyncErrorState(action, state);

    case SubscriptionsActionTypes.RECEIVED: {
      const newState: SubscriptionsState = {
        subscription: { ...action.payload },
      };
      return stateCreator.getInitialAsyncState(state, newState);
    }

    case SubscriptionsActionTypes.CANCEL_SUBSCRIPTION_SUCCESS: {
      const newState: SubscriptionsState = {
        subscription: {
          ...(state.subscription as Subscription),
          status: SubscriptionStatus.Cancelled,
        },
      };
      return stateCreator.getInitialAsyncState(state, newState);
    }

    case SubscriptionsActionTypes.FETCH_PAYPAL_SUCCESS: {
      const newState: SubscriptionsState = {
        ...state,
        paypalPlanId: action.payload.planId,
        paypalClientId: action.payload.clientId,
      };
      return stateCreator.getInitialAsyncState(state, newState);
    }

    case PageActionTypes.CHANGED: {
      return initialState;
    }

    default:
      return state;
  }
};
