import { useState, useRef, useContext, createContext, useCallback, ReactNode } from 'react';
import { ConfirmationDialogContent } from 'components/confirmation-dialog';
import Dialog from 'ui-library/dialog';

interface ConfirmationProps {
  message: string;
  title: string;
  reverse?: boolean;
  ctaText?: string;
  isOpen: boolean;
}

type ConfirmContextState = (props: Omit<ConfirmationProps, 'isOpen'>) => Promise<boolean>;

export const DEFAULT_PROPS: ConfirmationProps = { title: '', message: '', ctaText: '', isOpen: false, reverse: false };

// Create Context
const ConfirmContext = createContext<ConfirmContextState>(() => Promise.resolve(false));

export const useConfirm = () => useContext(ConfirmContext);

export const ConfirmProvider = ({ children }: { children: ReactNode }) => {
  const [options, setOptions] = useState<ConfirmationProps>(DEFAULT_PROPS);
  const awaitingPromiseRef = useRef<any>();

  const openModal = useCallback((props: Omit<ConfirmationProps, 'isOpen'>) => {
    setOptions({ ...props, isOpen: true });

    return new Promise<boolean>(resolve => {
      awaitingPromiseRef.current = { resolve };
    });
  }, []);

  const handleClose = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(false);
    }
    setOptions(DEFAULT_PROPS);
  };

  const handleConfirm = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(true);
    }
    setOptions(DEFAULT_PROPS);
  };

  const { message, isOpen, title, ctaText } = options;
  return (
    <>
      <ConfirmContext.Provider value={openModal}>{children}</ConfirmContext.Provider>

      {isOpen && (
        <Dialog
          active
          renderDialogBody={() => (
            <ConfirmationDialogContent
              title={title}
              confirmationMessage={message}
              onConfirmClick={handleConfirm}
              onClose={handleClose}
              reverse={options.reverse}
              confirmButtonText={ctaText}
            />
          )}
        />
      )}
    </>
  );
};
