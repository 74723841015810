import { useContext, ReactNode } from 'react';
import ClassNames from '@streamloots/classnames';
import { SidebarOpenerContext } from 'contexts/sidebar-opener';
import { Footer } from 'components/footer';
import { CampaingActive, Campaings } from 'domain/campaing-active';
import theme from './layout.scss';

const classNames = ClassNames(theme);

interface LayoutBodyProps {
  children: ReactNode;
}

export const LayoutBody = ({ children }: LayoutBodyProps): JSX.Element => {
  const { attributes, isOpen } = useContext(SidebarOpenerContext);
  const isHalloween = CampaingActive.check(Campaings.HALLOWEEN);
  const isChristmas = CampaingActive.check(Campaings.CHRISTMAS);

  return (
    <div
      className={classNames({
        'layout__body': true,
        'layout__content': true,
        'layout__body__toright': isOpen && attributes.sidebarToContent,
        'halloween': isHalloween,
        'layout__body--christmas': isChristmas,
      })}
    >
      {children}
      <Footer />
    </div>
  );
};
