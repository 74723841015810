import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pageFeedActions } from './actions';
import { pageFeedSelectors } from './selectors';
import {
  CreateFeedParameters,
  CreatePageFeed,
  DeletePageFeed,
  FetchNextPageFeed,
  FetchPageFeed,
  LikeFeedParameters,
  LikePageFeed,
  PageFeedState,
  FetchPageFeedPost,
} from './types';

export interface UsePageFeedActions {
  fetchPageFeed: FetchPageFeed;
  fetchNextPageFeed: FetchNextPageFeed;
  createPageFeed: CreatePageFeed;
  deletePageFeed: DeletePageFeed;
  fetchSingleFeedPost: FetchPageFeedPost;
  likePageFeed: LikePageFeed;
}

export const usePageFeedActions = (): UsePageFeedActions => {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      fetchPageFeed: (slug: string, limit?: number) => dispatch(pageFeedActions.fetchPageFeed(slug, limit)),
      fetchNextPageFeed: (nextUrl: string) => dispatch(pageFeedActions.fetchNextPageFeed(nextUrl)),
      createPageFeed: (slug: string, parameters: CreateFeedParameters) =>
        dispatch(pageFeedActions.createPageFeed(slug, parameters)),
      deletePageFeed: (slug: string, feedId: string) => dispatch(pageFeedActions.deletePageFeed(slug, feedId)),
      fetchSingleFeedPost: (slug: string, feedId: string) =>
        dispatch(pageFeedActions.fetchSingleFeedPost(slug, feedId)),
      likePageFeed: (slug: string, feedId: string, parameters: LikeFeedParameters) =>
        dispatch(pageFeedActions.likePageFeed(slug, feedId, parameters)),
    }),
    [dispatch],
  );
};

export const usePageFeedSelectors = (): PageFeedState => {
  return useSelector(pageFeedSelectors.pageFeedState);
};
