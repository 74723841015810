import { STATIC_FILES_URL_PATH } from './images';

function buildCollectionImagePath(path) {
  return `${STATIC_FILES_URL_PATH}collections/${path}`;
}

export const COLLECTION_IMAGES = [
  buildCollectionImagePath('ill-coll-chatting.png'),
  buildCollectionImagePath('ill-coll-diamond.png'),
  buildCollectionImagePath('ill-coll-donut.png'),
  buildCollectionImagePath('ill-coll-crown.png'),
  buildCollectionImagePath('ill-coll-coin.png'),
  buildCollectionImagePath('ill-coll-clover.png'),
  buildCollectionImagePath('ill-coll-emote.png'),
  buildCollectionImagePath('ill-coll-final_fantasy.png'),
  buildCollectionImagePath('ill-coll-new_world.png'),
  buildCollectionImagePath('ill-coll-gift.png'),
  buildCollectionImagePath('collection_icon_002.png'),
  buildCollectionImagePath('collection_icon_003.png'),
  buildCollectionImagePath('collection_icon_004.png'),
  buildCollectionImagePath('collection_icon_005.png'),
  buildCollectionImagePath('ill-coll-among_us.png'),
  buildCollectionImagePath('ill-coll-fall_guys.png'),
  buildCollectionImagePath('dbd_icon.png'),
  buildCollectionImagePath('ill-coll-nba.png'),
  buildCollectionImagePath('fortnite_icon.png'),
  buildCollectionImagePath('ill-coll-sea_of_thieves.png'),
  buildCollectionImagePath('ill-coll-valorant.png'),
  buildCollectionImagePath('lol_icon.png'),
  buildCollectionImagePath('ill-coll-animal_crossing_01.png'),
  buildCollectionImagePath('ill-coll-animal_crossing_02.png'),
  buildCollectionImagePath('minecraft_icon.png'),
  buildCollectionImagePath('ill-coll-minecraft_dungeons.png'),
  buildCollectionImagePath('warcraft_icon.png'),
  buildCollectionImagePath('ill-coll-genshin-min.png'),
  buildCollectionImagePath('ill-coll-destiny-min.png'),
  buildCollectionImagePath('ill-coll-apex-min.png'),
  buildCollectionImagePath('ill-coll-phasmofobia-min.png'),
  buildCollectionImagePath('ill-coll-cyberpunk-min.png'),
  buildCollectionImagePath('ill-coll-rocket-min.png'),
  buildCollectionImagePath('ill-coll-gta-min.png'),
  buildCollectionImagePath('ill-coll-christmas-min.png'),
];
