const endpoints = {
  ACCESS: {
    OAUTH: '/oauth2/authorize',
    SIGN_IN: '/oauth2/token',
  },
  ACHIEVEMENTS: {
    GET: '/me/achievements',
  },
  ADMIN_PAYOUTS: {
    GET_BALANCES: '/balances',
    PAYMENT_ORDER: {
      CREATE: '/payouts',
      EXECUTE: '/payouts/paypal/payments/:paymentId/executions',
    },
  },
  ADMIN_NOTIFICATIONS: {
    CREATE: '/notifications-in-app-insertions',
  },
  ADMIN_VIEWER: {
    GET_VIEWER: '/users/v2/:slug',
  },
  ADMINISTRATION: {
    PARTNERSHIP: {
      FETCH: '/pages/:slug/partnership',
      UPDATE: '/pages/:slug/partnership',
    },
    PLANS: {
      FETCH: '/plans',
      CREATE: '/plans',
      UPDATE: '/plans/:planId',
      REMOVE: '/plans/:planId',
    },
  },
  ALERT: {
    SKIP: '/alerts/:slug/skips',
    EFFECTS: '/alert-effects',
    DEFAULT_EFFECT: '/me/alert-effect-defaults',
  },
  ALERT_PROVIDER: {
    UPDATE: '/pages/:slug/alert-providers',
  },
  TIMER: {
    UPDATE: '/pages/:slug/timer',
  },
  AUTH_PROVIDERS: {
    LINK: '/me/auth-providers/authorize',
    UPDATE: '/oauth2/authorize',
    PUT: '/me/auth-providers',
    DELETE: '/me/auth-providers/:provider',
  },
  BAN: {
    BAN_USER_FROM_PAGE: '/pages/:slug/bans',
    UNBAN_USER_FROM_PAGE: '/pages/:slug/unbans',
    USERS_BANNED_FROM_PAGE: '/pages/:slug/bans',
  },
  CARD: {
    REDEEM: '/collections/:collectionId/redemptions',
    TEST: '/sets/:setId/cards/:cardId/test-redemptions',
  },
  COOLDOWN: {
    UPDATE: '/pages/:slug/redemption-limits',
    RESET: '/pages/:slug/redemption-rates-resets',
  },
  COUPONS: {
    CREATE: '/coupons',
    REDEEM: '/loot-orders',
    GET: '/coupons',
    GET_SPECIFIC: '/pages/:slug/coupons/:code',
    REVOKE: '/coupons/:couponId/revocations',
  },
  FLOATING_TICKET: {
    CLAIM: '/campaigns/:campaign/claims',
  },
  VIEWERS: {
    GET: '/pages/:slug/viewers',
  },
  MY_CREATORS: {
    GET: '/viewers/:userId/interactions',
  },
  CRAFTING: {
    CRAFTINGS: '/collections/:collectionId/craftings',
    DISENCHANT: '/collections/:collectionId/disenchantings',
    UPDATE: '/sets/:setId/card-crafting-recipes',
  },
  FILES: {
    GET: '/files/:slug',
    PATCH: '/files/:fileId',
    POST: '/files',
    DELETE: '/files/:fileId',
  },
  ADMIN_FILES: {
    GET: '/admin-files',
    PATCH: '/admin-files/:fileId',
    POST: '/admin-files',
    DELETE: '/admin-files/:fileId',
  },
  FRAMES: {
    CREATE: '/frames',
    LIST: '/frames',
    UPDATE: '/frames/:frameId',
    ORDER: '/frames/ordered-frames',
  },
  GOALS: {
    GET: '/pages/:slug/goals',
    CREATE: '/goals',
    UPDATE: '/goals/:targetId',
  },
  PREDEFINED_CARDS: {
    GET_ALL: '/predefined-cards',
    LIST: '/templates',
    CREATE: '/templates',
    DELETE: '/templates',
    GET: '/templates/:templateId',
    EDIT: '/templates/:templateId',
    BULK_EDIT: '/templates',
    PUBLISH: '/templates/publishments',
    UNPUBLISH: '/templates/unpublishments',
  },
  INVOICES: {
    GET: '/invoices',
    DOWNLOAD: '/invoices/:invoiceId',
  },
  LOOTS: {
    OPEN: '/collections/:collectionId/set/:setId/openings',
    UPDATE: '/sets/:setId/loots',
    TEST_ALERT: '/sets/:setId/loots/:lootId/test-purchases',
  },
  LOOTS_ORDERS: {
    GET: '/loot-orders',
    GIFT: '/loot-orders',
  },
  MODERATORS: {
    GET: '/pages/:slug/moderators',
    ADD: '/pages/:slug/moderators',
    DELETE: '/pages/:slug/moderators/:userId',
    UPDATE: '/pages/:slug/moderators/:userId',
  },
  PAGES: {
    GET: '/pages',
    GET_PAGE_CONFIGURATION: '/pages/:slug',
    UPDATE_SETTINGS: '/pages/:slug',
    UPDATE_MODULES: '/pages/:slug/modules',
    UPDATE_PAYMENT_METHODS: '/pages/:slug/payment-methods',
    UPDATE_BILLING_INFORMATION: '/pages/:slug/billing-information',
    UPDATE_INTERNAL_MANAGEMENT: '/pages/:slug/metadata-information',
    UPDATE_PAGE_LEVEL: '/pages/:slug/level-upgrades',
    RANKING: '/pages/:slug/ranking',
    RANKING_VIEWER: '/pages/:slug/ranking-for-viewers',
    GET_COMMUNITY_GIFTS: '/pages/:pageId/community-gifts',
    CLAIM_COMMUNITY_GIFTS: '/pages/:pageId/community-gifts/:communityGiftId/claims',
    CLAIM_PROMOTIONS: '/pages/:pageId/promotions/:promotionName',
    GET_SUBSCRIPTION_GIFTS: '/pages/:slug/subscription-gifts',
  },
  PAYMENT_ORDER: {
    CREATE: '/loot-orders',
    EXECUTE: '/loot-orders/:paymentProcessor/payments/:paymentId/executions',
    GET: '/loot-orders/:paymentProcessor/payments/:paymentId',
  },
  PURCHASES: {
    COUNT: '/loot-orders/purchases-count',
  },
  RANKING: {
    GET: '/pages/:slug/rankings',
    GET_COMMUNITY_LOVE_STREAMERS: '/rankings/global-community-love',
  },
  RARITIES: {
    UPDATE: '/sets/:setId/rarities',
  },
  PARTNERSHIP_APPLICATIONS: {
    GET: '/partnership-applications',
    ACCEPTANCES: '/pages/:slug/partnership-applications/:applicationId/acceptances',
    REJECTIONS: '/pages/:slug/partnership-applications/:applicationId/rejections',
  },
  PAGE_TYPE: {
    APPLY_FOR_AFFILIATE: '/pages/:slug/affiliate-applications',
    APPLY_FOR_PARTNERSHIP: '/pages/:slug/partnership-applications',
    AFFILIATE_APPLICATION_ACKNOWLEDGMENTS: '/pages/:slug/affiliate-applications/:applicationId/acknowledgements',
    PARTNERSHIP_APPLICATION_ACKNOWLEDGMENTS: '/pages/:slug/partnership-applications/:applicationId/acknowledgements',
  },
  REDEMPTIONS: {
    FINISH: '/redemptions/completions',
    RESTORE: '/collections/:collectionId/cards/:cardId/restorations',
    GET: '/redemptions',
    LATEST: '/redemptions/latest-redemptions',
  },
  CARDS: {
    ARCHIVATIONS: '/sets/:setId/card-archivations',
    CARD_ORDERS: '/sets/:setId/card-orders',
  },
  SETS: {
    ACTIVATE_CARDS: '/sets/:setId/card-activations',
    ARCHIVE: '/sets/:setId/archivations',
    CREATE: '/sets',
    CREATE_CARD: '/sets/:setId/cards',
    DEACTIVATE_CARDS: '/sets/:setId/card-deactivations',
    GET: '/sets',
    GET_CARDS: '/sets/:setId/cards',
    TRANSFER_CARDS: '/sets/:setId/cards',
    PUBLISH: '/sets/:setId/publishments',
    UNPUBLISH: '/sets/:setId/unpublishments',
    UPDATE_CARD: '/sets/:setId/cards/:cardId',
    BULK_UPDATE_CARD: '/sets/:setId/cards',
    UPDATE: '/sets/:setId',
    PAUSE: '/sets/:setId/pauses',
    RESUME: '/sets/:setId/resumptions',
    ORDER: '/pages/:pageId/ordered-sets',
  },
  TEXT_TO_SPEECH: {
    GET: '/pages/:slug/alert-providers/streamloots/tts-voices',
  },
  TRANSACTIONS: {
    GET: '/transactions',
  },
  USER: {
    GET_CURRENT_USER_INFO: '/me',
    UPDATE_USER: '/me',
    PATCH: '/me',
    GET_PROFILE_BY_PROVIDER: '/me/auth-providers/:provider/profiles',
    UPDATE_USER_PROFILE: '/me/profile',
    NET_PROMOTER_SCORE: '/me/net-promoter-scores/:netPromoterScoreId',
    UPDATE_PREFERENCES: '/me/preferences',
    UPDATE_EMAIL: '/email-verifications',
    VERIFY_EMAIL: '/email-verifications/:verificationId/verifications',
    TOKENS: '/me/auth-tokens',
    DELETE: '/users/:userId',
  },
  USER_COLLECTIONS: {
    GET: '/collections',
    STOP_WATCHING: '/pages/:slug/watchers/:userId',
    WATCH: '/pages/:slug/watchers',
  },
  USER_SETS: {
    GET: '/pages/:slug/sets',
  },
  ONBOARDING: {
    CREATE: '/wizards',
    UPDATE: '/wizards/:slug',
    GET: '/wizards/:slug',
    TEST: 'wizards/:slug/test-redemptions',
    FINALIZE: 'wizards/v2/:slug/finalizations',
  },
  SAVED_STRIPE_PAYMENT_METHODS: {
    GET: '/me/payment-methods/stripe/cards',
    DELETE: '/me/payment-methods/stripe/cards/:paymentMethodId',
  },
  USERS: {
    SEARCH: '/users',
  },
  REWIND: {
    GET: '/users/:userId',
    FILES: '/promotion-files',
  },
  DISCOVER: {
    GET: '/users/:userId/followed-pages',
  },
  NOTIFICATIONS: {
    GET: '/notifications-in-app',
    COUNT: '/notifications-in-app/count',
    MARK_AS_READ: '/notifications-in-app',
  },
  TAGS: {
    GET: '/tags',
    CREATE: '/tags',
  },
  HEARTBEAT: {
    GET: '/heartbeat',
  },
  AUCTIONS: {
    GET: '/pages/:slug/auctions',
    GET_SPECIFIC: '/pages/:slug/auctions/:auctionId',
    POST: '/pages/:slug/auctions/:auctionId',
    HOLD: '/pages/:slug/auctions/:auctionId/hold',
    EXECUTE_HOLD: '/pages/:slug/auctions/:auctionId/hold/:paymentId/execute',
    PAYMENT: '/pages/:slug/auctions/:auctionId/payment',
    EXECUTE_PAYMENT: '/pages/:slug/auctions/:auctionId/payment/:paymentId/execute',
    CREATE: '/pages/:slug/auctions',
    UPDATE: '/pages/:slug/auctions',
    PATCH: '/pages/:slug/auctions/:auctionId',
  },
  BOT_CONFIGURATIONS: {
    GET: '/pages/:pageId/bot-configurations',
    PUT: '/pages/:pageId/bot-configurations',
  },
  PAGE_STATISTICS: {
    GET: '/pages/:pageId/statistics',
  },
  PROMOTIONS: {
    GET: '/users/:userId/promotions/:promotionName',
  },
  ACTIVITY_FEED: {
    GET: '/pages/:slug/events',
    PUT: '/pages/:slug/events',
  },
  CAMPAIGN: {
    GET: '/campaigns/:campaignName',
    RSVPS: '/campaigns/:campaignName/rsvps',
  },
  MARATHON: {
    GET: '/pages/:slug/marathon',
    PUT: '/pages/:slug/marathon',
    POST: '/pages/:slug/marathon',
    GET_TRACKER: '/pages/:slug/marathontracker',
    START: '/marathons/:marathonId/starts',
    STOP: '/marathons/:marathonId/stops',
    PATCH_TRACKER: '/marathons/:marathonTrackerId',
    ADD_TIME: '/pages/:slug/marathon/additions',
    PAUSE: '/pages/:slug/marathon/pause',
    RESUME: '/pages/:slug/marathon/resume',
  },
  PAGE_SUBSCRIPTION_PLANS: {
    GET: '/pages/:slug/subscription-plans',
    PUT: '/pages/:slug/subscription-plans',
  },
  PAGE_SUBSCRIPTION_STATISTICS: {
    GET: '/pages/:slug/subscription-statistics',
  },
  PAGE_SUBSCRIPTION_STATISTICS_QUERIED: {
    GET: '/pages/:slug/subscription-statistics/v2',
  },
  PAGE_SUBSCRIPTIONS: {
    GET: '/pages/:slug/subscriptions',
    CLAIM_PACK: '/pages/:slug/subscription-packs/:setId/openings',
  },
  PAGE_EMOTES: {
    GET: '/pages/:slug/emotes/v2',
    PLAY: '/pages/:pageId/emotes/play',
  },
  SUBSCRIPTIONS: {
    GET: '/subscriptions/:slug',
    GET_PAYPAL: '/subscriptions/:slug/paypal',
    APPROVE_PAYPAL: '/subscriptions/:slug/paypal',
    PLAY: '/pages/:slug/subscription-alerts',
    POST: '/subscriptions/:slug',
    PAYMENT_CONFIRM: '/subscriptions/:slug/confirm/:paymentId',
    CANCEL: 'subscriptions/:subscriptionId',
    GIFT_INTENT: '/pages/:slug/subscription-gifts',
    GIFT_CONFIRM: '/pages/:slug/subscription-gifts/:transactionId',
    GIFT_PAYPAL: '/pages/:slug/subscription-gifts/paypal',
    GIFT_PAYPAL_CONFIRM: '/pages/subscription-gifts/paypal/confirmation',
  },
  SUBSCRIPTION_ALERTS: {
    GET: '/subscription-alerts',
    CREATE: '/subscription-alerts',
    DELETE: '/subscription-alerts/:subscriptionId',
    EDIT: '/subscription-alerts/:subscriptionId',
    MY_REACTIONS_FETCH: '/subscription-alerts',
    CREATE_MY_REACTIONS: '/subscription-alerts',
    DELETE_MY_REACTIONS: '/subscription-alerts/:alertId',
  },
  SUBSCRIPTION_GIFTS: {
    CLAIM: '/subscription-gifts/:subscriptionGiftId/claims',
  },
  SUBSCRIPTION_REWARDS: {
    GET: 'pages/:pageId/subscription-rewards',
    PLAY: '/subscription-rewards/:rewardId/redeem',
  },
  ACTIVE_SUBSCRIPTION_STATISTICS: {
    GET: '/pages/:slug/active-subscription-statistics',
  },
  EMOTES: {
    GET: '/emotes/v2',
    CREATE: '/emotes',
    DELETE: '/emotes/:emoteId',
    EDIT: '/emotes/:emoteId',
    STREAMER_DELETE: '/emotes/:emoteId',
    STREAMER_ADD: '/emotes',
  },
  EMOTES_COLLECTIONS: {
    GET: '/emotes-collections',
    CREATE: '/emotes-collections',
    DELETE: '/emotes-collections/:collectionId',
    UPDATE: '/emotes-collections/:collectionId',
    STREAMER_UPDATE: '/emotes-collections/:collectionId',
  },
  EMOTES_STYLES: {
    GET: '/emote-styles',
    UPDATE: '/pages/:slug/subscription-plans',
  },
  PAGE_COMMUNICATIONS: {
    GET: '/pages/:slug/page-communications',
    POST: '/pages/:slug/page-communications',
    MANAGE_COMMUNICATIONS: '/pages/:slug/manage-communications',
  },
  ORIGINALS: {
    GET: '/templates/frame/:frameId',
  },
  PAGE_FEED: {
    GET: '/pages/:slug/feed',
    CREATE: '/pages/:slug/feed',
    DELETE: '/pages/:slug/feed/:feedId',
    GET_ONE: '/pages/:slug/feed/:feedId',
    LIKE: '/pages/:slug/feed/:feedId/reaction',
  },
  ORDERS: {
    GET: '/orders/:orderId',
    CREATE: '/orders',
    EXECUTE_PAYPAL: '/payment-providers/paypal/payment-executions',
  },
  PAGE_DONATION_PRESETS: {
    CREATE: '/pages/:slug/donation-presets',
    DELETE: '/pages/:slug/donation-presets/:presetId',
    GET: '/pages/:slug/donation-presets',
    GET_ONE: '/pages/:slug/donation-presets/:presetId',
    UPDATE: '/pages/:slug/donation-presets/:presetId',
  },
  PAGE_DONATION_DONORS: {
    FETCH_ALL: '/pages/:slug/donors',
  },
  PAGE_FEED_COMMENTS: {
    GET: '/pages/:slug/feed/:feedId/comments',
    CREATE: '/pages/:slug/feed/:feedId/comments',
    DELETE: '/pages/:slug/feed/:feedId/comments/:feedCommentId',
    LIKE: '/pages/:slug/feed/:feedId/comment/:feedCommentId/reaction',
  },
  MARKETING_AUTOMATION: {
    CREATE: '/marketing-automation',
  },
};

export default endpoints;
