import ClassNames from '@streamloots/classnames';
import { forwardRefWithAs } from '@reach/utils';
import { useTranslation } from 'utils/translation';

const classNames = ClassNames({});

interface SetStatusProps {
  className?: string;
  published?: boolean;
  paused?: boolean;
  as?: 'div' | 'p';
}

const getStatusTextKey = (paused = false, published = false) => {
  if (paused) {
    return 'adminSets:paused';
  }

  if (!published) {
    return 'adminSets:notPublished';
  }

  return 'adminSets:active';
};

export const SetStatus = forwardRefWithAs<SetStatusProps, 'div' | 'p'>(function SetStatus(
  { as: Component = 'div', className = '', paused, published },
  forwardedRef,
) {
  const { t } = useTranslation('adminSets');
  const statusTextKey = getStatusTextKey(paused, published);
  return (
    <Component
      ref={forwardedRef}
      className={classNames({
        'color-success': !paused && published,
        'color-error': !paused && !published,
        'color-warning': paused,
        [className]: Boolean(className),
      })}
    >
      {t(statusTextKey)}
    </Component>
  );
});
