import { Link } from 'react-router-dom';
import trackEventManager from 'utils/event-manager';
import brandImages from 'constants/brand';
import { urls } from 'services/routing';
import { CampaingActive, Campaings } from 'domain/campaing-active';
import theme from './menu.scss';

interface MainMenuHeaderProps {
  isModeratorMode: boolean;
  isCreatorMode: boolean;
  isAdminMode: boolean;
}

interface BrandImagesForCurrentSeason {
  creatorLogoUrl: string;
  moderatorLogoUrl: string;
  viewerLogoUrl: string;
}

const getBrandImagesForCurrentSeason = (): BrandImagesForCurrentSeason => {
  const isHalloween = CampaingActive.check(Campaings.HALLOWEEN);

  if (isHalloween) {
    return {
      creatorLogoUrl: brandImages.halloween_creator,
      moderatorLogoUrl: brandImages.moderator,
      viewerLogoUrl: brandImages.halloween,
    };
  }

  const isChristmas = CampaingActive.check(Campaings.CHRISTMAS);

  if (isChristmas) {
    return {
      creatorLogoUrl: brandImages.christmas_creator,
      moderatorLogoUrl: brandImages.christmas_moderator,
      viewerLogoUrl: brandImages.christmas_default,
    };
  }

  return {
    creatorLogoUrl: brandImages.creator,
    moderatorLogoUrl: brandImages.moderator,
    viewerLogoUrl: brandImages.secondary,
  };
};

const getImageInfo = (props: MainMenuHeaderProps) => {
  const { isModeratorMode, isCreatorMode, isAdminMode } = props;
  const brandImagesForCurrentSeason = getBrandImagesForCurrentSeason();

  if (isCreatorMode) {
    return { imageUrl: brandImagesForCurrentSeason.creatorLogoUrl, alt: 'Streamloots creator' };
  }

  if (isModeratorMode) {
    return { imageUrl: brandImagesForCurrentSeason.moderatorLogoUrl, alt: 'Streamloots Moderator' };
  }

  if (isAdminMode) {
    return { imageUrl: brandImages.admin, alt: 'Streamloots Admin' };
  }

  return { imageUrl: brandImagesForCurrentSeason.viewerLogoUrl, alt: 'Streamloots' };
};

export const MainMenuHeader = (props: MainMenuHeaderProps): JSX.Element => {
  const { imageUrl, alt } = getImageInfo(props);
  const { isCreatorMode, isModeratorMode } = props;
  const mode = isCreatorMode ? 'creator' : 'regular';

  return (
    <div
      className={theme.menu__logo}
      onClick={() =>
        trackEventManager.trackEvent('mainMenuClick', {
          buttonId: 'logoClicked',
          mode: isModeratorMode ? 'moderator' : mode,
        })
      }
    >
      <Link to={isCreatorMode ? urls().DASHBOARD : urls().USER_COLLECTIONS}>
        <img src={imageUrl} alt={alt} className={theme['menu__logo--isotype']} />
      </Link>
    </div>
  );
};
