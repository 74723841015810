import stateCreator from 'helpers/stateCreator';
import { PageActionTypes } from '../page';
import { PageStatisticsActionTypes } from './actionTypes';
import { PageStatisticsActions, PageStatisticsState } from './types';

const initialState: PageStatisticsState = {};

export const reducer = (state = initialState, action: PageStatisticsActions): PageStatisticsState => {
  switch (action.type) {
    case PageStatisticsActionTypes.GET_PAGE_STATISTICS_REQUEST: {
      return {
        ...state,
        ...stateCreator.getAsyncLoadingState(state),
      };
    }

    case PageStatisticsActionTypes.GET_PAGE_STATISTICS_SUCCESS: {
      return stateCreator.getInitialAsyncState(state, {
        lastRequest: action.metadata.fromDate,
        statics: {
          ...state.statics,
          [action.metadata.fromDate]: { ...action.payload },
        },
      });
    }

    case PageStatisticsActionTypes.GET_PAGE_STATISTICS_ERROR: {
      return stateCreator.getAsyncErrorState(action, state);
    }

    case PageActionTypes.CHANGED: {
      return initialState;
    }

    default:
      return state;
  }
};
