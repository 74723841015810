import { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteConfigComponentProps } from 'react-router-config';
import type { Experiment } from 'model/indexTS';
import trackEventManager from 'utils/event-manager';
import { authSelectors } from 'services/auth';
import { cultureActions } from 'services/culture';
import { userSelectors } from 'services/user';
import App from './App';

type OwnProps = RouteConfigComponentProps;

interface MapStateToProps {
  isUserAuthenticated: boolean;
  mustAcceptTerms?: boolean;
  userExperiments: Experiment[];
}

interface MapDispatchToProps {
  getHeartbeat: () => void;
}

interface ConnectedAppProps extends OwnProps, MapStateToProps, MapDispatchToProps {}

const ConnectedAppBase = ({
  isUserAuthenticated,
  route,
  mustAcceptTerms,
  userExperiments,
  getHeartbeat,
}: ConnectedAppProps): JSX.Element => {
  // Send experiments, since cookie is not being sent through GET /me call for
  // non authenticated users.
  useEffect(() => {
    if (!userExperiments || userExperiments.length === 0) {
      return;
    }

    const experimentsAsAmplitudeProps = userExperiments.reduce((acc, curr) => {
      return { ...acc, [`EXP_${curr.name}`]: curr.segment };
    }, {});
    trackEventManager.updateUserProperties(experimentsAsAmplitudeProps);
  }, [userExperiments]);

  useEffect(() => {
    getHeartbeat();
  }, [getHeartbeat]);

  return <App isUserAuthenticated={isUserAuthenticated} route={route} mustAcceptTerms={mustAcceptTerms} />;
};

const mapStateToProps = (state): MapStateToProps => {
  return {
    isUserAuthenticated: authSelectors.isUserAuthenticated(state),
    mustAcceptTerms: userSelectors.mustAcceptTerms(state),
    userExperiments: userSelectors.userExperimentsMemoized(state),
  };
};

export { ConnectedAppBase as ConnectedAppTesting };

export const ConnectedApp = connect<MapStateToProps, MapDispatchToProps, OwnProps>(mapStateToProps, {
  getHeartbeat: cultureActions.getHeartbeat,
})(ConnectedAppBase);
