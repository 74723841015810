import stateCreator from 'helpers/stateCreator';
import type { MultiSelectionActions, MultiSelectionState, MultiSelectionStateByComponentId } from './types';
import { MultiSelectionActionTypes } from './actionTypes';
import { EMPTY_MULTI_SELECTION_STATE } from './constants';

const multiSelectionStateByKey = (state, action: MultiSelectionActions): MultiSelectionStateByComponentId => {
  switch (action.type) {
    case MultiSelectionActionTypes.INITIALIZE: {
      const { ids, keepSelection } = action.payload;
      if (ids.length === 0) {
        return EMPTY_MULTI_SELECTION_STATE;
      }

      const items = {};
      const stateItems = state.items || {};
      let selectedCount = 0;
      ids.forEach(item => {
        if (!keepSelection) {
          items[item] = false;
        } else {
          const isItemSelected = !!stateItems[item];
          items[item] = isItemSelected;

          if (isItemSelected) {
            selectedCount += 1;
          }
        }
      });

      return {
        items,
        selectedCount,
        totalItems: ids.length,
        allItemsSelected: ids.length === selectedCount,
      };
    }
    case MultiSelectionActionTypes.ALL_ITEMS_TOGGLED: {
      const { items, selectedCount, totalItems } = state;
      const { resetSelection } = action.payload;

      if (!items) {
        return EMPTY_MULTI_SELECTION_STATE;
      }

      const ids = Object.keys(items);

      if (ids.length === 0) {
        return EMPTY_MULTI_SELECTION_STATE;
      }

      const newItems = {};
      const allSelected = resetSelection || (selectedCount > 0 && selectedCount === totalItems);
      ids.forEach(id => {
        newItems[id] = !allSelected;
      });

      return {
        items: newItems,
        totalItems: ids.length,
        selectedCount: allSelected ? 0 : ids.length,
        allItemsSelected: !allSelected,
      };
    }
    case MultiSelectionActionTypes.ITEMS_SELECTED: {
      const { items } = state;
      const { ids, unselectOtherItems } = action.payload;
      const itemIds = Object.keys(items);

      if (itemIds.length === 0) {
        return EMPTY_MULTI_SELECTION_STATE;
      }

      const newSelectedItems = {};
      ids.forEach(id => {
        newSelectedItems[id] = true;
      });

      let selectedCount = 0;
      const newItems = {};

      itemIds.forEach(id => {
        let isSelected = newSelectedItems[id];
        if (!isSelected && unselectOtherItems) {
          isSelected = false;
        }
        newItems[id] = isSelected;

        if (isSelected) {
          selectedCount += 1;
        }
      });

      return {
        items: newItems,
        totalItems: ids.length,
        selectedCount,
        allItemsSelected: selectedCount === itemIds.length,
      };
    }
    case MultiSelectionActionTypes.ITEM_TOGGLED: {
      const { items, selectedCount, totalItems } = state;
      const id = action.payload;
      const newItems = { ...items };
      newItems[id] = !items[id];
      const newCount = selectedCount + (newItems[id] ? 1 : -1);

      return {
        ...state,
        items: newItems,
        selectedCount: newCount,
        allItemsSelected: newCount === totalItems,
      };
    }
    default:
      return state;
  }
};

export const reducer = (state = {}, action: MultiSelectionActions): MultiSelectionState => {
  switch (action.type) {
    case MultiSelectionActionTypes.INITIALIZE:
    case MultiSelectionActionTypes.ALL_ITEMS_TOGGLED:
    case MultiSelectionActionTypes.ITEMS_SELECTED:
    case MultiSelectionActionTypes.ITEM_TOGGLED: {
      const { componentKey } = action.metadata;
      return stateCreator.getUpdatedStateByKey(state, action, componentKey, multiSelectionStateByKey);
    }
    case MultiSelectionActionTypes.DESTROYED: {
      const { componentKey } = action.metadata;
      return stateCreator.getUpdatedStateWithoutKey(state, componentKey);
    }
    default:
      return state;
  }
};
