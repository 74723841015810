import type { Permissions } from 'model/indexTS';
import { pageSpecialPermissionsPrefixes, staffRoles } from 'services/permissions/constants';
import { Role } from '@streamloots/streamloots-types';

export const getPermissionsObject = (
  permissionsArray: Array<string>,
  rolesArray: Array<string>,
): Record<string, boolean> => {
  const permissionsObject = {};
  if (permissionsArray) {
    permissionsArray.forEach(permission => {
      permissionsObject[permission] = true;
    });
  }

  if (rolesArray) {
    rolesArray.forEach(role => {
      permissionsObject[`role:${role}`] = true;
    });
  }
  return permissionsObject;
};

export const hasPermissions = (
  permissions: Permissions | Array<string>,
  acceptedPermissions: string | Array<string>,
): boolean => {
  const permissionsObject = Array.isArray(permissions) ? getPermissionsObject(permissions, []) : permissions;

  if (!acceptedPermissions) {
    return false;
  }

  if (typeof acceptedPermissions === 'string') {
    return permissionsObject[acceptedPermissions];
  }

  let permission;

  for (let i = 0; i < acceptedPermissions.length; i += 1) {
    permission = acceptedPermissions[i];
    if (permissionsObject[permission]) {
      return true;
    }
  }
  return false;
};

export const hasPagePermissions = (pagePermissions: Array<string>) =>
  pagePermissions.filter(permission => {
    // if not contain the special permissions prefix, it does not have page permissions
    return !pageSpecialPermissionsPrefixes.some(prefix => permission.indexOf(prefix) > -1);
  }).length > 0;

export const isInStaffRoles = (roles: Role[]): boolean => roles.some(role => staffRoles.includes(role));
