import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import theme from './illustration-info.scss';

const classNames = ClassNames(theme);

type IllustrationWithInfoProps = {
  className?: string;
  imageUrl: string;
  children?: React.ReactNode;
  alt?: string;
};

const IllustrationWithInfo = ({ children, className = '', imageUrl, alt = '' }: IllustrationWithInfoProps) => {
  const illustrationInfoClassNames = classNames({
    'illustration-info': true,
    [className]: Boolean(className),
  });

  return (
    <div className={illustrationInfoClassNames}>
      <div className="margin-bottom">
        <img className={classNames('illustration-info__img')} src={imageUrl} alt={alt} />
      </div>
      {children}
    </div>
  );
};

export default IllustrationWithInfo;
