import type { Action, AsyncActionState, AsyncIterationState } from 'types/indexTS';
import { isOfType } from 'types/indexTS';

type GenericAsyncState = AsyncActionState | AsyncIterationState<never>;
type GenericState = { [key: string]: any };

const getInitialAsyncState = <State extends GenericAsyncState>(newState: State, reducerState: State): State => {
  const state: State = {
    ...newState,
    error: null,
    isLoading: false,
  };
  if (isOfType<AsyncIterationState<never>>(reducerState, 'isFetchingNextItems')) {
    (state as AsyncIterationState<never>).isFetchingNextItems = false;
  }
  return state;
};

export default {
  getUpdatedStateWithoutKey<State extends { [key: string]: unknown }>(state: State, key: string): State {
    const stateCopy = { ...state };
    delete stateCopy[key];
    return stateCopy;
  },
  getUpdatedStateByKey<
    StateByKey extends GenericState,
    State extends { [key: string]: StateByKey },
    ActionReceived extends Action<string, unknown, unknown>
  >(
    state: State,
    action: ActionReceived,
    key: string,
    processKeyState: (keyState: StateByKey, action: ActionReceived) => StateByKey,
  ): State {
    const newState = { ...state };
    return {
      ...newState,
      [key]: processKeyState(newState[key] || {}, action),
    };
  },
  getAsyncErrorState<ActionReceived extends Action<string, any, Record<string, any>>, State extends GenericAsyncState>(
    action: ActionReceived,
    reducerState: State,
    newState: Partial<State> = {},
  ): State {
    if (!action.error) {
      return reducerState;
    }

    const state = getInitialAsyncState(newState, reducerState);
    const { payload, metadata } = action;

    if (metadata.businessCode === 'REQUEST_CANCELED') {
      return { ...reducerState, ...state };
    }

    state.error = {
      businessCode: metadata.businessCode,
      code: metadata.code,
      message: payload.message,
    };
    return { ...reducerState, ...state };
  },
  getAsyncLoadingState<State extends GenericAsyncState>(reducerState: State, newState: Partial<State> = {}): State {
    const state = getInitialAsyncState(newState, reducerState);
    state.isLoading = true;
    return { ...reducerState, ...state };
  },
  getFetchingNextItemsState<State extends AsyncIterationState<unknown>>(
    reducerState: State,
    newState: Partial<State> = {},
  ): State {
    const state = getInitialAsyncState(newState, reducerState);
    state.isFetchingNextItems = true;
    return { ...reducerState, ...state };
  },
  getInitialAsyncState<State extends GenericAsyncState>(reducerState: State, newState: Partial<State> = {}): State {
    const state = getInitialAsyncState(newState, reducerState);
    return { ...reducerState, ...state };
  },
};
