import { useEffect, useState, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import ClassNames from '@streamloots/classnames';
import { RenderDialogBodyParams, DialogTypes } from './types';
import { DialogOverlay } from './DialogOverlay';
import theme from './dialog.scss';

const classNames = ClassNames(theme);

export interface DialogProps {
  onScroll?: () => void;
  preventClose?: boolean;
  active?: boolean;
  opacity?: boolean;
  onEscKeyDown?: () => void;
  onOverlayClick?: () => void;
  onClose?: () => void;
  renderDialogBody: (params: RenderDialogBodyParams) => ReactNode;
  type?: 'small' | 'normal' | 'large' | 'xlarge' | 'fullscreen' | 'auth';
  wrapperClassName?: string;
  dialogClassName?: string;
  fullOnSmall?: boolean;
  overflowH?: boolean;
  noCentered?: boolean;
  showBlurBackground?: boolean;
}

export const BaseDialog = (props: DialogProps): null | JSX.Element => {
  const {
    active,
    renderDialogBody,
    onOverlayClick,
    onClose,
    type = DialogTypes.NORMAL,
    opacity,
    preventClose,
    fullOnSmall,
    wrapperClassName = '',
    overflowH,
    noCentered,
    dialogClassName = '',
    showBlurBackground,
    onScroll,
    onEscKeyDown,
  } = props;
  const [canRender, setCanRender] = useState(false);

  useEffect(() => {
    setCanRender(true); // To avoid errors on SSR
  },[]); // eslint-disable-line

  useEffect(() => {
    if (!onEscKeyDown) {
      return () => null;
    }

    const handleKeyDown = event => {
      if (event.which === 27) {
        event.preventDefault();
        onEscKeyDown();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onEscKeyDown]);

  if (!active || !canRender) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      onScroll={onScroll}
      className={classNames({
        'dialog__wrapper': true,
        'dialog__wrapper--opacity': opacity,
        'dialog__wrapper--blur': showBlurBackground,
        [wrapperClassName]: wrapperClassName,
      })}
    >
      <DialogOverlay onClick={onOverlayClick} />
      <div
        className={classNames({
          'dialog': true,
          [`dialog--${type}`]: type !== undefined,
          'dialog--fullOnSmall': fullOnSmall,
          'dialog--overflowH': overflowH,
          'dialog--noCentered': noCentered,
          [dialogClassName]: dialogClassName,
        })}
      >
        {renderDialogBody({ onClose, preventClose })}
      </div>
    </div>,
    document.body,
  );
};
