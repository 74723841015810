import loadable from 'components/dynamic-import';
import { FullLayout } from 'scenes/layout';
import Skeleton from 'ui-library/skeleton';

const ActivityFeed = loadable(
  () => import('./components/list-activity-feed'),
  true,
  <>
    <Skeleton />
    <Skeleton />
    <Skeleton />
    <Skeleton />
  </>,
);

export const ActivityFeedPage = (): JSX.Element => {
  return (
    <FullLayout>
      <ActivityFeed />
    </FullLayout>
  );
};
