import loadable from 'components/dynamic-import';

const CookiePolicyEs = loadable(() => import('./CookiePolicyEs'));
const CookiePolicy = loadable(() => import('./CookiePolicy'));
const CookiePolicyUSA = loadable(() => import('./CookiePolicyUSA'));

const TermsAndConditions = loadable(() => import('./TermsAndConditions'));

const PrivacyPolicyEs = loadable(() => import('./PrivacyPolicyEs'));
const PrivacyPolicyEn = loadable(() => import('./PrivacyPolicyEn'));
const PrivacyPolicyUSA = loadable(() => import('./PrivacyPolicyUSA'));

export {
  CookiePolicy,
  CookiePolicyEs,
  CookiePolicyUSA,
  TermsAndConditions,
  PrivacyPolicyEs,
  PrivacyPolicyEn,
  PrivacyPolicyUSA,
};
