import endpoints from 'services/endpoints';
import actionCreator from 'helpers/actionCreator';
import normalizer from './normalizer';
import { UserSetsActionTypes } from './actionTypes';
import type { FetchUserSetsSuccessAction, FetchUserSetsErrorAction } from './types';

const userSetsReceived = ({ data }): FetchUserSetsSuccessAction =>
  actionCreator.createAction(UserSetsActionTypes.FETCH_SUCCESS, {
    data: normalizer.getFilteredAndSortedUserSetsFromApi(data.data),
  });

const userSetsNotReceived = ({ errorData }): FetchUserSetsErrorAction =>
  actionCreator.createErrorAction(UserSetsActionTypes.FETCH_ERROR, errorData);

const fetchUserSets = (slug: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.USER_SETS.GET,
    errorBinding: userSetsNotReceived,
    loadingAction: UserSetsActionTypes.FETCH_REQUEST,
    method: 'get',
    urlParameters: { slug },
    successBinding: userSetsReceived,
  });

export const userSetsActions = {
  fetchUserSets,
};
