import type { ReactNode, CSSProperties, ComponentType } from 'react';
import { transitions, positions, Provider as AlertProvider, useAlert } from 'react-alert';
import { FloatingAlert, AlertTypes } from 'ui-library/alert';
import { AlertTemplate } from './AlertTemplate';

export interface ReactAlertOptions {
  position?: string;
  timeout?: number;
  transition?: string;
  containerStyle?: CSSProperties;
}

export interface ReactDefaultAlertOptions extends ReactAlertOptions {
  className?: string;
  type?: AlertTypes;
}

export interface UseReactAlert {
  show: (renderer: ComponentType<any>) => void;
  showInfo: (message: ReactNode, options?: ReactDefaultAlertOptions) => void;
  showError: (message: ReactNode, options?: ReactDefaultAlertOptions) => void;
  showWarning: (message: ReactNode, options?: ReactDefaultAlertOptions) => void;
  showSuccess: (message: ReactNode, options?: ReactDefaultAlertOptions) => void;
}

const reactAlertOptions: ReactAlertOptions = {
  position: positions.BOTTOM_LEFT,
  timeout: 5000,
  transition: transitions.SCALE,
  containerStyle: {
    marginTop: '24px',
    padding: '0 12px 0 12px',
    zIndex: 1000,
    maxWidth: '960px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
};

export const useReactAlert = (): UseReactAlert => {
  const alert = useAlert();

  const showDefault = (message: ReactNode, options: ReactDefaultAlertOptions = {}) => {
    const { className, type, ...rest } = options;
    return alert.show(
      ({ close }) => (
        <FloatingAlert className={className} type={type} onClose={close}>
          {message}
        </FloatingAlert>
      ),
      rest,
    );
  };

  const showInfo = (message: ReactNode, options: ReactDefaultAlertOptions = {}) => {
    return showDefault(message, {
      ...options,
      type: AlertTypes.INFO,
    });
  };

  const showSuccess = (message: ReactNode, options: ReactDefaultAlertOptions = {}) => {
    return showDefault(message, {
      ...options,
      type: AlertTypes.SUCCESS,
    });
  };

  const showError = (message: ReactNode, options: ReactDefaultAlertOptions = {}) => {
    return showDefault(message, {
      ...options,
      type: AlertTypes.ERROR,
    });
  };

  const showWarning = (message: ReactNode, options: ReactDefaultAlertOptions = {}) => {
    return showDefault(message, {
      ...options,
      type: AlertTypes.WARNING,
    });
  };

  return {
    ...alert,
    showInfo,
    showError,
    showWarning,
    showSuccess,
  };
};

type ReactAlertProviderProps = {
  children: ReactNode;
};

const ReactAlertProvider = ({ children }: ReactAlertProviderProps): JSX.Element => {
  return (
    <AlertProvider template={AlertTemplate} {...reactAlertOptions}>
      {children}
    </AlertProvider>
  );
};

export default ReactAlertProvider;
