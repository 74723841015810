import { useState } from 'react';
import ClassNames from '@streamloots/classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { TranslateInterface } from 'utils/translation';
import Button, { ButtonIcon } from 'ui-library/button';
import Tooltip, { TooltipPlacement } from 'ui-library/tooltip';
import { InputNumber } from 'ui-library/form-elements';
import { TimerCountdown } from './TimerCountdown';
import theme from './tracker-panel.scss';

const classNames = ClassNames(theme);

interface TimerPanelProps extends TranslateInterface {
  expectedEndingAt: string;
  onAdd: (time: number) => void;
  onFinish: () => void;
  isPaused: boolean;
  remainingSeconds?: number;
  isAddingTime: boolean;
  isUpdatingStatus: boolean;
  onChangeStatus: (isPausing: boolean) => void;
}

export const TimerPanel = ({
  t,
  expectedEndingAt,
  onAdd,
  onFinish,
  isPaused,
  onChangeStatus,
  isUpdatingStatus,
  isAddingTime,
  remainingSeconds,
}: TimerPanelProps): JSX.Element => {
  const [time, setTime] = useState<number | ''>(1);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const collapseButtonTooltip = t(
    isCollapsed ? 'marathon:expandFloatingPanelTooltip' : 'marathon:minimizeFloatingPanelTooltip',
  );

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleOnChange = (value: number | '') => {
    setTime(value);
  };

  const handleChangeStatus = () => {
    onChangeStatus(!isPaused);
  };

  const handleClick = () => {
    onAdd(time || 0);
  };

  return (
    <div className={classNames({ 'tracker-panel': true, 'tracker-panel--collapsed': isCollapsed })}>
      <Tooltip tooltip={collapseButtonTooltip} placement={TooltipPlacement.TOP}>
        <ButtonIcon
          icon="chevron-down"
          className={classNames('tracker-panel__collapse')}
          size="sm"
          onClick={handleCollapse}
          ariaLabel={collapseButtonTooltip}
          iconRight
        >
          <AnimatePresence>
            {isCollapsed && (
              <motion.span
                key="timer-countdown-collapse-label"
                className="inline-block"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, width: 'auto' },
                  collapsed: { opacity: 0, width: 0, overflow: 'hidden' },
                }}
                transition={{ duration: 0.3 }}
              >
                <TimerCountdown
                  expectedEndingAt={expectedEndingAt}
                  isPaused={isPaused}
                  remainingSeconds={remainingSeconds}
                />
              </motion.span>
            )}
          </AnimatePresence>
        </ButtonIcon>
      </Tooltip>
      <div className={classNames('tracker-panel__content')}>
        <div className={classNames('tracker-panel__container')}>
          <p className={classNames('tracker-panel__title')}>{t('marathon:actions.timer')}</p>
          <p className={classNames('tracker-panel__countdown')}>
            <TimerCountdown
              onFinish={onFinish}
              expectedEndingAt={expectedEndingAt}
              isPaused={isPaused}
              remainingSeconds={remainingSeconds}
            />
          </p>
        </div>
        <div className={classNames('tracker-panel__actions')}>
          <div className={classNames('padding-right-s')}>
            <InputNumber
              label={t('common:seconds')}
              wrapperClassName={classNames('tracker-panel__input')}
              onChange={handleOnChange}
              value={time}
              id="add-time-to-marathon"
              showButtons={false}
              max={100000}
            />
          </div>
          <Button className="margin-right-s" size="sm" primary onClick={handleClick} loading={isAddingTime}>
            {t('marathon:actions.addTime')}
          </Button>
          <Button size="sm" warning={!isPaused} onClick={handleChangeStatus} cta={isPaused} loading={isUpdatingStatus}>
            {t(isPaused ? 'marathon:actions.resume' : 'marathon:actions.pause')}
          </Button>
        </div>
      </div>
    </div>
  );
};
