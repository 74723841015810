import { MarathonConfig, MarathonData } from 'model/indexTS';
import { omitProperties } from 'utils/mapOperations';

export const getMarathonConfigData = (data: MarathonConfig | null): MarathonData | null => {
  if (data === null) {
    return null;
  }

  return omitProperties<MarathonConfig, MarathonData>(data, ['userId']);
};
