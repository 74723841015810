import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Permissions } from 'model/userTS';
import { permissionsSelectors } from './selectors';

interface UsePermissionsSelectors {
  hasPagePermissions: boolean;
  isStaff: boolean;
  permissions: Permissions;
}

export const usePermissionsSelectors = (): UsePermissionsSelectors => {
  const isStaff = useSelector(permissionsSelectors.isStaff);
  const hasPagePermissions = useSelector(permissionsSelectors.hasPagePermissions);
  const permissions = useSelector(permissionsSelectors.permissions);

  return {
    hasPagePermissions,
    isStaff,
    permissions,
  };
};
