import { PageActionTypes } from 'services/page';
import { paginationStateNormalizer } from 'services/pagination';
import { PAGINATION_STATE_NORMALIZER_CONFIGURATION } from './constants';
import type { BannedUsersState } from './types';
import { BannedUsersActionTypes } from './actionTypes';

const initialState: BannedUsersState = paginationStateNormalizer.getInitial(PAGINATION_STATE_NORMALIZER_CONFIGURATION);

export const reducer = (state = initialState, action): BannedUsersState => {
  const { type, payload, error } = action;
  switch (type) {
    case BannedUsersActionTypes.RECEIVED: {
      if (error) {
        return {
          ...state,
          ...paginationStateNormalizer.getReceivedError(action, state),
        };
      }
      return {
        ...state,
        ...paginationStateNormalizer.getReceived(payload, PAGINATION_STATE_NORMALIZER_CONFIGURATION),
      };
    }
    case BannedUsersActionTypes.REQUEST: {
      const newList = paginationStateNormalizer.getRequest(state, payload, PAGINATION_STATE_NORMALIZER_CONFIGURATION);
      return {
        ...state,
        ...newList,
      };
    }
    case BannedUsersActionTypes.NEXT_REQUEST:
      return {
        ...state,
        ...paginationStateNormalizer.getFetchingNextItems(state, payload.cancelToken),
      };
    case BannedUsersActionTypes.NEXT_RECEIVED: {
      if (error) {
        return {
          ...state,
          ...paginationStateNormalizer.getNextReceivedError(state),
        };
      }

      return {
        ...state,
        ...paginationStateNormalizer.getNextItemsReceived(state, payload),
      };
    }
    case BannedUsersActionTypes.UNBANNED: {
      if (error) {
        return state;
      }
      const {
        query: { active },
      } = state;
      const data = state.data || [];
      const { banInfo } = payload;
      const deactivatedVisible = !active;

      if (!deactivatedVisible) {
        return {
          ...state,
          data: data.filter(unbannedInfo => unbannedInfo._id !== banInfo._id),
        };
      }

      const updatedList = data.map(unbannedInfo => {
        if (unbannedInfo._id === banInfo._id) {
          return { ...banInfo };
        }
        return unbannedInfo;
      });
      return {
        ...state,
        data: updatedList,
      };
    }
    case BannedUsersActionTypes.BANNED: {
      const {
        query: { active },
        data,
      } = state;

      if (error || !active || typeof active === 'undefined') {
        return state;
      }

      const { banInfo } = payload;
      const bannedUsers = data || [];
      return {
        ...state,
        data: [...bannedUsers, { ...banInfo }],
      };
    }
    case PageActionTypes.CHANGED:
      return initialState;
    default:
      return state;
  }
};
