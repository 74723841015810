import { useEffect } from 'react';
import theme from './dialog.scss';

type DialogOverlayProps = {
  onClick?: () => void;
};

export const DialogOverlay = ({ onClick }: DialogOverlayProps): JSX.Element => {
  useEffect(() => {
    const hasClass = document.body.classList.contains(theme.noscroll);

    if (!hasClass) {
      document.body.classList.add(theme.noscroll);
    }

    return () => {
      if (hasClass) {
        return;
      }
      document.body.classList.remove(theme.noscroll);
    };
  });

  return <div data-testid="dialog-overlay" className={theme.dialog__overlay} onClick={onClick} />;
};
