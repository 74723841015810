import { useContext } from 'react';
import { TranslateInterface } from 'utils/translation';
import ClassNames from '@streamloots/classnames';
import { ButtonIcon } from 'ui-library/button';
import { OpenContext } from 'contexts/open-provider';
import theme from './top-menu.scss';

const classNames = ClassNames(theme);

export const MainMenuMobileButton = ({ t }: TranslateInterface): JSX.Element => {
  const { isOpen, toggle } = useContext(OpenContext);
  return (
    <ButtonIcon
      className={classNames('top-menu__mobile__button')}
      selected={isOpen}
      icon="menu_spectrum"
      onClick={toggle}
      simple
      ariaLabel={t('common:menu.viewMenu')}
    />
  );
};
