import React from 'react';
import ClassNames from '@streamloots/classnames';
import { motion } from 'framer-motion';
import Icon from 'ui-library/icons';
import styles from './floating-notifications.scss';

const classNames = ClassNames(styles);

type Props = {
  children: JSX.Element;
  transitionDelay: number;
  onClose: () => void;
  isMobile: boolean;
  id: string;
  className: string;
};

// Contains common stuff for all notification types, for now: x button, animations and wrapper element.
function NotificationWrapper(props: Props): JSX.Element {
  const { children, onClose, transitionDelay, isMobile, id, className } = props;
  const desktopAnimation = {
    initial: { x: '110%' },
    animate: {
      x: '0%',
      transition: {
        x: { duration: 0.5, delay: transitionDelay },
      },
    },
    exit: {
      x: '110%',
      transition: {
        x: { duration: 0.3, delay: 0 },
      },
    },
  };

  const mobileAnimation = {
    initial: { y: '-110%' },
    animate: { y: '0%' },
    exit: { opacity: 0 },
    transition: {
      x: { duration: 0.3 },
    },
  };

  const animation = isMobile ? mobileAnimation : desktopAnimation;

  return (
    <motion.div {...animation} key={id}>
      <motion.div layout className={classNames({ notification: true, [className]: true })}>
        {children}
        <button
          type="button"
          aria-label="Close"
          className={classNames('close')}
          onClick={e => {
            // button should be outside of Link component
            e.preventDefault();
            e.stopPropagation();
            onClose();
          }}
        >
          <Icon id="close" />
        </button>
      </motion.div>
    </motion.div>
  );
}

export default NotificationWrapper;
