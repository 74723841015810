import { Languages } from '@streamloots/streamloots-types';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import {
  format as formatDate,
  formatDistanceToNow,
  FormatDistanceToNowOptions,
  isWithinInterval,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
  endOfDay,
  endOfMonth,
  endOfISOWeek,
} from 'date-fns';

type AcceptedDate = string | number | Date;

interface ZonedDateFromDateAndTimePArams {
  date: string;
  time: string;
  timezone: string;
}

interface FormattedZonedDate {
  date: AcceptedDate;
  format: string;
  timezone: string;
}

interface IsBetweenParams {
  dateToCheck?: AcceptedDate;
  start: AcceptedDate;
  end: AcceptedDate;
}

export enum DateUnit {
  Day = 'day',
  IsoWeek = 'isoweek',
  Month = 'month',
}

interface EndStartZonedParams {
  date: AcceptedDate;
  unit: DateUnit;
  timezone: string;
}

export class DateUtils {
  static serverTimezone = 'Europe/Madrid';

  static startOf(date: AcceptedDate, unit: DateUnit): Date {
    switch (unit) {
      case DateUnit.Day:
        return startOfDay(date);
      case DateUnit.Month:
        return startOfMonth(date);
      case DateUnit.IsoWeek:
        return startOfISOWeek(date);

      default:
        return startOfDay(date);
    }
  }

  static endOf(date: AcceptedDate, unit: DateUnit): Date {
    switch (unit) {
      case DateUnit.Day:
        return endOfDay(date);
      case DateUnit.Month:
        return endOfMonth(date);
      case DateUnit.IsoWeek:
        return endOfISOWeek(date);

      default:
        return endOfDay(date);
    }
  }

  static formatDate(date: AcceptedDate): string {
    return formatDate(date, 'P');
  }

  static formatDateAndTime(date: AcceptedDate): string {
    return formatDate(date, 'P p');
  }

  static formatTime(date: AcceptedDate): string {
    return formatDate(date, 'HH:mm');
  }

  static formatter(
    language: string | string[] | Languages,
    options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' },
  ): Intl.DateTimeFormat {
    return new Intl.DateTimeFormat(language, options);
  }

  static daysFromDate(date: string | Date) {
    const dateTime = typeof date === 'string' ? Date.parse(date) : date.getTime();

    return Math.floor((Date.now() - dateTime) / 86400000);
  }

  static zonedDate(date: AcceptedDate, timezone: string): Date {
    return fromZonedTime(date, timezone);
  }

  static startOfOnZonedDate({ date, unit, timezone }: EndStartZonedParams) {
    const startOf = DateUtils.startOf(date, unit as DateUnit);
    return DateUtils.zonedDate(startOf, timezone);
  }

  static endOfOnZonedDate({ date, unit, timezone }: EndStartZonedParams) {
    const startOf = DateUtils.endOf(date, unit as DateUnit);
    return DateUtils.zonedDate(startOf, timezone);
  }

  static currentZonedDate(date: AcceptedDate, timezone: string): Date {
    return toZonedTime(date, timezone);
  }

  static nowOnTimezone(timezone: string): Date {
    return toZonedTime(Date.now(), timezone);
  }

  static currentZonedFormattedDate({ date, timezone, format }: FormattedZonedDate): string {
    return formatDate(DateUtils.currentZonedDate(date, timezone), format);
  }

  static getDateFromDateAndTime(date: string, time: string): Date {
    return new Date(`${date}T${time}`);
  }

  static zonedDateFromDateAndTime({ date, time, timezone }: ZonedDateFromDateAndTimePArams): Date {
    return DateUtils.zonedDate(`${date}T${time}`, timezone);
  }

  static isBetween(params: IsBetweenParams): boolean {
    const { end, start, dateToCheck = Date.now() } = params;
    return isWithinInterval(dateToCheck, { end, start });
  }

  static fromNow(date: AcceptedDate, options: FormatDistanceToNowOptions = { addSuffix: true }) {
    return formatDistanceToNow(date, options);
  }
}
