import { Component } from 'react';
import ClassNames from '@streamloots/classnames';
import { Link } from 'react-router-dom';
import type { User } from 'model/indexTS';
import { withTranslation, TranslateInterface } from 'utils/translation';
import trackEventManager from 'utils/event-manager';
import Icon from 'ui-library/icons';
import Button from 'ui-library/button';
import { DropdownMenu, DropdownMenuPosition } from 'components/dropdown-menu';
import AvatarImage from 'components/avatar-image';
import { SignOutManager } from 'scenes/auth';
import { urls, pages, getUrlWithParams } from 'services/routing';
import { NotificationsBell } from '../notifications-bell';
import { MenuLink } from '../menu-content-components';
import theme from './top-menu.scss';

const classNames = ClassNames(theme);

interface UserAuthenticatedSectionProps extends TranslateInterface {
  openNotifications: () => void;
  showOnboarding: boolean;
  unreadNotificationsCount: number | string;
  user: User;
  isStaff: boolean;
  inOwnPage: boolean;
}

class UserAuthenticatedSection extends Component<UserAuthenticatedSectionProps> {
  getPageInfo = (page, hasFinishedOnboarding, backToStreamloots) => {
    if (hasFinishedOnboarding && !backToStreamloots) {
      return {
        className: classNames('top-menu__link--creator'),
        link: getUrlWithParams(pages.DASHBOARD, { slug: page.slug }),
        icon: 'card-configuration',
      };
    }

    if (hasFinishedOnboarding && backToStreamloots) {
      return {
        link: getUrlWithParams(pages.USER_COLLECTIONS, { slug: page.slug }),
        icon: 'exit',
      };
    }

    return {
      className: classNames('top-menu__link--creator'),
      link: pages.CREATE_PAGE,
      icon: 'card-configuration',
    };
  };

  renderMyPageLink = (inTopMenu?: boolean) => {
    const {
      user: { page },
      showOnboarding,
      inOwnPage,
      t,
    } = this.props;

    const hasFinishOnboarding = !showOnboarding && page;

    const { className, icon, link } = this.getPageInfo(page, hasFinishOnboarding, inOwnPage);

    if (!inTopMenu) {
      return (
        <MenuLink
          className={className}
          onClick={() => {
            if (!hasFinishOnboarding) {
              trackEventManager.onCreateCollectionClick('topMenuClick');
            }
          }}
          icon={icon}
          to={link}
        >
          <span>{inOwnPage ? t('common:menu.goBackToStreamloots') : t('common:menu.creatorMode')}</span>
        </MenuLink>
      );
    }

    if (hasFinishOnboarding) {
      return null;
    }

    return (
      <Button
        component={Link}
        className={classNames('top-menu__start-page')}
        onClick={() => {
          if (!hasFinishOnboarding) {
            trackEventManager.onCreateCollectionClick('topMenuHighlighted');
          }
        }}
        to={link}
        label={t('common:menu.startAPage')}
        primary
        size="sm"
      />
    );
  };

  renderMenuOptions = () => {
    const { openNotifications, t, unreadNotificationsCount, isStaff } = this.props;
    return (
      <div>
        {this.renderMyPageLink()}
        {isStaff && (
          <MenuLink icon="exit" to={pages.ADMIN_PREDEFINED_CARDS} className={classNames('top-menu__link--staff')}>
            <span>Staff Mode</span>
          </MenuLink>
        )}

        <MenuLink icon="person" to={urls().MY_PROFILE}>
          {t('common:menu.myProfile')}
        </MenuLink>
        <MenuLink className={theme['top-menu__notifications__button']} icon="bell" onClick={openNotifications}>
          {t('common:menu.notifications')}
          {Boolean(unreadNotificationsCount) && (
            <span className={theme['top-menu__notifications__unread']}>{unreadNotificationsCount}</span>
          )}
        </MenuLink>
        <MenuLink
          icon="help-circle"
          onClick={() => trackEventManager.onButtonClick('userMenuHelp')}
          externalLink={t('common:faqLink')}
        >
          {t('common:faq')}
        </MenuLink>
        <SignOutManager
          renderComponent={onSignOutClick => (
            <MenuLink icon="power" onClick={onSignOutClick}>
              {t('common:menu.signOut')}
            </MenuLink>
          )}
        />
      </div>
    );
  };

  renderUserSectionDropdownOpener = ({ active }: { active: boolean }) => {
    const { user, unreadNotificationsCount } = this.props;
    const {
      profile: { avatarImageUrl, username },
    } = user;
    return (
      <div>
        {Boolean(unreadNotificationsCount) && (
          <div className={classNames('top-menu__notifications__user')}>{unreadNotificationsCount}</div>
        )}
        <AvatarImage src={avatarImageUrl} alt={username} className={classNames('top-menu__avatar')} />
        <Icon
          id="chevron-down"
          className={classNames({
            'top-menu__user__open': active,
          })}
        />
      </div>
    );
  };

  render() {
    const { openNotifications, unreadNotificationsCount } = this.props;

    return (
      <>
        {this.renderMyPageLink(true)}
        <NotificationsBell count={unreadNotificationsCount} openNotifications={openNotifications} />
        <DropdownMenu
          className={classNames('top-menu__user')}
          contentClassName={classNames('top-menu__user__dropdown')}
          position={DropdownMenuPosition.BOTTOM_RIGHT}
          clickOnWrapper
          renderContent={this.renderMenuOptions}
          renderComponent={this.renderUserSectionDropdownOpener}
        />
      </>
    );
  }
}

export default withTranslation('common')(UserAuthenticatedSection);
