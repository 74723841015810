import _clean from 'lodash-clean';
import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import { AuctionActionTypes } from './actionTypes';
import { auctionNormalizers } from './normalizer';
import errorMapping from './errorMapping';
import {
  AuctionEndedAction,
  CreateBidOrderParameters,
  ExecuteBidOrderParameters,
  BidRequest,
  CreateAuctionParameters,
  UpdateAuctionParameters,
  PatchAuctionParameters,
} from './types';

function getAuctionsSuccess({ data }) {
  return actionCreator.createAction(
    AuctionActionTypes.GET_AUCTIONS_SUCCESS,
    auctionNormalizers.getAuctionsSuccess(data),
  );
}

function getAuctionsError({ errorData }) {
  return actionCreator.createErrorAction(AuctionActionTypes.GET_AUCTIONS_ERROR, errorData);
}

const fetchAuctions = (slug: string) =>
  actionCreator.createAsyncAction({
    method: 'get',
    endpoint: endpoints.AUCTIONS.GET,
    errorBinding: getAuctionsError,
    urlParameters: { slug },
    loadingAction: AuctionActionTypes.GET_AUCTIONS_REQUEST,
    successBinding: getAuctionsSuccess,
  });

function postBidSuccess({ data, urlParameters }) {
  return actionCreator.createAction(AuctionActionTypes.POST_BID_SUCCESS, {
    ...data,
    auctionId: urlParameters.auctionId,
  });
}

function postBidError({ errorData }) {
  return actionCreator.createErrorAction(AuctionActionTypes.POST_BID_ERROR, errorData, errorMapping);
}

const postBid = (slug: string, auctionId: string, body: BidRequest) =>
  actionCreator.createAsyncAction({
    method: 'post',
    endpoint: endpoints.AUCTIONS.POST,
    errorBinding: postBidError,
    urlParameters: { slug, auctionId },
    parameters: _clean(body),
    loadingAction: AuctionActionTypes.POST_BID_REQUEST,
    successBinding: postBidSuccess,
  });

function fetchSingleAuctionSuccess({ data }) {
  return actionCreator.createAction(AuctionActionTypes.FETCH_SINGLE_AUCTION_SUCCESS, {
    auction: auctionNormalizers.getAuctionFromResponse(data),
  });
}

function fetchSingleAuctionError({ errorData }) {
  return actionCreator.createErrorAction(AuctionActionTypes.FETCH_SINGLE_AUCTION_ERROR, errorData);
}

const fetchSingleAuction = (slug: string, auctionId: string) =>
  actionCreator.createAsyncAction({
    method: 'get',
    endpoint: endpoints.AUCTIONS.GET_SPECIFIC,
    errorBinding: fetchSingleAuctionError,
    urlParameters: { auctionId, slug },
    loadingAction: AuctionActionTypes.FETCH_SINGLE_AUCTION_REQUEST,
    successBinding: fetchSingleAuctionSuccess,
  });

const auctionEnded = (auctionId: string): AuctionEndedAction => {
  return actionCreator.createAction(AuctionActionTypes.COUNTDOWN_ENDED, { auctionId });
};

const createBidOrderSuccess = ({ data }) =>
  actionCreator.createAction(AuctionActionTypes.CREATE_BID_ORDER_SUCCESS, data);

const createBidOrderError = ({ errorData }) =>
  actionCreator.createErrorAction(AuctionActionTypes.CREATE_BID_ORDER_ERROR, errorData, errorMapping);

const createBidOrder = (parameters: CreateBidOrderParameters) => {
  const { auctionId, isHolding, stripeToken, slug } = parameters;
  const endpoint = isHolding ? endpoints.AUCTIONS.HOLD : endpoints.AUCTIONS.PAYMENT;

  return actionCreator.createAsyncAction({
    endpoint,
    errorBinding: createBidOrderError,
    loadingAction: AuctionActionTypes.CREATE_BID_ORDER_REQUEST,
    parameters: {
      stripeToken,
    },
    urlParameters: {
      auctionId,
      slug,
    },
    method: 'post',
    successBinding: createBidOrderSuccess,
  });
};

const executeBidOrderSuccess = ({ data }) =>
  actionCreator.createAction(AuctionActionTypes.EXECUTE_BID_ORDER_SUCCESS, data);

const executeBidOrderError = ({ errorData }) =>
  actionCreator.createErrorAction(AuctionActionTypes.EXECUTE_BID_ORDER_ERROR, errorData, errorMapping);

const executeBidOrder = (parameters: ExecuteBidOrderParameters) => {
  const { auctionId, isHolding, paymentId, slug } = parameters;
  const endpoint = isHolding ? endpoints.AUCTIONS.EXECUTE_HOLD : endpoints.AUCTIONS.EXECUTE_PAYMENT;

  return actionCreator.createAsyncAction({
    endpoint,
    errorBinding: executeBidOrderError,
    loadingAction: AuctionActionTypes.EXECUTE_BID_ORDER_REQUEST,
    urlParameters: {
      auctionId,
      paymentId,
      slug,
    },
    method: 'post',
    successBinding: executeBidOrderSuccess,
  });
};

const createAuctionSuccess = ({ data }) => actionCreator.createAction(AuctionActionTypes.POST_AUCTION_SUCCESS, data);

const createAuctionError = ({ errorData }) =>
  actionCreator.createErrorAction(AuctionActionTypes.POST_AUCTION_ERROR, errorData, errorMapping);

export const createAuction = (parameters: CreateAuctionParameters, slug: string) => {
  return actionCreator.createAsyncAction({
    endpoint: endpoints.AUCTIONS.CREATE,
    errorBinding: createAuctionError,
    loadingAction: AuctionActionTypes.POST_AUCTION_REQUEST,
    method: 'post',
    urlParameters: { slug },
    parameters,
    successBinding: createAuctionSuccess,
  });
};

const updateAuctionSuccess = ({ data }) => actionCreator.createAction(AuctionActionTypes.PUT_AUCTION_SUCCESS, data);

const updateAuctionError = ({ errorData }) =>
  actionCreator.createErrorAction(AuctionActionTypes.PUT_AUCTION_ERROR, errorData, errorMapping);

export const updateAuction = (parameters: UpdateAuctionParameters, slug: string, auctionId: string) => {
  return actionCreator.createAsyncAction({
    endpoint: endpoints.AUCTIONS.UPDATE,
    errorBinding: updateAuctionError,
    loadingAction: AuctionActionTypes.PUT_AUCTION_REQUEST,
    method: 'put',
    urlParameters: { slug, auctionId },
    parameters,
    successBinding: updateAuctionSuccess,
  });
};

const patchAuctionSuccess = ({ data }) => actionCreator.createAction(AuctionActionTypes.PATCH_AUCTION_SUCCESS, data);

const patchAuctionError = ({ errorData }) =>
  actionCreator.createErrorAction(AuctionActionTypes.PATCH_AUCTION_ERROR, errorData, errorMapping);

export const patchAuction = (parameters: PatchAuctionParameters, slug: string, auctionId: string) => {
  return actionCreator.createAsyncAction({
    endpoint: endpoints.AUCTIONS.PATCH,
    errorBinding: patchAuctionError,
    loadingAction: AuctionActionTypes.PATCH_AUCTION_REQUEST,
    method: 'patch',
    urlParameters: { slug, auctionId },
    parameters,
    successBinding: patchAuctionSuccess,
  });
};

export const auctionActions = {
  fetchAuctions,
  postBid,
  fetchSingleAuction,
  auctionEnded,
  executeBidOrder,
  createBidOrder,
  createAuction,
  updateAuction,
  patchAuction,
};
