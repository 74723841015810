import { checkPermissions, permissions } from 'components/permissions';
import { useEffect } from 'react';
import { useCouponsActions } from 'services/coupons';
import { usePageSelectors } from 'services/page';

const NewBuyerCouponFetcherInternal = (): null => {
  const {
    page: { _id },
  } = usePageSelectors();
  const { fetchNewBuyerCoupon } = useCouponsActions();

  useEffect(() => {
    if (_id) {
      fetchNewBuyerCoupon(_id);
    }
  }, [_id, fetchNewBuyerCoupon]);

  return null;
};

export const NewBuyerCouponFetcher = checkPermissions({ requiredPermissions: permissions.COUPONS_READ })(
  NewBuyerCouponFetcherInternal,
);
