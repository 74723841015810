import { AlertProviders } from 'model/indexTS';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReturnTypeFromInterfaceWithFunction } from 'types/utils';
import { alertProvidersActions } from './actions';
import { alertProvidersSelectors } from './selectors';

export type UseAlertProvidersActions = typeof alertProvidersActions;
type SelectorType = typeof alertProvidersSelectors;
interface UseAlertProvidersSelectors {
  alertProviders: ReturnTypeFromInterfaceWithFunction<SelectorType, 'alertProviders'>;
  targetId?: string;
  marathonWidgetUrl?: string;
  alertWidgetUrl: string;
}

export const useAlertProvidersSelectors = (): UseAlertProvidersSelectors => {
  const alertProviders = useSelector(alertProvidersSelectors.alertProviders);
  const alertWidgetUrl = useSelector(alertProvidersSelectors.alertWidgetUrl);
  const { targetId, marathonWidgetUrl } = useMemo(() => {
    if (!alertWidgetUrl) {
      return {
        targetId: undefined,
        marathonWidgetUrl: undefined,
      };
    }

    return {
      targetId: alertWidgetUrl.split('/')[4],
      marathonWidgetUrl: alertWidgetUrl.replace('alerts', 'marathons'),
    };
  }, [alertWidgetUrl]);

  return {
    alertWidgetUrl,
    alertProviders,
    targetId,
    marathonWidgetUrl,
  };
};

export const useAlertProvidersActions = (): UseAlertProvidersActions => {
  const dispatch = useDispatch();
  return useMemo(() => {
    return {
      updateAlertProviders: (params: AlertProviders, slug: string) =>
        dispatch(alertProvidersActions.updateAlertProviders(params, slug)),
    };
  }, [dispatch]);
};
