// Action Types.
export enum SubscriptionGiftsActionTypes {
  FETCH_ERROR = 'page-subscription-gifts/FETCH_ERROR',
  FETCH_LOADING = 'page-subscription-gifts/FETCH_LOADING',
  FETCH_SUCCESS = 'page-subscription-gifts/FETCH_SUCCESS',
  CLAIM_ERROR = 'page-subscription-gifts/CLAIM_ERROR',
  CLAIM_SUCCESS = 'page-subscription-gifts/CLAIM_SUCCESS',
  CREATE_SUBSCRIPTION_GIFT_ORDER_REQUEST = 'page-subscription-gifts/CREATE_SUBSCRIPTION_GIFT_ORDER_REQUEST',
  CREATE_SUBSCRIPTION_GIFT_ORDER_SUCCESS = 'page-subscription-gifts/CREATE_SUBSCRIPTION_GIFT_ORDER_SUCCESS',
  CREATE_SUBSCRIPTION_GIFT_ORDER_ERROR = 'page-subscription-gifts/CREATE_SUBSCRIPTION_GIFT_ORDER_ERROR',
  EXECUTE_SUBSCRIPTION_GIFT_ORDER_REQUEST = 'page-subscription-gifts/EXECUTE_SUBSCRIPTION_GIFT_ORDER_REQUEST',
  EXECUTE_SUBSCRIPTION_GIFT_ORDER_SUCCESS = 'page-subscription-gifts/EXECUTE_SUBSCRIPTION_GIFT_ORDER_SUCCESS',
  EXECUTE_SUBSCRIPTION_GIFT_ORDER_ERROR = 'page-subscription-gifts/EXECUTE_SUBSCRIPTION_GIFT_ORDER_ERROR',
  CREATE_SUBSCRIPTION_GIFT_PAYPAL_ORDER_REQUEST = 'page-subscription-gifts/CREATE_SUBSCRIPTION_GIFT_PAYPAL_ORDER_REQUEST',
  CREATE_SUBSCRIPTION_GIFT_PAYPAL_ORDER_SUCCESS = 'page-subscription-gifts/CREATE_SUBSCRIPTION_GIFT_PAYPAL_ORDER_SUCCESS',
  CREATE_SUBSCRIPTION_GIFT_PAYPAL_ORDER_ERROR = 'page-subscription-gifts/CREATE_SUBSCRIPTION_GIFT_PAYPAL_ORDER_ERROR',
  EXECUTE_SUBSCRIPTION_GIFT_PAYPAL_ORDER_REQUEST = 'page-subscription-gifts/EXECUTE_SUBSCRIPTION_GIFT_PAYPAL_ORDER_REQUEST',
  EXECUTE_SUBSCRIPTION_GIFT_PAYPAL_ORDER_SUCCESS = 'page-subscription-gifts/EXECUTE_SUBSCRIPTION_GIFT_PAYPAL_ORDER_SUCCESS',
  EXECUTE_SUBSCRIPTION_GIFT_PAYPAL_ORDER_ERROR = 'page-subscription-gifts/EXECUTE_SUBSCRIPTION_GIFT_PAYPAL_ORDER_ERROR',
}
