import * as React from 'react';
import ILLUSTRATIONS from 'constants/illustrations';
import { withTranslation, TranslateInterface } from 'utils/translation';
import Button from 'ui-library/button';
import { ErrorPage } from './ErrorPage';

const FatalErrorInternal = ({ t }: TranslateInterface): JSX.Element => (
  <ErrorPage
    imageUrl={ILLUSTRATIONS.CATASTROPHIC}
    title={t('errorPage.serverErrorTitle')}
    description={t('errorPage.serverErrorDescription')}
  >
    <Button cta onClick={() => window.location.reload()}>
      {t('errorPage.refresh')}
    </Button>
  </ErrorPage>
);

export const FatalError = withTranslation('errors')(FatalErrorInternal) as React.FunctionComponent;
