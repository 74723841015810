import stateCreator from 'helpers/stateCreator';
import type { PredefinedCardsPublicState, PredefinedCardsPublicActions } from './types';
import { PublicPredefinedCardsActionTypes } from './actionTypes';
import { PublicPredefinedCardsFetchedData } from './types';

function getPredefinedCardsState(
  state: PublicPredefinedCardsFetchedData = {},
  action: PredefinedCardsPublicActions,
): PublicPredefinedCardsFetchedData {
  switch (action.type) {
    case PublicPredefinedCardsActionTypes.FETCHING_PREDEFINED_CARDS: {
      return stateCreator.getAsyncLoadingState(state, {
        filters: action.payload.filters,
      });
    }

    case PublicPredefinedCardsActionTypes.PREDEFINED_CARDS_RECEIVED: {
      if (action.error) {
        return stateCreator.getAsyncErrorState(action, state);
      }
      const { payload } = action;
      return stateCreator.getInitialAsyncState(state, {
        filters: payload.filters,
        frames: payload.frames,
        frameTags: payload.frameTags,
        predefinedCards: payload.templates,
        predefinedCardsTags: payload.templateTags,
      });
    }
    default:
      return state;
  }
}

export const reducer = (
  state: PredefinedCardsPublicState = {},
  action: PredefinedCardsPublicActions,
): PredefinedCardsPublicState => {
  switch (action.type) {
    case PublicPredefinedCardsActionTypes.FETCHING_PREDEFINED_CARDS:
    case PublicPredefinedCardsActionTypes.PREDEFINED_CARDS_RECEIVED: {
      return stateCreator.getUpdatedStateByKey(state, action, action.metadata.componentKey, getPredefinedCardsState);
    }
    default:
      return state;
  }
};
