export enum GoalActionTypes {
  CREATE_REQUEST = 'goal/CREATE_REQUEST',
  CREATE_ERROR = 'goal/CREATE_ERROR',
  CREATE_SUCCESS = 'goal/CREATE_SUCCESS',
  UPDATED_REQUEST = 'goal/UPDATED_REQUEST',
  UPDATED_ERROR = 'goal/UPDATED_ERROR',
  UPDATED_SUCCESS = 'goal/UPDATED_SUCCESS',
  FETCH_REQUEST = 'goal/FETCH_REQUEST',
  FETCH_ERROR = 'goal/FETCH_ERROR',
  FETCH_SUCCESS = 'goal/FETCH_SUCCESS',
}
