import { FunctionComponent, useEffect } from 'react';
import { STATIC_IMAGES_PATH } from 'constants/images';
import { withTranslation, TranslateInterface } from 'utils/translation';
import { AuthSection } from 'scenes/auth';
import { ErrorPage } from './ErrorPage';

export type UserErrorType = 'invalidateToken' | 'errorLoading';

interface OwnProps {
  signOut: () => void;
  type: UserErrorType;
}

interface LoadingUserInformationProps extends TranslateInterface, OwnProps {}

const getTexts = (type: UserErrorType) => {
  if (type === 'invalidateToken') {
    return {
      descriptionI18nKey: 'authorizationErrorDescription',
      titleI18nKey: 'authorizationErrorTitle',
    };
  }

  return {
    descriptionI18nKey: 'loadingUserInformationErrorDescription',
    titleI18nKey: 'loadingUserInformationErrorTitle',
  };
};

const UserErrorDialogContentBase = ({ type, t, signOut }: LoadingUserInformationProps): JSX.Element => {
  useEffect(() => {
    signOut();
  }, [signOut]);

  const texts = getTexts(type);

  return (
    <ErrorPage
      imageUrl={`${STATIC_IMAGES_PATH}onboarding/robot-04.png`}
      title={t(`errorPage.${texts.titleI18nKey}`)}
      description={t(`errorPage.${texts.descriptionI18nKey}`)}
    >
      <AuthSection componentKey="userError" location="user error modal" />
    </ErrorPage>
  );
};

export const UserErrorDialogContent = withTranslation(['errors', 'common'])(UserErrorDialogContentBase);
