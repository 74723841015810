import { useAuthActions } from 'services/auth';
import auth from 'utils/authCookieManager';
import trackEventManager from 'utils/event-manager';
import sharedCookieManager from 'utils/sharedCookieManager';

type SignOutUser = () => void;

interface SignOutManagerProps {
  refreshPage?: boolean;
  renderComponent: (onSignOut: SignOutUser) => JSX.Element;
}

export const SignOutManager = ({ refreshPage = true, renderComponent }: SignOutManagerProps): JSX.Element => {
  const { disconnectUser } = useAuthActions();

  const handleSignOut = () => {
    auth.signOutUser();
    trackEventManager.signOut();
    sharedCookieManager.removeUserAuthenticatedCookies();

    if (!refreshPage) {
      return;
    }

    disconnectUser();

    if (typeof window !== 'undefined') {
      window.location.reload();
    }
  };

  return renderComponent(handleSignOut);
};
