import React from 'react';
import Icon from 'ui-library/icons';
import { ValueOf } from 'types/indexTS';
import { ALERT_TYPES } from './constants';

type AlertIconProps = {
  type?: ValueOf<typeof ALERT_TYPES>;
};

const AlertIcon = ({ type }: AlertIconProps) => {
  let id = 'circle-information';

  if (type === ALERT_TYPES.ERROR) {
    id = 'circle-close';
  }

  if (type === ALERT_TYPES.SUCCESS) {
    id = 'check';
  }

  if (type === ALERT_TYPES.WARNING) {
    id = 'base_alert';
  }

  return <Icon id={id} />;
};

export default AlertIcon;
