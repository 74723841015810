import ClassNames from '@streamloots/classnames';
import { ButtonIcon } from 'ui-library/button';
import theme from './explanation-panel.scss';

const classNames = ClassNames(theme);

interface CloseDialogIconProps {
  onClose: () => void;
  className?: string;
}

export const CloseExplanationPanel = ({ className = '', onClose }: CloseDialogIconProps): JSX.Element => {
  return (
    <ButtonIcon
      className={classNames({
        'explanation-panel__close': true,
        [className]: Boolean(className),
      })}
      onClick={onClose}
      icon="close"
      ariaLabel="Close"
      data-testid="dialog-close"
    />
  );
};
