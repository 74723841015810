const BUILD_SHORT_SHA = process.env.BUILD_SHORT_SHA || 'zzzzzzzz';
const STATIC_URL = 'https://static.streamloots.com';

export const getBaseUrl = ({ local } = { local: false }) => {
  let basePath = 'p';
  if (!local) {
    basePath = `49fb65d4-336f-48e1-918d-404ee420e84e/${BUILD_SHORT_SHA}`;
  }
  return basePath;
};

export const getUrl = ({ local } = { local: false }) => {
  let rootPath = '';
  if (!local) {
    rootPath = STATIC_URL;
  }
  const basePath = getBaseUrl({ local });
  return `${rootPath}/${basePath}`;
};
