import loadable from 'components/dynamic-import';
import { FullLayout } from 'scenes/layout';

const CommunityRanking = loadable(() => import('./CommunityRanking'));

const CommunityRankingPage = () => {
  return (
    <FullLayout>
      <CommunityRanking />
    </FullLayout>
  );
};

export default CommunityRankingPage;
