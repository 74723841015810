import loadable from 'components/dynamic-import';
import { FullLayout } from '../layout';

const AuctionEditor = loadable(() => import('./AuctionEditor'));

export const AuctionEditorPage = () => {
  return (
    <FullLayout>
      <AuctionEditor />
    </FullLayout>
  );
};
