import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { TranslateInterface } from 'utils/translation';
import RadioButtonCard from 'ui-library/radio-button-card';
import theme from './set-radio-button-cards.scss';
import { SetRadioItem } from './types';

const classNames = ClassNames(theme);

type SetRadioCardItemProps = TranslateInterface & {
  id: string;
  name: string;
  checked: boolean;
  item: SetRadioItem;
  isPhone?: boolean;
  onChange: (setId: string) => void;
};

export const SetRadioCardItem = ({ item, checked, t, onChange, isPhone = false, ...props }: SetRadioCardItemProps) => {
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onChange(evt.target.value);
  };

  return (
    <RadioButtonCard
      label={`${item.name} ${item.cardAmount}`}
      checked={checked}
      onChange={handleChange}
      value={item._id}
      selectable={item.selectable}
      isPhone={isPhone}
      {...props}
    >
      {isPhone ? (
        <React.Fragment>
          <div
            className={classNames({
              'set-radio-button-cards__set-item__name--phone': true,
              'set-radio-button-cards__set-item__name--phone--selected': checked,
            })}
          >
            {item.name}
          </div>
          <div className={classNames('set-radio-button-cards__set-item__image--phone')}>
            <img src={item.imageUrl} alt={item.name} />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <img className={classNames('set-radio-button-cards__set-item__image')} src={item.imageUrl} alt={item.name} />
          <h2
            className={classNames({
              'set-radio-button-cards__set-item__name': true,
              'set-radio-button-cards__set-item__name--selected': checked,
            })}
          >
            {item.name}
          </h2>
        </React.Fragment>
      )}
    </RadioButtonCard>
  );
};
