import { TranslateInterface } from 'utils/translation';
import { Link } from 'react-router-dom';
import ClassNames from '@streamloots/classnames';
import Button from 'ui-library/button';
import { urls } from 'services/routing';
import theme from './alerts-and-widgets.scss';

const classNames = ClassNames(theme);

export const NoSets = ({ t }: TranslateInterface): JSX.Element => {
  return (
    <div className={classNames('widgets__no-sets')}>
      <p className={classNames('margin-bottom')}>{t('adminSets:setsRequiredToGoToSection')}</p>
      <Button
        component={Link}
        to={urls().MANAGE_COLLECTIONS}
        size="md"
        primary
        label={t('adminSets:setsRequiredToGoToSectionButton')}
      />
    </div>
  );
};
