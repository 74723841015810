import { PanelSkeletons } from 'ui-library/panel';
import loadable from 'components/dynamic-import';
import { FullLayout } from 'scenes/layout';

const Administration = loadable(() => import('./Administration'), true, <PanelSkeletons count={3} />);

export const AdministrationPage = (): JSX.Element => (
  <FullLayout>
    <Administration />
  </FullLayout>
);
