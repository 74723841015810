import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { Checkbox } from 'ui-library/form-elements';
import theme from './grid.scss';

const classNames = ClassNames(theme);

interface GridHeaderProps {
  className?: string;
  contentClassName?: string;
  children: React.ReactNode;
  checkable?: boolean;
  checkHidden?: boolean;
  checked?: boolean;
  onCheckClick?: () => void;
}

export const GridHeader = ({
  checkable,
  checked,
  children,
  className = '',
  onCheckClick,
  contentClassName = '',
  checkHidden,
}: GridHeaderProps): JSX.Element => {
  return (
    <div
      className={classNames({
        'grid__header': true,
        'grid__row': true,
        'grid__row--checkable': checkable,
        [className]: Boolean(className),
      })}
    >
      {checkable && (
        <div className={classNames('grid__checkbox')}>
          {!checkHidden && <Checkbox checked={checked} onChange={onCheckClick} />}
        </div>
      )}
      <div
        className={classNames({
          grid__row__content: true,
          [className]: className,
          [contentClassName]: Boolean(contentClassName),
        })}
      >
        {children}
      </div>
    </div>
  );
};
