import { achievementsSideEffects } from 'services/sideEffects';
import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import type { PaymentProcessorType } from 'model/indexTS';
import type { PaypalPaymentParams, ExecutePaymentActionError, ExecutePaymentActionSuccess } from './types';
import { errorMapping } from '../../errorMapping';
import { ExecutePaymentOrderActionTypes } from './actionTypes';

const paymentOrderExecuted = ({ data }): ExecutePaymentActionSuccess =>
  actionCreator.createAction(ExecutePaymentOrderActionTypes.EXECUTED, data);

const paymentOrderNotExecuted = ({ errorData }): ExecutePaymentActionError =>
  actionCreator.createErrorAction(ExecutePaymentOrderActionTypes.EXECUTED, errorData, errorMapping);

const executePaymentOrder = (
  paymentId: string,
  paymentProcessor: PaymentProcessorType,
  paymentParams?: PaypalPaymentParams,
) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.PAYMENT_ORDER.EXECUTE,
    errorBinding: paymentOrderNotExecuted,
    loadingAction: ExecutePaymentOrderActionTypes.EXECUTING,
    urlParameters: { paymentId, paymentProcessor },
    parameters: paymentParams,
    method: 'post',
    successBinding: paymentOrderExecuted,
    successAsyncSideEffects: achievementsSideEffects,
  });

export const executePaymentOrderActions = {
  executePaymentOrder,
};
