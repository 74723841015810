import stateCreator from 'helpers/stateCreator';
import { PageActionTypes } from 'services/page';
import { paymentActionTypes, PaymentActions } from 'services/payment';
import { CommunityGiftsAction, CommunityGiftsState } from './types';
import { CommunityGiftsActionTypes } from './actionTypes';

const initialState: CommunityGiftsState = {};

export const reducer = (
  state: CommunityGiftsState = initialState,
  action: CommunityGiftsAction | PaymentActions,
): CommunityGiftsState => {
  switch (action.type) {
    case CommunityGiftsActionTypes.RECEIVED: {
      if (action.error) {
        return stateCreator.getAsyncErrorState(action, state, initialState);
      }

      const { payload } = action;

      return stateCreator.getInitialAsyncState(state, {
        communityGifts: payload.sort((giftA, giftB) => {
          return giftB.purchasedLootsQuantity - giftA.purchasedLootsQuantity;
        }),
      });
    }

    case CommunityGiftsActionTypes.FETCHING:
      return stateCreator.getAsyncLoadingState(state, { reconcile: false });

    case CommunityGiftsActionTypes.CLAIMING: {
      const { communityGiftId } = action.payload;
      return { ...state, clamingId: communityGiftId };
    }

    case CommunityGiftsActionTypes.ERROR_CLAIMING:
      return stateCreator.getAsyncErrorState(action, state, { ...initialState, clamingId: undefined });

    case CommunityGiftsActionTypes.CLAIMED: {
      if (action.error) {
        return stateCreator.getAsyncErrorState(action, state, initialState);
      }

      const { communityGiftId } = action.payload;

      return stateCreator.getInitialAsyncState(state, {
        communityGifts: state.communityGifts
          ?.map(communityGift => {
            if (communityGift._id === communityGiftId) {
              return { ...communityGift, remainingLootsQuantity: communityGift.remainingLootsQuantity - 1 };
            }
            return communityGift;
          })
          .filter(communityGift => {
            return communityGift.remainingLootsQuantity !== 0;
          }),
        clamingId: undefined,
      });
    }

    case PageActionTypes.CHANGED:
      return { ...initialState };
    case paymentActionTypes.EXECUTED:
    case paymentActionTypes.STRIPE_ORDER_EXECUTED:
      return { ...state, reconcile: true };
    default:
      return state;
  }
};
