import type { ActionError, ActionSuccess, AsyncComponentState } from 'types/indexTS';
import { PAGE_COMMUNICATIONS_POST_REQUEST } from '@streamloots/streamloots-types';
import { PageActions } from 'services/page';
import { PageCommunicationEmail } from 'model/indexTS';
import { PageCommunicationActionTypes } from './actionTypes';

export const MAX_MONTH_EMAILS = 10;

// Actions Function Type
export type PageCommunicationActionError = ActionError<PageCommunicationActionTypes.FETCH_ERROR>;
export type PageCommunicationActionSuccess = ActionSuccess<
  PageCommunicationActionTypes.RECEIVED,
  Array<PageCommunicationEmail>
>;
export type PageCommunicationFetchAction = PageCommunicationActionError | PageCommunicationActionSuccess;
export type FetchPageCommunication = (slug: string) => Promise<PageCommunicationFetchAction>;

export type SendEmailActionError = ActionError<PageCommunicationActionTypes.EMAIL_SENT_ERROR>;
export type SendEmailActionSuccess = ActionSuccess<PageCommunicationActionTypes.EMAIL_SENT, PageCommunicationEmail>;
export type SendEmailAction = SendEmailActionError | SendEmailActionSuccess;
export type SendEmail = (slug: string, data: PAGE_COMMUNICATIONS_POST_REQUEST) => Promise<SendEmailAction>;

export type ManageCommunicationError = ActionError<PageCommunicationActionTypes.MANAGED_COMMUNICATION_ERROR>;
export type ManageCommunicationSuccess = ActionSuccess<PageCommunicationActionTypes.MANAGED_COMMUNICATION>;
export type ManageCommunicationAction = PageCommunicationActionError | PageCommunicationActionSuccess;
export type ManageCommunication = (slug: string) => Promise<ManageCommunicationAction>;

export type PageCommunicationActions =
  | ActionSuccess<PageCommunicationActionTypes.FETCHING>
  | ActionSuccess<PageCommunicationActionTypes.SEND_EMAIL>
  | ActionSuccess<PageCommunicationActionTypes.MANAGE_COMMUNICATION>
  | PageCommunicationFetchAction
  | ManageCommunicationAction
  | SendEmailAction
  | PageActions;

export interface PageCommunicationState extends AsyncComponentState {
  emails?: Array<PageCommunicationEmail>;
  emailsLeft?: number;
  isLoading?: boolean;
}

export interface PageCommunicationStateStoreState {
  pageCommunications: PageCommunicationState;
}

// Selectors types
export type PageCommunicationStateSelectorOutput = Pick<PageCommunicationState, 'emails' | 'emailsLeft'> | null;
