import { createRef, Component, ReactNode } from 'react';
import { History, Location } from 'history';
import { Scrollbars } from 'react-custom-scrollbars';
import { forceCheck } from 'react-lazyload';
import scrollToTopManager from 'utils/scrollOnPageChangedManager';
import type { ScrollBarRef, ScrollToFunction } from './types';
import theme from './scrollbar-panel.scss';

interface RenderContentParams {
  scrollTo: ScrollToFunction;
  scrollToRight: ScrollToFunction;
}

interface ScrollBarPanelProps {
  autoHeight?: boolean;
  autoHeightMax?: string | number;
  autoHeightMin?: string | number;
  children?: ReactNode;
  contentClassName?: string;
  history?: History;
  location?: Location<{ noScroll?: boolean }>;
  renderContent?: (params: RenderContentParams) => ReactNode;
  scrollOnPageChange?: boolean;
}

class ScrollBarPanel extends Component<ScrollBarPanelProps> {
  scrollbarRef = createRef<ScrollBarRef>();

  timeout?: number;

  static defaultProps = {
    contentClassName: '',
  };

  static renderThumbVertical = props => <div {...props} className={theme.scrollbar__thumb__vertical} />;

  static renderThumbHorizontal = props => <div {...props} className={theme.scrollbar__thumb__horizontal} />;

  componentDidUpdate(prevProps) {
    const { history, location } = this.props;
    if (location && scrollToTopManager.shouldScrollToTop(location, prevProps.location, history)) {
      this.scrollbarRef.current?.scrollToTop();
    }
  }

  handleScroll = () => {
    forceCheck();
  };

  scrollTo = (top: number) => {
    if (!this.scrollbarRef.current) {
      return;
    }

    // Hack by Josemi incoming
    clearTimeout(this.timeout);
    this.timeout = window.setTimeout(() => {
      if (!this.scrollbarRef || !this.scrollbarRef.current) {
        return;
      }
      const { current } = this.scrollbarRef;
      const scrollPanelSize = current.getClientHeight();
      const currentScrollTop = current.getScrollTop();

      if (top < currentScrollTop) {
        this.scrollbarRef.current.scrollTop(top);
        return;
      }
      if (top > currentScrollTop + scrollPanelSize) {
        this.scrollbarRef.current.scrollTop(top);
      }
    }, 10);
  };

  scrollToRight = (right: number) => {
    if (!this.scrollbarRef.current) {
      return;
    }

    // Hack by Josemi incoming
    clearTimeout(this.timeout);
    this.timeout = window.setTimeout(() => {
      if (!this.scrollbarRef || !this.scrollbarRef.current) {
        return;
      }
      const { current } = this.scrollbarRef;
      const scrollPanelSize = current.getClientWidth();
      const currentScrollTop = current.getScrollLeft();

      if (right < currentScrollTop) {
        this.scrollbarRef.current.scrollLeft(right);
        return;
      }
      if (right > currentScrollTop + scrollPanelSize) {
        this.scrollbarRef.current.scrollLeft(right);
      }
    }, 10);
  };

  render() {
    const { autoHeight, autoHeightMax, autoHeightMin, children, contentClassName, renderContent } = this.props;

    return (
      <Scrollbars
        ref={this.scrollbarRef}
        onScroll={this.handleScroll}
        renderThumbVertical={ScrollBarPanel.renderThumbVertical}
        renderThumbHorizontal={ScrollBarPanel.renderThumbHorizontal}
        universal
        autoHeight={autoHeight}
        autoHeightMax={autoHeightMax}
        autoHeightMin={autoHeightMin}
      >
        <div className={contentClassName}>
          {children || (renderContent && renderContent({ scrollTo: this.scrollTo, scrollToRight: this.scrollToRight }))}
        </div>
      </Scrollbars>
    );
  }
}

export { ScrollBarPanel };
