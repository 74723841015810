import { PublicPageSearchResult, PublicPageSearchResultApiResponse } from 'model/indexTS';
import { PaginationApiResponse } from 'services/pagination/typesTS';
import { normalizeAchievementBadges } from 'services/page/normalizer';
import { PagesQuery, PagesResponse } from './typesTS';

const normalizePage = ({ achievementBadges, ...rest }: PublicPageSearchResultApiResponse): PublicPageSearchResult => {
  return {
    ...rest,
    achievementBadges: normalizeAchievementBadges(achievementBadges),
  };
};

const pages = (
  { data, pagination }: PaginationApiResponse<PublicPageSearchResultApiResponse>,
  query: PagesQuery,
): PagesResponse => ({ data: data.map(normalizePage), pagination, query });

export default {
  pages,
};
