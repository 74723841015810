import loadable from 'components/dynamic-import';
import { PanelSkeletons } from 'ui-library/panel';
import { PageHeader } from 'ui-library/headers';
import { FullLayout } from 'scenes/layout';

const Ranking = loadable(() => import('./ConnectedRanking'), true, <PanelSkeletons gridMode />);

export const RankingPage = (): JSX.Element => (
  <FullLayout>
    <PageHeader>Rankings</PageHeader>
    <Ranking />
  </FullLayout>
);
