import { PageActions, PageActionTypes } from 'services/page';
import { paginationStateNormalizer } from 'services/pagination';
import stateCreator from 'helpers/stateCreator';
import type { ViewersListState, ViewersListStateByComponentKey, ViewersListActions } from './types';
import { PAGINATION_STATE_NORMALIZER_CONFIGURATION } from '../../constants';
import { ViewerActionTypes } from '../../actionTypes';

type AcceptedActions = ViewersListActions | PageActions;

const getViewersListStateByComponentKey = (
  state: ViewersListStateByComponentKey,
  action: AcceptedActions,
): ViewersListStateByComponentKey => {
  switch (action.type) {
    case ViewerActionTypes.FETCH_SUCCESS: {
      return paginationStateNormalizer.getReceived(
        action.payload,
        PAGINATION_STATE_NORMALIZER_CONFIGURATION,
        action.payload.query.username ? undefined : state.data || [],
      );
    }
    case ViewerActionTypes.FETCH_ERROR: {
      return paginationStateNormalizer.getReceivedError(action, state);
    }
    case ViewerActionTypes.FETCH_REQUEST: {
      return paginationStateNormalizer.getRequest(state, action.payload, PAGINATION_STATE_NORMALIZER_CONFIGURATION);
    }
    case ViewerActionTypes.FETCH_NEXT_REQUEST:
      return paginationStateNormalizer.getFetchingNextItems(state, action.payload.cancelToken);
    case ViewerActionTypes.FETCH_NEXT_SUCCESS: {
      return paginationStateNormalizer.getNextItemsReceived(state, action.payload);
    }
    case ViewerActionTypes.FETCH_NEXT_ERROR: {
      return paginationStateNormalizer.getNextReceivedError(state);
    }
    default:
      return state;
  }
};

export const reducer = (state = {}, action: AcceptedActions): ViewersListState => {
  switch (action.type) {
    case ViewerActionTypes.FETCH_NEXT_ERROR:
    case ViewerActionTypes.FETCH_NEXT_SUCCESS:
    case ViewerActionTypes.FETCH_NEXT_REQUEST:
    case ViewerActionTypes.FETCH_ERROR:
    case ViewerActionTypes.FETCH_SUCCESS:
    case ViewerActionTypes.FETCH_REQUEST: {
      const { componentKey } = action.metadata;
      return stateCreator.getUpdatedStateByKey(state, action, componentKey, getViewersListStateByComponentKey);
    }
    case PageActionTypes.CHANGED:
      return {};
    default:
      return state;
  }
};
