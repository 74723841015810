import type { ReactNode } from 'react';
import ClassNames from '@streamloots/classnames';
import { Link } from 'react-router-dom';
import { urls } from 'services/routing';
import Button from 'ui-library/button';
import { ExplanationPanel, ExplanationHeaderVariations, ExplanationPanelHeader } from 'ui-library/explanation-panel';
import theme from './stream-deck-explanation.scss';

const classNames = ClassNames(theme);

export interface StreamdeckExplanationProps {
  title: string;
  description: ReactNode;
  buttonLabel?: string;
}

export const StreamDeckExplanation = ({ title, description, buttonLabel }: StreamdeckExplanationProps): JSX.Element => {
  return (
    <ExplanationPanel>
      <ExplanationPanelHeader
        className={classNames('stream-deck-explanation__image')}
        icon="stream-deck"
        variation={ExplanationHeaderVariations.Candy}
      />
      <div>
        <h3 className="margin-bottom-s">{title}</h3>
        {description}
        {buttonLabel && (
          <Button
            to={urls().STREAMDECK}
            component={Link}
            label={buttonLabel}
            className={classNames('stream-deck-explanation__go-to')}
            aria-label="go to streamdeck page"
          />
        )}
      </div>
    </ExplanationPanel>
  );
};
