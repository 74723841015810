// eslint-disable-next-line camelcase
import _ from 'lodash';
import type { FetchAndCreateTokensWithScope } from './types';
import { authenticationActions } from './services/authentication';
import { disconnectionActions } from './services/disconnection';
import { tokensActions } from './services/tokens';

const fetchAndCreateTokensWithScope: FetchAndCreateTokensWithScope = scope => async dispatch => {
  const userTokens = await dispatch(tokensActions.getTokensUser(scope));
  if (userTokens.error) {
    return userTokens;
  }

  if (!_.isEmpty(userTokens.payload)) {
    return userTokens;
  }

  const userNewTokens = await dispatch(tokensActions.postTokensUser(scope));

  if (userNewTokens.error) {
    return userTokens;
  }

  return userNewTokens;
};

export const authActions = {
  ...authenticationActions,
  ...disconnectionActions,
  fetchAndCreateTokensWithScope,
};
