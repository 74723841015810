import { useCallback, useEffect } from 'react';
import { useAlertProvidersSelectors } from 'services/alert-providers';
import { useMarathonActions, useMarathonSelectors } from 'services/marathon';
import type { UseMarathonActions, UseMarathonSelectors } from 'services/marathon';
import { usePageSelectors } from 'services/page';
import { PickReturnTypeMappedType } from 'types/utils';
import { MARATHON_DEFAULT_VALUES } from './constants';

type MarathonConfig = Pick<
  PickReturnTypeMappedType<UseMarathonSelectors, 'marathonState'>,
  'marathonConfig' | 'isLoading' | 'error'
>;

interface UseMarathonPage extends Pick<UseMarathonActions, 'createMarathon' | 'updateMarathon'>, MarathonConfig {}

export const useMarathonPage = (): UseMarathonPage => {
  const { marathonState } = useMarathonSelectors();
  const { isLoading = false, error, marathonConfig } = marathonState;
  const { createMarathon, fetchMarathon, fetchMarathonTracker, updateMarathon } = useMarathonActions();
  const { page } = usePageSelectors();

  useEffect(() => {
    if (!isLoading && marathonConfig === undefined) {
      fetchMarathon(page.slug);
      fetchMarathonTracker(page.slug);
    }
  }, [fetchMarathon, fetchMarathonTracker, isLoading, marathonConfig, page.slug]);

  useEffect(() => {
    if (marathonConfig === null || (marathonConfig && !marathonConfig._id)) {
      createMarathon(page.slug, MARATHON_DEFAULT_VALUES);
    }
  }, [createMarathon, marathonConfig, page.slug]);

  return {
    isLoading,
    error,
    marathonConfig,
    createMarathon,
    updateMarathon,
  };
};
