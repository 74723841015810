import type { PredefinedCard } from 'model/indexTS';
import stateCreator from 'helpers/stateCreator';
import { PredefinedCardsActionTypes } from '../../actionTypes';
import { PredefinedCardListState } from './types';

const initialState: PredefinedCardListState = {
  filters: {},
};

const updateTemplateInTemplateList = (predefinedCards: Array<PredefinedCard> = [], updatedCard) => {
  const isCardInList = predefinedCards.find(_predefinedCard => _predefinedCard._id === updatedCard._id);

  if (!isCardInList) {
    return [...predefinedCards, updatedCard];
  }

  return predefinedCards.map(_predefinedCardTemplate => {
    if (_predefinedCardTemplate._id === updatedCard._id) {
      return updatedCard;
    }
    return _predefinedCardTemplate;
  });
};

export const reducer = (state: PredefinedCardListState = initialState, action): PredefinedCardListState => {
  const { type, error, payload } = action;
  switch (type) {
    case PredefinedCardsActionTypes.FETCHING_ONE:
      return stateCreator.getAsyncLoadingState(state);

    case PredefinedCardsActionTypes.FETCHING: {
      const { filters, cancelToken } = payload;
      return stateCreator.getAsyncLoadingState(state, {
        invalidateData: false,
        cancelToken,
        filters,
      });
    }

    case PredefinedCardsActionTypes.RECEIVED: {
      if (error) {
        return stateCreator.getAsyncErrorState(action, state);
      }

      return stateCreator.getInitialAsyncState(state, {
        ...payload,
      });
    }

    case PredefinedCardsActionTypes.RECEIVED_ONE:
    case PredefinedCardsActionTypes.UPDATED: {
      if (error) {
        return stateCreator.getAsyncErrorState(action, state);
      }

      const { predefinedCard } = payload;
      const newPredefinedCardTemplateList = updateTemplateInTemplateList(state.predefinedCards, predefinedCard);

      return stateCreator.getInitialAsyncState(state, {
        predefinedCards: newPredefinedCardTemplateList,
      });
    }
    case PredefinedCardsActionTypes.BULK_UPDATED: {
      if (error) {
        return state;
      }

      return stateCreator.getInitialAsyncState(state, {
        invalidateData: true,
      });
    }

    case PredefinedCardsActionTypes.STATUS_CHANGED: {
      if (error) {
        return state;
      }

      const { predefinedCards: updatedPredefinedCards } = payload;
      const updatedCardPublishedStatus = {};

      updatedPredefinedCards.forEach(card => {
        updatedCardPublishedStatus[card._id] = card.published;
      });

      const newPredefinedCards = state.predefinedCards?.map(card => {
        const published = updatedCardPublishedStatus[card._id];
        if (typeof published === 'undefined') {
          return card;
        }

        return {
          ...card,
          published: updatedCardPublishedStatus[card._id],
        };
      });

      return {
        ...state,
        predefinedCards: newPredefinedCards,
      };
    }
    case PredefinedCardsActionTypes.VISUALIZATION_MODE_UPDATED: {
      return {
        ...state,
        ...payload,
      };
    }

    case PredefinedCardsActionTypes.DELETED: {
      if (error) {
        return state;
      }

      const { completedIds } = payload;

      const filteredPredefinedCards = state.predefinedCards?.filter(predefinedCard => {
        return !completedIds[predefinedCard._id];
      });

      return {
        ...state,
        predefinedCards: filteredPredefinedCards,
      };
    }

    default:
      return state;
  }
};
