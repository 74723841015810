import { AuthProvidersActionTypes } from 'services/auth-providers';
import { UserActionTypes } from 'services/user';
import { OnboardingActionTypes } from 'services/onboarding';
import { RewindActionTypes } from 'services/rewind';
import type { ReconcileState } from '../../types';

const initialState: ReconcileState = {};

export const reducer = (state: ReconcileState = initialState, action): ReconcileState => {
  const { type, error } = action;

  switch (type) {
    case UserActionTypes.VERIFY_RECEIVED:
    case RewindActionTypes.UPLOADED:
    case OnboardingActionTypes.FINALIZED:
    case AuthProvidersActionTypes.DELETE_SUCCESS:
      if (error) {
        return state;
      }
      return { reconcile: true };
    case UserActionTypes.REQUEST:
    case UserActionTypes.RECEIVED:
      return { reconcile: false };
    default:
      return state;
  }
};
