import { useContext, FunctionComponent } from 'react';
import { compose } from '@reduxjs/toolkit';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';
import { TranslateInterface, withTranslation } from 'utils/translation';
import trackEventManager from 'utils/event-manager';
import { SidebarOpenerContext } from 'contexts/sidebar-opener';
import { useSetsSelectors } from 'services/sets';
import { checkPermissions, permissions } from 'components/permissions';
import { SetList } from 'components/set-list';
import { ManageCollectionTopBar } from './ManageCollectionTopBar';

type ManageCardsPageProps = RouteConfigComponentProps;

interface ExtendedProps extends ManageCardsPageProps, TranslateInterface {}

const ManageMyCollectionPageInternal = ({ route, t }: ExtendedProps) => {
  const { sets = [] } = useSetsSelectors();
  const { open } = useContext(SidebarOpenerContext);

  function handleManageCollections() {
    open({
      component: <SetList sets={sets} />,
      attributes: {
        title: t('adminSets:selectCollection'),
      },
    });
  }

  return (
    <>
      <ManageCollectionTopBar onManageCollections={handleManageCollections} t={t} />
      {renderRoutes(route?.routes)}
    </>
  );
};

export const ManageMyCollectionPage = compose<FunctionComponent<ManageCardsPageProps>>(
  checkPermissions({
    requiredPermissions: [
      permissions.SET_LOOTS_EDIT,
      permissions.SET_CARDS_READ,
      permissions.SET_RARITIES_EDIT,
      permissions.CRAFTING,
    ],
    redirectToBasePage: true,
  }),
  withTranslation('adminSets'),
)(ManageMyCollectionPageInternal);
