import loadable from 'components/dynamic-import';
import { FullLayout } from 'scenes/layout';

const OBSPlugin = loadable(() => import('./OBSPlugin'));

export const OBSPluginPage = (): JSX.Element => {
  return (
    <FullLayout>
      <OBSPlugin />
    </FullLayout>
  );
};
