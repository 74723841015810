import type { User } from 'model/indexTS';
import config from 'config';
import cultureCookieManager from 'utils/cultureCookieManager';
import sharedCookieManager from 'utils/sharedCookieManager';
import { saveMarketingAutomationEvent } from 'services/marketing-automation/useMarketingAutomation';

import eventsHelper from './eventsHelpers';

declare const amplitude: any;

interface Revenue {
  productId: string;
  quantity: number;
  revenueType: 'purchase' | 'refund';
  price: number;
}
const isAmplitudeAdded = (): boolean => eventsHelper.isEventSenderAdded('amplitude');

const initializeInstanceIfNeeded = () => {
  if (!isAmplitudeAdded()) {
    return;
  }
  if (amplitude.getInstance().options?.domain) {
    return;
  }
  amplitude.getInstance().init(config.amplitude.apiKey, sharedCookieManager.getUserId() || null, {
    includeReferrer: true,
    saveEvents: true,
  });
};

const sendAmplitudeEvent = (eventName: string, eventData: Record<string, any>): void => {
  try {
    if (!isAmplitudeAdded()) {
      return;
    }
    amplitude.getInstance().logEvent(eventName, eventData);
  } catch (e) {
    console.error(e);
  }
};

const updateUserInfo = (userProperties: Record<string, any>): void => {
  if (!isAmplitudeAdded()) {
    return;
  }
  initializeInstanceIfNeeded();

  amplitude.getInstance().setUserProperties({
    ...userProperties,
  });
};

const logRevenue = ({ productId, price, revenueType, quantity }: Revenue): void => {
  initializeInstanceIfNeeded();

  const revenue = new amplitude.Revenue();
  const pricePerChest = (price / (quantity || 1)).toFixed(2);
  revenue.setProductId(productId);
  revenue.setPrice(parseFloat(pricePerChest));
  revenue.setRevenueType(revenueType);
  revenue.setQuantity(quantity);
  amplitude.getInstance().logRevenueV2(revenue);
};

export default {
  init(): void {
    if (!isAmplitudeAdded()) {
      return;
    }
    initializeInstanceIfNeeded();

    amplitude.getInstance().setVersionName(config.app.version);

    updateUserInfo({ pageLanguage: eventsHelper.getPageLanguage() });
  },
  signInUser(user: User): void {
    if (!isAmplitudeAdded()) {
      return;
    }
    initializeInstanceIfNeeded();

    const {
      _id,
      profile: { username, primaryEmail },
    } = user;

    amplitude.getInstance().setUserId(_id);
    updateUserInfo({
      username,
      email: primaryEmail,
      pageLanguage: eventsHelper.getPageLanguage(),
    });
  },
  sendEvent(eventName: string, eventData: Record<string, any> = {}): void {
    const { page, ...rest } = eventData;
    const userId = sharedCookieManager.getUserId();
    saveMarketingAutomationEvent({
      eventData: rest,
      name: eventName,
      type: 'amplitude',
      ...(page && { page }),
      ...(userId && { userId }),
    });

    if (config.amplitude.disabled || !isAmplitudeAdded()) {
      return;
    }
    initializeInstanceIfNeeded();
    sendAmplitudeEvent(eventName, {
      ...eventData,
      appLanguage: cultureCookieManager.getCulture(),
    });
  },
  logRevenue,
  updateUserInfo,
};
/* eslint-enable */
