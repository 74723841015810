import stateCreator from 'helpers/stateCreator';
import type { SetLootsState, SetLootsStateBySetId } from './types';
import { SetsActionTypes } from '../sets';
import { PageActionTypes } from '../page';
import { SetLootsActionTypes } from './actionTypes';

const getSetState = (state: SetLootsStateBySetId, action) => {
  const { type, error, payload } = action;
  switch (type) {
    case SetLootsActionTypes.LOOTS_UPDATED: {
      if (error) {
        return state;
      }

      const { loots = [] } = state;
      const { loots: updatedLoots } = payload;

      const updatedLootsById = {};
      updatedLoots.forEach(loot => {
        updatedLootsById[loot._id] = loot;
      });

      return {
        ...state,
        loots: loots.map(lootItem =>
          updatedLootsById[lootItem._id] ? { ...updatedLootsById[lootItem._id] } : lootItem,
        ),
      };
    }
    default:
      return state;
  }
};

export const INITIAL_SET_LOOTS_STATE: SetLootsState = {};

export const reducer = (state: SetLootsState = INITIAL_SET_LOOTS_STATE, action): SetLootsState => {
  const { type, error, payload, metadata } = action;
  switch (type) {
    case SetLootsActionTypes.LOOTS_UPDATED: {
      if (error || payload.loots.length === 0) {
        return state;
      }

      const { setId } = metadata;
      return stateCreator.getUpdatedStateByKey(
        {
          ...state,
        },
        action,
        setId,
        getSetState,
      );
    }
    case SetsActionTypes.RECEIVED: {
      if (error) {
        return state;
      }
      const { data } = payload;

      const newStateBySetId = {};
      if (data.length > 0 && !data[0].loots) {
        return state;
      }

      data.forEach(set => {
        const { _id: setId, loots } = set;

        const lootState = state[setId] || {};
        newStateBySetId[setId] = {
          ...lootState,
          loots: Array.isArray(loots) ? [...loots] : [],
        };
      });

      return {
        ...state,
        ...newStateBySetId,
      };
    }
    case PageActionTypes.CHANGED:
      return INITIAL_SET_LOOTS_STATE;
    default:
      return state;
  }
};
