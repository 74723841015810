import React from 'react';
import ClassNames from '@streamloots/classnames';
import { TranslateInterface } from 'utils/translation';
import { PageHeader } from 'ui-library/headers';
import theme from './manage-set-header.scss';

const classNames = ClassNames(theme);

type ManageSetsHeaderProps = TranslateInterface;

export const ManageSetsHeader = ({ t }: ManageSetsHeaderProps) => {
  return (
    <header className={classNames('manage-set-header')}>
      <PageHeader>{t('adminSets:myCollections')}</PageHeader>
    </header>
  );
};
