import type { User, Permissions } from 'model/indexTS';
import { TranslateInterface } from 'utils/translation';
import ClassNames from '@streamloots/classnames';
import ScrollBarPanel from 'components/scrollbar-panel';
import { CampaingActive, Campaings } from 'domain/campaing-active';
import { MainMenuHeader } from './MainMenuHeader';
import { ViewerMenu } from './ViewerMenu';
import { StaffMenu } from './StaffMenu';
import { CreatorMenu } from './CreatorMenu';
import theme from './menu.scss';

const classNames = ClassNames(theme);

export interface MenuProps extends TranslateInterface {
  hasPagePermissions: boolean;
  inPageContext: boolean;
  inStaffContext: boolean;
  isOwner: boolean;
  isStaff: boolean;
  user: User;
  permissions: Permissions;
}

export const Menu = (props: MenuProps): JSX.Element => {
  const { hasPagePermissions, inPageContext, inStaffContext, isOwner, isStaff, t, permissions } = props;
  const showPageMenu = inPageContext && hasPagePermissions;
  const isChristmas = CampaingActive.check(Campaings.CHRISTMAS);

  return (
    <>
      <MainMenuHeader
        isModeratorMode={hasPagePermissions && inPageContext && !isOwner}
        isCreatorMode={(isOwner || isStaff) && inPageContext}
        isAdminMode={inStaffContext}
      />
      <ScrollBarPanel>
        <div
          className={classNames({
            'menu__body': true,
            'menu__body--christmas': isChristmas,
          })}
        >
          {inStaffContext && isStaff && <StaffMenu t={t} />}
          {showPageMenu && <CreatorMenu t={t} permissions={permissions} />}
          {!inStaffContext && !showPageMenu && <ViewerMenu t={t} permissions={permissions} />}
        </div>
      </ScrollBarPanel>
    </>
  );
};
