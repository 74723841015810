import { FunctionComponent } from 'react';
import { compose } from '@reduxjs/toolkit';
import { withTranslation, TranslateInterface } from 'utils/translation';
import { pages } from 'services/routing';
import { PageHeader } from 'ui-library/headers';
import { checkPermissions, permissions } from 'components/permissions';
import loadable from 'components/dynamic-import';
import { FullLayout } from 'scenes/layout';

const Coupons = loadable(() => import('./Coupons'));

const COUPONS_KEY = 'siteCoupons';

const AdminCouponsPageInternal = ({ t }: TranslateInterface) => (
  <FullLayout>
    <PageHeader>{t('coupons:pageTitle')} </PageHeader>
    <Coupons staffMode componentKey={COUPONS_KEY} t={t} />
  </FullLayout>
);

export const AdminCouponsPage = compose<FunctionComponent>(
  checkPermissions({
    requiredPermissions: [permissions.ACCOUNT_MANAGER, permissions.PARTNERSHIP_MANAGER, permissions.ADMIN],
    redirectTo: pages.STREAMERS,
  }),
  withTranslation('coupons'),
)(AdminCouponsPageInternal);
