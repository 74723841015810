import { FunctionComponent } from 'react';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { withTranslation } from 'utils/translation';
import { userSelectors } from 'services/user';
import { permissionsSelectors } from 'services/permissions';
import { appSelectors } from 'services/app';
import { pageSelectors } from 'services/page';
import type { MenuProps } from './Menu';
import { Menu } from './Menu';

const ConnectedMenu = (props: MenuProps) => <Menu {...props} />;

const mapStateToProps = state => {
  return {
    hasPagePermissions: permissionsSelectors.hasPagePermissions(state),
    permissions: permissionsSelectors.permissions(state),
    inPageContext: pageSelectors.inPageContext(state),
    inStaffContext: appSelectors.currentPathname(state).indexOf('/admin/') > -1,
    isOwner: pageSelectors.isOwner(state),
    isStaff: permissionsSelectors.isStaff(state),
    slug: pageSelectors.slug(state),
    user: userSelectors.user(state),
  };
};

export const MainMenu = compose<FunctionComponent>(connect(mapStateToProps), withTranslation('common'))(ConnectedMenu);
