export enum PageFeedCommentsActionTypes {
  FETCH_PAGE_FEED_COMMENT_REQUEST = 'pageFeedComments/FETCH_PAGE_FEED_COMMENT_REQUEST',
  FETCH_PAGE_FEED_COMMENT_ERROR = 'pageFeedComments/FETCH_PAGE_FEED_COMMENT_ERROR',
  FETCH_PAGE_FEED_COMMENT_SUCCESS = 'pageFeedComments/FETCH_PAGE_FEED_COMMENT_SUCCESS',
  FETCH_NEXT_PAGE_FEED_COMMENT_SUCCESS = 'pageFeedComments/FETCH_NEXT_PAGE_FEED_COMMENT_SUCCESS',
  FETCH_NEXT_PAGE_FEED_COMMENT_ERROR = 'pageFeedComments/FETCH_NEXT_PAGE_FEED_COMMENT_ERROR',
  FETCH_NEXT_PAGE_FEED_COMMENT_REQUEST = 'pageFeedComments/FETCH_NEXT_PAGE_FEED_COMMENT_REQUEST',
  CREATE_PAGE_FEED_COMMENT_REQUEST = 'pageFeedComments/CREATE_PAGE_FEED_COMMENT_REQUEST',
  CREATE_PAGE_FEED_COMMENT_ERROR = 'pageFeedComments/CREATE_PAGE_FEED_COMMENT_ERROR',
  CREATE_PAGE_FEED_COMMENT_SUCCESS = 'pageFeedComments/CREATE_PAGE_FEED_COMMENT_SUCCESS',
  DELETE_PAGE_FEED_COMMENT_REQUEST = 'pageFeedComments/DELETE_PAGE_FEED_COMMENT_REQUEST',
  DELETE_PAGE_FEED_COMMENT_ERROR = 'pageFeedComments/DELETE_PAGE_FEED_COMMENT_ERROR',
  DELETE_PAGE_FEED_COMMENT_SUCCESS = 'pageFeedComments/DELETE_PAGE_FEED_COMMENT_SUCCESS',
  LIKE_PAGE_FEED_COMMENT_REQUEST = 'pageFeedComments/LIKE_PAGE_FEED_COMMENT_REQUEST',
  LIKE_PAGE_FEED_COMMENT_SUCCESS = 'pageFeedComments/LIKE_PAGE_FEED_COMMENT_SUCCESS',
  LIKE_PAGE_FEED_COMMENT_ERROR = 'pageFeedComments/LIKE_PAGE_FEED_COMMENT_ERROR',
}
