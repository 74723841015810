import ClassNames from '@streamloots/classnames';
import { NavLinkTabHeader } from 'ui-library/tabs';
import theme from './manage-collection.scss';

const classNames = ClassNames(theme);

type TopBarNavigationLinkProps = {
  exact?: boolean;
  icon: string;
  label: string;
  link: string;
};

export const TopBarNavigationLink = ({ icon, link, exact = true, label }: TopBarNavigationLinkProps): JSX.Element => {
  return (
    <li>
      <NavLinkTabHeader
        className={classNames('st-navigation__link')}
        to={link}
        exact={exact}
        label={label}
        icon={icon}
      />
    </li>
  );
};
