import { StrictOmit } from '@streamloots/streamloots-types';
import { useDispatch, useSelector } from 'react-redux';
import { IgnoreFirstArgument, InterfaceWithReturnType, MyReturnType } from 'types/utils';
import { SCOPE_TOKENS } from '.';
import { authActions } from './actions';
import { authSelectors } from './selectors';
import { AuthStoreState } from './types';

export type UseAuthActions = typeof authActions;
export type UseAuthSelectors = StrictOmit<InterfaceWithReturnType<typeof authSelectors>, 'scopeToken'>;

export const useAuthActions = (): UseAuthActions => {
  const dispatch = useDispatch();
  return {
    authenticateUser: (accessToken: string) => dispatch(authActions.authenticateUser(accessToken)),
    disconnectUser: () => dispatch(authActions.disconnectUser()),
    fetchAndCreateTokensWithScope: (scope: SCOPE_TOKENS) => dispatch(authActions.fetchAndCreateTokensWithScope(scope)),
  };
};

export const useAuthSelectors = (): UseAuthSelectors => {
  const authState = useSelector(authSelectors.authState);
  const authToken = useSelector(authSelectors.authToken);
  const invalidateAuthToken = useSelector(authSelectors.invalidateAuthToken);
  const isUserAuthenticated = useSelector(authSelectors.isUserAuthenticated);

  const scopeTokenError = useSelector(authSelectors.scopeTokenError);

  return {
    authState,
    authToken,
    invalidateAuthToken,
    isUserAuthenticated,
    scopeTokenError,
  };
};

type UseScopeToken = IgnoreFirstArgument<typeof authSelectors['scopeToken']>;

export const useScopeToken: UseScopeToken = scope => {
  const scopeToken = useSelector<AuthStoreState, MyReturnType<UseScopeToken>>(state =>
    authSelectors.scopeToken(state, scope),
  );

  return scopeToken;
};
