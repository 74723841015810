import type { FunctionComponent } from 'react';
import ClassNames from '@streamloots/classnames';
import { withTranslation, TranslateInterface } from 'utils/translation';
import { StreamDeckExplanation } from 'components/streamdeck-explanation';
import { ExplanationHeaderVariations, ExplanationPanel, ExplanationPanelHeader } from 'ui-library/explanation-panel';
import { VerticalList, VerticalListItem } from 'ui-library/vertical-list';
import theme from './sets-right-panel.scss';

const classNames = ClassNames(theme);

export interface SetsRightPanelProps {
  setsPublishedCount: number;
  setsCount: number;
}

interface ExtendedProps extends SetsRightPanelProps, TranslateInterface {}

const SetsRightPanelInternal = ({ setsPublishedCount, setsCount, t }: ExtendedProps): JSX.Element => {
  if (!setsPublishedCount) {
    return (
      <ExplanationPanel>
        <ExplanationPanelHeader
          className={classNames('stream-deck-explanation__image')}
          icon="cards"
          variation={ExplanationHeaderVariations.Mystic}
        />
        <div>
          <p>{t('adminSets:setsTasks.description')}</p>
          <VerticalList isOrderedList>
            <VerticalListItem
              title={t('adminSets:setsTasks.createSet')}
              isCompleted={setsCount > 0}
              completedVisuallyHiddenDescription={t('common:setsTasks.stepCompleted')}
            />
            <VerticalListItem title={t('adminSets:setsTasks.publishSet')} />
          </VerticalList>
        </div>
      </ExplanationPanel>
    );
  }

  return (
    <StreamDeckExplanation
      title={t('adminSets:streamdeck.title')}
      description={<p>{t('adminSets:streamdeck.description')}</p>}
      buttonLabel={t('adminSets:streamdeck.goTo')}
    />
  );
};

export const SetsRightPanel = withTranslation('adminSets')(SetsRightPanelInternal);
