import { useEffect } from 'react';
import { Permissions } from 'model/userTS';
import { TranslateInterface } from 'utils/translation';
import { usePowerActions, usePowerSelectors } from 'services/alert';
import { useAuthSelectors } from 'services/auth';
import { useAchievementActions, useAchievementSelectors } from 'services/achievement';
import { IgnoreChildrenOnFatalError } from 'components/error-boundary';
import { BannerLeftMenuFiesta } from 'components/campaign-tickets/banner-left/BannerLeftMenuFiesta';
// import { RewindLeftMenu } from 'scenes/rewind/components/left-menu/RewindLeftMenu';
import { VIEWER_LINKS } from './links';
import StreamerList from './components/streamer-list';
import theme from './menu.scss';
import { MainMenuPermissionLink } from './components/menu-content-components';

interface ViewerMenuProps extends TranslateInterface {
  permissions: Permissions;
}

export const ViewerMenu = ({ permissions, t }: ViewerMenuProps): JSX.Element => {
  const { isUserAuthenticated } = useAuthSelectors();
  const { fetchPowers } = usePowerActions();
  const { powers, activePowers } = usePowerSelectors();
  const { fetchAchievements } = useAchievementActions();
  const { canDoMoreAchievements } = useAchievementSelectors();
  const hasPowers = activePowers.length > 0;

  useEffect(() => {
    if (powers !== undefined || !isUserAuthenticated) {
      return;
    }
    fetchPowers();
  }, [fetchPowers, isUserAuthenticated, powers]);

  useEffect(() => {
    if (isUserAuthenticated) {
      fetchAchievements();
    }
  }, [fetchAchievements, isUserAuthenticated]);

  return (
    <>
      <div className={theme.menu__content}>
        {VIEWER_LINKS.map(link => (
          <MainMenuPermissionLink
            permissions={permissions}
            t={t}
            link={link}
            key={link.toUrlKey}
            hasPowers={hasPowers}
            activeAchievements={canDoMoreAchievements}
          />
        ))}
        {/* <MainMenuPermissionLink link={TOP_STREAMERS_LEADERBOARD} permissions={permissions} t={t} /> */}
      </div>
      <IgnoreChildrenOnFatalError>
        <BannerLeftMenuFiesta />
        {/* <RewindLeftMenu /> */}
        <StreamerList />
      </IgnoreChildrenOnFatalError>
    </>
  );
};
