import * as React from 'react';
import loadable from 'components/dynamic-import';
import { FullLayout } from '../layout';

const StreamDeck = loadable(() => import('./StreamDeck'));

export const StreamDeckPage = () => {
  return (
    <FullLayout>
      <StreamDeck />
    </FullLayout>
  );
};
