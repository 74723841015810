import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import theme from './two-column-panel.scss';

const classNames = ClassNames(theme);

interface BigColumnPanelProps {
  children: React.ReactNode;
  responsive?: boolean;
  largeBreak?: boolean;
}

export const BigColumnPanel = ({ children, responsive = false, largeBreak }: BigColumnPanelProps) => {
  return (
    <div
      className={classNames({
        'two-column-panel__big': !responsive,
        'two-column-panel-responsive__big': responsive,
        'two-column-panel__big__desktop-break': !largeBreak,
        'two-column-panel__big__large-break': largeBreak,
      })}
    >
      {children}
    </div>
  );
};
