import { PageActionTypes, PageActions } from 'services/page';
import { UserActionTypes, UserActions } from 'services/user';
import type { PermissionsState } from './types';
import { hasPermissions, getPermissionsObject, hasPagePermissions, isInStaffRoles } from './hasPermissions';
import { permissions as definedPermissions } from './constants';

const initialState: PermissionsState = {
  pagePermissions: [],
  roles: [],
  permissions: {},
  hasPagePermissions: false,
  isUserAuthenticated: false,
};

export const reducer = (
  state: PermissionsState = initialState,
  action: PageActions | UserActions,
): PermissionsState => {
  switch (action.type) {
    case PageActionTypes.RECEIVED: {
      if (action.error) {
        return state;
      }

      const { permissions, ownerId } = action.payload;

      const permissionsObject = getPermissionsObject(permissions, state.roles);
      permissionsObject[definedPermissions.USER_AUTHENTICATED] = state.isUserAuthenticated;
      permissionsObject[definedPermissions.PAGE_OWNER] = ownerId === state.userId;

      return {
        ...state,
        permissions: permissionsObject,
        hasPagePermissions: hasPagePermissions(permissions),
        pagePermissions: [...permissions],
        pageOwnerId: ownerId,
      };
    }
    case UserActionTypes.RECEIVED: {
      if (action.error) {
        return state;
      }

      const {
        user: { _id: userId, roles },
      } = action.payload;

      const isStaff = isInStaffRoles(roles);

      const permissionsObject = getPermissionsObject(state.pagePermissions, roles);
      permissionsObject[definedPermissions.USER_AUTHENTICATED] = true;
      permissionsObject[definedPermissions.PAGE_OWNER] = userId === state.pageOwnerId;

      return {
        ...state,
        roles: [...roles],
        permissions: permissionsObject,
        isUserAuthenticated: true,
        isStaff,
        userId,
      };
    }
    case PageActionTypes.CHANGED: {
      const permissionsObject = getPermissionsObject([], state.roles);
      permissionsObject[definedPermissions.USER_AUTHENTICATED] = state.isUserAuthenticated;

      return {
        ...state,
        permissions: permissionsObject,
        hasPagePermissions: false,
        pagePermissions: [],
        pageOwnerId: '',
      };
    }
    default:
      return state;
  }
};
