import { PageType } from '@streamloots/streamloots-types';
import { ReduxStore } from 'types/indexTS';
import { userSelectors } from 'services/user';
import { CurrentUserData } from './types';

let _currentUserData: CurrentUserData = null;

export const storeObserver = (state: ReduxStore): void => {
  if (typeof window === 'undefined') {
    return;
  }

  const currentUserPage = userSelectors.currentUserPage(state);
  _currentUserData = {
    userId: userSelectors.userId(state),
    username: userSelectors.username(state),
    country: userSelectors.currentUserCountryCode(state),
    usageIntention: userSelectors.usageIntention(state),
    provider: userSelectors.primaryAuthProvider(state),
    isPartner: currentUserPage?.type === PageType.PARTNER ?? false,
    userCategory: userSelectors.userCategory(state),
  };
};

export default {
  getUsername: (): string => _currentUserData?.username ?? '',
  getUserId: (): string => _currentUserData?.userId ?? '',
  getUserCategory: (): string => _currentUserData?.userCategory ?? '',
  isPartner: (): boolean => _currentUserData?.isPartner ?? false,
  getUserData: (): CurrentUserData => _currentUserData,
};
