import { PlanItem } from 'model/plans';
import stateCreator from 'helpers/stateCreator';
import type { PagePlanState } from './types';
import { PageActionTypes } from '../page';
import { PagePlanActionTypes } from './actionTypes';

const initialState: PagePlanState = {
  plans: null,
};

export const reducer = (state: PagePlanState = initialState, action): PagePlanState => {
  const { type, error, payload } = action;
  switch (type) {
    case PagePlanActionTypes.FETCHING:
      return stateCreator.getAsyncLoadingState(state, {
        invalidateData: false,
      });
    case PagePlanActionTypes.RECEIVED: {
      if (error) {
        return stateCreator.getAsyncErrorState(action, state, initialState);
      }
      const data = payload.data as PlanItem[];
      return stateCreator.getInitialAsyncState(state, {
        plans: [...data],
        onlyActivePlan: data.length === 1,
      });
    }
    case PagePlanActionTypes.ADDED: {
      if (error) {
        return state;
      }

      return {
        ...state,
        plans: null,
        invalidateData: true,
      };
    }
    case PagePlanActionTypes.UPDATED: {
      if (error) {
        return state;
      }
      const { plan } = payload;
      const plans = state.plans || [];
      return {
        ...state,
        plans: plans.map(planItem => (planItem._id === plan._id ? { ...plan } : { ...planItem })),
      };
    }
    case PagePlanActionTypes.DELETED: {
      if (error) {
        return state;
      }

      return {
        ...state,
        invalidateData: true,
        plans: null,
      };
    }
    case PageActionTypes.CHANGED:
      return initialState;
    default:
      return state;
  }
};
