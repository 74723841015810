import { paginationStateNormalizer } from 'services/pagination';
import { PartnershipApplicationStatus } from 'model/indexTS';
import { PartnershipApplicationsActionTypes } from './actionTypes';
import type { PartnershipApplicationsState, PartnershipApplicationsActions } from './types';

const PAGINATION_STATE_NORMALIZER_CONFIGURATION = {
  initialQuery: {
    limit: 40,
    status: PartnershipApplicationStatus.PENDING,
  },
};

const initialState: PartnershipApplicationsState = paginationStateNormalizer.getInitial(
  PAGINATION_STATE_NORMALIZER_CONFIGURATION,
);

export const reducer = (
  state: PartnershipApplicationsState = initialState,
  action: PartnershipApplicationsActions,
): PartnershipApplicationsState => {
  switch (action.type) {
    case PartnershipApplicationsActionTypes.RECEIVED: {
      if (action.error) {
        return paginationStateNormalizer.getReceivedError(action, state);
      }
      return paginationStateNormalizer.getReceived(action.payload, PAGINATION_STATE_NORMALIZER_CONFIGURATION);
    }
    case PartnershipApplicationsActionTypes.REQUEST: {
      return paginationStateNormalizer.getRequest(state, action.payload, PAGINATION_STATE_NORMALIZER_CONFIGURATION);
    }
    case PartnershipApplicationsActionTypes.NEXT_REQUEST:
      return paginationStateNormalizer.getFetchingNextItems(state);
    case PartnershipApplicationsActionTypes.NEXT_RECEIVED: {
      if (action.error) {
        return paginationStateNormalizer.getNextReceivedError(state);
      }
      return paginationStateNormalizer.getNextItemsReceived(state, action.payload);
    }
    case PartnershipApplicationsActionTypes.REACT_SUCCESS: {
      const data = state.data || [];
      const { applicationId, isAccepted } = action.payload;

      return {
        ...state,
        data: data.map(partnershipApplication => {
          if (applicationId !== partnershipApplication._id) {
            return partnershipApplication;
          }

          return {
            ...partnershipApplication,
            status: isAccepted ? PartnershipApplicationStatus.ACCEPTED : PartnershipApplicationStatus.REJECTED,
          };
        }),
      };
    }
    default:
      return state;
  }
};
