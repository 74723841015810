import { PanelSkeletons } from 'ui-library/panel';
import loadable from 'components/dynamic-import';
import { FullLayout } from 'scenes/layout';

const DefaultValuesConfiguration = loadable(
  () => import('./components/default-values'),
  true,
  <PanelSkeletons count={1} />,
);

export const CraftingModeDefaultValuesPage = (): JSX.Element => (
  <FullLayout>
    <DefaultValuesConfiguration />
  </FullLayout>
);
