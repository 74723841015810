import { Languages } from '@streamloots/streamloots-types';

export class LoveFormatter {
  static format(num: number, language: Languages): string {
    if (num < 1000) {
      return Math.floor(num).toString();
    }

    if (num < 1_000_000) {
      return LoveFormatter.formatThousands(num, language);
    }

    return LoveFormatter.formatWithMillions(num, language);
  }

  private static formatThousands(num: number, language: Languages) {
    const isSpanish = language === Languages.Spanish;
    const result = num / 1000;
    const hasDecimals = Math.floor(result) !== result;
    const calculatedResult = hasDecimals ? result.toFixed(2) : result.toString();
    const resultByLanguage = !isSpanish ? calculatedResult : calculatedResult.replace('.', ',');

    return `${resultByLanguage}k`;
  }

  private static formatWithMillions(num: number, language: Languages) {
    const isSpanish = language === 'es';
    const result = num / 1_000_000;
    const hasDecimals = Math.floor(result) !== result;
    const calculatedResult = hasDecimals ? result.toFixed(2) : result.toString();
    const resultByLanguage = !isSpanish ? calculatedResult : calculatedResult.replace('.', ',');

    return `${resultByLanguage}M`;
  }
}
