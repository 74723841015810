import stateCreator from 'helpers/stateCreator';
import { SubscriptionAlertsActionTypes } from './actionTypes';
import { SubscriptionAlertActions, SubscriptionAlertState } from './types';

const initialState: SubscriptionAlertState = {};

export const reducer = (
  state: SubscriptionAlertState = initialState,
  action: SubscriptionAlertActions,
): SubscriptionAlertState => {
  switch (action.type) {
    case SubscriptionAlertsActionTypes.CREATE_LOADING:
    case SubscriptionAlertsActionTypes.DELETE_LOADING:
    case SubscriptionAlertsActionTypes.FETCH_LOADING:
      return {
        ...stateCreator.getAsyncLoadingState(state),
        ...state,
      };

    case SubscriptionAlertsActionTypes.CREATE_ERROR:
    case SubscriptionAlertsActionTypes.DELETE_ERROR:
    case SubscriptionAlertsActionTypes.FETCH_ERROR:
      return {
        ...stateCreator.getAsyncErrorState(action, state),
        ...state,
      };

    case SubscriptionAlertsActionTypes.FETCH_SUCCESS: {
      const newState: SubscriptionAlertState = {
        subscriptionAlerts: action.payload,
      };
      return stateCreator.getInitialAsyncState(state, newState);
    }

    case SubscriptionAlertsActionTypes.CREATE_SUCCESS: {
      if (state.subscriptionAlerts) {
        const newState: SubscriptionAlertState = {
          subscriptionAlerts: [...state.subscriptionAlerts, action.payload],
        };
        return stateCreator.getInitialAsyncState(state, newState);
      }
      return state;
    }

    case SubscriptionAlertsActionTypes.DELETE_SUCCESS: {
      if (state.subscriptionAlerts) {
        const newState: SubscriptionAlertState = {
          subscriptionAlerts: state.subscriptionAlerts.filter(alert => alert._id !== action.payload.subscriptionId),
        };

        return stateCreator.getInitialAsyncState(state, newState);
      }
      return state;
    }

    case SubscriptionAlertsActionTypes.MY_REACTIONS_CREATE_SUCCESS: {
      if (state.myReactions) {
        const newState: SubscriptionAlertState = {
          ...state,
          myReactions: [...state.myReactions, action.payload],
        };

        return stateCreator.getInitialAsyncState(state, newState);
      }

      return stateCreator.getInitialAsyncState(state, { ...state, myReactions: [action.payload] });
    }

    case SubscriptionAlertsActionTypes.MY_REACTIONS_DELETE_SUCCESS: {
      if (state.myReactions) {
        const newState: SubscriptionAlertState = {
          ...state,
          myReactions: state.myReactions.filter(alert => alert._id !== action.payload._id),
        };

        return stateCreator.getInitialAsyncState(state, newState);
      }

      return state;
    }

    case SubscriptionAlertsActionTypes.MY_REACTIONS_FETCH_SUCCESS: {
      const newState: SubscriptionAlertState = {
        ...state,
        myReactions: action.payload.filter(alert => alert.pageId),
      };

      return stateCreator.getInitialAsyncState(state, newState);
    }

    default:
      return state;
  }
};
