import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { Coupon } from 'model/indexTS';
import { FetchSingleCoupon } from './services/fetch-single';
import { FetchCoupons, FetchNextCoupons } from './services/list';
import { CouponListStateByComponentKey, FetchNewBuyerCoupon } from './services/list/types';
import { CreateCoupon } from './services/create';
import { CouponRedemptionStatus, RedeemCoupon, ResetCouponRedemption } from './services/redeem';
import { RevokeCoupon } from './services/revoke';
import { couponsSelectors } from './selectors';
import { couponsActions } from './actions';

export const useCouponsListSelectorsByComponentKey = (componentKey: string): CouponListStateByComponentKey => {
  return useSelector(state => couponsSelectors.couponListStatus(state, componentKey));
};

export const useNewBuyerCoupon = (): Coupon | undefined => {
  return useSelector((state: any) => state.coupons.list.newBuyerCoupon);
};

export const useCouponRedemptionSelectors = (): CouponRedemptionStatus => {
  return useSelector(couponsSelectors.couponRedemptionStatus);
};

export interface UseCouponsActions {
  fetchNewBuyerCoupon: FetchNewBuyerCoupon;
  fetchCoupons: FetchCoupons;
  fetchNextCoupons: FetchNextCoupons;
  fetchSingleCoupon: FetchSingleCoupon;
  createCoupon: CreateCoupon;
  redeemCoupon: RedeemCoupon;
  resetCouponRedemption: ResetCouponRedemption;
  revokeCoupon: RevokeCoupon;
}

export const useCouponsActions = (): UseCouponsActions => {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      fetchNewBuyerCoupon: (pageId: string) => dispatch(couponsActions.fetchNewBuyerCoupon(pageId)),
      fetchCoupons: (componentKey, query, resetData) =>
        dispatch(couponsActions.fetchCoupons(componentKey, query, resetData)),
      fetchNextCoupons: (componentKey, nextUrl) => dispatch(couponsActions.fetchNextCoupons(componentKey, nextUrl)),
      fetchSingleCoupon: (slug, code) => dispatch(couponsActions.fetchSingleCoupon(slug, code)),
      createCoupon: (componentKey, params) => dispatch(couponsActions.createCoupon(componentKey, params)),
      redeemCoupon: (couponCode, setId) => dispatch(couponsActions.redeemCoupon(couponCode, setId)),
      resetCouponRedemption: () => dispatch(couponsActions.resetCouponRedemption()),
      revokeCoupon: (componentKey, couponId) => dispatch(couponsActions.revokeCoupon(componentKey, couponId)),
    }),
    [dispatch],
  );
};
