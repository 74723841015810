import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import Skeleton from 'ui-library/skeleton';
import { PanelContent } from './PanelContent';
import { PanelProps } from './types';
import theme from './panel.scss';

const classNames = ClassNames(theme);

const Panel = ({
  children,
  colorful,
  className = '',
  title,
  description,
  headerClassName = '',
  contentClassName = '',
  hClassName = '',
  renderFooter,
  skeletonMode,
  showHeader = true,
  color = 'default',
}: PanelProps): JSX.Element => {
  return (
    <article
      className={classNames({
        panel: true,
        [className]: className,
        [`panel-${color}`]: color !== 'default',
      })}
    >
      {showHeader && (
        <header
          className={classNames({
            'panel__header': true,
            'panel__header--colorful': colorful,
            [headerClassName]: headerClassName,
          })}
        >
          <h2
            className={classNames({
              panel__title: true,
              [hClassName]: Boolean(hClassName),
            })}
          >
            {skeletonMode ? <Skeleton width={200} /> : title}
          </h2>
          {description && <p className={classNames('panel__description')}>{description}</p>}
          {skeletonMode && (
            <p className={classNames('panel__description')}>
              <Skeleton />
              <Skeleton />
            </p>
          )}
        </header>
      )}
      <PanelContent className={contentClassName}>
        {skeletonMode ? (
          <React.Fragment>
            <Skeleton className="margin-bottom" height={50} />
            <Skeleton height={50} />
          </React.Fragment>
        ) : (
          children
        )}
      </PanelContent>
      {renderFooter && <div className={classNames('panel__footer')}>{renderFooter()}</div>}
    </article>
  );
};

export default Panel;
