import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { Checkbox } from 'ui-library/form-elements';
import theme from './grid.scss';

const classNames = ClassNames(theme);

interface GridRowProps {
  rowClassName?: string;
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
  selected?: boolean;
  checkable?: boolean;
  checked?: boolean;
  onCheckClick?: () => void;
}

export const GridRow = ({
  children,
  className = '',
  checked,
  rowClassName = '',
  selected,
  checkable,
  onClick,
  onCheckClick,
}: GridRowProps): JSX.Element => (
  <div
    className={classNames({
      'grid__row': true,
      'grid__row--selected': selected,
      'grid__row--checkable': checkable,
      [rowClassName]: Boolean(rowClassName),
    })}
  >
    {checkable && (
      <div className={classNames('grid__checkbox')}>
        <Checkbox checked={checked} onChange={onCheckClick} />
      </div>
    )}
    <div
      className={classNames({
        grid__row__content: true,
        [className]: Boolean(className),
      })}
      onClick={onClick}
    >
      {children}
    </div>
  </div>
);
