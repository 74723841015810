import { NOTIFICATION_TYPES } from 'model/indexTS';
import { NotificationsQuery } from 'services/notifications/typesTS';
import type { PaginationStateNormalizerConfiguration } from '../pagination/typesTS';

export const DEFAULT_SUMMARY = {
  newNotificationsInAppCount: {
    count: 0,
  },
};

export const DEFAULT_QUERY = {
  limit: 15,
};

export const PAGINATION_STATE_NORMALIZER_CONFIGURATION: PaginationStateNormalizerConfiguration<NotificationsQuery> = {
  initialQuery: DEFAULT_QUERY,
};

// We use this in action creators to separe floating and list notifications.
const { ACHIEVEMENT_GRANTED, ...listTypes } = NOTIFICATION_TYPES;
export const QUERY_STRING_TYPE_PARAM = {
  LIST: { type: Object.values(listTypes).join(',') },
  FLOATING: { type: ACHIEVEMENT_GRANTED },
};
