import type { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { PageModules, PageModulesValues } from 'model/pageTS';
import { pageSelectors } from 'services/page';

interface PageModulesCheckerProps {
  children: ReactNode;
  modulesRequired: PageModulesValues | PageModulesValues[];
}

export const areModulesRequiredEnabled = (
  modulesEnabled: PageModules | undefined,
  modulesRequired: PageModulesValues | PageModulesValues[],
): boolean => {
  if (!modulesEnabled) {
    return false;
  }

  if (typeof modulesRequired === 'string') {
    return modulesEnabled[modulesRequired].enabled;
  }

  return modulesRequired.some(module => modulesEnabled[module].enabled);
};

export const PageModulesChecker = ({ modulesRequired, children }: PageModulesCheckerProps): JSX.Element | null => {
  const modulesEnabled = useSelector(pageSelectors.pageModules);

  if (!areModulesRequiredEnabled(modulesEnabled, modulesRequired)) {
    return null;
  }

  return <>{children}</>;
};
