import { PageActionTypes } from 'services/page';
import stateCreator from 'helpers/stateCreator';
import { PageEmotesActionTypes } from './actionTypes';
import { PageEmotesActions, PageEmotesState } from './types';

const initialState: PageEmotesState = {};

export const reducer = (state = initialState, action: PageEmotesActions): PageEmotesState => {
  switch (action.type) {
    case PageEmotesActionTypes.PLAY_PAGE_EMOTES_ERROR:
    case PageEmotesActionTypes.FETCH_PAGE_EMOTES_LOADING:
      return { ...state, ...stateCreator.getAsyncLoadingState(state) };

    case PageEmotesActionTypes.FETCH_PAGE_EMOTES_ERROR:
      return stateCreator.getAsyncErrorState(action, state);

    case PageEmotesActionTypes.FETCH_PAGE_EMOTES_SUCCESS:
      return stateCreator.getInitialAsyncState(state, {
        ...state,
        collections: action.payload || [],
      });

    case PageEmotesActionTypes.PLAY_PAGE_EMOTES_SUCCESS:
      return stateCreator.getInitialAsyncState(state);

    case PageEmotesActionTypes.PAGE_EMOTES_FILTERED:
      return { ...state, searchString: action.payload.searchString };

    case PageActionTypes.CHANGED:
      return initialState;

    default:
      return state;
  }
};
