import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import ILLUSTRATIONS from 'constants/illustrations';
import theme from './errors.scss';

const classNames = ClassNames(theme);

interface ErrorPageProps {
  description?: string;
  imageUrl?: string;
  title?: string;
  children?: React.ReactNode;
}

export const ErrorPage = ({
  description,
  title = '',
  children,
  imageUrl = ILLUSTRATIONS.SEARCHING,
}: ErrorPageProps): JSX.Element => {
  return (
    <div className={classNames('errorpage')}>
      {imageUrl && (
        <div className={classNames('errorpage__img')}>
          <img src={imageUrl} alt={title} />
        </div>
      )}
      <h2 className={classNames('errorpage__title')}>{title}</h2>
      <div className="margin-bottom-x2">{description}</div>
      {children}
    </div>
  );
};
