import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Set } from 'model/indexTS';
import { PublishSet, ResetPublishSet } from './services/publish-set';
import { UnpublishSet } from './services/unpublish-set';
import { ArchiveSet } from './services/delete-set';
import { UpdateCraftableCardsInSet, UpdateSet } from './services/update-set';
import { ChangeSelectedSet, FetchSets, OrderSets } from './services/set-list';
import { UpdateRarities } from './services/set-rarities';
import { CreateSet, MarathonSpecialSetSelectorOutput, PauseSet, ResumeSet } from './types';
import { setsSelectors } from './selectors';
import { setsActions } from './actions';

export interface UseSetsSelectors {
  sets?: Set[];
  setsById: Record<string, Set>;
  selectedSetId: string;
  selectedSet: Set | null;
  setsPublishedCount: number;
  defaultSetId: string;
  marathonSpecialSet: MarathonSpecialSetSelectorOutput;
  hasSets: boolean;
}

export interface UseSetsActions {
  archiveSet: ArchiveSet;
  changeSelectedSet: ChangeSelectedSet;
  createSet: CreateSet;
  fetchSets: FetchSets;
  orderSets: OrderSets;
  pauseSet: PauseSet;
  publishSet: PublishSet;
  resetPublishSet: ResetPublishSet;
  resumeSet: ResumeSet;
  unpublishSet: UnpublishSet;
  updateCraftableCardsInSet: UpdateCraftableCardsInSet;
  updateRarities: UpdateRarities;
  updateSet: UpdateSet;
}

export const useSetsActions = (): UseSetsActions => {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      archiveSet: (setId, giveResources) => dispatch(setsActions.archiveSet(setId, giveResources)),
      changeSelectedSet: (setId, doNotReload) => dispatch(setsActions.changeSelectedSet(setId, doNotReload)),
      createSet: parameters => dispatch(setsActions.createSet(parameters)),
      fetchSets: slug => dispatch(setsActions.fetchSets(slug)),
      orderSets: (sets, pageId) => dispatch(setsActions.orderSets(sets, pageId)),
      pauseSet: (parameters, setId) => dispatch(setsActions.pauseSet(parameters, setId)),
      publishSet: setId => dispatch(setsActions.publishSet(setId)),
      resetPublishSet: () => dispatch(setsActions.resetPublishSet()),
      resumeSet: setId => dispatch(setsActions.resumeSet(setId)),
      unpublishSet: setId => dispatch(setsActions.unpublishSet(setId)),
      updateCraftableCardsInSet: (craftableCards, setInfo, setId) =>
        dispatch(setsActions.updateCraftableCardsInSet(craftableCards, setInfo, setId)),
      updateRarities: (params, setId) => dispatch(setsActions.updateRarities(params, setId)),
      updateSet: (setInfo, setId) => dispatch(setsActions.updateSet(setInfo, setId)),
    }),
    [dispatch],
  );
};

export const useSetsSelectors = (): UseSetsSelectors => {
  const sets = useSelector(setsSelectors.sets);
  const setsById = useSelector(setsSelectors.setsById);
  const selectedSetId = useSelector(setsSelectors.setId);
  const selectedSet = useSelector(setsSelectors.selectedSet);
  const setsPublishedCount = useSelector(setsSelectors.setsPublishedCount);
  const defaultSetId = useSelector(setsSelectors.defaultSetId);
  const marathonSpecialSet = useSelector(setsSelectors.marathonSpecialSet);

  return {
    sets,
    setsById,
    selectedSetId,
    selectedSet,
    setsPublishedCount,
    defaultSetId,
    marathonSpecialSet,
    hasSets: Boolean(sets?.length),
  };
};
