import actionCreator from 'helpers/actionCreator';
import type {
  OverloadedContentOpenedAction,
  OverloadedContentClosedAction,
  OverloadedContentParams,
  OverloadedContentWrapperProps,
  OverloadedModeWrapperPropsUpdatedAction,
} from './typesTS';
import { OverloadedContentActionTypes } from './actionTypes';

const openOverloadedContent = <Component>(content: OverloadedContentParams<Component>): OverloadedContentOpenedAction =>
  actionCreator.createAction(OverloadedContentActionTypes.OPENED, content);

const closeOverloadedContent = (): OverloadedContentClosedAction =>
  actionCreator.createAction(OverloadedContentActionTypes.CLOSED);

const updateWrapperProps = (wrapperProps: OverloadedContentWrapperProps): OverloadedModeWrapperPropsUpdatedAction =>
  actionCreator.createAction(OverloadedContentActionTypes.WRAPPER_PROPS_UPDATED, wrapperProps);

export const overloadedContentActions = {
  closeOverloadedContent,
  openOverloadedContent,
  updateWrapperProps,
};
