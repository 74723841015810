import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import loadable from 'components/dynamic-import';
import { FullLayout } from '../layout';

const EditAuction = loadable(() => import('./EditAuction'));

type MatchParamsProps = {
  auctionId?: string;
  slug: string;
};

type EditAuctionPageProps = RouteComponentProps<MatchParamsProps>;

export const EditAuctionPage = ({ match }: EditAuctionPageProps) => {
  const { auctionId, slug } = match.params;
  return (
    <FullLayout>
      <EditAuction auctionId={auctionId} slug={slug} />
    </FullLayout>
  );
};
