import { AchievementProgress, AchievementStats } from 'model/indexTS';
import { FixMe } from 'types/indexTS';
import type { AchievementsState } from './types';

const getAchievementsSuccess = (data: FixMe): AchievementsState => {
  const normalizedData = data.reduce((accumulator, userAchievement) => {
    const {
      achievementId,
      actived: isActived,
      completed: isCompleted,
      completedAt,
      stats,
      achievement,
    } = userAchievement;
    const { startsAt, completionRules, eventsDefinition, iconImageUrl, expiresAt, rewards, locales } = achievement;

    // Derived data, just once, when normalizing, and we forget about this
    const progresses = stats.reduce((acc: AchievementProgress[], curr: AchievementStats): AchievementProgress[] => {
      const { value: currentValue = 0, name: statsName } = curr;

      const targetValue = completionRules.find(rules => rules.name === statsName)?.value ?? 0;

      const type = eventsDefinition.find(rules => rules.name === statsName)?.type;

      const progressObject = {
        statsName,
        currentValue: currentValue < targetValue ? currentValue : targetValue,
        targetValue,
        type,
      };

      return [...acc, progressObject];
    }, []);

    return {
      ...accumulator,
      [achievementId]: {
        isActived,
        isCompleted,
        completedAt,
        progresses,
        achievement: {
          startsAt,
          expiresAt,
          rewards,
          iconImageUrl,
          locales,
          eventsDefinition,
        },
      },
    };
  }, {});

  return normalizedData;
};

export default {
  getAchievementsSuccess,
};
