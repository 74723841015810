import { ChangeEvent, forwardRef, useCallback, useEffect, useRef } from 'react';
import ClassNames from '@streamloots/classnames';
import { ButtonIcon } from 'ui-library/button';
import type { InputNumberProps } from './types';
import { Label } from './Label';
import FormElementAdditionalInfo from './FormElementAdditionalInfo';
import theme from './form.scss';
import incrementArrow from './assets/incrementArrow.svg';
import decrementArrow from './assets/decrementArrow.svg';

const classNames = ClassNames(theme);

export const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>(
  (
    {
      className = '',
      labelClassName,
      label,
      required,
      id,
      disabled,
      placeholder,
      error,
      value,
      min,
      max,
      wrapperClassName = '',
      showButtons = true,
      grey,
      small,
      big,
      units,
      onChange,
      decimals,
      helperText,
      additionalClassName = '',
      unitsClassName = '',
      ...rest
    }: InputNumberProps,
    innerRef,
  ) => {
    const interval = useRef<number | undefined>();
    const timeout = useRef<number | undefined>();
    const valueReference = useRef<number | undefined | ''>(value); // used to update value automatically while keeping mouseDown
    const disableMinus = disabled || (typeof min !== 'undefined' && Number(value) <= min);
    const disablePlus = disabled || (typeof max !== 'undefined' && Number(value) >= max);

    const clearTimers = useCallback(() => {
      clearInterval(interval.current);
      clearTimeout(timeout.current);
    }, []);

    useEffect(() => {
      clearTimers();
    }, [clearTimers]);

    const normalizeValueToDecimals = (newValue: number): number => {
      if (typeof decimals === 'undefined') {
        return newValue;
      }
      return Number(parseFloat(newValue.toString()).toFixed(decimals));
    };

    const incrementValue = (originalValue: number | undefined | '') => {
      const newValue = Number(originalValue) + 1;
      if (typeof max === 'number' && newValue > max) {
        return;
      }

      onChange(normalizeValueToDecimals(newValue));
      valueReference.current = newValue;
    };

    const handleIncrementClick = () => {
      incrementValue(value);
    };

    const decrementValue = (originalValue: number | undefined | '') => {
      const newValue = Number(originalValue) - 1;
      if (typeof min === 'number' && newValue < min) {
        return;
      }
      onChange(normalizeValueToDecimals(newValue));
      valueReference.current = newValue;
    };

    const handleDecrementClick = () => {
      decrementValue(value);
    };

    const handleDecrementMouseDown = () => {
      clearTimers();
      valueReference.current = value;
      timeout.current = window.setTimeout(() => {
        interval.current = window.setInterval(() => {
          decrementValue(valueReference.current);
        }, 50);
      }, 400);
    };

    const handleIncrementMouseDown = () => {
      clearTimers();

      valueReference.current = value;
      timeout.current = window.setTimeout(() => {
        interval.current = window.setInterval(() => {
          incrementValue(valueReference.current);
        }, 50);
      }, 400);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { value: targetValue } = event.target;

      if (targetValue === '') {
        onChange(targetValue);
        return;
      }

      const parsedValue = Number(targetValue);

      if (typeof min === 'number' && parsedValue < min) {
        onChange(min);
        return;
      }
      if (typeof max === 'number' && parsedValue > max) {
        onChange(max);
        return;
      }

      onChange(parsedValue);
    };

    return (
      <>
        {label && (
          <Label className={labelClassName} error={error} id={id} required={required}>
            {label}
          </Label>
        )}
        <div
          className={classNames({
            'number': true,
            'number--grey': grey,
            [wrapperClassName]: Boolean(wrapperClassName),
          })}
        >
          {showButtons && (
            <ButtonIcon
              data-testid={'input-number-decrement-button'}
              onClick={handleDecrementClick}
              onMouseDown={handleDecrementMouseDown}
              onMouseUp={clearTimers}
              onMouseLeave={clearTimers}
              onTouchMove={clearTimers}
              onTouchStart={handleDecrementMouseDown}
              onTouchEnd={clearTimers}
              disabled={disableMinus}
              icon="minus"
              grey={grey}
              className={classNames('number__button-left')}
            />
          )}
          <input
            id={id}
            className={classNames({
              'number__input': true,
              'number__input--grey': grey,
              'number__input--small': small,
              'number__input--big': big,
              [className]: Boolean(className),
            })}
            type="number"
            required={required}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            ref={innerRef}
            {...rest}
            onChange={handleChange}
          />
          {!disabled && !showButtons && (
            <div>
              <div
                className={classNames({
                  inputNumberInline__spinner: true,
                  inputNumberInline__spinner__increment: true,
                })}
                onClick={handleIncrementClick}
                onMouseDown={handleIncrementMouseDown}
                onMouseUp={clearTimers}
                onMouseLeave={clearTimers}
                onTouchMove={clearTimers}
                onTouchStart={handleIncrementMouseDown}
                onTouchEnd={clearTimers}
              >
                <img src={incrementArrow} alt="increment" />
              </div>
              <div
                className={classNames({
                  inputNumberInline__spinner: true,
                  inputNumberInline__spinner__decrement: true,
                })}
                onClick={handleDecrementClick}
                onMouseDown={handleDecrementMouseDown}
                onMouseUp={clearTimers}
                onMouseLeave={clearTimers}
                onTouchMove={clearTimers}
                onTouchStart={handleDecrementMouseDown}
                onTouchEnd={clearTimers}
              >
                <img src={decrementArrow} alt="decrement" />
              </div>
            </div>
          )}
          {showButtons && (
            <ButtonIcon
              data-testid={'input-number-increment-button'}
              onClick={handleIncrementClick}
              onMouseDown={handleIncrementMouseDown}
              onMouseUp={clearTimers}
              onMouseLeave={clearTimers}
              onTouchMove={clearTimers}
              onTouchStart={handleIncrementMouseDown}
              onTouchEnd={clearTimers}
              disabled={disablePlus}
              icon="plus"
              grey={grey}
              className={classNames('number__button-right')}
            />
          )}
          {units && (
            <div
              className={classNames({
                'number__units': true,
                'number__units--disabled': disabled,
                [unitsClassName]: Boolean(unitsClassName),
              })}
            >
              {units}
            </div>
          )}
        </div>
        <FormElementAdditionalInfo
          inputInfoClassName={additionalClassName}
          error={error}
          value={value}
          helperText={helperText}
        />
      </>
    );
  },
);
