import { FileTypeFilter } from '@streamloots/streamloots-types';
import { PaginationStateNormalizerConfiguration } from 'services/pagination/typesTS';
import { FilesQuery } from './services/list';

export const DEFAULT_FILES_QUERY: FilesQuery = {
  limit: 48,
  type: FileTypeFilter.ALL,
};

export const PAGINATION_STATE_NORMALIZER_CONFIGURATION: PaginationStateNormalizerConfiguration<FilesQuery> = {
  initialQuery: DEFAULT_FILES_QUERY,
};
