import { PageActions, PageActionTypes } from 'services/page';
import { paginationStateNormalizer } from 'services/pagination';
import { SubscriptionsActionTypes } from 'services/subscriptions';
import { SubscriptionActions } from 'services/subscriptions/types';
import { PageFeedCommentActions, PageFeedCommentsActionTypes } from 'services/page-feed-comments';
import { OrdersActionTypes } from 'services/orders/actionTypes';
import { CreateOrderActions } from 'services/orders/types';
import { OrderedFeedContentItem, OrderedItemType, PageFeedPaymentStatus } from 'model/indexTS';
import { PageFeedActions, PageFeedState } from './types';
import { PageFeedActionTypes } from './actionTypes';
import { DEFAULT_PAGE_FEED_QUERY, PAGINATION_STATE_NORMALIZER_CONFIGURATION } from './constants';

const initialState: PageFeedState = {
  query: DEFAULT_PAGE_FEED_QUERY,
};

export const reducer = (
  state = initialState,
  action: PageFeedActions | PageActions | SubscriptionActions | PageFeedCommentActions | CreateOrderActions,
): PageFeedState => {
  switch (action.type) {
    // ----- Actions: PageFeedFetch -----
    case PageFeedActionTypes.FETCH_PAGE_FEED_SUCCESS:
      return paginationStateNormalizer.getReceived(action.payload, PAGINATION_STATE_NORMALIZER_CONFIGURATION);

    case PageFeedActionTypes.FETCH_PAGE_FEED_ERROR:
      return paginationStateNormalizer.getReceivedError(action, state);

    case PageFeedActionTypes.FETCH_PAGE_FEED_REQUEST:
      return paginationStateNormalizer.getRequest(state, action.payload, PAGINATION_STATE_NORMALIZER_CONFIGURATION);

    // ----- Actions: PageFeedFetchNext -----
    case PageFeedActionTypes.FETCH_NEXT_PAGE_FEED_REQUEST:
      return paginationStateNormalizer.getFetchingNextItems(state, action.payload.cancelToken);

    case PageFeedActionTypes.FETCH_NEXT_PAGE_FEED_SUCCESS:
      return paginationStateNormalizer.getNextItemsReceived(state, action.payload);

    case PageFeedActionTypes.FETCH_NEXT_PAGE_FEED_ERROR:
      return paginationStateNormalizer.getNextReceivedError(state);

    // ----- Actions: DeletePageFeed -----
    case PageFeedActionTypes.DELETE_PAGE_FEED_REQUEST:
      return { ...state, isDeleting: true };

    case PageFeedActionTypes.DELETE_PAGE_FEED_ERROR:
      return { ...state, isDeleting: false };

    case PageFeedActionTypes.DELETE_PAGE_FEED_SUCCESS: {
      const newData = state.data?.filter(item => item._id !== action.metadata._id);
      return { ...state, data: newData, isDeleting: false };
    }

    // ----- Actions: CreatePageFeed -----
    case PageFeedActionTypes.CREATE_PAGE_FEED_REQUEST:
      return { ...state, isCreating: true };

    case PageFeedActionTypes.CREATE_PAGE_FEED_ERROR:
      return { ...state, isCreating: false };

    case PageFeedActionTypes.CREATE_PAGE_FEED_SUCCESS: {
      const newData = [action.payload, ...(state.data ?? [])];
      return { ...state, data: newData, isCreating: false };
    }

    // ----- Actions: FetchOnePost ------
    case PageFeedActionTypes.FETCH_PAGE_FEED_DETAIL_REQUEST: {
      return {
        ...state,
        isLoadingOne: true,
      };
    }

    case PageFeedActionTypes.FETCH_PAGE_FEED_DETAIL_SUCCESS: {
      if (action.error || action.payload == null) {
        return {
          ...state,
          isLoadingOne: false,
          detailedPosts: {
            ...state.detailedPosts,
          },
        };
      }

      return {
        ...state,
        isLoadingOne: false,
        detailedPosts: {
          ...state.detailedPosts,
          [action.payload._id]: action.payload,
        },
      };
    }

    // ----- Actions:LikePageFeed -----
    case PageFeedActionTypes.LIKE_PAGE_FEED_REQUEST: {
      const { _id, liked } = action.payload;
      const newData = state.data?.map(item =>
        item._id !== _id
          ? item
          : {
              ...item,
              summary: {
                ...item.summary,
                liked,
                reactionCount: liked ? item.summary.reactionCount + 1 : item.summary.reactionCount - 1,
              },
            },
      );
      return { ...state, data: newData };
    }

    case PageFeedActionTypes.LIKE_PAGE_FEED_ERROR: {
      const { _id, liked } = action.metadata;
      const newData = state.data?.map(item =>
        item._id !== _id
          ? item
          : {
              ...item,
              summary: {
                ...item.summary,
                liked: !liked,
                reactionCount: liked ? item.summary.reactionCount - 1 : item.summary.reactionCount + 1,
              },
            },
      );
      return { ...state, data: newData };
    }

    // ----- Actions: Fix summary -----
    case PageFeedCommentsActionTypes.CREATE_PAGE_FEED_COMMENT_SUCCESS: {
      const { feedId } = action.metadata;
      const newData = state.data?.map(item =>
        item._id !== feedId
          ? item
          : {
              ...item,
              summary: {
                ...item.summary,
                commentCount: item.summary.commentCount + 1,
                hasCommented: true,
              },
            },
      );
      return { ...state, data: newData };
    }

    case PageFeedCommentsActionTypes.DELETE_PAGE_FEED_COMMENT_SUCCESS: {
      const { feedId } = action.metadata;
      const newData = state.data?.map(item =>
        item._id !== feedId
          ? item
          : {
              ...item,
              summary: {
                ...item.summary,
                commentCount: item.summary.commentCount - 1,
                hasCommented: item.summary.commentCount - 1 <= 0 ? false : item.summary.hasCommented,
              },
            },
      );
      return { ...state, data: newData };
    }

    // ----- Actions: Unlock content -----
    case OrdersActionTypes.CREATE_ORDER_REQUEST: {
      if (action.payload.orderedItems?.[0]?.type !== OrderedItemType.FeedContent) {
        return state;
      }
      const { feedId } = action.payload.orderedItems[0] as OrderedFeedContentItem;

      const newData = state.data?.map(item =>
        item._id !== feedId
          ? item
          : {
              ...item,
              summary: {
                ...item.summary,
                paymentStatus: PageFeedPaymentStatus.InProgress,
              },
            },
      );
      return { ...state, data: newData };
    }

    // ----- Actions: Reconcile -----
    case PageActionTypes.CHANGED:
    case SubscriptionsActionTypes.EXECUTE_SUBSCRIPTION_ORDER_SUCCESS: //Stripe subscription
    case SubscriptionsActionTypes.APPROVE_PAYPAL_SUCCESS: // Paypal subscription
      return { ...initialState, reconcile: true };

    default:
      return state;
  }
};
