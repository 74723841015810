export enum NotificationsActionTypes {
  FETCHING = 'notifications/FETCHING',
  RECEIVED = 'notifications/RECEIVED',
  NEXT_RECEIVED = 'notifications/NEXT_RECEIVED',
  NEXT_REQUEST = 'notifications/NEXT_REQUEST',
  MARKING_AS_READ = 'notifications/MARKING_AS_READ',
  MARKED_AS_READ = 'notifications/MARKED_AS_READ',
  FLOATING_MARK_AS_READ_REQUEST = 'notifications/FLOATING_MARK_AS_READ_REQUEST',
  FLOATING_MARK_AS_READ_SUCCESS = 'notifications/FLOATING_MARK_AS_READ_SUCCESS',
  FLOATING_MARK_AS_READ_ERROR = 'notifications/FLOATING_MARK_AS_READ_ERROR',
  GET_FLOATING_REQUEST = 'notifications/GET_FLOATING_REQUEST',
  GET_FLOATING_SUCCESS = 'notifications/GET_FLOATING_SUCCESS',
  GET_FLOATING_ERROR = 'notifications/GET_FLOATING_ERROR',
}
