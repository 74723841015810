import stateCreator from 'helpers/stateCreator';
import type { AuthState } from './types';
import { actionTypes } from './actionTypes';

export const getInitialState = (authToken: string): AuthState => ({
  authToken,
});

export const reducer = (state: AuthState = {}, action): AuthState => {
  const { error, type, payload, metadata } = action;

  switch (type) {
    case actionTypes.AUTHENTICATED:
      if (error) {
        return stateCreator.getAsyncErrorState(action, state);
      }
      return stateCreator.getInitialAsyncState(state, { ...payload });

    case actionTypes.TOKEN_INVALIDATED:
      return { ...state, invalidateAuthToken: true };
    case actionTypes.GET_TOKENS_SUCCESS:
    case actionTypes.POST_TOKENS_SUCCESS:
      return stateCreator.getInitialAsyncState(state, {
        [metadata.scope]: payload.token,
      });
    case actionTypes.GET_TOKENS_ERROR:
    case actionTypes.POST_TOKENS_ERROR:
      return stateCreator.getAsyncErrorState(action, state);
    default:
      return state;
  }
};
