import actionCreator from 'helpers/actionCreator';
import { actionTypes } from '../../actionTypes';
import endpoints from '../../../endpoints';
import authNormalizer from '../../normalizer';
import { scopeRequest, scopeTestFns } from '../../constants';
import { SCOPE_TOKENS_VALUES } from '../../types';

function getTokenSuccesWithScope({ data, additionalData }) {
  return actionCreator.createAction(
    actionTypes.GET_TOKENS_SUCCESS,
    authNormalizer.tokensScoped(data, scopeTestFns[additionalData.scope]),
    additionalData,
  );
}

function getTokensError({ errorData, additionalData }) {
  return actionCreator.createErrorAction(actionTypes.GET_TOKENS_ERROR, errorData, {}, additionalData);
}

const getTokensUser = (scope: SCOPE_TOKENS_VALUES) =>
  actionCreator.createAsyncAction({
    method: 'get',
    additionalData: { scope },
    endpoint: endpoints.USER.TOKENS,
    errorBinding: getTokensError,
    loadingAction: actionTypes.GET_TOKENS_REQUEST,
    successBinding: getTokenSuccesWithScope,
  });

function postTokensError({ errorData, additionalData }) {
  return actionCreator.createErrorAction(actionTypes.POST_TOKENS_ERROR, errorData, {}, additionalData);
}

function postTokenSuccesWithScope({ data, additionalData }) {
  return actionCreator.createAction(actionTypes.POST_TOKENS_SUCCESS, data, additionalData);
}

const postTokensUser = (scope: SCOPE_TOKENS_VALUES) =>
  actionCreator.createAsyncAction({
    method: 'post',
    additionalData: { scope },
    endpoint: endpoints.USER.TOKENS,
    parameters: scopeRequest[scope],
    errorBinding: postTokensError,
    loadingAction: actionTypes.POST_TOKENS_REQUEST,
    successBinding: postTokenSuccesWithScope,
  });

export const tokensActions = {
  getTokensUser,
  postTokensUser,
};
