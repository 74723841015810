export enum UserActionTypes {
  CURRENT_USER_PROFILE_BY_PROVIDER_RECEIVED = 'user/CURRENT_USER_PROFILE_BY_PROVIDER_RECEIVED',
  NET_PROMOTER_SCORE_UPDATED = 'user/NET_PROMOTER_SCORE_UPDATED',
  RECEIVED = 'user/RECEIVED',
  REQUEST = 'user/REQUEST',
  RETRIEVING_CURRENT_USER_PROFILE_BY_PROVIDER = 'user/RETRIEVING_CURRENT_USER_PROFILE_BY_PROVIDER',
  UPDATED = 'user/UPDATED',
  UPDATED_PROFILE = 'user/UPDATE_PROFILE',
  UPDATING = 'user/UPDATING',
  UPDATING_NET_PROMOTER_SCORE = 'user/UPDATING_NET_PROMOTER_SCORE',
  UPDATING_PROFILE = 'user/UPDATING_PROFILE',
  UPDATING_USER_PREFERENCES = 'user/UPDATING_USER_PREFERENCES',
  UPDATING_SOCIAL_MEDIA = 'user/UPDATING_SOCIAL_MEDIA',
  USER_PREFERENCES_UPDATED = 'user/USER_PREFERENCES_UPDATED',
  UPDATING_USER_EMAIL = 'user/UPDATING_EMAIL',
  UPDATED_USER_EMAIL = 'user/UPDATED_EMAIL',
  UPDATED_SOCIAL_MEDIA = 'user/UPDATED_SOCIAL_MEDIA',
  VERIFY_RECEIVED = 'user/VERIFY_RECEIVED',
  VERIFYING = 'user/VERIFYING',
  DELETE_REQUEST = 'user/DELETE_REQUEST',
  DELETE_SUCCESS = 'user/DELETE_SUCCESS',
  DELETE_ERROR = 'user/DELETE_ERROR',
}
