import { checkPermissions, permissions } from 'components/permissions';
import loadable from 'components/dynamic-import';
import { FullLayout } from 'scenes/layout';

const ConnectedEditPredefinedCard = loadable(() => import('./ConnectedEditPredefinedCard'));

interface EditPredefinedCardPageProps {
  match: {
    params: {
      templateId: string;
    };
  };
}

const EditPredefinedCardPageInternal = (props: EditPredefinedCardPageProps) => {
  const {
    match: {
      params: { templateId },
    },
  } = props;

  return (
    <FullLayout>
      <ConnectedEditPredefinedCard key={templateId} templateId={templateId} isCreating={templateId === 'create'} />
    </FullLayout>
  );
};

export const EditPredefinedCardPage = checkPermissions({
  requiredPermissions: [
    permissions.ADMIN,
    permissions.PARTNERSHIP_MANAGER,
    permissions.ACCOUNT_MANAGER,
    permissions.SUPPORT,
  ],
  redirectToBasePage: true,
})(EditPredefinedCardPageInternal);
