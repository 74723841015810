export type MediaQueryListenerType = {
  addListener: (func: () => void) => any | null;
  removeListener: (func: () => void) => any | null;
  match: () => boolean;
};

// Used in useMedia hook for media query dependant logic,
// i.e. conditionally rendering a component depending on a specific resolution
export default (media: string): MediaQueryListenerType => {
  const mobileMatchMedia = typeof matchMedia === 'undefined' ? null : matchMedia(media);

  const addListener = (func: () => void): any | null => {
    if (mobileMatchMedia) {
      return mobileMatchMedia.addListener(func);
    }
    return null;
  };

  const removeListener = (func: () => void): any | null => {
    if (mobileMatchMedia && func) {
      mobileMatchMedia.removeListener(func);
    }
  };

  const match = (): boolean => {
    if (mobileMatchMedia) {
      return mobileMatchMedia.matches;
    }
    return false;
  };

  return {
    addListener,
    match,
    removeListener,
  };
};
