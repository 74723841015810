import type { FunctionComponent } from 'react';
import { compose } from '@reduxjs/toolkit';
import { withTranslation, TranslateInterface } from 'utils/translation';
import { FullLayout } from 'scenes/layout';
import { BigColumnPanel, SmallColumnPanel, TwoColumnLayout } from 'ui-library/two-column-panel';
import loadable from 'components/dynamic-import';
import { checkPermissions, permissions } from 'components/permissions';
import { Redirect } from 'react-router-dom';
import { urls } from 'services/routing';
import { usePageSelectors } from 'services/page';
import { ManageSetsHeader } from './components/manage-sets-header';
import { ConnectedSetsRightPanel } from './components/right-panel';

const ManageSets = loadable(() => import('./ManageSets'));

type ManageSetsPagesProps = TranslateInterface;

const ManageSetsPageInternal = ({ t }: ManageSetsPagesProps) => {
  const {
    page: { modules },
  } = usePageSelectors();

  if (modules && !modules.cards.enabled) {
    return <Redirect to={urls().ENABLE_CARDS} />;
  }

  return (
    <FullLayout>
      <ManageSetsHeader t={t} />
      <TwoColumnLayout reverse largeBreak>
        <BigColumnPanel largeBreak>
          <ManageSets t={t} />
        </BigColumnPanel>
        <SmallColumnPanel largeBreak>
          <ConnectedSetsRightPanel />
        </SmallColumnPanel>
      </TwoColumnLayout>
    </FullLayout>
  );
};

export const ManageSetsPage = compose<FunctionComponent>(
  checkPermissions({
    requiredPermissions: [permissions.SETS_EDIT],
    redirectToBasePage: true,
  }),
  withTranslation('adminSets'),
)(ManageSetsPageInternal);
