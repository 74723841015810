import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { TranslateInterface, withTranslation } from 'utils/translation';
import keyCodes from 'utils/keyCodes';
import { SearchInput, SearchInputProps } from 'ui-library/search-input';
import {
  ResultItem,
  ResultsConfiguration,
  AsyncAutocompleteResultsStatus,
  OnResultClick,
  NormalizeAutocompleteAsyncResult,
} from './types';
import { Results } from './Results';
import theme from './autocomplete-async.scss';

const classNames = ClassNames(theme);

export interface AsyncAutocompleteComponentProps
  extends Omit<SearchInputProps, 'value' | 'showSearchIcon' | 'onSearch'>,
    ResultsConfiguration,
    AsyncAutocompleteResultsStatus {
  resultsClass?: string;
  selectedValue?: string;
  onResultClick?: OnResultClick;
  normalizeResult: NormalizeAutocompleteAsyncResult;
  search: (value: string) => void;
  searchIcon?: boolean;
  value: string;
}

interface ExtendedProps extends AsyncAutocompleteComponentProps, TranslateInterface {}

type AsyncAutocompleteState = {
  resultIndex: number;
  setScrollToSelectedResult: boolean;
  focused: boolean;
};

class AsyncAutocompleteInternal extends React.Component<ExtendedProps, AsyncAutocompleteState> {
  node = React.createRef<HTMLDivElement>();

  state = {
    resultIndex: -1,
    setScrollToSelectedResult: false,
    focused: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const { value, isLoading, resultItems, search } = this.props;
    const { focused } = this.state;
    const needsToLoadResults = value && focused && !resultItems && !isLoading;

    if (resultItems !== prevProps.resultItems) {
      this.setState({ resultIndex: -1, setScrollToSelectedResult: false });
    }

    if (needsToLoadResults && !prevProps.isLoading && !prevState.focused) {
      search(value || '');
    }
  }

  handleResultClick = (result: ResultItem) => {
    const { onResultClick, onChange } = this.props;
    onChange(result.name);

    if (onResultClick) {
      onResultClick(result);
    }
  };

  handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { onBlur } = this.props;
    this.setState({ focused: false });

    if (onBlur) {
      onBlur(event);
    }
  };

  handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const { onFocus } = this.props;
    this.setState({ focused: true });

    if (onFocus) {
      onFocus(event);
    }
  };

  handleKeyDown = event => {
    const { normalizeResult, resultItems } = this.props;
    const { resultIndex } = this.state;
    const code = event.which;

    if (!Array.isArray(resultItems) || resultItems.length === 0) {
      return undefined;
    }

    if (code === keyCodes.ESCAPE) {
      event.preventDefault();
      this.node.current?.querySelector('input')?.blur();
      return false;
    }

    if (code === keyCodes.ENTER) {
      event.preventDefault();
      const index = resultIndex > -1 ? resultIndex : 0;
      const selectedResult = resultItems[index];
      this.node.current?.querySelector('input')?.blur();
      this.handleResultClick(normalizeResult(selectedResult));
      return false;
    }

    const lastIndex = resultItems.length - 1;

    if (code === keyCodes.ARROW_UP) {
      this.updateSelectedResult(resultIndex > 0 ? resultIndex - 1 : lastIndex, true);
    }

    if (code === keyCodes.ARROW_DOWN) {
      this.updateSelectedResult(resultIndex < lastIndex ? resultIndex + 1 : 0, true);
    }
    return undefined;
  };

  updateSelectedResult = (newIndex: number, setScrollToSelectedResult = false) => {
    this.setState({
      resultIndex: newIndex,
      setScrollToSelectedResult,
    });
  };

  getSelectedValue = () => {
    const { value, selectedValue } = this.props;
    return typeof selectedValue !== 'undefined' ? selectedValue : value;
  };

  render() {
    const {
      autoHeightMax,
      isLoading,
      normalizeResult,
      resultItems,
      resultsClass = '',
      resultsError,
      search,
      searchIcon,
      className,
      t,
      value,
      ...rest
    } = this.props;
    const { resultIndex, setScrollToSelectedResult, focused } = this.state;

    return (
      <div ref={this.node} className={className}>
        <SearchInput
          onKeyDown={this.handleKeyDown}
          showSearchIcon={searchIcon}
          value={value}
          {...rest}
          onSearch={search}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          noMargin={false}
        />
        {value && focused && (
          <div className={classNames('autocomplete-async__results-wrapper')}>
            <Results
              autoHeight
              autoHeightMax={autoHeightMax}
              className={classNames({
                [resultsClass]: resultsClass,
                'autocomplete-async__floating-results': true,
                'autocomplete-async__floating-results--icon': searchIcon,
              })}
              onResultClick={this.handleResultClick}
              onResultHover={this.updateSelectedResult}
              normalizeResult={normalizeResult}
              resultIndex={resultIndex}
              resultsError={resultsError}
              selectedValue={this.getSelectedValue()}
              setScrollToSelectedResult={setScrollToSelectedResult}
              t={t}
              isLoading={isLoading}
              results={resultItems}
              searchString={value}
            />
          </div>
        )}
      </div>
    );
  }
}

export const AsyncAutocomplete = withTranslation('common')(AsyncAutocompleteInternal);
