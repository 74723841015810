export enum actionTypes {
  AUTHENTICATED = 'auth/AUTHENTICATED',
  DISCONNECTED = 'auth/DISCONNECTED',
  TOKEN_INVALIDATED = 'auth/TOKEN_INVALIDATED',
  GET_TOKENS_REQUEST = 'auth/GET_TOKENS_REQUEST',
  GET_TOKENS_SUCCESS = 'auth/GET_TOKENS_SUCCESS',
  GET_TOKENS_ERROR = 'auth/GET_TOKENS_ERROR',
  POST_TOKENS_REQUEST = 'auth/POST_TOKENS_REQUEST',
  POST_TOKENS_SUCCESS = 'auth/POST_TOKENS_SUCCESS',
  POST_TOKENS_ERROR = 'auth/POST_TOKENS_ERROR',
}
