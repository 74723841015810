import { isAfter } from 'date-fns';
import type { Coupon } from 'model/couponTS';
import type { ActionMetadataError } from 'types/indexTS';
import urlUtils from 'utils/urlUtils';
import { pages, getUrlWithParams, PUBLIC_PAGE_LOCATIONS } from 'services/routing';
import { RESERVED_QUERY_PARAMS } from 'constants/reserved-query-params';
import { COUPON_REDEMPTION_BUSINESS_ERRORS } from './constants';
import { CouponListStatus } from './services/list';

const buildRelativeCouponUrl = (slug: string, code: string, giftCoupon: boolean): string => {
  const relativePage = getUrlWithParams(pages.USER_PAGE, { slug });
  if (!giftCoupon) {
    const startLocationString = `startLocation=${PUBLIC_PAGE_LOCATIONS.CHECKOUT_SELECT_COLLECTION}`;
    const discountCodeString = `${RESERVED_QUERY_PARAMS.PACKS_DISCOUNT_CODE}=${code}`;
    return `${relativePage}?${startLocationString}&${discountCodeString}`;
  }

  return `${relativePage}?${RESERVED_QUERY_PARAMS.GIFT_PACK_COUPON_CODE}=${code}`;
};

const buildCouponUrl = (slug: string, code: string, giftCoupon: boolean): string => {
  const relativePage = buildRelativeCouponUrl(slug, code, giftCoupon);
  if (!giftCoupon) {
    return urlUtils.buildDomainUrl(relativePage);
  }

  return urlUtils.buildDomainUrl(relativePage);
};

const isCouponExpired = (coupon: Coupon): boolean => {
  const { redeemLimitRemaining, priceRuleEndsAt, revoked } = coupon;
  if (revoked) {
    return true;
  }

  if (!priceRuleEndsAt) {
    return redeemLimitRemaining === 0;
  }
  return redeemLimitRemaining === 0 || isAfter(Date.now(), priceRuleEndsAt);
};

const isFreePackCoupon = (coupon: Coupon): boolean => {
  return typeof coupon.priceRuleQuantity === 'number';
};

const isBusinessError = (error: ActionMetadataError): boolean => {
  return COUPON_REDEMPTION_BUSINESS_ERRORS.indexOf(error.businessCode) > -1;
};

const getActiveCouponsCount = (coupons: CouponListStatus['data']): number => {
  return coupons?.filter(coupon => !isCouponExpired(coupon)).length ?? 0;
};

export default {
  isCouponExpired,
  buildCouponUrl,
  buildRelativeCouponUrl,
  isFreePackCoupon,
  isBusinessError,
  getActiveCouponsCount,
};
