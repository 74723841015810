import { withTranslation, TranslateInterface } from 'utils/translation';
import Button from 'ui-library/button';
import { DialogContent } from 'ui-library/dialog';

interface ConfirmationDialogContentProps extends TranslateInterface {
  confirmationMessage: string;
  confirmButtonText?: string;
  onClose?: () => void;
  onConfirmClick: () => void;
  isLoading?: boolean;
  title: string;
  warning?: boolean;
  reverse?: boolean;
  ariaLabel?: string;
}

const ConfirmationDialogContent = ({
  confirmationMessage,
  confirmButtonText,
  title,
  onClose,
  onConfirmClick,
  t,
  isLoading,
  warning,
  reverse,
  ariaLabel,
}: ConfirmationDialogContentProps) => {
  return (
    <DialogContent
      title={title}
      onClose={onClose}
      renderFooter={() => (
        <>
          {!reverse && onClose && <Button label={t('common:cancel')} onClick={onClose} />}
          <Button
            ariaLabel={ariaLabel}
            primary={!reverse && !warning}
            warning={!reverse && warning}
            label={confirmButtonText || t('confirm')}
            onClick={onConfirmClick}
            loading={isLoading}
          />
          {reverse && onClose && (
            <Button label={t('common:cancel')} onClick={onClose} primary={!warning} warning={warning} />
          )}
        </>
      )}
    >
      <p className="pre-wrap">{confirmationMessage}</p>
    </DialogContent>
  );
};

export default withTranslation(['common'])(ConfirmationDialogContent);
