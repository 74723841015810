import stateCreator from 'helpers/stateCreator';
import type { BaseClaimableCampaigns } from 'model/indexTS';
import { paymentActionTypes, PaymentActions } from 'services/payment';
import { SubscriptionGiftsActionTypes } from 'services/page-subscription-gifts';
import { SubscriptionsActionTypes } from 'services/subscriptions';
import { FloatingTicketActionTypes, ClaimFloatingTicketActionResponses } from 'services/floating-ticket';
import { ClaimableCampaignsAction, ClaimableCampaignsState } from './types';
import { ClaimableCampaignsActionTypes } from './actionTypes';

const initialState: ClaimableCampaignsState = {};

export const reducer = (
  state: ClaimableCampaignsState = initialState,
  action: ClaimableCampaignsAction | PaymentActions | ClaimFloatingTicketActionResponses,
): ClaimableCampaignsState => {
  const { type, error, payload } = action;
  switch (type) {
    case ClaimableCampaignsActionTypes.RECEIVED: {
      if (error) {
        return stateCreator.getAsyncErrorState(action, state, initialState);
      }

      const promotionsState = state.campaign || {};

      return stateCreator.getInitialAsyncState(state, {
        campaign: { ...promotionsState, ...(payload as BaseClaimableCampaigns) },
      });
    }

    case ClaimableCampaignsActionTypes.FETCHING:
      return stateCreator.getAsyncLoadingState(state, { reconcile: false });

    case paymentActionTypes.EXECUTED:
    case paymentActionTypes.STRIPE_ORDER_EXECUTED:
    case SubscriptionsActionTypes.EXECUTE_SUBSCRIPTION_ORDER_SUCCESS:
    case SubscriptionGiftsActionTypes.EXECUTE_SUBSCRIPTION_GIFT_ORDER_SUCCESS:
    case FloatingTicketActionTypes.CLAIM_SUCCESS:
      return { ...state, reconcile: true };
    default:
      return state;
  }
};
