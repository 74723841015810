import type { Coupon } from 'model/indexTS';
import { ActionSuccess, ActionError, CancelToken } from 'types/indexTS';
import type {
  PaginationResponse,
  FetchDataByComponentKey,
  FetchNextDataByComponentKey,
  PaginationState,
} from 'services/pagination/typesTS';
import { PaginationApiResponse } from 'services/pagination/typesTS';
import { CouponActionTypes } from '../../actionTypes';

export enum CouponsStatusFilter {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface CouponsQuery {
  limit: number;
  pageId?: string;
  status?: CouponsStatusFilter;
  priceRuleNewBuyer?: boolean;
}

export interface CouponsActionMetadata {
  componentKey: string;
}

export type CouponListResponse = PaginationResponse<Coupon, CouponsQuery>;

export type FetchCouponListActionSuccess = ActionSuccess<
  CouponActionTypes.RECEIVED,
  CouponListResponse,
  CouponsActionMetadata
>;

export type FetchNewBuyerCouponActionSuccess = ActionSuccess<
  CouponActionTypes.NEW_BUYER_RECEIVED,
  { coupon: Coupon | undefined }
>;
export type FetchNewBuyerCouponActionError = ActionError<CouponActionTypes.NEW_BUYER_RECEIVED>;

export type FetchCouponNextReceivedListActionSuccess = ActionSuccess<
  CouponActionTypes.NEXT_RECEIVED,
  PaginationApiResponse<Coupon>,
  CouponsActionMetadata
>;
export type FetchCouponListActionError = ActionError<
  CouponActionTypes.RECEIVED | CouponActionTypes.NEXT_RECEIVED,
  CouponsActionMetadata
>;
export type FetchCouponListActionResponses = FetchCouponListActionSuccess | FetchCouponListActionError;

export type FetchCouponListActionRequest = ActionSuccess<
  CouponActionTypes.REQUEST,
  { cancelToken: CancelToken; query: CouponsQuery; resetLoadedData?: boolean },
  CouponsActionMetadata
>;

export type FetchNextCouponListActionRequest = ActionSuccess<
  CouponActionTypes.NEXT_REQUEST,
  { cancelToken: CancelToken },
  CouponsActionMetadata
>;

export type CouponListActions = FetchCouponListActionResponses | FetchCouponListActionRequest;

export type CouponListStateByComponentKey = PaginationState<Coupon, CouponsQuery>;
export type CouponListState = Record<string, CouponListStateByComponentKey> & {
  newBuyerCoupon?: Coupon;
};
export type CouponListStatus = CouponListStateByComponentKey & {
  activeCouponsCount: number;
};

export type FetchCoupons = FetchDataByComponentKey<CouponsQuery, FetchCouponListActionResponses>;
export type FetchNewBuyerCoupon = (
  pageId: string,
) => Promise<FetchNewBuyerCouponActionSuccess | FetchNewBuyerCouponActionError>;
export type FetchNextCoupons = FetchNextDataByComponentKey<FetchCouponListActionResponses>;
