import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import theme from './panel.scss';

const classNames = ClassNames(theme);

export interface PanelContentProps {
  children?: React.ReactNode;
  className?: string;
}

export const PanelContent = ({ children, className = '' }: PanelContentProps): JSX.Element => {
  return (
    <div
      className={classNames({
        panel__content: true,
        [className]: Boolean(className),
      })}
    >
      {children}
    </div>
  );
};
