import type { ReactNode } from 'react';
import type { CheckPermissionsConfiguration } from './types';
import { PermissionsChecker } from './PermissionsChecker';

interface HasPermissionsPropsAsRenderPropComponent extends CheckPermissionsConfiguration {
  render?: () => ReactNode;
  children?: never;
}

interface HasPermissionsPropsAsParentComponent extends CheckPermissionsConfiguration {
  render?: never;
  children?: ReactNode;
}

type HasPermissionsProps = HasPermissionsPropsAsParentComponent | HasPermissionsPropsAsRenderPropComponent;

export const HasPermissions = ({ render, children, ...rest }: HasPermissionsProps): JSX.Element => {
  return <PermissionsChecker {...rest}>{render ? render() : children}</PermissionsChecker>;
};
