import _ from 'lodash';
import type { PaymentProcessorType } from 'model/indexTS';
import { PAYMENT_PROCESSORS } from 'model/indexTS';
import { pageFeedActions } from 'services/page-feed/actions';
import { pagesActions, DEFAULT_PAGES_QUERY } from '../pages';
import { userActions, userSelectors } from '../user';
import { pageActions } from '../page';
import { auctionActions } from '../auction';
import { userSetsActions } from '../user-sets';
import { setsActions, setUtils } from '../sets';
import { paymentActions } from '../payment';
import { authSelectors } from '../auth';
import { cultureSelectors } from '../culture';
import { rewindActions } from '../rewind';
import { originalsPublicActions, PUBLIC_ORIGINALS_LANDING_COMPONENT_KEY } from '../originals-public';

export const fetchUserIfAuthenticated = () => (dispatch, getState) => {
  const isUserAuthenticated = authSelectors.isUserAuthenticated(getState());

  if (isUserAuthenticated) {
    return dispatch(userActions.fetchCurrentUser());
  }

  return null;
};

export const fetchUserPage = (slug: string, path: string) => async (dispatch, _getState) => {
  const selectedSetId = setUtils.setIdFromUrl(path);
  if (selectedSetId) {
    dispatch(setsActions.changeSelectedSet(selectedSetId));
  }
  return Promise.all([dispatch(pageActions.fetchPage(slug)), dispatch(userSetsActions.fetchUserSets(slug))]);
};

export const fetchStreamers = (componentKey: string, limit: number = DEFAULT_PAGES_QUERY.limit) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const isUserAuthenticated = authSelectors.isUserAuthenticated(state);
  if (isUserAuthenticated) {
    return null;
  }

  return dispatch(pagesActions.fetchPages(componentKey, { limit, languages: cultureSelectors.language(state) }));
};

export const fetchRewind = (userId: string) => dispatch => {
  return dispatch(rewindActions.fetchRewind(userId));
};

export const fetchPaymentOrderInfo = (paymentId: string, processor: PaymentProcessorType) => dispatch => {
  if (processor !== PAYMENT_PROCESSORS.PAYSAFECARD) {
    return null;
  }
  return dispatch(paymentActions.fetchPaymentOrderInfo(paymentId, processor));
};

export const fetchSingleAuction = (slug: string, auctionId: string) => dispatch => {
  return dispatch(auctionActions.fetchSingleAuction(slug, auctionId));
};

export const fetchSingleFeedPost = (slug: string, postId: string) => dispatch => {
  return dispatch(pageFeedActions.fetchSingleFeedPost(slug, postId));
};

export const fetchPublicOriginals = frameId => (dispatch, getState) => {
  const state = getState();
  const language = cultureSelectors.language(state);
  return dispatch(
    originalsPublicActions.fetchPublicOriginalsCards({
      frameId,
      filters: { language },
      componentKey: PUBLIC_ORIGINALS_LANDING_COMPONENT_KEY,
    }),
  );
};
