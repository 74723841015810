import { History } from 'history';
import { PanelSkeletons } from 'ui-library/panel';
import Integrations from 'scenes/auth-providers';
import { FullLayout } from 'scenes/layout';
import loadable from 'components/dynamic-import';
import { CheckPermissions, permissions } from 'components/permissions';
import { UpdateSocialMedia } from './components/social-media';
import { DeleteAccountButton } from './components/delete-account';

const ConnectedUserProfile = loadable(() => import('./ConnectedUserProfile'), true, <PanelSkeletons count={1} />);

type UserCollectionsPageProps = {
  match: {
    params: {
      provider?: string;
    };
  };
  history: History;
};

export const UserProfilePage = (props: UserCollectionsPageProps): JSX.Element => (
  <FullLayout>
    <CheckPermissions requiredPermissions={permissions.USER_AUTHENTICATED}>
      <ConnectedUserProfile />
      <UpdateSocialMedia />
      <Integrations {...props} />
      <section className="padding-top align-right">
        <DeleteAccountButton />
      </section>
    </CheckPermissions>
  </FullLayout>
);
