import {
  StrictOmit,
  MARATHON_POST_REQUEST,
  MARATHON_PUT_REQUEST,
  MarathonWidgetStyles,
  MARATHON_TRACKER_GET_RESPONSE_SUCCESS,
  MARATHONS_GET_RESPONSE_SUCCESS,
} from '@streamloots/streamloots-types';

export type WidgetStyles = MarathonWidgetStyles;

export type MarathonConfig = MARATHONS_GET_RESPONSE_SUCCESS & {
  specialInteractions: boolean;
  pageId: string;
};
/// ^^^ Remove from the backend or move to the library type

export type MarathonData = StrictOmit<MarathonConfig, 'userId'>;

export type MarathonCreateFormValues = MARATHON_POST_REQUEST & {
  specialInteractions: boolean;
};
export type MarathonPutFormValues = MARATHON_PUT_REQUEST & {
  specialInteractions: boolean;
};

export type MarathonConfigRequest = MarathonCreateFormValues | MarathonPutFormValues;

export type MarathonTracker = MARATHON_TRACKER_GET_RESPONSE_SUCCESS;

export enum MarathonStates {
  ACTIVE = 'ACTIVE',
  STOPPED = 'STOPPED',
}
