import loadable from 'components/dynamic-import';
import { SimplifiedCardSkeletons } from 'components/component-skeletons';
import { FullLayout } from 'scenes/layout';

export const ManageCardsList = loadable(
  () => import('./ConnectedCardList'),
  true,
  <FullLayout>
    <SimplifiedCardSkeletons includeWrapper />,
  </FullLayout>,
);
