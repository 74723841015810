import { useState, FunctionComponent } from 'react';
import { differenceInSeconds } from 'date-fns';
import { compose } from '@reduxjs/toolkit';
import { TranslateInterface, withTranslation } from 'utils/translation';
import { usePageSelectors } from 'services/page';
import { useMarathonActions } from 'services/marathon';
import { useReactAlert } from 'components/react-alert-provider';
import { checkPermissions, staffPermissions, permissions } from 'components/permissions';
import trackEventManager from 'utils/event-manager';
import { useMarathonPolling } from './useMarathonPolling';
import { TimerPanel } from './TimerPanel';
import { useMarathonTimer, PanelState } from './useMarathonTimer';
import { TimerEndPanel } from './TimerEndPanel';

type TimerPanelContainerProps = TranslateInterface;

const ConnectedTimerPanelContainer = ({ t }: TimerPanelContainerProps): JSX.Element | null => {
  const [isAddingTime, setIsAddingTime] = useState(false);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  useMarathonPolling();
  const {
    page: { slug },
  } = usePageSelectors();
  const {
    inPageContext,
    marathonTracker,
    showPanel,
    handleHidePanel,
    isActiveMarathon,
    addMarathonTime,
    marathonTrackerId,
    fetchMarathonTracker,
    setShowPanel,
  } = useMarathonTimer();
  const { updateMarathonStatus } = useMarathonActions();
  const { showError, showSuccess } = useReactAlert();

  const handleAddTime = (seconds: number) => {
    if (!marathonTrackerId) {
      return;
    }

    setIsAddingTime(true);

    addMarathonTime(slug, {
      seconds,
    }).then(response => {
      setIsAddingTime(false);
      if (response.error) {
        showError(response.payload.message);
        return;
      }

      showSuccess(t('marathon:timeAdded'));
    });
  };

  const handleFinish = () => {
    if (marathonTracker != null && marathonTrackerId != null && slug) {
      fetchMarathonTracker(slug).then(response => {
        if (response.error) {
          showError(response.payload.message);
          return;
        }

        const { payload } = response;

        if (!payload) {
          return;
        }

        if (differenceInSeconds(payload.expectedEndingAt, Date.now()) <= 0) {
          setShowPanel(PanelState.FINISHED);

          trackEventManager.marathonStatusChanged({
            action: 'stop',
            location: 'Marathon page',
            marathon_id: marathonTracker._id,
          });
        }
      });
    }
  };

  const handleUpdateStatus = (isPausing: boolean) => {
    setIsUpdatingStatus(true);

    updateMarathonStatus(slug, isPausing).then(response => {
      setIsUpdatingStatus(false);

      if (response.error) {
        showError(response.payload.message);
        return;
      }

      trackEventManager.marathonStatusChanged({
        action: isPausing ? 'pause' : 'resume',
        location: 'manage marathon panel',
        marathon_id: marathonTrackerId || '',
      });

      showSuccess(t(isPausing ? 'marathon:pausedSucceed' : 'marathon:resumedSucceed'));
    });
  };

  if (marathonTracker == null || !inPageContext) {
    return null;
  }
  return (
    <>
      {isActiveMarathon && (
        <TimerPanel
          t={t}
          expectedEndingAt={marathonTracker.expectedEndingAt}
          isPaused={Boolean(marathonTracker.pausedAt)}
          onFinish={handleFinish}
          onAdd={handleAddTime}
          onChangeStatus={handleUpdateStatus}
          isUpdatingStatus={isUpdatingStatus}
          isAddingTime={isAddingTime}
          remainingSeconds={marathonTracker?.remainingSeconds}
        />
      )}
      {showPanel === PanelState.FINISHED && <TimerEndPanel t={t} onHidePanel={handleHidePanel} />}
    </>
  );
};

export const TimerPanelContainer = compose<FunctionComponent>(
  checkPermissions({ requiredPermissions: [permissions.PAGE_OWNER, permissions.MARATHON_EDIT, ...staffPermissions] }),
  withTranslation(['marathon']),
)(ConnectedTimerPanelContainer);
