import { ReactNode } from 'react';
import useLocalStorage from 'use-local-storage';
import ClassNames from '@streamloots/classnames';
import Render from 'components/render';
import { CloseExplanationPanel } from './CloseExplanationPanel';
import theme from './explanation-panel.scss';

interface ExplanationPanelProps {
  renderImageHeader?: () => ReactNode;
  children: ReactNode;
  horizontal?: boolean;
  largeVertical?: boolean;
  className?: string;
  storageKey?: string;
}

const classNames = ClassNames(theme);

export const ExplanationPanel = ({
  renderImageHeader,
  children,
  horizontal,
  largeVertical,
  className = '',
  storageKey = '',
}: ExplanationPanelProps): JSX.Element | null => {
  const [closed, setClosed] = useLocalStorage(storageKey, false);

  if (closed) {
    return null;
  }

  return (
    <Render.Client>
      <div
        className={classNames({
          'explanation-panel': true,
          'explanation-panel__horizontal': horizontal,
          'explanation-panel__large-vertical': largeVertical,
          [className]: Boolean(className),
        })}
      >
        {renderImageHeader && renderImageHeader()}
        {children}
        {storageKey && <CloseExplanationPanel onClose={() => setClosed(true)} />}
      </div>
    </Render.Client>
  );
};
