import _clean from 'lodash-clean';
import type { SetRarity } from 'model/indexTS';
import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import normalizer from '../../normalizer';
import { SetsActionTypes } from '../../actionTypes';
import { UpdateSetRaritiesActionError, UpdateSetRaritiesActionSuccess } from './types';

const rarityUpdated = ({ parameters, urlParameters }): UpdateSetRaritiesActionSuccess =>
  actionCreator.createAction(SetsActionTypes.RARITIES_UPDATED, {
    rarities: normalizer.rarities(parameters),
    setId: urlParameters.setId,
  });

const raritiesNotUpdated = ({ errorData }): UpdateSetRaritiesActionError =>
  actionCreator.createErrorAction(SetsActionTypes.RARITIES_UPDATED, errorData);

const updateRarities = (params: Array<SetRarity>, setId: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.RARITIES.UPDATE,
    errorBinding: raritiesNotUpdated,
    loadingAction: SetsActionTypes.UPDATING_RARITIES,
    method: 'put',
    parameters: _clean(params),
    urlParameters: { setId },
    successBinding: rarityUpdated,
  });

export const setRaritiesActions = {
  updateRarities,
};
