import type { PaginationStateNormalizerConfiguration } from 'services/pagination/typesTS';
import { CouponsStatusFilter, CouponsQuery } from './services/list/types';

export const DEFAULT_COUPONS_QUERY: CouponsQuery = {
  status: CouponsStatusFilter.ACTIVE,
  limit: 50,
};

export const COUPON_REDEMPTION_BUSINESS_ERRORS = [
  'COUPON_NOT_EXISTS',
  'COUPON_NOT_APPLICABLE',
  'COUPON_REDEEM_LIMIT_REACH',
  'COUPON_USAGE_LIMIT_REACHED',
];

export const PAGINATION_STATE_NORMALIZER_CONFIGURATION: PaginationStateNormalizerConfiguration<CouponsQuery> = {
  initialQuery: DEFAULT_COUPONS_QUERY,
};
