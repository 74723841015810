import stateCreator from 'helpers/stateCreator';
import { UserActionTypes } from 'services/user';
import { OnboardingActionTypes } from 'services/onboarding';
import { ClaimableCampaignsActionTypes } from 'services/claimable-campaigns';
import { JoinLeftCampaignActionSuccess } from 'services/claimable-campaigns/types';
import type { PageState } from './types';
import { PageActionTypes } from './actionTypes';
import { getPageState } from './normalizer';

export const INITIAL_PAGE_STATE: PageState = {
  slug: '',
  inPageContext: false,
  inPublicPageContext: false,
  internalManagement: {},
};

export const reducer = (state: PageState = INITIAL_PAGE_STATE, action): PageState => {
  const { error, payload } = action;
  switch (action.type) {
    case OnboardingActionTypes.FINALIZED: {
      return { ...INITIAL_PAGE_STATE, currentUserId: state.currentUserId };
    }
    case ClaimableCampaignsActionTypes.JOIN_LEFT_SUCCESS: {
      return { ...state, backFriday2022Joined: payload.joining };
    }
    case PageActionTypes.UPDATE_PAGE_MODULES_SUCCESS:
    case PageActionTypes.UPDATED: {
      if (error) {
        return state;
      }

      const { rankingVisibility, customization, modules } = payload;

      return {
        ...state,
        rankingVisibility,
        customization,
        modules,
      };
    }
    case PageActionTypes.RECEIVED: {
      if (error) {
        return stateCreator.getAsyncErrorState(action, state, {
          isLoaded: true,
        });
      }

      return stateCreator.getInitialAsyncState(state, getPageState(payload, state.currentUserId));
    }
    case PageActionTypes.REQUEST: {
      const currentSlug = state.slug;
      const { slug } = payload;

      if (slug === currentSlug) {
        return stateCreator.getAsyncLoadingState(state);
      }
      return stateCreator.getAsyncLoadingState(state, {
        slug,
        isLoaded: slug === currentSlug,
        inPageContext: true,
      });
    }
    case PageActionTypes.UPGRADED: {
      if (error) {
        return state;
      }
      return { ...state, level: 2 };
    }
    case PageActionTypes.CONTEXT_CHANGED: {
      return { ...state, inPageContext: payload.inPageContext };
    }

    case PageActionTypes.PUBLIC_PAGE_CONTEXT_CHANGED: {
      return { ...state, inPublicPageContext: payload.inPublicPageContext };
    }

    case UserActionTypes.RECEIVED: {
      if (error) {
        return state;
      }

      const {
        user: { _id: currentUserId },
      } = payload;

      return {
        ...state,
        currentUserId,
        isOwner: currentUserId === state.ownerId,
      };
    }
    default:
      return state;
  }
};
