export enum PageActionTypes {
  CONTEXT_CHANGED = 'page/CONTEXT_CHANGED',
  PUBLIC_PAGE_CONTEXT_CHANGED = 'page/PUBLIC_PAGE_CONTEXT_CHANGED',
  CHANGED = 'page/CHANGED',
  REQUEST = 'page/REQUEST',
  RECEIVED = 'page/RECEIVED',
  APPLYING_FOR_NEXT_TYPE = 'page/APPLYING_FOR_NEXT_TYPE',
  APPLIED_FOR_NEXT_TYPE = 'page/APPLIED_FOR_NEXT_TYPE',
  ACKNOWLEDGING_APPLICATION = 'page/ACKNOWLEDGING_APPLICATION',
  APPLICATION_ACKNOWLEDGED = 'page/APPLICATION_ACKNOWLEDGED',
  UPDATED = 'page/UPDATED',
  UPDATING = 'page/UPDATING',
  UPGRADED = 'page/UPGRADED',
  UPGRADING = 'page/UPGRADING',
  UPDATE_PAGE_MODULES_SUCCESS = 'page/UPDATE_PAGE_MODULES_SUCCESS',
  UPDATE_PAGE_MODULES_ERROR = 'page/UPDATE_PAGE_MODULES_ERROR',
  UPDATE_PAGE_MODULES_REQUEST = 'page/UPDATE_PAGE_MODULES_REQUEST',
}
export default PageActionTypes;
