import { useEffect } from 'react';
import { MarathonStates } from 'model/indexTS';
import { useMarathonActions, useMarathonSelectors } from 'services/marathon';
import { usePageSelectors } from 'services/page';

export const useMarathonPolling = (): void => {
  const { marathonStatus, marathonId } = useMarathonSelectors();
  const { fetchMarathonTracker } = useMarathonActions();
  const {
    page: { slug },
  } = usePageSelectors();

  useEffect(() => {
    let interval;
    if (marathonId != null && marathonStatus === MarathonStates.ACTIVE && slug) {
      interval = setInterval(() => {
        fetchMarathonTracker(slug);
      }, 20000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [fetchMarathonTracker, marathonId, marathonStatus, slug]);
};
