import { PageActionTypes } from 'services/page';
import { paginationStateNormalizer } from 'services/pagination';
import stateCreator from 'helpers/stateCreator';
import type { CouponListState, CouponListStateByComponentKey } from './types';
import { PAGINATION_STATE_NORMALIZER_CONFIGURATION } from '../../constants';
import { CouponActionTypes } from '../../actionTypes';

const getCouponStateByKey = (state, action): CouponListStateByComponentKey => {
  switch (action.type) {
    case CouponActionTypes.RECEIVED: {
      if (action.error) {
        return {
          ...state,
          ...paginationStateNormalizer.getReceivedError(action, state),
        };
      }
      const newPaginationState = paginationStateNormalizer.getReceived(
        action.payload,
        PAGINATION_STATE_NORMALIZER_CONFIGURATION,
      );
      return {
        ...state,
        ...newPaginationState,
      };
    }
    case CouponActionTypes.REQUEST: {
      return paginationStateNormalizer.getRequest(state, action.payload, PAGINATION_STATE_NORMALIZER_CONFIGURATION);
    }
    case CouponActionTypes.NEXT_REQUEST:
      return paginationStateNormalizer.getFetchingNextItems(state, action.payload.cancelToken);
    case CouponActionTypes.NEXT_RECEIVED: {
      if (action.error) {
        return paginationStateNormalizer.getNextReceivedError(state);
      }

      const { payload } = action;

      return {
        ...paginationStateNormalizer.getNextItemsReceived(state, payload),
      };
    }
    case CouponActionTypes.CREATED: {
      if (action.error) {
        return state;
      }

      const { data } = state;
      const { coupon } = action.payload;

      if (coupon.priceRuleNewBuyer) {
        return state;
      }

      const couponList = data || [];
      const newCouponList = [{ ...coupon }, ...couponList];
      return {
        ...state,
        data: newCouponList,
      };
    }
    case CouponActionTypes.REVOKED: {
      if (action.error) {
        return state;
      }
      const { couponId } = action.payload;
      const { data } = state;
      const newCouponList = data.map(coupon => {
        if (coupon._id === couponId) {
          return { ...coupon, revoked: true };
        }
        return coupon;
      });
      return {
        ...state,
        data: newCouponList,
      };
    }
    default:
      return state;
  }
};

export const reducer = (state: CouponListState = {}, action): CouponListState => {
  switch (action.type) {
    case CouponActionTypes.RECEIVED:
    case CouponActionTypes.REQUEST:
    case CouponActionTypes.NEXT_REQUEST:
    case CouponActionTypes.NEXT_RECEIVED: {
      const { componentKey } = action.metadata;
      return stateCreator.getUpdatedStateByKey(state, action, componentKey, getCouponStateByKey);
    }
    case CouponActionTypes.REVOKED: {
      if (action.error || state?.newBuyerCoupon?._id !== action.payload.couponId) {
        const { componentKey } = action.metadata;
        return stateCreator.getUpdatedStateByKey(state, action, componentKey, getCouponStateByKey);
      }

      return {
        ...state,
        newBuyerCoupon: undefined,
      } as CouponListState;
    }
    case CouponActionTypes.CREATED: {
      if (action.error || !action.payload.coupon.priceRuleNewBuyer) {
        const { componentKey } = action.metadata;
        return stateCreator.getUpdatedStateByKey(state, action, componentKey, getCouponStateByKey);
      }

      const { coupon } = action.payload;

      return {
        ...state,
        newBuyerCoupon: coupon,
      };
    }
    case CouponActionTypes.NEW_BUYER_RECEIVED: {
      if (action.error) {
        return state;
      }

      return {
        ...state,
        newBuyerCoupon: action.payload.coupon,
      };
    }
    case PageActionTypes.CHANGED:
      return {};
    default:
      return state;
  }
};
