import { changeSetCardStatusActionTypes } from './services/change-status';
import { createFromTemplateActionTypes } from './services/create-from-template';
import { createSetCardActionTypes } from './services/create';
import { deleteSetCardActionTypes } from './services/delete';
import { orderCardsActionTypes } from './services/order-cards';
import { setCardListActionTypes } from './services/list';
import { transferCardsActionTypes } from './services/transfer-cards';
import { updateSetCardActionTypes } from './services/update';
import { bulkUpdateCardsActionTypes } from './services/bulk-update';
import { updateSetCardsCraftingConfigurationActionTypes } from './services/update-crafting-configuration';

export const setCardsActionTypes = {
  ...changeSetCardStatusActionTypes,
  ...createFromTemplateActionTypes,
  ...createSetCardActionTypes,
  ...deleteSetCardActionTypes,
  ...orderCardsActionTypes,
  ...setCardListActionTypes,
  ...transferCardsActionTypes,
  ...updateSetCardActionTypes,
  ...updateSetCardsCraftingConfigurationActionTypes,
  ...bulkUpdateCardsActionTypes,
};
