import { zeroPad } from 'react-countdown-now';

const MINUTE_IN_SECONDS = 60;
const HOUR_IN_SECONDS = MINUTE_IN_SECONDS * 60;
const DAY_IN_SECONDS = HOUR_IN_SECONDS * 24;

export interface CountdownShapeFromSeconds {
  days?: number;
  hours: number;
  seconds: number;
  minutes: number;
}

export interface CountdownShapeFromSecondsOptions {
  daysInHours?: boolean;
}

interface FormattedCountdownTimeParameters {
  daysInHours?: boolean;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds: number;
}

export const getCountdownShapeFromSeconds = (
  seconds: number,
  { daysInHours = false }: CountdownShapeFromSecondsOptions = {},
): CountdownShapeFromSeconds => {
  if (!seconds) {
    return {
      days: daysInHours ? undefined : 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  const days = Math.floor(seconds / DAY_IN_SECONDS);
  const hours = Math.floor((seconds % DAY_IN_SECONDS) / HOUR_IN_SECONDS);

  return {
    days: daysInHours ? undefined : days,
    hours: daysInHours ? hours + days * 24 : hours,
    minutes: Math.floor((seconds % HOUR_IN_SECONDS) / MINUTE_IN_SECONDS),
    seconds: Math.floor(seconds % 60),
  };
};

export const getCountdownFormatted = ({
  daysInHours,
  days,
  hours,
  minutes,
  seconds,
}: FormattedCountdownTimeParameters): string => {
  const getFormattedPadOrEmpty = (value: number | undefined) => {
    return typeof value === 'undefined' ? '' : `${zeroPad(value)}:`;
  };

  const formattedDays = daysInHours ? '' : getFormattedPadOrEmpty(days);
  const totalHours = daysInHours && days !== undefined && hours !== undefined ? days * 24 + hours : hours;
  const formattedHours = getFormattedPadOrEmpty(totalHours);
  const formattedMinutes = getFormattedPadOrEmpty(minutes);

  return `${formattedDays}${formattedHours}${formattedMinutes}${zeroPad(seconds)}`;
};
