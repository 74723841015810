import loadable from 'components/dynamic-import';
import { FullLayout } from '../layout';

const ChatBot = loadable(() => import('./ChatBot'));

export const ChatBotPage = (): JSX.Element => {
  return (
    <FullLayout>
      <ChatBot />
    </FullLayout>
  );
};
