import { FunctionComponent, useContext, useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import mobileChecker from 'helpers/mobileChecker';
import { OpenContext } from 'contexts/open-provider';
import { layoutSelectors, layoutActions, ChangeLayoutStructure } from 'services/layout';
import { useLocation } from 'react-router-dom';

interface MapStateToProps {
  isMobile: boolean;
}

interface MapDispatchToProps {
  isMobile: boolean;
  changeLayoutStructure: ChangeLayoutStructure;
}

interface LayoutObserverProps extends MapStateToProps, MapDispatchToProps {}

const LayoutObserverBase = (props: LayoutObserverProps) => {
  const location = useLocation();
  const { changeLayoutStructure, isMobile } = props;
  const { close } = useContext(OpenContext);

  useEffect(() => {
    const listener = mobileChecker.addMobileListener(changeLayoutStructure);
    const mobileMode = mobileChecker.isMobile();
    if (isMobile !== mobileMode) {
      changeLayoutStructure(mobileMode);
    }

    return () => {
      mobileChecker.removeMobileListener(listener);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isMobile) {
      close();
    }
  }, [location, close, isMobile]);

  return null;
};

const mapStateToProps = state => ({
  isMobile: layoutSelectors.isMobile(state),
});

export const LayoutObserver = compose<FunctionComponent>(
  connect(mapStateToProps, {
    changeLayoutStructure: layoutActions.changeLayoutStructure,
  }),
)(LayoutObserverBase);
