import loadable from 'components/dynamic-import';
import { AuthenticationResponsePage } from './AuthenticationResponsePage';
import { SignUpPage } from './SignUpPage';
import { SignOutManager } from './SignOutManager';
import { useAuthTrackingEvents } from './useAuthTrackingEvents';
import { withAuthTrackingEvents } from './withAuthTrackingEvents';

const AuthSection = loadable(() => import('./ConnectedAuthSection'));
const AuthDialog = loadable(() => import('./AuthDialog'));

const SignOut = loadable(() => import('./SignOut'));

export { useAuthDialog } from './useAuthDialog';

export {
  AuthenticationResponsePage,
  AuthSection,
  AuthDialog,
  SignOut,
  SignUpPage,
  SignOutManager,
  useAuthTrackingEvents,
  withAuthTrackingEvents,
};
