import cookies from 'js-cookie';
import config from 'config';
import { DEFAULT_CULTURE, CULTURE_COOKIE_NAME } from '../i18n/constants';

export default {
  changeCultureCookie: (newCulture: string): void => {
    cookies.set(CULTURE_COOKIE_NAME, newCulture, { expires: 30, domain: config.streamloots.domain });
    window.location.reload();
  },
  getCulture: (): string => cookies.get(CULTURE_COOKIE_NAME) || DEFAULT_CULTURE,
  getCultureFromRequest: (reqCookies: Record<string, unknown>): string =>
    (reqCookies[CULTURE_COOKIE_NAME] as string) || DEFAULT_CULTURE,
};
