import type { UserFlags } from 'model/indexTS';
import experimentsManager from 'utils/experiments-manager';
import { USER_API_RESPONSE, UserSettings } from 'model/userTS';
import type { UserSuccessResponse } from './types';

const DEFAULT_USER_SETTINGS: UserSettings = {
  account: {
    ACCEPTED_PRIVACY_POLICY: false,
    ACCEPTED_STREAMER_TERMS_AND_CONDITIONS: false,
  },
  notifications: {
    SOLICITED_COMMERCIAL_COMMUNICATIONS: false,
  },
  preferences: {},
};

const mustAcceptTerms = (user: USER_API_RESPONSE) =>
  // Checking undefined, as false is used when new legal terms are issued
  user?.settings?.account?.ACCEPTED_PRIVACY_POLICY === undefined;

const showOnboarding = (userFlags: UserFlags) => userFlags && userFlags.showOnboarding;

// B is Streamer
// C is Elephant
// D is Ant

const userIsStreamer = ({ category }: USER_API_RESPONSE) => category === 'B' || category === 'C' || category === 'D';

const userResponse = (userInfo: USER_API_RESPONSE): UserSuccessResponse => {
  const { experiments, flags, settings = DEFAULT_USER_SETTINGS, ...data } = userInfo;

  const activeExperiments = experimentsManager.getActiveExperimentsFromUserExperiments(experiments);

  return {
    user: {
      ...data,
      isStreamer: userIsStreamer(userInfo),
      showSupportChat: Boolean(flags.showSupportChat),
      settings: { ...settings, preferences: settings.preferences || {} },
    },
    experiments: activeExperiments,
    mustAcceptTerms: mustAcceptTerms(userInfo),
    showOnboarding: Boolean(showOnboarding(flags)),
    flags,
  };
};

export default {
  userResponse,
};
