import * as React from 'react';
import trackEventManager from 'utils/event-manager';

export const useAuthTrackingEvents = () => {
  const openModalTrackingEvent = React.useCallback((componentKey: string) => {
    trackEventManager.trackEvent('loginBtnClick', {
      componentKey,
    });
  }, []);

  const closeModalTrackingEvent = React.useCallback((componentKey: string) => {
    trackEventManager.trackEvent('closeLoginBtnClick', {
      componentKey,
    });
  }, []);

  return {
    openModalTrackingEvent,
    closeModalTrackingEvent,
  };
};
