import { createContext, useEffect, useCallback, useState, useMemo, ReactNode } from 'react';

export interface OpenContextType {
  isOpen: boolean;
  toggle: () => void;
  close: () => void;
}

export interface OpenProviderProps {
  children: ReactNode;
  isOpen?: boolean;
}

const context: OpenContextType = {
  isOpen: false,
  toggle: () => undefined,
  close: () => undefined,
};

export const OpenContext = createContext<OpenContextType>(context);

export const OpenProvider = ({ children, isOpen = false }: OpenProviderProps): JSX.Element => {
  const [openState, setOpen] = useState(isOpen);
  const [component, setComponent] = useState(null);

  const toggle = useCallback(() => setOpen(state => !state), []);
  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const contextValue = useMemo(
    () => ({
      close,
      isOpen: openState,
      component,
      toggle,
      setComponent,
    }),
    [component, openState, close, toggle],
  );
  return <OpenContext.Provider value={contextValue}>{children}</OpenContext.Provider>;
};
