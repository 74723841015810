import _clean from 'lodash-clean';
import { achievementsSideEffects } from 'services/sideEffects';
import type { OrderType } from 'model/indexTS';
import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import { PAYMENT_PROCESSORS } from 'model/indexTS';
import type { StripePaymentOrderExecutedActionError, StripePaymentOrderExecutedActionSuccess } from './types';
import { errorMapping } from '../../errorMapping';
import { StripePaymentActionTypes } from './actionTypes';

const stripePaymentExecuted = ({ data }): StripePaymentOrderExecutedActionSuccess =>
  actionCreator.createAction(StripePaymentActionTypes.STRIPE_ORDER_EXECUTED, data.payment);

const stripePaymentNotExecuted = ({ errorData }): StripePaymentOrderExecutedActionError =>
  actionCreator.createErrorAction(StripePaymentActionTypes.STRIPE_ORDER_EXECUTED, errorData, errorMapping);

const executeStripePayment = (
  setLootId: string,
  stripeToken: string,
  quantity: number,
  type: OrderType,
  setId: string,
  gifteeUsername?: string,
  couponCode?: string,
) => {
  const parameters = _clean({
    processor: PAYMENT_PROCESSORS.STRIPE,
    stripeToken,
    type,
    gifteeUsername,
    couponCode,
    items: [
      {
        item: {
          setLootId,
          setId,
        },
        quantity,
      },
    ],
  });
  return actionCreator.createAsyncAction({
    endpoint: endpoints.PAYMENT_ORDER.CREATE,
    errorBinding: stripePaymentNotExecuted,
    loadingAction: StripePaymentActionTypes.EXECUTING_STRIPE_ORDER,
    parameters,
    method: 'post',
    successBinding: stripePaymentExecuted,
    successAsyncSideEffects: achievementsSideEffects,
  });
};

export const stripePaymentActions = {
  executeStripePayment,
};
