import { FunctionComponent, useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import type { PageModules, PageUsageStatistics } from 'model/indexTS';
import { pageSelectors } from 'services/page';
import { FetchPageStatistics, pageStatisticsActions } from 'services/page-statistics';
import { PanelSkeletons } from 'ui-library/panel';
import loadable from 'components/dynamic-import';
import { checkPermissions, permissions } from 'components/permissions';

const StreamerDashboard = loadable(() => import('./StreamerDashboard'), true, <PanelSkeletons gridMode count={4} />);
const Level1Dashboard = loadable(() => import('./InitialDashboard'), true, <PanelSkeletons gridMode count={4} />);

interface MapStateToProps {
  pageId: string;
  pageStatistics?: PageUsageStatistics;
  pageModules: PageModules | undefined;
}

interface MapDispatchToProps {
  fetchPageStatistics: FetchPageStatistics;
}

export interface ConnectedDashboardProps extends MapDispatchToProps, MapStateToProps {}

export const ConnectedDashboard = ({
  pageId,
  fetchPageStatistics,
  pageStatistics,
  pageModules,
}: ConnectedDashboardProps): JSX.Element | null => {
  // TODO do we need page statistics?
  useEffect(() => {
    fetchPageStatistics(pageId);
  }, [fetchPageStatistics, pageId]);

  if (!pageStatistics || pageModules === undefined) {
    return null;
  }

  const isCardModuleLevel2 =
    pageModules.cards.enabled && (pageStatistics.cardRedemptionCount >= 8 || pageStatistics.lootsSoldCount);

  return <>{isCardModuleLevel2 ? <StreamerDashboard /> : <Level1Dashboard />}</>;
};

const mapStateToProps = (state): MapStateToProps => {
  return {
    pageId: pageSelectors.pageId(state),
    pageStatistics: pageSelectors.usageStatistics(state),
    pageModules: pageSelectors.pageModules(state),
  };
};

export const Dashboard = compose<FunctionComponent>(
  checkPermissions({
    requiredPermissions: [
      permissions.SUPPORT,
      permissions.ACCOUNT_MANAGER,
      permissions.PARTNERSHIP_MANAGER,
      permissions.STREAMER_SUCCESS_MANAGER,
      permissions.ADMIN,
      permissions.PAGE_OWNER,
    ],
    redirectToBasePage: true,
  }),
  connect(mapStateToProps, {
    fetchPageStatistics: pageStatisticsActions.fetchPageStatistics,
  }),
)(ConnectedDashboard);
