import React from 'react';
import type { ExtendedSetRadioButtonCardsProps } from './types';
import { SetRadioCardItem } from './SetRadioCardItem';

export const PhoneSetRadio: React.FunctionComponent<ExtendedSetRadioButtonCardsProps> = ({
  items,
  activeId,
  onChange,
  t,
}) => (
  <React.Fragment>
    {items.map((item, index) => (
      <SetRadioCardItem
        key={index}
        id={`setCarrouselOption_${index}`}
        name="setCarrouselOption"
        checked={activeId === item._id}
        item={item}
        onChange={onChange}
        t={t}
        isPhone
      />
    ))}
  </React.Fragment>
);
