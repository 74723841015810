import * as React from 'react';
import { connect } from 'react-redux';
import ClassNames from '@streamloots/classnames';
import cultureCookieManage from 'utils/cultureCookieManager';
import { cultureSelectors as culturesSelectors, ACCEPTED_LANGUAGES } from 'services/culture';
import theme from './languageSwitcher.scss';

const classNames = ClassNames(theme);

type OwnProps = {
  className?: string;
};

type MapStateToProps = {
  language: string;
};

type LanguageSwitcherProps = OwnProps & MapStateToProps;

const LanguageSwitcher = (props: LanguageSwitcherProps): JSX.Element => {
  const { className = '', language } = props;

  return (
    <div className={classNames({ [className]: className, switcher: true })}>
      {ACCEPTED_LANGUAGES.map(lng => (
        <button
          key={lng.value}
          className={language === lng.value ? theme.active : ''}
          type="button"
          onClick={() => cultureCookieManage.changeCultureCookie(lng.value)}
        >
          {lng.label}
        </button>
      ))}
    </div>
  );
};

const mapStateToProps = state => ({
  language: culturesSelectors.language(state),
});

export default connect<MapStateToProps, null, OwnProps>(mapStateToProps)(LanguageSwitcher);
