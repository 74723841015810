import { LOCATION_CHANGE } from 'redux-first-history';
import type { OverloadedContentState } from './typesTS';
import { OverloadedContentActionTypes } from './actionTypes';

export const reducer = (state: OverloadedContentState = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOCATION_CHANGE: {
      const keepOverloadContentOpened = payload.location?.state?.keepOverloadedContentOpened;

      // TODO how to document special location states
      if (keepOverloadContentOpened) {
        return state;
      }

      return { ...state, isOpen: false };
    }
    case OverloadedContentActionTypes.OPENED: {
      return {
        ...payload,
        isOpen: true,
      };
    }
    case OverloadedContentActionTypes.CLOSED:
      return { ...state, isOpen: false };
    case OverloadedContentActionTypes.WRAPPER_PROPS_UPDATED:
      return { ...state, wrapperProps: payload };
    default:
      return state;
  }
};
