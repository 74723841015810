import { createSelector } from 'reselect';
import { MarathonStates } from 'model/indexTS';
import { MarathonSelectorOutput, MarathonTrackerSelectorOutput, MarathonState, MarathonStoreState } from './types';

const marathonState = (state: MarathonStoreState): MarathonState => state.marathon || {};

const getMarathon = createSelector<MarathonStoreState, MarathonState, MarathonSelectorOutput>(
  [marathonState],
  (state: MarathonState): MarathonSelectorOutput => {
    const { marathonConfig, isLoading } = state;

    if (isLoading || !marathonConfig) {
      return null;
    }

    return marathonConfig;
  },
);

const getMarathonTracker = createSelector<MarathonStoreState, MarathonState, MarathonTrackerSelectorOutput>(
  [marathonState],
  (state: MarathonState): MarathonTrackerSelectorOutput => {
    const { marathonTracker } = state;
    return marathonTracker || null;
  },
);

const isLoadingData = createSelector<MarathonStoreState, MarathonState, boolean>(
  [marathonState],
  (state: MarathonState): boolean => {
    const { isLoading } = state;

    if (isLoading) {
      return isLoading;
    }

    return false;
  },
);

const getReconcile = createSelector<MarathonStoreState, MarathonState, boolean>(
  [marathonState],
  (state: MarathonState): boolean => {
    const { reconcile } = state;

    if (reconcile) {
      return reconcile;
    }

    return false;
  },
);

const getMarathonId = createSelector<MarathonStoreState, MarathonState, string | null>(
  [marathonState],
  (state: MarathonState): string | null => {
    const { marathonConfig } = state;

    if (!marathonConfig || marathonConfig._id === '') {
      return null;
    }

    return marathonConfig._id;
  },
);

const getMarathonTrackerId = createSelector<MarathonStoreState, MarathonState, string | null>(
  [marathonState],
  (state: MarathonState): string | null => {
    const { marathonTracker } = state;

    if (marathonTracker == null || marathonTracker._id === '') {
      return null;
    }

    return marathonTracker._id;
  },
);

const getMarathonStatus = createSelector<MarathonStoreState, MarathonState, MarathonStates>(
  [marathonState],
  (state: MarathonState): MarathonStates => {
    const { marathonStatus } = state;

    if (!marathonStatus) {
      return MarathonStates.STOPPED;
    }

    return marathonStatus;
  },
);

const getHasPreviousConfig = createSelector<MarathonStoreState, MarathonState, boolean>(
  [marathonState],
  (state: MarathonState): boolean => {
    const { hasConfigSaved } = state;

    if (hasConfigSaved) {
      return hasConfigSaved;
    }

    return false;
  },
);

export const marathonSelectors = {
  marathonState,
  getMarathon,
  isLoadingData,
  getReconcile,
  getHasPreviousConfig,
  getMarathonId,
  getMarathonTracker,
  getMarathonStatus,
  getMarathonTrackerId,
};
