import * as React from 'react';
import { TranslateInterface } from 'utils/translation';
import Button from 'ui-library/button';
import { DialogOpener } from 'ui-library/dialog';
import { AuthDialog, useAuthTrackingEvents } from 'scenes/auth';

type LogInButtonProps = TranslateInterface;

const LogInButton = ({ t }: LogInButtonProps) => {
  const { openModalTrackingEvent, closeModalTrackingEvent } = useAuthTrackingEvents();
  const componentKey = 'topMenu';
  return (
    <DialogOpener
      fullOnSmall
      renderOpener={({ onShow }) => (
        <Button
          small
          primary
          onClick={() => {
            onShow();
            openModalTrackingEvent(componentKey);
          }}
        >
          {t('common:menu.signIn')}
        </Button>
      )}
      renderDialogBody={({ onClose }) => (
        <AuthDialog onClose={onClose} componentKey={componentKey} location="app top menu" />
      )}
      type="auth"
      onClose={() => closeModalTrackingEvent(componentKey)}
    />
  );
};

export default LogInButton;
