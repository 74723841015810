import { ValueOf } from 'types/indexTS';
import {
  Currency,
  LootOrderType,
  ORDERS_POST_REQUEST,
  ORDERS_POST_RESPONSE_SUCCESS,
} from '@streamloots/streamloots-types';

export const ORDER_TYPE = {
  REGULAR: 'REGULAR',
  GIFT: 'GIFT',
  FREE_GIFT: 'FREE_GIFT',
  COUPON: 'COUPON',
  RANDOM_COMMUNITY_GIFT: 'RANDOM_COMMUNITY_GIFT',
} as const;

export type OrderType = ValueOf<typeof ORDER_TYPE>;

export const OrderedItemType = {
  Donation: 'Donation',
  Tips: 'Tips',
  FeedContent: 'FeedContent',
} as const;

export type OrderedItemTypeValues = ValueOf<typeof OrderedItemType>;

export interface OrderedItem {
  type: OrderedItemTypeValues;
  quantity: number;
}
export interface OrderedTipItem extends OrderedItem {
  amount: number;
  message?: string;
  createMessageAsComment: boolean;
  feedId: string;
}

export interface OrderedFeedContentItem extends OrderedItem {
  amount: number;
  feedId: string;
}

export interface LootOrderItem {
  currency: Currency;
  imageUrl: string;
  price: number;
  quantity: number;
  setLootId: string;
  setId: string;
  cardAmount: number;
}

export interface OrderCustomerInfo {
  _id: string;
  email?: string;
  avatarImageUrl: string;
  username: string;
}

export interface Order {
  _id: string;
  completedAt: string;
  customer: OrderCustomerInfo;
  quantity: number;
  receiver: OrderCustomerInfo;
  totalPayment: string;
  type: LootOrderType;
  paidOrder: boolean;
  setId: string;
}

export interface ORDERS_GET_RESPONSE_ITEM {
  _id: string;
  billingAddress: {
    country: string;
  };
  amount: number;
  completed: boolean;
  completedAt: string;
  createdAt: string;
  currency: Currency;
  customer: {
    _id: string;
    email: string;
    avatarImageUrl: string;
    username: string;
  };
  customerId: string;
  disputed: boolean;
  gifted: boolean;
  giftee: { _id: string; avatarImageUrl: string; username: string };
  gifteeId: string;
  items: Array<{
    item: LootOrderItem;
    quantity: number;
  }>;
  modifiedAt: string;
  refunded: boolean;
  sellerId: string;
  type: LootOrderType;
}

export interface ORDERS_GET_RESPONSE {
  data: ORDERS_GET_RESPONSE_ITEM[];
  pagination: {
    cursors?: {
      after: string;
    };
    next?: string;
  };
}

// FIXME: Get from types lib
export type ORDERS_GET_RESPONSE_SUCCESS = ORDERS_POST_RESPONSE_SUCCESS;
export const OrderStatus = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  REFUNDED: 'REFUNDED',
  DISPUTED: 'DISPUTED',
  CANCELLED: 'CANCELLED',
  FAILED: 'FAILED',
} as const;

export type OrderStatusType = ValueOf<typeof OrderStatus>;

export type CreateOrder = ORDERS_POST_REQUEST;

// FIXME: Get from types lib
interface PAYPAL_PAYMENT_EXECUTION_POST_REQUEST {
  externalPayerId: string;
  paymentProviderExternalId: string;
}

export type ExecutePaypalPayment = PAYPAL_PAYMENT_EXECUTION_POST_REQUEST;
