/* eslint-disable no-redeclare */
import i18next from 'i18next';
import { captureReducerError } from 'utils/sentry';
import type {
  ActionSuccess,
  ActionError,
  ApiBusinessError,
  RequestCancelError,
  ActionMetadataError,
  ApiError,
} from 'types/indexTS';
import { isOfType } from 'types/indexTS';
import { ErroredEntity } from 'model/entityTS';
import createAsyncAction from './asyncActionsCreator';

export type ErrorData = Error | ApiError | RequestCancelError;
export type ErrorCodeMapping = { [key: string]: string };

const getError = (
  httpCode: number,
  error?: ApiBusinessError,
  errorMapping: ErrorCodeMapping = {},
): Pick<ApiBusinessError, 'code' | 'message'> & { metadata?: Record<string, any> } => {
  switch (httpCode) {
    case 404:
      return {
        code: 'NOT_FOUND',
        message: errorMapping.NOT_FOUND
          ? i18next.t(errorMapping.NOT_FOUND)
          : i18next.t('errors:asyncValidation.notFound'),
      };
    case 403:
      return {
        code: 'INSUFFICIENT_PERMISSIONS',
        message: errorMapping.INSUFFICIENT_PERMISSIONS
          ? i18next.t(errorMapping.INSUFFICIENT_PERMISSIONS)
          : i18next.t('errors:asyncValidation.insufficientPermissions'),
      };
    case 413:
      return {
        code: 'TOO_MANY_ITEMS',
        message: errorMapping.TOO_MANY_ITEMS
          ? i18next.t(errorMapping.TOO_MANY_ITEMS)
          : i18next.t('errors:asyncValidation.tooManyItems'),
      };
    case 503:
      return {
        code: 'SERVER_NOT_AVAILABLE',
        message: errorMapping.SERVER_NOT_AVAILABLE
          ? i18next.t(errorMapping.SERVER_NOT_AVAILABLE)
          : i18next.t('errors:asyncValidation.serverError'),
      };
    default:
      if (error) {
        return error;
      }

      return {
        code: 'UNKNOWN',
        message: errorMapping.UNKNOWN
          ? i18next.t(errorMapping.UNKNOWN)
          : i18next.t('errors:asyncValidation.unknownError'),
      };
  }
};

const getErrorsWithCorrectMessages = (
  errors?: ApiBusinessError[],
  errorCodeMapping: ErrorCodeMapping = {},
): ApiBusinessError[] => {
  let errorLocalizationCode;
  if (!errors || errors.length === 0) {
    return [];
  }

  return errors.map(error => {
    const mappedError = { ...error };
    if (error.code) {
      errorLocalizationCode = errorCodeMapping[error.code];
      mappedError.message = errorLocalizationCode ? i18next.t(errorLocalizationCode) : error.message;
    }
    return mappedError;
  });
};

const getNormalizedError = (
  httpCode: number,
  errors?: Array<ApiBusinessError>,
  errorMapping?: ErrorCodeMapping,
): ApiBusinessError => {
  const normalizedErrors = getErrorsWithCorrectMessages(errors, errorMapping);
  const firstError = normalizedErrors.length > 0 ? normalizedErrors[0] : undefined;
  return { ...getError(httpCode, firstError, errorMapping), metadata: firstError?.metadata || {} };
};

const getSpecialError = <Type, Metadata extends Record<string, unknown>>(
  type: Type,
  metadata: Metadata,
  businessCode: string,
  error,
): ActionError<Type, Metadata & ActionMetadataError> => {
  if (__DEV__) {
    console.error(error);
  }

  return {
    error: true,
    metadata: {
      ...(metadata || {}),
      businessCode,
      code: 6012018,
      errors: [],
    },
    payload: new Error(error.message || ''),
    type,
  };
};

function createAction<Type>(
  type: Type,
  payload?: never,
  metadata?: never,
): ActionSuccess<Type, Record<string, never>, Record<string, never>>;
function createAction<Type, Payload>(
  type: Type,
  payload: Payload,
  metadata?: never,
): ActionSuccess<Type, Payload, Record<string, never>>;
function createAction<Type, Payload, Metadata>(
  type: Type,
  payload: Payload,
  metadata: Metadata,
): ActionSuccess<Type, Payload, Metadata>;
function createAction(type, payload = {}, metadata = {}) {
  return {
    error: false,
    metadata,
    payload,
    type,
  };
}

interface BulkActionSuccessPayload {
  errors?: ErroredEntity[];
}

function createBulkActionSuccess<
  Type,
  Payload extends BulkActionSuccessPayload,
  Metadata extends Record<string, unknown>
>(
  type: Type,
  payload: Payload,
  errorMapping: ErrorCodeMapping = {},
  metadata: Metadata = {} as Metadata,
): ActionSuccess<Type, Payload, Metadata> {
  if (!payload || !Array.isArray(payload.errors)) {
    return createAction(type, payload, metadata);
  }
  const { errors } = payload;

  const normalizedErrors = errors.map(errorItem => {
    if (!errorItem.error) {
      return { ...errorItem };
    }

    return {
      ...errorItem,
      error: getNormalizedError(400, [errorItem.error], errorMapping),
    };
  });

  return {
    error: false,
    metadata,
    payload: { ...payload, errors: normalizedErrors },
    type,
  };
}

function createErrorAction<Type, Metadata extends Record<string, unknown>>(
  type: Type,
  errorData: ErrorData,
  errorMapping?: ErrorCodeMapping,
  metadata: Metadata = {} as Metadata,
): ActionError<Type, Metadata & ActionMetadataError> {
  if (errorData instanceof Error) {
    if (errorData.message.indexOf('Network Error') > -1) {
      return getSpecialError(type, metadata, 'NETWORK_ERROR', errorData);
    }

    captureReducerError(errorData, { type }, metadata);

    return getSpecialError(type, metadata, 'SOMETHING_HAS_EXPLODED', errorData);
  }

  if (isOfType<RequestCancelError>(errorData, 'isCancelError')) {
    return getSpecialError(type, metadata, 'REQUEST_CANCELED', errorData);
  }

  const normalizedError = getNormalizedError(errorData.code, errorData.errors, errorMapping);

  return {
    error: true,
    metadata: {
      ...metadata,
      errorMetadata: normalizedError.metadata,
      code: errorData.code,
      businessCode: normalizedError.code,
    },
    payload: new Error(normalizedError.message),
    type,
  };
}

const actionCreator = {
  createAction,
  createErrorAction,
  createBulkActionSuccess,
  createAsyncAction,
};

export default actionCreator;
