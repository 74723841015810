import jwtDecode from 'jwt-decode';
import { Token } from 'model/token';
import { TestScopeFunction, JWTPayloadWithScopes } from './types';
import { GetTokensResponse } from './services/tokens/types';

const tokensScoped = (data: GetTokensResponse, scopeTestFn: TestScopeFunction): Token | undefined => {
  const tokenWithScopes = data.find(({ token }) => {
    const tokenDecoded = jwtDecode<JWTPayloadWithScopes>(token);
    return tokenDecoded.scope && scopeTestFn(tokenDecoded.scope);
  });
  return tokenWithScopes;
};

export default {
  tokensScoped,
};
