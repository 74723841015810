import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserSet } from 'model/setsTS';
import { FetchUserSets } from './types';
import { userSetsSelectors } from './selectors';
import { userSetsActions } from './actions';

export interface UseUserSetsSelectors {
  selectedUserSetId: string;
  marathonSpecialSet: UserSet | null;
  selectedUserSet: UserSet | null;
  userSets?: UserSet[];
}

export interface UseUserSetsActions {
  fetchUserSets: FetchUserSets;
}

export const useUseSetsActions = (): UseUserSetsActions => {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      fetchUserSets: slug => dispatch(userSetsActions.fetchUserSets(slug)),
    }),
    [dispatch],
  );
};

export const useUserSetsSelectors = (): UseUserSetsSelectors => {
  return {
    userSets: useSelector(userSetsSelectors.userSets),
    selectedUserSetId: useSelector(userSetsSelectors.setId),
    selectedUserSet: useSelector(userSetsSelectors.selectedUserSet),
    marathonSpecialSet: useSelector(userSetsSelectors.marathonSpecialSet),
  };
};
