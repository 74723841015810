import { ReactNode } from 'react';
import RcTooltip from 'rc-tooltip';
import './rc-tooltip.scss';

export enum TooltipPlacement {
  TOP = 'top',
  BOTTOM = 'bottom',
  RIGHT = 'right',
}

export type TooltipPlacementWithStrings = 'top' | 'bottom' | 'bottomLeft' | 'bottomRight' | TooltipPlacement;

export interface TooltipProps {
  children: ReactNode;
  tooltip: string | ReactNode;
  className?: string;
  prefixCls?: string;
  placement?: TooltipPlacementWithStrings;
  trigger?: string[];
}

export const Tooltip = ({
  children,
  tooltip,
  placement = TooltipPlacement.BOTTOM,
  className = '',
  prefixCls = 'rc-tooltip',
  trigger = ['hover', 'click'],
}: TooltipProps): JSX.Element => {
  return (
    <RcTooltip
      placement={placement}
      overlay={<span>{tooltip}</span>}
      className={className}
      trigger={trigger}
      prefixCls={prefixCls}
    >
      {children}
    </RcTooltip>
  );
};
