import { PageActionTypes } from 'services/page';
import { paginationStateNormalizer } from 'services/pagination';
import { PAGINATION_STATE_NORMALIZER_CONFIGURATION } from './constants';
import { InvoicesActionTypes } from './actionTypes';
import type { InvoicesState } from './types';

const initialState: InvoicesState = paginationStateNormalizer.getInitial(PAGINATION_STATE_NORMALIZER_CONFIGURATION);

export const reducer = (state = initialState, action): InvoicesState => {
  const { type, payload, error } = action;
  switch (type) {
    case InvoicesActionTypes.RECEIVED: {
      if (error) {
        return paginationStateNormalizer.getReceivedError(action, state);
      }

      return paginationStateNormalizer.getReceived(payload, PAGINATION_STATE_NORMALIZER_CONFIGURATION);
    }
    case InvoicesActionTypes.REQUEST: {
      return paginationStateNormalizer.getRequest(state, payload, PAGINATION_STATE_NORMALIZER_CONFIGURATION);
    }
    case InvoicesActionTypes.NEXT_REQUEST:
      return paginationStateNormalizer.getFetchingNextItems(state);
    case InvoicesActionTypes.NEXT_RECEIVED: {
      if (error) {
        return paginationStateNormalizer.getNextReceivedError(state);
      }
      return paginationStateNormalizer.getNextItemsReceived(state, payload);
    }
    case PageActionTypes.CHANGED:
      return initialState;
    default:
      return state;
  }
};
