/* eslint-disable global-require */
import React from 'react';
import { hydrate } from 'react-dom';
import { RouteConfig } from 'react-router-config';
import { loadableReady } from '@loadable/component';
import { HelmetProvider } from 'react-helmet-async';
import { createBrowserHistory } from 'history';
import utmManager from 'utils/utm-manager';
import { initSentry } from 'utils/sentry';
import trackEvent from 'utils/event-manager/trackEventManager';
import { setDefaultOptions } from 'date-fns';
import { discountCouponCookieManager } from 'services/coupons';
import { getSlugFromUrl } from 'services/routing';
import { AppClient } from 'scenes/app';
import configureStore from 'store/configureStore';
import { FixMe } from 'types/indexTS';
import i18n from 'i18n/i18n-client';
import gclidManager from './utils/gclid-manager';
import routes from './routes';

declare const window: any;
const loadSpanishLocale = async () => {
  const es = await import(`date-fns/locale/es`);
  setDefaultOptions({ locale: es.es });
};

if (window.__i18n.locale.toLowerCase() === 'es') {
  loadSpanishLocale();
}

initSentry();
trackEvent.init();
utmManager.sendComeFromCampaignEvent();
gclidManager.sendComeFromGLinkEvent();
trackEvent.pageView(null, { isInitial: true, slug: getSlugFromUrl(window.location.pathname) });

// Get the initial state from server-side rendering
const initialState = window.__INITIAL_STATE__;
const history = createBrowserHistory();
const { store, history: hystorySync } = configureStore(history, initialState);

history.listen(location => {
  trackEvent.pageView(location as FixMe, { slug: getSlugFromUrl(location?.pathname || '') });
  discountCouponCookieManager.setCouponCookieFromLocation(location);
});

const helmetContext = {};

i18n.changeLanguage(window.__i18n.locale);
i18n.addResourceBundle(window.__i18n.locale, 'common', window.__i18n.resources, true);

const render = (Routes: Array<RouteConfig>) => {
  hydrate(
    <HelmetProvider context={helmetContext}>
      <AppClient store={store} routes={Routes} history={hystorySync} />
    </HelmetProvider>,
    document.getElementById('react-view'),
  );
};

loadableReady(async () => {
  render(routes);
});

if (module.hot) {
  // Enable webpack hot module replacement for routes
  module.hot.accept('./routes', () => {
    try {
      /* eslint-disable-next-line global-require,@typescript-eslint/no-var-requires */
      const nextRoutes = require('./routes').default;

      render(nextRoutes);
    } catch (error) {
      console.error(`==> 😭  Routes hot reloading error ${error}`);
    }
  });
}

// Expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}
