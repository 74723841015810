export enum FilesActionTypes {
  UPLOAD_FILE_REQUEST = 'files/UPLOAD_FILE_REQUEST',
  UPLOAD_FILE_SUCCESS = 'files/UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_ERROR = 'files/UPLOAD_FILE_ERROR',
  UPDATE_FILE_REQUEST = 'files/UPDATE_FILE_REQUEST',
  UPDATE_FILE_SUCCESS = 'files/UPDATE_FILE_SUCCESS',
  UPDATE_FILE_ERROR = 'files/UPDATE_FILE_ERROR',
  FETCH_FILES_REQUEST = 'files/FETCH_FILES_REQUEST',
  FETCH_FILES_SUCCESS = 'files/FETCH_FILES_SUCCESS',
  FETCH_FILES_ERROR = 'files/FETCH_FILES_ERROR',
  FETCH_NEXT_SUCCESS = 'viewers/FETCH_NEXT_SUCCESS',
  FETCH_NEXT_ERROR = 'viewers/FETCH_NEXT_ERROR',
  FETCH_NEXT_REQUEST = 'viewers/FETCH_NEXT_REQUEST',
  DELETE_FILE_REQUEST = 'files/DELETE_FILE_REQUEST',
  DELETE_FILE_SUCCESS = 'files/DELETE_FILE_SUCCESS',
  DELETE_FILE_ERROR = 'files/DELETE_FILE_ERROR',
}
