import { isActionError } from 'types/indexTS';
import trackEventManager from 'utils/event-manager';
import { useUserSelectors, useUserActions } from 'services/user';
import { SocialMediaFormValues } from './types';
import { SocialMediaForm } from './SocialMediaForm';

export const UpdateSocialMedia = (): JSX.Element | null => {
  const { user } = useUserSelectors();
  const { updateUserSocialMedia } = useUserActions();
  const handleSubmit = async (formData: SocialMediaFormValues) => {
    const response = await updateUserSocialMedia(formData);

    if (!isActionError(response)) {
      trackEventManager.trackEvent('social_media_configured', {
        has_included_twitter: Boolean(formData.twitterAcount),
        has_included_discord: Boolean(formData.discordServer),
      });
    }
    return response;
  };

  if (!user) {
    return null;
  }

  const { twitterAcount, discordServer } = user;

  return (
    <SocialMediaForm
      onSubmit={handleSubmit}
      initialValues={{
        twitterAcount,
        discordServer,
      }}
    />
  );
};
