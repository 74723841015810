import type { LootOrderType } from '@streamloots/streamloots-types';
import type { User, UserSetLoot } from 'model/indexTS';
import { USER_STREAMER_CATEGORIES, OnboardingWizard } from 'model/indexTS';
import urlUtils from 'utils/urlUtils';
import { RouteLocation } from 'types/indexTS';
import {
  BuyPacksCompletedProperties,
  BuyPacksStartedProperties,
  CardCraftingEventsProperties,
  CardInformationOpenedEventProperties,
  FilterAppliedEventProperties,
  RankViewedEventProperties,
  HalloweenCampaignModalProperties,
  SubscriptionPurchaseStepProperties,
  SubscriptionPurchasedClickProperties,
  RankDivisionClickedProperties,
  PublicPageRankingViewedProperties,
  PublicPageRankingClickedProperties,
  CancelSubscriptionStepProperties,
  SuggestionClickedProperties,
  SuggestionViewedProperties,
  DashboardPartnerSectionClickedProperties,
  FloatingImageAlertViewedEventProperties,
  FloatingImageAlertClickedEventProperties,
  CampaignViewedProperties,
  MarathonStatusChangedProperties,
  DashboardButtonClickedProperties,
  OnboardingModuleSelectedProperties,
  PageModuleClickedProperties,
  SendMessageClickedProperties,
  PublicPageExternalLinkClickedProperties,
  WidgetUrlClickedProperties,
  SubscriptionsAddReactionClickedProperties,
  RankRewardClickedProperties,
  MyCommunityViewerClickedProperties,
  SignInClickedEventProperties,
  SignInProviderClickedEventProperties,
  OnboardingSectionViewedProperties,
  OnboardingClickedProperties,
  OriginalsClickedProperties,
  FeedClickedProperties,
  CreatorClickedProperties,
  FeedSectionViewedProperties,
} from 'utils/event-manager/types';

import { AuthProviders } from '@streamloots/streamloots-types';
import { userflowTracker } from './userflowTracker';
import eventsHelper from './eventsHelpers';
import gtagEventSender from './gtagEventSender';
import amplitudeEventSender from './amplitudeEventSender';
import mixpanelEventSender from './mixpanelEventSender';
import twitterEventSender from './twitterEventSender';
import { MyCommunityViewerClickedSchema, OmitSnowplowProperties, SnowplowSchemas, SnowplowTracker } from './snowplot';

export const actions = {
  PAGE_VIEW: 'pageView',
  REGISTERED: 'completeRegistration',
  LOOT_OPENED: 'lootOpened',
  REDEEMED: 'cardRedeemed',
  CARD_CREATED: 'cardCreated',
  GIFTED: 'chestGifted',
  ADD_TO_CART: 'lootAddedToCart',
  PAYMENT_INITIALIZED: 'lootPaymentInitialized',
  PAYMENT_ERROR: 'lootPaymentError',
  PAYMENT_CANCELED: 'lootPaymentCanceled',
  PAYMENT_EXECUTED: 'lootPurchased',
  SET_PUBLISHED: 'setPublished',
  SET_UNPUBLISHED: 'setUnpublished',
  DASHBOARD_NEWS_CLICKED: 'dashboardNews',
  DISCOVERING_FROM_COLLECTIONS: 'discoveringFromCollections',
  HAS_SOLD: 'streamerHasSoldChests',
  HAS_BEEN_REDEEMED: 'streamerHasBeenRedeemed',
  CAN_APPLY_FOR_AFFILIATE: 'canApplyForAffiliate',
  CAN_APPLY_FOR_PARTNER: 'canApplyForPartner',
  PARTNER_APPLICATION_STARTED: 'partnerApplicationStarted',
  ONBOARDING_CLICK: 'onboardingClick',
  ONBOARDING_STEP_COMPLETED: 'onboardingStepCompleted',
  ONBOARDING_STARTED: 'onboardingStarted',
  ONBOARDING_COMPLETED: 'onboardingCompleted',
  TRACK_CLICK: 'buttonClick',
  COME_FROM_CAMPAIGN: 'comeFromCampaign',
  COME_FROM_CAMPAIGN_CATEGORIZED: 'comeFromCampaignCategorized',
  COME_FROM_GLINK: 'comeFromGLink',
  CREATE_COLLECTION_CLICK: 'createCollectionClick',
  USER_IS_STREAMER: 'userIsStreamer',
  ONBOARDING_VISITED: 'onboardingVisited',
  STREAMER_VISITED_FROM_MENU: 'streamerClickedFromMenu ',
};

const snowplowTracker = new SnowplowTracker();

interface PageViewConfiguration {
  isInitial?: boolean;
  slug?: string | null;
}

export default {
  init(): void {
    amplitudeEventSender.init();
    mixpanelEventSender.init();
  },
  signOut() {
    mixpanelEventSender.signOutUser();
  },
  reset(): void {
    // TODO this is needed to avoid errors on test utils
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  snapshot(_isAmplitude = true): Record<string, any> {
    // TODO this is needed to avoid errors on test utils
    return {};
  },
  cardsCreated(numCardsCreated: number, isPredefined: boolean, fromBulkCreation?: boolean): void {
    try {
      if (!eventsHelper.isOwner()) {
        return;
      }

      const page = eventsHelper.getCurrentPage();

      const cardCreatedInfo = {
        page,
        numCardsCreated,
        isPredefined,
        fromBulkCreation,
      };

      mixpanelEventSender.sendEvent(actions.CARD_CREATED, cardCreatedInfo);
      userflowTracker.trackEvent(actions.CARD_CREATED, cardCreatedInfo);

      twitterEventSender.sendEvent(actions.CARD_CREATED);
    } catch (e) {
      console.error(e);
    }
  },
  cardRedeemed(): void {
    try {
      // Note, card redeemed event is sent from the back-end
      twitterEventSender.sendEvent(actions.REDEEMED);
    } catch (e) {
      console.error(e);
    }
  },
  cardInformationOpened(eventProperties: CardInformationOpenedEventProperties): void {
    try {
      const { card, setId, source } = eventProperties;
      const { _id, paid: paidOnlyCard, dropLimited: limitedCard, fragmented: fragmentedCard, rarity, count } = card;

      const page = eventsHelper.getCurrentPage();
      const isOwner = eventsHelper.isOwner();
      const cardCraftingInfo = {
        cardId: _id,
        collectionSetId: setId,
        page,
        isOwner,
        rarity,
        paidOnlyCard,
        limitedCard,
        fragmentedCard,
        source,
      };

      amplitudeEventSender.sendEvent('publicCardOpened', {
        ...cardCraftingInfo,
        count,
      });
      mixpanelEventSender.sendEvent('publicCardOpened', {
        ...cardCraftingInfo,
        count,
      });
      userflowTracker.trackEvent('publicCardOpened', cardCraftingInfo);
    } catch (e) {
      console.error(e);
    }
  },
  cardCraftingEvent(eventProperties: CardCraftingEventsProperties): void {
    try {
      const { card, source, count, mode, setId } = eventProperties;
      const { _id, paid: paidOnlyCard, dropLimited: limitedCard, fragmented: fragmentedCard, rarity } = card;

      const isOwner = eventsHelper.isOwner();
      const page = eventsHelper.getCurrentPage();

      const cardCraftingInfo = {
        cardId: _id,
        collectionSetId: setId,
        page,
        rarity,
        count,
        paidOnlyCard,
        limitedCard,
        fragmentedCard,
        source,
        isOwner,
      };

      // NOTE: Card destroyed event is from back-end and real name is cardDisenchanted

      const eventName = mode === 'crafting' ? 'cardCraftedConfirmed' : 'cardDestroyedConfirmed';

      amplitudeEventSender.sendEvent(eventName, cardCraftingInfo);
      mixpanelEventSender.sendEvent(eventName, cardCraftingInfo);

      const eventNameFacebookTwitter = mode === 'crafting' ? 'cardCrafted' : 'cardDisenchanted';
      twitterEventSender.sendEvent(eventNameFacebookTwitter);
    } catch (e) {
      console.error(e);
    }
  },
  chestGifted(numChests: number, numCardsPerPack: number): void {
    try {
      const page = eventsHelper.getCurrentPage();
      const chestGifted = {
        page,
        numChests,
        numCardsPerPack,
      };

      amplitudeEventSender.sendEvent(actions.GIFTED, chestGifted);
      mixpanelEventSender.sendEvent(actions.GIFTED, chestGifted);
      userflowTracker.trackEvent(actions.GIFTED, chestGifted);

      twitterEventSender.sendEvent(actions.GIFTED);
    } catch (e) {
      console.error(e);
    }
  },
  initLootPurchase(loot: UserSetLoot): void {
    try {
      if (eventsHelper.isOwner()) {
        return;
      }

      const page = eventsHelper.getCurrentPage();
      const { currency, quantity, price, mostPopular } = loot;
      const lootInfo = {
        page,
        quantity,
        currency,
        price,
        mostPopular,
      };

      amplitudeEventSender.sendEvent(actions.PAYMENT_INITIALIZED, lootInfo);
      mixpanelEventSender.sendEvent(actions.PAYMENT_INITIALIZED, lootInfo);

      twitterEventSender.sendEvent(actions.PAYMENT_INITIALIZED);
    } catch (e) {
      console.error(e);
    }
  },
  lootOpened(gifted: boolean, cardsPerPack: number): void {
    try {
      if (eventsHelper.isOwner()) {
        return;
      }

      const page = eventsHelper.getCurrentPage();
      const eventData = {
        page,
        gifted,
        cardsPerPack,
      };

      amplitudeEventSender.sendEvent(actions.LOOT_OPENED, eventData);
      mixpanelEventSender.sendEvent(actions.LOOT_OPENED, eventData);

      twitterEventSender.sendEvent(actions.LOOT_OPENED);
    } catch (e) {
      console.error(e);
    }
  },
  lootPurchaseCancel(loot: UserSetLoot, type: LootOrderType): void {
    try {
      if (eventsHelper.isOwner()) {
        return;
      }

      const page = eventsHelper.getCurrentPage();
      const { currency, quantity, price } = loot;
      const eventData = {
        page,
        quantity,
        currency,
        price,
        type,
      };

      amplitudeEventSender.sendEvent(actions.PAYMENT_CANCELED, eventData);
      mixpanelEventSender.sendEvent(actions.PAYMENT_CANCELED, eventData);

      twitterEventSender.sendEvent(actions.PAYMENT_CANCELED);
    } catch (e) {
      console.error(e);
    }
  },
  lootPurchaseError(loot: UserSetLoot, method: string, type: LootOrderType): void {
    try {
      if (eventsHelper.isOwner()) {
        return;
      }

      const page = eventsHelper.getCurrentPage();
      const { currency, quantity, price } = loot;
      const eventData = {
        page,
        quantity,
        currency,
        price,
        method,
        type,
      };

      amplitudeEventSender.sendEvent(actions.PAYMENT_ERROR, eventData);
      mixpanelEventSender.sendEvent(actions.PAYMENT_ERROR, eventData);
    } catch (e) {
      console.error(e);
    }
  },
  lootPurchased(loot: UserSetLoot, packQuantity: number): void {
    try {
      const { quantity, price } = loot;
      const totalPrice = price * packQuantity;

      twitterEventSender.sendEvent(actions.PAYMENT_EXECUTED, totalPrice, quantity);
    } catch (e) {
      console.error(e);
    }
  },
  pageView(
    location?: RouteLocation | null | undefined,
    configuration: PageViewConfiguration = { slug: undefined },
  ): void {
    try {
      const { slug, isInitial } = configuration;
      const pageName = location ? location.pathname : window.location.pathname;

      const search = location ? location.search : window.location.search;

      const queryParams = urlUtils.getSearchParams(search);

      if (location && location.hash) {
        return;
      }

      if (isInitial) {
        mixpanelEventSender.sendEvent('page_view', {
          pageName,
          pageReferrer: document.referrer,
          queryParams: queryParams || undefined,
          page: slug,
        });
      } else {
        mixpanelEventSender.sendEvent('page_view', {
          pageName,
          queryParams: queryParams || undefined,
          page: slug,
        });
      }

      twitterEventSender.sendEvent(actions.PAGE_VIEW);
    } catch (e) {
      console.error(e);
    }
  },
  setUnpublished(): void {
    try {
      const page = eventsHelper.getCurrentPage();
      const eventData = {
        page,
      };

      amplitudeEventSender.sendEvent(actions.SET_UNPUBLISHED, eventData);
      mixpanelEventSender.sendEvent(actions.SET_UNPUBLISHED, eventData);
      userflowTracker.trackEvent(actions.SET_UNPUBLISHED);

      twitterEventSender.sendEvent(actions.SET_UNPUBLISHED);
    } catch (e) {
      console.error(e);
    }
  },
  canApplyForAffiliate(): void {
    try {
      if (!eventsHelper.isOwner()) {
        return;
      }

      amplitudeEventSender.sendEvent(actions.CAN_APPLY_FOR_AFFILIATE);
      mixpanelEventSender.sendEvent(actions.CAN_APPLY_FOR_AFFILIATE);
      userflowTracker.trackEvent(actions.CAN_APPLY_FOR_AFFILIATE);
    } catch (e) {
      console.error(e);
    }
  },
  canApplyForPartner(): void {
    try {
      if (!eventsHelper.isOwner()) {
        return;
      }

      amplitudeEventSender.sendEvent(actions.CAN_APPLY_FOR_PARTNER);
      mixpanelEventSender.sendEvent(actions.CAN_APPLY_FOR_PARTNER);
      userflowTracker.trackEvent(actions.CAN_APPLY_FOR_PARTNER);
    } catch (e) {
      console.error(e);
    }
  },
  partnerApplicationStarted(): void {
    try {
      amplitudeEventSender.sendEvent(actions.PARTNER_APPLICATION_STARTED);
      mixpanelEventSender.sendEvent(actions.PARTNER_APPLICATION_STARTED);
      userflowTracker.trackEvent(actions.PARTNER_APPLICATION_STARTED);
    } catch (e) {
      console.error(e);
    }
  },
  setPublished(): void {
    try {
      userflowTracker.trackEvent(actions.SET_PUBLISHED);

      twitterEventSender.sendEvent(actions.SET_PUBLISHED);
    } catch (e) {
      console.error(e);
    }
  },
  userHasSold(quantity: number): void {
    try {
      if (!eventsHelper.isOwner()) {
        return;
      }

      userflowTracker.trackEvent(actions.HAS_SOLD, { quantity });

      twitterEventSender.sendEvent(actions.HAS_SOLD);
    } catch (e) {
      console.error(e);
    }
  },

  userIsStreamer(category: keyof typeof USER_STREAMER_CATEGORIES): void {
    try {
      if (!category || category === 'A' || category === 'E') {
        return;
      }

      twitterEventSender.sendEvent(actions.USER_IS_STREAMER);
    } catch (e) {
      console.error(e);
    }
  },
  streamerHasBeenRedeemed(count: number): void {
    try {
      if (!eventsHelper.isOwner()) {
        return;
      }

      userflowTracker.trackEvent(actions.HAS_BEEN_REDEEMED, { count });

      twitterEventSender.sendEvent(actions.HAS_BEEN_REDEEMED);
    } catch (e) {
      console.error(e);
    }
  },
  userRegistered(): void {
    try {
      gtagEventSender.sendEvent();
      twitterEventSender.sendEvent(actions.REGISTERED);
    } catch (e) {
      console.error(e);
    }
  },
  userSignIn(user: User): void {
    try {
      amplitudeEventSender.signInUser(user);
      mixpanelEventSender.signInUser(user);
    } catch (e) {
      console.error(e);
    }
  },
  updateUserProperties(newProperties: Record<string, any>, configuration: { onlyOwner?: boolean } = {}): void {
    try {
      const { onlyOwner } = configuration;

      if (onlyOwner && !eventsHelper.isOwner()) {
        return;
      }

      amplitudeEventSender.updateUserInfo(newProperties);
      mixpanelEventSender.updateUserInfo(newProperties);
    } catch (e) {
      console.error(e);
    }
  },
  discoveringFromCollections(
    provider: AuthProviders | 'none',
    fetchedStreamersCount: number,
    providerConnected: boolean,
  ): void {
    try {
      amplitudeEventSender.sendEvent(actions.DISCOVERING_FROM_COLLECTIONS, {
        provider,
        fetchedStreamersCount,
        providerConnected,
      });
      mixpanelEventSender.sendEvent(actions.DISCOVERING_FROM_COLLECTIONS, {
        provider,
        fetchedStreamersCount,
        providerConnected,
      });
    } catch (e) {
      console.error(e);
    }
  },
  onboardingFinished(onboardingWizard: OnboardingWizard): void {
    try {
      amplitudeEventSender.sendEvent(actions.ONBOARDING_COMPLETED, onboardingWizard);
      mixpanelEventSender.sendEvent(actions.ONBOARDING_COMPLETED, onboardingWizard);

      twitterEventSender.sendEvent(actions.ONBOARDING_COMPLETED);
    } catch (e) {
      console.error(e);
    }
  },
  onboardingStepCompleted(stepId: string, additionalData: Record<string, any> = {}): void {
    try {
      const eventInfo = {
        stepId,
        ...additionalData,
      };

      amplitudeEventSender.sendEvent(actions.ONBOARDING_STEP_COMPLETED, eventInfo);
      mixpanelEventSender.sendEvent(actions.ONBOARDING_STEP_COMPLETED, eventInfo);

      twitterEventSender.sendEvent(actions.ONBOARDING_STEP_COMPLETED);

      if (!additionalData || !additionalData.categories) {
        return;
      }
    } catch (e) {
      console.error(e);
    }
  },
  onboardingClick(buttonId: string, additionalData?: Record<string, any>): void {
    try {
      const eventData = {
        buttonId,
        ...additionalData,
      };

      amplitudeEventSender.sendEvent(actions.ONBOARDING_CLICK, eventData);
      mixpanelEventSender.sendEvent(actions.ONBOARDING_CLICK, eventData);
    } catch (e) {
      console.error(e);
    }
  },
  onButtonClick(buttonId: string, additionalData: Record<string, any> = {}): void {
    try {
      const eventData = {
        buttonId,
        ...additionalData,
      };

      amplitudeEventSender.sendEvent(actions.TRACK_CLICK, eventData);
      mixpanelEventSender.sendEvent(actions.TRACK_CLICK, eventData);
      userflowTracker.trackEvent(actions.TRACK_CLICK, eventData);
    } catch (e) {
      console.error(e);
    }
  },
  comeFromCampaign(eventInfo: Record<string, any>): void {
    try {
      amplitudeEventSender.sendEvent(actions.COME_FROM_CAMPAIGN, eventInfo);
      mixpanelEventSender.sendEvent(actions.COME_FROM_CAMPAIGN, eventInfo);
      userflowTracker.trackEvent(actions.COME_FROM_CAMPAIGN, eventInfo);

      twitterEventSender.sendEvent(actions.COME_FROM_CAMPAIGN);
    } catch (e) {
      console.error(e);
    }
  },
  comeFromCampaignCategorized(eventInfo: Record<string, any>): void {
    try {
      amplitudeEventSender.sendEvent(actions.COME_FROM_CAMPAIGN_CATEGORIZED, eventInfo);
      mixpanelEventSender.sendEvent(actions.COME_FROM_CAMPAIGN_CATEGORIZED, eventInfo);
      userflowTracker.trackEvent(actions.COME_FROM_CAMPAIGN_CATEGORIZED, eventInfo);
    } catch (e) {
      console.error(e);
    }
  },
  comeFromGLink(eventInfo: Record<string, any>): void {
    try {
      amplitudeEventSender.sendEvent(actions.COME_FROM_GLINK, eventInfo);
      mixpanelEventSender.sendEvent(actions.COME_FROM_GLINK, eventInfo);
    } catch (e) {
      console.error(e);
    }
  },
  onCreateCollectionClick(buttonId: string, times?: number): void {
    try {
      const eventData = {
        buttonId,
        times,
      };

      amplitudeEventSender.sendEvent(actions.CREATE_COLLECTION_CLICK, eventData);
      mixpanelEventSender.sendEvent(actions.CREATE_COLLECTION_CLICK, eventData);
    } catch (e) {
      console.error(e);
    }
  },
  onOnboardingVisited(): void {
    try {
      amplitudeEventSender.sendEvent(actions.ONBOARDING_VISITED);
      mixpanelEventSender.sendEvent(actions.ONBOARDING_VISITED);
    } catch (e) {
      console.error(e);
    }
  },
  onStreamerClickedFromMenu(streamerSlug: string, streamerPageId: string, provider: AuthProviders | 'none'): void {
    try {
      const eventData = {
        page: streamerSlug,
        pageId: streamerPageId,
        productId: streamerSlug,
        provider,
      };

      amplitudeEventSender.sendEvent(actions.STREAMER_VISITED_FROM_MENU, eventData);
      mixpanelEventSender.sendEvent(actions.STREAMER_VISITED_FROM_MENU, eventData);
      userflowTracker.trackEvent(actions.STREAMER_VISITED_FROM_MENU, eventData);
    } catch (e) {
      console.error(e);
    }
  },
  trackEvent(event: string, data: Record<string, any> = {}, includePage?: boolean): void {
    try {
      const eventData = {
        ...data,
      };

      if (includePage) {
        eventData.page = eventsHelper.getCurrentPage();
        eventData.isPageOwner = eventsHelper.isOwner();
      }

      amplitudeEventSender.sendEvent(event, eventData);
      mixpanelEventSender.sendEvent(event, eventData);
      userflowTracker.trackEvent(event, data);
    } catch (e) {
      console.error(e);
    }
  },
  fitlerApplied(data: FilterAppliedEventProperties, includePage = true): void {
    try {
      this.trackEvent(
        'dashboardStatsFiltered',
        {
          filterBy: data.filter,
        },
        includePage,
      );

      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.FilterApplied, data, includePage);
    } catch (e) {
      console.error(e);
    }
  },
  floatingImageAlertViewed(data: FloatingImageAlertViewedEventProperties): void {
    try {
      this.trackEvent('floatingAlertVisible', data, true);
    } catch (e) {
      console.error(e);
    }
  },
  floatingImageAlertClicked(data: FloatingImageAlertClickedEventProperties): void {
    try {
      this.trackEvent('floatingAlertClick', data, true);
    } catch (e) {
      console.error(e);
    }
  },
  rankViewed(data: RankViewedEventProperties, includePage = true): void {
    try {
      this.trackEvent('rank_viewed', data, includePage);

      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.RankViewed, data, includePage);
    } catch (e) {
      console.error(e);
    }
  },
  buyPacksStarted(data: BuyPacksStartedProperties, includePage = true): void {
    try {
      this.trackEvent(
        'buyPacksButtonClick',
        {
          buttonId: data.location,
        },
        includePage,
      );

      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.BuyPacksStarted, data, includePage);
    } catch (e) {
      console.error(e);
    }
  },
  buyPacksCompleted(data: BuyPacksCompletedProperties, includePage = true): void {
    try {
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.BuyPacksCompleted, data, includePage);
    } catch (e) {
      console.error(e);
    }
  },
  halloweenCampaignModalViewed(campaignData: HalloweenCampaignModalProperties): void {
    try {
      this.trackEvent('halloween_campaign_modal_viewed', campaignData, true);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.HalloweenCampaignModalSchema, campaignData, true);
    } catch (e) {
      console.error(e);
    }
  },
  subscriptionPurchaseStep(data: SubscriptionPurchaseStepProperties, includedPage = true): void {
    try {
      this.trackEvent('purchase_subscription_step', data, includedPage);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.SubscriptionPurchaseStep, data, includedPage);
    } catch (e) {
      console.error(e);
    }
  },
  subscriptionPurchasedClick(data: SubscriptionPurchasedClickProperties, includedPage = true): void {
    try {
      this.trackEvent('purchase_subscription_clicked', data, includedPage);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.SubscriptionPurchasedClick, data, includedPage);
    } catch (e) {
      console.error(e);
    }
  },
  rankDivisionClicked(data: RankDivisionClickedProperties, includedPage = true): void {
    try {
      this.trackEvent('rank_division_clicked', data, includedPage);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.RankDivisionClicked, data, includedPage);
    } catch (e) {
      console.error(e);
    }
  },
  publicPageRankingClicked(data: PublicPageRankingClickedProperties, includedPage = true): void {
    try {
      this.trackEvent('public_page_rank_clicked', data, includedPage);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.PublicPageRankingClicked, data, includedPage);
    } catch (e) {
      console.error(e);
    }
  },
  suggestionViewed(data: SuggestionViewedProperties, includedPage = true): void {
    try {
      this.trackEvent('suggestion_viewed', data, includedPage);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.SuggestionViewed, data, includedPage);
    } catch (e) {
      console.error(e);
    }
  },
  publicPageRankingViewed(data: PublicPageRankingViewedProperties, includedPage = true): void {
    try {
      this.trackEvent('public_page_rank_viewed', data, includedPage);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.PublicPageRankingViewed, data, includedPage);
    } catch (e) {
      console.error(e);
    }
  },
  suggestionClicked(data: SuggestionClickedProperties, includedPage = true): void {
    try {
      this.trackEvent('suggestion_clicked', data, includedPage);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.SuggestionClicked, data, includedPage);
    } catch (e) {
      console.error(e);
    }
  },
  dashboardPartnerSectionClicked(data: DashboardPartnerSectionClickedProperties, includedPage = true): void {
    try {
      this.trackEvent('dashboard_partner_section_clicked', data, includedPage);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.DashboardPartnerSectionClicked, data, includedPage);
    } catch (e) {
      console.error(e);
    }
  },
  cancelSubscription(includedPage = true): void {
    try {
      this.trackEvent('cancel_subscription_clicked', {}, includedPage);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.CancelSubscription, {}, includedPage);
    } catch (e) {
      console.error(e);
    }
  },
  cancelSubscriptionStep(data: CancelSubscriptionStepProperties, includedPage = true): void {
    try {
      this.trackEvent('cancel_subscription_step', data, includedPage);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.CancelSubscriptionStep, data, includedPage);
    } catch (e) {
      console.error(e);
    }
  },
  campaignViewed(data: CampaignViewedProperties, includedPage = true): void {
    try {
      this.trackEvent('campaign_viewed', data, includedPage);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.CampaignViewed, data, includedPage);
    } catch (e) {
      console.error(e);
    }
  },
  marathonStatusChanged(data: MarathonStatusChangedProperties, includedPage = true): void {
    try {
      this.trackEvent('marathon_status_changed', data, includedPage);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.MarathonStatusChanged, data, includedPage);
    } catch (e) {
      console.error(e);
    }
  },
  widgetUrlClicked(data: WidgetUrlClickedProperties): void {
    try {
      this.trackEvent('widget_url_clicked', data, true);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.WidgetUrlClicked, data, true);
    } catch (e) {
      console.error(e);
    }
  },
  dashboardButtonClicked(data: DashboardButtonClickedProperties, includedPage = true): void {
    try {
      this.trackEvent('dashboard_button_clicked', data, includedPage);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.DashboardButtonClicked, data, includedPage);
    } catch (e) {
      console.error(e);
    }
  },
  publicPageExternalLinkClicked(data: PublicPageExternalLinkClickedProperties): void {
    try {
      this.trackEvent('public_page_external_link_clicked', data, true);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.PublicPageExternalLinkClicked, data, true);
    } catch (e) {
      console.error(e);
    }
  },
  onboardingModuleSelected(data: OnboardingModuleSelectedProperties, includedPage = true): void {
    try {
      this.trackEvent('onboarding_module_selected', data, includedPage);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.OnboardingModuleSelected, data, includedPage);
    } catch (e) {
      console.error(e);
    }
  },
  pageModuleClicked(data: PageModuleClickedProperties, includedPage = true): void {
    try {
      this.trackEvent('page_module_clicked', data, includedPage);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.PageModuleClicked, data, includedPage);
    } catch (e) {
      console.error(e);
    }
  },
  sendMessageClicked(data: SendMessageClickedProperties, includedPage = true): void {
    try {
      this.trackEvent('send_message_clicked', data, includedPage);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.SendMessageClicked, data, includedPage);
    } catch (e) {
      console.error(e);
    }
  },
  subscriptionsGoalsClicked(includedPage = true): void {
    try {
      this.trackEvent('subscriptions_goals_clicked', {}, includedPage);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.SubscriptionsGoalsClicked, {}, includedPage);
    } catch (e) {
      console.error(e);
    }
  },
  subscriptionsAddAlertClicked(data: SubscriptionsAddReactionClickedProperties, includedPage = true): void {
    try {
      this.trackEvent('subscriptions_add_alert_clicked', data, includedPage);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.SubscriptionsAddAlertClicked, data, includedPage);
    } catch (e) {
      console.error(e);
    }
  },
  rankRewardClicked(data: RankRewardClickedProperties, includePage = true): void {
    try {
      this.trackEvent('rank_reward_clicked', data, includePage);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.RankRewardClicked, data, includePage);
    } catch (e) {
      console.error(e);
    }
  },
  myCommunityViewerClicked({ username, ...rest }: MyCommunityViewerClickedProperties, includePage = true): void {
    try {
      this.trackEvent('my_community_viewer_clicked', { username, ...rest }, includePage);
      snowplowTracker.trackSelfDescribingEvent(
        SnowplowSchemas.MyCommunityViewerClicked,
        rest as OmitSnowplowProperties<MyCommunityViewerClickedSchema>,
        includePage,
      );
    } catch (e) {
      console.error(e);
    }
  },
  onboardingSectionViewed(data: OnboardingSectionViewedProperties): void {
    try {
      this.trackEvent('onboarding_section_viewed', data, false);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.OnboardingSectionViewed, data, false);
    } catch (e) {
      console.error(e);
    }
  },
  onboardingClickedNew(data: OnboardingClickedProperties): void {
    try {
      this.trackEvent('onboarding_clicked', data, false);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.OnboardingClicked, data, false);
    } catch (e) {
      console.error(e);
    }
  },
  originalsClicked(data: OriginalsClickedProperties, includePage = true): void {
    try {
      this.trackEvent('originals_clicked', data, includePage);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.OriginalsClicked, data, includePage);
    } catch (e) {
      console.error(e);
    }
  },
  signInClicked(eventProperties: SignInClickedEventProperties): void {
    try {
      this.trackEvent('sign_in_clicked', eventProperties);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.SignInClicked, eventProperties, false);
    } catch (e) {
      console.error(e);
    }
  },
  signInProviderClicked(eventProperties: SignInProviderClickedEventProperties): void {
    try {
      this.trackEvent('sign_in_provider_clicked', eventProperties);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.SignInProviderClicked, eventProperties, false);
    } catch (e) {
      console.error(e);
    }
  },
  feedClicked(eventProperties: FeedClickedProperties, includedPage = true): void {
    try {
      this.trackEvent('feed_clicked', eventProperties, includedPage);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.FeedClicked, eventProperties, includedPage);
    } catch (e) {
      console.error(e);
    }
  },
  feedSectionViewed(eventProperties: FeedSectionViewedProperties, includedPage = true): void {
    try {
      this.trackEvent('feed_section_viewed', eventProperties, includedPage);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.FeedSectionViewed, eventProperties, includedPage);
    } catch (e) {
      console.error(e);
    }
  },
  creatorClicked(eventProperties: CreatorClickedProperties): void {
    try {
      this.trackEvent('creator_clicked', eventProperties);
      snowplowTracker.trackSelfDescribingEvent(SnowplowSchemas.CreatorClicked, eventProperties, false);
    } catch (e) {
      console.error(e);
    }
  },
  couponsClicked(eventProps: { button: 'share on X' | 'send alert' | 'configure alert' | 'save alert configuration' }) {
    try {
      this.trackEvent('coupons_clicked', eventProps);
    } catch (e) {
      console.error(e);
    }
  },
};
