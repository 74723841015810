import ClassNames from '@streamloots/classnames';
import { useOverloadedContentActions } from 'services/overloaded-content';
import { TranslateInterface, withTranslation } from 'utils/translation';
import Button from 'ui-library/button';
import loadable from 'components/dynamic-import';
import theme from './delete-account.scss';

const classNames = ClassNames(theme);

const DeleteAccount = loadable(() => import('./DeleteAccount'));

const DeleteAccountButtonInternal = ({ t }: TranslateInterface) => {
  const { openOverloadedContent } = useOverloadedContentActions();

  const handleClick = () => {
    openOverloadedContent({
      component: DeleteAccount,
      componentProps: {
        t,
      },
    });
  };

  return (
    <Button
      onClick={handleClick}
      label={t('userProfile:deleteAccount.delete')}
      dangerous
      className={classNames('delete-account')}
    />
  );
};

export const DeleteAccountButton = withTranslation('userProfile')(DeleteAccountButtonInternal);
