import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { Trans, withTranslation } from 'utils/translation';
import AvatarImage from 'components/avatar-image';
import theme from './support-streamer.scss';

const classNames = ClassNames(theme);

export type SupportStreamerProps = {
  avatarImageUrl?: string;
  slug: string;
};

const SupportStreamer = ({ avatarImageUrl, slug }: SupportStreamerProps) => {
  return (
    <div className={classNames('support-streamer__container')}>
      <div className={classNames('support-streamer__avatar')}>
        <AvatarImage src={avatarImageUrl} />
      </div>
      <div className={classNames('support-streamer__message')}>
        <Trans i18nKey="collection:supportStreamer">
          You are buying packs to support and interact with
          <strong>{slug}</strong>
        </Trans>
      </div>
    </div>
  );
};

export default withTranslation('collection')(SupportStreamer) as React.FunctionComponent<SupportStreamerProps>;
