import ClassNames from '@streamloots/classnames';
import { TooltipPlacement } from 'ui-library/tooltip';
import { AchievementBadgeInfo } from 'model/indexTS';
import { AchievementBadge } from './AchievementBadge';
import theme from './achievement-badge.scss';

const classNames = ClassNames(theme);

interface AchievementBadgesProps {
  achievementBadges: AchievementBadgeInfo[];
  tooltipPosition?: TooltipPlacement;
  size?: 'sm' | 'md';
}

export const AchievementBadges = ({ achievementBadges, ...rest }: AchievementBadgesProps): JSX.Element | null => {
  if (!achievementBadges.length) {
    return null;
  }

  return (
    <div className={classNames('badges')}>
      {achievementBadges.map(badgeInfo => (
        <AchievementBadge key={badgeInfo.id} achievementBadge={badgeInfo} {...rest} />
      ))}
    </div>
  );
};
