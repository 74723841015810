import { createSelector } from 'reselect';
import type { AuthState, AuthStoreState } from './types';

const authState = (state: AuthStoreState): AuthState => state.auth;

const authToken = createSelector(
  [authState],
  (authStateInfo: AuthState): string | undefined => authStateInfo.authToken,
);

const scopeToken = createSelector(
  [authState, (_authStateInfo: AuthState, scope: string) => scope],
  (authStateInfo: AuthState, scope: string): string | undefined => {
    return authStateInfo[scope];
  },
);

const scopeTokenError = createSelector([authState], (authStateInfo: AuthState) => {
  return authStateInfo.error;
});

const isUserAuthenticated = createSelector(
  [authToken],
  (authTokenInfo: string | undefined): boolean => !!authTokenInfo,
);

const invalidateAuthToken = createSelector(
  [authState],
  (authStateInfo: AuthState): boolean => !!authStateInfo.invalidateAuthToken,
);

export const authSelectors = {
  authState,
  invalidateAuthToken,
  isUserAuthenticated,
  authToken,
  scopeToken,
  scopeTokenError,
};
