import { Component, ComponentType } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import type { User, PageMetadata, PageModules } from 'model/indexTS';
import type { FixMe } from 'types/indexTS';
import type { ChangePageContext, FetchPage, PageChanged, PageState } from 'services/page/types';
import { Languages } from '@streamloots/streamloots-types';
import trackEventManager from 'utils/event-manager';
import { userflowTracker } from 'utils/event-manager/userflowTracker';
import { pages } from 'services/routing';
import { pageActions, pageSelectors } from 'services/page';
import { layoutSelectors } from 'services/layout';
import { userSelectors } from 'services/user';
import { cultureSelectors } from 'services/culture';
import { dataConciliatorSelectors } from 'services/data-conciliator';
import { permissionsSelectors } from 'services/permissions';
import { FetchPageStatistics, pageStatisticsActions, pageStatisticsSelectors } from 'services/page-statistics';
import { BottomBanner, BottomBannerType } from 'components/banners';
import { OGP_STREAMER_DEFAULT_IMAGE, OGP_STREAMER_DEFAULT_IMAGE_ES } from 'constants/images';
import { RouteConfig, RouteConfigComponentProps } from 'react-router-config';
import { UserBannedFromPageInfo } from './UserBannedFromPageInfo';
import { FatalError } from '../errors';
import { StreamerNotFoundPage } from './StreamerNotFoundPage';
import { UserPage } from './UserPage';

export interface UserPageParams {
  params: {
    slug: string;
  };
}

type OwnProps = RouteConfigComponentProps<{
  slug: string;
}>;

interface MapDispatchToProps {
  changePageContext: ChangePageContext;
  pageChanged: PageChanged;
  fetchPage: FetchPage;
  fetchPageStatistics: FetchPageStatistics;
}

interface MapStateToProps {
  headerImageUrl?: string;
  page: PageState;
  metadata?: PageMetadata;
  username: string;
  showOnboarding: boolean;
  reconcilePage: boolean;
  isMobile: boolean;
  showSupportChat: boolean;
  level: number;
  user: User | null;
  language: Languages;
  isStaff: boolean;
  isPowerSeller: boolean;
  userExperiments: FixMe;
  pageModules: PageModules | undefined;
}

interface ConnectedUserPageProps extends OwnProps, MapStateToProps, MapDispatchToProps {}

const sendStreamerHasBeenRedeemed = (page: PageState) => {
  const { isOwner, usageStatistics } = page;

  if (!isOwner || !usageStatistics || !usageStatistics.cardRedemptionCount) {
    return;
  }

  trackEventManager.streamerHasBeenRedeemed(usageStatistics.cardRedemptionCount);
};

const getDefaultShareImage = (pageLanguage: string | undefined): string => {
  // if spanish language or Catalan => Image in Spanish
  if (pageLanguage === 'es' || pageLanguage === 'ca') {
    return OGP_STREAMER_DEFAULT_IMAGE_ES;
  }
  return OGP_STREAMER_DEFAULT_IMAGE;
};

class ConnectedPage extends Component<ConnectedUserPageProps> {
  componentDidMount() {
    const { changePageContext, page, reconcilePage, fetchPageStatistics } = this.props;

    changePageContext(true);
    sendStreamerHasBeenRedeemed(page);

    if ((!this.paramsMatchSlug() && !page.isLoading) || reconcilePage) {
      this.fetchPageIfNeeded();
    }

    if (page._id) {
      fetchPageStatistics(page._id);
    }

    this.initializeUserflow();
  }

  componentDidUpdate(prevProps) {
    const { fetchPage, fetchPageStatistics, page, reconcilePage, isPowerSeller } = this.props;

    if (!this.paramsMatchSlug() && !page.isLoading) {
      this.fetchPageIfNeeded();
    } else if (reconcilePage && !prevProps.reconcilePage) {
      fetchPage(page.slug);
    }

    if (prevProps.page._id !== page._id && page._id) {
      fetchPageStatistics(page._id);
    }

    this.initializeUserflow();
  }

  componentWillUnmount() {
    const { changePageContext } = this.props;
    changePageContext(false);
  }

  initializeUserflow(): void {
    const {
      history,
      user,
      language,
      isMobile,
      page: { isOwner, publishedAt, usageStatistics, flags },
      userExperiments,
      pageModules,
    } = this.props;

    if (!isOwner || !publishedAt || !usageStatistics || !user || !pageModules) {
      return;
    }

    const experiments = {};
    userExperiments
      .filter(experiment => experiment.segment === 'A')
      .forEach(experiment => {
        experiments[experiment.name] = experiment.segment;
      });

    userflowTracker.initUserflow({
      history,
      user,
      language,
      isMobile,
      publishedAt,
      usageStatistics,
      flags,
      experiments: Object.keys(experiments).join(','),
      pageModules,
    });
  }

  fetchPageIfNeeded() {
    const { pageChanged, fetchPage, page, match, reconcilePage } = this.props;
    const newSlug = this.getSlug(match);

    if (reconcilePage || newSlug !== page.slug) {
      fetchPage(newSlug);

      if (page.slug) {
        pageChanged();
      }
    }
  }

  getSlug = (match: UserPageParams) => match.params.slug;

  paramsMatchSlug = () => {
    const { page, match } = this.props;
    return this.getSlug(match) === page.slug;
  };

  render() {
    const { showOnboarding, page, route, metadata, username, showSupportChat, level, headerImageUrl } = this.props;

    const { isOwner, slug, isLoaded, error, customization } = page;

    if (isOwner && showOnboarding) {
      return <Redirect to={pages.CREATE_PAGE} />;
    }

    if (error && this.paramsMatchSlug()) {
      if (error.code === 404) {
        return <StreamerNotFoundPage username={username} slug={slug} />;
      }
      return <FatalError />;
    }

    if (page.ban) {
      return <UserBannedFromPageInfo slug={slug} banInfo={page.ban} />;
    }

    return (
      <>
        <BottomBanner type={BottomBannerType.CREATE_COLLECTION} />
        <UserPage
          ogpImage={headerImageUrl || getDefaultShareImage(customization?.language)}
          slug={slug}
          route={route as RouteConfig}
          isLoaded={Boolean(isLoaded && this.paramsMatchSlug())}
          isOwner={isOwner}
          showSupportChat={showSupportChat}
          error={error}
          metadata={metadata}
          level={level}
        />
      </>
    );
  }
}

const mapStateToProps = (state): MapStateToProps => ({
  page: pageSelectors.page(state),
  metadata: pageSelectors.metadataInfo(state),
  showSupportChat: userSelectors.showSupportChat(state),
  reconcilePage: dataConciliatorSelectors.reconcilePage(state),
  showOnboarding: userSelectors.showOnboarding(state),
  username: userSelectors.username(state),
  isMobile: layoutSelectors.isMobile(state),
  level: pageSelectors.level(state),
  user: userSelectors.user(state),
  headerImageUrl: pageSelectors.headerImageUrl(state),
  language: cultureSelectors.language(state),
  isStaff: permissionsSelectors.isStaff(state),
  isPowerSeller: pageStatisticsSelectors.isPowerSeller(state),
  userExperiments: userSelectors.userExperiments(state),
  pageModules: pageSelectors.pageModules(state),
});

export const ConnectedUserPage = compose<ComponentType<OwnProps>>(
  connect<MapStateToProps, MapDispatchToProps, OwnProps>(mapStateToProps, {
    changePageContext: pageActions.changePageContext,
    pageChanged: pageActions.pageChanged,
    fetchPage: pageActions.fetchPage,
    fetchPageStatistics: pageStatisticsActions.fetchPageStatistics,
  }),
)(ConnectedPage);
