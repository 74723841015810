import * as React from 'react';
import Spinner from './Spinner';
import theme from './loading-spinner.scss';

const SpinnerPanel = (): JSX.Element => (
  <div className={theme.spinner__panel}>
    <Spinner overlay={false} />
  </div>
);

export default SpinnerPanel;
