export default {
  BID_IS_LOW: 'auction:errors:bidIsLow',
  BID_IS_HIGH: 'auction:errors:bidIsHigh',
  ONLY_BUYERS_CAN_BID: 'auction:errors:onlyBuyersCanBid',
  BID_ON_EXPIRED_AUCTION: 'auction:errors:auctionHasEnded',
  BID_ON_INACTIVE_AUCTION: 'auction:errors:auctionIsInactive',
  BID_ON_NON_STARTED_AUCTION: 'auction:errors:auctionNotStarted',
  CANT_PAY_MORE_THAN_ONCE: 'auction:errors:alreadyPaid',
  CREATE_A_HOLD_FIRST: 'auction:errors:createHoldFirst',
  NOT_FOUND: 'auction:errors:auctionNotFound',
};
