import type { ReactNode } from 'react';
import ClassNames from '@streamloots/classnames';
import { FixedCloseButton } from 'ui-library/fixed-close-button';
import theme from './dialog.scss';

const classNames = ClassNames(theme);

type CleanDialogContentProps = {
  className?: string;
  fullHeight?: boolean;
  children: ReactNode;
  onClose?: () => void;
  preventClose?: boolean;
};

export const CleanDialogContent = ({
  className = '',
  children,
  onClose,
  fullHeight,
  preventClose,
}: CleanDialogContentProps): JSX.Element => {
  return (
    <div
      className={classNames({
        'empty-dialog': true,
        'empty-dialog--full-h': fullHeight,
        [className]: Boolean(className),
      })}
    >
      {onClose && !preventClose && <FixedCloseButton onClose={onClose} ariaLabel="Close" />}
      {children}
    </div>
  );
};
