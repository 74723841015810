import { PageActions, PageActionTypes } from 'services/page';
import { paginationStateNormalizer } from 'services/pagination';
import stateCreator from 'helpers/stateCreator';
import type { PageFeedCommentActions, PageFeedCommenStateByKey, PageFeedCommentState } from './types';
import { PageFeedCommentsActionTypes } from './actionTypes';
import { PAGINATION_STATE_NORMALIZER_CONFIGURATION } from './constants';

type AcceptedActions = PageFeedCommentActions | PageActions;

const getPageFeedCommentsStateByComponentKey = (
  state: PageFeedCommenStateByKey,
  action: AcceptedActions,
): PageFeedCommenStateByKey => {
  switch (action.type) {
    // ----- Actions: PageFeedCommentFetch -----
    case PageFeedCommentsActionTypes.FETCH_PAGE_FEED_COMMENT_SUCCESS: {
      return paginationStateNormalizer.getReceived(
        action.payload,
        PAGINATION_STATE_NORMALIZER_CONFIGURATION,
        state.data || [],
      );
    }
    case PageFeedCommentsActionTypes.FETCH_PAGE_FEED_COMMENT_ERROR: {
      return paginationStateNormalizer.getReceivedError(action, state);
    }
    case PageFeedCommentsActionTypes.FETCH_PAGE_FEED_COMMENT_REQUEST: {
      return paginationStateNormalizer.getRequest(state, action.metadata, PAGINATION_STATE_NORMALIZER_CONFIGURATION);
    }
    // ----- Actions: PageFeedCommentNextFetch ----
    case PageFeedCommentsActionTypes.FETCH_NEXT_PAGE_FEED_COMMENT_REQUEST:
      return paginationStateNormalizer.getFetchingNextItems(state, action.metadata.cancelToken);
    case PageFeedCommentsActionTypes.FETCH_NEXT_PAGE_FEED_COMMENT_SUCCESS: {
      return paginationStateNormalizer.getNextItemsReceived(state, action.payload);
    }
    case PageFeedCommentsActionTypes.FETCH_NEXT_PAGE_FEED_COMMENT_ERROR: {
      return paginationStateNormalizer.getNextReceivedError(state);
    }
    // ----- Actions: PageFeedCommentCreate ----
    case PageFeedCommentsActionTypes.CREATE_PAGE_FEED_COMMENT_REQUEST: {
      return { ...state, isCreating: true };
    }
    case PageFeedCommentsActionTypes.CREATE_PAGE_FEED_COMMENT_ERROR: {
      return { ...state, isCreating: false };
    }

    case PageFeedCommentsActionTypes.CREATE_PAGE_FEED_COMMENT_SUCCESS: {
      return {
        ...state,
        data: [action.payload, ...(state.data || [])],
        isCreating: false,
      };
    }

    // ----- Actions: PageFeedCommentDelete ----
    case PageFeedCommentsActionTypes.DELETE_PAGE_FEED_COMMENT_REQUEST: {
      return { ...state, isDeleting: true };
    }
    case PageFeedCommentsActionTypes.DELETE_PAGE_FEED_COMMENT_ERROR: {
      return { ...state, isDeleting: false };
    }

    case PageFeedCommentsActionTypes.DELETE_PAGE_FEED_COMMENT_SUCCESS: {
      const newData = state.data?.filter(item => item._id !== action.metadata.feedCommentId);
      return {
        ...state,
        data: newData,
        isDeleting: false,
      };
    }

    // ----- Actions:PageFeedCommentLike -----
    case PageFeedCommentsActionTypes.LIKE_PAGE_FEED_COMMENT_REQUEST: {
      const { feedCommentId, liked } = action.payload;
      const newData = state.data?.map(item =>
        item._id !== feedCommentId
          ? item
          : {
              ...item,
              summary: {
                ...item.summary,
                liked,
                reactionCount: liked ? item.summary.reactionCount + 1 : item.summary.reactionCount - 1,
              },
            },
      );
      return { ...state, data: newData };
    }

    case PageFeedCommentsActionTypes.LIKE_PAGE_FEED_COMMENT_ERROR: {
      const { feedCommentId, liked } = action.metadata;
      const newData = state.data?.map(item =>
        item._id !== feedCommentId
          ? item
          : {
              ...item,
              summary: {
                ...item.summary,
                liked: !liked,
                reactionCount: liked ? item.summary.reactionCount - 1 : item.summary.reactionCount + 1,
              },
            },
      );
      return { ...state, data: newData };
    }

    default:
      return state;
  }
};

export const reducer = (state = {}, action: AcceptedActions): PageFeedCommentState => {
  switch (action.type) {
    case PageFeedCommentsActionTypes.FETCH_NEXT_PAGE_FEED_COMMENT_REQUEST:
    case PageFeedCommentsActionTypes.FETCH_NEXT_PAGE_FEED_COMMENT_SUCCESS:
    case PageFeedCommentsActionTypes.FETCH_NEXT_PAGE_FEED_COMMENT_ERROR:
    case PageFeedCommentsActionTypes.FETCH_PAGE_FEED_COMMENT_REQUEST:
    case PageFeedCommentsActionTypes.FETCH_PAGE_FEED_COMMENT_SUCCESS:
    case PageFeedCommentsActionTypes.FETCH_PAGE_FEED_COMMENT_ERROR:
    case PageFeedCommentsActionTypes.CREATE_PAGE_FEED_COMMENT_REQUEST:
    case PageFeedCommentsActionTypes.CREATE_PAGE_FEED_COMMENT_SUCCESS:
    case PageFeedCommentsActionTypes.CREATE_PAGE_FEED_COMMENT_ERROR:
    case PageFeedCommentsActionTypes.DELETE_PAGE_FEED_COMMENT_REQUEST:
    case PageFeedCommentsActionTypes.DELETE_PAGE_FEED_COMMENT_SUCCESS:
    case PageFeedCommentsActionTypes.DELETE_PAGE_FEED_COMMENT_ERROR:
    case PageFeedCommentsActionTypes.LIKE_PAGE_FEED_COMMENT_REQUEST:
    case PageFeedCommentsActionTypes.LIKE_PAGE_FEED_COMMENT_SUCCESS:
    case PageFeedCommentsActionTypes.LIKE_PAGE_FEED_COMMENT_ERROR: {
      const { feedId } = action.metadata;
      return stateCreator.getUpdatedStateByKey(state, action, feedId, getPageFeedCommentsStateByComponentKey);
    }
    case PageActionTypes.CHANGED:
      return {};
    default:
      return state;
  }
};
