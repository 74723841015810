import { ReactNode } from 'react';
import ClassNames from '@streamloots/classnames';
import { ValueOf } from 'types/indexTS';
import { ALERT_TYPES } from './constants';
import AlertIcon from './AlertIcon';
import theme from './alert.scss';

const classNames = ClassNames(theme);

type AlertProps = {
  children: ReactNode;
  small?: boolean;
  className?: string;
  iconClassName?: string;
  type?: ValueOf<typeof ALERT_TYPES>;
  icon?: ReactNode;
};

/**
 * These alerts should be used when we want to give the user a message in the content itself,
 * as in forms error message, correct or incorrect number of cards picked, etc...
 */
const Alert = ({ children, icon, small, className = '', iconClassName = '', type = ALERT_TYPES.INFO }: AlertProps) => (
  <div
    className={classNames({
      'alert': true,
      'alert--info': type === ALERT_TYPES.INFO,
      'alert--success': type === ALERT_TYPES.SUCCESS,
      'alert--error': type === ALERT_TYPES.ERROR,
      'alert--warning': type === ALERT_TYPES.WARNING,
      'alert--cta': type === ALERT_TYPES.CTA,
      'alert--small': small,
      [className]: className,
    })}
    role="alert"
  >
    <div
      className={classNames({
        alert__icon: true,
        [iconClassName]: Boolean(iconClassName),
      })}
    >
      {icon ?? <AlertIcon type={type} />}
    </div>
    <div className={classNames('alert__content')}>{children}</div>
  </div>
);

Alert.ALERT_TYPES = ALERT_TYPES;

export default Alert;
