import { ReactNode } from 'react';
import ClassNames from '@streamloots/classnames';
import { Footer } from 'components/footer';
import { TopBar } from './TopBar';
import theme from './simplified-layout.scss';

const classNames = ClassNames(theme);

type SimplifiedLayoutProps = {
  children: ReactNode;
};

export const SimplifiedLayout = ({ children }: SimplifiedLayoutProps): JSX.Element => {
  return (
    <div className={classNames('sticky-footer')}>
      <TopBar />
      {children}
      <Footer />
    </div>
  );
};
