import { createPaymentOrderActions } from './services/create-payment-order';
import { executePaymentOrderActions } from './services/execute-payment-order';
import { fetchPaymentOrderActions } from './services/fetch-payment-oder';
import { stripePaymentActions } from './services/stripe-payment';
import { savedStripedMethodsListActions } from './services/saved-stripe-payment-methods-list';
import { deleteStripePaymentMethodActions } from './services/delete-stripe-payment-method';

export const paymentActions = {
  ...createPaymentOrderActions,
  ...executePaymentOrderActions,
  ...fetchPaymentOrderActions,
  ...stripePaymentActions,
  ...savedStripedMethodsListActions,
  ...deleteStripePaymentMethodActions,
};
