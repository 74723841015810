import { ReactNode, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ClassNames from '@streamloots/classnames';
import { PublicPageSearchResult } from 'model/indexTS';
import { useTranslation } from 'utils/translation';
import trackEventManager from 'utils/event-manager';
import { getUrlWithParams, pages } from 'services/routing';
import { useAppSelectors } from 'services/app';
import { AsyncAutocomplete, ResultItem } from 'components/autocomplete-async';
import { PageBadge } from 'components/page-type-badge';
/* Importing useStreamerFetcher from index file, provokes jest fail.
   The error seems to happen only by raising jest throw console.
   VS code plugins seems not to be affected */
import { useStreamerFetcher } from 'scenes/streamers/useStreamerFetcher';
import { AchievementBadges } from 'components/achievement-badge';
import theme from './streamers-searcher.scss';

const classNames = ClassNames(theme);

const COMPONENT_KEY = 'searchStreamer';
const FETCHER_CONFIGURATION = {
  skipFetchingOnMount: true,
};

const normalizeStreamersResults = ({
  slug,
  owner,
  type,
  ambassador,
  achievementBadges,
}: PublicPageSearchResult): ResultItem<ReactNode> => {
  return {
    _id: slug,
    name: (
      <div className={classNames('search__username')}>
        {slug}
        {achievementBadges && <AchievementBadges achievementBadges={achievementBadges} />}
      </div>
    ),
    imageUrl: owner?.profile?.avatarImageUrl,
    badge: type ? (
      <PageBadge className={theme.search__badge} type={type} ambassador={ambassador} showRegular={false} />
    ) : null,
  };
};

const StreamersSearcher = (): JSX.Element => {
  const history = useHistory();
  const [value, setValue] = useState('');
  const { currentPathname } = useAppSelectors();
  const { data, isLoading, error, filterPages } = useStreamerFetcher(COMPONENT_KEY, FETCHER_CONFIGURATION);
  const { t } = useTranslation('common');

  const searchStreamer = (slug: string) => {
    filterPages({ slug });
    trackEventManager.trackEvent('searchStreamer', {
      pathName: currentPathname,
      searchFieldID: 'Top',
    });
  };

  const handleResultClick = (result: ResultItem) => {
    const userPage = getUrlWithParams(pages.USER_PAGE, { slug: result._id });
    history.push(userPage);
    setValue('');
  };

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  return (
    <AsyncAutocomplete
      className={classNames('search')}
      inputClassName={classNames({
        'search__input': true,
        'search__input--expanded': Boolean(value),
      })}
      autoHeightMax="220px"
      name="slug"
      search={searchStreamer}
      normalizeResult={normalizeStreamersResults}
      resultsError={error?.message}
      resultItems={data}
      isLoading={isLoading}
      onChange={handleChange}
      searchIcon
      onResultClick={handleResultClick}
      placeholder={t('common:menu.search')}
      value={value}
    />
  );
};

export default StreamersSearcher;
