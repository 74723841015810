import * as Sentry from '@sentry/react';
import config from 'config';
import { ApiError } from 'types/indexTS';
import { ReduxStore } from 'store/types';
import { filterError } from './filters';

// This is used in server.tsx as well and it uses @sentry/node there.
// Config is set when the hub is initialized, but applied *after* the error is
// caught, ie, when hub.captureException() is called.
export function sentryConfig() {
  return {
    beforeSend(event, hint) {
      return filterError(event, hint);
    },

    dsn: config.sentry.dsn,
    maxBreadcrumbs: 50,
    debug: config.sentry.environment !== 'production',
    environment: config.sentry.environment,
    release: config.sentry.release,
    dist: config.sentry.dist,
  };
}

const isFilteredError = (message: string): boolean => {
  const lowerCaseMessage = message.toLowerCase();
  return (
    lowerCaseMessage.indexOf('network error') > -1 ||
    lowerCaseMessage.indexOf('0ms') > -1 ||
    lowerCaseMessage.indexOf('request aborted') > -1
  );
};

export function initSentry(): void {
  if (config.sentry.environment !== 'development') {
    Sentry.init(sentryConfig());
  }
}

export function captureSentryError(message: string, info = {}): void {
  Sentry.configureScope(scope => {
    scope.setTag('BusinessError', String(true));
    scope.setExtra('Error Boundary Info', info);
  });

  Sentry.captureException(new Error(message));
}

export function captureErrorBoundarySentry(error: Error, info: Record<string, any>, skipOnError?: boolean): void {
  Sentry.configureScope(scope => {
    scope.setTag('ErrorBoundary', String(true));
    scope.setTag('SkipOnError', String(!!skipOnError));
    scope.setExtra('Error Boundary Info', info);
  });

  Sentry.captureException(error);
}

export function captureReducerError(error: Error, action: Record<string, unknown>, metadata: Record<string, unknown>) {
  try {
    if (error && error.message && isFilteredError(error.message)) {
      return;
    }

    Sentry.configureScope(scope => {
      scope.setTag('ReducerError', String(true));
      scope.setExtra('Reducer error action', action);
      scope.setExtra('Reducer error Metadata', metadata);
      scope.setExtra('Reducer error trace', error);
    });

    Sentry.captureException(error);
  } catch (e) {
    console.error(e);
  }
}

export function captureApiError(error: ApiError, parameters: Record<string, unknown>): void {
  Sentry.configureScope(scope => {
    scope.setTag('ApiError', String(true));
    scope.setExtra('Serialized errors', JSON.stringify(error.errors));
    scope.setExtra('Serialized parameters', JSON.stringify(parameters));
  });

  Sentry.captureException(error);
}

export function setUserContext(state: ReduxStore): void | { id: string; username: string } {
  const {
    user: { user },
  } = state;
  if (!user || !user.profile) {
    return Sentry.configureScope(scope => scope.setUser({}));
  }

  const {
    profile: { username },
    _id: id,
  } = user;

  return {
    id,
    username,
  };
}

// stateTransformer for Sentry redux middleware.
export function filterState(state: Partial<ReduxStore>): Record<string, any> {
  const filteredState = { ...state };

  delete filteredState.bannedUsers;
  delete filteredState.form;
  delete filteredState.adminPagePlans;
  delete filteredState.adminPayouts;
  delete filteredState.auth;
  delete filteredState.bannedUsers;
  delete filteredState.culture;
  delete filteredState.dataConciliator;
  delete filteredState.invoices;
  delete filteredState.transactions;

  return filteredState;
}
