import staticImages from 'constants/aws';
import { useTranslation } from 'utils/translation';
import { AuthSection } from 'scenes/auth';
import theme from './user-collections.scss';

export const LogInToDiscover = (): JSX.Element => {
  const { t } = useTranslation('collection');
  return (
    <div className={theme.discover__connect}>
      <img
        className={theme.discover__connect__image}
        src={staticImages.MISCELLANEOUS.FRIENDS}
        alt="Sign in to discover"
      />
      <h2>{t('collection:signInToDiscoverTitle')}</h2>
      <p className={theme.discover__connect__description}>{t('collection:signInToDiscoverDescription')}</p>
      <AuthSection componentKey="discover" location="my streamers page" />
    </div>
  );
};
