import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import { CancelToken } from 'types/indexTS';
import { PageFeedActionTypes } from './actionTypes';
import { DEFAULT_PAGE_FEED_QUERY_LIMIT } from './constants';
import { getNormalizedPageFeedFromResponse, getPageFeedFromResponse } from './normalizer';
import { pageFeedSelectors } from './selectors';
import {
  CreateFeedParameters,
  CreatePageFeed,
  CREATE_PAGE_FEED_ERROR_ACTION,
  CREATE_PAGE_FEED_SUCCESS_ACTION,
  DeletePageFeed,
  DELETE_ADDITIONAL_DATA,
  DELETE_PAGE_FEED_ERROR_ACTION,
  DELETE_PAGE_FEED_SUCCESS_ACTION,
  FetchNextPageFeed,
  FetchPageFeed,
  FetchPageFeedPost,
  FETCH_NEXT_PAGE_FEED_ERROR_ACTION,
  FETCH_NEXT_PAGE_FEED_LOADING_ACTION,
  FETCH_NEXT_PAGE_FEED_SUCCESS_ACTION,
  FETCH_PAGE_FEED_ERROR_ACTION,
  FETCH_PAGE_FEED_LOADING_ACTION,
  FETCH_PAGE_FEED_DETAIL_ERROR_ACTION,
  FETCH_PAGE_FEED_DETAIL_SUCCESS_ACTION,
  FETCH_PAGE_FEED_SUCCESS_ACTION,
  LikeFeedParameters,
  LikePageFeed,
  LIKE_ADDITIONAL_DATA,
  LIKE_PAGE_FEED_ERROR_ACTION,
  LIKE_PAGE_FEED_LOADING_ACTION,
  LIKE_PAGE_FEED_SUCCESS_ACTION,
  PageFeedQuery,
} from './types';

// ----- Fetch Page Feed -----
const fetchPageFeedSuccess = ({ data, parameters }): FETCH_PAGE_FEED_SUCCESS_ACTION =>
  actionCreator.createAction(PageFeedActionTypes.FETCH_PAGE_FEED_SUCCESS, {
    data: getNormalizedPageFeedFromResponse(data.data),
    pagination: data.pagination,
    query: parameters,
  });

const fetchPageFeedError = ({ errorData }): FETCH_PAGE_FEED_ERROR_ACTION =>
  actionCreator.createErrorAction(PageFeedActionTypes.FETCH_PAGE_FEED_ERROR, errorData);

const fetchPageFeedRequest = (query: PageFeedQuery, cancelToken: CancelToken): FETCH_PAGE_FEED_LOADING_ACTION =>
  actionCreator.createAction(PageFeedActionTypes.FETCH_PAGE_FEED_REQUEST, {
    cancelToken,
    query,
  });

const fetchPageFeed: FetchPageFeed = (slug: string, limit: number = DEFAULT_PAGE_FEED_QUERY_LIMIT) => {
  return actionCreator.createAsyncAction({
    endpoint: endpoints.PAGE_FEED.GET,
    errorBinding: fetchPageFeedError,
    loadingAction: (cancelToken: CancelToken) => fetchPageFeedRequest({ slug, limit }, cancelToken),
    method: 'get',
    parameters: { limit },
    urlParameters: {
      slug,
    },
    successBinding: fetchPageFeedSuccess,
    cancelTokenSelector: state => pageFeedSelectors.fetchingCancelToken(state),
  });
};

// ----- Fetch Next Page Feed -----
const fetchNextPageFeedSuccess = ({ data, parameters }): FETCH_NEXT_PAGE_FEED_SUCCESS_ACTION =>
  actionCreator.createAction(PageFeedActionTypes.FETCH_NEXT_PAGE_FEED_SUCCESS, {
    data: getNormalizedPageFeedFromResponse(data.data),
    pagination: data.pagination,
    query: parameters,
  });

const fetchNextPageFeedError = ({ errorData }): FETCH_NEXT_PAGE_FEED_ERROR_ACTION =>
  actionCreator.createErrorAction(PageFeedActionTypes.FETCH_NEXT_PAGE_FEED_ERROR, errorData);

const fetchNextPageFeedRequest = (cancelToken: CancelToken): FETCH_NEXT_PAGE_FEED_LOADING_ACTION =>
  actionCreator.createAction(PageFeedActionTypes.FETCH_NEXT_PAGE_FEED_REQUEST, { cancelToken });

const fetchNextPageFeed: FetchNextPageFeed = (nextUrl: string) =>
  actionCreator.createAsyncAction({
    absoluteEndPoint: nextUrl,
    errorBinding: fetchNextPageFeedError,
    loadingAction: (cancelToken: CancelToken) => fetchNextPageFeedRequest(cancelToken),
    method: 'get',
    successBinding: fetchNextPageFeedSuccess,
  });

// ----- Create Page Feed -----
const createPageFeedSuccess = ({ data }): CREATE_PAGE_FEED_SUCCESS_ACTION =>
  actionCreator.createAction(PageFeedActionTypes.CREATE_PAGE_FEED_SUCCESS, getPageFeedFromResponse(data));

const createPageFeedError = ({ errorData }): CREATE_PAGE_FEED_ERROR_ACTION =>
  actionCreator.createErrorAction(PageFeedActionTypes.CREATE_PAGE_FEED_ERROR, errorData);

const createPageFeed: CreatePageFeed = (slug: string, parameters: CreateFeedParameters) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.PAGE_FEED.CREATE,
    errorBinding: createPageFeedError,
    method: 'post',
    parameters,
    urlParameters: {
      slug,
    },
    successBinding: createPageFeedSuccess,
  });

// ----- Delete Page Feed -----
const deletePageFeedSuccess = ({ data, additionalData }): DELETE_PAGE_FEED_SUCCESS_ACTION =>
  actionCreator.createAction(PageFeedActionTypes.DELETE_PAGE_FEED_SUCCESS, data, additionalData);

const deletePageFeedError = ({ errorData }): DELETE_PAGE_FEED_ERROR_ACTION =>
  actionCreator.createErrorAction(PageFeedActionTypes.DELETE_PAGE_FEED_ERROR, errorData);

const deletePageFeed: DeletePageFeed = (slug: string, feedId: string) => {
  const additionalData: DELETE_ADDITIONAL_DATA = { _id: feedId };

  return actionCreator.createAsyncAction({
    endpoint: endpoints.PAGE_FEED.DELETE,
    additionalData,
    errorBinding: deletePageFeedError,
    method: 'delete',
    urlParameters: {
      slug,
      feedId,
    },
    successBinding: deletePageFeedSuccess,
  });
};

// ----- Fetch Single Feed Post -----
const fetchSingleFeedPostSuccess = ({ data }): FETCH_PAGE_FEED_DETAIL_SUCCESS_ACTION =>
  actionCreator.createAction(PageFeedActionTypes.FETCH_PAGE_FEED_DETAIL_SUCCESS, data);

const fetchSingleFeedPostError = ({ errorData }): FETCH_PAGE_FEED_DETAIL_ERROR_ACTION =>
  actionCreator.createErrorAction(PageFeedActionTypes.FETCH_PAGE_FEED_DETAIL_ERROR, errorData);

const fetchSingleFeedPost: FetchPageFeedPost = (slug: string, feedId: string) => {
  return actionCreator.createAsyncAction({
    endpoint: endpoints.PAGE_FEED.GET_ONE,
    errorBinding: fetchSingleFeedPostError,
    loadingAction: PageFeedActionTypes.FETCH_PAGE_FEED_DETAIL_REQUEST,
    method: 'get',
    urlParameters: {
      slug,
      feedId,
    },
    successBinding: fetchSingleFeedPostSuccess,
    cancelTokenSelector: state => pageFeedSelectors.fetchingCancelToken(state),
  });
};
// ----- Like Page Feed -----
const likePageFeedSuccess = ({ data }): LIKE_PAGE_FEED_SUCCESS_ACTION =>
  actionCreator.createAction(PageFeedActionTypes.LIKE_PAGE_FEED_SUCCESS, data);

const likePageFeedError = ({ errorData, additionalData }): LIKE_PAGE_FEED_ERROR_ACTION =>
  actionCreator.createErrorAction(PageFeedActionTypes.LIKE_PAGE_FEED_ERROR, errorData, additionalData);

const likePageFeed: LikePageFeed = (slug: string, feedId: string, parameters: LikeFeedParameters) => {
  const additionalData: LIKE_ADDITIONAL_DATA = { _id: feedId, liked: parameters.liked };

  return actionCreator.createAsyncAction({
    additionalData,
    endpoint: endpoints.PAGE_FEED.LIKE,
    errorBinding: likePageFeedError,
    method: 'post',
    urlParameters: {
      slug,
      feedId,
    },
    parameters,
    successBinding: likePageFeedSuccess,
    loadingAction: (): LIKE_PAGE_FEED_LOADING_ACTION =>
      actionCreator.createAction(PageFeedActionTypes.LIKE_PAGE_FEED_REQUEST, additionalData),
  });
};

// ----- Exported actions -----
export const pageFeedActions = {
  fetchPageFeed,
  fetchNextPageFeed,
  createPageFeed,
  deletePageFeed,
  fetchSingleFeedPost,
  likePageFeed,
};
