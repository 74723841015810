import { paginationStateNormalizer } from 'services/pagination';
import { FileTypeFilter } from '@streamloots/streamloots-types';
import { getTypeFromMimeType } from 'utils/fileTypeChecker';
import type { FileActions, FilesState } from './typesTS';
import { PageActionTypes } from '../page';
import { FilesActionTypes } from './actionTypes';
import { DEFAULT_FILES_QUERY, PAGINATION_STATE_NORMALIZER_CONFIGURATION } from './constants';

const INITIAL_FILES_STATE: FilesState = {
  query: DEFAULT_FILES_QUERY,
  selectedTab: 'all',
  summary: {
    totalSize: 0,
  },
  reconcile: false,
};

export const reducer = (state: FilesState = INITIAL_FILES_STATE, action: FileActions): FilesState => {
  switch (action.type) {
    case FilesActionTypes.FETCH_FILES_REQUEST:
      return {
        ...paginationStateNormalizer.getRequest(state, action.payload, PAGINATION_STATE_NORMALIZER_CONFIGURATION),
        reconcile: false,
      };
    case FilesActionTypes.FETCH_FILES_ERROR:
      return paginationStateNormalizer.getReceivedError(action, state);
    case FilesActionTypes.FETCH_FILES_SUCCESS: {
      const { summary, ...restPayload } = action.payload;
      const isSameTab = restPayload.query.type === state.selectedTab;
      const isNameFilter = action.payload.query.name !== null || action.payload.query.name !== '';

      return {
        ...paginationStateNormalizer.getReceived(
          restPayload,
          PAGINATION_STATE_NORMALIZER_CONFIGURATION,
          isSameTab && !isNameFilter ? state.data || [] : [],
        ),
        summary,
        filesTotalMaxBytesAllowed: state.filesTotalMaxBytesAllowed,
        selectedTab: action.payload.query.type,
        reconcile: false,
      };
    }
    case FilesActionTypes.FETCH_NEXT_REQUEST:
      return paginationStateNormalizer.getFetchingNextItems(state, action.payload.cancelToken);
    case FilesActionTypes.FETCH_NEXT_SUCCESS: {
      return paginationStateNormalizer.getNextItemsReceived(state, action.payload);
    }
    case FilesActionTypes.FETCH_NEXT_ERROR: {
      return paginationStateNormalizer.getNextReceivedError(state);
    }

    case FilesActionTypes.UPLOAD_FILE_SUCCESS: {
      const { file, isOverwriting } = action.payload;
      const { data, selectedTab } = state;

      if (!data || (getTypeFromMimeType(file.mimeType) !== selectedTab && selectedTab !== FileTypeFilter.ALL)) {
        return state;
      }

      let newFiles;

      if (isOverwriting) {
        newFiles = data.map(savedFile => {
          if (savedFile.name === file.name) {
            return {
              ...file,
              fileUri: `${file.fileUri}?cacheId=${Date.now()}`,
            };
          }
          return savedFile;
        });
      } else {
        newFiles = [{ ...file }, ...data];
      }

      return {
        ...state,
        data: newFiles,
      };
    }
    case FilesActionTypes.UPDATE_FILE_SUCCESS: {
      const { file } = action.payload;
      const { data, selectedTab } = state;

      if (!data || (getTypeFromMimeType(file.mimeType) !== selectedTab && selectedTab !== FileTypeFilter.ALL)) {
        return state;
      }

      const newFiles = data.map(fileItem => (fileItem._id === file._id ? { ...file } : fileItem));
      return {
        ...state,
        data: newFiles,
      };
    }
    case FilesActionTypes.DELETE_FILE_SUCCESS: {
      const { fileId } = action.payload;
      const { data } = state;

      if (!data || !data.find(file => file._id === fileId)) {
        return state;
      }

      return {
        ...state,
        reconcile: true,
      };
    }

    case PageActionTypes.CHANGED:
      return INITIAL_FILES_STATE;
    case PageActionTypes.RECEIVED: {
      if (action.error) {
        return state;
      }

      const { filesTotalMaxBytesAllowed } = action.payload;
      return {
        ...state,
        filesTotalMaxBytesAllowed,
      };
    }
    default:
      return state;
  }
};
