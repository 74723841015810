import { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { Languages } from '@streamloots/streamloots-types';
import { STATIC_FILES_URL_PATH } from 'constants/images';
import { TranslateInterface, withTranslation } from 'utils/translation';
import trackEventManager from 'utils/event-manager';
import { authSelectors } from 'services/auth';
import VideoModal from 'components/video-modal';
import { articlesKeys, articlesMapping } from 'components/help';
import { Powers } from 'components/powers';
import { FullLayout } from 'scenes/layout';
import { cultureSelectors } from 'services/culture';
import { BottomBanner, BottomBannerType } from 'components/banners';
import { AnonymousAlert } from './components/anonymous-alert';
import { PowersExplanation } from './components/powers-explanation';

interface MapStateToProps {
  isUserAuthenticated: boolean;
  language: Languages;
}

interface PowerPageProps extends MapStateToProps, TranslateInterface {}

const PowersPageBase = ({ isUserAuthenticated, language, t }: PowerPageProps): JSX.Element => {
  const [triggerHelpModal, setTriggerHelpModal] = useState(false);

  const videoURL = `${STATIC_FILES_URL_PATH}videos/powers-${language.toUpperCase()}.mp4`;
  const posterURL = `${STATIC_FILES_URL_PATH}videos/powers-poster-${language.toUpperCase()}.jpg`;

  if (!isUserAuthenticated) {
    return <AnonymousAlert t={t} />;
  }

  const showHowItWorks = () => {
    setTriggerHelpModal(true);
  };
  return (
    <FullLayout>
      <BottomBanner type={BottomBannerType.CREATE_COLLECTION} />
      <Powers isPowersPage>
        <PowersExplanation onClick={showHowItWorks} t={t} />
      </Powers>

      <VideoModal
        announcementsKey="isPowersHelpsModalSeen"
        videoURL={videoURL}
        posterURL={posterURL}
        title={t('powers:helpModalTitle')}
        text={t('powers:helpModalText')}
        linkText={t('powers:linkText')}
        link={articlesMapping[language].POWERS}
        triggerModal={triggerHelpModal}
        onClose={() => setTriggerHelpModal(false)}
        onClickHelpLink={() =>
          trackEventManager.trackEvent('help_clicked', {
            articleKey: articlesKeys.POWERS,
            source: 'helpModal',
          })
        }
      />
    </FullLayout>
  );
};

const mapStateToProps = state => ({
  isUserAuthenticated: authSelectors.isUserAuthenticated(state),
  language: cultureSelectors.language(state),
});

export const PowersPage = compose<FunctionComponent>(
  connect(mapStateToProps, null),
  withTranslation(['common', 'powers']),
)(PowersPageBase);
