import { createSelector } from 'reselect';
import type { DataConciliatorState } from './types';

const dataConciliatorState = (state): DataConciliatorState => state.dataConciliator;

const reconcilePage = createSelector(
  [dataConciliatorState],
  (dataConciliatorStateInfo: DataConciliatorState): boolean => Boolean(dataConciliatorStateInfo.page.reconcile),
);

const reconcileSets = createSelector(
  [dataConciliatorState],
  (dataConciliatorStateInfo: DataConciliatorState): boolean => Boolean(dataConciliatorStateInfo.sets.reconcile),
);

const reconcileUserSets = createSelector(
  [dataConciliatorState],
  (dataConciliatorStateInfo: DataConciliatorState): boolean => Boolean(dataConciliatorStateInfo.userSets.reconcile),
);

const invalidateSetOrUserSet = createSelector(
  [dataConciliatorState],
  (dataConciliatorStateInfo: DataConciliatorState): boolean =>
    Boolean(dataConciliatorStateInfo.userSets.invalidating || dataConciliatorStateInfo.sets.invalidating),
);

const reconcileUser = createSelector(
  [dataConciliatorState],
  (dataConciliatorStateInfo: DataConciliatorState): boolean => Boolean(dataConciliatorStateInfo.user.reconcile),
);

const reconcileAuctions = createSelector(
  [dataConciliatorState],
  (dataConciliatorStateInfo: DataConciliatorState): boolean => Boolean(dataConciliatorStateInfo.auctions.reconcile),
);

export const dataConciliatorSelectors = {
  invalidateSetOrUserSet,
  reconcilePage,
  reconcileSets,
  reconcileUserSets,
  reconcileAuctions,
  reconcileUser,
};
