export enum actionTypes {
  GET_PROMOTIONS_REQUEST = 'promotions/GET_PROMOTIONS',
  GET_PROMOTIONS_SUCCESS = 'promotions/GET_PROMOTIONS_SUCCESS',
  GET_PROMOTIONS_ERROR = 'promotions/GET_PROMOTIONS_ERROR',
  GET_CLAIMABLE_PROMOTION_REQUEST = 'promotions/GET_CLAIMABLE_PROMOTION',
  GET_CLAIMABLE_PROMOTION_SUCCESS = 'promotions/GET_CLAIMABLE_PROMOTION_SUCCESS',
  GET_CLAIMABLE_PROMOTION_ERROR = 'promotions/GET_CLAIMABLE_PROMOTION_ERROR',
  POST_CLAIMABLE_PROMOTION_REQUEST = 'promotions/POST_CLAIMABLE_PROMOTION',
  POST_CLAIMABLE_PROMOTION_SUCCESS = 'promotions/POST_CLAIMABLE_PROMOTION_SUCCESS',
  POST_CLAIMABLE_PROMOTION_ERROR = 'promotions/POST_CLAIMABLE_PROMOTION_ERROR',
}
