import { FixMe } from 'types/indexTS';
import { StrictOmit } from '@streamloots/streamloots-types';

export enum NOTIFICATION_TYPES {
  FREE_GIFT_FROM = 'FREE_GIFT_FROM',
  GIFT_FROM = 'GIFT_FROM',
  RANDOM_COMMUNITY_GIFT_FROM = 'RANDOM_COMMUNITY_GIFT_FROM',
  NEW_CARDS_IN_COLLECTION = 'NEW_CARDS_IN_COLLECTION',
  KINGMIDAS_BADGE = 'KINGMIDAS_BADGE',
  KINGMIDAS_UNLOCKED = 'KINGMIDAS_UNLOCKED',
  OPENABLE_CHESTS = 'OPENABLE_CHESTS',
  CREATE_YOUR_COLLECTION = 'CREATE_YOUR_COLLECTION',
  CUSTOM = 'CUSTOM',
  ACHIEVEMENT_GRANTED = 'ACHIEVEMENT_GRANTED',
  AUCTION_WINNER = 'AUCTION_WINNER',
  AUCTION_PAID = 'AUCTION_PAID',
  WIZARD_FINISHED = 'WIZARD_FINISHED',
  COMMUNITY_LOOT_CLAIMED = 'COMMUNITY_LOOT_CLAIMED',
  NEW_CARD_SUGGESTION = 'NEW_CARD_SUGGESTION',
  SUBSCRIPTION_GIFT = 'SUBSCRIPTION_GIFT',
  COMMUNITY_RANKS_POWERS_REWARD = 'COMMUNITY_RANKS_POWERS_REWARD',
  COMMUNITY_SUBSCRIPTION_CLAIMED = 'COMMUNITY_SUBSCRIPTION_CLAIMED',
  COMMUNITY_SUBSCRIPTION_RANDOM_GIFTED = 'COMMUNITY_SUBSCRIPTION_RANDOM_GIFTED',
  FEED_POST_TIPPED = 'FEED_POST_TIPPED',
  PAGE_FEED_COMMENT_CREATED = 'PAGE_FEED_COMMENT_CREATED',
  PAGE_FEED_COMMENT_LIKED = 'PAGE_FEED_COMMENT_LIKED',
  PAGE_FEED_COMMENT_STREAMER_LIKED = 'PAGE_FEED_COMMENT_STREAMER_LIKED',
}

export interface GET_NOTIFICATION_RESPONSE_DATA {
  _id: string;
  read: boolean;
  createdAt: string;
  metadata: {
    location?: {
      en: string;
      es: string;
    };
    trace: string;
    image?: string;
    icon?: string;
    link?: string;
    internalLink?: string;
    externalLink?: string;
    slug?: string;
    rewards?: Record<string, FixMe>[];
    feedId?: string;
    amountFeedTip?: string;
    username?: string;
    currency?: string;
    commentFeedTip?: string;
    feedCommentId?: string;
    avatarImageUrl?: string;
    comment?: string;
  };
  icon?: string;
  image?: string;
  targetId: string;
  type: NOTIFICATION_TYPES;
}

export interface GET_NOTIFICATION_SUMMARY {
  newNotificationsInAppCount: {
    count: number;
    targetId: string;
  };
}

export interface NotificationMetadata
  extends StrictOmit<Pick<GET_NOTIFICATION_RESPONSE_DATA, 'metadata'>['metadata'], 'trace'> {
  trace?: string;
  cardsLeft?: number;
  amount?: number;
  quantity?: number;
  auctionId?: string;
}

export interface Notification extends StrictOmit<GET_NOTIFICATION_RESPONSE_DATA, 'metadata'> {
  metadata: NotificationMetadata;
}

export interface NormalizedNotificationMetadata extends NotificationMetadata {
  count?: number;
}

export interface NormalizedNotification
  extends Pick<GET_NOTIFICATION_RESPONSE_DATA, 'read' | 'createdAt' | 'image' | 'icon' | 'type'> {
  text: string;
  externalLink?: string;
  internalLink?: string;
  metadata: NormalizedNotificationMetadata;
}
