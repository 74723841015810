import loadable from 'components/dynamic-import';
import { FullLayout } from 'scenes/layout';

const ConnectedUpdateBilling = loadable(() => import('./ConnectedUpdateBilling'));

export const BillingInformationConfigurationPage = () => (
  <FullLayout>
    <ConnectedUpdateBilling />
  </FullLayout>
);
