import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import theme from './two-column-panel.scss';

const classNames = ClassNames(theme);

interface SmallColumnPanelProps {
  children: React.ReactNode;
  responsive?: boolean;
  largeBreak?: boolean;
}

export const SmallColumnPanel = ({ children, responsive = false, largeBreak }: SmallColumnPanelProps) => {
  return (
    <div
      className={classNames({
        'two-column-panel__small': !responsive,
        'two-column-panel-responsive__small': responsive,
        'two-column-panel__small__desktop-break': !largeBreak,
        'two-column-panel__small__large-break': largeBreak,
      })}
    >
      {children}
    </div>
  );
};
