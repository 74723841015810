export enum MarathonActionTypes {
  FETCHING = 'marathon/FETCHING',
  RECEIVED = 'marathon/RECEIVED ',
  ERROR = 'marathon/FETCH-ERROR',
  CREATING = 'marathon/CREATING',
  CREATED = 'marathon/CREATED ',
  ERROR_CREATING = 'marathon/ERROR_CREATING',
  UPDATING = 'marathon/UPDATING',
  UPDATED = 'marathon/UPDATED ',
  ERROR_UPDATING = 'marathon/ERROR_UPDATING',
  STARTING = 'marathon/STARTING',
  STARTED = 'marathon/STARTED ',
  ERROR_STARTING = 'marathon/ERROR_STARTING',
  STOPING = 'marathon/STOPING',
  STOPPED = 'marathon/STOPPED ',
  ERROR_STOPING = 'marathon/ERROR_STOPING',
  FETCHING_TRACKER = 'marathon/FETCHING_TRACKER',
  TRACKER_RECEIVED_SUCCESS = 'marathon/TRACKER_RECEIVED_SUCCESS',
  TRACKER_RECEIVED_ERROR = 'marathon/TRACKER_RECEIVED_ERROR',
  ADDING_TIME = 'marathon/ADDING_TIME',
  ADDED_TIME = 'marathon/ADDED_TIME ',
  ERROR_ADDING_TIME = 'marathon/ERROR_ADDING_TIME',
  MARATHON_STATUS_CHANGED_SUCCESS = 'marathon/MARATHON_STATUS_CHANGED_SUCCESS',
  MARATHON_STATUS_CHANGED_ERROR = 'marathon/MARATHON_STATUS_CHANGED_ERROR',
  MARATHON_STATUS_CHANGED_REQUEST = 'marathon/MARATHON_STATUS_CHANGED_REQUEST',
}
