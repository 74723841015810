/* eslint consistent-return: 0 */
/* eslint no-useless-escape: 0 */
import i18n from 'i18next';

const isEmpty = (value: string[] | string | undefined | null): boolean =>
  value === undefined || value === null || value === '';

const checked = (value: boolean, messageKey: string): string | undefined => {
  if (!value) {
    return i18n.t(messageKey || 'errors:validation.fieldRequired');
  }
  return undefined;
};

const email = (value: string): string | undefined => {
  const emailRegularExpression = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
  if (!isEmpty(value) && !emailRegularExpression.test(value)) {
    return i18n.t('errors:validation.wrongEmail');
  }
  return undefined;
};

const zipCode = (value: string): string | undefined => {
  const zipCodeExpression = /^\d{5}(?:[-\s]\d{4})?$/i;
  if (!isEmpty(value) && !zipCodeExpression.test(value)) {
    return i18n.t('errors:validation.wrongZipCode');
  }
  return undefined;
};

const isFullUrl = (value: string): string | undefined => {
  const urlPattern = /(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/;
  if (!isEmpty(value) && !urlPattern.test(value)) {
    return i18n.t('errors:validation.wrongUrl');
  }
  return undefined;
};

const integer = (value: string): string | undefined => {
  if (!Number.isInteger(Number(value))) {
    return i18n.t('errors:validation.mustBeInteger');
  }
  return undefined;
};

const positiveInteger = value => {
  if (!Number.isInteger(Number(value)) || value <= 0) {
    return i18n.t('errors:validation.positiveInteger');
  }
  return undefined;
};

const positiveNumber = value => {
  if (Number.isNaN(value) || value <= 0) {
    return i18n.t('errors:validation.positiveNumber');
  }
  return undefined;
};

const isUrlFromDomain = options => value => {
  const domain = options.domain.replace(/\./g, '\\.');
  const pattern = `(?:(http|https):\/\/)?(?:www\.)?${domain}\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)`;
  const urlPattern = new RegExp(pattern);
  if (!isEmpty(value) && !urlPattern.test(value)) {
    return i18n.t('errors:validation.wrongUrl');
  }
  return undefined;
};

const match = options => (value, data) => {
  const { field } = options;
  if (data) {
    if (value !== data[field]) {
      return i18n.t('errors:validation.fieldDoesNotMatch');
    }
  }
  return undefined;
};

const maxLength = ({ max }: { max: number }) => (value: string) => {
  if (!isEmpty(value) && value.length > max) {
    return i18n.t('errors:validation.maxLength', { max });
  }

  return undefined;
};

const maxNumber = ({ max, message }: { max: number; message?: string }) => (value: string | undefined) => {
  if (Number(value) > max) {
    return i18n.t(message || 'errors:validation.maxNumber', { max });
  }
  return undefined;
};

const minNumber = options => value => {
  const { message, min } = options;
  if (Number(value) < min) {
    return i18n.t(message || 'errors:validation.minNumber', { min });
  }
  return undefined;
};

const minLength = options => value => {
  const { min } = options;
  if (!isEmpty(value) && value.length < min) {
    return i18n.t('errors:validation.minLength', { min });
  }
  return undefined;
};

const oneOf = options => value => {
  const { enumeration } = options;
  if (enumeration.indexOf(value) === -1) {
    return i18n.t('errors:validation.oneOf', {
      values: enumeration.join(', '),
    });
  }
  return undefined;
};

const required = (value?: string | string[] | null) => {
  if (isEmpty(value) || (Array.isArray(value) && value.length === 0)) {
    return i18n.t('errors:validation.fieldRequired');
  }
  return undefined;
};

const maxItemsAutocomplete = ({ max, message }: { max: number; message: string }) => (value: string[]) => {
  const totalItems = value ? value.length : 0;

  if (value && totalItems > max) {
    return i18n.t(message || 'errors:validation.maxItemsAutocomplete', { max });
  }
  return undefined;
};

const isTwitterUrl = (value: string): string | null => {
  const twitterRegex = new RegExp(/http(?:s)?:\/\/(?:www\.)?twitter\.com\/(\w+)/);
  if (!value || twitterRegex.test(value)) {
    return null;
  }

  return i18n.t('userProfile:socialMedia.errors.twitter');
};

const isDiscordUrl = (value: string): string | null => {
  const discordRegex = new RegExp(/http(?:s)?:\/\/(?:www\.)?discord\.gg\/(\w+)/);
  if (!value || discordRegex.test(value)) {
    return null;
  }

  return i18n.t('userProfile:socialMedia.errors.discord');
};

const isReactionValidName = (values: string): string | null => {
  const reactionRegex = new RegExp(/^[a-z_]+$/);
  if (!values || reactionRegex.test(values)) {
    return null;
  }

  return i18n.t('pageSubscription:staff.databaseError');
};

const isEmoteValidName = (values: string): string | null => {
  const reactionRegex = new RegExp(/^[a-z0-9]+$/);
  if (!values || reactionRegex.test(values)) {
    return null;
  }

  return i18n.t('pageSubscription:emotes.invalidName');
};

export default {
  checked,
  email,
  integer,
  isFullUrl,
  isUrlFromDomain,
  match,
  maxLength,
  maxNumber,
  minLength,
  minNumber,
  oneOf,
  positiveInteger,
  positiveNumber,
  required,
  zipCode,
  maxItemsAutocomplete,
  isTwitterUrl,
  isDiscordUrl,
  isReactionValidName,
  isEmoteValidName,
};
