import ClassNames from '@streamloots/classnames';
import { TranslateInterface } from 'utils/translation';
import Countdown from 'react-countdown-now';
import theme from './flash-coupons.scss';

const classNames = ClassNames(theme);

interface FlashCouponTimeLeftProps extends TranslateInterface {
  countdownKey?: number;
  couponExpiresAt: Date | string;
}

export const FlashCouponTimeLeft = ({
  countdownKey,
  couponExpiresAt,
  t,
}: FlashCouponTimeLeftProps): JSX.Element | null => {
  return (
    <p className="no-margin">
      {t('common:flashCoupons.timeLeft')} {` `}
      <span className={classNames('flash-coupon__time')}>
        <Countdown date={couponExpiresAt} key={countdownKey} daysInHours />
      </span>
    </p>
  );
};
