import Skeleton from 'ui-library/skeleton';
import { BigColumnPanel, SmallColumnPanel, TwoColumnLayout } from 'ui-library/two-column-panel';
import { MarathonPageHeader } from './MarathonPageHeader';

export const MarathonSkeleton = (): JSX.Element => {
  return (
    <section>
      <MarathonPageHeader />
      <TwoColumnLayout reverse>
        <BigColumnPanel>
          <Skeleton height="250px" className="margin-bottom" />
          <Skeleton height="750px" />
        </BigColumnPanel>
        <SmallColumnPanel>
          <Skeleton height="500px" className="margin-bottom" />
          <Skeleton height="125px" />
        </SmallColumnPanel>
      </TwoColumnLayout>
    </section>
  );
};
