export enum EmotesActionTypes {
  // Fetch
  FETCH_EMOTES_REQUEST = 'emotes/FETCH_EMOTES_REQUEST',
  FETCH_EMOTES_ERROR = 'emotes/FETCH_EMOTES_ERROR',
  FETCH_EMOTES_SUCCESS = 'emotes/FETCH_EMOTES_SUCCESS',

  // Create.
  CREATE_EMOTE_REQUEST = 'emotes/CREATE_EMOTE_REQUEST',
  CREATE_EMOTE_ERROR = 'emotes/CREATE_EMOTE_ERROR',
  CREATE_EMOTE_SUCCESS = 'emotes/CREATE_EMOTE_SUCCESS',

  // Delete.
  DELETE_EMOTE_REQUEST = 'emotes/DELETE_EMOTE_REQUEST',
  DELETE_EMOTE_ERROR = 'emotes/DELETE_EMOTE_ERROR',
  DELETE_EMOTE_SUCCESS = 'emotes/DELETE_EMOTE_SUCCESS',

  // Edit.
  EDIT_EMOTE_REQUEST = 'emotes/EDIT_EMOTE_REQUEST',
  EDIT_EMOTE_ERROR = 'emotes/EDIT_EMOTE_ERROR',
  EDIT_EMOTE_SUCCESS = 'emotes/EDIT_EMOTE_SUCCESS',

  // Delete Streamer's Emote.
  DELETE_STREAMER_EMOTE_REQUEST = 'emotes/DELETE_STREAMER_EMOTE_REQUEST',
  DELETE_STREAMER_EMOTE_ERROR = 'emotes/DELETE_STREAMER_EMOTE_ERROR',
  DELETE_STREAMER_EMOTE_SUCCESS = 'emotes/DELETE_STREAMER_EMOTE_SUCCESS',

  // Add Streamer's Emote.
  ADD_STREAMER_EMOTE_REQUEST = 'emotes/ADD_STREAMER_EMOTE_REQUEST',
  ADD_STREAMER_EMOTE_ERROR = 'emotes/ADD_STREAMER_EMOTE_ERROR',
  ADD_STREAMER_EMOTE_SUCCESS = 'emotes/ADD_STREAMER_EMOTE_SUCCESS',
}
