import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

// create a "selector creator" that uses Underscore's isEqual instead of ===
export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const actionTypeRegexp = /(.*)_(REQUEST|SUCCESS|ERROR|FINISHED|NOT_FOUND)/;
export const typeWithoutSuffix = (actionType: string): string => {
  const matches = actionTypeRegexp.exec(actionType);
  const [, requestName] = matches || [];

  return requestName;
};
