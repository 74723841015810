import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import Skeleton from 'ui-library/skeleton';
import theme from './component-skeletons.scss';

const classNames = ClassNames(theme);

interface NotificationSkeletonsProps {
  count?: number;
}

export const NotificationSkeletons = ({ count = 8 }: NotificationSkeletonsProps): JSX.Element => {
  const array = new Array(count);
  const notifications = [...array.keys()];

  return (
    <React.Fragment>
      {notifications.map(key => (
        <div key={key} className={classNames('skeletons-notification')}>
          <div className={classNames('skeletons-notification__icon')}>
            <Skeleton width={40} height={40} />
          </div>
          <div>
            <Skeleton height={32} className={classNames('skeletons-notification__description')} />
            <Skeleton height={14} />
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};
