import cookies from 'js-cookie';
import config from 'config';
import { ReqQuery, ResType } from 'types/indexTS';
import { Location } from 'history';
import { RESERVED_QUERY_PARAMS } from 'constants/reserved-query-params';
import urlUtils from 'utils/urlUtils';

export const DISCOUNT_COUPON_CODE = 'stl-checkout-discount-coupon';
const TWENTY_MINUTES = 20 * 60 * 1000;

const getCouponConfiguration = () => {
  const inTwentyMinutes = new Date(Date.now() + TWENTY_MINUTES);
  return {
    expires: inTwentyMinutes,
    domain: config.streamloots.domain,
    encode: String,
  };
};

const setCouponCookieToResponse = (query: ReqQuery, res: ResType) => {
  const discountCouponCode = query[RESERVED_QUERY_PARAMS.PACKS_DISCOUNT_CODE];
  if (!discountCouponCode) {
    return;
  }

  res.cookie(DISCOUNT_COUPON_CODE, discountCouponCode, getCouponConfiguration());
};

const setCouponCookie = (discountCode: string) => {
  cookies.set(DISCOUNT_COUPON_CODE, discountCode, getCouponConfiguration());
};

const getCouponCode = (reqCookies?: Record<string, string>): string | undefined =>
  reqCookies ? reqCookies[DISCOUNT_COUPON_CODE] : cookies.get(DISCOUNT_COUPON_CODE);

const removeCouponCode = () => {
  cookies.remove(DISCOUNT_COUPON_CODE, { domain: config.streamloots.domain });
};

// Needed mainly for product internal notifications sent by marketing
const setCouponCookieFromLocation = (location: Location) => {
  const queryParams = urlUtils.getSearchParams(location.search);

  if (!queryParams || !queryParams[RESERVED_QUERY_PARAMS.PACKS_DISCOUNT_CODE]) {
    return;
  }

  setCouponCookie(queryParams[RESERVED_QUERY_PARAMS.PACKS_DISCOUNT_CODE]);
};

export default {
  getCouponCode,
  setCouponCookieToResponse,
  removeCouponCode,
  setCouponCookie,
  setCouponCookieFromLocation,
};
