// Use these different objects depending of which media rule you are using.
// They represent pixels.
const minWidth = {
  xxSmall: 481,
  xSmall: 540,
  small: 670,
  mobile: 769,
  medium: 1024,
  desktop: 1200,
  large: 1600,
};

const maxWidth = {
  xxSmall: minWidth.xxSmall - 1,
  xSmall: minWidth.xSmall - 1,
  small: minWidth.small - 1,
  mobile: minWidth.mobile - 1,
  medium: minWidth.medium - 1,
  desktop: minWidth.desktop - 1,
  large: minWidth.large - 1,
};

function breakpointsToStrings() {
  const minWidthString: Record<keyof typeof minWidth, string> = Object.keys({
    ...minWidth,
  }).reduce(
    (acc, curr) => {
      return { ...acc, [curr]: `(min-width: ${minWidth[curr]}px)` };
    },
    {
      xxSmall: '',
      xSmall: '',
      small: '',
      mobile: '',
      medium: '',
      desktop: '',
      large: '',
    },
  );

  const maxWidthString: Record<keyof typeof maxWidth, string> = Object.keys({
    ...maxWidth,
  }).reduce(
    (acc, curr) => {
      return { ...acc, [curr]: `(max-width: ${maxWidth[curr]}px)` };
    },
    {
      xxSmall: '',
      xSmall: '',
      small: '',
      mobile: '',
      medium: '',
      desktop: '',
      large: '',
    },
  );

  return { minWidthString, maxWidthString };
}

export const BREAKPOINTS = { minWidth, maxWidth };
export const USE_MEDIA_BREAKPOINTS = breakpointsToStrings();
