import ClassNames from '@streamloots/classnames';
import Tooltip, { TooltipPlacement } from 'ui-library/tooltip';
import { AchievementBadgeInfo, ACHIEVEMENT_BADGE_MAPPING } from 'model/indexTS';
import { DateUtils } from 'domain/date-utils';
import { useTranslation } from 'utils/translation';
import theme from './achievement-badge.scss';

const classNames = ClassNames(theme);

interface AchievementBadgeProps {
  achievementBadge: AchievementBadgeInfo;
  tooltipPosition?: TooltipPlacement;
  size?: 'sm' | 'md';
}

export const AchievementBadge = ({
  achievementBadge,
  size = 'sm',
  tooltipPosition = TooltipPlacement.TOP,
}: AchievementBadgeProps): JSX.Element => {
  const { t } = useTranslation();
  const mappingInfo = ACHIEVEMENT_BADGE_MAPPING[achievementBadge.id];
  const localizedName = t(mappingInfo.nameKey);
  const description = t(mappingInfo.descriptionKey);

  return (
    <Tooltip
      placement={tooltipPosition}
      tooltip={
        <div>
          <strong className={classNames('tooltip__id')}>{localizedName}</strong>

          <p className={classNames('tooltip__description')}>{description}</p>
          <p className={classNames('tooltip__date')}>
            {t('achievements:achievedWithDate', {
              completedAt: DateUtils.formatDate(achievementBadge.achievedAt),
            })}
          </p>
        </div>
      }
    >
      <span className={classNames({ badge: true, [`badge--${size}`]: Boolean(size) })}>
        <img src={achievementBadge.imageUrl} alt={localizedName} />
      </span>
    </Tooltip>
  );
};
