import type { Cooldown, UserCooldownStatus } from 'model/indexTS';
import { PageActionTypes } from 'services/page';
import { RedemptionActionTypes } from 'services/redemption';
import type { CooldownState } from './types';
import { CooldownActionTypes } from './actionTypes';
import normalizer from './normalizer';

const userCooldownStateProperties = (
  userCooldownData: UserCooldownStatus | undefined,
  cooldownData: Cooldown | undefined,
  currentCountdown?: string,
): Partial<CooldownState> => {
  const userCooldownStatusData = userCooldownData || normalizer.userCooldownStatusFromCooldown(cooldownData);

  if (!userCooldownStatusData) {
    return {};
  }

  const userCooldownStatus = normalizer.userCooldownStatus(userCooldownStatusData);

  return {
    userCooldownStatus,
    userCooldownResetAt: normalizer.userCooldownResetAt(userCooldownStatus),
    countdown: normalizer.countdown(cooldownData) || currentCountdown,
  };
};

export const reducer = (state: CooldownState = {}, action): CooldownState => {
  const { type, error, payload } = action;
  switch (type) {
    case RedemptionActionTypes.REDEEMED: {
      if (error) {
        return state;
      }
      const { redemptionRateLimits } = payload;
      return {
        ...state,
        ...userCooldownStateProperties(redemptionRateLimits, undefined, state.countdown),
      };
    }
    case PageActionTypes.RECEIVED: {
      if (error) {
        return state;
      }
      const { cooldown, userCooldownStatus } = payload;

      return {
        cooldown: { ...cooldown },
        ...userCooldownStateProperties(userCooldownStatus, cooldown),
      };
    }
    case CooldownActionTypes.UPDATED_SUCCESS: {
      return {
        cooldown: { ...payload },
        ...userCooldownStateProperties(undefined, payload),
      };
    }
    case CooldownActionTypes.RESET_SUCCESS: {
      const { cooldown } = state;
      return {
        cooldown,
        ...userCooldownStateProperties(undefined, cooldown),
      };
    }
    case CooldownActionTypes.COOLDOWN_RECALCULATED: {
      if (!state.userCooldownStatus) {
        return state;
      }

      const userCooldownStatus = normalizer.userCooldownStatus(state.userCooldownStatus);
      return {
        ...state,
        userCooldownStatus,
        userCooldownResetAt: normalizer.userCooldownResetAt(userCooldownStatus),
      };
    }
    case PageActionTypes.CHANGED:
      return {};
    default:
      return state;
  }
};
