import { useCallback } from 'react';
import { useOverloadedContentActions } from 'services/overloaded-content';
import loadable from 'components/dynamic-import';

const AuthDialog = loadable(() => import('./AuthDialog'));

interface UseAuthDialogProps {
  componentKey: string;
  infoMessage?: string;
  location: string;
  redirectTo?: string;
}

interface UseAuthDialog {
  showAuthDialog: () => void;
}

export const useAuthDialog = ({
  infoMessage,
  location,
  componentKey,
  redirectTo,
}: UseAuthDialogProps): UseAuthDialog => {
  const { openOverloadedContent } = useOverloadedContentActions();
  const showAuthDialog = useCallback(() => {
    openOverloadedContent({
      component: AuthDialog,
      componentProps: {
        infoMessage,
        location,
        componentKey,
        redirectTo,
      },
    });
  }, [openOverloadedContent, infoMessage, location, componentKey, redirectTo]);

  return {
    showAuthDialog,
  };
};
