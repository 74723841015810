import ClassNames from '@streamloots/classnames';
import FloatingAlert from 'ui-library/floating-image-alert';
import { Trans, useTranslation } from 'utils/translation';
import BuyLootPacksPicker from 'components/buy-loot-packs-picker';
import { FlashCouponTimeLeft } from './FlashCouponTimeLeft';
import NEW_USER_COUPONS from './assets/chipi-coupons.png';
import { useNewBuyerStreamerCoupon } from './useNewBuyerStreamerCoupon';
import theme from './flash-coupons.scss';

const classNames = ClassNames(theme);

export const NewBuyerStreamerCoupon = (): JSX.Element | null => {
  const { t } = useTranslation('common');
  const { expiresAt, applyCoupon, newBuyerCouponCode, capitalizedSlug, show, hideCoupon } = useNewBuyerStreamerCoupon();

  return (
    <FloatingAlert
      title={t('common:newUserCoupon.title')}
      description={
        <>
          <Trans parent="p" i18nKey="common:newUserCoupon.description">
            Use this code to get a discount on your first purchase in <strong>{capitalizedSlug}</strong>
          </Trans>
          <p>
            {t('common:flashCoupons.code')}
            <strong className={classNames('flash-coupon__code')}>{newBuyerCouponCode}</strong>
          </p>
          {expiresAt && <FlashCouponTimeLeft couponExpiresAt={expiresAt} t={t} />}
        </>
      }
      img={NEW_USER_COUPONS}
      show={show}
      onClose={hideCoupon}
    >
      <BuyLootPacksPicker
        buttonId="coupon new user"
        label={t('common:newUserCoupon.applyDiscount')}
        onClick={applyCoupon}
        size="sm"
      />
    </FloatingAlert>
  );
};
