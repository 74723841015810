import { Link } from 'react-router-dom';
import ClassNames from '@streamloots/classnames';
import Icon from 'ui-library/icons';
import Button, { BaseButtonProps } from 'ui-library/button';
import { NewLabel } from 'ui-library/new-indicator';
import { ReactNode } from 'react';
import theme from './alerts-and-widgets.scss';

const classNames = ClassNames(theme);

interface OtherPanelProps {
  icon: string;
  title: string;
  description: string;
  url: string;
  buttonLabel: string;
  isNew?: boolean;
  renderDisabled?: () => ReactNode;
  disabled?: boolean;
  isExternalUrl?: boolean;
}

export const OtherPanel = ({
  icon,
  title,
  description,
  buttonLabel,
  url,
  isNew,
  disabled,
  renderDisabled,
  isExternalUrl,
}: OtherPanelProps): JSX.Element => {
  const buttonProps: Partial<BaseButtonProps> = isExternalUrl
    ? {
        component: 'a',
        target: '_blank',
        href: url,
      }
    : {
        component: Link,
        to: url,
      };

  return (
    <div
      className={classNames({
        'widgets__panel': true,
        'widgets__panel--disabled': disabled,
      })}
    >
      <div
        className={classNames({
          'widgets__others__panel': true,
          'widgets__others__panel--disabled': disabled,
        })}
      >
        <Icon className={classNames('widgets__others__icon')} id={icon} />
        <div className="margin-bottom">
          <h2 className={classNames('widgets__others__title')}>{title}</h2>
          {isNew && (
            <span className={classNames('widgets__help')}>
              <NewLabel />
            </span>
          )}
        </div>
        <div className={classNames('widgets__others__content')}>
          <p>{description}</p>
        </div>
        <Button
          weird
          block
          className={classNames('widgets__others__cta widgets__cta')}
          label={buttonLabel}
          {...buttonProps}
        />
      </div>
      {disabled && renderDisabled && renderDisabled()}
    </div>
  );
};
