import React from 'react';
import Panel from './Panel';
import theme from './panel.scss';

type PanelSkeletonsProps = {
  count?: number;
  gridMode?: boolean;
};

const PanelSkeletons = ({ count = 2, gridMode }: PanelSkeletonsProps): JSX.Element => {
  const panelsCount = new Array(count);
  const panels = [...panelsCount.keys()];

  return (
    <div className={gridMode ? theme['panel-skeletons'] : ''}>
      {panels.map(key => (
        <Panel className="margin-bottom-x2" key={key} skeletonMode title="Loading panel..." />
      ))}
    </div>
  );
};

export default PanelSkeletons;
