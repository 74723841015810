import loadable from 'components/dynamic-import';
import { StreamersCardsSkeleton } from 'components/streamer-card';
import { checkPermissions, permissions } from 'components/permissions';

import { FullLayout } from 'scenes/layout';
import { LogInToDiscover } from './LogInToDiscover';

const UserCollections = loadable(() => import('./UserCollections'), true, <StreamersCardsSkeleton includeWrapper />);
const Discover = loadable(() => import('./components/recommended'), false);

const UserCollectionsPageInternal = () => {
  return (
    <>
      <FullLayout>
        <UserCollections />
        <Discover />
      </FullLayout>
    </>
  );
};

export const UserCollectionsPage = checkPermissions({
  requiredPermissions: permissions.USER_AUTHENTICATED,
  fallbackComponent: () => <LogInToDiscover />,
})(UserCollectionsPageInternal);
