import type { LayoutState, LayoutStoreState } from './types';

const layoutState = (state: LayoutStoreState): LayoutState => state.layout;

// Global checker used for showing/hiding components such as a mobile menu
// If you want to check the *device* instead, use the 'is-mobile' npm package
const isMobile = (state: LayoutStoreState): boolean => Boolean(layoutState(state).isMobile);

const isIOS = (state: LayoutStoreState): boolean | undefined => layoutState(state).isIOS;

export const layoutSelectors = {
  layoutState,
  isMobile,
  isIOS,
};
