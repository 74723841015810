import type { Dispatch } from '@reduxjs/toolkit';
import { JwtPayload } from 'jwt-decode';
import { AsyncComponentState } from 'types/indexTS';
import type { DisconnectUserAction } from './services/disconnection';
import { FetchTokensAction, PostBidSuccessAction, PostTokenRequest } from './services/tokens/types';

export enum SCOPE_TOKENS {
  STREAMDECK = 'STREAMDECK',
}

type ScopeTokensType = typeof SCOPE_TOKENS;
export type SCOPE_TOKENS_VALUES = keyof ScopeTokensType;

export type FetchAndCreateTokensWithScope = (
  scope: SCOPE_TOKENS,
) => (dispatch: Dispatch) => Promise<FetchTokensAction | PostBidSuccessAction>;

export type AuthActionCreators = {
  disconnectUser: () => (dispatch: Dispatch) => DisconnectUserAction;
  fetchAndCreateTokensWithScope: FetchAndCreateTokensWithScope;
};

export type TestScopeFunction = (scope: string) => boolean;

export type RecordScopeTestFunctions = Record<SCOPE_TOKENS_VALUES, TestScopeFunction>;

export type RecordScopeTokensRequest = Record<SCOPE_TOKENS_VALUES, PostTokenRequest>;

export interface AuthState extends AsyncComponentState {
  authToken?: string;
  invalidateAuthToken?: boolean;
}

export interface AuthStoreState {
  auth: AuthState;
}

export interface JWTPayloadWithScopes extends JwtPayload {
  scope: string;
}

export type DisconnectUser = () => void;
