export const PAGE_SECTIONS_MAPPING = {
  admin: 'ADMINISTRATION',
  alerts: 'ALERTS',
  billing: 'BILLING_CONFIGURATION',
  billing_conf: 'BILLING_INFORMATION_CONFIGURATION',
  invoices: 'INVOICES',
  card_list: 'CARD_LIST',
  configure_page: 'CONFIGURE_PAGE',
  configure: 'CONFIGURE_GENERAL',
  goals: 'GOALS',
  dashboard: 'DASHBOARD',
  disenchant: 'DISENCHANT_CONFIGURATION',
  disenchant_customization: 'DISENCHANT_CUSTOMIZATION',
  manage_collections: 'MANAGE_COLLECTIONS',
  orders: 'ORDERS',
  packs_configuration: 'PACKS_AND_PRICES',
  rankings: 'RANKINGS',
  redeem: 'ACTIVITY_FEED', //I don't know if this redirections is used in some case so this way we keep retrocompatibility
  revenue: 'REVENUE',
  probabilities: 'UPDATE_PROBABILITIES',
  user_page: 'USER_PAGE',
  main_collection: 'USER_PAGE_COLLECTION',
  coupons: 'PAGE_COUPONS',
  moderators: 'MODERATORS',
  user_cooldown: 'CONFIGURE_COOLDOWN',
  referral: 'REFERRAL',
  transactions: 'TRANSACTIONS',
  bans: 'BANNED_USERS',
  add_predefined_card: 'ADD_DEFAULT_CARDS',
  originals: 'ORIGINALS',
  weekly_report: 'WEEKLY',
  user_collections: 'USER_COLLECTIONS',
  powers: 'POWERS',
  achievements: 'ACHIEVEMENTS',
  timer: 'TIMER',
  widgets: 'WIDGETS',
  streamdeck: 'STREAMDECK',
  chatbot: 'CHAT_BOT',
  auctions: 'AUCTIONS',
  activity_feed: 'ACTIVITY_FEED',
  streamer_ranks: 'COMMUNITY_RANKING',
  page_subscriptions: 'PAGE_SUBSCRIPTIONS',
  marathon: 'MARATHON',
  packs_goals: 'GOALS_PACKS',
  subs_goals: 'GOALS_SUBS',
  my_community: 'MY_VIEWERS_COMMUNITY',
  my_feed: 'USER_PAGE_FEED',
  subscription: 'USER_PAGE_SUBSCRIPTION',
};
