import { ActionSuccess, ActionError, AsyncActionState } from 'types/indexTS';
import { PublicOriginalCard, PublicOriginalCardFromApi, PublicOriginalFrame } from 'model/indexTS';
import { OriginalsPublicActionTypes } from './actionTypes';

export const PUBLIC_ORIGINALS_LANDING_COMPONENT_KEY = 'publicOriginalsLanding';

export interface FetchPublicOriginalsResponseSuccess {
  templates: PublicOriginalCardFromApi[];
  frame: PublicOriginalFrame;
}

export type OriginalsCardsPublicFilters = {
  language?: string;
};

export interface OriginalsPublicActionMetadata {
  componentKey: string;
}

export type FetchPublicOriginalsCardsActionRequest = ActionSuccess<
  OriginalsPublicActionTypes.FETCH_PUBLIC_ORIGINALS_REQUEST,
  {
    filters: OriginalsCardsPublicFilters;
  },
  OriginalsPublicActionMetadata
>;
export type FetchPublicOriginalsCardsActionError = ActionError<
  OriginalsPublicActionTypes.FETCH_PUBLIC_ORIGINALS_ERROR,
  OriginalsPublicActionMetadata
>;
export type FetchPublicOriginalsCardsActionSuccess = ActionSuccess<
  OriginalsPublicActionTypes.FETCH_PUBLIC_ORIGINALS_SUCCESS,
  {
    filters: OriginalsCardsPublicFilters;
    originals: PublicOriginalCard[];
    frame?: PublicOriginalFrame;
  },
  OriginalsPublicActionMetadata
>;

export type FetchOriginalsPublicActionResponses =
  | FetchPublicOriginalsCardsActionError
  | FetchPublicOriginalsCardsActionSuccess;
export type OriginalsPublicActions = FetchPublicOriginalsCardsActionRequest | FetchOriginalsPublicActionResponses;

export interface OriginalsPublicStateByComponentKey extends AsyncActionState {
  filters?: OriginalsCardsPublicFilters;
  originals?: PublicOriginalCard[];
  frame?: PublicOriginalFrame;
}

export type OriginalsPublicState = Record<string, OriginalsPublicStateByComponentKey>;

export interface FetchPublicOriginalsArgs {
  componentKey: string;
  filters: OriginalsCardsPublicFilters;
  frameId: string;
}

export type FetchPublicOriginalsCards = (
  args: FetchPublicOriginalsArgs,
) => Promise<FetchOriginalsPublicActionResponses>;
