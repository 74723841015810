import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InterfaceWithReturnType } from 'types/indexTS';
import { cultureActions } from './actions';
import { cultureSelectors } from './selectors';

export type UseCultureActions = typeof cultureActions;
export type UseCultureSelectors = InterfaceWithReturnType<typeof cultureSelectors>;

export const useCultureSelectors = (): UseCultureSelectors => {
  const IPCountry = useSelector(cultureSelectors.IPCountry);
  const language = useSelector(cultureSelectors.language);

  return {
    IPCountry,
    language,
  };
};

export const useCultureActions = (): UseCultureActions => {
  const dispatch = useDispatch();
  return useMemo(() => {
    return {
      getHeartbeat: () => dispatch(cultureActions.getHeartbeat()),
    };
  }, [dispatch]);
};
