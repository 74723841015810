export enum PageCommunicationActionTypes {
  FETCHING = 'pageCommunication/FETCHING',
  RECEIVED = 'pageCommunication/RECEIVED',
  FETCH_ERROR = 'pageCommunication/FETCH_ERROR',
  SEND_EMAIL = 'pageCommunication/SEND_EMAIL',
  EMAIL_SENT = 'pageCommunication/EMAIL_SENT',
  EMAIL_SENT_ERROR = 'pageCommunication/EMAIL_SENT_ERROR',
  MANAGE_COMMUNICATION = 'pageCommunication/MANAGE_COMMUNICATION',
  MANAGED_COMMUNICATION = 'pageCommunication/MANAGED_COMMUNICATION',
  MANAGED_COMMUNICATION_ERROR = 'pageCommunication/MANAGED_COMMUNICATION_ERROR',
}
