import stateCreator from 'helpers/stateCreator';
import { EmotesStylesActionTypes } from '../emotes-styles/actionTypes';
import { PageActionTypes } from '../page';
import { PageSubscriptionPlansActionTypes } from './actionTypes';
import { PageSubscriptionPlansActions, PageSubscriptionPlansState } from './types';

const initialState: PageSubscriptionPlansState = {};

export const reducer = (state = initialState, action: PageSubscriptionPlansActions): PageSubscriptionPlansState => {
  switch (action.type) {
    case PageSubscriptionPlansActionTypes.GET_PAGE_SUBSCRIPTION_PLANS_REQUEST: {
      return {
        ...state,
        ...stateCreator.getAsyncLoadingState(state),
      };
    }

    case PageSubscriptionPlansActionTypes.GET_PAGE_SUBSCRIPTION_PLANS_SUCCESS: {
      const newState = {
        subscriptionPlan: { ...action.payload },
      };
      return stateCreator.getInitialAsyncState(state, newState);
    }
    case PageSubscriptionPlansActionTypes.PUT_PAGE_SUBSCRIPTION_PLANS_SUCCESS: {
      const newState = {
        ...state,
        subscriptionPlan: {
          ...action.payload,
          ...(!!state.subscriptionPlan && { alerts: state.subscriptionPlan.alerts }),
        },
      };
      return stateCreator.getInitialAsyncState(state, newState);
    }

    case PageSubscriptionPlansActionTypes.GET_PAGE_SUBSCRIPTION_PLANS_ERROR:
    case PageSubscriptionPlansActionTypes.PUT_PAGE_SUBSCRIPTION_PLANS_ERROR: {
      return stateCreator.getAsyncErrorState(action, state);
    }

    case EmotesStylesActionTypes.UPDATE_EMOTES_STYLES_SUCCESS: {
      const newState = {
        ...state,
        subscriptionPlan: {
          ...action.payload,
        },
      };

      return stateCreator.getInitialAsyncState(state, newState);
    }

    case PageActionTypes.CHANGED: {
      return initialState;
    }

    default:
      return state;
  }
};
