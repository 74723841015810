import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import theme from './dialog-layout.scss';

const classNames = ClassNames(theme);

const DialogLayout = ({ children }: { children: React.ReactNode }) => {
  return <div className={classNames('dialog-layout')}>{children}</div>;
};

const DialogLayoutContent = ({ children }: { children: React.ReactNode }) => {
  return <div className={classNames('dialog-layout__content')}>{children}</div>;
};

DialogLayout.Content = DialogLayoutContent;

export default DialogLayout;
