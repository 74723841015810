import * as React from 'react';
import Helmet from 'react-helmet-async';
import { OGP_DEFAULT_IMAGE } from 'constants/images';
import config from '../../config';

type MetaTagsProps = {
  children?: React.ReactNode;
  title?: string;
  description?: string;
  imageUrl?: string;
  imageWidth?: number;
  imageHeight?: number;
  urlPath?: string;
  robots?: string;
};

const MetaTags = ({
  title = 'Streamloots - The no.1  monetization platform for Creators and their fans',
  description = 'Streamloots is the platform that connects content creators with their fans. Make a living doing what you love using Streamloots solutions, have fun and get the support of your community 🚀',
  imageUrl = OGP_DEFAULT_IMAGE,
  imageWidth = 1200,
  imageHeight = 630,
  urlPath = '',
  robots = '',
  children,
}: MetaTagsProps): JSX.Element => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:url" content={`${config.ogUrl}${urlPath}`} />
      <meta property="description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image:width" content={imageWidth.toString()} />
      <meta property="og:image:height" content={imageHeight.toString()} />
      <meta data-testid="helmet-og-image" property="og:image" content={imageUrl} />
      <meta name="description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@streamloots" />
      <meta name="twitter:title" content="Streamloots" />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:description" content={description} />
      {robots && <meta name="robots" content={robots} />}
      {children}
    </Helmet>
  );
};

export default MetaTags;
