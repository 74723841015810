import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { DropdownMenuPosition } from './types';
import theme from './dropdown-menu.scss';

const classNames = ClassNames(theme);

type DropdownMenuContentProps = {
  className?: string;
  colorBg?: 'grey';
  children: React.ReactNode;
  closeDropdown: (event: any) => void;
  position?: DropdownMenuPosition;
  closeOnClick?: boolean;
};

export const DropdownMenuContent = React.forwardRef<HTMLDivElement, DropdownMenuContentProps>(
  (
    {
      className = '',
      children,
      closeOnClick,
      colorBg,
      closeDropdown,
      position = DropdownMenuPosition.BOTTOM_LEFT,
    }: DropdownMenuContentProps,
    ref,
  ) => {
    const handleClick = event => {
      if (closeOnClick) {
        closeDropdown(event);
      }
    };

    return (
      <div
        className={classNames({
          menu__content: true,
          [`menu__content--${colorBg ?? ''}`]: colorBg,
          [className]: Boolean(className),
          menu__bottom__left: position === DropdownMenuPosition.BOTTOM_LEFT,
          menu__bottom__right: position === DropdownMenuPosition.BOTTOM_RIGHT,
          menu__top__left: position === DropdownMenuPosition.TOP_LEFT,
          menu__top__right: position === DropdownMenuPosition.TOP_RIGHT,
        })}
        ref={ref}
        onClick={handleClick}
      >
        {children}
      </div>
    );
  },
);
