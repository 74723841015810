import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import Icon from 'ui-library/icons/Icons';
import theme from './radio-button-card.scss';

const classNames = ClassNames(theme);

type RadioButtonIconProps = {
  selectable: boolean;
  checked: boolean;
};
export const RadioButtonIcon = ({ selectable, checked }: RadioButtonIconProps) => {
  return (
    <span
      className={classNames({
        'radio-button-card__circle': true,
        'radio-button-card__circle--not-selectable': !selectable,
        'radio-button-card__circle--not-selectable--selected': !selectable && checked,
        'radio-button-card__circle--selected': selectable && checked,
      })}
    >
      {!selectable && <Icon id="close" />}
      {selectable && checked && <Icon id="check" />}
    </span>
  );
};
