import { positions, transitions } from 'react-alert';
import type { WithAlertProviderProps } from './withAlertProvider';
import type { ReactAlertInjectedProps } from './AlertTemplate';
import ReactAlertProvider, { useReactAlert } from './ReactAlertProvider';
import withAlertProvider from './withAlertProvider';

export default ReactAlertProvider;
export { useReactAlert, withAlertProvider, positions, transitions };

export type { ReactAlertInjectedProps, WithAlertProviderProps };
