import stateCreator from 'helpers/stateCreator';
import { DonationsActionTypes } from './actionTypes';
import { DonationsActions, DonationsState } from './types';

const initialState: DonationsState = {};

export const reducer = (state: DonationsState = initialState, action: DonationsActions): DonationsState => {
  switch (action.type) {
    case DonationsActionTypes.FETCHING:
      return stateCreator.getAsyncLoadingState(state);

    default:
      return state;
  }
};
