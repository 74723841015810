import type { VoicesReceivedAction, VoicesReceivedActionSuccess, VoicesState } from './types';
import { VoicesActionTypes } from './actionTypes';

export const voicesReducer = (state: VoicesState = {}, action: VoicesReceivedAction): VoicesState => {
  switch (action.type) {
    case VoicesActionTypes.FETCH_SUCCESS: {
      const {
        payload: { voices },
      } = action as VoicesReceivedActionSuccess;

      return {
        voices: Object.keys(voices).map(voiceKey => ({
          value: voiceKey,
          label: voices[voiceKey],
        })),
      };
    }
    default:
      return state;
  }
};
