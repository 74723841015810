import { AuthProviders, PageType } from '@streamloots/streamloots-types';
import config from 'config';
import { saveMarketingAutomationEvent } from 'services/marketing-automation/useMarketingAutomation';
import eventsHelper from '../eventsHelpers';
import { PageSchema, SnowplowSchemas, UserSchema } from './schemas';

export class SnowplowTracker {
  trackSelfDescribingEvent(schema: string, data: Record<string, unknown>, includePage: boolean): void {
    const normalizeData = this.normalizeData(data);
    const pageContext = this.getPageContext();
    const userContext = this.getUserContext();
    saveMarketingAutomationEvent({
      eventData: normalizeData,
      name: schema,
      type: 'snowplow',
      ...(includePage && pageContext && { page: pageContext.data.page_id }),
      ...(!!userContext?.data.user_id && { userId: userContext.data.user_id }),
    });
  }

  private normalizeData(data: Record<string, unknown>): Record<string, unknown> {
    return {
      ...data,
      app_version: config.app.version,
      app_language: eventsHelper.getPageLanguage(),
    };
  }

  private getPageContext() {
    const pageData = eventsHelper.getCurrentPageData();

    if (!pageData) {
      return null;
    }

    const data: PageSchema = {
      page_id: pageData.pageId,
      owner_id: pageData.ownerId,
      seller_id: pageData.sellerId,
      page_type: pageData.pageType as PageType,
      is_published: pageData.isPublished,
      language: pageData.language,
      provider: pageData.provider as AuthProviders,
    };

    return {
      schema: SnowplowSchemas.Page,
      data,
    };
  }

  private getUserContext() {
    const userData = eventsHelper.getCurrentUserData();

    if (!userData) {
      return null;
    }

    const data: UserSchema = {
      user_id: userData.userId,
      username: userData.username,
      country: userData.country,
      usage_intention: userData.usageIntention,
    };

    return {
      schema: SnowplowSchemas.User,
      data,
    };
  }
}
