import { Event, EventHint } from '@sentry/types';
import { ApiError } from 'types/indexTS';

export type SentryEvent = Event;

export type SentryEventHint = EventHint & {
  originalException: string | Error | null | undefined | ApiError;
};

/**
 * @param {Sentry.Event} event - A wrapper for the original error.
 * @param {Sentry.EventHint} hint - Contains the original error.
 */
export function filterError(event: SentryEvent, hint: SentryEventHint): Event | null {
  // Filter API errors
  if (event.tags && event.tags.ApiError) {
    const originalException = hint.originalException as ApiError;
    if (!originalException) {
      return event;
    }
    if (originalException?.message !== 'Validation Error') {
      return null;
    }

    if (event?.exception?.values) {
      // eslint-disable-next-line no-param-reassign
      const errorsMessage = originalException.errors && originalException.errors[0]?.message;
      event.exception.values[0].value = `${originalException.message} - ${errorsMessage || ''}`;
    }
  }

  const error = hint.originalException ? hint.originalException : event.message;

  // A guard against our own mistakes, ie when we capture undefined or the like
  if (!error || (typeof error !== 'string' && typeof error.message !== 'string')) {
    return event;
  }

  // TODO Solve not knowing if we are going to receive a string or an Error
  const message = typeof error === 'string' ? error : error.message;

  // Filter the messages we don't want in Sentry.
  const filter =
    RegExp(/loading css chunk/i).test(message) ||
    RegExp(/loading chunk/i).test(message) ||
    RegExp(/target window is closed/i).test(message) ||
    RegExp(/includes is not a function/i).test(message);

  if (filter) {
    return null;
  }

  return event;
}
