import { paymentActionTypes, PaymentActions } from 'services/payment';
import { PromotionsActions, PromotionsState } from './types';
import { actionTypes } from './actionTypes';

export const getInitialState = (): PromotionsState => ({
  tickets: { asStreamer: 0, asViewer: 0, reload: true },
});

export default (
  state: PromotionsState = getInitialState(),
  action: PromotionsActions | PaymentActions,
): PromotionsState => {
  const { type, payload } = action;
  switch (type) {
    case paymentActionTypes.STRIPE_ORDER_EXECUTED:
    case paymentActionTypes.EXECUTED:
      return {
        ...state,
        tickets: {
          ...state.tickets,
          reload: true,
        },
      };
    case actionTypes.GET_PROMOTIONS_SUCCESS:
      return {
        ...state,
        tickets: {
          reload: false,
          asStreamer: payload.asStreamer.lootsSold,
          asViewer: payload.asViewer.lootsBought,
        },
      };
    case actionTypes.GET_CLAIMABLE_PROMOTION_SUCCESS:
    case actionTypes.POST_CLAIMABLE_PROMOTION_SUCCESS: {
      const { promotionName } = (action.metadata as unknown) as { promotionName: string };
      return {
        ...state,
        promotionsClaimable: {
          ...state.promotionsClaimable,
          [promotionName]: (payload as unknown) as boolean,
        },
      };
    }
    default:
      return state;
  }
};
