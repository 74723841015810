import { AuthProviders } from '@streamloots/streamloots-types';
import config from 'config';
import urlUtils from 'utils/urlUtils';
import trackEventManager from 'utils/event-manager';
import { redirectToCookieProvider } from 'services/routing';
import endpoints from 'services/endpoints';
import { ProviderButton } from './ProviderButton';

type AuthProviderProps = {
  className?: string;
  componentKey?: string;
  location?: string;
  label: string;
  redirectTo?: string;
  provider: AuthProviders;
  noTextOnSmallRes?: boolean;
  icon: string;
};

export const AuthProviderButton = ({
  location,
  redirectTo,
  provider,
  className,
  label,
  noTextOnSmallRes,
  icon,
}: AuthProviderProps): JSX.Element => {
  const handleClick = () => {
    redirectToCookieProvider.setPathname(redirectTo);

    if (location) {
      trackEventManager.signInProviderClicked({ location, button: provider });
    }

    const params = {
      client_id: config.streamloots.clientId,
      redirect_uri: config.streamloots.redirectUri,
      response_type: 'token',
      provider,
    };
    const queryString = urlUtils.paramsToQueryStream(params);
    window.location.href = `${config.streamloots.api}${endpoints.ACCESS.OAUTH}${queryString}`;
  };

  return (
    <ProviderButton
      className={className}
      label={label}
      onClick={handleClick}
      noTextOnSmallRes={noTextOnSmallRes}
      icon={icon}
    />
  );
};
