import { Rarity, StrictOmit } from '@streamloots/streamloots-types';
import { CardCountFragmented, CardCountNormal, CardFile, CARD_TYPE } from './cardTS';
import { CardIntegrations, Integrations } from './integrations';
import { RedeemField } from './redeemFieldTS';
import { RewardField } from './rewardFieldTS';

export enum CARD_STATUS {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED',
  DRAFT = 'DRAFT',
}

export type CardStatus = CARD_STATUS;

export type CardRedemptionLimit = {
  configuration: {
    timeFrameSeconds: number;
  };
  resetAt: string;
};

type CardUsageStatisticsNormal = {
  consumedCount: number;
  disenchantedCount: number;
  redeemableCount: number;
  redeemedCount: number;
  total: number;
};

type CardUsageStatisticsFragmented = {
  consumedCount: number;
  disenchantedCount: number;
  redeemableCount: number;
  redeemedCount: number;
  total: number;
  availableFragmentsCount: number;
};

export interface RestResponseCardIntegrations extends StrictOmit<CardIntegrations, Integrations.MARATHON> {
  [Integrations.MARATHON]?: {
    increment: number;
  };
}

type BaseOwnerCard = {
  _id: string;
  actionType: CARD_TYPE;
  autocomplete: boolean;
  craftable: boolean;
  craftingCost?: number;
  createdAt: string;
  description: string;
  descriptionDetailed?: string;
  disenchantingReward?: number;
  dropLimit?: number;
  dropLimited: boolean;
  dropLimitRemaining?: number;
  imageUrl: string;
  modifiedAt?: string;
  name: string;
  normalizedName: string;
  normalizedDescription: string;
  order: number;
  paid: boolean;
  rarity: Rarity;
  rarityCardProbability: number;
  redeemable: boolean;
  redeemFields?: Array<RedeemField>;
  redemptionSuccessMessage?: string;
  redemptionLimit: CardRedemptionLimit;
  rewardFields?: Array<RewardField>;
  setId: string;
  status: CardStatus;
  obtainable: boolean;
  imageFile?: CardFile;
  integrations?: RestResponseCardIntegrations;
  duration: number;
  templateId?: string;
};

export interface NormalOwnerCard extends BaseOwnerCard {
  fragmented: false;
  fragments?: never;
  usageStatistics: CardUsageStatisticsNormal;
  count?: CardCountNormal;
}

export interface FragmentedOwnerCard extends BaseOwnerCard {
  fragmented: true;
  fragments: number;
  usageStatistics: CardUsageStatisticsFragmented;
  count?: CardCountFragmented;
}

export type OwnerCard = NormalOwnerCard | FragmentedOwnerCard;

export type OwnerCardsById = Record<string, OwnerCard>;
