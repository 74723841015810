import { createSelector } from 'reselect';
import { Languages } from '@streamloots/streamloots-types';
import { mapTagsToAsyncAutocompleteByLanguage } from 'model/tag';
import type { Set, SetBasicInfo, SetRarities, SetStatusInfo } from 'model/indexTS';
import type { SetEditableInfo } from './services/update-set';
import type { PublishSetStatus } from './services/publish-set';
import type { SetsStatus, SetListState, SetImagesByUrl } from './services/set-list';
import type { SetsState } from './types';
import { MarathonSpecialSetSelectorOutput } from './types';

const setsState = (state): SetsState => state.sets;

const setListState = (state): SetListState => setsState(state).list;

const publishSetState = (state): PublishSetStatus => setsState(state).publish;

const setsStatus = createSelector(
  [setListState],
  (setListStateInfo: SetListState): SetsStatus => {
    const { sets, isLoading, error } = setListStateInfo;

    return {
      sets,
      isLoading,
      error,
    };
  },
);

const set = createSelector(
  [setListState, (_state, setId: string) => setId],
  (setListStateInfo: SetListState, setId?: string): Set | null => {
    const { selectedSetId = '', setsById = {} } = setListStateInfo;
    const setInfo = setsById[setId || selectedSetId];
    return setInfo || null;
  },
);

const selectedSet = createSelector([setListState], (setListStateInfo: SetListState): Set | null => {
  const { selectedSetId = '', setsById = {} } = setListStateInfo;
  const setInfo = setsById[selectedSetId];
  return setInfo || null;
});

const rarities = createSelector([set], (setInfo: Set | null): SetRarities | null => {
  return setInfo?.rarities || null;
});

const isCraftingConfigurationInitialized = createSelector([set], (setInfo: Set | null): boolean => {
  if (!setInfo) {
    return false;
  }

  const { rarities: setRarities } = setInfo;
  if (!setRarities) {
    return false;
  }

  const anyRarityWithoutConfiguration = Object.values(setRarities).some(
    rarity =>
      typeof rarity.defaultDisenchantingReward === 'undefined' || typeof rarity.defaultCraftingCost === 'undefined',
  );
  return !anyRarityWithoutConfiguration;
});

const published = createSelector([set], (setInfo: Set | null): boolean =>
  setInfo !== null ? setInfo.published : false,
);

const setBasicInfo = createSelector([set], (setInfo: Set | null): SetBasicInfo | null => {
  if (!setInfo) {
    return null;
  }

  const { default: defaultSet, name, craftableCards, imageUrl, order } = setInfo;
  return {
    default: defaultSet || false,
    name,
    craftableCards,
    imageUrl,
    order,
  };
});

const setEditableInfo = createSelector(
  [set, (_state, _setId, language: Languages) => language],
  (setInfo: Set | null, language: string): SetEditableInfo | null => {
    if (!setInfo) {
      return null;
    }

    const { default: defaultSet, name, craftableCards, imageUrl, tags = [], order } = setInfo;
    return {
      default: defaultSet || false,
      name,
      craftableCards,
      imageUrl,
      tags: mapTagsToAsyncAutocompleteByLanguage(tags, language),
    };
  },
);

const craftableCards = createSelector([set], setInfo => {
  if (!setInfo) {
    return false;
  }

  const { craftableCards: craftable } = setInfo;

  return craftable;
});

const status = createSelector([set], (setInfo: Set | null): SetStatusInfo | null => {
  if (setInfo === null) {
    return null;
  }

  const { paused, pausedReason, resumeAt } = setInfo;
  return { paused, pausedReason, resumeAt };
});

const updatingSet = createSelector([set], (setInfo: Set | null): boolean => Boolean(setInfo?.updating));

const sets = createSelector([setListState], (setListStateInfo: SetListState): Array<Set> | undefined => {
  const { sets: allSets } = setListStateInfo;
  if (!allSets) {
    return allSets;
  }

  return allSets.filter(setItem => !setItem.marathonDefault);
});

const marathonSpecialSet = createSelector(
  [setListState],
  (setListStateInfo: SetListState): MarathonSpecialSetSelectorOutput => {
    const { sets: allSets } = setListStateInfo;
    if (!allSets) {
      return undefined;
    }

    return allSets.find(setItem => setItem.marathonDefault) || null;
  },
);

const hasLoadedSets = createSelector([sets], (setsInfo: Array<Set> | undefined): boolean => Array.isArray(setsInfo));

const hasSets = createSelector([sets], (setsInfo: Array<Set> | undefined): boolean => Boolean(setsInfo?.length));

const setsPublishedCount = createSelector(
  [setListState],
  (setListStateInfo: SetListState) => setListStateInfo.setsPublishedCount || 0,
);

const setsCount = createSelector([sets], (setsInfo: Array<Set> | undefined): number => setsInfo?.length || 0);

const setId = createSelector(
  [setListState],
  (setListStateInfo: SetListState): string => setListStateInfo.selectedSetId || '',
);

const defaultSetId = createSelector(
  [setListState],
  (setListStateInfo: SetListState): string => setListStateInfo.defaultSetId || '',
);

const setsFilteredById = createSelector(
  [setListState, (_state, setIds) => setIds],
  (setListStateInfo: SetListState, setIds: Array<string>): Array<Set> | null => {
    const { sets: currentSets } = setListStateInfo;
    if (setIds.length === 0 || !currentSets) {
      return null;
    }

    return currentSets.filter(currSet => setIds.indexOf(currSet._id) !== -1);
  },
);

const setImagesByUrl = createSelector(
  [setListState],
  (setListStateInfo: SetListState): SetImagesByUrl => setListStateInfo.setImagesByUrl || {},
);

const setsById = createSelector([setListState], (setListStateInfo: SetListState) => {
  return setListStateInfo.setsById || {};
});

export const setsSelectors = {
  setListState,
  defaultSetId,
  hasLoadedSets,
  published,
  publishStatus: publishSetState,
  rarities,
  set,
  hasSets,
  selectedSet,
  setBasicInfo,
  setEditableInfo,
  setId,
  sets,
  setsPublishedCount,
  setsCount,
  setsStatus,
  status,
  updatingSet,
  setImagesByUrl,
  setsFilteredById,
  isCraftingConfigurationInitialized,
  craftableCards,
  setsById,
  marathonSpecialSet,
};
