import { Currency } from '@streamloots/streamloots-types';
import type { DropdownOption } from 'types/indexTS';
import { I18nType } from 'utils/translation';
import languageManager from './languageManager';

export const ACCEPTED_CURRENCIES: Array<DropdownOption> = [
  {
    label: '$ USD Dollar',
    value: Currency.USD,
  },
  {
    label: '€ Euro',
    value: Currency.EUR,
  },
];

const acceptedCurrencies: Array<string> = ACCEPTED_CURRENCIES.map(currency => currency.value);

export default {
  isAcceptedCurrency: (currency: string): boolean => acceptedCurrencies.indexOf(currency) > -1,
  getDefaultCurrencyByLanguage: (language: string | I18nType): Currency =>
    languageManager.isSpanish(language) ? Currency.EUR : Currency.USD,
  getSymbol: (currency: string | Currency): string => (currency === Currency.EUR ? '€' : '$'),
  getCurrencyWithSymbol: (currency: string | Currency): string => (currency === Currency.EUR ? '€ EUR' : '$ USD'),
  getFormattedPrice: (priceParams: string | number, currency: string | Currency, skipDecimals = false): string => {
    const price = Number(priceParams);
    if (Number.isNaN(price)) {
      return '';
    }
    const negativeNumber = price < 0;
    const positivePrice = negativeNumber ? price * -1 : price;
    const formattedPrice = skipDecimals ? positivePrice : (positivePrice || 0).toFixed(2);
    if (currency === 'EUR') {
      return `${negativeNumber ? '-' : ''}${formattedPrice}€`;
    }

    return `${negativeNumber ? '-' : ''}$${formattedPrice}`;
  },
};
