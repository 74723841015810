import _clean from 'lodash-clean';
import { Power } from 'model/power';
import actionCreator from 'helpers/actionCreator';
import type {
  GetEffectsSuccess,
  GetEffectsError,
  UpdateDefaultAlertEffectSuccess,
  UpdateDefaultAlertEffectError,
} from './types';
import endpoints from '../endpoints';
import { ActionTypes } from './actionTypes';
import errorMapping from './errorMapping';

const currentAlertSkipped = () => actionCreator.createAction(ActionTypes.SKIP_SUCCESS);

const currentAlertNotSkipped = ({ errorData }) =>
  actionCreator.createErrorAction(ActionTypes.SKIP_ERROR, errorData, errorMapping);

const skipCurrentAlert = (slug: string, type: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.ALERT.SKIP,
    errorBinding: currentAlertNotSkipped,
    loadingAction: ActionTypes.SKIP_REQUEST,
    parameters: _clean({ type }),
    method: 'post',
    urlParameters: { slug },
    successBinding: currentAlertSkipped,
  });

function getEffectsSuccess({ data }): GetEffectsSuccess {
  return actionCreator.createAction(ActionTypes.GET_EFFECTS_SUCCESS, data);
}

function getEffectsError({ errorData }): GetEffectsError {
  return actionCreator.createErrorAction(ActionTypes.GET_EFFECTS_ERROR, errorData, errorMapping);
}

const getEffects = () =>
  actionCreator.createAsyncAction({
    method: 'get',
    endpoint: endpoints.ALERT.EFFECTS,
    errorBinding: getEffectsError,
    loadingAction: ActionTypes.GET_EFFECTS_REQUEST,
    successBinding: getEffectsSuccess,
  });

function updateDefaultAlertEffectSuccess(payload: Power | null) {
  return (): UpdateDefaultAlertEffectSuccess => {
    return actionCreator.createAction(ActionTypes.UPDATE_DEFAULT_EFFECT_SUCCESS, payload);
  };
}

function updateDefaultAlertEffectError({ errorData }): UpdateDefaultAlertEffectError {
  return actionCreator.createErrorAction(ActionTypes.UPDATE_DEFAULT_EFFECT_ERROR, errorData, errorMapping);
}

const updateDefaultAlertEffect = (alertEffect: Power | null) => {
  const parameters = alertEffect === null ? {} : { _id: alertEffect._id };

  return actionCreator.createAsyncAction({
    endpoint: endpoints.ALERT.DEFAULT_EFFECT,
    errorBinding: updateDefaultAlertEffectError,
    loadingAction: ActionTypes.UPDATE_DEFAULT_EFFECT_REQUEST,
    method: 'put',
    parameters,
    successBinding: updateDefaultAlertEffectSuccess(alertEffect),
  });
};

const actions = {
  skipCurrentAlert,
  getEffects,
  updateDefaultAlertEffect,
};

export default actions;
