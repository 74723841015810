import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import Skeleton from 'ui-library/skeleton';
import theme from './streamer-list.scss';

const classNames = ClassNames(theme);

export const StreamerOnMenuSkeleton = (): JSX.Element => {
  return (
    <div className={classNames('streamers on')}>
      <Skeleton className={classNames('streamers__avatar')} circle width={32} />
      <div className={classNames('streamers__texts')}>
        <p className={classNames('streamers__name')}>
          <Skeleton />
        </p>
        <Skeleton height={12} />
      </div>
    </div>
  );
};
