import { ReactElement, PureComponent } from 'react';
import { StrictOmit } from '@streamloots/streamloots-types';
import type { RenderOpenerDialogBodyParams, RenderOpenerDialogParams } from './types';
import type { DialogProps } from './BaseDialog';
import { Dialog } from './Dialog';

export interface DialogOpenerProps extends StrictOmit<DialogProps, 'renderDialogBody'> {
  renderOpener: (params: RenderOpenerDialogParams) => ReactElement | null;
  renderDialogBody: (params: RenderOpenerDialogBodyParams) => ReactElement | null;
  onClose?: () => void;
  disableOverlayClick?: boolean;
  disableEscKeyDown?: boolean;
}

type DialogOpenerState = {
  active: boolean;
};

class DialogOpener extends PureComponent<DialogOpenerProps, DialogOpenerState> {
  isUnmounted = false;

  static defaultProps = {
    className: '',
    dialogClass: '',
    component: 'div',
    type: 'normal',
    dialogInitStatus: 'hidden',
  };

  state = {
    active: false,
  };

  componentWillUnmount(): void {
    this.isUnmounted = true;
  }

  handleOpenDialog = () => {
    const { active } = this.state;

    if (active || this.isUnmounted) {
      return;
    }

    this.setState({ active: true });
  };

  handleCloseDialog = () => {
    const { preventClose, onClose } = this.props;
    const { active } = this.state;

    if (this.isUnmounted || !active || preventClose) {
      return;
    }

    this.setState({ active: false });

    if (onClose) {
      onClose();
    }
  };

  render() {
    const {
      renderOpener,
      renderDialogBody,
      preventClose,
      disableEscKeyDown,
      disableOverlayClick,
      ...rest
    } = this.props;
    const { active } = this.state;
    return (
      <>
        {renderOpener({ onShow: this.handleOpenDialog })}
        <Dialog
          {...rest}
          preventClose={preventClose}
          active={active}
          onEscKeyDown={disableEscKeyDown ? undefined : this.handleCloseDialog}
          onClose={this.handleCloseDialog}
          onOverlayClick={disableOverlayClick ? undefined : this.handleCloseDialog}
          renderDialogBody={() => renderDialogBody({ onClose: this.handleCloseDialog, preventClose })}
        />
      </>
    );
  }
}

export { DialogOpener };
