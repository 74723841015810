import { CancelToken, FixMe, AsyncActionIteratorQuery } from 'types/indexTS';
import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import {
  NotificationsMarkedAsReadActionSuccess,
  NotificationsMarkedAsReadActionError,
  FetchDataActionSuccess,
  FetchDataActionError,
  FetchDataActionRequest,
} from './typesTS';
import { QUERY_STRING_TYPE_PARAM, DEFAULT_SUMMARY } from './constants';
import normalizer from './normalizer';
import { NotificationsActionTypes } from './actionTypes';

const notificationsReceived = ({ data, parameters }): FetchDataActionSuccess =>
  actionCreator.createAction(NotificationsActionTypes.RECEIVED, {
    ...data,
    query: parameters,
    summary: data.summary || DEFAULT_SUMMARY,
  });

const notificationsNotReceived = ({ errorData }): FetchDataActionError =>
  actionCreator.createErrorAction(NotificationsActionTypes.RECEIVED, errorData);

const receivingNotifications = (query, resetLoadedData): FetchDataActionRequest =>
  actionCreator.createAction(NotificationsActionTypes.FETCHING, { query, resetLoadedData });

// Except achievements
const fetchNotificationsExceptFloating = (params: AsyncActionIteratorQuery, resetLoadedData = false) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.NOTIFICATIONS.GET,
    errorBinding: notificationsNotReceived,
    loadingAction: () => receivingNotifications(params, resetLoadedData),
    method: 'get',
    parameters: {
      ...params,
      ...QUERY_STRING_TYPE_PARAM.LIST,
    },
    successBinding: notificationsReceived,
  });

const nextNotificationsReceived = ({ data }): FetchDataActionSuccess =>
  actionCreator.createAction(NotificationsActionTypes.NEXT_RECEIVED, data);

const nextNotificationsNotReceived = ({ errorData }): FetchDataActionError =>
  actionCreator.createErrorAction(NotificationsActionTypes.NEXT_RECEIVED, errorData);

const fetchNextNotifications = (nextUrl: string) =>
  actionCreator.createAsyncAction({
    absoluteEndPoint: nextUrl,
    errorBinding: nextNotificationsNotReceived,
    loadingAction: NotificationsActionTypes.NEXT_REQUEST,
    method: 'get',
    successBinding: nextNotificationsReceived,
  });

function getFloatingNotificationsSuccess({ data }: FixMe): FixMe {
  return actionCreator.createAction(
    NotificationsActionTypes.GET_FLOATING_SUCCESS,
    normalizer.getFloatingNotificationsSuccess(data.data),
  );
}

function getFloatingNotificationsError({ errorData }: FixMe) {
  return actionCreator.createErrorAction(NotificationsActionTypes.GET_FLOATING_ERROR, errorData);
}

const getFloatingNotifications = () =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.NOTIFICATIONS.GET,
    loadingAction: NotificationsActionTypes.GET_FLOATING_REQUEST,
    successBinding: getFloatingNotificationsSuccess,
    errorBinding: getFloatingNotificationsError,
    method: 'get',
    parameters: {
      limit: 3,
      ...QUERY_STRING_TYPE_PARAM.FLOATING,
      read: false, // TODO change to true.
    },
  });

const notificationsMarkedAsRead = ({ data }): NotificationsMarkedAsReadActionSuccess =>
  actionCreator.createAction(NotificationsActionTypes.MARKED_AS_READ, data);

const notificationsNotMarkedAsRead = ({ errorData }): NotificationsMarkedAsReadActionError =>
  actionCreator.createErrorAction(NotificationsActionTypes.MARKED_AS_READ, errorData);

const markAsReadNotifications = (notificationsId: Array<string>) => {
  const parameters = notificationsId.map(notificationId => {
    return {
      _id: notificationId,
      read: true,
    };
  });

  return actionCreator.createAsyncAction({
    endpoint: endpoints.NOTIFICATIONS.MARK_AS_READ,
    errorBinding: notificationsNotMarkedAsRead,
    loadingAction: NotificationsActionTypes.MARKING_AS_READ,
    parameters,
    method: 'patch',
    successBinding: notificationsMarkedAsRead,
  });
};

const floatingNotificationsMarkAsReadSuccess = ({ data }) => {
  return actionCreator.createAction(NotificationsActionTypes.FLOATING_MARK_AS_READ_SUCCESS, data.notificationsInApp);
};

const floatingNotificationsMarkAsReadError = ({ errorData }) =>
  actionCreator.createErrorAction(NotificationsActionTypes.FLOATING_MARK_AS_READ_ERROR, errorData);

const floatingNotificationsMarkAsRead = (notificationsId: Array<string>) => {
  const parameters = notificationsId.map(notificationId => {
    return {
      _id: notificationId,
      read: true,
    };
  });

  return actionCreator.createAsyncAction({
    endpoint: endpoints.NOTIFICATIONS.MARK_AS_READ,
    errorBinding: floatingNotificationsMarkAsReadError,
    loadingAction: (cancelToken: CancelToken) =>
      actionCreator.createAction(NotificationsActionTypes.FLOATING_MARK_AS_READ_REQUEST, notificationsId, cancelToken),
    parameters,
    method: 'patch',
    successBinding: floatingNotificationsMarkAsReadSuccess,
  });
};

export const notificationsActions = {
  getFloatingNotifications,
  fetchNotificationsExceptFloating,
  fetchNextNotifications,
  markAsReadNotifications,
  floatingNotificationsMarkAsRead,
};
