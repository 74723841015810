import * as React from 'react';
import { connect } from 'react-redux';
import { layoutSelectors } from 'services/layout';

type ZendeskWidgetProps = {
  isMobile: boolean;
};

const hasScript = (id: string): boolean => {
  const script = document.querySelector(`#${id}`);

  return script !== null;
};

const createScript = () => {
  const ACCOUNT_KEY = 'cda056cd-35f7-44a1-9037-ffa20423f399';

  (window as any).zESettings = {
    webWidget: {
      zIndex: 100,
    },
  };

  const script = document.createElement('script');
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${ACCOUNT_KEY}`;
  script.id = 'ze-snippet';
  script.async = true;

  document.getElementsByTagName('head')[0].appendChild(script);
};

const showSupportWidget = () => {
  const zendeskFunction =
    (window as any).zE ||
    function () {
      return null;
    };
  zendeskFunction('webWidget', 'show');
};

const hideSupportWidget = () => {
  const zendeskFunction =
    (window as any).zE ||
    function () {
      return null;
    };
  zendeskFunction('webWidget', 'hide');
};

function ZendeskWidget({ isMobile }: ZendeskWidgetProps): null {
  React.useEffect(() => {
    if (hasScript('ze-snippet') && !isMobile) {
      showSupportWidget();
    } else if (!isMobile) {
      createScript();
    }

    if (hasScript('ze-snippet') && isMobile) {
      hideSupportWidget();
    }

    return () => {
      hideSupportWidget();
    };
  }, [isMobile]);

  return null;
}

const mapStateToProps = state => {
  return {
    isMobile: layoutSelectors.isMobile(state),
  };
};

export { ZendeskWidget as ZendeskWidgetTest };

export default connect(mapStateToProps)(ZendeskWidget) as React.FunctionComponent;
