import { Redirect, RouteComponentProps } from 'react-router-dom';
import { urls } from 'services/routing';
import urlUtils from 'utils/urlUtils';
import loadable from 'components/dynamic-import';
import { SimplifiedCardSkeletons } from 'components/component-skeletons';
import { FullLayout } from 'scenes/layout';

const CreateCardFromTemplate = loadable(
  () => import('./ConnectedCreateCardFromTemplate'),
  true,
  <FullLayout>
    <SimplifiedCardSkeletons includeWrapper />
  </FullLayout>,
);

type CreateCardsFromTemplatePageProps = RouteComponentProps;

export const CreateCardsFromTemplatePage = ({ location }: CreateCardsFromTemplatePageProps): JSX.Element => {
  const { frame } = urlUtils.urlToParams(location.search) || {};

  if (!frame) {
    return <Redirect to={urls().ORIGINALS} push />;
  }

  return <CreateCardFromTemplate frameToFilterFor={frame} />;
};
