import _clean from 'lodash-clean';
import type { CancelToken } from 'types/indexTS';
import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import type {
  FetchPublicPagesActionError,
  FetchPublicPagesActionSuccess,
  FetchPublicPagesActionRequest,
  DestroyPagesStateAction,
  FetchNextPublicPagesActionRequest,
  PagesQuery,
} from './typesTS';
import { PagesActionTypes } from './actionTypes';
import normalizer from './normalizer';
import { FetchNextPublicPagesActionSuccess } from './typesTS';

const pagesReceivedSuccess = ({ additionalData, data }): FetchPublicPagesActionSuccess =>
  actionCreator.createAction(PagesActionTypes.RECEIVED, normalizer.pages(data, additionalData.query), additionalData);

const pagesReceivedError = ({ additionalData, errorData }): FetchPublicPagesActionError =>
  actionCreator.createErrorAction(PagesActionTypes.RECEIVED, errorData, {}, additionalData);

const pagesReceivedRequest = (
  componentKey: string,
  query: PagesQuery,
  resetLoadedData?: boolean,
  cancelToken?: CancelToken,
): FetchPublicPagesActionRequest =>
  actionCreator.createAction(
    PagesActionTypes.REQUEST,
    {
      cancelToken,
      query,
      resetLoadedData,
    },
    {
      componentKey,
    },
  );

const fetchPages = (componentKey: string, params: PagesQuery, resetLoadedData?: boolean) => {
  const { options, ...query } = params;
  return actionCreator.createAsyncAction({
    additionalData: {
      componentKey,
      query: params,
    },
    endpoint: endpoints.PAGES.GET,
    errorBinding: pagesReceivedError,
    loadingAction: (cancelToken: CancelToken) =>
      pagesReceivedRequest(componentKey, params, resetLoadedData, cancelToken),
    method: 'get',
    parameters: _clean(query),
    successBinding: pagesReceivedSuccess,
  });
};

const nextPagesReceived = ({ additionalData, data }): FetchNextPublicPagesActionSuccess =>
  actionCreator.createAction(
    PagesActionTypes.NEXT_RECEIVED,
    { data: data.data, pagination: data.pagination },
    additionalData,
  );

const nextPagesNotReceived = ({ additionalData, errorData }): FetchPublicPagesActionError =>
  actionCreator.createErrorAction(PagesActionTypes.NEXT_RECEIVED, errorData, {}, additionalData);

const fetchingNextPages = (componentKey: string, cancelToken: CancelToken): FetchNextPublicPagesActionRequest =>
  actionCreator.createAction(
    PagesActionTypes.NEXT_REQUEST,
    {
      cancelToken,
    },
    {
      componentKey,
    },
  );

const fetchNextPages = (componentKey: string, nextUrl: string) =>
  actionCreator.createAsyncAction({
    additionalData: {
      componentKey,
    },
    absoluteEndPoint: nextUrl,
    errorBinding: nextPagesNotReceived,
    loadingAction: (cancelToken: CancelToken) => fetchingNextPages(componentKey, cancelToken),
    method: 'get',
    successBinding: nextPagesReceived,
  });

const destroyPages = (componentKey: string): DestroyPagesStateAction =>
  actionCreator.createAction(PagesActionTypes.DESTROYED, {}, { componentKey });

export const pagesActions = {
  fetchPages,
  fetchNextPages,
  destroyPages,
};
