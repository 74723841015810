import {
  fetchUserIfAuthenticated,
  fetchStreamers,
  fetchUserPage,
  fetchPaymentOrderInfo,
  fetchRewind,
  fetchSingleAuction,
  fetchPublicOriginals,
  fetchSingleFeedPost,
} from 'services/ss-data-loader';
import { pages } from 'services/routing';
import { ConnectedApp } from 'scenes/app';
import { Layout } from 'scenes/app-layout';
import { AdministrationPage } from 'scenes/administration';
import { AdminPayoutsPage } from 'scenes/admin-payouts';
import { AdminPredefinedCardsPage, EditPredefinedCardPage } from 'scenes/admin-predefined-cards';
import { AdminNotificationsPage } from 'scenes/admin-notifications';
import { AdminPredefinedFramesPage } from 'scenes/admin-predefined-frames';
import { AdminPartnershipApplicationsPage } from 'scenes/admin-partnership-applications';
import { AdminTagsPage } from 'scenes/admin-tags';
import AdminViewer from 'scenes/admin-viewer';
import AdminReactions from 'scenes/admin-reactions';
import AdminEmotes from 'scenes/admin-emotes';
import { InvoicesPage } from 'scenes/invoices';
import UserPage from 'scenes/user-page';
import { SignOut, AuthenticationResponsePage, SignUpPage } from 'scenes/auth';
import { NotFound } from 'scenes/errors';
import { StreamersPage } from 'scenes/streamers';
import { PageSettingsPage } from 'scenes/page-settings';
import BrandPage from 'scenes/brand-page';
import { ManageLootsPage } from 'scenes/set-loots';
import { ManageSetsPage } from 'scenes/sets';
import { RankingPage } from 'scenes/rankings';
import { Dashboard } from 'scenes/dashboard';
import { ReferralPage } from 'scenes/referral';
import { ConnectedCollection } from 'scenes/collection';
import { CreateCardsFromTemplatePage } from 'scenes/create-card-from-template';
import { UserCollectionsPage } from 'scenes/user-collections';
import { PublicUserPagePage } from 'scenes/public-user-page';
import { RedirectToSectionPage } from 'scenes/redirect-to-page-section';
import { ProbabilitiesPage } from 'scenes/probabilities';
import { ManualAuthentication } from 'scenes/authenticate-test-accounts';
import { ManageCardsList } from 'scenes/set-cards';
import { ManageMyCollectionPage, ManageCollectionPermissionsRedirection } from 'scenes/manage-collection';
import { PaymentReturnPage, PaymentResultPage } from 'scenes/loot-checkout';
import { BillingInformationConfigurationPage } from 'scenes/page-billing-information';
import { RevenuePage } from 'scenes/revenue';
import {
  CookiePolicyEs,
  CookiePolicy,
  CookiePolicyUSA,
  TermsAndConditions,
  PrivacyPolicyEs,
  PrivacyPolicyEn,
  PrivacyPolicyUSA,
} from 'scenes/legal';
import { AlertProviderPage } from 'scenes/alert-provider';
import { DisenchantPage } from 'scenes/disenchant';
import { CraftingModeDefaultValuesPage } from 'scenes/crafting-mode-configuration';
import { AdminCouponsPage, CouponsPage } from 'scenes/coupons';
import { AdminFramesPage } from 'scenes/admin-frames';
import { OnboardingPage } from 'scenes/onboarding';
import { UserProfilePage } from 'scenes/user-profile';
import { GoalsPage } from 'scenes/goals';
import { AlertsAndWidgetsPage } from 'scenes/alerts-and-widgets';
import { WelcomeAgainMixer } from 'scenes/welcome-again-mixer';
import { ValidateEmailPage } from 'scenes/validate-email';
import { Rewind, RewindSocial } from 'scenes/rewind';
import { PowersPage } from 'scenes/powers';
import Achievements from 'scenes/achievements';
import Originals from 'scenes/originals';
import { TimerPage } from 'scenes/timer';
import AuctionPage from 'scenes/auction';
import { ChatBotPage } from 'scenes/chat-bot';
import StreamDeckPage from 'scenes/streamdeck';
import { AuctionListPage } from 'scenes/auction-list';
import { AuctionEditorPage } from 'scenes/create-auction';
import { EditAuctionPage } from 'scenes/edit-auction';
import { PublicAuctionListPage } from 'scenes/public-auction-list';
import { CommunityRankingPage } from 'scenes/community-ranking';
import { ActivityFeedPage } from 'scenes/activity-feed';
import { OBSPluginPage } from 'scenes/obs-plugin';
import { MarathonPage } from 'scenes/marathon';
import { SubscriptionPage } from 'scenes/page-subscription';
import {
  CardsModulesPage,
  AuctionModulesPage,
  SubscriptionModulesPage,
  CardsModulePublicLandingPage,
  SubscriptionsModulePublicLandingPage,
  AuctionsModulePublicLandingPage,
  MarathonsModulesPage,
  MarathonsModulePublicLandingPage,
  // DonationsModulesPage,
  // DonationsModuleLandingPage,
} from 'scenes/products-modules';
import { MyViewersCommunityPage } from 'scenes/my-viewers-community';
import { PublicOriginalPage } from 'scenes/public-originals';
import { RouteConfig } from 'react-router-config';
import { MyCommunityCommunicationPage } from 'scenes/my-community-communication';
import { OriginalOnboardingRedirection } from 'scenes/public-originals/OriginalOnboardingRedirection';
import { PublicFeedDetailPage, PublicFeedPage } from 'scenes/public-page-feed';
import { ManageCommunicationsPage } from 'scenes/manage-communications';
// import { DonationsPage } from 'scenes/page-donations';
import { MonthlyStreamersLeaderboard } from 'components/top-christmas-snowflakes';

const NullComponent = (): null => null;

const routes: RouteConfig[] = [
  {
    component: NullComponent,
    path: pages.STATIC,
  },
  {
    component: NullComponent,
    path: pages.FAVICON,
  },
  {
    component: ConnectedApp,
    loadData: () => [fetchUserIfAuthenticated()],
    routes: [
      {
        path: pages.LANDING_CARDS,
        component: CardsModulePublicLandingPage,
        exact: true,
      },
      {
        path: pages.LANDING_AUCTIONS,
        component: AuctionsModulePublicLandingPage,
        exact: true,
      },
      {
        path: pages.LANDING_MARATHONS,
        component: MarathonsModulePublicLandingPage,
        exact: true,
      },
      {
        path: pages.LANDING_SUBSCRIPTIONS,
        component: SubscriptionsModulePublicLandingPage,
        exact: true,
      },
      // {
      //   path: pages.LANDING_DONATIONS,
      //   component: DonationsModuleLandingPage,
      //   exact: true,
      // },

      {
        path: pages.PUBLIC_ORIGINALS,
        component: PublicOriginalPage,
        exact: true,
        loadData: ({ params }) => [fetchPublicOriginals(params.frameId)],
      },
      {
        path: pages.REWIND_SOCIAL,
        component: RewindSocial,
        exact: true,
      },
      {
        path: pages.REWIND,
        component: Rewind,
        exact: true,
        loadData: ({ params }) => [fetchRewind(params.userId)],
      },
      {
        component: OnboardingPage,
        exact: true,
        path: pages.CREATE_PAGE,
      },
      {
        component: ValidateEmailPage,
        exact: true,
        path: pages.VALIDATE_EMAIL,
      },
      {
        component: Layout,
        routes: [
          {
            component: CookiePolicyEs,
            exact: true,
            path: pages.COOKIES_POLICY_ES,
          },
          {
            component: CookiePolicy,
            exact: true,
            path: pages.COOKIES_POLICY,
          },
          {
            component: CookiePolicyUSA,
            exact: true,
            path: pages.COOKIES_POLICY_USA,
          },
          {
            component: TermsAndConditions,
            exact: true,
            path: pages.TERMS_AND_CONDITIONS,
          },
          {
            component: PrivacyPolicyEs,
            exact: true,
            path: pages.PRIVACY_POLICY_ES,
          },
          {
            component: PrivacyPolicyEn,
            exact: true,
            path: pages.PRIVACY_POLICY_EN,
          },
          {
            component: PrivacyPolicyUSA,
            exact: true,
            path: pages.PRIVACY_POLICY_USA,
          },
          // {
          //   path: pages.TOP_STREAMERS,
          //   component: MonthlyStreamersLeaderboard,
          //   exact: true,
          // },
          {
            path: pages.BRAND,
            component: BrandPage,
            exact: true,
          },
          {
            component: AdminPayoutsPage,
            exact: true,
            path: pages.ADMIN_PAYOUTS,
          },
          {
            component: AdminCouponsPage,
            exact: true,
            path: pages.ADMIN_COUPONS,
          },
          {
            component: AdminPredefinedCardsPage,
            exact: true,
            path: pages.ADMIN_PREDEFINED_CARDS,
          },
          /**
           * For the future, because it's debt and required some time to discover the reason.
           * Right now the code related to create a predefined card on Admin is dead code, with the unused route ADMIN_PREDEFINED_CARDS_CREATE.
           * You're wonder why it's working if that code is dead.
           * It's working because the following route have the same route but ending with a param, this way we are still catching the route.
           * Next thing, it's on the EditPredefinedCardPage Component we are checking the templateId value, if it's value is **create**, the app will display the create card form.
           * In other case, the app will display the edit card form.
           */
          {
            component: EditPredefinedCardPage,
            exact: true,
            path: pages.ADMIN_PREDEFINED_CARDS_EDIT,
          },
          {
            component: AdminPredefinedFramesPage,
            exact: true,
            path: pages.ADMIN_PREDEFINED_FRAMES,
          },
          {
            component: AdminFramesPage,
            exact: true,
            path: pages.ADMIN_ORDER_FRAMES,
          },
          {
            component: AdminPartnershipApplicationsPage,
            exact: true,
            path: pages.ADMIN_PARTNERSHIP_APPLICATIONS,
          },
          {
            component: AdminNotificationsPage,
            exact: true,
            path: pages.ADMIN_NOTIFICATIONS,
          },
          {
            component: AdminTagsPage,
            exact: true,
            path: pages.ADMIN_TAGS,
          },
          {
            component: AdminViewer,
            exact: true,
            path: pages.ADMIN_VIEWER,
          },
          {
            component: AdminReactions,
            exact: true,
            path: pages.ADMIN_REACTIONS,
          },
          {
            component: AdminEmotes,
            exact: true,
            path: pages.ADMIN_EMOTES,
          },
          {
            path: pages.SIGN_IN_ABSOLUTE,
            component: AuthenticationResponsePage,
          },
          { path: pages.SIGN_UP, component: SignUpPage },
          {
            path: pages.STREAMERS,
            loadData: () => [fetchStreamers('streamers')],
            component: StreamersPage,
          },
          {
            path: pages.USER_COLLECTIONS,
            component: UserCollectionsPage,
          },
          {
            path: pages.POWERS,
            component: PowersPage,
          },
          {
            component: SignOut,
            exact: true,
            path: pages.SIGN_OUT,
          },
          {
            component: PaymentReturnPage,
            path: pages.PAYMENT_RETURN,
            loadData: ({ query }) => [fetchPaymentOrderInfo(query.paymentId, query.processor)],
            exact: true,
          },
          {
            component: UserProfilePage,
            path: pages.INTEGRATIONS_RESPONSE,
            exact: true,
          },
          {
            component: OriginalOnboardingRedirection,
            exact: true,
            path: pages.PUBLIC_ORIGINALS_REDIRECT,
          },
          {
            component: UserProfilePage,
            path: pages.MY_PROFILE,
            exact: true,
          },
          {
            path: pages.REDIRECT_TO_PAGE_SECTION,
            component: RedirectToSectionPage,
            exact: true,
          },
          {
            path: pages.WELCOME_MIXER,
            component: WelcomeAgainMixer,
            exact: true,
          },
          {
            path: pages.ACHIEVEMENTS,
            component: Achievements,
            exact: true,
          },
          {
            path: pages.USER_PAGE,
            component: UserPage,
            loadData: ({ params, path }) => [fetchUserPage(params.slug, path)],
            routes: [
              {
                component: AuctionPage,
                exact: true,
                path: pages.AUCTION,
                loadData: ({ params }) => [fetchSingleAuction(params.slug, params.auctionId)],
              },
              {
                component: PublicFeedDetailPage,
                path: pages.USER_PAGE_FEED_DETAIL,
                exact: true,
                loadData: ({ params }) => [fetchSingleFeedPost(params.slug, params.feedId)],
              },
              {
                component: ManageCommunicationsPage,
                path: pages.MANAGE_COMMUNICATIONS,
                exact: true,
              },
              {
                component: ManualAuthentication,
                exact: true,
                path: pages.AUTHENTICATE_TEST,
              },
              {
                component: AdministrationPage,
                exact: true,
                path: pages.ADMINISTRATION,
              },
              {
                component: Dashboard,
                fullContent: true,
                exact: true,
                path: pages.DASHBOARD,
              },
              {
                component: CommunityRankingPage,
                exact: true,
                path: pages.COMMUNITY_RANKING,
              },
              {
                component: MyCommunityCommunicationPage,
                exact: true,
                path: pages.MY_VIEWERS_COMMUNITY_EMAILS,
              },
              {
                component: MyViewersCommunityPage,
                exact: true,
                path: pages.MY_VIEWERS_COMMUNITY_BASE_PATH,
              },
              {
                component: PaymentResultPage,
                path: pages.PAYMENT_RESULT,
                exact: true,
              },
              {
                component: CouponsPage,
                path: pages.PAGE_COUPONS,
                exact: true,
              },
              {
                component: PaymentReturnPage,
                path: pages.PAYMENT_RETURN,
                exact: true,
              },
              {
                component: RankingPage,
                exact: true,
                path: pages.RANKINGS,
              },
              {
                component: ActivityFeedPage,
                exact: true,
                path: pages.ACTIVITY_FEED,
              },
              {
                component: AlertProviderPage,
                exact: true,
                path: pages.ALERTS,
              },
              {
                component: GoalsPage,
                exact: true,
                path: pages.GOALS_PATH,
              },
              {
                component: AlertsAndWidgetsPage,
                exact: true,
                path: pages.WIDGETS,
              },
              {
                component: TimerPage,
                exact: true,
                path: pages.TIMER,
              },
              {
                component: ChatBotPage,
                exact: true,
                path: pages.CHAT_BOT,
              },
              {
                component: SubscriptionPage,
                exact: true,
                path: pages.PAGE_SUBSCRIPTIONS_BASE_PATH,
              },
              // {
              //   component: DonationsPage,
              //   exact: true,
              //   path: pages.PAGE_DONATIONS_BASE_PATH,
              // },
              {
                component: AuctionListPage,
                exact: true,
                path: pages.AUCTIONS,
              },
              {
                component: StreamDeckPage,
                exact: true,
                path: pages.STREAMDECK,
              },
              {
                component: OBSPluginPage,
                exact: true,
                path: pages.OBS_PLUGIN,
              },
              {
                component: MarathonPage,
                exact: true,
                path: pages.MARATHON_PATH,
              },
              {
                component: AuctionEditorPage,
                exact: true,
                path: pages.CREATE_AUCTION,
              },
              {
                component: EditAuctionPage,
                exact: true,
                path: pages.EDIT_AUCTION,
              },
              {
                component: BillingInformationConfigurationPage,
                exact: true,
                path: pages.BILLING_INFORMATION_CONFIGURATION,
              },
              {
                component: InvoicesPage,
                exact: true,
                path: pages.INVOICES,
              },
              {
                component: RevenuePage,
                exact: true,
                path: pages.REVENUE_PATH,
              },
              {
                component: ReferralPage,
                exact: true,
                path: pages.REFERRAL,
              },
              {
                component: Originals,
                exact: true,
                path: pages.ORIGINALS,
              },
              {
                component: CreateCardsFromTemplatePage,
                exact: true,
                path: pages.ADD_DEFAULT_CARDS,
              },
              {
                component: CardsModulesPage,
                exact: true,
                path: pages.ENABLE_CARDS,
              },
              {
                component: AuctionModulesPage,
                exact: true,
                path: pages.ENABLE_AUCTIONS,
              },
              {
                component: SubscriptionModulesPage,
                exact: true,
                path: pages.ENABLE_SUBSCRIPTIONS,
              },
              {
                component: MarathonsModulesPage,
                exact: true,
                path: pages.ENABLE_MARATHONS,
              },
              // {
              //   component: DonationsModulesPage,
              //   exact: true,
              //   path: pages.ENABLE_DONATIONS,
              // },
              {
                component: ManageMyCollectionPage,
                path: pages.MANAGE_COLLECTION,
                routes: [
                  {
                    component: ManageCardsList,
                    path: pages.CARD_LIST,
                  },
                  {
                    component: CraftingModeDefaultValuesPage,
                    exact: true,
                    path: pages.DISENCHANT_DEFAULT,
                  },
                  {
                    component: DisenchantPage,
                    exact: true,
                    path: pages.DISENCHANT,
                  },
                  {
                    component: ProbabilitiesPage,
                    path: pages.UPDATE_PROBABILITIES,
                  },
                  {
                    component: ManageLootsPage,
                    path: pages.PACKS_AND_PRICES,
                  },
                  {
                    component: ManageCollectionPermissionsRedirection,
                    path: '*',
                  },
                ],
              },
              {
                component: ManageSetsPage,
                exact: true,
                path: pages.MANAGE_COLLECTIONS,
              },
              {
                component: PageSettingsPage,
                exact: true,
                path: pages.CONFIGURE_PAGE_PATH,
              },
              {
                path: pages.USER_PAGE,
                component: PublicUserPagePage,
                routes: [
                  {
                    component: ConnectedCollection,
                    path: pages.USER_PAGE_COLLECTION,
                  },
                  {
                    component: PublicAuctionListPage,
                    exact: true,
                    path: pages.ALL_AUCTIONS,
                  },
                  {
                    component: ConnectedCollection,
                    path: pages.USER_PAGE,
                  },
                  {
                    component: PublicUserPagePage,
                    path: pages.USER_PAGE_SUBSCRIPTION,
                  },
                  {
                    component: PublicFeedPage,
                    path: pages.USER_PAGE_FEED,
                  },
                  {
                    component: PublicUserPagePage,
                    path: pages.USER_PAGE_COLLECTIONS,
                  },
                ],
              },
              {
                path: '*',
                component: NotFound,
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
