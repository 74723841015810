import { FullLayout } from 'scenes/layout';
import { RouteConfigComponentProps } from 'react-router-config';
import { RedirectToSection } from './RedirectToSection';

export const RedirectToSectionPage = (
  props: RouteConfigComponentProps<{
    pageKey: string;
  }>,
): JSX.Element => {
  return (
    <FullLayout>
      <RedirectToSection {...props} />
    </FullLayout>
  );
};
