import * as React from 'react';
import Skeleton from 'ui-library/skeleton';
import theme from './component-skeletons.scss';

export const FormInputSkeleton = (): JSX.Element => {
  return (
    <div className={theme['skeletons-form-input']}>
      <Skeleton className={theme['skeletons-form-input__label']} height={12} />
      <Skeleton height={38} />
    </div>
  );
};
