export enum BannedUsersActionTypes {
  BANNED = 'ban-users/BANNED',
  BANNING = 'ban-users/BANNING',
  RECEIVED = 'ban-users/RECEIVED',
  REQUEST = 'ban-users/REQUEST',
  NEXT_RECEIVED = 'ban-users/NEXT_RECEIVED',
  NEXT_REQUEST = 'ban-users/NEXT_REQUEST',
  UNBANNED = 'ban-users/UNBANNED',
  UNBANNING = 'ban-users/UNBANNING',
}
