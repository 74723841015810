import type { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import ClassNames from '@streamloots/classnames';
import Icon from 'ui-library/icons';
import theme from './tabs.scss';

const classNames = ClassNames(theme);

type NavLinkTabHeaderProps = {
  activeClassName?: string;
  className?: string;
  disabled?: boolean;
  icon?: string;
  label: ReactNode;
  to: string;
  exact?: boolean;
};

export const NavLinkTabHeader = ({
  activeClassName = '',
  className = '',
  icon,
  label,
  disabled,
  to,
  exact,
}: NavLinkTabHeaderProps): JSX.Element => {
  return (
    <NavLink
      className={classNames({
        'tabs__header': true,
        [className]: Boolean(className),
        'tabs__header--disabled': disabled,
      })}
      activeClassName={classNames({
        [activeClassName]: Boolean(activeClassName),
        'tabs__header--active': true,
      })}
      to={to}
      exact={exact}
    >
      {icon && <Icon id={icon} className={theme.tabs__header__icon} />}
      {label}
    </NavLink>
  );
};
