import stateCreator from 'helpers/stateCreator';
import { PageActionTypes } from 'services/page';
import { SetsActionTypes, setUtils } from 'services/sets';
import type { UserSetsState } from './types';
import { UserSetsActionTypes } from './actionTypes';
import normalizer from './normalizer';

export const INITIAL_USER_SETS_STATE: UserSetsState = {
  userSetsById: {},
};

export default (state: UserSetsState = INITIAL_USER_SETS_STATE, action): UserSetsState => {
  const { error, type, payload } = action;
  switch (type) {
    case UserSetsActionTypes.FETCH_SUCCESS: {
      const { selectedUserSetId } = state;
      const { data } = payload;
      const selectedId = setUtils.getSelectedIdOrDefaultId(data, selectedUserSetId, false);

      const normalizedUserSets = normalizer.userSets(data);
      const userSetListStateProps = normalizer.userSetListStateProps(normalizedUserSets);

      return stateCreator.getInitialAsyncState(state, {
        ...userSetListStateProps,
        selectedUserSetId: selectedId,
      });
    }
    case UserSetsActionTypes.FETCH_ERROR: {
      return stateCreator.getAsyncErrorState(action, state);
    }
    case UserSetsActionTypes.FETCH_REQUEST:
      return stateCreator.getAsyncLoadingState(state);
    case SetsActionTypes.SELECTED_SET_CHANGED: {
      const { setId } = payload;
      return {
        ...state,
        selectedUserSetId: setId,
      };
    }
    case SetsActionTypes.UPDATED:
    case SetsActionTypes.RESUMED:
    case SetsActionTypes.PAUSED: {
      if (error) {
        return state;
      }
      const { userSets = [] } = state;
      const { set } = payload;
      const { paused, pausedReason, name, craftableCards, default: defaultUserSet, imageUrl, resumeAt } = set;
      return {
        ...state,
        ...normalizer.userSetListStateProps(userSets, set._id, {
          default: defaultUserSet,
          paused,
          pausedReason,
          name,
          craftableCards,
          imageUrl,
          resumeAt,
        }),
      };
    }
    case PageActionTypes.CHANGED:
      return INITIAL_USER_SETS_STATE;
    default:
      return state;
  }
};
