/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import stateCreator from 'helpers/stateCreator';
import { PageActionTypes } from 'services/page';
import { AuctionActionTypes } from './actionTypes';
import { PostBidResponse, AuctionEndedPayload } from './types';

const initialState = {};

// eslint-disable-next-line consistent-return
export const reducer = produce((currentState, action) => {
  const { type, payload } = action;

  switch (type) {
    case AuctionActionTypes.FETCH_SINGLE_AUCTION_REQUEST:
    case AuctionActionTypes.GET_AUCTIONS_REQUEST: {
      return stateCreator.getAsyncLoadingState(currentState);
    }
    case AuctionActionTypes.GET_AUCTIONS_ERROR:
    case AuctionActionTypes.FETCH_SINGLE_AUCTION_ERROR:
      // TODO managing error and isLoading for auctions and single auctions isn't flexible at all
      return stateCreator.getAsyncErrorState(action, currentState);

    case AuctionActionTypes.FETCH_SINGLE_AUCTION_SUCCESS: {
      const { auction } = payload;
      const { singleAuctions = {} } = currentState;

      return stateCreator.getInitialAsyncState(currentState, {
        singleAuctions: {
          ...singleAuctions,
          [auction._id]: auction,
        },
      });
    }

    case AuctionActionTypes.GET_AUCTIONS_SUCCESS: {
      return stateCreator.getInitialAsyncState(currentState, {
        data: payload,
      });
    }
    case AuctionActionTypes.POST_BID_SUCCESS: {
      const { amount, userId, username, auctionId }: PostBidResponse = payload;

      currentState.singleAuctions[auctionId].topBid = {
        amount,
        userId,
        username,
      };
      // eslint-disable-next-line consistent-return
      return;
    }
    case AuctionActionTypes.COUNTDOWN_ENDED: {
      const { auctionId }: AuctionEndedPayload = payload;
      currentState.singleAuctions[auctionId].isEnded = true;

      return currentState;
    }
    case PageActionTypes.CHANGED:
      return {};
  }
}, initialState);
