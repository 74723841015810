import { useEffect } from 'react';
import { usePagesActions, usePagesSelectorByComponentKey, PagesQuery } from 'services/pages';
import { useUserSelectors } from 'services/user';
import { useCultureSelectors } from 'services/culture';

interface StreamerFetcherConfiguration {
  filterByLanguageRecommendations?: boolean;
  initialQuery?: PagesQuery;
  skipFetchingOnMount?: boolean;
}

export const useStreamerFetcher = (componentKey: string, configuration: StreamerFetcherConfiguration = {}) => {
  const { userPreferences } = useUserSelectors();
  const { language } = useCultureSelectors();
  const { initialQuery, filterByLanguageRecommendations, skipFetchingOnMount } = configuration;
  const languageToUse = userPreferences.streamersRecommendations?.language || language;
  const { fetchPages, fetchNextPages } = usePagesActions();
  const { data, error, pagination, query, isLoading, isFetchingNextItems = false } = usePagesSelectorByComponentKey(
    componentKey,
  );

  useEffect(() => {
    if (Array.isArray(data) || isLoading || skipFetchingOnMount) {
      return;
    }

    const pagesQuery = initialQuery || { ...query };

    if (filterByLanguageRecommendations) {
      pagesQuery.languages = languageToUse;
    }

    fetchPages(componentKey, pagesQuery, false);

    // eslint-disable-next-line
  }, [componentKey])

  const handleFilterPages = (newQuery: PagesQuery, invalidateData = true) => {
    return fetchPages(componentKey, newQuery, invalidateData);
  };

  const onShowMoreClick = () => {
    if (!pagination?.next) {
      return Promise.resolve(null);
    }

    return fetchNextPages(componentKey, pagination.next);
  };

  const moreItems = (): boolean => {
    const fullLoadedItems = Array.isArray(data) && (data.length === 0 || data.length < (query.limit || 50));
    return Boolean(!fullLoadedItems && pagination && pagination.next);
  };

  return {
    error,
    isLoading,
    isFetchingNextItems,
    moreItems,
    query,
    data,
    filterPages: handleFilterPages,
    fetchNext: onShowMoreClick,
  };
};
