import { Auction, Auctions } from 'model/auction';
import { Languages, PAGE_AUCTIONS_GET_RESPONSE_SUCCESS } from '@streamloots/streamloots-types';
import { FixMe } from 'types/indexTS';

function getAuctionFromResponse(auction: PAGE_AUCTIONS_GET_RESPONSE_SUCCESS): Auction {
  const { startDate, endDate, language, ...rest } = auction;
  const end = new Date(endDate);
  return {
    ...rest,
    startDate: new Date(startDate),
    endDate: end,
    isEnded: end.getTime() < Date.now(),
    userHasBidden: (auction as FixMe).userHasBidden ?? auction.topBid !== undefined,
    language: language.toLocaleLowerCase() as Languages,
  };
}

const getAuctionsSuccess = (data: PAGE_AUCTIONS_GET_RESPONSE_SUCCESS[]): Auctions => {
  return data.map(getAuctionFromResponse);
};

export const auctionNormalizers = {
  getAuctionFromResponse,
  getAuctionsSuccess,
};
