import stateCreator from 'helpers/stateCreator';
import { PageActionTypes } from 'services/page';
import { PageSubscriptionRewardsActionTypes } from './actionTypes';
import { PageSubscriptionRewardsState, PageSubscriptionRewardsActions, PLAY_REWARD_ERROR_ACTION } from './types';

const initialState: PageSubscriptionRewardsState = {};

export const reducer = (state = initialState, action: PageSubscriptionRewardsActions): PageSubscriptionRewardsState => {
  switch (action.type) {
    case PageSubscriptionRewardsActionTypes.PLAY_REWARD_REQUEST:
    case PageSubscriptionRewardsActionTypes.GET_PAGE_SUBSCRIPTION_REWARDS_REQUEST: {
      return {
        ...state,
        ...stateCreator.getAsyncLoadingState(state),
      };
    }

    case PageSubscriptionRewardsActionTypes.PLAY_REWARD_ERROR:
    case PageSubscriptionRewardsActionTypes.GET_PAGE_SUBSCRIPTION_REWARDS_ERROR: {
      return stateCreator.getAsyncErrorState(action, state);
    }

    case PageSubscriptionRewardsActionTypes.GET_PAGE_SUBSCRIPTION_REWARDS_SUCCESS: {
      const newState: PageSubscriptionRewardsState = {
        rewards: action.payload,
      };

      return stateCreator.getInitialAsyncState(state, newState);
    }

    case PageSubscriptionRewardsActionTypes.PLAY_REWARD_SUCCESS:
    case PageActionTypes.CHANGED: {
      return initialState;
    }

    default:
      return state;
  }
};
