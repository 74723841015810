import { createSetActions } from './services/create-set';
import { publishSetActions } from './services/publish-set';
import { setListActions } from './services/set-list';
import { setRaritiesActions } from './services/set-rarities';
import { setStatusActions } from './services/set-status';
import { unpublishSetActions } from './services/unpublish-set';
import { updateSetActions } from './services/update-set';
import { deleteSetActions } from './services/delete-set';

export const setsActions = {
  ...createSetActions,
  ...publishSetActions,
  ...setListActions,
  ...setRaritiesActions,
  ...setStatusActions,
  ...unpublishSetActions,
  ...updateSetActions,
  ...deleteSetActions,
};
