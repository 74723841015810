import React from 'react';
import ClassNames from '@streamloots/classnames';
import theme from './avatar-image.scss';

const classNames = ClassNames(theme);

type AvatarImageProps = JSX.IntrinsicElements['img'] & {
  src?: string;
  fallbackSrc?: string;
  rounded?: boolean;
  size?: 'md';
  bordered?: boolean;
};

type AvatarImageState = {
  src?: string;
  errored: boolean;
};

const DEFAULT_PLACEHOLDER_IMAGE =
  'https://static.streamloots.com/b355d1ef-d931-4c16-a48f-8bed0076401b/miscellaneous/avatar_default.png';
export const ROUNDED_PLACEHOLDER_IMAGE =
  'https://static.streamloots.com/b355d1ef-d931-4c16-a48f-8bed0076401b/miscellaneous/rounded_avatar_placeholder.png';

class AvatarImage extends React.Component<AvatarImageProps, AvatarImageState> {
  state = {
    // eslint-disable-next-line react/destructuring-assignment
    src: this.props.src,
    errored: false,
  };

  componentDidUpdate(prevProps) {
    const { src } = this.props;

    if (prevProps.src !== src) {
      this.setState({
        src,
        errored: false,
      });
    }
  }

  handleError = () => {
    const { errored } = this.state;
    const { fallbackSrc, rounded } = this.props;

    if (!errored) {
      this.setState({
        src: fallbackSrc || (rounded ? ROUNDED_PLACEHOLDER_IMAGE : DEFAULT_PLACEHOLDER_IMAGE),
        errored: true,
      });
    }
  };

  render() {
    const { alt, className = '', onClick, fallbackSrc, bordered, size, rounded, ...props } = this.props;
    const { src } = this.state;

    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <img
        {...props}
        className={classNames({
          [className]: className,
          'avatar--bordered': bordered,
          'avatar--rounded': rounded,
          [`avatar--${size}`]: size,
        })}
        src={src}
        alt={alt}
        onError={this.handleError}
        onClick={onClick}
      />
    );
  }
}

export default AvatarImage;
