import stateCreator from 'helpers/stateCreator';
import type { OnboardingState, OnboardingActions } from './typesTS';
import { OnboardingActionTypes } from './actionTypes';

const initialState: OnboardingState = {};

export const reducer = (state: OnboardingState = initialState, action: OnboardingActions): OnboardingState => {
  switch (action.type) {
    case OnboardingActionTypes.CHANGED: {
      const pageOnboarding = state.pageOnboarding || {};
      return {
        ...state,
        pageOnboarding: {
          ...pageOnboarding,
          onboardingWizard: { ...action.payload },
        },
      };
    }
    case OnboardingActionTypes.FETCHING:
    case OnboardingActionTypes.FINALIZING:
    case OnboardingActionTypes.CREATING:
      return stateCreator.getAsyncLoadingState(state);
    case OnboardingActionTypes.UPDATING: {
      if (!action.payload.isUpdatingModules) {
        return state;
      }

      return stateCreator.getAsyncLoadingState(state);
    }
    case OnboardingActionTypes.RECEIVED: {
      if (action.error && action.metadata.code === 404) {
        return stateCreator.getInitialAsyncState(state);
      }

      if (action.error) {
        return stateCreator.getAsyncErrorState(action, state);
      }

      return stateCreator.getInitialAsyncState({
        ...state,
        pageOnboarding: action.payload,
      });
    }
    case OnboardingActionTypes.CREATED:
      if (action.error) {
        return stateCreator.getAsyncErrorState(action, state);
      }

      return stateCreator.getInitialAsyncState({
        ...state,
        pageOnboarding: action.payload,
      });
    case OnboardingActionTypes.FINALIZED: {
      if (action.error) {
        return stateCreator.getAsyncErrorState(action, state);
      }

      return stateCreator.getInitialAsyncState({
        ...state,
        onboardingFinalized: true,
      });
    }
    case OnboardingActionTypes.UPDATED: {
      // we use optimistic update unless it is selecting a new module since it is required to ensure that the back-end is updated
      if (!action.metadata.isUpdatingModules) {
        return stateCreator.getInitialAsyncState(state);
      }

      if (action.error) {
        return stateCreator.getAsyncErrorState(action, state);
      }

      return stateCreator.getInitialAsyncState({
        ...state,
        pageOnboarding: action.payload,
      });
    }
    case OnboardingActionTypes.CLEAN_ASYNC_STATUS:
      return stateCreator.getInitialAsyncState(state);
    default:
      return state;
  }
};
