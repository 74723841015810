import React, { useContext } from 'react';
import ClassNames from '@streamloots/classnames';
import { SidebarOpenerContext } from 'contexts/sidebar-opener';
import CardCreatorPage from './CardCreatorPage';
import theme from './card-creator-sidebar.scss';

const classNames = ClassNames(theme);

type OpenCreateCardArgs = {
  adminMode?: boolean;
  initialConfiguration: {
    name: string;
    rarity: string;
    description: string;
  };
};

const useCardCreator = () => {
  const { open } = useContext(SidebarOpenerContext);

  const openCreateCard = ({ adminMode = false, initialConfiguration }: OpenCreateCardArgs) => {
    open({
      component: <CardCreatorPage adminMode={adminMode} initialConfiguration={initialConfiguration} />,
      attributes: {
        sidebarContentClassName: classNames('card-creator-sidebar'),
        type: 'large',
        hideToolbox: true,
      },
    });
  };

  return { openCreateCard };
};

export default useCardCreator;
