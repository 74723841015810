import { AlertProviderSettings, AlertProvidersProvider } from '@streamloots/streamloots-types';
import { TimerConfiguration } from 'model/timer';

export enum AlertProvider {
  STREAMLABS = 'streamlabs',
  STREAMLOOTS = 'streamloots',
}

export interface AlertProvidersAutomaticAlerts {
  buyMePacks: {
    enabled: boolean;
    message: string;
    minutesFrecuency: number;
  };
  legendaryCardObtained: {
    enabled: boolean;
    message: string;
  };
}

export type AlertProviderGeneralSettings = AlertProviderSettings;

export type AlertProviderAdvancedSettings = {
  noCache: boolean;
};

export type AlertProviderSettingsShape = {
  general: AlertProviderGeneralSettings;
  advanced: AlertProviderAdvancedSettings;
  timer: TimerConfiguration;
};

export type AlertProviders = {
  OBSConfigured: boolean;
  provider: AlertProvidersProvider;
  streamloots: {
    settings: AlertProviderSettingsShape;
    url?: string; // not visible for moderator
    timerUrl?: string; // not visible for moderator
  };
  customBadWords: string;
  streamlootsBadWordFilterEnabled: boolean;
  automaticAlerts: AlertProvidersAutomaticAlerts;
};
