import type { AppState, AppStoreState, BaseQuery, RouterState } from './types';

const appState = (state: AppStoreState): AppState => state.app;
const routerState = (state: AppStoreState): RouterState => state.router;

const baseRequestQuery = (state: AppStoreState): BaseQuery => {
  return appState(state).query;
};

const baseRequestPath = (state: AppStoreState): string => {
  return appState(state).path;
};

const currentPathname = (state: AppStoreState): string => {
  const { location } = routerState(state);
  const path = location ? location.pathname : '';
  return path || baseRequestPath(state);
};

const hash = (state: AppStoreState): string => {
  const { location } = routerState(state);
  return (location && location.hash) || '';
};

export const appSelectors = {
  currentPathname,
  baseRequestQuery,
  baseRequestPath,
  hash,
};
