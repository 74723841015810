import { PageActionTypes } from 'services/page';
import { paginationStateNormalizer } from 'services/pagination';
import { SetLootsActionTypes } from 'services/set-loots';
import type { TransactionsState } from './types';
import { PAGINATION_STATE_NORMALIZER_CONFIGURATION } from './constants';
import { TransactionsActionTypes } from './actionTypes';

const initialState: TransactionsState = {
  list: paginationStateNormalizer.getInitial(PAGINATION_STATE_NORMALIZER_CONFIGURATION),
};

export const transactionsReducer = (state = initialState, action): TransactionsState => {
  const { type, payload, error } = action;
  switch (type) {
    case TransactionsActionTypes.RECEIVED: {
      if (error) {
        return {
          ...state,
          list: paginationStateNormalizer.getReceivedError(action, state.list),
        };
      }
      return {
        ...state,
        summary: { ...payload.summary },
        daysFromLastSale: payload.daysFromLastSale,
        list: paginationStateNormalizer.getReceived(payload, PAGINATION_STATE_NORMALIZER_CONFIGURATION),
      };
    }
    case TransactionsActionTypes.REQUEST: {
      const newList = paginationStateNormalizer.getRequest(
        state.list,
        payload,
        PAGINATION_STATE_NORMALIZER_CONFIGURATION,
      );
      return {
        ...state,
        list: newList,
      };
    }
    case TransactionsActionTypes.NEXT_REQUEST:
      return {
        ...state,
        list: paginationStateNormalizer.getFetchingNextItems(state.list, payload.cancelToken),
      };
    case TransactionsActionTypes.NEXT_RECEIVED: {
      if (error) {
        return {
          ...state,
          list: paginationStateNormalizer.getNextReceivedError(state.list),
        };
      }
      return {
        ...state,
        list: paginationStateNormalizer.getNextItemsReceived(state.list, payload),
      };
    }
    case TransactionsActionTypes.REQUEST_NEW: {
      return {
        ...state,
        list: paginationStateNormalizer.getFetchingNewItems(state.list, payload.cancelToken),
      };
    }
    case TransactionsActionTypes.NEW_RECEIVED: {
      if (error) {
        return state;
      }
      return {
        ...state,
        summary: { ...payload.summary },
        list: paginationStateNormalizer.getNewReceived(state.list, payload),
      };
    }
    case TransactionsActionTypes.SHOW_NEW: {
      return {
        ...state,
        list: paginationStateNormalizer.getShowNew(state.list, PAGINATION_STATE_NORMALIZER_CONFIGURATION),
      };
    }
    case SetLootsActionTypes.LOOTS_UPDATED: {
      if (error) {
        return state;
      }
      return {
        ...state,
        list: paginationStateNormalizer.getResetLoadedData(state.list),
      };
    }
    case PageActionTypes.CHANGED:
      return initialState;
    default:
      return state;
  }
};
