import ClassNames from '@streamloots/classnames';
import Panel from 'ui-library/panel';
import theme from './component-skeletons.scss';

const classNames = ClassNames(theme);

interface CardCreatorLikeSkeletonProps {
  ariaLabel?: string;
}

export const CardCreatorLikeSkeleton = ({ ariaLabel }: CardCreatorLikeSkeletonProps): JSX.Element => {
  return (
    <div className={classNames('skeletons-card-creator-like')} aria-label={ariaLabel}>
      <Panel skeletonMode title="" showHeader={false} />
      <Panel skeletonMode title="" showHeader={false} />
    </div>
  );
};
