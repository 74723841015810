import stateCreator from 'helpers/stateCreator';
import { NEXT_PAYOUT_INFO } from 'services/transactions';
import type { AdminPayoutsState } from './types';
import { AdminPayoutActionTypes } from './actionTypes';

const initialState: AdminPayoutsState = {
  payouts: null,
};

export const reducer = (state: AdminPayoutsState = initialState, action): AdminPayoutsState => {
  const { type, error, payload } = action;
  switch (type) {
    case AdminPayoutActionTypes.FETCHING:
      return stateCreator.getAsyncLoadingState(state);
    case AdminPayoutActionTypes.RECEIVED: {
      if (error) {
        return stateCreator.getAsyncErrorState(action, state, initialState);
      }
      const { data } = payload;
      return stateCreator.getInitialAsyncState(state, {
        payouts: data,
        pendingPayouts: data.filter(
          payout => payout.amount >= NEXT_PAYOUT_INFO.minWithdraw && payout.seller.hasPayoutMethod,
        ),
      });
    }
    default:
      return state;
  }
};
