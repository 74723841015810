import { useMemo, FunctionComponent } from 'react';
import ClassNames from '@streamloots/classnames';
import { withTranslation, TranslateInterface } from 'utils/translation';
import { UserSetLootsBySetId } from 'services/user-set-loots';
import SetRadioButtonCards from 'components/set-radio-button-cards';
import theme from './section-sets.scss';

const classNames = ClassNames(theme);

interface OwnProps {
  collectionLootsFromAllUserSets: Array<UserSetLootsBySetId>;
  setId: string;
  onChangeSet: (setId: string) => void;
}

type SectionSetsProps = OwnProps & TranslateInterface;

const SectionSets = ({ collectionLootsFromAllUserSets, setId, onChangeSet, t }: SectionSetsProps) => {
  const collectionLootsFromAllUserSetsNormalized = useMemo(() => {
    return collectionLootsFromAllUserSets.map(collectionLootFromAllUserSets => {
      return {
        _id: collectionLootFromAllUserSets.set._id,
        name: collectionLootFromAllUserSets.set.name,
        imageUrl: collectionLootFromAllUserSets.lootImageUrl,
        selectable: collectionLootFromAllUserSets.hasBuyableLoots,
        cardAmount: (collectionLootFromAllUserSets.loots && collectionLootFromAllUserSets.loots[0]?.cardAmount) || 3,
      };
    });
  }, [collectionLootsFromAllUserSets]);

  return (
    <section>
      <header className={classNames('section-sets__header')}>
        <h3>{t('lootPacks:checkout.step1.setsSection.title')}</h3>
      </header>
      <SetRadioButtonCards
        items={collectionLootsFromAllUserSetsNormalized}
        activeId={setId}
        onChange={id => onChangeSet(id)}
      />
    </section>
  );
};

export default withTranslation('lootPacks')(SectionSets) as FunctionComponent<OwnProps>;
