import ClassNames from '@streamloots/classnames';
import { TranslateInterface } from 'utils/translation';
import Button from 'ui-library/button';
import theme from './tracker-panel.scss';

const classNames = ClassNames(theme);

interface TimerEndPanelProps extends TranslateInterface {
  onHidePanel: () => void;
}

export const TimerEndPanel = ({ t, onHidePanel }: TimerEndPanelProps): JSX.Element => {
  return (
    <div className={classNames('tracker-panel')}>
      <Button primary onClick={onHidePanel}>
        {t('marathon:actions.endTimer')}
      </Button>
    </div>
  );
};
