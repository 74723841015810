import stateCreator from 'helpers/stateCreator';
import { EmotesCollectionsActionTypes } from 'services/emotes-collections';
import { PageActionTypes } from 'services/page';
import { EmotesActionTypes } from './actionTypes';
import { EmotesState, EmotesActions } from './types';

const initialState: EmotesState = {};

export const reducer = (state = initialState, action: EmotesActions): EmotesState => {
  switch (action.type) {
    case EmotesActionTypes.DELETE_STREAMER_EMOTE_REQUEST:
    case EmotesActionTypes.FETCH_EMOTES_REQUEST:
    case EmotesActionTypes.ADD_STREAMER_EMOTE_REQUEST: {
      return { ...state, ...stateCreator.getAsyncLoadingState(state) };
    }

    case EmotesActionTypes.DELETE_STREAMER_EMOTE_ERROR:
    case EmotesActionTypes.FETCH_EMOTES_ERROR:
    case EmotesActionTypes.ADD_STREAMER_EMOTE_ERROR: {
      return stateCreator.getAsyncErrorState(action, state);
    }

    case EmotesActionTypes.FETCH_EMOTES_SUCCESS: {
      return stateCreator.getInitialAsyncState(state, {
        ...state,
        collections: action.payload,
      });
    }

    case EmotesActionTypes.DELETE_STREAMER_EMOTE_SUCCESS: {
      if (!state.collections) {
        return state;
      }

      return stateCreator.getInitialAsyncState(state, {
        collections: state.collections.map(collection => {
          if (collection._id !== action.payload.emotesCollectionId) {
            return collection;
          }
          return {
            ...collection,
            emotes: collection.emotes.filter(emote => emote._id !== action.payload._id),
          };
        }),
      });
    }

    case EmotesActionTypes.ADD_STREAMER_EMOTE_SUCCESS: {
      if (!state.collections) {
        return state;
      }

      return stateCreator.getInitialAsyncState(state, {
        collections: state.collections.map(collection => {
          if (collection._id !== action.payload.emotesCollectionId) {
            return collection;
          }

          return {
            ...collection,
            emotes: [...collection.emotes, action.payload],
          };
        }),
      });
    }

    case EmotesCollectionsActionTypes.UPDATE_STREAMER_EMOTES_COLLECTION_SUCCESS: {
      if (!state.collections) {
        return state;
      }

      return stateCreator.getInitialAsyncState(state, {
        collections: state.collections.map(collection => {
          if (collection._id !== action.payload._id) {
            return collection;
          }

          return {
            ...collection,
            locales: {
              ...action.payload.locales,
            },
          };
        }),
      });
    }

    case PageActionTypes.CHANGED: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
