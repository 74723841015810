import React from 'react';
import ClassNames from '@streamloots/classnames';
import theme from './icons.scss';

const classNames = ClassNames(theme);

type IconProps = {
  ariaLabel?: string;
  className?: string;
  description?: string;
  size?: 'big' | 'bigger' | 'huge';
  id: string;
};

/**
 *
 * Our icons are based on a sprite component, this component takes the icon
 * ID to print the desired icon, you can also pass classes with
 * <code>className="selector"</code> to add a color, a size or any other
 * modification to the icon.
 *
 * The icons are set with <code>color: currentColor</code> so their color
 * value is always the <code>color</code> value on the parent element. you
 * can change that ny adding any class with the <code> color:</code> value
 * of your choice.
 *
 */

const Icon = ({ className = '', ariaLabel, description, id, size }: IconProps): JSX.Element => (
  <svg
    role="img"
    aria-label={ariaLabel}
    className={classNames({
      icon: true,
      [`icon--${size}`]: Boolean(size),
      [className]: className,
    })}
  >
    <use xlinkHref={`#${id.toString()}`} xlinkTitle={description || undefined} />
  </svg>
);

Icon.defaultProps = { className: '', description: '', id: '' };
export default Icon;
