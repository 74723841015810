import { useCultureSelectors } from 'services/culture';
import articlesMapping from 'components/help/articles-mapping';
import { ArticleKey } from 'components/help/articles-keys';

export const useArticlesMapping = (articleKey: ArticleKey): string => {
  const { language } = useCultureSelectors();
  const mappingByLanguage = articlesMapping[language] || {};

  return mappingByLanguage[articleKey] || '';
};
