import actionCreator from 'helpers/actionCreator';
import { RewindFetchData } from 'model/rewind';
import { FIESTAS } from 'constants/fiestas';
import endpoints from '../endpoints';
import { RewindActionTypes } from './actionTypes';
import {
  FetchRewindError,
  FetchRewindSucces,
  FetchRewind,
  UploadRewindError,
  UploadRewindSucces,
  UploadRewind,
} from './types';

const rewindReceived = ({ data }: { data: RewindFetchData }): FetchRewindSucces =>
  actionCreator.createAction(RewindActionTypes.GET_REWIND_SUCCESS, data);

const rewindNotReceived = ({ errorData }): FetchRewindError =>
  actionCreator.createErrorAction(RewindActionTypes.GET_REWIND_ERROR, errorData);

const fetchRewind = (userId: string): FetchRewind => {
  return actionCreator.createAsyncAction({
    method: 'get',
    endpoint: endpoints.REWIND.GET,
    errorBinding: rewindNotReceived,
    urlParameters: { userId },
    loadingAction: RewindActionTypes.GET_REWIND_REQUEST,
    successBinding: rewindReceived,
  });
};

const fileUploaded = (): UploadRewindSucces => actionCreator.createAction(RewindActionTypes.UPLOADED);

const fileNotUploaded = ({ errorData }): UploadRewindError =>
  actionCreator.createErrorAction(RewindActionTypes.UPLOADED, errorData);

const uploadFile = (rawFile: Blob, type: string, fileName = FIESTAS.REWIND.id): UploadRewind => {
  const data = new FormData();
  const normalizedName = `${fileName.toLowerCase().replaceAll(' ', '-')}.png`;
  data.append('file', rawFile, normalizedName);
  data.append('type', type);
  //data.append('overwrite', true);

  return actionCreator.createAsyncAction({
    endpoint: endpoints.REWIND.FILES,
    errorBinding: fileNotUploaded,
    loadingAction: RewindActionTypes.UPLOADING,
    method: 'post',
    parameters: data,
    successBinding: fileUploaded,
  });
};

const actions = {
  fetchRewind,
  uploadFile,
};

export default actions;
