import { Field, WrappedFieldProps } from 'redux-form';
import { PushInput, PushInputProps } from '../form-elements';
import { FieldSharedProps } from './typesTS';

interface PushInputPropsFieldWrappedProps extends WrappedFieldProps, PushInputProps {}

const PushInputField = ({ label, input, meta, ...rest }: PushInputPropsFieldWrappedProps) => {
  const { error, touched } = meta;
  return <PushInput {...input} label={label} error={touched && error ? error : ''} {...rest} />;
};

export const CheckboxField = (props: FieldSharedProps<PushInputProps>) => (
  <Field component={PushInputField} {...props} type="checkbox" />
);

export const RadioField = (props: FieldSharedProps<PushInputProps>) => (
  <Field component={PushInputField} {...props} type="radio" />
);
