import stateCreator from 'helpers/stateCreator';
import { EmotesStylesActionTypes } from './actionTypes';
import { EmotesStylesState, EmotesStylesAction } from './types';

const initialState: EmotesStylesState = {};

export const reducer = (state = initialState, action: EmotesStylesAction): EmotesStylesState => {
  switch (action.type) {
    case EmotesStylesActionTypes.FETCH_EMOTES_STYLES_REQUEST:
      return stateCreator.getAsyncLoadingState(state);

    case EmotesStylesActionTypes.FETCH_EMOTES_STYLES_ERROR:
      return stateCreator.getAsyncErrorState(action, state);

    case EmotesStylesActionTypes.FETCH_EMOTES_STYLES_SUCCESS:
      return stateCreator.getInitialAsyncState(state, { ...state, styles: action.payload });

    default:
      return state;
  }
};
