import { useUserSelectors } from 'services/user';
import { useAuthSelectors } from 'services/auth';
import { useTicketsPanel } from '../useTicketsPanel';
import { BannerLeftMenuFiestaContent } from './BannerLeftMenuFiestaContent';

export const BannerLeftMenuFiesta = (): JSX.Element | null => {
  const { isFiestaActive, campaign } = useTicketsPanel();
  const { isUserAuthenticated } = useAuthSelectors();
  const { showOnboarding } = useUserSelectors();

  if (!isFiestaActive) {
    return null;
  }

  if (!isUserAuthenticated) {
    return (
      <BannerLeftMenuFiestaContent
        contributionAsStreamer={0}
        showTooltip={false}
        totalContribution={0}
        contributionAsViewer={0}
        showAuthDialogOnClick
      />
    );
  }

  if (!campaign) {
    return null;
  }

  const { userContribution } = campaign;
  const contributionAsViewer = userContribution?.contribution || 0;
  const contributionAsStreamer = userContribution?.streamerContribution || 0;

  return (
    <BannerLeftMenuFiestaContent
      contributionAsStreamer={contributionAsStreamer}
      showTooltip={!showOnboarding}
      totalContribution={contributionAsViewer + contributionAsStreamer}
      contributionAsViewer={contributionAsViewer}
    />
  );
};
