import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import { errorMapping } from '../../errorMapping';
import { SetsActionTypes } from '../../actionTypes';
import type { SetStatusChangedActionSuccess, SetStatusChangedActionError } from '../../types';
import type { ResetPublishSetAction } from './types';

const setPublished = ({ urlParameters }): SetStatusChangedActionSuccess =>
  actionCreator.createAction(SetsActionTypes.PUBLISHED, {
    setId: urlParameters.setId,
  });

const setNotPublished = ({ errorData, urlParameters }): SetStatusChangedActionError =>
  actionCreator.createErrorAction(SetsActionTypes.PUBLISHED, errorData, errorMapping, { setId: urlParameters.setId });

const publishSet = (setId: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.SETS.PUBLISH,
    errorBinding: setNotPublished,
    loadingAction: SetsActionTypes.PUBLISHING,
    method: 'post',
    urlParameters: { setId },
    successBinding: setPublished,
  });

const resetPublishSet = (): ResetPublishSetAction => actionCreator.createAction(SetsActionTypes.RESET_PUBLISH);

export const publishSetActions = {
  publishSet,
  resetPublishSet,
};
