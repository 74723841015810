import { FileTypeFilter } from '@streamloots/streamloots-types';

const imageRegExp = /\.(gif|jpg|jpeg|tiff|png)$/i;
const audioRegExp = /\.(ogg|mp3)$/i;
const videoRegExp = /\.(mp4|webm)$/i;

export const getTypeFromUri = (fileUriOrName?: string): FileTypeFilter => {
  if (!fileUriOrName) {
    return FileTypeFilter.ALL;
  }

  if (imageRegExp.test(fileUriOrName)) {
    return FileTypeFilter.IMAGES;
  }

  if (audioRegExp.test(fileUriOrName)) {
    return FileTypeFilter.SOUND;
  }

  if (videoRegExp.test(fileUriOrName)) {
    return FileTypeFilter.VIDEOS;
  }
  return FileTypeFilter.ALL;
};

export const getTypeFromMimeType = (mimeType: string): FileTypeFilter => {
  if (!mimeType) {
    return FileTypeFilter.ALL;
  }

  if (mimeType.indexOf('audio/') > -1) {
    return FileTypeFilter.SOUND;
  }
  if (mimeType.indexOf('image/') > -1) {
    return FileTypeFilter.IMAGES;
  }
  if (mimeType.indexOf('video/') > -1) {
    return FileTypeFilter.VIDEOS;
  }
  return FileTypeFilter.ALL;
};

export const getFileExtension = (fileName: string): string => {
  if (!fileName) {
    return FileTypeFilter.ALL;
  }

  const nameParts = fileName.split('.');
  const namePartsLength = nameParts.length;
  if (namePartsLength === 0) {
    return FileTypeFilter.ALL;
  }

  return nameParts[namePartsLength - 1];
};

export const getNameWithoutExtension = (fileName: string): string => {
  return fileName.replace(/\.[^/.]+$/, '');
};
