import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import { errorMapping } from '../../errorMapping';
import { SetsActionTypes } from '../../actionTypes';
import { SetStatusChangedActionError, SetStatusChangedActionSuccess } from '../../types';

const setUnpublished = ({ urlParameters }): SetStatusChangedActionSuccess =>
  actionCreator.createAction(SetsActionTypes.UNPUBLISHED, {
    setId: urlParameters.setId,
  });

const setNotUnpublished = ({ errorData, urlParameters }): SetStatusChangedActionError =>
  actionCreator.createErrorAction(SetsActionTypes.UNPUBLISHED, errorData, errorMapping, {
    setId: urlParameters.setId,
  });

const unpublishSet = (setId: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.SETS.UNPUBLISH,
    errorBinding: setNotUnpublished,
    loadingAction: SetsActionTypes.UNPUBLISHING,
    method: 'post',
    urlParameters: { setId },
    successBinding: setUnpublished,
  });

export const unpublishSetActions = {
  unpublishSet,
};
