import loadable from 'components/dynamic-import';
import { FullLayout } from 'scenes/layout';

const ConnectedAuthenticationResponse = loadable(() => import('./ConnectedAuthenticationResponse'));

export const AuthenticationResponsePage = (): JSX.Element => (
  <FullLayout>
    <ConnectedAuthenticationResponse isResponsePage />
  </FullLayout>
);
