import type { StripePaymentMethod } from 'model/indexTS';
import { FixMe } from 'types/indexTS';

function createStripePaymentMethodFromSavedPaymentMethod(savedStripeMethod: FixMe): StripePaymentMethod {
  const {
    id,
    billing_details: { name },
    // eslint-disable-next-line camelcase
    card: { last4, brand, exp_month, exp_year },
  } = savedStripeMethod;

  return {
    _id: id,
    brand,
    last4,
    cardholder: name,
    expMonth: exp_month,
    expYear: exp_year,
  };
}

export default { createStripePaymentMethodFromSavedPaymentMethod };
