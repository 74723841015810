import ClassNames from '@streamloots/classnames';
import theme from './loading-spinner.scss';

const classNames = ClassNames(theme);

type SpinnerProps = {
  fixed?: boolean;
  opaque?: boolean;
  overlay?: boolean;
  small?: boolean;
  ariaLabel?: string;
};

const Spinner = ({ overlay = true, opaque, fixed, small, ariaLabel }: SpinnerProps): JSX.Element => (
  <div
    className={classNames({
      'spinner-overlay': overlay,
      'spinner-overlay--fixed': fixed,
      'spinner-overlay--opaque': opaque,
    })}
    aria-label={ariaLabel}
  >
    <div
      className={classNames({
        'spinner': true,
        'spinner--small': small,
      })}
    />
  </div>
);

export default Spinner;
