import { useSelector } from 'react-redux';
import { LayoutState } from './types';
import { layoutSelectors } from './selectors';

export const useLayoutSelectors = (): LayoutState => {
  const layoutState = useSelector(layoutSelectors.layoutState);

  return {
    ...layoutState,
  };
};
