import type { ReactNode, CSSProperties } from 'react';

export interface ReactAlertInjectedProps {
  close: () => void;
  id: string;
  options: {
    position: string;
    timeout: number;
  };
  style: CSSProperties;
}

interface AlertTemplateProps extends ReactAlertInjectedProps {
  message: (props: ReactAlertInjectedProps) => ReactNode;
}

export const AlertTemplate = ({ message, ...props }: AlertTemplateProps) => {
  return message(props);
};
