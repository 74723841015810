import { ValueOf } from 'src/types/indexTS';

export const LOADING_STATES = {
  loading: 'loading',
  success: 'success',
  error: 'error',
  idle: 'idle',
} as const;

export type LoadingState = {
  state?: ValueOf<typeof LOADING_STATES>;
  error?: { message: string };
};
