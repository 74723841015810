import { addMilliseconds } from 'date-fns';
import _clean from 'lodash-clean';
import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import type { UpdateSetActionError, UpdateSetActionSuccess, UpdateSetActionRequest } from '../../types';
import normalizer from '../../normalizer';
import { SetsActionTypes } from '../../actionTypes';
import type { PauseSetConfiguration } from './types';

const setPaused = ({ data }): UpdateSetActionSuccess =>
  actionCreator.createAction(SetsActionTypes.PAUSED, {
    set: normalizer.set(data),
  });

const setNotPaused = ({ errorData, urlParameters }): UpdateSetActionError =>
  actionCreator.createErrorAction(SetsActionTypes.PAUSED, errorData, {}, urlParameters);

const updatingStatus = (setId: string): UpdateSetActionRequest =>
  actionCreator.createAction(SetsActionTypes.UPDATING_STATUS, { setId });

const pauseSet = (params: PauseSetConfiguration, setId: string) => {
  const parameters = _clean({
    ...params,
    resumeAt: params.resumeAt ? addMilliseconds(Date.now(), Number(params.resumeAt)).getTime() : undefined,
  });
  return actionCreator.createAsyncAction({
    endpoint: endpoints.SETS.PAUSE,
    errorBinding: setNotPaused,
    loadingAction: () => updatingStatus(setId),
    method: 'post',
    parameters,
    urlParameters: { setId },
    successBinding: setPaused,
  });
};

const setResumed = ({ data }): UpdateSetActionSuccess =>
  actionCreator.createAction(SetsActionTypes.RESUMED, {
    set: normalizer.set(data),
  });

const setNoResumed = ({ errorData, urlParameters }): UpdateSetActionError =>
  actionCreator.createErrorAction(SetsActionTypes.RESUMED, errorData, {}, urlParameters);

const resumeSet = (setId: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.SETS.RESUME,
    errorBinding: setNoResumed,
    loadingAction: () => updatingStatus(setId),
    method: 'post',
    urlParameters: { setId },
    successBinding: setResumed,
  });

export const setStatusActions = {
  resumeSet,
  pauseSet,
};
