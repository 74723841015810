import eventHelpers from './eventsHelpers';

declare const window: any;

const googleSiteTagAdded = (): boolean => eventHelpers.isEventSenderAdded('gtag');

const gtagOptions = {
  B: {
    send_to: 'AW-816212248/UyCACNmGmuIBEJjSmYUD',
    transaction_id: '',
  },
  C: {
    send_to: 'AW-816212248/DbqTCJ6HmuIBEJjSmYUD',
    transaction_id: '',
  },
  D: { send_to: 'AW-816212248/cOp8COvCgOIBEJjSmYUD' },
};

function sendEvent(): void {
  const category = eventHelpers.getUserCategory();

  if (googleSiteTagAdded() && category && gtagOptions[category]) {
    window.gtag('event', 'conversion', gtagOptions[category]);
  }
}

function sendEventRetarget(): void {
  window?.gtag('event', 'conversion', {
    send_to: 'AW-816212248/RGBVCOz8qPoBEJjSmYUD',
    transaction_id: '',
  });
}

export default {
  sendEvent,
  sendEventRetarget,
};
