import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import Icon from 'ui-library/icons';
import Tooltip from 'components/help-tooltip';
import theme from './grid.scss';

const classNames = ClassNames(theme);

interface GridColumnProps {
  className?: string;
  centered?: boolean;
  numeric?: boolean;
  noWrap?: boolean;
  tooltip?: string;
  children: React.ReactNode;
}

export const GridColumn = ({
  children,
  tooltip,
  centered,
  className = '',
  numeric,
  noWrap = true,
}: GridColumnProps): JSX.Element => {
  const columnClasses = classNames({
    'grid__column': true,
    'grid__column--numeric': numeric,
    'grid__column--centered': centered,
    'grid__column--nowrap': noWrap,
    [className]: Boolean(className),
  });

  if (tooltip) {
    return (
      <Tooltip className={columnClasses} tooltip={tooltip}>
        <div>
          {children} <Icon id="help-circle" />
        </div>
      </Tooltip>
    );
  }

  return <div className={columnClasses}>{children}</div>;
};
