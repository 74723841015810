import actionCreator from 'helpers/actionCreator';
import type { Christmas2021CliamableCampaign } from 'model/indexTS';
import endpoints from 'services/endpoints';
import { Campaings } from 'domain/campaing-active';
import { getClaimableCampaignWithCompletedMilestones } from './normalizer';
import type {
  FetchClaimableCampaigns,
  UpdateClaimableCampaigns,
  ClaimableCampaignsActionSuccess,
  ClaimableCampaignsActionError,
  JoinLeftCampaignResponseActions,
  JoinLeftCampaignActionError,
  JoinLeftCampaignActionSuccess,
} from './types';
import { ClaimableCampaignsActionTypes } from './actionTypes';

const campaignReceived = ({ data }: { data: Christmas2021CliamableCampaign }): ClaimableCampaignsActionSuccess =>
  actionCreator.createAction(ClaimableCampaignsActionTypes.RECEIVED, getClaimableCampaignWithCompletedMilestones(data));

const campaignNotReceived = ({ errorData }): ClaimableCampaignsActionError =>
  actionCreator.createErrorAction(ClaimableCampaignsActionTypes.ERROR, errorData);

const fetchCampaign: FetchClaimableCampaigns = (campaignName: Campaings, userContributionEnabled = false) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.CAMPAIGN.GET,
    errorBinding: campaignNotReceived,
    loadingAction: ClaimableCampaignsActionTypes.FETCHING,
    urlParameters: { campaignName },
    parameters: { userContributionEnabled },
    method: 'get',
    successBinding: campaignReceived,
  });

const updateCampaign: UpdateClaimableCampaigns = (campaignName: Campaings, userContributionEnabled = false) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.CAMPAIGN.GET,
    errorBinding: campaignNotReceived,
    loadingAction: ClaimableCampaignsActionTypes.POLLING,
    urlParameters: { campaignName },
    parameters: { userContributionEnabled },
    method: 'get',
    successBinding: campaignReceived,
  });

const joinLeftCampaignSuccess = ({ data }): JoinLeftCampaignActionSuccess =>
  actionCreator.createAction(ClaimableCampaignsActionTypes.JOIN_LEFT_SUCCESS, { joining: data.joining });

const joinLeftCampaignError = ({ errorData }): JoinLeftCampaignActionError =>
  actionCreator.createErrorAction(ClaimableCampaignsActionTypes.JOIN_LEFT_ERROR, errorData);

const joinLeftCampaign = (campaignName: Campaings, joining: boolean) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.CAMPAIGN.RSVPS,
    errorBinding: joinLeftCampaignError,
    loadingAction: ClaimableCampaignsActionTypes.JOIN_LEFT_REQUEST,
    urlParameters: { campaignName },
    parameters: { joining },
    method: 'post',
    successBinding: joinLeftCampaignSuccess,
  });

export const claimableCampaignsActions = {
  fetchCampaign,
  updateCampaign,
  joinLeftCampaign,
};
