import { Alert } from 'model/alertTS';

export enum RewardFieldType {
  ALERT = 'ALERT',
}

export type RewardFieldAlert = Omit<Alert, 'type'> & {
  type: RewardFieldType.ALERT;
};

export type RewardField =
  | RewardFieldAlert
  | {
      type: RewardFieldType;
      deactivated: boolean;
    };
