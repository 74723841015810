import { ACTIVE_EXPERIMENTS, ACTIVE_EXPERIMENTS_TO_DELETE } from './experiments';

export const getActiveExperiments = () => {
  return [...ACTIVE_EXPERIMENTS, ...ACTIVE_EXPERIMENTS_TO_DELETE];
};

export const getExperimentsThatAreCurrentlyAssigned = () => {
  return ACTIVE_EXPERIMENTS;
};

export const generateSegment = segmentsNumber => Math.floor(Math.random() * segmentsNumber);
