import { Frame } from '@streamloots/streamloots-types';
import stateCreator from 'helpers/stateCreator';
import type { FramesState } from './types';
import { FramesActionTypes } from './actionTypes';

const initialState: FramesState = {
  filters: {},
};

const updateSingleFrame = (state: FramesState, updatedFrame: Frame) => {
  const { frames = [] } = state;
  const { _id: updatedFrameId } = updatedFrame;

  return frames.map(frame => {
    if (frame._id !== updatedFrameId) {
      return frame;
    }
    return updatedFrame;
  });
};

export const reducer = (state: FramesState = initialState, action): FramesState => {
  const { type, error, payload } = action;
  switch (type) {
    case FramesActionTypes.FETCHING: {
      const { filters, cancelToken } = payload;
      return stateCreator.getAsyncLoadingState(state, {
        filters,
        invalidateData: false,
        cancelToken,
      });
    }
    case FramesActionTypes.RECEIVED: {
      if (error) {
        return stateCreator.getAsyncErrorState(action, state);
      }

      return stateCreator.getInitialAsyncState(state, {
        ...payload,
      });
    }
    case FramesActionTypes.CREATED: {
      if (error) {
        return stateCreator.getAsyncErrorState(action, state);
      }
      const { frames = [] } = state;
      const { frame } = payload;

      return stateCreator.getInitialAsyncState(state, {
        frames: [...frames, frame],
      });
    }
    case FramesActionTypes.UPDATED: {
      if (error) {
        return stateCreator.getAsyncErrorState(action, state);
      }
      const { frame } = payload;
      const updatedFramesList = updateSingleFrame(state, frame);

      return stateCreator.getInitialAsyncState(state, {
        frames: [...updatedFramesList],
      });
    }
    default:
      return state;
  }
};
