// From: https://epicreact.dev/how-react-uses-closures-to-avoid-bugs/
import { useRef, useLayoutEffect, useMemo } from 'react';
import _ from 'lodash';

export function useDebounce(callback: (...args: any[]) => void, delay: number) {
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  return useMemo(() => _.debounce((...args) => callbackRef.current(...args), delay), [delay]);
}
