import { Component, ComponentType } from 'react';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { dataConciliatorSelectors } from 'services/data-conciliator';
import type { AsyncComponentProps } from 'types/indexTS';
import { setsActions, setsSelectors, FetchSets } from 'services/sets';
import { pageSelectors } from 'services/page';
import Spinner from 'ui-library/loading-spinner';
import { checkPermissions, permissions } from 'components/permissions';

interface MapStateToProps extends AsyncComponentProps {
  hasLoadedSets: boolean;
  slug: string;
  reconcileSets: boolean;
  invalidating?: boolean;
}

interface MapDispatchToProps {
  fetchSets: FetchSets;
}

interface ExtendedProps extends MapStateToProps, MapDispatchToProps {}

class SetsProviderInternal extends Component<ExtendedProps> {
  componentDidMount() {
    this.fetchSetsIfNeeded();
  }

  componentDidUpdate(prevProps) {
    const { reconcileSets } = this.props;
    this.fetchSetsIfNeeded(reconcileSets && !prevProps.reconcileSets);
  }

  fetchSetsIfNeeded = (reloadSets?: boolean) => {
    const { fetchSets, error, isLoading, hasLoadedSets, slug } = this.props;

    if (!isLoading && (!hasLoadedSets || reloadSets) && (!error || reloadSets)) {
      fetchSets(slug);
    }
  };

  render() {
    const { invalidating } = this.props;

    if (!invalidating) {
      return null;
    }

    return <Spinner fixed />;
  }
}

const mapStateToProps = (state): MapStateToProps => {
  const { isLoading, error } = setsSelectors.setsStatus(state);
  return {
    reconcileSets: dataConciliatorSelectors.reconcileSets(state),
    invalidating: dataConciliatorSelectors.invalidateSetOrUserSet(state),
    slug: pageSelectors.slug(state),
    hasLoadedSets: setsSelectors.hasLoadedSets(state),
    isLoading,
    error,
  };
};

export const SetsProvider = compose<ComponentType>(
  checkPermissions({ requiredPermissions: permissions.SETS_READ }),
  connect(mapStateToProps, {
    fetchSets: setsActions.fetchSets,
  }),
)(SetsProviderInternal);
