import ClassNames from '@streamloots/classnames';
import { paymentMethodsImages } from 'constants/images';
import { withTranslation, TranslateInterface } from 'utils/translation';
import Icon from 'ui-library/icons/Icons';
import theme from './payment-footer.scss';

const classNames = ClassNames(theme);

const PaymentFooterInternal = ({ t }: TranslateInterface) => {
  return (
    <div className={classNames('payment-footer__container')}>
      <div className={classNames('payment-footer__items')}>
        <Icon id="lock" />
        <span className={classNames('payment-footer__message')}>{t('lootPacks:acceptedPaymentMethods')}</span>
      </div>
      <div className={classNames('payment-footer__items')}>
        <img
          className={classNames('payment-footer__accepted-methods')}
          src={paymentMethodsImages.acceptedInline}
          alt="Credit card, paypal & paysafecard"
        />
      </div>
    </div>
  );
};

export const PaymentFooter = withTranslation('lootPacks')(PaymentFooterInternal);
