import axios from 'axios';
import config from 'config';
import { POST_MARKETING_AUTOMATION_REQUEST } from 'model/marketingAutomation';
import endpoints from 'services/endpoints';

export const saveMarketingAutomationEvent = async (data: POST_MARKETING_AUTOMATION_REQUEST): Promise<void> => {
  const baseApiUrl = config.streamloots.api;
  axios({
    method: 'post',
    url: `${baseApiUrl}${endpoints.MARKETING_AUTOMATION.CREATE}`,
    data,
  }).catch(error => console.error(error));
};
