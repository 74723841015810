import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from './actions';
import selectors from './selectors';

export interface UsePageAchievementActions {
  fetchAchievements: () => Promise<any>;
}

export const useAchievementActions = (): UsePageAchievementActions => {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      fetchAchievements: () => dispatch(actions.getAchievements()),
    }),
    [dispatch],
  );
};

export const useAchievementSelectors = () => {
  const canDoMoreAchievements = useSelector(selectors.canDoMoreAchievements);

  return {
    canDoMoreAchievements,
  };
};
