import { withTranslation as i18nextWithTranslation, Trans, Namespace, TFunction } from 'react-i18next';
import { MyReturnType } from 'types/indexTS';

export type I18nType = {
  language: string;
};

export type tFunction = TFunction<Namespace>;

export interface TranslateInterface {
  t: TFunction<Namespace>;
}

export interface i18nInterface {
  i18n: {
    language: string;
  };
}

type WithTranslationInterface = (...namespaces: Namespace[]) => MyReturnType<typeof i18nextWithTranslation>;

const withTranslation: WithTranslationInterface = (...namespaces) => {
  return i18nextWithTranslation(namespaces.flat());
};

export { withTranslation, Trans };
export { useTranslation } from 'react-i18next';
