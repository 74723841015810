import actionCreator from 'helpers/actionCreator';
import { ordersNormalizer } from 'services/orders';
import endpoints from 'services/endpoints';
import { LootOrderType } from '@streamloots/streamloots-types';
import { errorMapping } from '../../errorMapping';
import { CouponActionTypes } from '../../actionTypes';
import type { RedeemCouponActionSuccess, RedeemCouponActionError, ResetCouponAction } from './types';

const redeemCouponSuccess = ({ data, parameters }): RedeemCouponActionSuccess =>
  actionCreator.createAction(CouponActionTypes.REDEEMED, {
    order: ordersNormalizer.order(data),
    setId: parameters.setId,
  });

const redeemCouponError = ({ errorData }): RedeemCouponActionError =>
  actionCreator.createErrorAction(CouponActionTypes.REDEEMED, errorData, errorMapping);

const redeemCoupon = (couponCode: string, setId: string) => {
  const parameters = {
    items: [
      {
        item: {
          setId,
        },
      },
    ],
    couponCode,
    type: LootOrderType.COUPON,
  };
  return actionCreator.createAsyncAction({
    endpoint: endpoints.COUPONS.REDEEM,
    errorBinding: redeemCouponError,
    loadingAction: CouponActionTypes.REDEEMING,
    method: 'post',
    parameters,
    successBinding: redeemCouponSuccess,
  });
};

const resetCouponRedemption = (): ResetCouponAction => actionCreator.createAction(CouponActionTypes.REDEMPTION_RESET);

export const redeemCouponActions = {
  redeemCoupon,
  resetCouponRedemption,
};
