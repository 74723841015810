import { CardCount } from 'model/cardTS';

export const DEFAULT_COUNT: CardCount = {
  total: 0,
  redeemable: 0,
  redeemed: 0,
  disenchanted: 0,
  availableFragments: 0,
};

export const DEFAULT_STATE_BY_SET_ID = {
  filters: {
    options: [],
    searchString: '',
    rarities: [],
  },
};
