import { MarathonCreateFormValues } from 'model/indexTS';

export const MARATHON_DEFAULT_VALUES: MarathonCreateFormValues = {
  startingSeconds: 120 * 60,
  maximumExtendableSeconds: 480 * 60,
  lootsPurchasedIncluded: true,
  incrementByLootPurchasedInSeconds: 10,
  subscriptionsIncluded: true,
  incrementBySubscriptionsInSeconds: 10,
  widgetStyles: {
    fontStyle: 'Lato',
    fontSize: 116,
    fontColor: '#fff',
    viewerPositionActions: 'top',
  },
  showStreamerPageLink: true,
  twitchIntegrationEnabled: false,
  specialInteractions: false,
  incrementByTwitchBitsTransactionInSeconds: 10,
  incrementByTwitchSubscriptionsInSeconds: 10,
};

export const MARATHON_CONFIGURATION_FORM = 'marathonConfiguration';
