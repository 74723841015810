import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import ClassNames from '@streamloots/classnames';
import Button from 'ui-library/button';
import theme from './alerts-and-widgets.scss';

const classNames = ClassNames(theme);

interface MainPanelProps {
  imageUrl: string;
  isBlue?: boolean;
  title: string;
  description: string;
  url: string;
  buttonLabel: string;
  renderDisabled?: () => ReactNode;
  disabled?: boolean;
}

export const MainPanel = ({
  buttonLabel,
  isBlue,
  imageUrl,
  title,
  description,
  url,
  disabled,
  renderDisabled,
}: MainPanelProps): JSX.Element => {
  return (
    <div
      className={classNames({
        'widgets__panel': true,
        'widgets__panel--disabled': disabled,
      })}
    >
      <div
        className={classNames({
          'widgets__main__panel': true,
          'widgets__main__panel--blue': isBlue,
          'widgets__main__panel--disabled': disabled,
        })}
      >
        <div className={classNames('widgets__main__panel-texts')}>
          <h2 className={classNames('widgets__main__title')}>{title}</h2>
          <p className={classNames('margin-bottom')}>{description}</p>
          <Button primary component={Link} to={url} label={buttonLabel} disabled={disabled} />
        </div>
        <div className={classNames('widgets__main__panel-img')}>
          <img className={classNames('widgets__main__img')} src={imageUrl} alt="" />
        </div>
      </div>
      {disabled && renderDisabled && renderDisabled()}
    </div>
  );
};
