export const STATIC_IMAGES_PATH = 'https://static.streamloots.com/99d63dc9-60b1-4376-aea9-08592f04781d/';
export const STATIC_FILES_URL_PATH = 'https://static.streamloots.com/b355d1ef-d931-4c16-a48f-8bed0076401b/';
export const PAYMENT_METHODS_IMAGES_URL = `${STATIC_IMAGES_PATH}payment-methods/`;
export const MISCELLANEOUS_URL = `${STATIC_IMAGES_PATH}miscellaneous/`;
export const CHESTS_URL = `${STATIC_FILES_URL_PATH}chests/`;
export const GOALS_URL = `${STATIC_FILES_URL_PATH}goals/`;
export const CARD_GENERATOR_RESOURCES_URL = `${STATIC_FILES_URL_PATH}card-generator/`;
export const GIFT_COMMUNITY_RESOURCES_URL = `${STATIC_FILES_URL_PATH}gift-community/`;
export const CAMPAIGN_RESOURCES_URL = `${STATIC_FILES_URL_PATH}campaigns/`;
export const CAMPAIGN_IMAGES_URL = `${STATIC_IMAGES_PATH}campaigns/`;
export const BADGES_FOLDER = `${STATIC_FILES_URL_PATH}badges/`;

export const VIMEO_EMBEDDED_BASE_URL = 'https://player.vimeo.com/video/';

export const OBS_VIDEOS_PATH = `${STATIC_FILES_URL_PATH}obs/`;
export const LANDING_VIDEOS_PATH = `${STATIC_FILES_URL_PATH}landing-modules/`;
export const LANDING_MODULES_ASSETS = `${STATIC_FILES_URL_PATH}landing-modules/assets/`;
export const ONBOARDING_VIDEOS = `${STATIC_FILES_URL_PATH}onboarding-videos/`;

export const MISCELLANEOUS_IMAGES = {
  musicalNote: `${MISCELLANEOUS_URL}musical-note.svg`,
};

export const paymentMethodsImages = {
  paypal: `${PAYMENT_METHODS_IMAGES_URL}paypal-white.png`,
  paysafecard: `${PAYMENT_METHODS_IMAGES_URL}logo-paysafecard-corp.png`,
  paysafecardWhite: `${PAYMENT_METHODS_IMAGES_URL}logo-paysafecard.png`,
  creditCards: `${PAYMENT_METHODS_IMAGES_URL}credit_cards.png`,
  accepted: `${PAYMENT_METHODS_IMAGES_URL}accepted_methods.png`,
  acceptedInline: `${PAYMENT_METHODS_IMAGES_URL}accepted_methods_inline.png`,
};

export const OGP_DEFAULT_IMAGE = `${STATIC_IMAGES_PATH}ogp/social.png?timestamp=23232444`;
export const OGP_STREAMER_DEFAULT_IMAGE = `${STATIC_IMAGES_PATH}ogp/creator-share-default-en.png?timestamp=23232555`;
export const OGP_STREAMER_DEFAULT_IMAGE_ES = `${STATIC_IMAGES_PATH}ogp/creator-share-default-es.png?timestamp=23232555`;

export const DEFAULT_AVATAR_IMAGE = `https://static.streamloots.com/b355d1ef-d931-4c16-a48f-8bed0076401b/miscellaneous/avatar_default.png`;

export const HALLOWEEN = {
  ANIMATED_CHEST: `${CAMPAIGN_RESOURCES_URL}caldero_animado03.gif`,
  SPIDER_WEB_R: `${CAMPAIGN_RESOURCES_URL}spiderweb-right.svg`,
  SCREAMY_VIDEO: `${CAMPAIGN_RESOURCES_URL}loop_screamy.webm`,
  PANEL_BG: `${CAMPAIGN_RESOURCES_URL}bg-halloween-panel.png`,
  CANDY: `${CAMPAIGN_RESOURCES_URL}halloween-candy.png`,
};

export const CHRISTMAS = {
  DASHBOARD_PANEL_BG: `${CAMPAIGN_RESOURCES_URL}christmas/christmas-bg-min.png`,
  DASHBOARD_PABEL_BG_NO_SNOW: `${CAMPAIGN_RESOURCES_URL}christmas/christmas-modal-background-snow.png`,
  LOGO: `${CAMPAIGN_RESOURCES_URL}christmas/christmas-logo.png`,
  SNOWFLAKE: `${CAMPAIGN_RESOURCES_URL}christmas/snowflake.svg`,
  SNOW: `${CAMPAIGN_RESOURCES_URL}christmas/snow.png`,
  STREAMY_CLAUS: `${CAMPAIGN_RESOURCES_URL}loop_ScreamyClaus.webm`,
  GIFT: 'https://static.streamloots.com/b355d1ef-d931-4c16-a48f-8bed0076401b/campaigns/christmas_gift.png',
};

export const VALENTINES = {
  CHIPI_IMAGE: `${CAMPAIGN_RESOURCES_URL}valentines-chipi-min.jpg`,
  DASHBOARD_BG: `${CAMPAIGN_RESOURCES_URL}valentines-dashboard-bg-min.jpg`,
};

export const ARCADE_WEEK = {
  ARCADEAMY: `${CAMPAIGN_RESOURCES_URL}loop_arcadeamy.webm`,
};
