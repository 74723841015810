import stateCreator from 'helpers/stateCreator';
import { SetsActionTypes } from '../../actionTypes';
import type { PublishSetState } from './types';

export const reducer = (state: PublishSetState = {}, action): PublishSetState => {
  const { type, error } = action;
  switch (type) {
    case SetsActionTypes.PUBLISHING:
      return stateCreator.getAsyncLoadingState({});
    case SetsActionTypes.PUBLISHED: {
      if (error) {
        return stateCreator.getAsyncErrorState(action, {});
      }

      return { setPublished: true };
    }
    case SetsActionTypes.RESET_PUBLISH: {
      return {};
    }
    default:
      return state;
  }
};
