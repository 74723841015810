import * as React from 'react';

const RenderClient = ({ children }: { children: React.ReactNode }): JSX.Element | null => {
  const [isClient, setClient] = React.useState(false);

  React.useEffect(() => {
    setClient(true);
  }, []);

  if (!isClient) {
    return null;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

const Render = () => null;

Render.Client = RenderClient;

export default Render;
