import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';
import { usePreviousValue } from 'hooks/use-previous-value';
import scrollToTopManager from 'utils/scrollOnPageChangedManager';
import Render from 'components/render';
import ErrorBoundary, { IgnoreChildrenOnFatalError } from 'components/error-boundary';
import MetaTags from 'components/meta-tags';
import 'scss/styles.global.scss';
import { ViewportObserver } from 'components/viewport-observer';
import { ConnectedStreamerWelcome } from 'scenes/user-welcome';
import { CurrentUserFetcher } from 'scenes/user';
import { SidebarOpenerProvider } from 'contexts/sidebar-opener';
import { ConfirmProvider } from 'contexts/confirm';
import {
  // FloatingExtraTicketPromotion,
  ClaimExtraTicketModal,
} from 'ui-library/floating-promotion';
import { CampaingActive } from 'domain/campaing-active';
// import { TicketsCampaignChipiChipi } from 'components/campaign-tickets';
import {
  // BattlePassAnnouncement,
  BattlePassBoostProgress,
  // BattlePassUnlockedAlert,
} from 'components/christmas-campaign';
import TCModal from './components/tc-modal';
import FloatingNotifications from './components/floating-notifications';

interface AppProps extends Pick<RouteConfigComponentProps, 'route'> {
  mustAcceptTerms?: boolean;
  isUserAuthenticated: boolean;
}

const App = ({ mustAcceptTerms, route, isUserAuthenticated }: AppProps): JSX.Element => {
  const [isNewUser, setIsNewUser] = useState(mustAcceptTerms);
  const location = useLocation();
  const history = useHistory();
  const prevLocation = usePreviousValue(location);
  const isTicketCampaignTime = CampaingActive.isTicketsCampaignActive();
  // const isBattlePassCampaignTime = CampaingActive.isBattlepassCampaignActive();
  // const isFloatingPromotionTime = isTicketCampaignTime; // CampaingActive.check(Campaings.TICKETS_CAMPAIGN);
  const isCampaignTime = isTicketCampaignTime; //|| isFloatingPromotionTime;

  useEffect(() => {
    if (scrollToTopManager.shouldScrollToTop(location, prevLocation, history)) {
      window.scrollTo(0, 0);
    }

    if (!isNewUser && mustAcceptTerms) {
      setIsNewUser(true);
    }
  }, [history, isNewUser, location, mustAcceptTerms, prevLocation]);

  const renderUserAuthenticatedAdditions = () => {
    return (
      <>
        <CurrentUserFetcher />
        <IgnoreChildrenOnFatalError>
          <ConnectedStreamerWelcome />
          {mustAcceptTerms === false && !isNewUser && (
            <Render.Client>
              <FloatingNotifications />
              {isUserAuthenticated && <TCModal routerPath={location.pathname} />}
            </Render.Client>
          )}

          {isCampaignTime && mustAcceptTerms === false && (
            <Render.Client>
              {/* {isFloatingPromotionTime && (
                <>
                  <FloatingExtraTicketPromotion />
                </>
              )} */}
              {/* {isTicketCampaignTime && <TicketsCampaignChipiChipi />} */}
              {isTicketCampaignTime && <BattlePassBoostProgress />}
              {/* {isBattlePassCampaignTime && (
                <>
                  <BattlePassBoostProgress />
                  <BattlePassAnnouncement />
                  <BattlePassUnlockedAlert />
                </>
              )} */}
            </Render.Client>
          )}
        </IgnoreChildrenOnFatalError>
      </>
    );
  };

  const renderAdditions = () => {
    return (
      <>
        <MetaTags key="meta" />
        <Render.Client>
          <ViewportObserver />
          {isTicketCampaignTime && <ClaimExtraTicketModal />}
        </Render.Client>
        {isUserAuthenticated && renderUserAuthenticatedAdditions()}
      </>
    );
  };

  return (
    <ErrorBoundary>
      <ConfirmProvider>
        <SidebarOpenerProvider>
          {renderAdditions()}
          {renderRoutes(route?.routes)}
        </SidebarOpenerProvider>
      </ConfirmProvider>
    </ErrorBoundary>
  );
};

export default App;
