import { FunctionComponent } from 'react';
import { compose } from '@reduxjs/toolkit';
import { TranslateInterface } from 'utils/translation';
import { checkPermissions, permissions as definedPermissions } from 'components/permissions';
import { useCardCreator } from 'scenes/card-creator';
import { Rarity } from '@streamloots/streamloots-types';
import { usePermissionsSelectors } from 'services/permissions';
import { MainMenuPermissionLink } from './components/menu-content-components';
import theme from './menu.scss';
import { STAFF_LINKS } from './links';

const StaffMenuInternal = (props: TranslateInterface): JSX.Element => {
  const { permissions } = usePermissionsSelectors();
  const { openCreateCard } = useCardCreator();
  const handleOnAdminClick = () => {
    openCreateCard({
      adminMode: true,
      initialConfiguration: { name: '', rarity: Rarity.COMMON, description: '' },
    });
  };

  return (
    <div className={theme.menu__content}>
      {STAFF_LINKS.map(link => (
        <MainMenuPermissionLink
          onClickMapping={{ onAdminCardClick: handleOnAdminClick }}
          key={link.i18nKey}
          link={link}
          permissions={permissions}
          {...props}
        />
      ))}
    </div>
  );
};

export const StaffMenu = compose<FunctionComponent<TranslateInterface>>(
  checkPermissions({
    requiredPermissions: [
      definedPermissions.ADMIN,
      definedPermissions.ACCOUNT_MANAGER,
      definedPermissions.PARTNERSHIP_MANAGER,
      definedPermissions.SUPPORT,
    ],
  }),
)(StaffMenuInternal);
