import { History } from 'history';
import { FunctionComponent } from 'react';
import { compose } from '@reduxjs/toolkit';
import { TranslateInterface, withTranslation } from 'utils/translation';
import { checkPermissions, permissions as definedPermissions } from 'components/permissions';
import { FullLayout } from 'scenes/layout';
import loadable from 'components/dynamic-import';
import { PageHeader } from 'ui-library/headers';
import ClassNames from '@streamloots/classnames';
import theme from './page-subscription.scss';

const classNames = ClassNames(theme);

interface SubscriptionPageProps extends TranslateInterface {
  match: {
    params: {
      tab: string;
    };
  };
  history: History;
}

const WhatAreSubsOpener = loadable(() => import('./components/what-are-subs'));
const ManageSubscription = loadable(() => import('./ManageSubscription'));

const SubscriptionPageBase: FunctionComponent<SubscriptionPageProps> = ({
  t,
  ...rest
}: SubscriptionPageProps): JSX.Element => {
  return (
    <FullLayout>
      <PageHeader className={classNames('subs-header')}>
        <span>{t('pageSubscription:subscriptions')}</span> <WhatAreSubsOpener t={t} />
      </PageHeader>
      <ManageSubscription t={t} {...rest} />
    </FullLayout>
  );
};

export const SubscriptionPage = compose<FunctionComponent>(
  checkPermissions({
    requiredPermissions: [
      definedPermissions.PAGE_OWNER,
      definedPermissions.ADMIN,
      definedPermissions.SUPPORT,
      definedPermissions.ACCOUNT_MANAGER,
      definedPermissions.PARTNERSHIP_MANAGER,
      definedPermissions.STREAMER_SUCCESS_MANAGER,
    ],
    redirectToBasePage: true,
  }),
  withTranslation(['common', 'pageSubscription']),
)(SubscriptionPageBase);
