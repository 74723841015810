export const SEGMENTS_NAME: Array<string> = ['A', 'B', 'C', 'D', 'E'];

type ActiveExperiment = {
  name: string;
  segmentsNumber: number;
};

// Experiments that are currently being assigned to users
export const ACTIVE_EXPERIMENTS: Array<ActiveExperiment> = [
  {
    name: 'AATest-FakeTest',
    segmentsNumber: 2,
  },
  {
    name: 'AB-SubsVsTwitchMessage-June24',
    segmentsNumber: 2,
  },
];

// Experiments that aren't assigned to anyone and that we keep them to not impact in the users who show them.
// e.g. a coupon code that expires in 5 days
export const ACTIVE_EXPERIMENTS_TO_DELETE: Array<ActiveExperiment> = [];
