import type { Cooldown, UserCooldownDetail, UserCooldownStatus } from 'model/indexTS';
import { cooldownFormatter } from './cooldownFormatter';

const userCooldownInfo = (cooldownDetails: UserCooldownDetail): UserCooldownDetail => {
  const resetData = new Date(cooldownDetails.resetAt);
  const resetDataTime = resetData.getTime();
  const now = new Date();
  if (resetDataTime <= now.getTime()) {
    return {
      ...cooldownDetails,
      expirationDateTime: resetDataTime,
      remaining: cooldownDetails.limit,
    };
  }
  return { ...cooldownDetails, expirationDateTime: resetDataTime };
};

const userCooldownStatus = (userCooldown: UserCooldownStatus): UserCooldownStatus => {
  const normalizedUserCooldown: UserCooldownStatus = {};

  Object.keys(userCooldown).forEach(key => {
    normalizedUserCooldown[key] = userCooldownInfo(userCooldown[key]);
  });
  return normalizedUserCooldown;
};

const userCooldownResetAt = (userCooldown: UserCooldownStatus): Date | undefined => {
  let nextResetAt = null;

  Object.keys(userCooldown).forEach(key => {
    const cooldownDetails = userCooldown[key];
    if (cooldownDetails && cooldownDetails.remaining !== cooldownDetails.limit) {
      nextResetAt = cooldownDetails.resetAt;
    }
  });

  if (nextResetAt) {
    return new Date(nextResetAt);
  }

  return undefined;
};

const countdown = (cooldown: Cooldown | undefined): string | undefined => {
  if (!cooldown || !cooldown.configuration) {
    return undefined;
  }

  return cooldownFormatter.formatTime(cooldown.configuration.timeframeSeconds);
};

const userCooldownStatusFromCooldown = (cooldown: Cooldown | undefined): UserCooldownStatus | undefined => {
  if (!cooldown || !cooldown.rarities) {
    return undefined;
  }
  const { rarities } = cooldown;
  const previousDate = new Date(2017, 6, 6);

  const userCooldown: UserCooldownStatus = {};

  Object.keys(rarities).forEach(key => {
    const rarityLimit = rarities[key];
    if (!rarityLimit) {
      return;
    }
    userCooldown[key] = {
      limit: rarityLimit,
      remaining: rarityLimit,
      resetAt: previousDate.toISOString(),
      expirationDateTime: previousDate.getTime(),
    };
  });
  return userCooldown;
};

export default {
  countdown,
  userCooldownStatus,
  userCooldownStatusFromCooldown,
  userCooldownResetAt,
};
