import { permissions } from 'services/permissions';
import loadable from 'components/dynamic-import';
import { checkPermissions } from 'components/permissions';
import { CardCreatorLikeSkeleton } from 'components/component-skeletons';
import { CardCreatorScroll } from './withCardCreatorScroll';

const CardCreator = loadable(() => import('./card-creator'), true, <CardCreatorLikeSkeleton />);

type CardCreatorPageProps = {
  initialConfiguration: {
    description: string;
    name: string;
    rarity: string;
  };
  adminMode: boolean;
  scrollTo?: (top: number) => void;
};

const CardCreatorPage = ({ adminMode, initialConfiguration, scrollTo }: CardCreatorPageProps) => {
  return (
    <CardCreatorScroll scrollTo={scrollTo}>
      <CardCreator adminMode={adminMode} initialConfiguration={initialConfiguration} />
    </CardCreatorScroll>
  );
};

export default checkPermissions({
  redirectToBasePage: true,
  requiredPermissions: [
    permissions.ADMIN,
    permissions.SET_CARDS_EDIT,
    permissions.ACCOUNT_MANAGER,
    permissions.PARTNERSHIP_MANAGER,
    permissions.SUPPORT,
    permissions.STREAMER_SUCCESS_MANAGER,
  ],
})(CardCreatorPage);
