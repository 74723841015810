import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import Tooltip, { TooltipPlacementWithStrings } from 'ui-library/tooltip';
import theme from './tooltip-help.scss';

const classNames = ClassNames(theme);

type TooltipHelpIndicatorWrapperProps = {
  children: React.ReactNode;
  tooltip: string | React.ReactNode;
  className?: string;
  placement?: TooltipPlacementWithStrings;
};

export const TooltipHelpIndicatorWrapper = ({
  children,
  tooltip,
  placement = 'bottom',
  className = '',
}: TooltipHelpIndicatorWrapperProps): JSX.Element => {
  return (
    <Tooltip
      placement={placement}
      tooltip={tooltip}
      className={classNames({ 'tooltip-help': true, [className]: className })}
    >
      {children}
    </Tooltip>
  );
};
