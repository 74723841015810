import { FloatingNotificationResponse, FloatingNotification } from './typesTS';

export const getFloatingNotificationsSuccess = (
  data: FloatingNotificationResponse[],
): Record<string, FloatingNotification> => {
  return data.reduce((acc, notification) => {
    const { _id, metadata, type } = notification;
    const { imageUrl, internalLink, location: locales, rewards } = metadata;
    const floatingNotification = {
      _id,
      imageUrl,
      internalLink,
      locales,
      rewards,
      type,
    };

    return {
      ...acc,
      [_id]: floatingNotification,
    };
  }, {});
};

export default {
  getFloatingNotificationsSuccess,
};
