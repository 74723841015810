export enum CouponActionTypes {
  REVOKED = 'coupons/REVOKED',
  REVOKING = 'coupons/REVOKING',
  REDEEMED = 'coupons/REDEEMED',
  REDEEMING = 'coupons/REDEEMING',
  REDEMPTION_RESET = 'coupons/REDEMPTION_RESET',
  RECEIVED = 'coupons/RECEIVED',
  REQUEST = 'coupons/REQUEST',
  NEXT_RECEIVED = 'coupons/NEXT_RECEIVED',
  NEXT_REQUEST = 'coupons/NEXT_REQUEST',
  REQUEST_SINGLE_COUPON = 'coupons/REQUEST_SINGLE_COUPON',
  NEW_BUYER_RECEIVED = 'coupons/NEW_BUYER_RECEIVED',
  RECEIVED_SINGLE_COUPON = 'coupons/RECEIVED_SINGLE_COUPON',
  CREATED = 'coupons/CREATED',
  CREATING = 'coupons/CREATING',
}
