import { AuthProviders, UsageIntention } from '@streamloots/streamloots-types';
import { createSelector } from 'reselect';
import type {
  User,
  CurrentUserPage,
  Experiment,
  NetPromoterScore,
  UserStatistics,
  UserPreferences,
  Power,
} from 'model/indexTS';
import { createDeepEqualSelector } from 'utils/selectorUtils';
import type { UserState, UserAsyncStatus } from './types';

const userState = (state): UserState => state.user;

const userExperiments = createSelector(
  [userState],
  (userStateInfo: UserState): Experiment[] => userStateInfo.experiments || [],
);

const userExperimentsMemoized = createDeepEqualSelector(userExperiments, experiments => experiments || []);

const segmentsByExperimentName = createSelector(
  [userExperiments],
  (experiments: Experiment[]): Record<string, string> => {
    const mapping = {};
    if (!Array.isArray(experiments)) {
      return mapping;
    }

    experiments.forEach(experiment => {
      mapping[experiment.name] = experiment.segment;
    });
    return mapping;
  },
);

const userSegmentFromExperiment = createSelector(
  [segmentsByExperimentName, (_state, experimentName): string => experimentName],
  (segmentsByName, experimentName: string): string => {
    return segmentsByName[experimentName];
  },
);

const authProviders = createSelector([userState], (userStateInfo: UserState): AuthProviders[] => {
  const { user } = userStateInfo;
  return !user ? [] : user.authProviders;
});

const primaryAuthProvider = createSelector([userState], (userStateInfo: UserState): AuthProviders | undefined => {
  const { user } = userStateInfo;
  return user?.primaryAuthProvider;
});

const mustAcceptTerms = createSelector(
  [userState],
  (userStateInfo: UserState): boolean | undefined => userStateInfo.mustAcceptTerms,
);

const user = createSelector([userState], (userStateInfo: UserState): User | null => userStateInfo.user || null);

const username = createSelector([user], (userInfo: User | null): string => userInfo?.profile?.username ?? '');

const userId = createSelector([user], (userInfo: User | null): string => {
  return userInfo?._id || '';
});

const primaryEmail = createSelector([user], (userInfo: User | null): string => userInfo?.profile?.primaryEmail ?? '');

const currentUserCountryCode = createSelector([user], (userInfo: User | null): string => userInfo?.country || '');

const userSignUpDate = createSelector([user], (userInfo: User | null): string => userInfo?.createdAt || '');

const userAsyncStatus = createSelector(
  [userState, user],
  (userStateInfo: UserState, userInfo: User | null): UserAsyncStatus => {
    const { isLoading, error } = userStateInfo;
    return {
      error,
      isLoading,
      user: userInfo,
    };
  },
);

const netPromoterScore = createSelector(
  [user],
  (userInfo: User | null): NetPromoterScore | undefined => userInfo?.netPromoterScore || undefined,
);

const currentUserPage = createSelector(
  [user],
  (userInfo: User | null): CurrentUserPage | null => userInfo?.page || null,
);

const userPreferences = createSelector(
  [user],
  (userInfo: User | null): UserPreferences => (userInfo ? userInfo?.settings?.preferences || {} : {}),
);

const showOnboarding = createSelector([userState], (userStateInfo: UserState): boolean =>
  Boolean(userStateInfo.showOnboarding),
);

// This is just for streamer categorization. This only tells us if this user
// is categorized as a streamer, elephant or ant. BUT this doesn't mean necessarily
// that he/she has a page in streamloots.

const isStreamer = createSelector([user], (userInfo: User | null): boolean => {
  return Boolean(userInfo?.isStreamer);
});

const highlightCreateCollection = createSelector([userState], (fullUserState: UserState): boolean => {
  return Boolean(fullUserState.user?.isStreamer && fullUserState.showOnboarding);
});

const showSupportChat = createSelector([userState], (userStateInfo: UserState): boolean => {
  return userStateInfo && userStateInfo.user
    ? userStateInfo.user.isStreamer || userStateInfo.user.showSupportChat
    : false;
});

const alertEffect = createSelector(
  [user],
  (userInfo: User | null): Power | null => userInfo?.settings?.defaultAlertEffect || null,
);

const avatarImageUrl = createSelector(user, (userInfo: User | null): string => userInfo?.profile?.avatarImageUrl || '');

const userCategory = createSelector([user], (userInfo: User | null): string => userInfo?.category || '');

const userStatistics = createSelector([user], (userInfo: User | null): UserStatistics => userInfo?.statistics || {});

const hasRedeemedAnyCard = createSelector([userStatistics], (userStats: UserStatistics): boolean => {
  return Boolean(userStats && userStats.uniquePageRedemptions && userStats.uniquePageRedemptions > 0);
});
const userCreatedAt = createSelector([user], (userInfo: User | null): string => userInfo?.createdAt || '');

const userTwitterAccount = createSelector([user], (userInfo: User | null): string => userInfo?.twitterAcount || '');

const userDiscordServer = createSelector([user], (userInfo: User | null): string => userInfo?.discordServer || '');

const usageIntention = createSelector(
  [user],
  (userInfo: User | null): UsageIntention | null => userInfo?.usageIntention || null,
);

export const userSelectors = {
  userState,
  alertEffect,
  authProviders,
  avatarImageUrl,
  highlightCreateCollection,
  currentUserCountryCode,
  currentUserPage,
  isStreamer,
  mustAcceptTerms,
  netPromoterScore,
  segmentsByExperimentName,
  showOnboarding,
  user,
  userAsyncStatus,
  userExperiments,
  userExperimentsMemoized,
  userId,
  username,
  userSegmentFromExperiment,
  userPreferences,
  showSupportChat,
  primaryEmail,
  userSignUpDate,
  userCategory,
  userStatistics,
  hasRedeemedAnyCard,
  userCreatedAt,
  userTwitterAccount,
  userDiscordServer,
  usageIntention,
  primaryAuthProvider,
};
