import ClassNames from '@streamloots/classnames';
import { Languages } from '@streamloots/streamloots-types';
import { Tag } from 'model/tag';
import Icon from 'ui-library/icons';
import { SetStatus } from 'components/set';
import theme from './set-list-item.scss';

const classNames = ClassNames(theme);

export type SetListItemProps = {
  imageUrl: string;
  name: string;
  isActive: boolean;
  onClick: () => void;
  tags: Pick<Tag, 'locales' | 'value'>[];
  language: Languages;
  title: string;
  paused?: boolean;
  published?: boolean;
  hidePublishBug?: boolean;
};

export const SetListItem = ({
  imageUrl,
  name,
  isActive,
  onClick,
  tags = [],
  language,
  title,
  paused,
  published,
  hidePublishBug,
}: SetListItemProps): JSX.Element => {
  return (
    <div
      className={classNames({
        'set-list-item': true,
        'set-list-item--active': isActive,
      })}
      onClick={onClick}
      role="button"
    >
      <div className={classNames('set-list-item__image')}>
        <img src={imageUrl} alt={name} />
      </div>
      <div className={classNames('set-list-item__content')}>
        <span className={classNames('set-list-item__title')}>{name}</span>
        <ul className={classNames('set-list-item__tags')}>
          {tags.map(tag => (
            <li key={tag.value}>{tag.locales[language]}</li>
          ))}
        </ul>
        {paused && hidePublishBug && <SetStatus paused={paused} published={published} />}
        {!hidePublishBug && <SetStatus paused={paused} published={published} />}
      </div>
      <button type="button" className={classNames('set-list-item__button')} title={title}>
        <Icon id={isActive ? 'check' : 'viewdetail'} />
      </button>
    </div>
  );
};
