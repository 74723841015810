import { useContext, useEffect } from 'react';
import ClassNames from '@streamloots/classnames';
import type { Set } from 'model/indexTS';
import { withTranslation, TranslateInterface } from 'utils/translation';
import { SidebarOpenerContext } from 'contexts/sidebar-opener';
import Icon from 'ui-library/icons';
import { useSetsActions } from 'services/sets';
import { useUserSetsSelectors } from 'services/user-sets';
import { useCultureSelectors } from 'services/culture';
import { ManageCollectionsLink } from './ManageCollectionsLink';
import { SetListItem } from './SetListItem';
import theme from './setlist.scss';

const classNames = ClassNames(theme);

export interface SetProps extends Pick<Set, 'name' | 'imageUrl' | '_id' | 'paused' | 'tags'> {
  published?: boolean;
}

export type SetsListProps = {
  sets: SetProps[];
  hidePublishBug?: boolean;
};

export interface ExtendedProps extends SetsListProps, TranslateInterface {}

const SetListInternal = ({ sets = [], hidePublishBug, t }: ExtendedProps) => {
  const { close } = useContext(SidebarOpenerContext);
  const { language } = useCultureSelectors();
  const { changeSelectedSet } = useSetsActions();
  const { selectedUserSetId } = useUserSetsSelectors(); // It is the same as setsSelectors

  useEffect(() => {
    return () => {
      close();
    };
  }, [close]);

  function handleOnClick(setId) {
    if (selectedUserSetId !== setId) {
      changeSelectedSet(setId);
    }

    close();
  }

  return (
    <div>
      <ul className={classNames('set__list')}>
        {sets.map(set => {
          const isActive = selectedUserSetId === set._id;

          return (
            <li className={classNames('set__list-item')} key={set._id}>
              <div className={classNames('set__list-item-wrapper')}>
                <SetListItem
                  imageUrl={set.imageUrl}
                  name={set.name}
                  paused={set.paused}
                  published={set.published}
                  isActive={isActive}
                  onClick={() => handleOnClick(set._id)}
                  tags={set.tags || []}
                  language={language}
                  hidePublishBug={hidePublishBug}
                  title={isActive ? t('adminSets:selectedCollection') : t('adminSets:selectCollection')}
                />
              </div>

              <div
                className={classNames({
                  'set__see-collection': true,
                  'set__see-collection--active': isActive,
                })}
                onClick={() => handleOnClick(set._id)}
              >
                {isActive && (
                  <>
                    <Icon id="viewdetail" /> {t('adminSets:selectedCollection')}
                  </>
                )}
              </div>
            </li>
          );
        })}
      </ul>
      <ManageCollectionsLink t={t} />
    </div>
  );
};

export const SetList = withTranslation(['common', 'adminSets'])(SetListInternal);
