import * as React from 'react';
import { Redirect } from 'react-router-dom';
import ClassNames from '@streamloots/classnames';
import type { VerifyEmail } from 'services/user';
import { pages } from 'services/routing';
import { ValidateEmailSkeleton } from './ValidateEmailSkeleton';
import ExpiredPanel from './ExpiredPanel';
import UpdatedPanel from './UpdatedPanel';
import ErrorPanel from './ErrorPanel';
import EmailNotUpdatedPanel from './EmailNotUpdated';
import theme from './validate-email.scss';

const classNames = ClassNames(theme);

type ValidateEmailProps = {
  match: {
    params: Record<string, string>;
  };
  verifyEmail: VerifyEmail;
};

const ERRORS_API = {
  EMAIL_VERIFICATION_EXPIRED: 'EMAIL_VERIFICATION_EXPIRED',
  EMAIL_VERIFICATION_ALREADY_VERIFIED: 'EMAIL_VERIFICATION_ALREADY_VERIFIED',
  EMAIL_NOT_UPDATED: 'EMAIL_NOT_UPDATED',
  GENERIC_ERROR: 'GENERIC_ERROR',
};

export const ValidateEmail = ({ verifyEmail, match }: ValidateEmailProps): JSX.Element => {
  const [isLoading, setLoading] = React.useState(true);
  const [error, setError] = React.useState('');
  const { params } = match;
  React.useEffect(() => {
    if (params && params.verificationId) {
      verifyEmail(params.verificationId).then(response => {
        setLoading(false);
        if (!response.error) {
          return;
        }

        const { metadata } = response;

        if (
          metadata.businessCode === ERRORS_API.EMAIL_VERIFICATION_ALREADY_VERIFIED ||
          metadata.businessCode === ERRORS_API.EMAIL_VERIFICATION_EXPIRED
        ) {
          setError(metadata.businessCode);
          return;
        }

        setError(ERRORS_API.GENERIC_ERROR);
      });
    }
  }, [params, verifyEmail]);

  if (!params || !params.verificationId) {
    return <Redirect to={pages.USER_COLLECTIONS} />;
  }

  return (
    <div className={classNames('validate-email__wrapper')}>
      {isLoading && !error && <ValidateEmailSkeleton />}
      {error === ERRORS_API.GENERIC_ERROR && <ErrorPanel />}
      {error === ERRORS_API.EMAIL_VERIFICATION_EXPIRED && <ExpiredPanel />}
      {!isLoading && (!error || error === ERRORS_API.EMAIL_VERIFICATION_ALREADY_VERIFIED) && <UpdatedPanel />}
      {error === ERRORS_API.EMAIL_NOT_UPDATED && <EmailNotUpdatedPanel />}
    </div>
  );
};
