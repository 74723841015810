import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';
import { OpenProvider } from 'contexts/open-provider';
import { useLayoutSelectors } from 'services/layout';
import { ConnectedDialog } from 'components/overloaded-content';
// import { RewindAlert } from 'scenes/rewind';
import { MainMenu } from 'scenes/menu';
import { ChristmasDecoration } from 'scenes/app-layout/ChristmasDecoration';
import { CampaingActive, Campaings } from 'domain/campaing-active';
// import Render from 'components/render';
// import { IgnoreChildrenOnFatalError } from 'components/error-boundary';
import { LayoutObserver } from './LayoutObserver';
import { LayoutHeader } from './LayoutHeader';
import { LayoutLeftSidebar } from './LayoutLeftSidebar';
import { LayoutBody } from './LayoutBody';
import LayoutRightSidebar from './LayoutRightSidebar';
import theme from './layout.scss';

export type LayoutProps = RouteConfigComponentProps;

export const Layout = ({ route }: LayoutProps): JSX.Element => {
  const { isMobile = false } = useLayoutSelectors();
  const isChristmas = CampaingActive.check(Campaings.CHRISTMAS);

  return (
    <div className={theme.layout}>
      {isChristmas && <ChristmasDecoration />}
      <ConnectedDialog />
      <OpenProvider isOpen={!isMobile}>
        <LayoutObserver />
        <LayoutHeader isChristmas={isChristmas} />
        <LayoutLeftSidebar isMobile={isMobile}>
          <MainMenu />
        </LayoutLeftSidebar>
      </OpenProvider>
      <LayoutBody>{renderRoutes(route?.routes)}</LayoutBody>
      <LayoutRightSidebar />
      {/* <IgnoreChildrenOnFatalError>
        <Render.Client>
          <RewindAlert />
        </Render.Client>
      </IgnoreChildrenOnFatalError> */}
    </div>
  );
};
