import stateCreator from 'helpers/stateCreator';
import { PageActionTypes } from 'services/page';
import type { RankingActions, RankingState } from './types';
import { RankingActionTypes } from './actionTypes';
import { RankingStatus } from './types';

const updaterState = (state: RankingStatus, action: RankingActions): RankingStatus => {
  switch (action.type) {
    case RankingActionTypes.RECEIVED_SUCCESS: {
      const { data, filters } = action.payload;
      const newState = {
        ranking: [...data],
        filters,
        timestamp: new Date().getTime(),
      };
      return stateCreator.getInitialAsyncState(state, newState);
    }
    case RankingActionTypes.RECEIVED_ERROR: {
      return stateCreator.getAsyncErrorState(action, state);
    }
    case RankingActionTypes.RECEIVED_REQUEST: {
      const { payload, metadata } = action;
      return stateCreator.getAsyncLoadingState(state, {
        filters: payload,
        cancelToken: metadata.cancelToken,
        timestamp: undefined,
      });
    }
    default:
      return state;
  }
};

export default (state: RankingState = {}, action: RankingActions): RankingState => {
  switch (action.type) {
    case RankingActionTypes.RECEIVED_REQUEST:
    case RankingActionTypes.RECEIVED_SUCCESS:
    case RankingActionTypes.RECEIVED_ERROR: {
      return stateCreator.getUpdatedStateByKey(
        state,
        action,
        action.metadata.componentKey || 'KING_MIDAS',
        updaterState,
      );
    }
    case PageActionTypes.CHANGED:
      return {};
    default:
      return state;
  }
};
