import type { PaginationStateNormalizerConfiguration } from 'services/pagination/typesTS';
import type { PagesQuery, PagesStateByComponentKey } from './typesTS';

export const DEFAULT_PAGES_QUERY = {
  limit: 48,
};

export const PAGINATION_STATE_NORMALIZER_CONFIGURATION: PaginationStateNormalizerConfiguration<PagesQuery> = {
  initialQuery: DEFAULT_PAGES_QUERY,
};

export const EMPTY_STATUS: PagesStateByComponentKey = {
  query: DEFAULT_PAGES_QUERY,
};
