import { useMemo } from 'react';
import { matchPath } from 'react-router-dom';
import { MenuSections } from 'model/userTS';
import { TranslateInterface } from 'utils/translation';
import { InjectedPermissions } from 'components/permissions/types';
import { pages } from 'services/routing';
import { MainMenuPermissionLink, MenuExpandableSection } from './components/menu-content-components';
import { canRenderMenuItem, SpecialConditionsProps } from './menu-permissions-checker';
import type { LinkSection } from './links';

export interface CreatorMenuSectionProps extends InjectedPermissions, TranslateInterface, SpecialConditionsProps {
  currentPathname: string;
  linkSection: LinkSection;
  minimumLevel?: number;
  onClickMapping?: { [mappingKey: string]: () => void };
  onToggle: (menuId: MenuSections, isOpen: boolean) => void;
  isForcedOpen: boolean;
}

export const CreatorMenuSection = (props: CreatorMenuSectionProps): JSX.Element | null => {
  const { currentPathname, linkSection, permissions, isForcedOpen, onToggle, t } = props;
  const { i18nKey, links, icon, id } = linkSection;

  const isSubpageSelected = useMemo(() => {
    return links.some(link => {
      const linkPage = pages[link?.toUrlKey || ''];
      const match = matchPath(currentPathname, linkPage);
      return Boolean(match);
    });
  }, [currentPathname, links]);

  const handleToggle = (isOpen: boolean) => {
    onToggle(id, isOpen);
  };

  if (!canRenderMenuItem(linkSection, permissions, props)) {
    return null;
  }

  return (
    <MenuExpandableSection
      label={t(i18nKey)}
      icon={icon}
      isSubpageSelected={isSubpageSelected}
      onToggle={handleToggle}
      isForcedOpen={isForcedOpen}
    >
      {links.map(link => (
        <MainMenuPermissionLink key={link.i18nKey} link={link} {...props} />
      ))}
    </MenuExpandableSection>
  );
};
