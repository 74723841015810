import ClassNames from '@streamloots/classnames';
import { TooltipHelpIndicatorIcon } from 'components/help-tooltip';
import theme from './alerts-and-widgets.scss';

const classNames = ClassNames(theme);

interface AlertsAndWidgetsTitleProps {
  title: string;
  tooltip?: string;
}

export const AlertsAndWidgetsTitle = ({ title, tooltip }: AlertsAndWidgetsTitleProps): JSX.Element => {
  return (
    <h2 className={classNames('widgets__title')}>
      {title}
      {tooltip && <TooltipHelpIndicatorIcon tooltip={tooltip} className={classNames('widgets__titlte__tooltip')} />}
    </h2>
  );
};
