import stateCreator from 'helpers/stateCreator';
import { PageActionTypes } from 'services/page';
import { EmotesCollectionsActionTypes } from './actionTypes';
import { EmotesCollectionsActions, EmotesCollectionState } from './types';

const initialState: EmotesCollectionState = {};

export const reducer = (state = initialState, action: EmotesCollectionsActions): EmotesCollectionState => {
  switch (action.type) {
    case EmotesCollectionsActionTypes.CREATE_EMOTES_COLLECTIONS_REQUEST:
    case EmotesCollectionsActionTypes.DELETE_EMOTES_COLLECTIONS_REQUEST:
    case EmotesCollectionsActionTypes.UPDATE_EMOTES_COLLECTIONS_REQUEST:
    case EmotesCollectionsActionTypes.UPDATE_STREAMER_EMOTES_COLLECTION_REQUEST:
    case EmotesCollectionsActionTypes.FETCH_EMOTES_COLLECTIONS_REQUEST: {
      return { ...state, ...stateCreator.getAsyncLoadingState(state) };
    }

    case EmotesCollectionsActionTypes.CREATE_EMOTES_COLLECTIONS_ERROR:
    case EmotesCollectionsActionTypes.DELETE_EMOTES_COLLECTIONS_ERROR:
    case EmotesCollectionsActionTypes.UPDATE_EMOTES_COLLECTIONS_ERROR:
    case EmotesCollectionsActionTypes.UPDATE_STREAMER_EMOTES_COLLECTION_ERROR:
    case EmotesCollectionsActionTypes.FETCH_EMOTES_COLLECTIONS_ERROR: {
      return stateCreator.getAsyncErrorState(action, state);
    }

    case EmotesCollectionsActionTypes.FETCH_EMOTES_COLLECTIONS_SUCCESS: {
      return stateCreator.getInitialAsyncState(state, {
        ...state,
        collections: action.payload,
      });
    }

    case EmotesCollectionsActionTypes.CREATE_EMOTES_COLLECTIONS_SUCCESS: {
      if (!state.collections) {
        return state;
      }
      return stateCreator.getInitialAsyncState(state, {
        ...state,
        collections: [...state.collections, action.payload],
      });
    }

    case EmotesCollectionsActionTypes.DELETE_EMOTES_COLLECTIONS_SUCCESS: {
      if (!state.collections) {
        return state;
      }
      return stateCreator.getInitialAsyncState(state, {
        collections: state.collections.filter(collection => collection._id !== action.payload._id),
      });
    }

    case EmotesCollectionsActionTypes.UPDATE_EMOTES_COLLECTIONS_SUCCESS: {
      if (!state.collections) {
        return state;
      }
      return stateCreator.getInitialAsyncState(state, {
        collections: state.collections.map(collection => {
          if (collection._id !== action.payload._id) {
            return collection;
          }
          return action.payload;
        }),
      });
    }

    /*
    case EmotesCollectionsActionTypes.UPDATE_STREAMER_EMOTES_COLLECTION_SUCCESS: {
      if (!state.collections) {
        return state;
      }

      return stateCreator.getInitialAsyncState(state, {
        collections: state.collections.map(collection => {
          if (collection._id !== action.payload._id) {
            return collection;
          }
          return {
            ...collection,
            locales: {
              name: {
                en: action.payload.locales.name.en,
                es: action.payload.locales.name.es,
              },
            },
          };
        }),
      });
    }
    */

    case PageActionTypes.CHANGED: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
