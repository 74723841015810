import React from 'react';
import { captureErrorBoundarySentry } from 'utils/sentry';
import { FatalError } from 'scenes/errors';

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  state = {
    hasError: false,
  };

  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    this.setState({ hasError: true });

    captureErrorBoundarySentry(error, info);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div className="padding-top-x4">
          <FatalError />
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
