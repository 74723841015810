import { createSelector } from 'reselect';
import type { AlertProviders } from 'model/indexTS';
import type { AlertProvidersState } from './types';

const alertProvidersState = (state): AlertProvidersState => state.alertProviders;

const alertProviders = createSelector(
  [alertProvidersState],
  (alertProvidersStateInfo): AlertProviders | undefined => alertProvidersStateInfo.alertProviders,
);

const provider = createSelector([alertProviders], alertProvidersInfo =>
  alertProvidersInfo ? alertProvidersInfo.provider : '',
);

const streamlabsConnected = createSelector(
  [alertProvidersState],
  (alertProvidersStateInfo): boolean => alertProvidersStateInfo.streamlabsConnected || false,
);

const alertWidgetUrl = createSelector(
  [alertProvidersState],
  (alertProvidersStateInfo): string => alertProvidersStateInfo.alertWidgetUrl || '',
);

export const alertProvidersSelectors = {
  alertProviders,
  provider,
  alertWidgetUrl,
  streamlabsConnected,
};
