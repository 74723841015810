import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import type { FetchPublicOriginalsCardsActionError, FetchPublicOriginalsCardsActionSuccess } from './types';
import { OriginalsPublicActionTypes } from './actionTypes';
import * as normalizer from './normalizer';
import { FetchPublicOriginalsArgs } from './types';

const fetchPublicOriginalsCardsSuccess = ({ additionalData, data }): FetchPublicOriginalsCardsActionSuccess => {
  const { templates, frame } = data;
  const { filters } = additionalData;

  return actionCreator.createAction(
    OriginalsPublicActionTypes.FETCH_PUBLIC_ORIGINALS_SUCCESS,
    {
      filters,
      originals: normalizer.normalizeOriginals(templates, frame),
      frame,
    },
    { componentKey: additionalData.componentKey },
  );
};

const fetchPublicOriginalsCadsError = ({ additionalData, errorData }): FetchPublicOriginalsCardsActionError =>
  actionCreator.createErrorAction(
    OriginalsPublicActionTypes.FETCH_PUBLIC_ORIGINALS_ERROR,
    errorData,
    {},
    { componentKey: additionalData.componentKey },
  );

const fetchPublicOriginalsCards = (args: FetchPublicOriginalsArgs) => {
  const { componentKey, filters, frameId } = args;
  return actionCreator.createAsyncAction({
    additionalData: {
      filters,
      componentKey,
    },
    endpoint: endpoints.ORIGINALS.GET,
    errorBinding: fetchPublicOriginalsCadsError,
    loadingAction: () =>
      actionCreator.createAction(
        OriginalsPublicActionTypes.FETCH_PUBLIC_ORIGINALS_REQUEST,
        { filters },
        { componentKey },
      ),
    queryParameters: filters,
    urlParameters: { frameId },
    method: 'get',
    successBinding: fetchPublicOriginalsCardsSuccess,
  });
};

export const originalsPublicActions = {
  fetchPublicOriginalsCards,
};
