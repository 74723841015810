import { paginationStateNormalizer } from 'services/pagination';
import type { UserCollectionsState } from './types';
import { UserCollectionsActionTypes } from './actionTypes';
import { PAGINATION_STATE_NORMALIZER_CONFIGURATION } from './constants';

const initialState: UserCollectionsState = paginationStateNormalizer.getInitial(
  PAGINATION_STATE_NORMALIZER_CONFIGURATION,
);

export default (state: UserCollectionsState = initialState, action): UserCollectionsState => {
  const { type, payload, error } = action;
  switch (type) {
    case UserCollectionsActionTypes.RECEIVED: {
      if (error) {
        return paginationStateNormalizer.getReceivedError(action, state);
      }

      return paginationStateNormalizer.getReceived(payload, PAGINATION_STATE_NORMALIZER_CONFIGURATION);
    }
    case UserCollectionsActionTypes.REQUEST: {
      return paginationStateNormalizer.getRequest(state, payload, PAGINATION_STATE_NORMALIZER_CONFIGURATION);
    }
    case UserCollectionsActionTypes.NEXT_REQUEST:
      return paginationStateNormalizer.getFetchingNextItems(state, payload.cancelToken);

    case UserCollectionsActionTypes.NEXT_RECEIVED: {
      if (error) {
        return paginationStateNormalizer.getNextReceivedError(state);
      }
      return paginationStateNormalizer.getNextItemsReceived(state, payload);
    }

    case UserCollectionsActionTypes.STOP_WATCHING_COLLECTION: {
      if (error) {
        return state;
      }

      const filteredData = state.data?.filter(collection => collection.slug !== payload.slug) || [];

      return {
        ...state,
        data: filteredData,
      };
    }

    case UserCollectionsActionTypes.START_WATCHING_COLLECTION: {
      if (error) {
        return state;
      }

      const filteredData = state.data?.filter(collection => collection.slug !== payload.slug) || [];

      return {
        ...state,
        data: filteredData,
      };
    }

    default:
      return state;
  }
};
