import stateCreator from 'helpers/stateCreator';
import actionTypes from './actionTypes';
import { CheckoutActionTypes } from '../../actionTypes';
import { CouponActionsTypes, CheckoutCouponState } from './types';

const couponState: CheckoutCouponState = {
  coupon: null,
  couponValidation: '',
};

export default (state: CheckoutCouponState = couponState, action: CouponActionsTypes) => {
  switch (action.type) {
    case actionTypes.GET_SINGLE_COUPON_REQUEST: {
      return stateCreator.getAsyncLoadingState(state);
    }
    case actionTypes.GET_SINGLE_COUPON_SUCCESS: {
      return stateCreator.getInitialAsyncState({
        ...state,
        coupon: action.payload.coupon,
        couponValidation: '',
      });
    }

    case actionTypes.GET_SINGLE_COUPON_ERROR: {
      return stateCreator.getAsyncErrorState(action, state);
    }

    case actionTypes.COUPON_NOT_VALID: {
      return stateCreator.getInitialAsyncState({
        ...state,
        couponValidation: 'COUPON_NOT_VALID',
      });
    }

    case actionTypes.INVALIDATE_COUPON:
    case CheckoutActionTypes.RESET_CHECKOUT: {
      return couponState;
    }

    default:
      return state;
  }
};
