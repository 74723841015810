export enum SubscriptionsActionTypes {
  FETCHING = 'subscriptions/FETCHING',
  RECEIVED = 'subscriptions/RECEIVED',
  ERROR = 'subscriptions/ERROR',
  ALERT_PLAYED = 'subscriptions/ALERT_PLAYED',
  ALERT_PLAYED_ERROR = 'subscriptions/ALERT_PLAYED_ERROR',
  CREATE_SUBCRIPTION_ORDER_REQUEST = 'subscriptions/CREATE_SUBCRIPTION_ORDER_REQUEST',
  CREATE_SUBSCRIPTION_ORDER_SUCCESS = 'subscriptions/CREATE_SUBSCRIPTION_ORDER_SUCCESS',
  CREATE_SUBSCRIPTION_ORDER_ERROR = 'subscriptions/CREATE_SUBSCRIPTION_ORDER_ERROR',
  EXECUTE_SUBSCRIPTION_ORDER_REQUEST = 'subscriptions/EXECUTE_SUBSCRIPTION_ORDER_REQUEST',
  EXECUTE_SUBSCRIPTION_ORDER_SUCCESS = 'subscriptions/EXECUTE_SUBSCRIPTION_ORDER_SUCCESS',
  EXECUTE_SUBSCRIPTION_ORDER_ERROR = 'subscriptions/EXECUTE_SUBSCRIPTION_ORDER_ERROR',
  CANCEL_SUBSCRIPTION_REQUEST = 'subscriptions/CANCEL_SUBSCRIPTION_REQUEST',
  CANCEL_SUBSCRIPTION_SUCCESS = 'subscriptions/CANCEL_SUBSCRIPTION_SUCCESS',
  CANCEL_SUBSCRIPTION_ERROR = 'subscriptions/CANCEL_SUBSCRIPTION_ERROR',
  FETCH_PAYPAL_SUCCESS = 'subscriptions/FETCH_PAYPAL_SUCCESS',
  FETCH_PAYPAL_ERROR = 'subscriptions/FETCH_PAYPAL_ERROR',
  FETCH_PAYPAL_LOADING = 'subscriptions/FETCH_PAYPAL_LOADING',
  APPROVE_PAYPAL_SUCCESS = 'subscriptions/APPROVE_PAYPAL_SUCCESS',
  APPROVE_PAYPAL_ERROR = 'subscriptions/APPROVE_PAYPAL_ERROR',
  APPROVE_PAYPAL_LOADING = 'subscriptions/APPROVE_PAYPAL_LOADING',
  CLAIM_PACK_SUCCESS = 'page-subscriptions/CLAIM_PACK_SUCCESS',
  CLAIM_PACK_ERROR = 'page-subscriptions/CLAIM_PACK_ERROR',
  CLAIM_PACK_REQUEST = 'page-subscriptions/CLAIM_PACK_FETCH',
}
