import { ReactNode } from 'react';
import ClassNames from '@streamloots/classnames';
import theme from './streamercard.scss';

const classNames = ClassNames(theme);

interface StreamerCardListProps {
  children: ReactNode;
  layout?: 'old' | 'new';
}

export const StreamerCardList = ({ children, layout = 'old' }: StreamerCardListProps): JSX.Element => {
  return <div className={classNames(layout === 'new' ? 'streamer-list' : 'card-list')}>{children}</div>;
};
