import React from 'react';

type CardCreatorScrollContextType = {
  scrollTo: (top: number) => void;
} | null;

const CardCreatorScrollContext = React.createContext<CardCreatorScrollContextType>(null);

type CardCreatorScrollProps = {
  children: React.ReactNode;
  scrollTo?: (top: number) => void;
};

export const CardCreatorScroll = ({ children, scrollTo }: CardCreatorScrollProps): JSX.Element => {
  const value = scrollTo ? { scrollTo } : null;
  return <CardCreatorScrollContext.Provider value={value}>{children}</CardCreatorScrollContext.Provider>;
};

const useCardCreatorScroll = () => {
  const context = React.useContext(CardCreatorScrollContext);
  if (!context) {
    console.error('CardCreatorScrollContext not defined');
  }

  return context;
};

export const withCardCreatorScroll = (Component: any): any => {
  function WrapperComponent(componentProps) {
    const props = useCardCreatorScroll();
    return <Component {...componentProps} {...props} />;
  }

  return WrapperComponent;
};
