import ClassNames from '@streamloots/classnames';
import { useTranslation } from 'utils/translation';
import { PageHeader } from 'ui-library/headers';
import theme from './marathon.scss';

const classNames = ClassNames(theme);

export const MarathonPageHeader = (): JSX.Element => {
  const { t } = useTranslation('marathon');
  return (
    <header className={classNames('marathon__header')}>
      <PageHeader>{t('marathon:title')}</PageHeader>
    </header>
  );
};
