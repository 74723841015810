import { PageType, StrictOmit, PAGE_GET_RESPONSE_STAFF_SUCCESS, PageFlags } from '@streamloots/streamloots-types';
import {
  AffiliateApplication,
  PartnershipApplication,
  ACHIEVEMENT_BADGE_MAPPING,
  NormalizedPageData,
  PageCooldown,
  PageModules,
  ModulesPlans,
  AchievementBadgeInfo,
  AchievementBadgeApiResponse,
  PartnershipProgress,
  PartnershipRequirements,
} from 'model/indexTS';
import CRAFTING_MODE_RESOURCES_IMAGES from 'constants/crafting-mode-resources';
import { PlanItem } from 'model/plans';
import { PageState } from 'services/page/types';
import { Campaings } from 'domain/campaing-active';

const normalizedPermissions = (
  data: Pick<PAGE_GET_RESPONSE_STAFF_SUCCESS, 'rankingVisibility' | 'permissions' | 'type'>,
): Array<string> => {
  const { type, permissions, rankingVisibility } = data;
  const permissionsArray = permissions ? permissions.slice(0) : [];
  const pageTypePermissions = `page-type:${type.toLowerCase()}`;

  if (type && permissionsArray.indexOf(pageTypePermissions) === -1) {
    permissionsArray.push(pageTypePermissions);
  }

  if (!rankingVisibility) {
    return permissionsArray;
  }

  const purchasesRankingPermissions = 'local-permission:rankings:purchases';
  const redemptionsRankingPermissions = 'local-permission:rankings:redemptions';

  if (rankingVisibility.purchasesRanking && permissionsArray.indexOf(purchasesRankingPermissions) === -1) {
    permissionsArray.push(purchasesRankingPermissions);
  }

  if (rankingVisibility.redemptionsRanking && permissionsArray.indexOf(redemptionsRankingPermissions) === -1) {
    permissionsArray.push(redemptionsRankingPermissions);
  }

  return permissionsArray;
};

const normalizedCooldown = (
  data: Pick<PAGE_GET_RESPONSE_STAFF_SUCCESS, 'redemptionLimits' | 'redemptionRateLimits'>,
): PageCooldown => ({
  cooldown: data.redemptionLimits,
  userCooldownStatus: data.redemptionRateLimits,
});

const normalizedPlan = (activePlanData: PlanItem | undefined, type: PageType, flags): ModulesPlans | undefined => {
  if (!activePlanData) {
    return undefined;
  }

  const { fees } = activePlanData;

  const auctionsPlan = type === PageType.PARTNER ? 96 : 94;
  const subsPlan = flags?.plan100PromotionEnabled ? 100 : 80;

  const feesSum = fees?.map(fee => fee.variableFee).reduce((accumulator, fee) => accumulator + fee, 0) ?? 0;
  const cardsPlan = (1 - feesSum) * 100;
  const auctionsCurrentPlan = Math.max(cardsPlan, auctionsPlan);

  return {
    cards: `${cardsPlan}%`,
    auctions: `${auctionsCurrentPlan}%`,
    subscriptions: `${subsPlan}%`,
    marathonsInteractions: `${cardsPlan}%`,
    donations: '100%',
  };
};

export const normalizeAchievementBadges = (
  achievementBadges: AchievementBadgeApiResponse[] | undefined,
): AchievementBadgeInfo[] | undefined => {
  if (!achievementBadges?.length) {
    return undefined;
    // return [
    //   {
    //     id: ACHIEVEMENT_BADGE_MAPPING[AchievementBadgeId.TopStreamer].id,
    //     achievedAt: new Date().toISOString(),
    //     imageUrl: ACHIEVEMENT_BADGE_MAPPING[AchievementBadgeId.TopStreamer].imageUrl,
    //     count: 1,
    //   },
    // ];
  }

  const badges: AchievementBadgeInfo[] = [];

  achievementBadges.forEach(badge => {
    const badgeInfo = ACHIEVEMENT_BADGE_MAPPING[badge.id];
    if (!badgeInfo) {
      return;
    }

    badges.push({
      id: badgeInfo.id,
      achievedAt: new Date().toISOString(),
      imageUrl: badgeInfo.imageUrl,
      count: 1,
    });
  });

  return badges;
};

interface NEW_PAGE_GET_RESPONSE_STAFF_SUCCESS
  extends StrictOmit<
    PAGE_GET_RESPONSE_STAFF_SUCCESS,
    'modules' | 'flags' | 'partnershipProgress' | 'partnershipRequirements'
  > {
  modules: PageModules;
  ambassador?: boolean;
  flags: PageFlags & {
    campaigns: {
      givebackfriday2022: {
        history: Array<{
          createdAt: string;
          joining: boolean;
        }>;
        createdAt: string;
        joining: boolean;
      };
      sub90Promo202302: {
        history: Array<{
          createdAt: string;
          joining: boolean;
        }>;
        createdAt: string;
        joining: boolean;
      };
    };
  };
  achievementBadges?: AchievementBadgeApiResponse[]; // TODO BADGES
  partnershipProgress?: PartnershipProgress;
  partnershipRequirements?: PartnershipRequirements;
}

export const getNormalizedPage = (
  pageResponseData: NEW_PAGE_GET_RESPONSE_STAFF_SUCCESS & { aboutMe?: string },
): NormalizedPageData => {
  const {
    ambassador = false,
    activePlan,
    affiliateApplication,
    primaryCurrency,
    redemptionLimits,
    redemptionRateLimits,
    type,
    permissions,
    headerImageUrl,
    dustImageUrl,
    rankingVisibility,
    closerId,
    closer,
    accountManagerId,
    accountManager,
    community,
    description,
    title,
    usageData,
    userHasCollection,
    constraints: { filesTotalMaxBytesAllowed = 0 } = {},
    sellerHasSoldThisWeek,
    language,
    aboutMe,
    flags,
    achievementBadges, // TODO BADGES
    ...rest
  } = pageResponseData;

  const pageType = affiliateApplication && type === PageType.AFFILIATE ? PageType.REGULAR : type;

  const campaigns = flags?.campaigns || {};

  return {
    ...rest,
    achievementBadges: normalizeAchievementBadges(achievementBadges), // TODO BADGES
    flags,
    backFriday2022Joined: campaigns[Campaings.SUB_90_PROMO]?.joining,
    ambassador,
    affiliateApplication,
    rankingVisibility: rankingVisibility || {},
    currency: primaryCurrency,
    modulesPlans: normalizedPlan(activePlan, pageType, flags),
    internalManagement: {
      closerId,
      closer,
      accountManagerId,
      accountManager,
      community,
    },
    type: pageType,
    ...normalizedCooldown({ redemptionLimits, redemptionRateLimits }),
    permissions: normalizedPermissions({
      permissions,
      rankingVisibility,
      type: pageType,
    }),
    customization: {
      headerImageUrl,
      dustImageUrl: dustImageUrl || CRAFTING_MODE_RESOURCES_IMAGES[0],
      language,
      aboutMe,
    },
    metadata: {
      description,
      title,
    },
    usageStatistics: usageData,
    userHasCollection,
    filesTotalMaxBytesAllowed,
    sellerHasSoldThisWeek,
  };
};

const newTypeNotification = (
  affiliateApplication?: AffiliateApplication,
  partnershipApplication?: PartnershipApplication,
): PageType | undefined => {
  if (
    partnershipApplication &&
    partnershipApplication.status !== 'REJECTED' &&
    partnershipApplication.status !== 'PENDING' &&
    !affiliateApplication
  ) {
    return PageType.PARTNER;
  }

  if (!affiliateApplication) {
    return undefined;
  }

  const { createdAt } = affiliateApplication;

  const now = new Date();
  const affiliationAcceptedDate = new Date(createdAt);
  affiliationAcceptedDate.setHours(affiliationAcceptedDate.getHours() + 1);

  if (now < affiliationAcceptedDate) {
    return undefined;
  }
  return PageType.AFFILIATE;
};

export const getPageState = (
  payload: NormalizedPageData,
  currentUserId = '',
): StrictOmit<PageState, 'currentUserId' | 'inPageContext' | 'inPublicPageContext'> => {
  const {
    type,
    ownerId,
    affiliateApplication,
    partnershipApplication,
    ban = undefined,
    newBuyerCouponCode = undefined,
    ...rest
  } = payload;

  return {
    type,
    newBuyerCouponCode,
    ownerId,
    isAffiliate: type === PageType.AFFILIATE,
    isLoaded: true,
    isOwner: Boolean(currentUserId) && ownerId === currentUserId,
    isPartner: type === PageType.PARTNER,
    newTypeNotification: newTypeNotification(affiliateApplication, partnershipApplication),
    affiliateApplication,
    partnershipApplication,
    ban,
    ...rest,
  };
};
