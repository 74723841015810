import stateCreator from 'helpers/stateCreator';
import { CommunityLoveRankingActions, CommunityLoveRankingState } from './types';
import { GlobalCommunityLoveRankingActionTypes } from './actionTypes';

const initialState: CommunityLoveRankingState = {};

export const reducer = (
  state: CommunityLoveRankingState = initialState,
  action: CommunityLoveRankingActions,
): CommunityLoveRankingState => {
  switch (action.type) {
    case GlobalCommunityLoveRankingActionTypes.ERROR: {
      return stateCreator.getAsyncErrorState(action, state);
    }
    case GlobalCommunityLoveRankingActionTypes.FETCHING:
      return stateCreator.getAsyncLoadingState(state);
    case GlobalCommunityLoveRankingActionTypes.SUCCESS: {
      return stateCreator.getInitialAsyncState(state, {
        data: action.payload,
      });
    }
    default:
      return state;
  }
};
