import { combineReducers } from '@reduxjs/toolkit';
import { reducer as page } from './services/page-conciliator';
import { reducer as sets } from './services/sets-conciliator';
import { reducer as userSets } from './services/user-sets-conciliator';
import { reducer as user } from './services/user-conciliator';
import { reducer as auctions } from './services/auctions-conciliator';

export const reducer = combineReducers({
  page,
  sets,
  userSets,
  user,
  auctions,
});
