import { compose } from 'lodash/fp';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import ClassNames from '@streamloots/classnames';
import { Trans, withTranslation, TranslateInterface } from 'utils/translation';
import { cultureSelectors, produceLegalURLs } from 'services/culture';
import { Checkbox, rules } from 'ui-library/forms';
import { Form } from 'components/forms';
import { CountryPicker } from 'components/form-fields';
import theme from './tc-modal.scss';

const classNames = ClassNames(theme);

interface Props extends TranslateInterface {
  language: string;
  isStreamerCheckboxRendered: boolean;
}

const shouldAcceptTermsAndConditions = value => rules.checked(value, 'userProfile:mustAcceptTerms');

const FormCountry = (props: Props) => {
  const { t, language, isStreamerCheckboxRendered } = props;
  const { termsURL, privacyURL } = produceLegalURLs({
    language,
  });

  return (
    <Form {...props} successMessage={t('onboarding:termsAndConditionsModal.updated')} button={t('common:accept')}>
      <CountryPicker name="country" t={t} label={t('userProfile:whereAreYouFrom')} />
      <div className="margin-bottom-s">
        <Checkbox
          id="accept-terms"
          name="acceptTerms"
          labelDescriptionClass={classNames('tc-modal__checkbox-label')}
          label={
            <Trans i18nKey="onboarding:acceptGeneralTerms">
              Accept{' '}
              <Link to={termsURL} target="_blank">
                Terms and Conditions
              </Link>{' '}
              and{' '}
              <Link to={privacyURL} target="_blank">
                Privacy Policy
              </Link>
            </Trans>
          }
          validate={shouldAcceptTermsAndConditions}
        />
      </div>
      {isStreamerCheckboxRendered && (
        <div className="margin-bottom-s">
          <Checkbox
            type="checkbox"
            id="accept-streamer-terms"
            name="acceptStreamerTerms"
            labelDescriptionClass={classNames('tc-modal__checkbox-label')}
            label={
              <Trans i18nKey="onboarding:acceptStreamerTerms" parent="label" html-for="accept-streamer-terms">
                {`Accept `}
                <Link to={termsURL} target="_blank">
                  Streamer Terms and Conditions
                </Link>
              </Trans>
            }
            validate={shouldAcceptTermsAndConditions}
          />
        </div>
      )}
    </Form>
  );
};

const mapStateToProps = state => ({
  language: cultureSelectors.language(state),
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'formCountry',
  }),
  withTranslation('onboarding', 'userProfile', 'common'),
)(FormCountry) as any;
