import { ReactNode } from 'react';
import ClassNames from '@streamloots/classnames';
import theme from './full-layout.scss';

interface FullLayoutProps {
  className?: string;
  children: ReactNode;
}

const classNames = ClassNames(theme);

export const FullLayout = ({ className = '', children }: FullLayoutProps): JSX.Element => {
  return (
    <div
      className={classNames({
        'full-layout': true,
        [className]: Boolean(className),
      })}
    >
      {children}
    </div>
  );
};
