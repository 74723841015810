import stateCreator from 'helpers/stateCreator';
import { PageDonationDonorsActionTypes } from './actionTypes';
import { DonationsDonorsAction, DonationsDonorsState } from './types';

const initialState: DonationsDonorsState = {};

export const reducer = (state = initialState, action: DonationsDonorsAction): DonationsDonorsState => {
  switch (action.type) {
    case PageDonationDonorsActionTypes.PAGE_DONATIONS_DONORS_FETCH_LOADING:
      return { ...state, ...stateCreator.getAsyncLoadingState(state) };

    case PageDonationDonorsActionTypes.PAGE_DONATIONS_DONORS_FETCH_ERROR:
      return { ...state, ...stateCreator.getAsyncErrorState(action, state) };

    case PageDonationDonorsActionTypes.PAGE_DONATIONS_DONORS_FETCH_SUCCESS:
      if (action.payload) {
        return stateCreator.getInitialAsyncState(state, {
          ...state,
          donors: action.payload,
        });
      }
      return state;

    default:
      return state;
  }
};
