import _clean from 'lodash-clean';
import type { OrderType } from 'model/indexTS';
import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import type { PaymentOrderCreatedActionSuccess, PaymentOrderCreatedActionError } from './types';
import { errorMapping } from '../../errorMapping';
import { CreatePaymentOrderActionTypes } from './actionTypes';

const paymentOrderCreated = ({ data }): PaymentOrderCreatedActionSuccess =>
  actionCreator.createAction(CreatePaymentOrderActionTypes.CREATED, data);

const paymentOrderNotCreated = ({ errorData }): PaymentOrderCreatedActionError =>
  actionCreator.createErrorAction(CreatePaymentOrderActionTypes.CREATED, errorData, errorMapping);

const createPaymentOrder = (
  setLootId: string,
  processor: string,
  quantity: number,
  type: OrderType,
  gifteeUsername: string | undefined,
  couponCode: string | undefined,
  setId: string,
) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.PAYMENT_ORDER.CREATE,
    errorBinding: paymentOrderNotCreated,
    loadingAction: CreatePaymentOrderActionTypes.CREATING,
    parameters: _clean({
      processor,
      type,
      couponCode,
      gifteeUsername,
      items: [
        {
          item: {
            setLootId,
            setId,
          },
          quantity,
        },
      ],
    }),
    method: 'post',
    successBinding: paymentOrderCreated,
  });

export const createPaymentOrderActions = {
  createPaymentOrder,
};
