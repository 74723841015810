import { Redirect } from 'react-router-dom';
import { urls } from 'services/routing';
import { permissions as definedPermissions } from 'components/permissions';
import { usePermissionsSelectors } from 'services/permissions';

export const ManageCollectionPermissionsRedirection = () => {
  const { permissions } = usePermissionsSelectors();

  if (permissions[definedPermissions.SET_CARDS_READ]) {
    return <Redirect to={urls().CARD_LIST} />;
  }

  if (permissions[definedPermissions.SET_LOOTS_EDIT]) {
    return <Redirect to={urls().PACKS_AND_PRICES} />;
  }

  if (permissions[definedPermissions.SET_RARITIES_EDIT]) {
    return <Redirect to={urls().UPDATE_PROBABILITIES} />;
  }

  if (permissions[definedPermissions.CRAFTING]) {
    return <Redirect to={urls().DISENCHANT} />;
  }

  return <Redirect to={urls().USER_PAGE} />;
};
