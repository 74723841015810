import * as React from 'react';
import { BigColumnPanel, SmallColumnPanel, TwoColumnLayout } from 'ui-library/two-column-panel';
import Skeleton from 'ui-library/skeleton';

const AuctionPageSkeleton = (): JSX.Element => {
  return (
    <TwoColumnLayout reverse>
      <BigColumnPanel>
        <Skeleton height="750px" />
      </BigColumnPanel>
      <SmallColumnPanel>
        <Skeleton height="250px" />
      </SmallColumnPanel>
    </TwoColumnLayout>
  );
};

export default AuctionPageSkeleton;
