import { Languages } from '@streamloots/streamloots-types';
import { CultureStoreState } from './types';

const language = (state: CultureStoreState): Languages => state.culture.language;
const IPCountry = (state: CultureStoreState): string => state.culture.IPCountry ?? 'EN'; // Following project conventions. Should we use a constant instead?

export const cultureSelectors = {
  language,
  IPCountry,
};
