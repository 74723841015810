import stateCreator from 'helpers/stateCreator';
import { PageActionTypes } from 'services/page';
import { SetsActionTypes } from 'services/sets';
import { updateSetCardsCraftingConfigurationActionTypes } from '../update-crafting-configuration';
import { deleteSetCardActionTypes } from '../delete';
import { transferCardsActionTypes } from '../transfer-cards';
import { updateSetCardActionTypes } from '../update';
import { changeSetCardStatusActionTypes } from '../change-status';
import { bulkUpdateCardsActionTypes } from '../bulk-update';
import { createFromTemplateActionTypes } from '../create-from-template';
import type { SetCardListState, SetCardListStateBySetId } from './types';
import normalizer from './normalizer';
import { SetCardListActionTypes } from './actionTypes';

const getSetIdState = (state: SetCardListStateBySetId = {}, action): SetCardListStateBySetId => {
  const { type, error, payload, metadata } = action;
  switch (type) {
    case SetCardListActionTypes.FETCHING: {
      const { cancelToken } = metadata;
      return stateCreator.getAsyncLoadingState(state, {
        invalidateData: false,
        cancelToken,
      });
    }
    case SetCardListActionTypes.RECEIVED: {
      if (error) {
        return stateCreator.getAsyncErrorState(action, state);
      }
      const { data } = payload;
      return stateCreator.getInitialAsyncState(state, normalizer.setListState(data));
    }
    case SetCardListActionTypes.FILTERED: {
      return { ...state, filters: payload };
    }

    case updateSetCardActionTypes.UPDATED: {
      if (error) {
        return state;
      }

      const { cards = [] } = state;
      const { setCard } = payload;

      const cardsCopy = cards.map(card => {
        if (card._id !== setCard._id) {
          return card;
        }
        return { ...card, ...setCard };
      });

      return {
        ...state,
        ...normalizer.setListState(cardsCopy),
        invalidateData: true,
      };
    }
    case deleteSetCardActionTypes.DELETED:
    case transferCardsActionTypes.TRANSFERRED: {
      if (error) {
        return state;
      }

      const { cards = [] } = state;
      const { completedIds } = payload;

      return stateCreator.getInitialAsyncState(state, {
        invalidateData: true,
        cards: cards.filter(card => !completedIds[card._id]),
      });
    }
    case changeSetCardStatusActionTypes.STATUS_CHANGED: {
      if (error) {
        return state;
      }

      const { cards = [] } = state;
      const updatedCards = payload.cards;

      if (updatedCards.length === 0) {
        return state;
      }

      const updatedCardStatus = {};
      updatedCards.forEach(card => {
        updatedCardStatus[card._id] = card.status;
      });

      const newCards = cards.map(card => ({
        ...card,
        status: updatedCardStatus[card._id] || card.status,
      }));

      return { ...state, ...normalizer.setListState(newCards) };
    }
    case updateSetCardsCraftingConfigurationActionTypes.CRAFTING_CONFIGURATION_UPDATED: {
      if (error) {
        return state;
      }

      const { cards = [] } = state;
      const receivedCards = payload.cards || [];

      const cardCraftingInfo = {};
      receivedCards.forEach(card => {
        const { _id, ...rest } = card;
        cardCraftingInfo[_id] = rest;
      });

      const newCards = cards.map(card => {
        const newCardInfo = cardCraftingInfo[card._id];
        if (!newCardInfo) {
          return card;
        }
        return {
          ...card,
          ...newCardInfo,
        };
      });
      return { ...state, cards: newCards };
    }
    case bulkUpdateCardsActionTypes.BULK_UPDATED:
    case createFromTemplateActionTypes.CREATED_FROM_TEMPLATE:
    case SetsActionTypes.SELECTED_SET_CHANGED:
      if (error) {
        return state;
      }
      return {
        ...state,
        invalidateData: true,
      };
    default:
      return state;
  }
};

export const reducer = (state: SetCardListState = {}, action): SetCardListState => {
  const { type, error, payload, metadata } = action;
  switch (type) {
    case SetsActionTypes.RECEIVED: {
      if (error) {
        return state;
      }
      const { data } = payload;

      const newStateBySetId = {};
      data.forEach(set => {
        const setCardsState = state[set._id] || {};
        newStateBySetId[set._id] = {
          ...setCardsState,
        };
      });

      return {
        ...state,
        ...newStateBySetId,
      };
    }
    case SetCardListActionTypes.FETCHING:
    case SetCardListActionTypes.RECEIVED:
    case SetCardListActionTypes.FILTERED:
    case updateSetCardActionTypes.UPDATED:
    case bulkUpdateCardsActionTypes.BULK_UPDATED:
    case createFromTemplateActionTypes.CREATED_FROM_TEMPLATE:
    case deleteSetCardActionTypes.DELETED:
    case changeSetCardStatusActionTypes.STATUS_CHANGED:
    case SetsActionTypes.SELECTED_SET_CHANGED:
    case transferCardsActionTypes.TRANSFERRED:
    case updateSetCardsCraftingConfigurationActionTypes.CRAFTING_CONFIGURATION_UPDATED: {
      const setId = metadata.setId || payload.setId;
      return stateCreator.getUpdatedStateByKey(state, action, setId, getSetIdState);
    }
    case PageActionTypes.CHANGED:
      return {};
    default:
      return state;
  }
};
