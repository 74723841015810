import baseLoadable from '@loadable/component';
import { SpinnerPanel } from 'ui-library/loading-spinner';

export default function (
  importFunction: () => void,
  includeFallback = true,
  fallBack?: React.ReactNode | JSX.Element,
  ssr = true,
) {
  return baseLoadable(importFunction, {
    fallback: includeFallback ? fallBack || <SpinnerPanel /> : null,
    ssr,
  });
}
