import { useEffect } from 'react';
import { useTranslation } from 'utils/translation';
import { usePageSelectors } from 'services/page';
import { usePageFeedActions, usePageFeedSelectors } from 'services/page-feed';

export const useFeedItemEnsurer = (): null => {
  const { data, isLoading } = usePageFeedSelectors();
  const { t } = useTranslation('pageFeed');

  const { fetchPageFeed, createPageFeed } = usePageFeedActions();
  const {
    page: { slug, isOwner },
  } = usePageSelectors();
  const ignoreFetch = !slug || !isOwner || isLoading || typeof data?.length === 'number';

  useEffect(() => {
    if (ignoreFetch || !slug) {
      return;
    }

    fetchPageFeed(slug).then(response => {
      if (response.error) {
        return;
      }

      const hasAnyPublicPost = response.payload.data.some(post => post.restrictedTo.length === 0);

      if (!hasAnyPublicPost) {
        createPageFeed(slug, { content: t('pageFeed:noFeed.firstPost'), restrictedTo: [], attachments: [] });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
