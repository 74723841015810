import { Redirect, useParams } from 'react-router-dom';
import { getUrlWithParams, pages, urls } from 'services/routing';
import { useUserSelectors } from 'services/user';
import Spinner from 'ui-library/loading-spinner';

interface ParamsUrl {
  frameId: string;
}

export const OriginalOnboardingRedirection = (): JSX.Element => {
  const { user, isLoading: isLoadingUser, showOnboarding } = useUserSelectors();
  const params = useParams<ParamsUrl>();

  if (!user && !isLoadingUser) {
    return <Redirect to={urls().USER_COLLECTIONS} />;
  }

  if (user) {
    if (!user.page || showOnboarding) {
      return <Redirect to={`${urls().CREATE_PAGE}?frameId=${params.frameId}`} />;
    }

    return (
      <Redirect to={`${getUrlWithParams(pages.ADD_DEFAULT_CARDS, { slug: user.page.slug })}?frame=${params.frameId}`} />
    );
  }

  return <Spinner fixed />;
};
