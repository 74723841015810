export enum PagePlanActionTypes {
  UPDATING = 'page-plans/UPDATING',
  UPDATED = 'page-plans/UPDATED',
  FETCHING = 'page-plans/FETCHING',
  RECEIVED = 'page-plans/RECEIVED',
  DELETING = 'page-plans/DELETING',
  DELETED = 'page-plans/DELETED',
  ADDING = 'page-plans/ADDING',
  ADDED = 'page-plans/ADDED',
}
