import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import theme from './alert-animation.scss';

type AlertAnimationProps = {
  show: boolean;
  children: React.ReactNode;
};

const AlertAnimation = ({ show, children }: AlertAnimationProps): JSX.Element => {
  return (
    <CSSTransition
      timeout={2000}
      mountOnEnter
      unmountOnExit
      in={show}
      classNames={{
        enter: theme.enter,
        enterActive: theme.enterActive,
        exit: theme.exit,
        exitActive: theme.exitActive,
        exitDone: theme.exitDone,
      }}
    >
      {children}
    </CSSTransition>
  );
};

export default AlertAnimation;
