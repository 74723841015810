import { Achievement } from 'model/indexTS';
import { createSelector } from 'reselect';
import { AchievementsState } from './types';

const allAchievements = (state: { achievements: AchievementsState }): AchievementsState => state.achievements || {};

const missingPurchaseAchievementsFrom = (achievements: AchievementsState) => {
  return Object.values(achievements).filter(userAchievement => {
    const { progresses, isActived, isCompleted, achievement } = userAchievement;
    const { eventsDefinition } = achievement;
    if (!progresses || !isActived || isCompleted) {
      return false;
    }

    const currentProgress = progresses[0];
    return (
      currentProgress.statsName === 'purchasedPacks' &&
      currentProgress.currentValue < currentProgress.targetValue &&
      eventsDefinition[0].conditions.length === 0
    );
  });
};
const byCompletion = (a: Achievement, b: Achievement) => {
  const aProgresses = a.progresses[0];
  const aMissing = aProgresses.targetValue - aProgresses.currentValue;

  const bProgresses = b.progresses[0];
  const bMissing = bProgresses.targetValue - bProgresses.currentValue;

  return aMissing - bMissing;
};

const nextPurchasePacksAchievement = createSelector([allAchievements], achievements => {
  const purchaseAchievements = missingPurchaseAchievementsFrom(achievements);
  if (!purchaseAchievements || purchaseAchievements.length === 0) {
    return null;
  }

  const orderAchievements = purchaseAchievements.sort(byCompletion);
  return orderAchievements[0];
});

const canDoMoreAchievements = (state: { achievements: AchievementsState }): boolean => {
  return Object.keys(allAchievements(state)).some(key => {
    const achievement = state.achievements[key];
    return achievement.isActived && !achievement.isCompleted;
  });
};

export default {
  allAchievements,
  canDoMoreAchievements,
  nextPurchasePacksAchievement,
};
