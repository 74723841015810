import loadable from 'components/dynamic-import';
import { StreamersCardsSkeleton } from 'components/streamer-card';
import { FullLayout } from 'scenes/layout';

import { BottomBanner, BottomBannerType } from 'components/banners';

const Streamers = loadable(() => import('./Streamers'), true, <StreamersCardsSkeleton includeWrapper />);

export const StreamersPage = (): JSX.Element => (
  <>
    <FullLayout>
      <BottomBanner type={BottomBannerType.CREATE_COLLECTION} />
      <Streamers />
    </FullLayout>
  </>
);
