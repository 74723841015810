/* eslint-disable */
import produce from 'immer';
import actionTypes from './actionTypes';

const initialState = {};

const achievementReducer = produce((draft, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.GET_ACHIEVEMENTS_SUCCESS: {
      Object.keys(payload).forEach(id => {
        draft[id] = payload[id];
      });
      return;
    }
  }
}, initialState);

export default achievementReducer;
