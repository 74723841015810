import stateCreator from 'helpers/stateCreator';
import { PageActionTypes } from '../page';
import { PageSubscriptionStatisticsActionTypes } from './actionTypes';
import { PageSubscriptionStatisticsActions, PageSubscriptionStatisticsState } from './types';

const initialState: PageSubscriptionStatisticsState = {};

export const reducer = (
  state = initialState,
  action: PageSubscriptionStatisticsActions,
): PageSubscriptionStatisticsState => {
  switch (action.type) {
    case PageSubscriptionStatisticsActionTypes.GET_PAGE_SUBSCRIPTION_STATISTICS_REQUEST: {
      return {
        ...state,
        ...stateCreator.getAsyncLoadingState(state),
      };
    }

    case PageSubscriptionStatisticsActionTypes.GET_PAGE_SUBSCRIPTION_STATISTICS_SUCCESS: {
      const newState: PageSubscriptionStatisticsState = {
        subscriptionStatistics: { ...action.payload },
      };
      return stateCreator.getInitialAsyncState(state, newState);
    }

    case PageSubscriptionStatisticsActionTypes.GET_PAGE_SUBSCRIPTION_STATISTICS_ERROR: {
      return stateCreator.getAsyncErrorState(action, state);
    }

    case PageActionTypes.CHANGED: {
      return initialState;
    }

    default:
      return state;
  }
};
