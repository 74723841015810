import { useTranslation } from 'utils/translation';
import { PanelSkeletons } from 'ui-library/panel';
import { PageHeader } from 'ui-library/headers';
import loadable from 'components/dynamic-import';
import { FullLayout } from 'scenes/layout';

const ConnectedConfigureLoots = loadable(() => import('./ConnectedConfigureLoots'), true, <PanelSkeletons count={1} />);

export const ManageLootsPage = (): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <FullLayout>
      <PageHeader>{t('common:menu.prices')}</PageHeader>
      <ConnectedConfigureLoots />
    </FullLayout>
  );
};
