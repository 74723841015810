import ClassNames from '@streamloots/classnames';
import theme from './new-indicator.scss';

const classNames = ClassNames(theme);

export interface NewLabelProps {
  className?: string;
  label?: string;
  big?: boolean;
  date?: string | Date | number;
  expiresInHours?: number;
}

const showLabel = ({ date, expiresInHours }: Pick<NewLabelProps, 'date' | 'expiresInHours'>) => {
  if (!date || !expiresInHours) {
    return true;
  }

  const baseDate = typeof date === 'string' || typeof date === 'number' ? new Date(date) : date;
  const expiresInMs = expiresInHours * 60 * 60 * 1000;
  return baseDate.getTime() + expiresInMs > Date.now();
};

const NewLabel = ({ className = '', label, big, date, expiresInHours }: NewLabelProps) => {
  if (!showLabel({ expiresInHours, date })) {
    return null;
  }

  return (
    <>
      {label}{' '}
      <span
        className={classNames({
          [className]: className,
          'new': true,
          'new--big': big,
        })}
      >
        New
      </span>
    </>
  );
};
export default NewLabel;
