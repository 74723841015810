export enum BadgeVariants {
  Filled = 'filled',
  Outlined = 'outlined',
}

export const BadgeColors = {
  Success: 'success',
  Dangerous: 'dangerous',
  Info: 'info',
  Warning: 'warning',
  Common: 'common',
  Rare: 'rare',
  Epic: 'epic',
  Legendary: 'legendary',
  notification: 'notification',
  LightGrey: 'light-grey',
  ElectricBlue: 'electric-blue',
} as const;

export type BadgeColorsType = `${typeof BadgeColors[keyof typeof BadgeColors]}`;
