import type { DropdownOption } from 'types/indexTS';
import { I18nType } from 'utils/translation';
import { Languages } from '@streamloots/streamloots-types';

export const ACCEPTED_LANGUAGES: Array<DropdownOption> = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Castellano',
    value: 'es',
  },
];

const getLanguage = (culture: string | I18nType): string => (typeof culture === 'string' ? culture : culture.language);

const getAcceptedLanguage = (language: Languages | string): Languages =>
  language === Languages.Spanish ? Languages.Spanish : Languages.English;

export default {
  isSpanish: (culture: string | I18nType) => {
    const language = getLanguage(culture);
    return language.toLowerCase().indexOf('es') > -1;
  },
  getLanguage,
  getAcceptedLanguage,
};
