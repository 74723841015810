import _clean from 'lodash-clean';
import endpoints from 'services/endpoints';
import actionCreator from 'helpers/actionCreator';
import type {
  CreateCouponActionSuccess,
  CreateCouponActionError,
  CreateCouponActionRequest,
  CreateCouponParams,
} from './types';
import { errorMapping } from '../../errorMapping';
import { CouponActionTypes } from '../../actionTypes';

const couponCreated = ({ additionalData, data }): CreateCouponActionSuccess =>
  actionCreator.createAction(
    CouponActionTypes.CREATED,
    {
      coupon: data,
    },
    additionalData,
  );

const couponNotCreated = ({ additionalData, errorData }): CreateCouponActionError =>
  actionCreator.createErrorAction(CouponActionTypes.CREATED, errorData, errorMapping, additionalData);

const creatingCoupon = (componentKey: string): CreateCouponActionRequest =>
  actionCreator.createAction(CouponActionTypes.CREATING, {}, { componentKey });

const createCoupon = (componentKey: string, coupon: CreateCouponParams) => {
  const parameters = _clean(coupon);
  return actionCreator.createAsyncAction({
    additionalData: {
      componentKey,
    },
    endpoint: endpoints.COUPONS.CREATE,
    errorBinding: couponNotCreated,
    loadingAction: () => creatingCoupon(componentKey),
    method: 'post',
    parameters,
    successBinding: couponCreated,
  });
};

export const createCouponActions = {
  createCoupon,
};
