import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import {
  AcknowledgeTypeApplication,
  ApplyForAffiliate,
  ApplyForPartnership,
  BadgeKingMidas,
  ChangePageContext,
  ChangePublicPageContext,
  FetchPage,
  HyperwalletConfigurationOutput,
  PageChanged,
  PageState,
  UpdatePage,
  UpdatePageModules,
  UpdatePageModulesParams,
  UpgradePageLevel,
} from './types';
import { pageSelectors } from './selectors';
import { pageActions } from './actions';

export interface UsePageSelectors {
  page: PageState;
  hyperwalletConfiguration: HyperwalletConfigurationOutput;
  kingMidasBadge?: BadgeKingMidas;
}

export interface UsePageActions {
  fetchPage: FetchPage;
  changePageContext: ChangePageContext;
  changePublicPageContext: ChangePublicPageContext;
  pageChanged: PageChanged;
  applyForAffiliate: ApplyForAffiliate;
  applyForPartnership: ApplyForPartnership;
  acknowledgeTypeApplication: AcknowledgeTypeApplication;
  upgradePageLevel: UpgradePageLevel;
  updatePage: UpdatePage;
  updatePageModules: UpdatePageModules;
}

export const usePageActions = (): UsePageActions => {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      fetchPage: slug => dispatch(pageActions.fetchPage(slug)),
      changePageContext: inPageContext => dispatch(pageActions.changePageContext(inPageContext)),
      changePublicPageContext: inPublicPageContext =>
        dispatch(pageActions.changePublicPageContext(inPublicPageContext)),
      pageChanged: () => dispatch(pageActions.pageChanged()),
      applyForAffiliate: slug => dispatch(pageActions.applyForAffiliate(slug)),
      applyForPartnership: (slug, partnershipApplicationData) =>
        dispatch(pageActions.applyForPartnership(slug, partnershipApplicationData)),
      acknowledgeTypeApplication: (applicationId, slug, isPartnership) =>
        dispatch(pageActions.acknowledgeTypeApplication(applicationId, slug, isPartnership)),
      upgradePageLevel: slug => dispatch(pageActions.upgradePageLevel(slug)),
      updatePage: (params, slug) => dispatch(pageActions.updatePage(params, slug)),
      updatePageModules: (params: UpdatePageModulesParams, slug: string) =>
        dispatch(pageActions.updatePageModules(params, slug)),
    }),
    [dispatch],
  );
};

export const usePageSelectors = (): UsePageSelectors => {
  const page = useSelector(pageSelectors.page);
  const hyperwalletConfiguration = useSelector(pageSelectors.hyperwalletConfiguration);
  const kingMidasBadge = useSelector(pageSelectors.getBadgeKingMidas);

  return {
    page,
    hyperwalletConfiguration,
    kingMidasBadge,
  };
};
