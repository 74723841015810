import { pages } from 'services/routing';

type ProduceURLsParams = {
  language: string;
};

export function produceLegalURLs({
  language,
}: ProduceURLsParams): {
  termsURL: string;
  privacyURL: string;
} {
  return {
    termsURL: pages.TERMS_AND_CONDITIONS,
    privacyURL: pages[`PRIVACY_POLICY_${language.toUpperCase()}`],
  };
}
