export enum SetsActionTypes {
  CREATED = 'sets/CREATED',
  CREATING = 'sets/CREATING',
  PUBLISHED = 'sets/PUBLISHED',
  PUBLISHING = 'sets/PUBLISHING',
  RESET_PUBLISH = 'sets/RESET_PUBLISH',
  REQUEST = 'sets/REQUEST',
  RECEIVED = 'sets/RECEIVED',
  SELECTED_SET_CHANGED = 'sets/SELECTED_SET_CHANGED',
  ORDER = 'sets/ORDER',
  ORDER_FAILED = 'sets/ORDER_FAILED',
  ORDER_SUCCESS = 'sets/ORDER_SUCCESS',
  UPDATING_RARITIES = 'sets/UPDATING_RARITIES',
  RARITIES_UPDATED = 'sets/RARITIES_UPDATED',
  PAUSED = 'sets/PAUSED',
  RESUMED = 'sets/RESUMED',
  UPDATING_STATUS = 'sets/UPDATING_STATUS',
  UNPUBLISHED = 'sets/UNPUBLISHED',
  UNPUBLISHING = 'sets/UNPUBLISHING',
  UPDATING = 'sets/UPDATING',
  UPDATED = 'sets/UPDATED',
  ARCHIVED = 'sets/ARCHIVED',
  ARCHIVING = 'sets/ARCHIVING',
}
