import * as React from 'react';
import config from 'config';
import { Redirect } from 'react-router-dom';
import illustrations from 'constants/illustrations';
import IllustrationWithInfo from 'components/illustration-with-info';
import { FullLayout } from 'scenes/layout';
import { pages } from 'services/routing';
import urlUtils from 'utils/urlUtils';
import auth from 'utils/authCookieManager';

export const WelcomeAgainMixer = (): JSX.Element => {
  const [redirectToProfile, setRedirectToProfile] = React.useState(false);

  React.useEffect(() => {
    const hash = urlUtils.urlToParams(window.location.hash);

    if (!hash || !hash.token) {
      setRedirectToProfile(true);
      return () => {
        return null;
      };
    }

    auth.signInUser(hash.token);
    const timeout = setTimeout(() => {
      window.location.href = `${config.streamloots.api}/oauth2/authorize/mixer/welcome-again?token=${hash.token}`;
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (redirectToProfile) {
    return <Redirect to={pages.MY_PROFILE} />;
  }

  return (
    <FullLayout>
      <IllustrationWithInfo imageUrl={illustrations.CASTLE_STREAMERS}>
        <h2 className="align-center">WELCOME AGAIN!</h2>
        <p>We are authenticating you...</p>
      </IllustrationWithInfo>
    </FullLayout>
  );
};
