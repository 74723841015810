import stateCreator from 'helpers/stateCreator';
import { PageActionTypes } from '../page';
import { PageSubscriptionsActions, PageSubscriptionsState } from './types';
import { PageSubscriptionsActionTypes } from './actionTypes';

const initialState: PageSubscriptionsState = {};

export const reducer = (
  state: PageSubscriptionsState = initialState,
  action: PageSubscriptionsActions,
): PageSubscriptionsState => {
  switch (action.type) {
    case PageSubscriptionsActionTypes.RECEIVED: {
      if (action.error) {
        return stateCreator.getAsyncErrorState(action, state, initialState);
      }

      const newState: PageSubscriptionsState = {
        subscriptions: action.payload,
      };
      return stateCreator.getInitialAsyncState(state, newState);
    }

    case PageSubscriptionsActionTypes.FETCHING:
    case PageSubscriptionsActionTypes.COUNT_SUBS_LOADING:
      return stateCreator.getAsyncLoadingState(state);

    case PageSubscriptionsActionTypes.ERROR:
      return stateCreator.getAsyncErrorState(action, state);

    case PageSubscriptionsActionTypes.COUNT_SUBS_SUCCESS: {
      if (action.error) {
        return stateCreator.getAsyncErrorState(action, state, initialState);
      }

      return stateCreator.getInitialAsyncState(state, {
        activeSubscriptionsStats: action.payload,
      });
    }

    case PageActionTypes.CHANGED: {
      return initialState;
    }

    default:
      return state;
  }
};
