import ClassNames from '@streamloots/classnames';
import { BadgeColors, BadgeColorsType, BadgeVariants } from 'ui-library/badge/types';
import theme from './badge.scss';

const classNames = ClassNames(theme);

export interface BadgeProps {
  className?: string;
  label: string;
  variant?: BadgeVariants;
  color?: BadgeColorsType;
  onClick?: () => void;
}

export const Badge = ({
  className = '',
  variant = BadgeVariants.Filled,
  color = BadgeColors.Success,
  label,
  onClick,
}: BadgeProps): JSX.Element => (
  <div
    className={classNames({
      badge: true,
      [`badge-variant--${variant}`]: true,
      [`badge-color--${color}`]: true,
      [className]: Boolean(className),
    })}
    onClick={onClick}
    role={onClick ? 'button' : undefined}
  >
    <span>{label}</span>
  </div>
);
