import * as React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ClassNames from '@streamloots/classnames';
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';
import { FloatingNotification } from 'services/notifications/typesTS';
import { notificationsActions, NotificationsActionTypes, notificationsSelectors } from 'services/notifications';
import { cultureSelectors } from 'services/culture';
import { loadingStateSelectors, LoadingState } from 'services/loadingState';
import useMedia from 'hooks/use-media';
import { USE_MEDIA_BREAKPOINTS } from 'constants/stylesBreakpoints';
import trackEventManager from 'utils/event-manager';
import Render from 'components/render';
import { LoadingStatesLoader } from 'components/loading';
import { Languages } from '@streamloots/streamloots-types';
import AchievementNotification from './AchievementNotification';
import styles from './floating-notifications.scss';
import NotificationWrapper from './NotificationWrapper';

const classNames = ClassNames(styles);

interface MapStateToProps {
  notifications: FloatingNotification[];
  loadingState: LoadingState | null;
  language: Languages;
}

interface MapDispatchToProps {
  getFloatingNotifications: () => void;
  markNotificationAsRead: (notificationsId: Array<string>) => void;
}

interface Props extends MapStateToProps, MapDispatchToProps {}

function FloatingNotifications(props: Props): JSX.Element {
  const { notifications, markNotificationAsRead, getFloatingNotifications, loadingState, language } = props;

  const isMobile = useMedia(USE_MEDIA_BREAKPOINTS.maxWidthString.mobile);

  React.useEffect(() => {
    getFloatingNotifications();
  }, [getFloatingNotifications]);

  // TODO (If we finally decide to use this as a wrapper for different notification types)
  // Dinamic render of different notifications depending on notification type
  // all of them wrapped with notification wrapper
  function renderNotification(notification: FloatingNotification, i: number) {
    const { _id } = notification;
    const handleClose = (source: 'notificationBody' | 'closeButton') => {
      trackEventManager.trackEvent('achievementNotificationClose', {
        achievementName: notification.locales.en,
        source,
      });
      markNotificationAsRead([_id]);
    };

    return (
      <Link
        key={_id}
        className={classNames('link__no-decoration')}
        onClick={() => {
          handleClose('notificationBody');
        }}
        to={notification.internalLink}
        data-testid="achievement-floating-notification"
      >
        <NotificationWrapper
          onClose={() => handleClose('closeButton')}
          transitionDelay={i * 0.2}
          isMobile={isMobile}
          id={`id-${_id}`} // just so motion component has a different key than its wrapper
          className={'achievement'}
        >
          <AchievementNotification notification={notification} language={language} onClose={handleClose} />
        </NotificationWrapper>
      </Link>
    );
  }

  return (
    <Render.Client>
      <AnimateSharedLayout>
        <LoadingStatesLoader loadingState={loadingState} renderLoading={() => null}>
          <motion.div
            layout
            className={classNames({
              'notifications-wrapper': true,
              'notifications-wrapper--hidden': !notifications.length,
            })}
          >
            <AnimatePresence exitBeforeEnter={isMobile}>
              {isMobile
                ? notifications.slice(0, 1).map(renderNotification)
                : notifications.slice(0, 3).map(renderNotification)}
            </AnimatePresence>
          </motion.div>
        </LoadingStatesLoader>
      </AnimateSharedLayout>
    </Render.Client>
  );
}

const mapStateToProps = (state): MapStateToProps => ({
  notifications: notificationsSelectors.selectFloatingNotifications(state),
  loadingState: loadingStateSelectors.loadingStateByAction(state, NotificationsActionTypes.GET_FLOATING_REQUEST),
  language: cultureSelectors.language(state),
});

export default connect<MapStateToProps, MapDispatchToProps>(mapStateToProps, {
  getFloatingNotifications: notificationsActions.getFloatingNotifications,
  markNotificationAsRead: notificationsActions.floatingNotificationsMarkAsRead,
})(FloatingNotifications);
