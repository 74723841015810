import ClassNames from '@streamloots/classnames';
import { TranslateInterface } from 'utils/translation';
import { urls } from 'services/routing';
import { usePermissionsSelectors } from 'services/permissions';
import { useSetsSelectors } from 'services/sets';
import { ButtonIcon } from 'ui-library/button';
import { permissions as definedPermissions } from 'components/permissions';
import { TopBarNavigationLink } from './TopBarNavigationLink';
import theme from './manage-collection.scss';

const classNames = ClassNames(theme);

export interface ManageCollectionTopBarProps extends TranslateInterface {
  onManageCollections: () => void;
}

export const ManageCollectionTopBar = ({ onManageCollections, t }: ManageCollectionTopBarProps): JSX.Element | null => {
  const { selectedSet: set } = useSetsSelectors();
  const { permissions } = usePermissionsSelectors();

  if (!set) {
    return null;
  }

  const cardsPermissions = permissions[definedPermissions.SET_CARDS_READ];
  const packsPermissions = permissions[definedPermissions.SET_LOOTS_EDIT];
  const craftingPermissions = permissions[definedPermissions.CRAFTING];
  const probabilitiesPermissions = permissions[definedPermissions.SET_RARITIES_EDIT];

  return (
    <div className={classNames('top-bar')}>
      <div className={classNames('top-bar__collection-info')}>
        <img src={set.imageUrl} alt={set.name} className={classNames('top-bar__logo')} />
        <div>
          <h3 className={classNames('top-bar__collection-name')}>{set.name}</h3>
          <ButtonIcon
            type="button"
            className={classNames('top-bar__change-collection')}
            asLink
            onClick={onManageCollections}
            label={t('common:changeCollection')}
            icon="chevron-right"
            iconRight
          />
        </div>
      </div>
      <div className={classNames('top-bar__nav')}>
        <ul className={classNames('st-navigation')}>
          {cardsPermissions && (
            <TopBarNavigationLink icon="cards" label={t('common:menu.manageCards')} link={urls().CARD_LIST} />
          )}
          {packsPermissions && (
            <TopBarNavigationLink icon="treasure" label={t('common:menu.prices')} link={urls().PACKS_AND_PRICES} />
          )}
          {probabilitiesPermissions && (
            <TopBarNavigationLink
              icon="dice"
              label={t('common:menu.probabilities')}
              link={urls().UPDATE_PROBABILITIES}
            />
          )}
          {craftingPermissions && (
            <TopBarNavigationLink
              icon="diamond"
              exact={false}
              label={t('common:menu.disenchant')}
              link={urls().DISENCHANT_BASE}
            />
          )}
        </ul>
      </div>
    </div>
  );
};
