import { LoveFormatter } from 'domain/love-formatter';
import { Languages } from '@streamloots/streamloots-types';
import { createSelector } from 'reselect';
import { AsyncComponentStateError } from 'types/indexTS';
import {
  AllStatisticsOutput,
  IsSellerOuput,
  PageStatisticsReduxState,
  PageStatisticsState,
  SummaryFormated,
  SUMMARY_KEY,
  TotalSelectorOutput,
  GrossRevenueOutput,
} from './types';

const pageStatistics = (state: PageStatisticsReduxState): PageStatisticsState => state.pageStatistics ?? {};

const error = (state: PageStatisticsReduxState): AsyncComponentStateError => pageStatistics(state).error;

const total = createSelector<
  PageStatisticsReduxState,
  Languages,
  PageStatisticsState,
  { language: Languages },
  TotalSelectorOutput
>(
  pageStatistics,
  (_, language: Languages) => ({
    language,
  }),
  (pageStatisticsState: PageStatisticsState, { language }): TotalSelectorOutput => {
    if (
      !pageStatisticsState.statics ||
      !pageStatisticsState.statics[SUMMARY_KEY] ||
      (pageStatisticsState.isLoading ?? true)
    ) {
      return null;
    }

    const { total: count } = pageStatisticsState.statics[SUMMARY_KEY];

    return {
      total: count,
      totalFormatted: LoveFormatter.format(count, language),
    };
  },
);

const grossRevenueOfMonth = createSelector<
  PageStatisticsReduxState,
  string,
  PageStatisticsState,
  { month: string },
  GrossRevenueOutput
>(
  pageStatistics,
  (_, month: string) => ({ month }),
  (pageStatisticsState: PageStatisticsState, { month }): GrossRevenueOutput => {
    if (!pageStatisticsState.statics || !pageStatisticsState.statics[month]) {
      return null;
    }

    const {
      revenueInUsd,
      revenueInUsdFromAuctions,
      revenueInUsdFromGiftedLoots,
      revenueInUsdFromPurchasedLoots,
    } = pageStatisticsState.statics[month];

    return {
      revenueInUsd,
      revenueInUsdFromAuctions,
      revenueInUsdFromGiftedLoots,
      revenueInUsdFromPurchasedLoots,
    };
  },
);

const seller = createSelector<PageStatisticsReduxState, PageStatisticsState, IsSellerOuput>(
  pageStatistics,
  (pageStatisticsState: PageStatisticsState): IsSellerOuput => {
    if (pageStatisticsState.statics && pageStatisticsState.lastRequest) {
      return {
        isSeller: pageStatisticsState.statics[pageStatisticsState.lastRequest]?.isSeller ?? false,
        isLoading: false,
      };
    }

    return {
      isSeller: false,
      isLoading: pageStatisticsState.isLoading ?? true,
    };
  },
);

const isPowerSeller = createSelector<PageStatisticsReduxState, PageStatisticsState, boolean>(
  pageStatistics,
  (pageStatisticsState: PageStatisticsState): boolean => {
    if (!pageStatisticsState.lastRequest) {
      return false;
    }

    const statistics = pageStatisticsState.statics?.[pageStatisticsState.lastRequest];
    const purchasedLootsCount = statistics?.purchasedLootsCount ?? 0;

    const isSeller = statistics?.isSeller ?? false;

    return isSeller && purchasedLootsCount >= 3000;
  },
);

const allStatistics = createSelector<
  PageStatisticsReduxState,
  Languages,
  PageStatisticsState,
  { language: Languages },
  AllStatisticsOutput
>(
  pageStatistics,
  (_, language: Languages) => ({ language }),
  (pageStatisticsState: PageStatisticsState, { language }): AllStatisticsOutput => {
    if (!pageStatisticsState.statics) {
      return null;
    }

    const resp: Record<string, SummaryFormated> = {};
    Object.entries(pageStatisticsState.statics).forEach(([key, value]) => {
      if (!value) {
        return;
      }

      resp[key] = {
        ...value,
        total: LoveFormatter.format(value.total, language),
      };
    });
    return resp;
  },
);

export const pageStatisticsSelectors = {
  allStatistics,
  error,
  seller,
  isPowerSeller,
  total,
  grossRevenueOfMonth,
};
