import * as React from 'react';

// This component sets a correct vh for each mobile browser
// It sets the real 1% of vh that changes in different devices
// For more info: https://css-tricks.com/the-trick-to-viewport-units-on-mobile

export const ViewportObserver = (): null => {
  React.useEffect(() => {
    const setVH = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setVH();
    window.addEventListener('resize', setVH);

    return () => {
      window.removeEventListener('resize', setVH);
    };
  }, []);

  return null;
};
