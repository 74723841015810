import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import Skeleton from 'ui-library/skeleton';
import { StreamerCardList } from './StreamerCardList';
import theme from './streamercard.scss';

const classNames = ClassNames(theme);

interface StreamersCardsSkeletonV2Props {
  includeWrapper?: boolean;
}

export const StreamersCardsSkeleton = ({ includeWrapper }: StreamersCardsSkeletonV2Props): JSX.Element => {
  const elementsArray = new Array(5);
  const elements = [...elementsArray.keys()];
  const Wrapper = includeWrapper ? StreamerCardList : React.Fragment;

  return (
    <Wrapper>
      {elements.map(key => (
        <div key={key} className={classNames('card')}>
          <div className={classNames('card__header')} />
          <div className={classNames('card__avatar')}>
            <Skeleton height="100%" block />
          </div>
          <div className={classNames('card__name')}>
            <Skeleton />
          </div>
          <div className={classNames('card__info')}>
            <Skeleton />
          </div>
        </div>
      ))}
    </Wrapper>
  );
};
