/* eslint-disable camelcase */
// These cannot be interface due https://github.com/microsoft/TypeScript/issues/15300
// Snowplot expects a Record<string, unknown> but an interface cannot be instantiate to that

import { AuthProviders, PageType, RankingDivision, StrictOmit } from '@streamloots/streamloots-types';
import { PageModulesValues } from 'model/indexTS';

// This schemas should match with: https://console.snowplowanalytics.com/08b46d14-b9ca-4f1f-8473-b334dc30e587/data-structures
type BaseSchema = {
  app_version: string | null;
  app_language: string | null;
};

export type OmitSnowplowProperties<Type extends BaseSchema> = StrictOmit<Type, 'app_version' | 'app_language'>;

export type PageSchema = {
  page_id: string;
  owner_id: string | null;
  seller_id: string | null;
  page_type: PageType | null;
  is_published: boolean | null;
  language: string | null;
  provider: AuthProviders | null;
};

export type UserSchema = {
  user_id: string | null;
  username: string | null;
  country: string | null;
  usage_intention: string | null;
};

export type FilterAppliedSchema = BaseSchema & {
  name: string;
  location: string | null;
  filter: string;
};

export type RankViewedSchema = BaseSchema & {
  location: string;
  rank_displayed: RankingDivision | 'No division';
  percentile_displayed: number | null;
  community_love: number | null;
  previous_rank: RankingDivision | null;
};

export type RankRewardClickedSchema = BaseSchema & {
  rank: string;
  reward: 'power';
  location: string;
};

export type BuyPacksStartedSchema = BaseSchema & {
  location: string | null;
  type: string | null;
};

export type SignInClickedSchema = BaseSchema & {
  location: string;
};

export type SignInProviderClickedSchema = BaseSchema & {
  location: string;
  button: string;
};

export type BuyPacksCompletedSchema = BaseSchema & {
  location: string | null;
  type: string;
  number_of_packs: number;
  quantity: number;
  amount: number;
  amount_currency: string;
};

export type HalloweenCampaignModalSchema = BaseSchema & {
  total_candies: number;
  milestones_unlocked: number;
  candies_to_next: number;
  location: string;
  modal_key: string;
  reward_name: string | null;
  reward_index: number | null;
};

export type SubscriptionPurchaseStepSchema = BaseSchema & {
  step: 'info' | 'payment';
};

export type SubscriptionPurchasedClickSchema = BaseSchema & {
  location: string;
};

export type RankDivisionClickedSchema = BaseSchema & {
  rank: RankingDivision;
  is_current_rank: boolean;
};

export type PublicPageRankingClickedSchema = BaseSchema & {
  percentile_displayed: number;
  rank_displayed: RankingDivision;
};

export type PublicPageRankingViewedSchema = BaseSchema & {
  percentile_displayed: number;
  rank_displayed: RankingDivision;
};

export type DashboardPartnerSectionClickedSchema = BaseSchema & {
  location: string;
  button: string;
};

export type CancelSubscriptionSchema = BaseSchema;

export type CancelSubscriptionStepSchema = BaseSchema & {
  step: 'confirmation';
  button: string;
};

export type SuggestionClickedSchema = BaseSchema & {
  button: string;
  location: string;
  suggestion: string;
};

export type SuggestionViewedSchema = BaseSchema & {
  location: string;
  suggestion: string;
};

export type CampaignViewedSchema = BaseSchema & {
  campaign: string;
  location: string;
  needed_to_unlock_next?: number;
  milestones_unlocked?: number;
  viewer_entries?: number;
  streamer_entries?: number;
  days_buying?: number;
  days_selling?: number;
};

export type MarathonStatusChangedSchema = BaseSchema & {
  marathon_id: string;
  action: 'start' | 'stop' | 'pause' | 'resume';
  location: 'Marathon page' | 'manage marathon panel';
};

export type WidgetUrlClickedSchema = BaseSchema & {
  widget:
    | 'alerts'
    | 'auction'
    | 'card timer'
    | 'emotes'
    | 'subs goal'
    | 'packs goal'
    | 'campaigns'
    | 'community ranking viewers'
    | 'marathon'
    | 'subs multigoal'
    | 'packs multigoal';
  button: 'copy' | 'open';
};

export type DashboardButtonClickedSchema = BaseSchema & {
  button: string;
  location: string;
};

export type PublicPageExternalLinkClickedSchema = BaseSchema & {
  button: string;
};

export type OnboardingModuleSelectedSchema = BaseSchema & {
  module: PageModulesValues;
};

export type PageModuleClickedSchema = BaseSchema & {
  module: PageModulesValues;
  button: string;
  location: string;
};

export type SendMessageClickedSchema = BaseSchema & {
  type: 'email';
  location: 'dashboard' | 'community';
  segment: 'all' | 'individual' | 'subscribers';
};

export type SubscriptionsAddReactionClickedSchema = BaseSchema & {
  alert_type: 'emote' | 'reaction';
};

export type MyCommunityViewerClickedSchema = BaseSchema & {
  location: 'my viewers community page';
  button: 'view details';
  user_id: string;
};

export type OnboardingSectionViewedSchema = BaseSchema & {
  location: string;
};

export type OnboardingClickedSchema = BaseSchema & {
  button: string;
  location: string;
};

export type OriginalsClickedSchema = BaseSchema & {
  location: string;
  button: string;
  frame_id: string;
};

export type FeedClickedSchema = BaseSchema & {
  location: string;
  button: string;
  content_id?: string | null;
  like_count?: number | null;
  comment_count?: number | null;
};

export type FeedSectionViewedSchema = BaseSchema & {
  location: 'feed content';
  section: 'tips checkout' | 'paid content checkout';
};

export type CreatorClickedSchema = BaseSchema & {
  location: 'my subscriptions';
  button: 'dots menu' | 'cancel sub' | 'see page';
  page: string;
  page_id: string;
  subscription_status?: 'active' | 'expired' | 'canceled' | null;
  total_packs_purchased?: number;
  total_months_subscribed?: number;
  total_subs_gifted?: number;
  community_love?: number;
};

// IMPORTANT: If you modify this enum you MUST review globalMocks.js
// If you try to import this schema in globalMock.js to avoid the manual update
// you will break cookieManager.spec.ts
export enum SnowplowSchemas {
  Page = 'iglu:com.streamloots/page/jsonschema/1-0-4',
  User = 'iglu:com.streamloots/user/jsonschema/1-0-3',
  FilterApplied = 'iglu:com.streamloots/filter_applied/jsonschema/1-0-2',
  RankViewed = 'iglu:com.streamloots/rank_viewed/jsonschema/1-0-0',
  BuyPacksStarted = 'iglu:com.streamloots/buy_packs_started/jsonschema/1-0-0',
  BuyPacksCompleted = 'iglu:com.streamloots/buy_packs_completed/jsonschema/1-0-0',
  HalloweenCampaignModalSchema = 'iglu:com.streamloots/halloween_campaign_modal_viewed/jsonschema/1-0-0',
  SubscriptionPurchasedClick = 'iglu:com.streamloots/purchase_subscription_clicked/jsonschema/1-0-1',
  SubscriptionPurchaseStep = 'iglu:com.streamloots/purchase_subscription_step/jsonschema/1-0-0',
  PublicPageRankingClicked = 'iglu:com.streamloots/public_page_rank_clicked/jsonschema/1-0-0',
  PublicPageRankingViewed = 'iglu:com.streamloots/public_page_rank_viewed/jsonschema/1-0-0',
  SuggestionViewed = 'iglu:com.streamloots/suggestion_viewed/jsonschema/1-0-0',
  SuggestionClicked = 'iglu:com.streamloots/suggestion_clicked/jsonschema/1-0-0',
  RankDivisionClicked = 'iglu:com.streamloots/rank_division_clicked/jsonschema/2-0-0',
  RankRewardClicked = 'iglu:com.streamloots/rank_reward_clicked/jsonschema/1-0-0',
  DashboardPartnerSectionClicked = 'iglu:com.streamloots/dashboard_partner_section_clicked/jsonschema/1-0-1',
  CancelSubscription = 'iglu:com.streamloots/cancel_subscription_clicked/jsonschema/1-0-0',
  CancelSubscriptionStep = 'iglu:com.streamloots/cancel_subscription_step/jsonschema/1-0-0',
  CampaignViewed = 'iglu:com.streamloots/campaign_viewed/jsonschema/1-0-0',
  MarathonStatusChanged = 'iglu:com.streamloots/marathon_status_changed/jsonschema/1-0-1',
  DashboardButtonClicked = 'iglu:com.streamloots/dashboard_button_clicked/jsonschema/1-0-0',
  OnboardingModuleSelected = 'iglu:com.streamloots/onboarding_module_selected/jsonschema/1-0-0',
  PageModuleClicked = 'iglu:com.streamloots/page_module_clicked/jsonschema/1-0-0',
  SendMessageClicked = 'iglu:com.streamloots/send_message_clicked/jsonschema/2-0-0',
  PublicPageExternalLinkClicked = 'iglu:com.streamloots/public_page_external_link_clicked/jsonschema/1-0-0',
  WidgetUrlClicked = 'iglu:com.streamloots/widget_url_clicked/jsonschema/1-0-0',
  SubscriptionsGoalsClicked = 'iglu:com.streamloots/subscriptions_goals_clicked/jsonschema/1-0-0',
  SubscriptionsAddAlertClicked = 'iglu:com.streamloots/subscriptions_add_alert_clicked/jsonschema/1-0-0',
  MyCommunityViewerClicked = 'iglu:com.streamloots/my_community_viewer_clicked/jsonschema/1-0-0',
  OnboardingSectionViewed = 'iglu:com.streamloots/onboarding_section_viewed/jsonschema/1-0-0',
  OnboardingClicked = 'iglu:com.streamloots/onboarding_clicked/jsonschema/1-0-0',
  OriginalsClicked = 'iglu:com.streamloots/originals_clicked/jsonschema/1-0-0',
  SignInClicked = 'iglu:com.streamloots/sign_in_clicked/jsonschema/1-0-0',
  SignInProviderClicked = 'iglu:com.streamloots/sign_in_provider_clicked/jsonschema/1-0-0',
  FeedClicked = 'iglu:com.streamloots/feed_clicked/jsonschema/1-0-0',
  CreatorClicked = 'iglu:com.streamloots/creator_clicked/jsonschema/1-0-0',
  FeedSectionViewed = 'iglu:com.streamloots/feed_section_viewed/jsonschema/1-0-0',
}
