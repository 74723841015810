import { useEffect } from 'react';
import ClassNames from '@streamloots/classnames';
import trackEventManager from 'utils/event-manager';
import Icons from 'ui-library/icons';
import theme from './notifications-bell.scss';

const classNames = ClassNames(theme);

type NotificationsBellProps = {
  openNotifications: () => void;
  count: number | string;
};

export const NotificationsBell = ({ count, openNotifications }: NotificationsBellProps): JSX.Element => {
  const isActive = Boolean(count);
  useEffect(() => {
    if (isActive) {
      trackEventManager.trackEvent('pendingNotificationsToRead', { count });
    }
  }, [isActive, count]);

  return (
    <button
      type="button"
      className={classNames({
        'notifications__bell': true,
        'notifications__bell--active': isActive,
      })}
      data-notnumber={typeof count === 'string' || count <= 9 ? count : '+9'}
      onClick={openNotifications}
    >
      <Icons id="bell" className={theme.notifications__bell__icon} />
    </button>
  );
};
