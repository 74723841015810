import type { FunctionComponent } from 'react';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import type { OpenOverloadedContent } from 'services/overloaded-content';
import { pageSelectors } from 'services/page';
import { setsSelectors } from 'services/sets';
import { UpdateUserPreferences, userActions, userSelectors } from 'services/user';
import { overloadedContentActions } from 'services/overloaded-content';
import { userSetsSelectors } from 'services/user-sets';
import { appSelectors } from 'services/app';
import { TranslateInterface } from 'utils/translation';
import { Permissions, UserPreferences } from 'model/indexTS';
import { MenuSections } from 'model/userTS';
import configuration from 'config';
import { alertSelectors } from 'services/alert';
import { achievementSelectors } from 'services/achievement';
import { CampaingActive, Campaings } from 'domain/campaing-active';
import type { CreatorMenuSectionProps } from './CreatorMenuSection';
import { CREATOR_LINKS, STREAMER_SECTIONS } from './links';
import { CreatorMenuSection } from './CreatorMenuSection';
import theme from './menu.scss';
import { MainMenuPermissionLink } from './components/menu-content-components';
import { GiftChest } from '../gift-chest';
import { SpecialConditionsProps } from './menu-permissions-checker';

interface OwnProps extends TranslateInterface {
  permissions: Permissions;
}

interface MapStateToProps extends SpecialConditionsProps {
  currentPathname: string;
  userPreferences: UserPreferences;
}

interface MapDispatchToProps {
  openOverloadedContent: OpenOverloadedContent;
  updateUserPreferences: UpdateUserPreferences;
}

interface CreatorMenuProps extends CreatorMenuSectionProps, MapStateToProps, MapDispatchToProps {}

const CreatorMenuInternal = ({
  openOverloadedContent,
  updateUserPreferences,
  currentPathname,
  userPreferences,
  ...rest
}: CreatorMenuProps) => {
  const { pageModules } = rest;

  const handleGiftClick = () => {
    openOverloadedContent({
      component: GiftChest,
      wrapperProps: { type: 'small' },
    });
  };

  const handleMenuToogle = (menuKey: MenuSections, isOpen: boolean) => {
    const mainMenu = {
      ...userPreferences.mainMenu,
      [menuKey]: isOpen,
    };

    updateUserPreferences({
      ...userPreferences,
      mainMenu,
    });
  };

  return (
    <div className={theme.menu__content}>
      <MainMenuPermissionLink link={CREATOR_LINKS.PUBLIC} {...rest} />
      <MainMenuPermissionLink link={CREATOR_LINKS.DASHBOARD} {...rest} />
      {pageModules?.cards.enabled ? (
        <CreatorMenuSection
          {...rest}
          linkSection={STREAMER_SECTIONS.collections}
          onClickMapping={{ onGiftClick: handleGiftClick }}
          currentPathname={currentPathname}
          isForcedOpen={userPreferences?.mainMenu?.collections ?? false}
          onToggle={handleMenuToogle}
        />
      ) : (
        <MainMenuPermissionLink link={CREATOR_LINKS.ENABLE_CARDS} {...rest} />
      )}
      <MainMenuPermissionLink
        link={pageModules?.subscriptions.enabled ? CREATOR_LINKS.SUBSCRIPTIONS : CREATOR_LINKS.ENABLE_SUBSCRIPTIONS}
        {...rest}
      />
      <MainMenuPermissionLink
        link={pageModules?.auctions.enabled ? CREATOR_LINKS.AUCTIONS : CREATOR_LINKS.ENABLE_AUCTIONS}
        {...rest}
      />
      <MainMenuPermissionLink
        link={pageModules?.marathons?.enabled ? CREATOR_LINKS.MARATHONS : CREATOR_LINKS.ENABLE_MARATHONS}
        {...rest}
      />
      {configuration.modules.DONATIONS_ENABLED && (
        <MainMenuPermissionLink
          link={pageModules?.donations?.enabled ? CREATOR_LINKS.DONATIONS : CREATOR_LINKS.ENABLE_DONATIONS}
          {...rest}
        />
      )}
      <MainMenuPermissionLink link={CREATOR_LINKS.REVENUE} {...rest} />
      <MainMenuPermissionLink link={CREATOR_LINKS.ACTIVITY_FEED} {...rest} />
      <MainMenuPermissionLink link={CREATOR_LINKS.MY_VIEWERS_COMMUNITY} {...rest} />
      <CreatorMenuSection
        {...rest}
        linkSection={STREAMER_SECTIONS.administration}
        currentPathname={currentPathname}
        onToggle={handleMenuToogle}
        isForcedOpen={userPreferences?.mainMenu?.configuration ?? false}
      />
      <MainMenuPermissionLink link={CREATOR_LINKS.STAFF} {...rest} />
      {/* <MainMenuPermissionLink link={TOP_STREAMERS_LEADERBOARD} {...rest} /> */}
    </div>
  );
};

const mapStateToProps = (state): MapStateToProps => {
  const currentSetPublished = !!userSetsSelectors.selectedUserSet(state);
  const hasPublishedSets = currentSetPublished || setsSelectors.setsPublishedCount(state) > 0;
  const hasPowers = alertSelectors.activePowers(state).length > 0;
  const activeAchievements = achievementSelectors.canDoMoreAchievements(state);

  return {
    pageModules: pageSelectors.pageModules(state),
    hasPublishedSets,
    hasSets: Boolean(setsSelectors.sets(state)?.length),
    setPublished: currentSetPublished,
    registeredAt: userSelectors.userCreatedAt(state),
    currentPathname: appSelectors.currentPathname(state),
    userPreferences: userSelectors.userPreferences(state),
    hasPowers,
    activeAchievements,
  };
};

export const CreatorMenu = compose<FunctionComponent<OwnProps>>(
  connect(mapStateToProps, {
    openOverloadedContent: overloadedContentActions.openOverloadedContent,
    updateUserPreferences: userActions.updateUserPreferences,
  }),
)(CreatorMenuInternal);
