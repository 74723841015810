import { TransactionsQuery } from 'services/transactions/types';
import { PaymentOwnerType } from 'model/indexTS';
import type { PaginationStateNormalizerConfiguration } from '../pagination/typesTS';

// Days of the month to get payment in a labor week
export const NEXT_PAYOUT_DATE_MAPPING = {
  2021: {
    0: 15,
    1: 15,
    2: 15,
    3: 15,
    4: 17,
    5: 15,
    6: 15,
    7: 16,
    8: 15,
    9: 15,
    10: 15,
    11: 15,
    12: 15,
  },
  2022: {
    0: 17,
    1: 15,
    2: 15,
    3: 18,
    4: 16,
    5: 15,
    6: 15,
    7: 16,
    8: 15,
    9: 17,
    10: 15,
    11: 15,
    12: 15,
  },
};

export const DEFAULT_LIMIT = 30;
export const DEFAULT_QUERY: TransactionsQuery = {
  limit: DEFAULT_LIMIT,
  ownerType: PaymentOwnerType.SELLER,
  ownerId: '',
};

export const PAGINATION_STATE_NORMALIZER_CONFIGURATION: PaginationStateNormalizerConfiguration<TransactionsQuery> = {
  timeProperty: 'createdAt',
  initialQuery: DEFAULT_QUERY,
};

export const NEXT_PAYOUT_INFO = {
  minWithdraw: 20,
};
