import ClassNames from '@streamloots/classnames';
import { withTranslation, TranslateInterface } from 'utils/translation';
import { UserSetLoot } from 'model/indexTS';
import { currencyManager } from 'services/culture';
import { Badge } from 'ui-library/badge';
import Button from 'ui-library/button';
import { CardsYouGonnaGet } from '../cards-you-gonna-get';
import theme from './pack-price-with-discount.scss';

const classNames = ClassNames(theme);

interface OwnProps {
  loot: UserSetLoot & { discount: number; originalPrice: number };
  referenceLoot: UserSetLoot;
  onLootSelected: () => void;
}

interface PackPriceWithDiscountProps extends TranslateInterface, OwnProps {}

const BasePackPriceWithDiscount = ({ loot, referenceLoot, onLootSelected, t }: PackPriceWithDiscountProps) => {
  return (
    <>
      <div className={classNames('pack-price-with-discount__header')}>
        {loot.discount > 0 && (
          <>
            <p className={classNames('pack-price-with-discount__unit-price')}>
              {currencyManager.getFormattedPrice(loot.originalPrice, referenceLoot.currency)}
            </p>
            <Badge label={t('lootPacks:discountAmount', { discount: loot.discount })} color="notification" />
          </>
        )}
      </div>
      <Button
        className={classNames({
          'pack-price-with-discount__button': true,
          'pack-price-with-discount__button--most-popular': loot.mostPopular,
        })}
        color="checkout"
        variant="filled"
        onClick={onLootSelected}
        data-most-popular-label={loot.mostPopular ? t('lootPacks:mostPopular') : undefined}
      >
        <span className={classNames('pack-price-with-discount__button__packs')}>
          {t('lootPacks:chests', { count: loot.quantity })}
        </span>
        <span className={classNames('pack-price-with-discount__button__price')}>
          {currencyManager.getFormattedPrice(loot.price, loot.currency)}
        </span>
      </Button>
      <CardsYouGonnaGet
        className={classNames('pack-price-with-discount__footer')}
        cardsAmount={loot.cardAmount}
        quantity={loot.quantity}
      />
    </>
  );
};

export const PackPriceWithDiscount = withTranslation('lootPacks')(
  BasePackPriceWithDiscount,
) as React.FunctionComponent<OwnProps>;
