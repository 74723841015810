import * as _ from 'lodash';
import mixpanelBrowser from 'mixpanel-browser';

import config from 'config';
import type { User } from 'model/indexTS';
import cultureCookieManager from 'utils/cultureCookieManager';

import eventsHelper from './eventsHelpers';

declare let mixpanel: any;

const initializeInstanceIfNeeded = () => {
  if (mixpanel) {
    return;
  }
  mixpanel = mixpanelBrowser.init(config.mixpanel.projectToken, {
    api_host: 'https://api-eu.mixpanel.com',
    persistence: 'localStorage',
  });
};

const isMixpanelAdded = (): boolean => eventsHelper.isEventSenderAdded('mixpanel');

const sendMixpanelEvent = (eventName: string, eventData: Record<string, any>): void => {
  try {
    const data = {};
    Object.keys(eventData).forEach(key => {
      let newKey = _.snakeCase(key);
      if (key.startsWith('$')) {
        newKey = `$${newKey}`;
      }
      data[newKey] = eventData[key];
    });
    mixpanel.track(eventName, data);
  } catch (e) {
    console.error(e);
  }
};

const updateUserInfo = (userProperties: Record<string, any>): void => {
  if (!isMixpanelAdded()) {
    return;
  }
  initializeInstanceIfNeeded();
  try {
    const data = {};
    Object.keys(userProperties).forEach(key => {
      let newKey = _.snakeCase(key);
      if (key.startsWith('$')) {
        newKey = `$${newKey}`;
      }
      data[newKey] = userProperties[key];
    });
    mixpanel.people.set({ ...data });
  } catch (e) {
    console.error(e);
  }
};

export default {
  init(): void {
    if (!isMixpanelAdded()) {
      return;
    }
    initializeInstanceIfNeeded();
    updateUserInfo({ pageLanguage: eventsHelper.getPageLanguage() });
  },
  signInUser(user: User): void {
    if (!isMixpanelAdded()) {
      return;
    }
    initializeInstanceIfNeeded();

    const {
      _id,
      profile: { username, primaryEmail },
    } = user;

    mixpanel.identify(_id);

    updateUserInfo({
      username,
      email: primaryEmail,
      pageLanguage: eventsHelper.getPageLanguage(),
    });
  },
  signOutUser(): void {
    if (!isMixpanelAdded()) {
      return;
    }

    mixpanel.reset();
  },
  sendEvent(eventName: string, eventData: Record<string, any> = {}): void {
    initializeInstanceIfNeeded();
    sendMixpanelEvent(eventName, {
      ...eventData,
      appLanguage: cultureCookieManager.getCulture(),
    });
  },
  updateUserInfo,
};
/* eslint-enable */
