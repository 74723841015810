import * as React from 'react';
import ReactDOM from 'react-dom';
import ClassNames from '@streamloots/classnames';
import { ButtonIcon } from 'ui-library/button';
import theme from './fixed-close-button.scss';

const classNames = ClassNames(theme);

interface FixedCloseButtonProps {
  onClose: () => void;
  ariaLabel?: string;
}
/*
 * This button should be fixed in mobile when you scroll
 * CSS problems when you have a fixed with overflow
 */
export const FixedCloseButton = ({ onClose, ariaLabel }: FixedCloseButtonProps): JSX.Element => {
  return ReactDOM.createPortal(
    <ButtonIcon icon="close" simple className={classNames('close-button')} onClick={onClose} ariaLabel={ariaLabel} />,
    document.body,
  );
};
