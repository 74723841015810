import type { PaginationStateNormalizerConfiguration } from 'services/pagination/typesTS';
import { ViewersQuery } from './services/list/types';

export const DEFAULT_VIEWERS_QUERY: ViewersQuery = {
  limit: 50,
  slug: '',
};

export const PAGINATION_STATE_NORMALIZER_CONFIGURATION: PaginationStateNormalizerConfiguration<ViewersQuery> = {
  initialQuery: DEFAULT_VIEWERS_QUERY,
};
