import { TopMenu } from 'scenes/menu';
import classNames from '@streamloots/classnames';
import theme from './layout.scss';

const ClassNames = classNames(theme);

interface LayoutHeaderProps {
  isChristmas: boolean;
}

export const LayoutHeader = ({ isChristmas }: LayoutHeaderProps): JSX.Element => {
  return (
    <header className={ClassNames({ 'layout__header': true, 'layout__header--christmas': isChristmas })}>
      <TopMenu />
    </header>
  );
};
