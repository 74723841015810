import { BADGES_FOLDER } from 'constants/images';

export enum AchievementBadgeId {
  TopStreamer = 'TopStreamer',
}

export interface AchievementBadgeApiResponse {
  // TODO
  id: AchievementBadgeId;
}

export const ACHIEVEMENT_BADGE_MAPPING: Record<
  AchievementBadgeId,
  { id: AchievementBadgeId; nameKey: string; descriptionKey: string; imageUrl: string }
> = {
  [AchievementBadgeId.TopStreamer]: {
    id: AchievementBadgeId.TopStreamer,
    nameKey: `common:achievementBadges.${AchievementBadgeId.TopStreamer}`,
    descriptionKey: `common:achievementBadges.${AchievementBadgeId.TopStreamer}Description`,
    imageUrl: `${BADGES_FOLDER}badge-heart-min.png`,
  },
};

export interface AchievementBadgeInfo {
  id: AchievementBadgeId;
  achievedAt: string;
  imageUrl: string;
  count: number;
}
