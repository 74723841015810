import { Christmas2021CliamableCampaign } from 'model/claimableCampaigns';

export const getCompletedViewerMilestones = (distinctDays: number): number => {
  if (distinctDays < 3) {
    return 0;
  }

  if (distinctDays < 5) {
    return 1;
  }

  if (distinctDays < 8) {
    return 2;
  }

  if (distinctDays < 12) {
    return 3;
  }

  return 4;
};

export const getCompletedStreamerMilestones = (distinctDaysPurchasingCount: number): number => {
  if (distinctDaysPurchasingCount < 2) {
    return 0;
  }

  if (distinctDaysPurchasingCount < 4) {
    return 1;
  }

  if (distinctDaysPurchasingCount < 8) {
    return 2;
  }
  if (distinctDaysPurchasingCount < 12) {
    return 3;
  }

  return 4;
};

export const getClaimableCampaignWithCompletedMilestones = (
  campaignInfo: Christmas2021CliamableCampaign,
): Christmas2021CliamableCampaign => {
  const { userContribution } = campaignInfo;

  if (!userContribution) {
    return campaignInfo;
  }

  return {
    ...campaignInfo,
    userContribution: {
      ...userContribution,
      completedViewerBonuses: getCompletedViewerMilestones(userContribution.distinctDaysPurchasingCount),
      completedStreamerGiveaways: getCompletedStreamerMilestones(userContribution.distinctDaysSellingCount),
    },
  };
};
