import { FetchResponseError, FetchResponseSuccess } from 'types/indexTS';
import actionCreator from 'helpers/actionCreator';
import { HeartbeatResponse } from './types';
import endpoints from '../endpoints';
import actionTypes from './actionTypes';

function getHeartbeatSuccess({ data }: { data: HeartbeatResponse }) {
  return actionCreator.createAction(actionTypes.GET_HEARTBEAT_SUCCESS, data.geoip);
}

function getHeartbeatError({ errorData }) {
  return actionCreator.createErrorAction(actionTypes.GET_HEARTBEAT_ERROR, errorData);
}

/* eslint-disable-next-line */
const getHeartbeat = ():
  | FetchResponseSuccess<HeartbeatResponse>
  | FetchResponseError => {
  return actionCreator.createAsyncAction({
    method: 'get',
    endpoint: endpoints.HEARTBEAT.GET,
    errorBinding: getHeartbeatError,
    loadingAction: actionTypes.GET_HEARTBEAT_REQUEST,
    successBinding: getHeartbeatSuccess,
  });
};

export const cultureActions = {
  getHeartbeat,
};
