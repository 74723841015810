export enum ActivityFeedActionTypes {
  FETCHING = 'activityFeed/FETCHING',
  RECEIVED = 'activityFeed/RECEIVED ',
  ERROR = 'activityFeed/FETCH-ERROR',
  FINISHING = 'activityFeed/FINISHING',
  FINISHED = 'activityFeed/FINISHED',
  CANCEL_REEDEMED = 'activityFeed/CANCEL_REEDEMED',
  FETCHING_NEW = 'activityFeed/FETCHING_NEW',
  ERROR_NEW = 'activityFeed/ERROR_NEW',
  RECEIVED_NEW = 'activityFeed/RECEIVED_NEW',
  FETCHING_NEXT = 'activityFeed/FETCHING_NEXT',
  ERROR_NEXT = 'activityFeed/ERROR_NEXT',
  RECEIVED_NEXT = 'activityFeed/RECEIVED_NEXT',
}
