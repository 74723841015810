import ClassNames from '@streamloots/classnames';
import type { User } from 'model/indexTS';
import { useTranslation } from 'utils/translation';
import StreamerSearcher from '../streamers-searcher';
import LogInButton from './LogInButton';
import UserAuthenticatedSection from './ConnectedAuthenticatedSection';
import { MainMenuMobileButton } from './MainMenuMobileButton';
import theme from './top-menu.scss';

const classNames = ClassNames(theme);

export interface TopMenuProps {
  isMobile: boolean;
  user: User | null;
}

export const TopMenu = ({ isMobile, user }: TopMenuProps): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <div className={classNames('top-menu')}>
      {isMobile && <MainMenuMobileButton t={t} />}
      <div className={classNames('top-menu__content')}>
        <StreamerSearcher />
      </div>
      <div className={classNames('top-menu__user-menu')}>
        {user ? <UserAuthenticatedSection /> : <LogInButton t={t} />}
      </div>
    </div>
  );
};
