import { AuctionActionTypes } from 'services/auction';
import { UserActionTypes } from 'services/user';
import { PageActionTypes } from 'services/page';
import type { ReconcileState } from '../../types';

const initialState: ReconcileState = {};

export const reducer = (
  state: ReconcileState = initialState,
  action: { type: string; error?: string },
): ReconcileState => {
  const { type, error } = action;

  switch (type) {
    case UserActionTypes.RECEIVED:
    case PageActionTypes.RECEIVED:
    case AuctionActionTypes.POST_AUCTION_SUCCESS:
    case AuctionActionTypes.PUT_AUCTION_SUCCESS:
    case AuctionActionTypes.PATCH_AUCTION_SUCCESS:
      if (error) {
        return state;
      }
      return { reconcile: true };
    case AuctionActionTypes.GET_AUCTIONS_SUCCESS:
      return { reconcile: false };
    default:
      return state;
  }
};
