import config from '../../config';

import {
  IMAGE_TYPES,
  Transformation,
  TransformationOptions,
  VIDEO_AND_AUDIO_TYPES,
  getExtension,
} from './Transformation';

const { CDN_CLOUDINARY_URL, CDN_AWS_UPLOADS_URL: CDN_UPLOADS_URL, CDN_AWS_STATIC_URL: CDN_STATIC_URL } = config.cdn;

export default class CloudinaryTransformation implements Transformation {
  private transformations: string[];

  constructor(private url: string, transformationOptions = {}) {
    this.transformations = this.getTransformations(transformationOptions);
  }

  isSupported(): boolean {
    return true;
  }

  toUrl(): string {
    if (!this.url) {
      return this.url;
    }

    const cdnSelector: { [key: string]: string } = {
      static: CDN_STATIC_URL,
      uploads: CDN_UPLOADS_URL,
    };

    const cdnType = this.getCdnType(this.url);
    const replaceUrl = cdnSelector[cdnType];
    let fileUri = this.url.replace(replaceUrl, '');

    const resourceTypesValues = {
      image: '/image/upload/',
      video: '/video/upload/',
      raw: '/raw/upload/',
    };
    const resourceTypeUri = resourceTypesValues[this.getResourceTypeFromUrl(this.url)];

    const paramString = this.transformations.join(',');

    let staticCdn = '';
    if (cdnType === 'static') {
      staticCdn = '/static';
    }

    if (this.transformations.length === 0) {
      fileUri = fileUri.substr(1);
    }

    return CDN_CLOUDINARY_URL + resourceTypeUri + paramString + staticCdn + fileUri;
  }

  private getTransformations(transformationOptions: TransformationOptions = {}): string[] {
    const transformations: string[] = [];

    if (transformationOptions.bestFormat) {
      transformations.push('f_auto');
    }

    if (transformationOptions.scale) {
      transformations.push('c_scale');
    }

    if (transformationOptions.width) {
      transformations.push(`w_${transformationOptions.width}`);
    }

    if (transformationOptions.quality) {
      transformations.push(`q_${transformationOptions.quality}`);
    }

    return transformations;
  }

  private getResourceTypeFromUrl(url: string): string {
    if (!url) {
      return 'raw';
    }

    const extension = getExtension(url);
    if (!extension) {
      return 'raw';
    }

    if (IMAGE_TYPES.includes(extension)) {
      return 'image';
    }

    if (VIDEO_AND_AUDIO_TYPES.includes(extension)) {
      return 'video';
    }

    return 'raw';
  }

  private getCdnType(url: string): string {
    if (!url) {
      return '';
    }

    if (url.indexOf(CDN_UPLOADS_URL) > -1) {
      return 'uploads';
    }

    if (url.indexOf(CDN_STATIC_URL) > -1) {
      return 'static';
    }

    return '';
  }
}
