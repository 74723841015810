import { Field, WrappedFieldProps, BaseFieldProps } from 'redux-form';
import { FieldSharedProps } from 'ui-library/forms/typesTS';
import { InputText, InputTextProps } from '../form-elements';

interface InputFieldProps extends Pick<BaseFieldProps, 'validate' | 'parse' | 'format'>, InputTextProps {}

interface InputWrappedFieldProps extends WrappedFieldProps, InputTextProps {}

export const InputWrappedField = ({ label, input, meta, ...rest }: InputWrappedFieldProps) => {
  const { error, touched } = meta;
  return <InputText {...input} label={label} error={touched && error ? error : ''} {...rest} />;
};

export const InputField = (props: FieldSharedProps<InputTextProps>) => (
  <Field component={InputWrappedField} {...props} />
);

export const DateInputField = (props: FieldSharedProps<InputTextProps>) => (
  <Field component={InputWrappedField} {...props} type="date" />
);

export const TimeInputField = (props: FieldSharedProps<InputFieldProps>) => (
  <Field component={InputWrappedField} {...props} type="time" />
);
