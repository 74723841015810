import type { Action, Dispatch, Store } from '@reduxjs/toolkit';
import { pageSelectors, PageActionTypes } from '../page';
import { updateUrls } from './urls';

const urlUpdater = (store: Store) => (next: Dispatch) => (action: Action) => {
  if (action.type !== PageActionTypes.REQUEST && action.type !== PageActionTypes.RECEIVED) {
    return next(action);
  }
  const prevSlug = pageSelectors.slug(store.getState());
  const result = next(action);
  const newSlug = pageSelectors.slug(store.getState());

  if (prevSlug !== newSlug && !!newSlug) {
    updateUrls({ slug: newSlug });
  }
  return result;
};

export default urlUpdater;
