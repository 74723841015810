import { useTranslation } from 'utils/translation';
import { PageHeader } from 'ui-library/headers';
import { PanelSkeletons } from 'ui-library/panel';
import { checkPermissions, permissions } from 'components/permissions';
import loadable from 'components/dynamic-import';
import { FullLayout } from 'scenes/layout';

const EditProbabilities = loadable(() => import('./components/edit-probabilities'), true, <PanelSkeletons count={1} />);

const ProbabilityTesterPanel = loadable(
  () => import('./components/probability-tester'),
  true,
  <PanelSkeletons count={1} />,
);

const ProbabilitiesPageInternal = () => {
  const { t } = useTranslation('common');
  return (
    <FullLayout>
      <PageHeader>{t('common:menu.probabilities')}</PageHeader>
      <EditProbabilities />
      <ProbabilityTesterPanel />
    </FullLayout>
  );
};

export const ProbabilitiesPage = checkPermissions({
  requiredPermissions: [permissions.SETS_READ, permissions.SET_CARDS_READ, permissions.SET_RARITIES_EDIT],
  redirectToBasePage: true,
})(ProbabilitiesPageInternal);
