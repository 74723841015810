import { Component } from 'react';
import { PageType } from '@streamloots/streamloots-types';
import { connect } from 'react-redux';
import type { AcknowledgeTypeApplication } from 'services/page';
import { pageActions, pageSelectors } from 'services/page';
import type { AffiliateApplication, PartnershipApplication } from 'model/indexTS';
import { FixMe } from 'types/indexTS';
import { ApprovedNotification } from './components/application-approved-notification';

interface MapStateToProps {
  affiliateApplication?: AffiliateApplication;
  partnershipApplication?: PartnershipApplication;
  newTypeNotification?: PageType;
  slug: string;
}

interface MapDispatchToProps {
  acknowledgeTypeApplication: AcknowledgeTypeApplication;
}

interface PageTypeNotificationManagerProps extends MapStateToProps, MapDispatchToProps {}

type PageTypeNotificationManagerState = {
  notificationType?: PageType;
  showNotification: boolean;
};

class PageTypeNotificationManagerInternal extends Component<
  PageTypeNotificationManagerProps,
  PageTypeNotificationManagerState
> {
  timeout: FixMe;

  state: PageTypeNotificationManagerState = {
    showNotification: false,
  };

  componentDidMount() {
    const { newTypeNotification } = this.props;
    if (newTypeNotification) {
      this.setState({
        notificationType: newTypeNotification,
      });

      this.timeout = setTimeout(() => {
        this.setState({
          showNotification: true,
          notificationType: newTypeNotification,
        });
      }, 3000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleNotificationRead = () => {
    const { acknowledgeTypeApplication, slug, affiliateApplication, partnershipApplication } = this.props;
    const { notificationType } = this.state;

    if (notificationType === PageType.PARTNER) {
      acknowledgeTypeApplication(partnershipApplication?._id || '', slug, true);
      return;
    }

    acknowledgeTypeApplication(affiliateApplication?._id || '', slug);
  };

  handleHideNotification = () => {
    this.setState({ showNotification: false });
  };

  render() {
    const { notificationType, showNotification } = this.state;
    switch (notificationType) {
      case PageType.PARTNER:
      case PageType.AFFILIATE:
        return (
          <ApprovedNotification
            active={showNotification}
            onShowNotification={this.handleNotificationRead}
            onClose={this.handleHideNotification}
            notificationType={notificationType}
          />
        );
      default:
        return null;
    }
  }
}

const mapStateToProps = (state): MapStateToProps => ({
  affiliateApplication: pageSelectors.affiliateInfo(state).affiliateApplication,
  partnershipApplication: pageSelectors.partnershipInfo(state).partnershipApplication,
  newTypeNotification: pageSelectors.newTypeNotification(state),
  slug: pageSelectors.slug(state),
});

export const PageTypeApplicationApproved = connect<MapStateToProps, MapDispatchToProps>(mapStateToProps, {
  acknowledgeTypeApplication: pageActions.acknowledgeTypeApplication,
})(PageTypeNotificationManagerInternal);
