import * as React from 'react';
import { Link } from 'react-router-dom';
import ClassNames from '@streamloots/classnames';
import type { TranslateInterface } from 'utils/translation';
import type { Page } from 'model/indexTS';
import trackEventManager from 'utils/event-manager';
import AvatarImage from 'components/avatar-image';
import { getUrlWithParams, pages } from 'services/routing';
import { AuthProviders } from '@streamloots/streamloots-types';
import theme from './streamer-list.scss';

const classNames = ClassNames(theme);

interface StreamerOnMenuProps extends TranslateInterface {
  streamer: Page;
  provider: AuthProviders | 'none';
}

export const StreamerOnMenu = ({ streamer, t, provider }: StreamerOnMenuProps): JSX.Element => {
  const {
    slug,
    _id: pageId,
    owner: {
      profile: { avatarImageUrl },
    },
  } = streamer;
  return (
    <div className={classNames({ streamers: true })}>
      <AvatarImage src={avatarImageUrl} alt={slug} className={theme.streamers__avatar} />
      <div className={theme.streamers__texts}>
        <p className={theme.streamers__name}>{slug}</p>
        <Link
          className={theme.streamers__cta}
          onClick={() => {
            trackEventManager.onStreamerClickedFromMenu(slug, pageId, provider);
          }}
          to={getUrlWithParams(pages.USER_PAGE, {
            slug,
          })}
        >
          {t('common:viewCollection')}
        </Link>
      </div>
    </div>
  );
};
