import { Component } from 'react';
import { connect } from 'react-redux';
import type { AsyncComponentProps } from 'types/indexTS';
import type { FetchUserSets } from 'services/user-sets';
import { userSetsActions, userSetsSelectors } from 'services/user-sets';
import { pageSelectors } from 'services/page';
import { dataConciliatorSelectors } from 'services/data-conciliator';

export const FETCH_INTERVAL = 180000;

interface MapStateToProps extends AsyncComponentProps {
  hasLoadedUserSets: boolean;
  slug: string;
  reconcileUserSets: boolean;
}

interface MapDispatchToProps {
  fetchUserSets: FetchUserSets;
}

interface UserSetsProviderProps extends MapStateToProps, MapDispatchToProps {}

class UserSetsProviderInternal extends Component<UserSetsProviderProps> {
  interval?: number;

  componentDidMount() {
    this.fetchUserSetsIfNeeded();
    this.createInterval();
  }

  componentDidUpdate(prevProps) {
    const { reconcileUserSets } = this.props;

    this.fetchUserSetsIfNeeded(reconcileUserSets && !prevProps.reconcileUserSets);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  createInterval = () => {
    clearInterval(this.interval);
    this.interval = window.setInterval(() => {
      if (document && document.visibilityState !== 'visible') {
        return;
      }
      this.fetchUserSetsIfNeeded(true);
    }, FETCH_INTERVAL);
  };

  fetchUserSetsIfNeeded = (reloadUserSets?: boolean) => {
    const { fetchUserSets, isLoading, hasLoadedUserSets, error, slug } = this.props;
    if (!isLoading && ((!error && !hasLoadedUserSets) || reloadUserSets)) {
      fetchUserSets(slug);
    }
  };

  render() {
    return null;
  }
}

const mapStateToProps = (state): MapStateToProps => {
  const userSetsState = userSetsSelectors.userSetsStatus(state);
  return {
    hasLoadedUserSets: userSetsSelectors.hasLoadedUserSets(state),
    slug: pageSelectors.slug(state),
    isLoading: userSetsState.isLoading,
    error: userSetsState.error,
    reconcileUserSets: dataConciliatorSelectors.reconcileUserSets(state),
  };
};

export const UserSetsProvider = connect<MapStateToProps, MapDispatchToProps>(mapStateToProps, {
  fetchUserSets: userSetsActions.fetchUserSets,
})(UserSetsProviderInternal);
