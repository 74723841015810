export enum EmotesStylesActionTypes {
  // Fetch.
  FETCH_EMOTES_STYLES_REQUEST = 'emotesStyles/FETCH_EMOTES_STYLES_REQUEST',
  FETCH_EMOTES_STYLES_ERROR = 'emotesStyles/FETCH_EMOTES_STYLES_ERROR',
  FETCH_EMOTES_STYLES_SUCCESS = 'emotesStyles/FETCH_EMOTES_STYLES_SUCCESS',

  // Update.
  UPDATE_EMOTES_STYLES_REQUEST = 'emotesStyles/UPDATE_EMOTES_STYLES_REQUEST',
  UPDATE_EMOTES_STYLES_ERROR = 'emotesStyles/UPDATE_EMOTES_STYLES_ERROR',
  UPDATE_EMOTES_STYLES_SUCCESS = 'emotesStyles/UPDATE_EMOTES_STYLES_SUCCESS',
}
