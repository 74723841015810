import type { CardRedemptionLimit, UserCooldownDetail, UserCooldownStatus } from 'model/indexTS';

const formatTime = (timeInSeconds: number | undefined): string | undefined => {
  if (!timeInSeconds) {
    return undefined;
  }

  const minutes = Math.floor(timeInSeconds / 60);

  const hours = Math.floor(timeInSeconds / (60 * 60));

  const days = Math.floor(timeInSeconds / (60 * 60 * 24));

  if (timeInSeconds < 60) {
    return `${timeInSeconds}s`;
  }

  if (minutes < 60) {
    return `${minutes}m`;
  }

  if (hours < 24) {
    return `${hours}h`;
  }

  return `${days}d`;
};

const getRemainingMilliseconds = (expirationTime: number): number => {
  const currentTime = new Date();
  const remainingSeconds = expirationTime - currentTime.getTime();
  return remainingSeconds > 0 ? remainingSeconds : 0;
};

const getRemainingTimeForCardCooldown = (cardCooldown: CardRedemptionLimit | undefined) => {
  if (!cardCooldown || !cardCooldown.resetAt) {
    return 0;
  }
  const cardCooldownResetAt = new Date(cardCooldown.resetAt);
  return getRemainingMilliseconds(cardCooldownResetAt.getTime());
};

const getRemainingTimeForUserCooldown = (rarityCooldown: UserCooldownDetail) => {
  if (!rarityCooldown || rarityCooldown.remaining > 0) {
    return 0;
  }
  return getRemainingMilliseconds(rarityCooldown.expirationDateTime);
};

const getCooldownTime = (
  cardRedemptionLimit: CardRedemptionLimit | undefined,
  userCooldown: UserCooldownStatus | undefined,
  rarity: string,
): string | undefined => {
  if (!userCooldown) {
    return undefined;
  }

  const remainingTimeForCardCooldown = getRemainingTimeForCardCooldown(cardRedemptionLimit);

  const rarityCooldown = userCooldown ? userCooldown[rarity.toLowerCase()] : null;

  const remainingTimeForUserCooldown = getRemainingTimeForUserCooldown(rarityCooldown);

  if (remainingTimeForCardCooldown === 0 && remainingTimeForUserCooldown === 0) {
    return undefined;
  }

  return remainingTimeForCardCooldown > remainingTimeForUserCooldown
    ? cardRedemptionLimit?.resetAt
    : rarityCooldown.resetAt;
};

export const cooldownFormatter = {
  getCooldownTime,
  formatTime,
};
