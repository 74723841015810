import type { UserSetLoot } from 'model/lootTS';

export const calculateLootDiscount = (referenceLoot?: UserSetLoot, currentLoot?: UserSetLoot) => {
  if (referenceLoot && currentLoot) {
    const discountedPrice = currentLoot.price / currentLoot.quantity;
    const currentPrice = referenceLoot.price / referenceLoot.quantity;
    const discount = (discountedPrice / currentPrice) * 100;

    return discount >= 100 ? 0 : Math.round(100 - discount);
  }
  return 0;
};
