import type { Store, Unsubscribe } from '@reduxjs/toolkit';
import type { ReduxStore } from './types';

type ChangeFunction = (currentStateStoreState: ReduxStore) => void;

export const createStoreObserver = (store: Store, onChange: ChangeFunction): Unsubscribe => {
  let currentState;

  const handleChange = () => {
    const nextState = store.getState();
    if (nextState !== currentState) {
      currentState = nextState;
      onChange(currentState);
    }
  };

  const unsubscribe = store.subscribe(handleChange);
  handleChange();
  return unsubscribe;
};
