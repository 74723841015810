import { createSelector } from 'reselect';
import { FixMe, ReduxStore } from 'types/indexTS';
import type { PageFeedState } from './types';

const pageFeedState = (state: ReduxStore): PageFeedState => state.pageFeed;

const fetchingCancelToken = createSelector([pageFeedState], (state: PageFeedState): FixMe => state.fetchingCancelToken);

export const pageFeedSelectors = {
  pageFeedState,
  fetchingCancelToken,
};
