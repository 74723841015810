import { ReactNode, useMemo, useState } from 'react';
import { useReactAlert } from 'components/react-alert-provider';
import { ActionError, ActionSuccess } from 'types/indexTS';

interface UseResponseAlertOptions {
  successMessage: ReactNode;
  successAdditionalMessages?: string[];
  onSuccess?: () => void;
  updateLoadingOnFinish?: boolean; // When true it updates isLoading to false after finishing the request
}

const useAsyncConfirmation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const alert = useReactAlert();

  const modifiers = useMemo(() => {
    return {
      initRequest: () => setIsLoading(true),
      finalizeRequest: <T extends unknown>(
        response: Pick<ActionError<string>, 'error' | 'payload'> | Pick<ActionSuccess<string, T>, 'error'>,
        options: UseResponseAlertOptions,
      ) => {
        const { onSuccess, successMessage, updateLoadingOnFinish, successAdditionalMessages } = options;

        if (updateLoadingOnFinish || response.error) {
          setIsLoading(false);
        }

        if (response.error) {
          alert.showError(response.payload.message);
          return;
        }

        alert.showSuccess(successMessage);

        if (successAdditionalMessages) {
          successAdditionalMessages.forEach(message => alert.showSuccess(message));
        }

        if (onSuccess) {
          onSuccess();
        }
      },
    };
  }, [alert]);

  return {
    ...modifiers,
    isLoading,
  };
};

export default useAsyncConfirmation;
