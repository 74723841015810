import stateCreator from 'helpers/stateCreator';
import { paymentActionTypes, PaymentActions } from 'services/payment';
import { PageActionTypes } from 'services/page';
import { PageRankingAction, PageRankingState } from './types';
import { PageRankingActionTypes } from './actionTypes';

const initialState: PageRankingState = {};

export const reducer = (
  state: PageRankingState = initialState,
  action: PageRankingAction | PaymentActions,
): PageRankingState => {
  switch (action.type) {
    case PageRankingActionTypes.RECEIVED: {
      if (action.error) {
        return stateCreator.getAsyncErrorState(action, state, initialState);
      }

      return stateCreator.getInitialAsyncState(state, {
        pageRanking: action.payload,
      });
    }
    case PageRankingActionTypes.FETCHING:
    case PageRankingActionTypes.FETCHING_VIEWER_RANKING:
      return stateCreator.getAsyncLoadingState(state, { reconcile: false });
    case PageRankingActionTypes.RECEIVED_VIEWER_RANKING: {
      if (action.error) {
        return stateCreator.getAsyncErrorState(action, state, initialState);
      }

      return stateCreator.getInitialAsyncState(state, {
        pageRankingViewer: action.payload,
      });
    }
    case PageActionTypes.CHANGED:
      return { ...initialState };
    case paymentActionTypes.EXECUTED:
    case paymentActionTypes.STRIPE_ORDER_EXECUTED:
      return { ...state, reconcile: true };
    default:
      return state;
  }
};
