import { PageActionTypes } from 'services/page';
import type { TimerState } from './types';
import actionTypes from './actionTypes';

export default (state: TimerState = {}, action): TimerState => {
  const { type, error, payload } = action;
  switch (type) {
    case PageActionTypes.RECEIVED: {
      if (error || !payload.alertProviders) {
        return {};
      }
      const { alertProviders } = payload;
      const { streamloots } = alertProviders;

      const { pageId, ...timerConfiguration } = streamloots.settings.timer;

      return {
        ...state,
        timerUrl: streamloots && streamloots.timerUrl,
        timerConfiguration,
      };
    }
    case actionTypes.UPDATE_TIMER_SUCCESS: {
      return {
        ...state,
        timerConfiguration: payload,
      };
    }
    case PageActionTypes.CHANGED: {
      return {};
    }
    default:
      return state;
  }
};
