import { Role } from '@streamloots/streamloots-types';

export const permissions = {
  ACCOUNT_MANAGER: 'role:account-manager',
  PARTNERSHIP_MANAGER: 'role:partnership-manager',
  AFFILIATE: 'page-type:affiliate',
  ADMIN: 'role:admin',
  ALERT_PROVIDERS_EDIT: 'alert-providers:edit',
  ALERT_PROVIDERS_READ: 'alert-providers:read',
  BAN_FROM_PAGE_EDIT: 'ban:edit',
  BAN_FROM_PAGE_READ: 'ban:read',
  BILLING_EDIT: 'billing:edit',
  BILLING_READ: 'billing:read',
  CHEST_GIFT: 'chests:gift',
  COUPONS_EDIT: 'coupons:edit',
  COUPONS_READ: 'coupons:read',
  CRAFTING: 'set-crafting:edit',
  GALLERY_EDIT: 'gallery:edit',
  GALLERY_READ: 'gallery:read',
  ORDERS_READ: 'orders:read',
  ORDERS_READ_GIFTS: 'orders:read:free-gifts',
  PAGE_EDIT: 'page:edit',
  PARTNER: 'page-type:partner',
  PERMISSIONS_GRANT: 'permissions:grant',
  PLANS_EDIT: 'plans:edit',
  PLANS_READ: 'plans:read',
  RANKINGS_PURCHASES_READ: 'rankings:read:purchases',
  RANKINGS_REDEMPTIONS_READ: 'rankings:read:redemptions',
  RANKINGS_PUBLIC_REDEMPTIONS: 'local-permission:rankings:redemptions',
  RANKINGS_PUBLIC_PURCHASES: 'local-permission:rankings:purchases',
  REDEMPTIONS_EDIT: 'redemptions:edit',
  REDEMPTIONS_READ: 'redemptions:read',
  SET_CARDS_EDIT: 'set-cards:edit',
  SET_CARDS_EDIT_STATUS: 'set-cards:edit:status',
  SET_CARDS_READ: 'set-cards:read',
  SET_LOOTS_EDIT: 'set-loots:edit',
  SET_LOOTS_READ: 'set-loots:read',
  SET_RARITIES_EDIT: 'set-rarities:edit',
  SET_RARITIES_READ: 'set-rarities:read',
  SET_UNPUBLISH: 'sets:unpublish',
  SETS_EDIT: 'sets:edit',
  SETS_PUBLISH: 'sets:publish',
  SETS_READ: 'sets:read',
  STREAMER_SUCCESS_MANAGER: 'role:streamer-success-manager',
  SUPPORT: 'role:support',
  TRANSACTIONS_READ: 'transactions:read',
  USER_AUTHENTICATED: 'user:authenticated',
  PAGE_OWNER: 'page:owner',
  GOALS_CREATE: 'goals:create',
  GOALS_EDIT: 'goals:edit',
  COOLDOWN_EDIT: 'page:edit:redemption-rates',
  MVP_TIMER_WIDGET: 'mvp:timer-widget',
  AUCTION_EDIT: 'auction:edit',
  MARATHON_READ: 'marathon:read',
  MARATHON_EDIT: 'marathon:edit',
};

export const staffPermissions = [
  permissions.ADMIN,
  permissions.ACCOUNT_MANAGER,
  permissions.SUPPORT,
  permissions.PARTNERSHIP_MANAGER,
];

export const staffRoles = [Role.Admin, Role.AccountManager, Role.PartnershipManager, Role.Support];

export const pageSpecialPermissionsPrefixes = ['page-type:', 'experimental-feature:', 'local-permission:'];
