import { Permissions } from 'model/userTS';
import ClassNames from '@streamloots/classnames';
import type { TranslateInterface } from 'utils/translation';
import { urls } from 'services/routing';
import { NewLabel } from 'ui-library/new-indicator';
import type { Link } from '../../links';
import { canRenderMenuItem, SpecialConditionsProps } from '../../menu-permissions-checker';
import { MenuLink } from './MenuLink';
import theme from './menu-content-components.scss';

const classNames = ClassNames(theme);

interface MenuPermissionLinkProps extends TranslateInterface, Partial<SpecialConditionsProps> {
  onClickMapping?: { [mappingKey: string]: () => void };
  link: Link;
  permissions?: Permissions;
}

export const MainMenuPermissionLink = (props: MenuPermissionLinkProps): JSX.Element | null => {
  const { link, onClickMapping = {}, t } = props;
  const { i18nKey, toUrlKey, onClickProperty, icon, relativePath, isNew, dataId } = link;

  if (!canRenderMenuItem(link, props.permissions, props)) {
    return null;
  }

  return (
    <MenuLink
      key={i18nKey}
      to={toUrlKey && urls()[toUrlKey]}
      onClick={!onClickProperty ? undefined : onClickMapping[onClickProperty]}
      exact={!relativePath}
      icon={icon}
      dataId={dataId}
    >
      {!isNew ? t(i18nKey) : <NewLabel label={t(i18nKey)} className={classNames('new')} />}
    </MenuLink>
  );
};
