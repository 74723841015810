import * as React from 'react';
import { withTranslation, TranslateInterface } from 'utils/translation';

interface OwnProps {
  cardsAmount: number;
  quantity: number;
  className?: string;
}

interface CardsYouGonnaGetProps extends OwnProps, TranslateInterface {}

const BaseCardsYouGonnaGet = ({ quantity, cardsAmount, className, t }: CardsYouGonnaGetProps) => {
  return (
    <p className={className}>
      {t('lootPacks:checkout.step1.cardsInfo', {
        count: cardsAmount * quantity,
      })}
    </p>
  );
};

export const CardsYouGonnaGet = withTranslation('lootPacks')(BaseCardsYouGonnaGet) as React.FunctionComponent<OwnProps>;
