import { UserActionTypes } from 'services/user';
import type { RewindState } from './types';
import { RewindActionTypes } from './actionTypes';

const initialState = {
  user: null,
  userRewind: null,
};

export default (state: RewindState = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case UserActionTypes.RECEIVED: {
      if (error) {
        return {
          ...state,
          user: null,
        };
      }
      return {
        ...state,
        user: payload.user,
      };
    }

    case RewindActionTypes.GET_REWIND_SUCCESS: {
      if (error) {
        return {
          ...state,
          userRewind: null,
        };
      }
      return {
        ...state,
        userRewind: payload,
      };
    }

    default: {
      return state;
    }
  }
};
