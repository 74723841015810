import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AuctionPageParams } from 'services/auction';
import { FullLayout } from 'scenes/layout';
import loadable from 'components/dynamic-import';
import AuctionPageSkeleton from './AuctionPageSkeleton';

const ConnectedCardAuction = loadable(() => import('./ConnectedCardAuction'), true, <AuctionPageSkeleton />);

type CardAuctionPageProps = RouteComponentProps<AuctionPageParams>;

const CardAuctionPage = ({ match }: CardAuctionPageProps): JSX.Element => {
  const { auctionId, slug } = match.params;

  return (
    <FullLayout>
      <ConnectedCardAuction auctionId={auctionId} slug={slug} />
    </FullLayout>
  );
};

export default CardAuctionPage;
