import { Alert } from 'ui-library/alert';
import { AuthSection } from 'scenes/auth';
import { TranslateInterface } from 'utils/translation';

export const AnonymousAlert = ({ t }: TranslateInterface): JSX.Element => {
  return (
    <div className={'margin-top margin-bottom align-center maxwidth'}>
      <Alert>{t('common:sectionsRedirection.logToAccessSection')}</Alert>
      <AuthSection componentKey="powersPage" location="powers page" />
    </div>
  );
};
