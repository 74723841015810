import { couponListActions } from './services/list';
import { couponFetchSingleActions } from './services/fetch-single';
import { redeemCouponActions } from './services/redeem';
import { createCouponActions } from './services/create';
import { revokeCouponActions } from './services/revoke';

export const couponsActions = {
  ...couponListActions,
  ...couponFetchSingleActions,
  ...createCouponActions,
  ...redeemCouponActions,
  ...revokeCouponActions,
};
