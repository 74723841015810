import { pageProvider, currentUserProvider } from '../store-state-providers';
import cultureCookieManager from '../cultureCookieManager';

const isEventSenderAdded = (libraryProperty: string): boolean =>
  typeof window !== 'undefined' && typeof window[libraryProperty] !== 'undefined';

export default {
  isOwner: pageProvider.isCurrentUserOwner,
  isEventSenderAdded,
  isUserAuthenticated: (): boolean => !!currentUserProvider.getUsername(),
  isCurrentUserPartner: (): boolean => currentUserProvider.isPartner(),
  getCurrentPage: pageProvider.getPageSlug,
  getCurrentPageData: pageProvider.getPageData,
  getCurrentUserData: currentUserProvider.getUserData,
  getUsername: currentUserProvider.getUsername,
  getUserId: currentUserProvider.getUserId,
  getPageLanguage: cultureCookieManager.getCulture,
  getUserCategory: currentUserProvider.getUserCategory,
};
