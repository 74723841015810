export enum PageFeedActionTypes {
  FETCH_PAGE_FEED_REQUEST = 'pageFeed/FETCH_PAGE_FEED_REQUEST',
  FETCH_PAGE_FEED_ERROR = 'pageFeed/FETCH_PAGE_FEED_ERROR',
  FETCH_PAGE_FEED_SUCCESS = 'pageFeed/FETCH_PAGE_FEED_SUCCESS',
  FETCH_PAGE_FEED_DETAIL_REQUEST = 'pageFeed/FETCH_PAGE_FEED_DETAIL_REQUEST',
  FETCH_PAGE_FEED_DETAIL_ERROR = 'pageFeed/FETCH_PAGE_FEED_DETAIL_ERROR',
  FETCH_PAGE_FEED_DETAIL_SUCCESS = 'pageFeed/FETCH_PAGE_FEED_DETAIL_SUCCESS',
  FETCH_NEXT_PAGE_FEED_SUCCESS = 'pageFeed/FETCH_NEXT_PAGE_FEED_SUCCESS',
  FETCH_NEXT_PAGE_FEED_ERROR = 'pageFeed/FETCH_NEXT_PAGE_FEED_ERROR',
  FETCH_NEXT_PAGE_FEED_REQUEST = 'pageFeed/FETCH_NEXT_PAGE_FEED_REQUEST',
  CREATE_PAGE_FEED_REQUEST = 'pageFeed/CREATE_PAGE_FEED_REQUEST',
  CREATE_PAGE_FEED_ERROR = 'pageFeed/CREATE_PAGE_FEED_ERROR',
  CREATE_PAGE_FEED_SUCCESS = 'pageFeed/CREATE_PAGE_FEED_SUCCESS',
  DELETE_PAGE_FEED_REQUEST = 'pageFeed/DELETE_PAGE_FEED_REQUEST',
  DELETE_PAGE_FEED_ERROR = 'pageFeed/DELETE_PAGE_FEED_ERROR',
  DELETE_PAGE_FEED_SUCCESS = 'pageFeed/DELETE_PAGE_FEED_SUCCESS',
  LIKE_PAGE_FEED_REQUEST = 'pageFeed/LIKE_PAGE_FEED_REQUEST',
  LIKE_PAGE_FEED_SUCCESS = 'pageFeed/LIKE_PAGE_FEED_SUCCESS',
  LIKE_PAGE_FEED_ERROR = 'pageFeed/LIKE_PAGE_FEED_ERROR',
}
