import { matchPath } from 'react-router-dom';
import { isOfType } from 'types/indexTS';
import type { Set, UserSet } from 'model/indexTS';
import { pages } from '../routing';

export interface ByOrderEntity {
  _id: string;
  order?: number;
}

const byOrder = (a: ByOrderEntity, b: ByOrderEntity): number => {
  const lastAvailableOrder = 100;
  const order = (a.order ?? lastAvailableOrder) - (b.order ?? lastAvailableOrder);
  return order !== 0 ? order : a._id.localeCompare(b._id);
};

const setIdFromUrl = (url: string): string => {
  const match = matchPath<{ setId: string }>(url, {
    path: pages.USER_PAGE_COLLECTION,
  });
  return !match || !match.params ? '' : match.params.setId;
};

const getDefaultId = (sets: Array<Set | UserSet>): string => {
  if (sets.length === 0) {
    return '';
  }

  // Default property won't be used anymore, instead, the first published set will be the default one
  const defaultSet = sets.find((set, index) => {
    // It the type is Set we can return the first published, if it is user set we will return the first in the list
    if (isOfType<Set>(set, 'published')) {
      return set.published;
    }

    return index === 0;
  });
  return defaultSet?._id || sets[0]._id;
};

const getSelectedIdOrDefaultId = (
  sets: Array<Set | UserSet>,
  selectedId = '',
  returnDefaultIfNotExist = true,
): string => {
  if (sets.length === 0) {
    return selectedId;
  }

  if (!selectedId) {
    return getDefaultId(sets);
  }

  const selectedSet = sets.find(set => selectedId === set._id);
  if (!selectedSet) {
    return returnDefaultIfNotExist ? getDefaultId(sets) : selectedId;
  }
  return selectedSet ? selectedSet._id : selectedId;
};

export default {
  setIdFromUrl,
  getSelectedIdOrDefaultId,
  getDefaultId,
  byOrder,
};
