import loadable from 'components/dynamic-import';
import { GridSkeleton } from 'components/table';
import { RouteTabsPageProps } from 'components/route-tabs';
import { FullLayout } from 'scenes/layout';

const MyViewersCommunity = loadable(() => import('./MyViewersCommunity'), true, <GridSkeleton />);

export const MyViewersCommunityPage = (props: RouteTabsPageProps): JSX.Element => {
  return (
    <FullLayout>
      <MyViewersCommunity {...props} />
    </FullLayout>
  );
};
