import type { CancelToken } from 'types/indexTS';
import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import type {
  FetchCouponListActionRequest,
  FetchCouponListActionSuccess,
  FetchCouponListActionError,
  CouponsQuery,
  FetchNewBuyerCouponActionSuccess,
  FetchNewBuyerCouponActionError,
  FetchCouponNextReceivedListActionSuccess,
  FetchNextCouponListActionRequest,
} from './types';
import { CouponActionTypes } from '../../actionTypes';
import { CouponsStatusFilter } from './types';

const couponsReceived = ({ additionalData, data, parameters }): FetchCouponListActionSuccess =>
  actionCreator.createAction(
    CouponActionTypes.RECEIVED,
    { data: data.data, pagination: data.pagination, query: parameters },
    additionalData,
  );

const couponsNotReceived = ({ additionalData, errorData }): FetchCouponListActionError =>
  actionCreator.createErrorAction(CouponActionTypes.RECEIVED, errorData, {}, additionalData);

const fetchingCoupons = (
  componentKey: string,
  query: CouponsQuery,
  resetLoadedData: boolean | undefined,
  cancelToken: CancelToken,
): FetchCouponListActionRequest =>
  actionCreator.createAction(
    CouponActionTypes.REQUEST,
    {
      cancelToken,
      query,
      resetLoadedData,
    },
    { componentKey },
  );

const fetchCoupons = (componentKey: string, params: CouponsQuery, resetLoadedData?: boolean) =>
  actionCreator.createAsyncAction({
    additionalData: { componentKey },
    endpoint: endpoints.COUPONS.GET,
    errorBinding: couponsNotReceived,
    loadingAction: (cancelToken: CancelToken) => fetchingCoupons(componentKey, params, resetLoadedData, cancelToken),
    method: 'get',
    parameters: { ...params, priceRuleNewBuyer: false },
    successBinding: couponsReceived,
  });

const nextCouponsReceived = ({
  additionalData,
  data: { data, pagination },
}): FetchCouponNextReceivedListActionSuccess =>
  actionCreator.createAction(CouponActionTypes.NEXT_RECEIVED, { data, pagination }, additionalData);

const nextCouponsNotReceived = ({ additionalData, errorData }): FetchCouponListActionError =>
  actionCreator.createErrorAction(CouponActionTypes.NEXT_RECEIVED, errorData, {}, additionalData);

const fetchingNextCoupons = (componentKey: string, cancelToken: CancelToken): FetchNextCouponListActionRequest =>
  actionCreator.createAction(CouponActionTypes.NEXT_REQUEST, { cancelToken }, { componentKey });

const fetchNextCoupons = (componentKey: string, nextUrl: string) =>
  actionCreator.createAsyncAction({
    additionalData: { componentKey },
    absoluteEndPoint: nextUrl,
    errorBinding: nextCouponsNotReceived,
    loadingAction: (cancelToken: CancelToken) => fetchingNextCoupons(componentKey, cancelToken),
    method: 'get',
    successBinding: nextCouponsReceived,
  });

const newBuyerCouponReceived = ({ additionalData, data }): FetchNewBuyerCouponActionSuccess => {
  const newBuyerCoupon = data.data.length > 0 ? data.data[0] : undefined;
  return actionCreator.createAction(CouponActionTypes.NEW_BUYER_RECEIVED, { coupon: newBuyerCoupon }, additionalData);
};

const newBuyerCouponNotReceived = ({ errorData }): FetchNewBuyerCouponActionError =>
  actionCreator.createErrorAction(CouponActionTypes.NEW_BUYER_RECEIVED, errorData, {});

const fetchNewBuyerCoupon = (pageId: string) => {
  const query: CouponsQuery = {
    limit: 1,
    status: CouponsStatusFilter.ACTIVE,
    pageId,
    priceRuleNewBuyer: true,
  };
  return actionCreator.createAsyncAction({
    endpoint: endpoints.COUPONS.GET,
    errorBinding: newBuyerCouponNotReceived,
    method: 'get',
    parameters: query,
    successBinding: newBuyerCouponReceived,
  });
};
export const couponListActions = {
  fetchCoupons,
  fetchNextCoupons,
  fetchNewBuyerCoupon,
};
