import {
  AuthProviders,
  PageType,
  RankingDivision,
  UsageIntention,
  StrictOmit,
  Role,
  Rarity,
} from '@streamloots/streamloots-types';
import { PaymentProcessorType } from 'model/paymentsTS';
import { Alert } from './alertTS';
import { PageFeedPermissionValues } from './indexTS';
import { Power } from './power';
import { RewindFetchData } from './rewind';

export enum UserRoles {
  ACCOUNT_MANAGER = 'account-manager',
  STREAMER_SUCCESS_MANAGER = 'streamer-success-manager',
  PARTNERSHIP_MANAGER = 'partnership-manager',
  ADMIN = 'admin',
  OWNER = 'owner',
  SUPPORT = 'support',
}
export enum MenuSections {
  Collections = 'collections',
  Configuration = 'configuration',
}

export type MainMenu = { [key in MenuSections]?: boolean };

export enum UserNormalizedCategories {
  Regular = 'A',
  Streamer = 'B',
  Elephant = 'C',
  Ant = 'D',
  Uncategorized = 'E',
}

export const USER_STREAMER_CATEGORIES: Readonly<{
  A: 'Regular';
  B: 'Streamer';
  C: 'Elephant';
  D: 'Ant';
  E: 'Uncategorized';
}> = {
  A: 'Regular',
  B: 'Streamer',
  C: 'Elephant',
  D: 'Ant',
  E: 'Uncategorized',
};

export enum BannedUserCategories {
  HARASSMENT = 'HARASSMENT',
  INAPPROPRIATE_CONTENT = 'INAPPROPRIATE_CONTENT',
  OTHER = 'OTHER',
  SPAM = 'SPAM',
}

export interface UserProfileData {
  avatarImageUrl: string;
  username: string;
}

export interface LastEmailVerification {
  email: string;
  expiredAt: string;
  status: 'PENDING' | 'EXPIRED';
}

export interface UserProfile extends UserProfileData, LastEmailVerification {
  _id?: string;
  primaryEmail?: string;
}

export interface CurrentUserProfile extends UserProfileData {
  lastEmailVerification?: LastEmailVerification;
  primaryEmail?: string;
}

export type Experiment = {
  name: string;
  createdAt: string | number; // Compatibility with the front. We need to check if we can make the front a string
  serverConfirmationAt?: string;
  segment: string;
};

export interface UserSettingsAccount {
  ACCEPTED_PRIVACY_POLICY: boolean;
  ACCEPTED_STREAMER_TERMS_AND_CONDITIONS: boolean;
}

export interface UserNotifications {
  SOLICITED_COMMERCIAL_COMMUNICATIONS: boolean;
}

export interface NetPromoterScore {
  _id: string;
  createdAt: string;
  endAt: string;
  modifiedAt: string;
  segment: string;
  startAt: string;
  userId: string;
  uuid: string;
}

export type UserStatistics = {
  uniquePageRedemptions?: number;
  uniquePagePurchases?: number;
};

export type UserPreferences = {
  editCardFormCollapsableSections?: Record<string, boolean>;
  announcements?: Record<string, boolean | any>;
  cardCreator?: {
    frame: string;
    rarity: Rarity;
  };
  checkout?: {
    lastPaymentSelected: PaymentProcessorType;
  };
  christmas?: {
    lastDate: number;
    currentStreak: number;
  };
  createCollection?: {
    closedAt: string;
  };
  completeTaskTest?: {
    completedTasks?: number;
    seenOnce?: boolean;
    dailyTasksCompleted?: boolean;
    finishedAtTime?: number;
    version?: number;
  };
  hyperwallet?: {
    hideMigrationWarning: boolean;
  };
  goalConfiguration?: {
    lastSelectedMode: string;
  };
  multigoalConfiguration?: {
    lastSelectedMode: string;
  };
  manageCards?: {
    simplifiedView: boolean;
  };
  powersBetaForm?: Record<string, string>;
  predefinedCollectionNotifications?: {
    animalCrossing?: boolean;
    fallguys?: boolean;
    mixitup?: boolean;
  };
  streamersRecommendations?: {
    language: string;
  };
  christmasAlertLastSeenAt?: string;
  feedbackForms?: Record<string, boolean>;
  inlineRecommendations?: Record<string, boolean>;
  division?: RankingDivision | null;
  communityRanking?: {
    hasCompletedRewardFormForSeason?: number;
  };
  showOldActivityFeed?: boolean;
  nextTimeToShowCampaignBanner?: string;
  claimedHalloween100Revenue?: boolean;
  suggestionsDashboard?: Record<string, boolean>;
  events?: {
    retarget: boolean;
  };
  mainMenu?: MainMenu;
  claimedSanValentin2022100Revenue?: boolean;
  newBuyerCoupon?: {
    expiresAt?: string;
    timesClosed?: number;
  };
  newBuyerStreamerCoupon?: Record<string, string | undefined>;
  newPackSellersPromotion?: {
    // We shouldn't run two promotions like this at the same time so if there is a new promotion, the properties here should be overwritten
    promotionId: string;
    claimed?: boolean;
  };
  nextModulesScoring?: Record<string, number>;
  feedPermision?: PageFeedPermissionValues | null;
  couponAlertConfiguration?: Alert;
};

export type UserFlags = {
  showAlertEffects?: boolean;
  showOnboarding: boolean;
  showSupportChat?: boolean;
  showUSOldPartnershipRequirements?: false;
};

export type BasicUserData = {
  _id: string;
  profile: {
    avatarImageUrl: string;
    username: string;
  };
};

export type Permissions = Record<string, boolean>;

export interface BannedUser {
  _id: string;
  active: boolean;
  bannedByUser: BasicUserData;
  bannedUserId: string;
  category: BannedUserCategories;
  createdAt: string;
  reason: string;
  unbannedAt: string;
  unbannedByUser: BasicUserData;
  user: BasicUserData;
  userId: string;
}

export interface CurrentUserPage {
  _id: string;
  createdAt: string;
  published: boolean;
  level: number;
  type: PageType;
  slug: string;
  usageData: { cardRedemptionCount: number; lootsSoldCount: number };
}

export interface USER_API_RESPONSE {
  _id: string;
  authProviders: AuthProviders[]; // It includes streamlabs but it will be removed soon
  category: keyof typeof USER_STREAMER_CATEGORIES;
  country?: string;
  createdAt: string;
  experiments: Experiment[];
  flags: UserFlags;
  page?: CurrentUserPage;
  paymentMethods: Record<string, unknown>[];
  primaryAuthProvider: AuthProviders;
  profile: CurrentUserProfile;
  promotions: {
    rewind2020?: RewindFetchData;
    rewind2021?: RewindFetchData;
    rewind2022?: RewindFetchData;
    rewind2023?: RewindFetchData;
  };
  refreshYoutubeAuthProvider?: boolean;
  roles: Role[];
  settings?: UserSettings;
  statistics: UserStatistics;
  twitterAcount?: string;
  discordServer?: string;
  usageIntention?: UsageIntention | null;
  netPromoterScore?: NetPromoterScore;
}

export interface UserSettings {
  notifications: UserNotifications;
  account: UserSettingsAccount;
  preferences?: UserPreferences;
  defaultAlertEffect?: Power;
}

export interface User extends StrictOmit<USER_API_RESPONSE, 'flags' | 'experiments' | 'settings'> {
  isStreamer: boolean;
  showSupportChat: boolean;
  settings: UserSettings;
}
