import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import type { ExtendedSetRadioButtonCardsProps } from './types';
import { SetRadioCardItem } from './SetRadioCardItem';
import theme from './set-radio-button-cards.scss';

const classNames = ClassNames(theme);

export const DesktopSetRadio = ({ items, activeId, t, onChange }: ExtendedSetRadioButtonCardsProps) => {
  return (
    <div
      className={classNames({
        'set-radio-button-cards__desktop__container': true,
        'set-radio-button-cards__desktop__container--one': items.length === 1,
      })}
    >
      {items.map((item, index) => (
        <div className={classNames('set-radio-button-cards__desktop__item')} key={index}>
          <SetRadioCardItem
            id={`setCarrouselOption_${index}`}
            name="setCarrouselOption"
            checked={activeId === item._id}
            item={item}
            onChange={onChange}
            t={t}
          />
        </div>
      ))}
    </div>
  );
};
