import { connect } from 'react-redux';
import type { VerifyEmail } from 'services/user';
import { userActions } from 'services/user';
import { ValidateEmail } from './ValidateEmail';

interface OwnProps {
  match: {
    params: Record<string, string>;
  };
}

interface MapDispatchToProps {
  verifyEmail: VerifyEmail;
}

interface ConnectedValidateEmailProps extends MapDispatchToProps, OwnProps {}

const ConnectedValidateEmail = ({ match, verifyEmail }: ConnectedValidateEmailProps) => {
  return <ValidateEmail match={match} verifyEmail={verifyEmail} />;
};

export default connect<null, MapDispatchToProps, OwnProps>(null, {
  verifyEmail: userActions.verifyEmail,
})(ConnectedValidateEmail);
