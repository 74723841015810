import { useState, useEffect, FunctionComponent } from 'react';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ClassNames from '@streamloots/classnames';
import trackEventManager from 'utils/event-manager';
import { withTranslation, Trans, TranslateInterface } from 'utils/translation';
import type { UpdateUserPreferences, UpdateUserSettings } from 'services/user/types';
import { User, UserPreferences } from 'model/userTS';
import { userSelectors, userActions } from 'services/user';
import { cultureSelectors, produceLegalURLs } from 'services/culture';
import { pages } from 'services/routing/urls';
import brand from 'constants/brand';
import Dialog, { DialogContent } from 'ui-library/dialog';
import Button from 'ui-library/button';
import { Checkbox } from 'ui-library/form-elements';
import { useAsyncConfirmation } from 'components/confirmation-dialog';
import { asyncFormValidationResolver } from 'components/forms';
import FormCountry from './FormCountry';
import theme from './tc-modal.scss';

const classNames = ClassNames(theme);

interface OwnProps {
  routerPath: string;
}

interface MapStateToProps {
  user: User | null;
  hasPage: boolean;
  language: string;
  userCountry: string;
  isNewUser?: boolean;
  userPreferences: UserPreferences;
}

interface MapDispatchToProps {
  updateUserSettings: UpdateUserSettings;
  updateUserPreferences: UpdateUserPreferences;
}

interface Props extends TranslateInterface, MapDispatchToProps, MapStateToProps, OwnProps {}

const routesAllowed = [
  pages.PRIVACY_POLICY_EN,
  pages.PRIVACY_POLICY_ES,
  pages.PRIVACY_POLICY_USA,
  pages.TERMS_AND_CONDITIONS,
  pages.COOKIES_POLICY_ES,
  pages.COOKIES_POLICY,
  pages.COOKIES_POLICY_USA,
];

// TODO Test using API branch once all changes needed are in one branch.
function TCModal(props: Props): JSX.Element {
  const {
    user,
    hasPage,
    updateUserSettings,
    language,
    userCountry,
    routerPath,
    isNewUser,
    userPreferences,
    updateUserPreferences,
    t,
  } = props;

  const [hasAcceptedGeneralTerms, setHasAcceptedGeneralTerms] = useState(false);

  const { finalizeRequest, initRequest, isLoading } = useAsyncConfirmation();

  const isInTermsOrPolicyPage = routesAllowed.some(url => url === routerPath);

  const account = user?.settings?.account ?? {
    ACCEPTED_PRIVACY_POLICY: undefined,
    ACCEPTED_STREAMER_TERMS_AND_CONDITIONS: false,
  };
  const { ACCEPTED_PRIVACY_POLICY, ACCEPTED_STREAMER_TERMS_AND_CONDITIONS } = account;

  const userMustSign = ACCEPTED_PRIVACY_POLICY === false || (hasPage && !ACCEPTED_STREAMER_TERMS_AND_CONDITIONS);

  const isActive = !isNewUser && userMustSign && !isInTermsOrPolicyPage;

  const isAcceptButtonEnabled = (hasAcceptedGeneralTerms && !hasPage) || hasAcceptedGeneralTerms;

  const isStreamerCheckboxRendered = hasPage;

  useEffect(() => {
    if (isActive) {
      trackEventManager.trackEvent('newUserTermsViewed', {
        generals: true,
        ...(isStreamerCheckboxRendered && { streamers: true }),
      });
    }
  }, [isActive, isStreamerCheckboxRendered]);

  const { termsURL, privacyURL } = produceLegalURLs({
    language,
  });

  function handleClose() {
    initRequest();

    updateUserSettings({
      settings: {
        notifications: user?.settings?.notifications || {
          SOLICITED_COMMERCIAL_COMMUNICATIONS: false,
        },
        account: {
          ACCEPTED_PRIVACY_POLICY: hasAcceptedGeneralTerms,
          ACCEPTED_STREAMER_TERMS_AND_CONDITIONS: true,
        },
      },
      primaryAuthProvider: user?.primaryAuthProvider,
      country: userCountry,
    }).then(response => {
      if (!response.error) {
        updateUserPreferences(userPreferences);
      }

      return finalizeRequest(response, {
        successMessage: t('onboarding:termsAndConditionsModal.updated'),
      });
    });
  }

  // this method is for old users
  const handleCountryForm = formData => {
    const { acceptTerms, country } = formData;

    return updateUserSettings({
      settings: {
        notifications: user?.settings?.notifications || {
          SOLICITED_COMMERCIAL_COMMUNICATIONS: false,
        },
        account: {
          ACCEPTED_PRIVACY_POLICY: acceptTerms,
          ACCEPTED_STREAMER_TERMS_AND_CONDITIONS: true,
        },
      },
      primaryAuthProvider: user?.primaryAuthProvider,
      country,
    }).then(response => {
      if (!response.error) {
        updateUserPreferences(userPreferences);
      }
      return asyncFormValidationResolver(response);
    });
  };

  return (
    <Dialog
      active={isActive}
      renderDialogBody={() => {
        return (
          <DialogContent
            headerClassName={classNames('tc-modal__header')}
            contentClassName={classNames('tc-modal__content')}
          >
            <img src={brand.symbolWhite} alt="Streamloots" className={classNames('tc-modal__header-logo')} />
            <h2 className={classNames('tc-modal__title')}>{t('onboarding:newTermsModalHeader')}</h2>
            <div className={classNames('tc-modal__container')}>
              <p className={classNames('tc-modal__text')}>
                <Trans
                  i18nKey="onboarding:newTermsModalText"
                  parent="span" // Is this how we use trans with css classes?
                  html-for="accept-streamer-terms"
                >
                  To make sure you have the best experience on Streamloots we have{' '}
                  <Link className="font-bold" to={termsURL} target="_blank">
                    updated the terms and conditions
                  </Link>{' '}
                  of our services , you need to check the box and accept the new terms in order to continue using the
                  platform.
                </Trans>
              </p>
              {!userCountry && (
                <div className={classNames('tc-modal__form')}>
                  <FormCountry onSubmit={handleCountryForm} isStreamerCheckboxRendered={isStreamerCheckboxRendered} />
                </div>
              )}
              {userCountry && (
                <div className={classNames('tc-modal__checkboxes')}>
                  <div>
                    <Checkbox
                      id="accept-terms"
                      name="accept-terms"
                      labelClassName={classNames('tc-modal__checkbox')}
                      labelDescriptionClass={classNames('tc-modal__checkbox-label')}
                      checked={hasAcceptedGeneralTerms}
                      onChange={() => setHasAcceptedGeneralTerms(state => !state)}
                      label={
                        <Trans i18nKey="onboarding:acceptGeneralTerms">
                          Accept{' '}
                          <Link to={termsURL} target="_blank">
                            Terms and Conditions
                          </Link>{' '}
                          and{' '}
                          <Link to={privacyURL} target="_blank">
                            Privacy Policy
                          </Link>
                        </Trans>
                      }
                    />
                  </div>
                </div>
              )}
            </div>
            {userCountry && (
              <Button
                loading={isLoading}
                label={t('common:accept')}
                onClick={handleClose}
                primary
                disabled={!isAcceptButtonEnabled}
              />
            )}
          </DialogContent>
        );
      }}
    />
  );
}

const mapStateToProps = (state): MapStateToProps => {
  return {
    user: userSelectors.user(state),
    hasPage: !userSelectors.showOnboarding(state),
    language: cultureSelectors.language(state),
    userCountry: userSelectors.currentUserCountryCode(state),
    userPreferences: userSelectors.userPreferences(state),
    isNewUser: userSelectors.mustAcceptTerms(state),
  };
};

export default compose<FunctionComponent<OwnProps>>(
  withTranslation('onboarding', 'common', 'userProfile'),
  connect(mapStateToProps, {
    updateUserSettings: userActions.updateUserSettings,
    updateUserPreferences: userActions.updateUserPreferences,
  }),
)(TCModal);
