import ClassNames from '@streamloots/classnames';
import { useTranslation } from 'utils/translation';
import { OptionalTooltip, TooltipPlacement } from 'ui-library/tooltip';
import { useAuthDialog } from 'scenes/auth';
import theme from './banner-left-menu.scss';
import { TICKETS_CAMPAIGN_RESOURCES } from '../constants';

const classNames = ClassNames(theme);

interface BannerLeftMenuFiestaContentProps {
  showTooltip: boolean;
  showAuthDialogOnClick?: boolean;
  contributionAsStreamer: number;
  contributionAsViewer: number;
  totalContribution: number;
}

export const BannerLeftMenuFiestaContent = ({
  showTooltip,
  contributionAsStreamer,
  totalContribution,
  contributionAsViewer,
  showAuthDialogOnClick,
}: BannerLeftMenuFiestaContentProps): JSX.Element | null => {
  const { t } = useTranslation('dashboard');
  const { showAuthDialog } = useAuthDialog({ location: 'app left menu', componentKey: 'left menu fiesta banner' });

  return (
    <div
      className={classNames({
        'banner-left-menu-wrapper': true,
      })}
    >
      <div
        className={classNames({
          'banner-left-menu': true,
          'banner-left-menu--fiesta': true,
        })}
        role={showAuthDialogOnClick ? 'button' : undefined}
        onClick={showAuthDialogOnClick ? showAuthDialog : undefined}
      >
        <h4 className={classNames('banner-left-menu__title')}>
          <img height="30" src={TICKETS_CAMPAIGN_RESOURCES.MENU_LOGO} alt="Screamy's revenge" />
        </h4>
        <div className={classNames('banner-left-menu__logo-container')} />
        <OptionalTooltip
          placement={TooltipPlacement.TOP}
          tooltip={
            showTooltip && (
              <>
                <p>
                  {t('campaings:ticketsCampaign.asStreamer')}: {contributionAsStreamer}
                </p>
                <p className={classNames('no-margin-imp')}>
                  {t('campaings:ticketsCampaign.asViewer')}: {contributionAsViewer}
                </p>
              </>
            )
          }
        >
          <span className={classNames('banner-left-info')}>
            <p className={classNames('banner-left-info__campaign')}>
              {t('campaings:ticketsCampaign.banner', { count: totalContribution || 0 })}
            </p>
          </span>
        </OptionalTooltip>
      </div>
      <a
        className={classNames('font-small')}
        href={t('campaings:ticketsCampaign.link')}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('campaings:ticketsCampaign.moreInfo')}
      </a>
    </div>
  );
};
