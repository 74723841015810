import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import type { PaymentProcessorType } from 'model/indexTS';
import type { PaymentOrderReceivedActionSuccess, PaymentOrderReceivedActionError } from './types';
import { FetchPaymentOrderActionTypes } from './actionTypes';

const paymentOrderReceived = ({ data }): PaymentOrderReceivedActionSuccess =>
  actionCreator.createAction(FetchPaymentOrderActionTypes.ORDER_RECEIVED, data);

const paymentOrderNotReceived = ({ errorData }): PaymentOrderReceivedActionError =>
  actionCreator.createErrorAction(FetchPaymentOrderActionTypes.ORDER_RECEIVED, errorData);

const fetchPaymentOrderInfo = (paymentId: string, paymentProcessor: PaymentProcessorType) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.PAYMENT_ORDER.GET,
    errorBinding: paymentOrderNotReceived,
    loadingAction: FetchPaymentOrderActionTypes.FETCHING_ORDER,
    urlParameters: { paymentId, paymentProcessor },
    method: 'get',
    successBinding: paymentOrderReceived,
  });

export const fetchPaymentOrderActions = {
  fetchPaymentOrderInfo,
};
