import { SubmissionError } from 'redux-form';
import type { ActionError, ActionSuccess } from 'types/indexTS';
import { BulkActionsResponseFormatterResponse } from 'services/bulk-actions';
import { isOfType } from 'types/indexTS';

type AcceptedResponse =
  | Pick<ActionError<string>, 'error' | 'payload'>
  | Pick<ActionSuccess<string, any>, 'error' | 'payload'>
  | Pick<ActionSuccess<string, BulkActionsResponseFormatterResponse>, 'error' | 'payload'>;

type AsyncFormResponse = AcceptedResponse | AcceptedResponse[];

interface ErrorReturned {
  payload: {
    message: string;
  };
}

const getError = (response: AsyncFormResponse): ErrorReturned | null => {
  if (!Array.isArray(response)) {
    if (response.error) {
      return response;
    }
    const { payload } = response;

    if (!isOfType<BulkActionsResponseFormatterResponse>(payload, 'errors')) {
      return null;
    }

    const { errors } = payload;
    if (!errors || !Array.isArray(payload.errors) || errors.length === 0) {
      return null;
    }
    const erroredItem = errors[0];
    return erroredItem && erroredItem.error ? { payload: erroredItem.error } : null;
  }

  const error = response.filter(res => res.error);

  if (error.length > 0) {
    return error[0] as ActionError<string>;
  }

  return null;
};

export const asyncFormValidationResolver = <Response extends AcceptedResponse>(
  response: Response,
  successCallback?: (response: Response) => void,
) => {
  const erroredAction = getError(response);
  if (erroredAction) {
    const {
      payload: { message },
    } = erroredAction;
    throw new SubmissionError({ _error: message });
  } else if (typeof successCallback === 'function') {
    successCallback(response);
  }
};
