import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { withTranslation, TranslateInterface } from 'utils/translation';
import Panel from 'ui-library/panel';
import { ButtonIcon } from 'ui-library/button';
import theme from './dialog.scss';

const classNames = ClassNames(theme);

interface OwnProps {
  className?: string;
  headerClassName?: string;
  contentClassName?: string;
  children: React.ReactNode;
  onClose?: () => void;
  preventClose?: boolean;
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  colorfulHeader?: boolean;
  renderFooter?: () => React.ReactNode;
}

interface DialogContentProps extends TranslateInterface, OwnProps {}

const DialogContentBase = ({
  className = '',
  headerClassName = '',
  contentClassName = '',
  children,
  description,
  title = '',
  colorfulHeader,
  renderFooter,
  onClose,
  preventClose,
  t,
}: DialogContentProps) => {
  const dialogPanelClassNames = classNames({
    dialog__panel: true,
    [className]: className,
  });

  return (
    <Panel
      className={dialogPanelClassNames}
      contentClassName={contentClassName}
      description={description}
      title={title}
      renderFooter={renderFooter}
      headerClassName={headerClassName}
      colorful={colorfulHeader}
    >
      {onClose && !preventClose && (
        <ButtonIcon
          className={classNames({
            dialog__close: true,
          })}
          onClick={onClose}
          icon="close"
          simple
          ariaLabel={t('common:close')}
          data-testid="dialog-close"
        />
      )}
      {children}
    </Panel>
  );
};

export const DialogContent = withTranslation('common')(DialogContentBase) as React.FunctionComponent<OwnProps>;
