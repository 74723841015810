import type {
  PAGE_GET_RESPONSE_ANONYMOUS_SUCCESS,
  Currency,
  StrictOmit,
  PageModuleResponse,
  PublicPageOwnerInfo as PublicPageOwnerInfoType,
  PAGE_GET_RESPONSE_STAFF_SUCCESS,
} from '@streamloots/streamloots-types';
import { PagesModules } from '@streamloots/streamloots-types';
import { PartnershipProgress, PartnershipRequirements } from './partnership-applicationTS';
import { BannedUserCategories, BasicUserData } from './userTS';
import { AchievementBadgeApiResponse, AchievementBadgeInfo } from './achievement-badges';

//FIX ME - 👇🏻 Move me to types library after merging to develop
export const NewPagesModules = {
  ...PagesModules,
  Donations: 'donations', // FIXME: Move to types lib
  Feeds: 'feeds',
} as const;
//FIX ME - 👆🏻 Move me to types library after merging to develop

export type PageModulesValues = typeof NewPagesModules[keyof typeof NewPagesModules];

export enum PAYOUT_METHOD {
  BANK_TRANSFER = 'bankTransfer',
  PAYPAL = 'paypal',
  HYPERWALLET = 'hyperwallet',
}

export enum PayoutFrequency {
  ONE_FIRST = 'ONE_1ST',
  ONE_MIDDLE = 'ONE_15TH',
  TWICE = 'TWICE',
}

export enum PayDays {
  First = '01',
  Middle = '15',
}

export enum PaymentProcessors {
  Paypal = 'paypal',
  Stripe = 'stripe',
  Paysafecard = 'paysafecard',
}

export enum PagePayoutMethod {
  BANK_TRANSFER = 'bankTransfer',
  PAYPAL = 'paypal',
  HYPERWALLET = 'hyperwallet',
}

export type BannedFromPageInfo = {
  category: BannedUserCategories;
};

export interface PageInternalManagement {
  accountManagerId?: string;
  accountManager?: BasicUserData | null;
  closerId?: string;
  closer?: BasicUserData | null;
  community?: string;
}

export interface BillingInformation {
  timezone?: string;
  country: string;
  address1?: string;
  address2?: string;
  fullName?: string;
  taxId?: string;
  state?: string;
  city?: string;
  zipCode?: string;
}

export interface PaymentMethodPaypal {
  email: string;
  provider: PagePayoutMethod.PAYPAL;
}

export type PaymentMethod = PaymentMethodPaypal;
export type PaymentMethods = PaymentMethod[];

// Probably there are more missing fields due to the normalizer.page
// function in the services folder
export type Page = Omit<PAGE_GET_RESPONSE_STAFF_SUCCESS, 'primaryCurrency'> & {
  currency: Currency;
  currentUser: {
    packsBought: number;
  };
};

export interface PublicPageOwnerInfo extends PublicPageOwnerInfoType {
  discordServer?: string;
}

// Endpoint to search streamer
export type PublicPageSearchResult = Pick<
  PAGE_GET_RESPONSE_ANONYMOUS_SUCCESS,
  | 'badges'
  | 'createdAt'
  | 'description'
  | 'isLive'
  | 'language'
  | 'level'
  | 'modifiedAt'
  | 'modules'
  | 'owner'
  | 'ownerId'
  | 'paymentProcessors'
  | 'permissions'
  | 'primaryCurrency'
  | 'published'
  | 'publishedAt'
  | 'rankingVisibility'
  | 'redemptionLimits'
  | 'sellerId'
  | 'slug'
  | 'tagIds'
  | 'timezone'
  | 'tags'
  | 'title'
  | 'type'
  | '_id'
> & {
  statistics?: {
    total: number;
  };
  ambassador: boolean;
  achievementBadges?: AchievementBadgeInfo[];
};

export interface PublicPageSearchResultApiResponse extends Omit<PublicPageSearchResult, 'achievementBadges'> {
  achievementBadges: AchievementBadgeApiResponse[]; // TODO BADGES
}

export type PageBasicInfo = Pick<Page, '_id' | 'slug'>;

export interface PageUsageStatistics {
  badgeSystemCount?: number;
  cardRedemptionCount: number;
  lootsSoldCount?: number;
}

export interface PageCustomization {
  dustImageUrl?: string;
  headerImageUrl?: string;
  language: string;
  aboutMe?: string;
}

export interface PageRankingVisibility {
  purchasesRanking: boolean;
  redemptionsRanking: boolean;
}

export interface PageMetadata {
  title: string;
  description: string;
}

export type PageModuleInfo = PageModuleResponse;

export type PageModules = {
  [key in PageModulesValues]: PageModuleResponse;
};

export interface PageFlags {
  badgesSystemEnabled?: boolean;
  badgesSystemEnabledAt?: string;
  botUsageEnabled?: boolean;
  hyperwalletActivationRequired?: boolean;
  hyperwalletActivationRequiredLastUpdatedAt?: string;
  hyperwalletVerificationRequired?: boolean;
  hyperwalletVerificationRequiredLastUpdatedAt?: string;
  plan100PromotionEnabled?: boolean;
  showHyperwalletWarning?: boolean;
  buyMePacksAlertEnabled?: boolean;
  chatbotEnabled?: boolean;
  fragmentedCardsEnabled?: boolean;
  fragmentedCardsWithoutOriginalsEnabled?: boolean;
  genericChatbotMessageEnabled?: boolean;
  goalsEnabled?: boolean;
  legendaryCardObtainedAlertEnabled?: boolean;
  legendaryCardObtainedChatbotMessageEnabled?: boolean;
  limitedCardsEnabled?: boolean;
  limitedCardsWithoutOriginalsEnabled?: boolean;
  paidCardsEnabled?: boolean;
  paidCardsWithoutOriginalsEnabled?: boolean;
  rankingChatbotMessageEnabled?: boolean;
}
export interface ModulesPlans {
  [NewPagesModules.Cards]: string;
  [NewPagesModules.Auctions]: string;
  [NewPagesModules.Subscriptions]: string;
  [NewPagesModules.Donations]: string;
  marathonsInteractions: string;
}

// Page response after normalization, it is received by the different reducers that use page received/updated actions
export interface NormalizedPageData
  extends StrictOmit<
    PAGE_GET_RESPONSE_STAFF_SUCCESS,
    | 'activePlan'
    | 'primaryCurrency'
    | 'redemptionLimits'
    | 'redemptionRateLimits'
    | 'headerImageUrl'
    | 'dustImageUrl'
    | 'closer'
    | 'closerId'
    | 'accountManagerId'
    | 'accountManager'
    | 'community'
    | 'description'
    | 'usageData'
    | 'title'
    | 'constraints'
    | 'language'
    | 'owner'
    | 'partnershipProgress'
    | 'partnershipRequirements'
  > {
  currency: Currency;
  internalManagement: PageInternalManagement;
  newBuyerCouponCode?: string;
  customization: PageCustomization;
  metadata: PageMetadata;
  usageStatistics: PageUsageStatistics;
  filesTotalMaxBytesAllowed?: number;
  modules: PageModules;
  modulesPlans?: ModulesPlans;
  ambassador?: boolean;
  owner: PublicPageOwnerInfo;
  backFriday2022Joined?: boolean;
  achievementBadges?: AchievementBadgeInfo[];
  partnershipProgress?: PartnershipProgress;
  partnershipRequirements?: PartnershipRequirements;
}

// Page response after normalization, it is received by the different reducers that use page received/updated actions
export interface CurrentPage
  extends StrictOmit<
    PAGE_GET_RESPONSE_STAFF_SUCCESS,
    | 'activePlan'
    | 'primaryCurrency'
    | 'redemptionLimits'
    | 'redemptionRateLimits'
    | 'headerImageUrl'
    | 'dustImageUrl'
    | 'closer'
    | 'closerId'
    | 'accountManagerId'
    | 'accountManager'
    | 'community'
    | 'description'
    | 'usageData'
    | 'title'
    | 'constraints'
  > {
  activePlan: string;
  currency: Currency;
  internalManagement: PageInternalManagement;
  customization: PageCustomization;
  metadata: PageMetadata;
  usageStatistics: PageUsageStatistics;
  filesTotalMaxBytesAllowed?: number;
}
