import type { PaginationStateNormalizerConfiguration } from 'services/pagination/typesTS';
import type { OrdersQuery } from './types';

export const DEFAULT_LIMIT = 30;
export const DEFAULT_QUERY: OrdersQuery = {
  limit: DEFAULT_LIMIT,
  types: '',
};

export const PAGINATION_STATE_NORMALIZER_CONFIGURATION: PaginationStateNormalizerConfiguration<OrdersQuery> = {
  timeProperty: 'completedAt',
  initialQuery: DEFAULT_QUERY,
};
