import { useDispatch, useSelector } from 'react-redux';
import { InterfaceWithReturnType } from 'types/indexTS';
import {
  OverloadedContentParams,
  OverloadedContentWrapperProps,
  CloseOverloadedContent,
  OpenOverloadedContent,
  UpdateOverloadedContentWrapperProps,
} from './typesTS';
import { overloadedContentActions } from './actions';
import { overloadedContentSelectors } from './selectors';

export interface UseOpenOverloadedActions {
  closeOverloadedContent: CloseOverloadedContent;
  openOverloadedContent: OpenOverloadedContent;
  updateWrapperProps: UpdateOverloadedContentWrapperProps;
}
export type UseOpenOverloadedSelectors = InterfaceWithReturnType<typeof overloadedContentSelectors>;

export const useOverloadedContentSelectors = (): UseOpenOverloadedSelectors => {
  const dialogProps = useSelector(overloadedContentSelectors.dialogProps);

  return {
    dialogProps,
  };
};

export const useOverloadedContentActions = (): UseOpenOverloadedActions => {
  const dispatch = useDispatch();
  return {
    closeOverloadedContent: () => dispatch(overloadedContentActions.closeOverloadedContent()),
    openOverloadedContent: <Component>(content: OverloadedContentParams<Component>) =>
      dispatch(overloadedContentActions.openOverloadedContent(content)),
    updateWrapperProps: (wrapperProps: OverloadedContentWrapperProps) =>
      dispatch(overloadedContentActions.updateWrapperProps(wrapperProps)),
  };
};
