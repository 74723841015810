import type { Order } from 'model/indexTS';
import type { ORDERS_GET_RESPONSE, ORDERS_GET_RESPONSE_ITEM } from 'model/orderTS';
import { currencyManager } from 'services/culture';
import { StrictOmit } from '@streamloots/streamloots-types';
import type { OrderListResponse, OrdersQuery } from './services/list';

const mapOrderDataToOrder = (order: ORDERS_GET_RESPONSE_ITEM): Order => {
  const { _id, amount, currency, completedAt, customer, giftee, type, items } = order;

  const quantity = items.reduce((accumulator, itemRow) => {
    const { item } = itemRow;
    return accumulator + itemRow.quantity * item.quantity;
  }, 0);

  const itemWithSetId = items.find(itemRow => {
    // At the moment all items belongs to the same setId
    const { item } = itemRow;
    return item.setId;
  });

  return {
    _id,
    type,
    receiver: giftee || customer,
    quantity,
    totalPayment: currencyManager.getFormattedPrice(amount, currency),
    completedAt,
    customer,
    paidOrder: amount > 0,
    setId: itemWithSetId?.item.setId || '',
  };
};

const normalizedResponse = ({ data, pagination }: ORDERS_GET_RESPONSE): StrictOmit<OrderListResponse, 'query'> => ({
  data: data.map(mapOrderDataToOrder),
  pagination,
});

const list = (data: ORDERS_GET_RESPONSE, query: OrdersQuery): OrderListResponse => ({
  ...normalizedResponse(data),
  query,
});

export default {
  order: mapOrderDataToOrder,
  list,
  nextList: normalizedResponse,
};
