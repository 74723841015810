import React from 'react';
import loadable from 'components/dynamic-import';
import { FullLayout } from 'scenes/layout';

const ConnectedCreateNotification = loadable(() => import('./ConnectedCreateNotification'));

export const AdminNotificationsPage = (): JSX.Element => (
  <FullLayout>
    <ConnectedCreateNotification />
  </FullLayout>
);
