import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { FetchNextPages, FetchPages, PagesStateByComponentKey, DestroyPagesState } from 'services/pages/typesTS';
import { pagesSelectors } from './selectors';
import { pagesActions } from './actions';

export const usePagesSelectorByComponentKey = (componentKey: string): PagesStateByComponentKey => {
  return useSelector(state => pagesSelectors.pagesStatus(state, componentKey));
};

export interface UsePagesActions {
  fetchPages: FetchPages;
  fetchNextPages: FetchNextPages;
  destroyPagesState: DestroyPagesState;
}

export const usePagesActions = (): UsePagesActions => {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      fetchPages: (componentKey, query, resetData) => dispatch(pagesActions.fetchPages(componentKey, query, resetData)),
      fetchNextPages: (componentKey, nextUrl) => dispatch(pagesActions.fetchNextPages(componentKey, nextUrl)),
      destroyPagesState: componentKey => dispatch(pagesActions.destroyPages(componentKey)),
    }),
    [dispatch],
  );
};
