export interface RenderDialogBodyParams {
  onClose?: () => void;
  preventClose?: boolean;
}

export interface RenderOpenerDialogBodyParams {
  onClose: () => void;
  preventClose?: boolean;
}

export type RenderOpenerDialogParams = {
  onShow: () => void;
};

export const DIALOG_RESULT_TYPES = {
  INFO: 'info',
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
} as const;

export enum DialogTypes {
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large',
  XLARGE = 'xlarge',
  FULL_SCREEN = 'fullscreen',
  AUTH = 'auth',
}
