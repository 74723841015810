import React from 'react';
import { withTranslation, TranslateInterface } from 'utils/translation';
import ClassNames from '@streamloots/classnames';
import { FloatingNotification } from 'services/notifications/typesTS';
import styles from './floating-notifications.scss';

const classNames = ClassNames(styles);

interface Props extends TranslateInterface {
  notification: FloatingNotification;
  language: string;
  onClose: () => void;
}

function AchievementNotification(props: Props): JSX.Element {
  const { notification, language, t } = props;

  const { imageUrl, locales, rewards = [] } = notification;

  const rewardsString = rewards.reduce((acc, curr) => {
    return acc === '' ? `${curr.value.locales.title[language]}` : `${acc}, ${curr.value.locales.title[language]}`;
  }, '');

  return (
    <React.Fragment>
      <img className={classNames('image')} src={imageUrl} alt={locales[language]} />
      <div className={classNames('info')}>
        <p className={classNames('achieved')}>{t('achieved')}</p>
        <h4 className={classNames('name')}>{locales[language]}</h4>
        {rewards.length > 0 && (
          <p className={classNames('reward')}>
            {t('reward')}: {`${rewardsString} `}
          </p>
        )}
      </div>
    </React.Fragment>
  );
}

export default withTranslation('achievements')(AchievementNotification) as any;
