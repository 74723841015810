import { PageActionTypes } from 'services/page';
import { PagePlanActionTypes } from 'services/admin-page-plans';
import { AdminPageTypeActionTypes } from 'services/admin-page-type';
import { AdminPageInternalActionTypes } from 'services/admin-page-internal';
import { RedemptionActionTypes } from 'services/redemption';
import type { ReconcileState } from '../../types';

const initialState: ReconcileState = {};

export const reducer = (state: ReconcileState = initialState, action): ReconcileState => {
  const { type, error } = action;

  switch (type) {
    case RedemptionActionTypes.REDEEMED: {
      const { metadata } = action;
      if (error && metadata.businessCode === 'REDEMPTION_RATE_LIMIT_REACHED') {
        return { reconcile: true };
      }
      return state;
    }
    case AdminPageTypeActionTypes.PAGE_TYPE_UPDATE_SUCCESS:
    case AdminPageInternalActionTypes.UPDATED_SUCCESS:
    case PagePlanActionTypes.ADDED:
    case PageActionTypes.APPLICATION_ACKNOWLEDGED:
    case PageActionTypes.APPLIED_FOR_NEXT_TYPE:
    case PagePlanActionTypes.UPDATED:
    case PagePlanActionTypes.DELETED:
      if (error) {
        return state;
      }
      return { reconcile: true };
    case PageActionTypes.REQUEST:
    case PageActionTypes.RECEIVED:
      if (error) {
        return state;
      }
      return { reconcile: false };
    default:
      return state;
  }
};
