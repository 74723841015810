import ClassNames from '@streamloots/classnames';
import { Link } from 'react-router-dom';
import { pages } from 'services/routing';
import { Imagotypo } from 'components/logo';
import theme from './simplified-layout.scss';

const classNames = ClassNames(theme);

export const TopBar = (): JSX.Element => (
  <div className={classNames({ 'top-bar': true })}>
    <Link to={pages.USER_COLLECTIONS}>
      <Imagotypo />
    </Link>
  </div>
);
