export enum EmotesCollectionsActionTypes {
  // Fetch
  FETCH_EMOTES_COLLECTIONS_REQUEST = 'emotesCollections/FETCH_EMOTES_COLLECTIONS_REQUEST',
  FETCH_EMOTES_COLLECTIONS_ERROR = 'emotesCollections/FETCH_EMOTES_COLLECTIONS_ERROR',
  FETCH_EMOTES_COLLECTIONS_SUCCESS = 'emotesCollections/FETCH_EMOTES_COLLECTIONS_SUCCESS',

  // Create
  CREATE_EMOTES_COLLECTIONS_REQUEST = 'emotesCollections/CREATE_EMOTES_COLLECTIONS_REQUEST',
  CREATE_EMOTES_COLLECTIONS_ERROR = 'emotesCollections/CREATE_EMOTES_COLLECTIONS_ERROR',
  CREATE_EMOTES_COLLECTIONS_SUCCESS = 'emotesCollections/CREATE_EMOTES_COLLECTIONS_SUCCESS',

  // Delete
  DELETE_EMOTES_COLLECTIONS_REQUEST = 'emotesCollections/DELETE_EMOTES_COLLECTIONS_REQUEST',
  DELETE_EMOTES_COLLECTIONS_ERROR = 'emotesCollections/DELETE_EMOTES_COLLECTIONS_ERROR',
  DELETE_EMOTES_COLLECTIONS_SUCCESS = 'emotesCollections/DELETE_EMOTES_COLLECTIONS_SUCCESS',

  // Update
  UPDATE_EMOTES_COLLECTIONS_REQUEST = 'emotesCollections/UPDATE_EMOTES_COLLECTIONS_REQUEST',
  UPDATE_EMOTES_COLLECTIONS_ERROR = 'emotesCollections/UPDATE_EMOTES_COLLECTIONS_ERROR',
  UPDATE_EMOTES_COLLECTIONS_SUCCESS = 'emotesCollections/UPDATE_EMOTES_COLLECTIONS_SUCCESS',

  // Update Streamer's collection.
  UPDATE_STREAMER_EMOTES_COLLECTION_REQUEST = 'emotesCollections/UPDATE_STREAMER_EMOTES_COLLECTION_REQUEST',
  UPDATE_STREAMER_EMOTES_COLLECTION_ERROR = 'emotesCollections/UPDATE_STREAMER_EMOTES_COLLECTION_ERROR',
  UPDATE_STREAMER_EMOTES_COLLECTION_SUCCESS = 'emotesCollections/UPDATE_STREAMER_EMOTES_COLLECTION_SUCCESS',
}
