import loadable from 'components/dynamic-import';
import { GridSkeleton } from 'components/table';
import { FullLayout } from 'scenes/layout';

const MyCommunityCommunication = loadable(() => import('./MyCommunityCommunication'), true, <GridSkeleton />);

export const MyCommunityCommunicationPage = (): JSX.Element => {
  return (
    <FullLayout>
      <MyCommunityCommunication />
    </FullLayout>
  );
};
