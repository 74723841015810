import { usePermissionsSelectors } from 'services/permissions';
import { Alert, AlertTypes } from 'ui-library/alert';
import loadable from 'components/dynamic-import';
import { checkPermissions, permissions, staffPermissions } from 'components/permissions';
import { FullLayout } from 'scenes/layout';
import { useSetsSelectors } from 'services/sets';
import { MarathonSkeleton } from './MarathonSkeleton';
import { useMarathonPage } from './useMarathonPage';
import { MarathonnPageProps } from './types';

const Marathon = loadable(() => import('./Marathon'), true, <MarathonSkeleton />);

export const ConnectedMarathonPage = (props: MarathonnPageProps): JSX.Element => {
  const { error, marathonConfig } = useMarathonPage();
  const { permissions: userPermissions } = usePermissionsSelectors();
  const { marathonSpecialSet } = useSetsSelectors();

  return (
    <FullLayout>
      {!marathonConfig && !error && <MarathonSkeleton />}
      {!marathonConfig && error && <Alert type={AlertTypes.ERROR}>{error.message}</Alert>}
      {Boolean(marathonConfig) && (
        <Marathon {...props} permissions={userPermissions} hasMarathonSpecialSet={Boolean(marathonSpecialSet)} />
      )}
    </FullLayout>
  );
};

export const MarathonPage = checkPermissions({
  requiredPermissions: [permissions.PAGE_OWNER, permissions.MARATHON_EDIT, ...staffPermissions],
  redirectToBasePage: true,
})(ConnectedMarathonPage);
