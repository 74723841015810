import ClassNames from '@streamloots/classnames';
import { LoadingBar } from 'ui-library/loading-spinner';
import { FixMe } from 'types/indexTS';
import { TranslateInterface } from 'utils/translation';
import { ScrollToFunction } from 'components/scrollbar-panel/types';
import type { ResultItem } from './types';
import { Result } from './Result';
import theme from './autocomplete-async.scss';

const classNames = ClassNames(theme);

export interface ResultsContentProps extends TranslateInterface {
  isLoading?: boolean;
  normalizeResult: (returnedObject: any) => ResultItem;
  onResultClick: (result: ResultItem) => void;
  onResultHover?: (index: number) => void;
  resultIndex?: number;
  results?: FixMe[] | null;
  resultsError?: string;
  scrollTo: ScrollToFunction;
  searchString: string;
  selectedValue: string;
  setScrollToSelectedResult?: boolean;
}

const isResultSelected = (result: ResultItem, selectedValue): boolean => {
  const { _id, name } = result;
  return selectedValue === name || selectedValue === _id;
};

export const ResultsContent = ({
  normalizeResult,
  onResultClick,
  onResultHover,
  resultIndex,
  setScrollToSelectedResult,
  selectedValue,
  isLoading,
  resultsError,
  searchString,
  results,
  t,
  scrollTo,
}: ResultsContentProps): JSX.Element => {
  if (isLoading || (!resultsError && searchString && !results)) {
    return (
      <div className={classNames('autocomplete-async__status')}>
        <LoadingBar />
      </div>
    );
  }

  if (resultsError) {
    return <div className={classNames('autocomplete-async__status color-error')}>{resultsError}</div>;
  }

  if (!results) {
    return <div className={classNames('autocomplete-async__status')}>{t('common:userSearch.startTyping')}</div>;
  }

  if (results.length === 0) {
    return <div className={classNames('autocomplete-async__status')}>{t('common:userSearch.noResults')}</div>;
  }

  return (
    <>
      {results.map((result, index) => {
        const resultItem = normalizeResult(result);

        return (
          <Result
            key={resultItem._id}
            result={resultItem}
            focus={index === resultIndex}
            index={index}
            onClick={onResultClick}
            selected={isResultSelected(resultItem, selectedValue)}
            setScrollToSelectedResult={setScrollToSelectedResult}
            onHover={onResultHover}
            scrollTo={scrollTo}
          />
        );
      })}
    </>
  );
};
