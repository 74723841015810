import { useState } from 'react';
import { connect } from 'react-redux';
import ClassNames from '@streamloots/classnames';
import type { UserPreferences } from 'model/userTS';
import type { UpdateUserPreferences } from 'services/user/types';
import { userSelectors, userActions } from 'services/user';
import useMedia from 'hooks/use-media';
import mediaOptimizer from 'utils/media-optimizer';
import Dialog, { CleanDialogContent } from 'ui-library/dialog';
import { USE_MEDIA_BREAKPOINTS } from 'constants/stylesBreakpoints';
import theme from './video-modal.scss';

const classNames = ClassNames(theme);
const {
  maxWidthString: { xSmall, mobile },
} = USE_MEDIA_BREAKPOINTS;

interface OwnProps {
  announcementsKey: string;
  videoURL: string;
  posterURL: string;
  title: string;
  text?: string;
  linkText?: string;
  link?: string;
  triggerModal: boolean; // So we can manually open it
  onClose: () => void;
  onClickVideo?: () => void;
  onClickHelpLink?: () => void;
  showAutomaticallyIfNotViewed?: boolean;
}

interface MapStateToProps {
  userPreferences: UserPreferences;
}

interface MapDispatchToProps {
  updateUserPreferences: UpdateUserPreferences;
}

interface AllVideoModalProps extends OwnProps, MapStateToProps, MapDispatchToProps {}

function getVideoURL({ width, videoURL, posterURL }) {
  const optimizedVideoURL = mediaOptimizer.optimizeVideo(videoURL, { width });
  const optimizedPosterURL = mediaOptimizer.optimizeImage(posterURL, { width });

  return { optimizedVideoURL, optimizedPosterURL };
}

function VideoModal(props: AllVideoModalProps): JSX.Element {
  const {
    userPreferences,
    updateUserPreferences,
    announcementsKey,
    videoURL,
    posterURL,
    title,
    text,
    linkText,
    link,
    triggerModal,
    onClose,
    onClickVideo = () => null,
    onClickHelpLink = () => null,
    showAutomaticallyIfNotViewed,
  } = props;
  const { announcements = {} } = userPreferences;
  const isVideoModalAlreadyViewed = announcements[announcementsKey];
  const [isModalOpened, setModalOpened] = useState(showAutomaticallyIfNotViewed && !isVideoModalAlreadyViewed);

  const isXSmall = useMedia(xSmall);
  const isMobile = useMedia(mobile);
  // Is this allowed? The nested ternary?
  // eslint-disable-next-line
  const width = isXSmall ? 400 : isMobile ? 600 : 800;

  const { optimizedVideoURL, optimizedPosterURL } = getVideoURL({
    width,
    videoURL,
    posterURL,
  });

  const isOpen = isModalOpened || triggerModal;

  function handleClose() {
    onClose();
    setModalOpened(false);

    if (isVideoModalAlreadyViewed) {
      return;
    }

    updateUserPreferences({
      ...userPreferences,
      announcements: {
        ...announcements,
        [announcementsKey]: true,
      },
    });
  }

  return (
    <Dialog
      type="large"
      active={isOpen}
      onEscKeyDown={handleClose}
      onOverlayClick={handleClose}
      renderDialogBody={() => {
        return (
          <CleanDialogContent className={classNames('video-modal')} onClose={handleClose}>
            <h2 className={classNames('video-modal__title')}>{title}</h2>
            <div className={classNames('video-modal__video-container')}>
              {/* eslint-disable-next-line */}
              <video
                width="100%"
                controls
                src={optimizedVideoURL}
                poster={optimizedPosterURL}
                playsInline
                onClick={onClickVideo}
              />
              {!text && !link && (
                <p className={classNames('video-modal__text')}>
                  {text}
                  <a
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      handleClose();
                      onClickHelpLink();
                    }}
                  >
                    {linkText}
                  </a>
                </p>
              )}
            </div>
          </CleanDialogContent>
        );
      }}
    />
  );
}

const mapStateToProps = state => {
  return {
    userPreferences: userSelectors.userPreferences(state),
  };
};

export default connect<MapStateToProps, MapDispatchToProps, OwnProps>(mapStateToProps, {
  updateUserPreferences: userActions.updateUserPreferences,
})(VideoModal);
