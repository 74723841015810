import { useMemo } from 'react';
import { Campaings } from 'domain/campaing-active';
import { useDispatch, useSelector } from 'react-redux';
import { claimableCampaignsActions } from './actions';
import { claimableCampaignsSelectors } from './selectors';
import { ClaimableCampaignsState } from './types';

export type UseClaimableCampaignsActions = typeof claimableCampaignsActions;

export const useClaimableCampaignsSelectors = (): ClaimableCampaignsState => {
  return useSelector(claimableCampaignsSelectors.battlePassState);
};

export const useClaimableCampaignsActions = (): UseClaimableCampaignsActions => {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      fetchCampaign: (campaignName: Campaings, userContributionEnabled = false) =>
        dispatch(claimableCampaignsActions.fetchCampaign(campaignName, userContributionEnabled)),
      updateCampaign: (campaignName: Campaings, userContributionEnabled = false) =>
        dispatch(claimableCampaignsActions.updateCampaign(campaignName, userContributionEnabled)),
      joinLeftCampaign: (campaignName, joining) =>
        dispatch(claimableCampaignsActions.joinLeftCampaign(campaignName, joining)),
    }),
    [dispatch],
  );
};
