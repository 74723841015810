import ClassNames from '@streamloots/classnames';
import trackEventManager from 'utils/event-manager';
import { ButtonIcon } from 'ui-library/button';
import { useArticlesMapping } from 'components/help/useArticlesMapping';
import { ArticleKey } from './articles-keys';
import theme from './help.scss';

const classNames = ClassNames(theme);

type HelpOpenerProps = {
  className?: string;
  panelHeader?: boolean;
  articleKey: ArticleKey;
  asLink?: boolean;
  label?: string;
  inputLabel?: boolean;
  onClick?: () => void;
  icon?: string;
  underlined?: boolean;
  eventPayload?: Record<string, string>;
  iconRight?: boolean;
};

export const HelpOpener = (props: HelpOpenerProps): JSX.Element | null => {
  const {
    articleKey,
    className = '',
    panelHeader,
    label,
    inputLabel,
    asLink,
    onClick,
    icon = 'help-circle',
    eventPayload = {},
    underlined = false,
    iconRight = false,
  } = props;
  const articleLink = useArticlesMapping(articleKey);

  if (!articleLink) {
    return null;
  }

  return (
    <ButtonIcon
      component="a"
      target="_blank"
      rel="noopener noreferrer"
      href={articleLink}
      className={classNames({
        [className]: Boolean(className),
        'help__button': true,
        'help__button--panelheader': panelHeader,
        'help__button--input': inputLabel,
        'help__button--underlined': underlined,
      })}
      onClick={() => {
        trackEventManager.trackEvent('help_clicked', { articleKey, ...eventPayload });
        if (onClick) {
          onClick();
        }
      }}
      icon={icon}
      label={label}
      asLink={asLink || inputLabel}
      iconRight={iconRight}
    />
  );
};
