import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { ButtonIcon } from 'ui-library/button';
import { ValueOf } from 'types/indexTS';
import { ALERT_TYPES } from './constants';
import AlertIcon from './AlertIcon';
import theme from './alert.scss';

const classNames = ClassNames(theme);

type AlertProps = {
  children: React.ReactNode;
  className?: string;
  type?: ValueOf<typeof ALERT_TYPES>;
  onClose: () => void;
};

/**
 * These alerts should be used when we want to give the user a message in the content itself,
 * as in forms error message, correct or incorrect number of cards picked, etc...
 */
const FloatingAlert = ({ children, className = '', type = ALERT_TYPES.INFO, onClose }: AlertProps) => (
  <div
    className={classNames({
      'alert alert--floating': true,
      'alert--floating--info': type === ALERT_TYPES.INFO,
      'alert--floating--warning': type === ALERT_TYPES.WARNING,
      'alert--floating--success': type === ALERT_TYPES.SUCCESS,
      'alert--floating--error': type === ALERT_TYPES.ERROR,
      [className]: Boolean(className),
    })}
  >
    <div className={theme.alert__icon}>
      <AlertIcon type={type} />
    </div>
    <div className={theme['alert--floating__content']}>{children}</div>
    <ButtonIcon className={theme.alert__close} onClick={onClose} icon="close" asLink />
  </div>
);

FloatingAlert.ALERT_TYPES = ALERT_TYPES;

export default FloatingAlert;
