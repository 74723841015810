import type { LayoutState } from './types';
import { LayoutActionTypes } from './actionTypes';

export const getInitialState = (isMobile: boolean, isIOS: boolean) => ({
  isMobile,
  isIOS,
});

export const reducer = (state: LayoutState = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case LayoutActionTypes.STRUCTURE_CHANGED: {
      return { ...state, isMobile: payload.isMobile };
    }
    default:
      return state;
  }
};
