import {
  PageFeedPermission,
  StrictOmit,
  PageFeed as PageFeedResponse,
  PageFeedSummary,
  Currency,
  PAGE_FEED_POST_REQUEST,
  PAGE_FEED_GET_RESPONSE_SUCCESS,
} from '@streamloots/streamloots-types';

// 👇 FIX ME - Move this to types library
export const PageFeedPaymentStatus = {
  InProgress: 'InProgress',
  Error: 'Error',
  Payed: 'Payed',
} as const;

// 👇 FIX ME - Move this to types library
export interface NewPageFeedSummary extends PageFeedSummary {
  tipAmount?: number;
  paymentStatus?: typeof PageFeedPaymentStatus[keyof typeof PageFeedPaymentStatus];
}

export type PageFeedPermissionValues = typeof PageFeedPermission[keyof typeof PageFeedPermission];

export interface PageFeed extends StrictOmit<PageFeedResponse, 'createdAt' | 'summary'> {
  summary: NewPageFeedSummary;
  createdAt: Date;
  price?: number;
  currency?: Currency;
}

// 👇 FIX ME - Move this to types library
export interface NEW_PAGE_FEED_POST_REQUEST extends PAGE_FEED_POST_REQUEST {
  price?: number;
}

// 👇 FIX ME - Move this to types library
export interface NewPageFeedResponse extends PageFeedResponse {
  price?: number;
  currency?: Currency;
}

// FIX ME - 👇 Move to types library
export interface NEW_PAGE_FEED_GET_RESPONSE_SUCCESS extends StrictOmit<PAGE_FEED_GET_RESPONSE_SUCCESS, 'data'> {
  data: NewPageFeedResponse[];
}
