import { useEffect, useState } from 'react';
import { useMarathonSelectors, useMarathonActions, UseMarathonSelectors, UseMarathonActions } from 'services/marathon';
import { UsePageSelectors, usePageSelectors } from 'services/page';
import { useAlertProvidersSelectors } from 'services/alert-providers';
import { MarathonStates } from 'model/marathon';
import { PickReturnTypeMappedType } from 'types/utils';

export enum PanelState {
  TIMER = 'TIMER',
  FINISHED = 'FINISHED',
}

type MarathonConfig = Pick<
  PickReturnTypeMappedType<UseMarathonSelectors, 'marathonState'>,
  'marathonConfig' | 'marathonTracker' | 'isLoading'
>;

type InPageContext = Pick<PickReturnTypeMappedType<UsePageSelectors, 'page'>, 'inPageContext'>;

type MarathonActions = Pick<UseMarathonActions, 'addMarathonTime' | 'fetchMarathonTracker'>;

interface UseMarathonTimer
  extends MarathonConfig,
    MarathonActions,
    Pick<UseMarathonSelectors, 'marathonTrackerId'>,
    InPageContext {
  showPanel: PanelState;
  handleHidePanel: () => void;
  isActiveMarathon: boolean;
  targetId?: string;
  setShowPanel: (state: PanelState) => void;
}

export const useMarathonTimer = (): UseMarathonTimer => {
  const [showPanel, setShowPanel] = useState(PanelState.TIMER);
  const { marathonStatus, marathonState, marathonTrackerId } = useMarathonSelectors();
  const { targetId } = useAlertProvidersSelectors();
  const {
    page: { slug },
  } = usePageSelectors();
  const { marathonConfig, marathonTracker, isLoading } = marathonState;
  const { fetchMarathon, fetchMarathonTracker, addMarathonTime } = useMarathonActions();
  const {
    page: { inPageContext },
  } = usePageSelectors();
  const isActiveMarathon = marathonStatus === MarathonStates.ACTIVE && showPanel === PanelState.TIMER;

  useEffect(() => {
    if (!isLoading && slug) {
      fetchMarathonTracker(slug);
    }
  }, [fetchMarathon, fetchMarathonTracker, isLoading, marathonConfig, slug]);

  useEffect(() => {
    if (marathonStatus === MarathonStates.ACTIVE) {
      setShowPanel(PanelState.TIMER);
    }
  }, [marathonStatus]);

  const handleHidePanel = () => {
    setShowPanel(PanelState.TIMER);
  };

  return {
    marathonTracker,
    marathonTrackerId,
    inPageContext,
    showPanel,
    handleHidePanel,
    isActiveMarathon,
    addMarathonTime,
    fetchMarathonTracker,
    targetId,
    setShowPanel,
  };
};
