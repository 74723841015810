import { createContext, useState, useCallback } from 'react';

interface SelfActivationsPanelsContextState {
  activePanels: number;
  onActivate?: (key: string) => void;
  onDeactivate?: (key: string) => void;
}

export const SelfActivationsPanelsContext = createContext<SelfActivationsPanelsContextState>({
  activePanels: 0,
});

interface SelfActivationPanelsProviderProps {
  children: React.ReactNode;
}

export const SelfActivationPanelsProvider = ({ children }: SelfActivationPanelsProviderProps): JSX.Element => {
  const [promotionsActive, setPromotionsActive] = useState<Record<string, boolean>>({});

  const handleActivate = useCallback((key: string) => {
    setPromotionsActive(state => ({ ...state, [key]: true }));
  }, []);

  const handleDeactivate = useCallback((key: string) => {
    setPromotionsActive(state => ({ ...state, [key]: false }));
  }, []);

  return (
    <SelfActivationsPanelsContext.Provider
      value={{
        activePanels: Object.values(promotionsActive).filter(Boolean).length,
        onActivate: handleActivate,
        onDeactivate: handleDeactivate,
      }}
    >
      {children}
    </SelfActivationsPanelsContext.Provider>
  );
};
