import type { ModeratorsActionTypes } from './types';
import { AddModeratorActionTypes } from './services/add';
import { UpdateModeratorActionTypes } from './services/update';
import { DeleteModeratorActionTypes } from './services/delete';
import { ListModeratorActionTypes } from './services/list';

export const actionTypes: ModeratorsActionTypes = {
  ...AddModeratorActionTypes,
  ...UpdateModeratorActionTypes,
  ...DeleteModeratorActionTypes,
  ...ListModeratorActionTypes,
};
