import { AlertProvider } from 'model/indexTS';
import { PageActionTypes } from 'services/page';
import { UserActionTypes } from 'services/user';
import { AuthProvidersActionTypes } from 'services/auth-providers';
import type { AlertProvidersState } from './types';
import { AlertProvidersActionTypes } from './actionTypes';

const normalizeCustomBadWord = (customBadWordsString = '') => customBadWordsString.split(',').join(', ');

export const reducer = (state: AlertProvidersState = {}, action): AlertProvidersState => {
  const { type, error, payload } = action;
  switch (type) {
    case UserActionTypes.RECEIVED: {
      if (error) {
        return state;
      }
      const {
        user: { authProviders },
      } = payload;

      return {
        ...state,
        streamlabsConnected: authProviders.indexOf(AlertProvider.STREAMLABS) > -1,
      };
    }
    case PageActionTypes.RECEIVED: {
      if (error || !payload.alertProviders) {
        return {};
      }
      const { alertProviders } = payload;
      const { streamloots } = alertProviders;

      return {
        ...state,
        alertProviders: { ...alertProviders },
        alertWidgetUrl: streamloots && streamloots.url,
      };
    }
    case AlertProvidersActionTypes.UPDATED: {
      if (error) {
        return state;
      }

      const {
        OBSConfigured,
        provider,
        streamloots,
        customBadWords,
        streamlootsBadWordFilterEnabled,
        automaticAlerts,
      } = payload;

      const newAlertProvider = {
        provider,
        customBadWords: normalizeCustomBadWord(customBadWords),
        streamlootsBadWordFilterEnabled,
        automaticAlerts,
        OBSConfigured,
        streamloots: {
          settings: { ...streamloots.settings },
          url: state.alertWidgetUrl || '',
        },
      };

      return {
        ...state,
        alertProviders: newAlertProvider,
      };
    }
    case AuthProvidersActionTypes.ADD_SUCCESS: {
      if (error || payload.provider !== 'streamlabs') {
        return state;
      }
      return {
        ...state,
        streamlabsConnected: true,
      };
    }
    case PageActionTypes.CHANGED: {
      return {};
    }
    default:
      return state;
  }
};
