import type { PaginationStateNormalizerConfiguration } from 'services/pagination/typesTS';
import { MyCreatorsQuery } from './services/list/types';

export const DEFAULT_VIEWERS_QUERY: MyCreatorsQuery = {
  limit: 50,
  userId: '',
};

export const PAGINATION_STATE_NORMALIZER_CONFIGURATION: PaginationStateNormalizerConfiguration<MyCreatorsQuery> = {
  initialQuery: DEFAULT_VIEWERS_QUERY,
};
