import { createSelector } from 'reselect';
import type { PagesState, PagesStatus } from './typesTS';
import { EMPTY_STATUS } from './constants';

const pagesState = (state): PagesState => state.pages;

const pagesStatus = createSelector(
  [pagesState, (_state, componentKey) => componentKey],
  (pagesStateInfo: PagesState, componentKey: number): PagesStatus => pagesStateInfo[componentKey] || EMPTY_STATUS,
);

export const pagesSelectors = {
  pagesStatus,
};
