declare const window: any;

const events = {
  cardCreated: {
    es: 'o27qt',
    en: 'o28e2',
  },
  cardRedeemed: {
    es: 'o27qw',
    en: 'o28e3',
  },
  cardCrafted: {
    es: 'o27qx',
    en: 'o28e4',
  },
  cardDisenchanted: {
    es: 'o27qy',
    en: 'o28e5',
  },
  chestGifted: {
    es: 'o27qz',
    en: 'o28e6',
  },
  lootPaymentInitialized: {
    es: 'o27r0',
    en: 'o28e7',
  },
  lootOpened: {
    es: 'o27r1',
    en: 'o28e8',
  },
  lootPaymentCanceled: {
    es: 'o27r2',
    en: 'o28e9',
  },
  lootPurchased: {
    es: 'o2797',
    en: 'o28eb',
  },
  pageView: {
    es: 'o2799',
    en: 'o28ed',
  },
  setPublished: {
    es: 'o27r3',
    en: 'o28ee',
  },
  setUnpublished: {
    es: 'o27r4',
    en: 'o28ef',
  },
  streamerHasSoldChests: {
    es: 'o27r6',
    en: 'o28eh',
  },
  userIsStreamer: {
    es: 'o27r7',
    en: 'o28ei',
  },
  streamerHasBeenRedeemed: {
    es: 'o27r9',
    en: 'o28ej',
  },
  onboardingCompleted: {
    es: 'o27ra',
    en: 'o28ek',
  },
  onboardingStepCompleted: {
    es: 'o27rc',
    en: 'o28el',
  },
  comeFromCampaign: {
    es: 'o27rd',
    en: 'o28em',
  },
  completeRegistration: {
    es: 'o27sf',
    en: 'o28eq',
  },
};

function sendEvent(eventName: string, amount = 0, quantity = 0): void {
  try {
    const eventCode = events[eventName];

    if (typeof window.twttr !== 'undefined' && typeof eventCode !== 'undefined') {
      window.twttr.conversion.trackPid(eventCode.es, {
        tw_sale_amount: amount,
        tw_order_quantity: quantity,
      });
      window.twttr.conversion.trackPid(eventCode.en, {
        tw_sale_amount: amount,
        tw_order_quantity: quantity,
      });
    }

    if (typeof eventCode === 'undefined') {
      console.error('Twitter event not defined');
    }
  } catch (e) {
    console.error(e);
  }
}

export default {
  sendEvent,
};
