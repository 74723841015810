import { useContext, useEffect } from 'react';
import { CampaingActive, Campaings } from 'domain/campaing-active';
import { useClaimableCampaignsActions, useClaimableCampaignsSelectors } from 'services/claimable-campaigns';
import { UseCultureSelectors, useCultureSelectors } from 'services/culture';
import { SelfActivationsPanelsContext } from 'components/self-activation-panels-context';
import { BaseClaimableCampaigns } from 'model/claimableCampaigns';

interface UseTicketsPanel extends Pick<UseCultureSelectors, 'language'> {
  isFiestaActive: boolean;
  campaign?: BaseClaimableCampaigns;
}

export const useTicketsPanel = (): UseTicketsPanel => {
  const { campaign, isLoading, reconcile } = useClaimableCampaignsSelectors();
  const { fetchCampaign } = useClaimableCampaignsActions();
  const isFiestaActive = CampaingActive.isTicketsCampaignActive();
  const { language } = useCultureSelectors();
  const { onActivate, onDeactivate } = useContext(SelfActivationsPanelsContext);

  useEffect(() => {
    if (isFiestaActive && onActivate) {
      onActivate(Campaings.TICKETS_CAMPAIGN);
    }

    if (!isFiestaActive && onDeactivate) {
      onDeactivate(Campaings.TICKETS_CAMPAIGN);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFiestaActive && ((!campaign && !isLoading) || reconcile)) {
      fetchCampaign(Campaings.TICKETS_CAMPAIGN, true);
    }
  }, [campaign, fetchCampaign, isFiestaActive, isLoading, onActivate, onDeactivate, reconcile]);

  return {
    campaign,
    isFiestaActive,
    language,
  };
};
