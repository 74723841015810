import useMedia from 'hooks/use-media';
import { USE_MEDIA_BREAKPOINTS } from 'constants/stylesBreakpoints';
import { withTranslation } from 'utils/translation';
import type { ExtendedSetRadioButtonCardsProps } from './types';
import { DesktopSetRadio } from './DesktopSetRadio';
import { PhoneSetRadio } from './PhoneSetRadio';

export const SetRadioButtonCardsBase = (props: ExtendedSetRadioButtonCardsProps) => {
  const isMobile = useMedia(USE_MEDIA_BREAKPOINTS.maxWidthString.mobile);
  if (isMobile) {
    return <PhoneSetRadio {...props} />;
  }

  return <DesktopSetRadio {...props} />;
};

export default withTranslation('lootPacks')(SetRadioButtonCardsBase);
