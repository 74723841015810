import type { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import Icon from 'ui-library/icons';
import { checkPermissions, permissions } from 'components/permissions';
import { TranslateInterface } from 'utils/translation';
import { urls } from 'services/routing';

const ManageCollectionsLinkInternal = ({ t }: TranslateInterface) => {
  return (
    <Link to={urls().MANAGE_COLLECTIONS}>
      <Icon id="settings" /> {t('common:menu.manageCollections')}
    </Link>
  );
};

export const ManageCollectionsLink: FunctionComponent<TranslateInterface> = checkPermissions({
  requiredPermissions: [permissions.SETS_EDIT],
})(ManageCollectionsLinkInternal);
