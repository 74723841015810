import stateCreator from 'helpers/stateCreator';
import type { SearchUsersStatus, UsersSearchState } from './types';
import { UsersSearchActionTypes } from './actionTypes';
import { USER_RESULTS_LIMIT } from './constants';

const initialStatus: SearchUsersStatus = {
  results: null,
  filters: { limit: USER_RESULTS_LIMIT, username: '' },
};

const getNewStateWithKeyStatus = (state: UsersSearchState, keyStatus: SearchUsersStatus, key: string) => {
  const newState = { ...state };
  newState[key] = { ...keyStatus };
  return newState;
};

export const reducer = (state: UsersSearchState = {}, action): UsersSearchState => {
  const { error, payload, metadata } = action;
  switch (action.type) {
    case UsersSearchActionTypes.INITIALIZED: {
      const { searchKey } = payload;
      return getNewStateWithKeyStatus(state, initialStatus, searchKey);
    }
    case UsersSearchActionTypes.RECEIVED: {
      const searchKey = error ? metadata.searchKey : payload.searchKey;
      const searchKeyState = state[searchKey];

      if (!searchKeyState) {
        return state;
      }

      if (error) {
        const keyErrorStatus = stateCreator.getAsyncErrorState(action, state);
        return getNewStateWithKeyStatus(state, keyErrorStatus, searchKey);
      }
      const { data, filters } = payload;
      const keyStatus = stateCreator.getInitialAsyncState(searchKeyState, {
        results: [...data],
        filters: { ...filters },
      });
      return getNewStateWithKeyStatus(state, keyStatus, searchKey);
    }
    case UsersSearchActionTypes.FETCHING: {
      const { searchKey, filters, cancelToken } = payload;

      const searchKeyState = state[searchKey];

      if (!searchKeyState) {
        return state;
      }

      const keyStatus = stateCreator.getAsyncLoadingState(searchKeyState, {
        results: null,
        filters: { ...filters },
        cancelToken,
      });
      return getNewStateWithKeyStatus(state, keyStatus, searchKey);
    }
    case UsersSearchActionTypes.RESET: {
      const { searchKey } = payload;
      return getNewStateWithKeyStatus(state, {}, searchKey);
    }
    default:
      return state;
  }
};
