import { StrictOmit } from '@streamloots/streamloots-types';
import ScrollbarPanel from 'components/scrollbar-panel';
import { ResultsConfiguration } from 'components/autocomplete-async/types';
import { ResultsContent, ResultsContentProps } from './ResultsContent';

export interface ResultsProps extends StrictOmit<ResultsContentProps, 'scrollTo'>, ResultsConfiguration {}

export const Results = ({
  autoHeight = false,
  autoHeightMin = '40px',
  autoHeightMax = '144px',
  className,
  ...rest
}: ResultsProps): JSX.Element => {
  return (
    <div className={className}>
      <ScrollbarPanel
        autoHeight={autoHeight}
        autoHeightMin={autoHeightMin}
        autoHeightMax={autoHeightMax}
        renderContent={({ scrollTo }) => <ResultsContent {...rest} scrollTo={scrollTo} />}
      />
    </div>
  );
};
