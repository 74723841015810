import { PayoutMethods } from '@streamloots/streamloots-types';
import { PaymentMethods, PagePayoutMethod } from 'model/indexTS';
import { NormalizedPagePayoutMethod } from './types';

const manualPayment = (paymentMethods: PaymentMethods): NormalizedPagePayoutMethod | null => {
  if (!paymentMethods || paymentMethods.length === 0) {
    return null;
  }

  const paymentMethod = paymentMethods[0];
  const { provider } = paymentMethod;

  switch (provider) {
    case PagePayoutMethod.PAYPAL:
      return {
        account: paymentMethod.email,
        provider: PayoutMethods.PAYPAL,
      };
    default:
      return null;
  }
};

const normalizePayoutMethod = ({ primaryPayoutMethod, email }): NormalizedPagePayoutMethod | null => {
  switch (primaryPayoutMethod) {
    case PayoutMethods.PAYPAL:
      return {
        account: email,
        provider: PayoutMethods.PAYPAL,
      };
    case PayoutMethods.BANK_ACCOUNT:
      return {
        provider: PayoutMethods.BANK_ACCOUNT,
      };
    case PayoutMethods.HYPERWALLET:
      return {
        account: email,
        provider: PayoutMethods.HYPERWALLET,
      };
    default:
      return null;
  }
};

const payoutMethod = (
  paymentMethods: PaymentMethods,
  hyperwallet: {
    primaryPayoutMethod?: PayoutMethods;
    hyperwalletRegistered?: boolean;
  },
): NormalizedPagePayoutMethod | null => {
  const isHyperwalletPath = hyperwallet.hyperwalletRegistered !== undefined;
  if (!isHyperwalletPath) {
    return manualPayment(paymentMethods);
  }

  if (Array.isArray(paymentMethods) && paymentMethods.length > 0) {
    const { email } = paymentMethods[0];

    return normalizePayoutMethod({
      primaryPayoutMethod: hyperwallet.primaryPayoutMethod,
      email,
    });
  }

  return null;
};

export default {
  payoutMethod,
};
