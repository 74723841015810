import { Location } from 'history';
import { History } from 'types/indexTS';

export default {
  shouldScrollToTop(
    location: Location | Location<{ noScroll?: boolean }>,
    prevLocation: Location | undefined,
    history?: History,
  ): boolean {
    if (!prevLocation || location === prevLocation) {
      return false;
    }

    const isBack = history && history.action === 'POP';

    if (isBack) {
      return false;
    }

    const { state } = location as Location<{ noScroll?: boolean }>;

    return !state?.noScroll;
  },
};
