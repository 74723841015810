import React from 'react';
import { connect } from 'react-redux';
import { Experiment } from 'model/indexTS';
import type { FetchCurrentUser, UserAsyncStatus } from 'services/user';
import trackEventManager from 'utils/event-manager';
import utmManager from 'utils/utm-manager';
import experimentsManager from 'utils/experiments-manager';
import { userSelectors, userActions } from 'services/user';
import { authSelectors } from 'services/auth';
import { dataConciliatorSelectors } from 'services/data-conciliator';
import Spinner from 'ui-library/loading-spinner';
import sharedCookieManager from 'utils/sharedCookieManager';
import { UserErrorDialog } from '../errors';

interface MapDispatchToProps {
  fetchCurrentUser: FetchCurrentUser;
}

interface MapStateToProps extends UserAsyncStatus {
  authToken?: string;
  isUserAuthenticated: boolean;
  invalidateAuthToken?: boolean;
  reconcileUser: boolean;
  databaseExperiments: Experiment[];
}

interface CurrentUserFetcherProps extends MapStateToProps, MapDispatchToProps {}

export class CurrentUserFetcherInternal extends React.Component<CurrentUserFetcherProps> {
  componentDidMount() {
    const { user, authToken = '' } = this.props;
    if (user) {
      trackEventManager.userSignIn(user);
      trackEventManager.userIsStreamer(user.category);
      utmManager.sendComeFromCampaignCategorizedEvent(true);
      sharedCookieManager.setUserAuthenticatedCookies(user._id, authToken);
    }

    this.fetchUserInfo();
  }

  componentDidUpdate(prevProps) {
    const { reconcileUser, user, databaseExperiments } = this.props;

    if (reconcileUser && !prevProps.reconcileUser) {
      this.fetchUserInfo(true);
    }

    if (user && prevProps.user === null) {
      trackEventManager.userIsStreamer(user.category);
    }

    if (databaseExperiments !== prevProps.databaseExperiments) {
      this.synchronizeExperiments();
    }
  }

  fetchUserInfo = async (forceFetch?: boolean) => {
    const { fetchCurrentUser, isUserAuthenticated, invalidateAuthToken, isLoading, error } = this.props;

    if (!isUserAuthenticated) {
      return;
    }

    if ((!invalidateAuthToken && !isLoading && !error) || forceFetch) {
      fetchCurrentUser();
    }
  };

  getDatabaseExperiments = () =>
    // eslint-disable-next-line
    this.props.databaseExperiments.map(exp => {
      // Normalize: strip out this key as cookies don't have it
      const { serverConfirmationAt, ...rest } = exp;
      return rest;
    });

  getActiveExperimentsNames = () => experimentsManager.ACTIVE_EXPERIMENTS.map(exp => exp.name);

  // When user is authenticated update cookies with whatever we receive as a GET /me response.
  synchronizeExperiments = () => {
    const dbExperiments = this.getDatabaseExperiments();
    if (dbExperiments.length === 0) {
      return;
    }

    const allActiveExperimentsNames = this.getActiveExperimentsNames();
    const dbActiveExperiments = dbExperiments.filter(exp => allActiveExperimentsNames.includes(exp.name));

    experimentsManager.setExperimentsCookie(dbActiveExperiments);
  };

  render() {
    const { error, isUserAuthenticated, invalidateAuthToken, user } = this.props;

    if (error || invalidateAuthToken) {
      return <UserErrorDialog type={invalidateAuthToken ? 'invalidateToken' : 'errorLoading'} />;
    }

    if (!isUserAuthenticated || user !== null) {
      return null;
    }

    return <Spinner fixed opaque />;
  }
}

const mapStateToProps = (state): MapStateToProps => ({
  isUserAuthenticated: authSelectors.isUserAuthenticated(state),
  authToken: authSelectors.authToken(state),
  invalidateAuthToken: authSelectors.invalidateAuthToken(state),
  reconcileUser: dataConciliatorSelectors.reconcileUser(state),
  ...userSelectors.userAsyncStatus(state),
  databaseExperiments: userSelectors.userExperimentsMemoized(state),
});

export const CurrentUserFetcher = connect<MapStateToProps, MapDispatchToProps>(mapStateToProps, {
  fetchCurrentUser: userActions.fetchCurrentUser,
})(CurrentUserFetcherInternal);
