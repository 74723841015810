import { PageType } from '@streamloots/streamloots-types';
import AFFILIATE_BADGE from './assets/affiliate.svg';
import PARTNER_BADGE from './assets/partner.svg';
import REGULAR_BADGE from './assets/streamer.svg';
import AMBASSADOR_BADGE from './assets/ambassador.svg';

const BADGE_MAPPING = {
  AFFILIATE: AFFILIATE_BADGE,
  PARTNER: PARTNER_BADGE,
  REGULAR: REGULAR_BADGE,
};

export interface PageBadgeProps {
  className?: string;
  type: PageType;
  showRegular?: boolean;
  ambassador?: boolean;
}

export const PageBadge = ({
  className,
  type = PageType.REGULAR,
  showRegular = true,
  ambassador,
}: PageBadgeProps): JSX.Element | null => {
  if (!showRegular && type === PageType.REGULAR && !ambassador) {
    return null;
  }

  const imageUrl = ambassador ? AMBASSADOR_BADGE : BADGE_MAPPING[type];
  const title = ambassador ? 'Streamloots Ambassador' : type;
  const alt = ambassador ? 'Ambassador' : type;

  return <img src={imageUrl || BADGE_MAPPING.REGULAR} alt={alt} title={title} className={className} />;
};
