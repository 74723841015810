import { Transformation, TransformationOptions, getExtension } from './Transformation';
import config from '../../config';

const { CDN_WSRV_URL } = config.cdn;

const SUPPORTED_IMAGE_TYPES = ['.bmp', '.jpeg', '.jpg', '.png'];

const ANIMATED_IMAGE_TYPES = ['.gif', '.webp'];

export default class WsrvTransformation implements Transformation {
  private transformations: string[];

  constructor(private url: string, transformationOptions = {}) {
    this.transformations = this.getTransformations(transformationOptions);
  }

  isSupported(): boolean {
    return SUPPORTED_IMAGE_TYPES.includes(getExtension(this.url));
  }

  toUrl(): string {
    if (!this.url || !this.isSupported()) {
      return this.url;
    }

    let paramString = '';
    if (this.transformations.length) {
      paramString = this.transformations.join('');
    }

    return `${CDN_WSRV_URL}?url=${this.url}${paramString}`;
  }

  private getTransformations(transformationOptions: TransformationOptions): string[] {
    const transformations: string[] = [];

    if (transformationOptions.width) {
      transformations.push(`&w=${transformationOptions.width}`);
    }

    if (transformationOptions.quality) {
      transformations.push(`&q=${transformationOptions.quality}`);
    }

    if (ANIMATED_IMAGE_TYPES.includes(getExtension(this.url))) {
      transformations.push('&n=-1');
    }

    return transformations;
  }
}
