import { useSelector } from 'react-redux';
import { InterfaceWithReturnType } from 'types/indexTS';
import { appSelectors } from './selectors';

export type UseAppSelectors = InterfaceWithReturnType<typeof appSelectors>;

export const useAppSelectors = (): UseAppSelectors => {
  const currentPathname = useSelector(appSelectors.currentPathname);
  const baseRequestQuery = useSelector(appSelectors.baseRequestQuery);
  const baseRequestPath = useSelector(appSelectors.baseRequestPath);
  const hash = useSelector(appSelectors.hash);

  return {
    currentPathname,
    baseRequestQuery,
    baseRequestPath,
    hash,
  };
};
