import { useOverloadedContentActions } from 'services/overloaded-content';
import { useTranslation } from 'utils/translation';
import trackEventManager from 'utils/event-manager';
import Button, { ButtonIcon } from 'ui-library/button';
import { ButtonSizeProp } from 'ui-library/button/types';
import DialogBuyLootPacksPicker from './DialogBuyLootPacksPicker';

interface ConnectedBuyLootPacksPickerProps {
  buttonId: string;
  className?: string;
  icon?: string;
  label?: string;
  onClick?: () => void;
  size?: ButtonSizeProp;
}

export const ConnectedBuyLootPacksPicker = ({
  buttonId,
  icon,
  className = '',
  onClick,
  label,
  size,
}: ConnectedBuyLootPacksPickerProps): JSX.Element => {
  const { openOverloadedContent } = useOverloadedContentActions();
  const { t } = useTranslation('publicUserPage');

  const handleClick = () => {
    trackEventManager.buyPacksStarted({
      location: buttonId,
      type: null,
    });

    openOverloadedContent({
      component: DialogBuyLootPacksPicker,
      wrapperProps: {
        type: 'large',
        opacity: true,
        overflowH: true,
        noCentered: true,
      },
      componentProps: {
        startLocation: buttonId,
      },
    });

    if (onClick) {
      onClick();
    }
  };

  if (icon) {
    return (
      <ButtonIcon
        icon={icon}
        color="checkout"
        variant="filled"
        className={className}
        onClick={handleClick}
        label={t('publicUserPage:packs.buyMore')}
        size={size}
      />
    );
  }

  return (
    <Button
      color="checkout"
      variant="filled"
      className={className}
      onClick={handleClick}
      label={label || t('publicUserPage:packs.buyMore')}
      size={size}
    />
  );
};

export default ConnectedBuyLootPacksPicker;
