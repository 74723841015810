export const errorMapping = {
  MARATHON_NOT_EXISTS: 'marathon:error.MARATHON_NOT_EXISTS',
  MARATHON_ALREADY_EXISTS: 'marathon:error.MARATHON_ALREADY_EXISTS',
  MARATHON_ALREADY_STARTED: 'marathon:error.MARATHON_ALREADY_STARTED',
  MARATHON_ALREADY_STOPPED: 'marathon:error.MARATHON_ALREADY_STOPPED',
  MARATHON_OVERTIME: 'marathon:error.MARATHON_OVERTIME',
  MARATHON_DATE_RANGE: 'marathon:error.MARATHON_DATE_RANGE',
  MAX_TIME_EXCEED: 'marathon:error.MAX_TIME_EXCEED',
  UNABLE_TO_ACTIVE_TWITCH_SUBSCRIPTIONS: 'marathon:error.UNABLE_TO_ACTIVE_TWITCH_SUBSCRIPTIONS',
};
