import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { DropdownMenuPosition, DropdownMenuRenderComponentArguments, DropdownMenuRenderContentProp } from './types';
import { DropdownMenuContent } from './DropdownMenuContent';
import theme from './dropdown-menu.scss';

const classNames = ClassNames(theme);

interface DropdownMenuProps {
  clickOnWrapper?: boolean;
  className?: string;
  contentClassName?: string;
  position?: DropdownMenuPosition;
  renderContent: DropdownMenuRenderContentProp;
  renderComponent: (props: DropdownMenuRenderComponentArguments) => React.ReactNode;
  closeOnClick?: boolean;
  colorBg?: 'grey';
}

export const DropdownMenu = ({
  className = '',
  contentClassName,
  renderComponent,
  renderContent,
  position,
  clickOnWrapper,
  closeOnClick,
  colorBg,
}: DropdownMenuProps): JSX.Element => {
  const [active, setIsActive] = React.useState(false);
  const contentRef = React.useRef<HTMLDivElement | null>(null);
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);
  const buttonRef = React.useRef<HTMLDivElement | null>(null);

  const toggleContent = React.useCallback(
    event => {
      if (active) {
        setIsActive(false);
      } else {
        event.preventDefault();
        event.stopPropagation();
        setIsActive(true);
      }
    },
    [active, setIsActive],
  );

  const hide = React.useCallback(() => {
    setIsActive(false);
  }, [setIsActive]);

  React.useEffect(() => {
    const handleClickOutside = event => {
      if (buttonRef.current?.contains(event.target)) {
        return;
      }

      if (wrapperRef.current?.contains(event.target)) {
        return;
      }

      if (contentRef.current?.contains(event.target)) {
        return;
      }

      hide();
    };
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [active, hide]);

  return (
    <div
      className={classNames({ menu: true, [className]: Boolean(className) })}
      onClick={clickOnWrapper ? toggleContent : undefined}
      ref={wrapperRef}
    >
      <div ref={buttonRef}>{renderComponent({ active, onClick: toggleContent })}</div>
      {active && (
        <DropdownMenuContent
          position={position}
          className={contentClassName}
          closeDropdown={toggleContent}
          closeOnClick={closeOnClick}
          colorBg={colorBg}
          ref={contentRef}
        >
          {renderContent({ onHideMenu: hide })}
        </DropdownMenuContent>
      )}
    </div>
  );
};
