import _ from 'lodash';
import type { Notification } from 'model/indexTS';
import { paginationStateNormalizer } from 'services/pagination';
import { NotificationsActionTypes } from './actionTypes';
import { DEFAULT_QUERY, PAGINATION_STATE_NORMALIZER_CONFIGURATION } from './constants';
import { NotificationsMarkedAsReadActionSuccess, NotificationsState } from './typesTS';

const initialState: NotificationsState = {
  query: DEFAULT_QUERY,
};

const getUnreadNotificationIds = (notifications: Array<Notification>): Array<string> | null => {
  const unreadNotificationIds = notifications
    .filter(notification => !notification.read)
    .map(notification => notification._id);

  return unreadNotificationIds.length > 0 ? unreadNotificationIds : null;
};

export const reducer = (state: NotificationsState = initialState, action): NotificationsState => {
  const { error, payload } = action;
  switch (action.type) {
    case NotificationsActionTypes.RECEIVED: {
      if (error) {
        return paginationStateNormalizer.getReceivedError(action, state);
      }

      const responseObject = payload;

      if (Array.isArray(state.data) && state.data.length > 0) {
        const newData = _.concat(payload.data, state.data);
        responseObject.data = _.uniqBy(newData, '_id');

        responseObject.query = state.query;
        responseObject.pagination = state.pagination;
      }

      const newPaginationState = paginationStateNormalizer.getReceived(
        responseObject,
        PAGINATION_STATE_NORMALIZER_CONFIGURATION,
      );

      const { newNotificationsInAppCount } = payload.summary;

      return {
        ...state,
        ...newPaginationState,
        unreadNotificationIds: getUnreadNotificationIds(responseObject.data),
        unreadNotificationsCount: newNotificationsInAppCount && newNotificationsInAppCount.count,
      };
    }
    case NotificationsActionTypes.FETCHING: {
      return paginationStateNormalizer.getRequest(state, payload, PAGINATION_STATE_NORMALIZER_CONFIGURATION);
    }
    case NotificationsActionTypes.NEXT_REQUEST: {
      return paginationStateNormalizer.getFetchingNextItems(state);
    }
    case NotificationsActionTypes.NEXT_RECEIVED: {
      if (error) {
        return paginationStateNormalizer.getNextReceivedError(state);
      }

      return {
        ...paginationStateNormalizer.getNextItemsReceived(state, payload),
        unreadNotificationIds: getUnreadNotificationIds(payload.data),
      };
    }
    case NotificationsActionTypes.MARKED_AS_READ: {
      if (error || !state.unreadNotificationsCount) {
        return state;
      }
      const { notificationsInApp } = payload as Pick<NotificationsMarkedAsReadActionSuccess, 'payload'>['payload'];
      const newUnreadNotificationsCount = state.unreadNotificationsCount - notificationsInApp.length;
      return {
        ...state,
        unreadNotificationIds: null,
        unreadNotificationsCount: newUnreadNotificationsCount || 0,
      };
    }

    case NotificationsActionTypes.GET_FLOATING_SUCCESS: {
      return { ...state, floating: payload };
    }

    // Optimistic update. TODO Undo logic for error action.
    case NotificationsActionTypes.FLOATING_MARK_AS_READ_REQUEST: {
      const nextFloatingState = { ...state.floating };

      payload.forEach(id => {
        delete nextFloatingState[id];
      });

      return { ...state, floating: nextFloatingState };
    }

    default:
      return state;
  }
};
