import { ReactNode } from 'react';
import { withTranslation, TranslateInterface } from 'utils/translation';
import { LoadingState, LOADING_STATES } from 'services/loadingState';
import { Alert } from 'ui-library/alert';

interface Props extends TranslateInterface {
  renderLoading: () => JSX.Element | null;
  loadingState: LoadingState | null;
  children: ReactNode;
}

const LoadingStatesLoader = (props: Props): JSX.Element | null => {
  const { renderLoading, children, loadingState, t } = props;

  if (loadingState?.state === LOADING_STATES.error) {
    return (
      <Alert type={Alert.ALERT_TYPES.ERROR}>
        {loadingState?.error?.message ?? t('errors:asyncValidation.unknownError')}
      </Alert>
    );
  }

  if (loadingState?.state === LOADING_STATES.success) {
    return <>{children}</>;
  }

  return renderLoading();
};

export default withTranslation('errors')(LoadingStatesLoader);
