import actionCreator from 'helpers/actionCreator';
import { GetAchievementsSuccess, GetAchievementsError } from './types';
import endpoints from '../endpoints';
import actionTypes from './actionTypes';
import normalizer from './normalizer';

function getAchievementsSuccess({ data }): GetAchievementsSuccess {
  return actionCreator.createAction(actionTypes.GET_ACHIEVEMENTS_SUCCESS, normalizer.getAchievementsSuccess(data));
}

function getAchievementsError({ errorData }): GetAchievementsError {
  return actionCreator.createErrorAction(actionTypes.GET_ACHIEVEMENTS_ERROR, errorData);
}

const getAchievements = () =>
  actionCreator.createAsyncAction({
    method: 'get',
    endpoint: endpoints.ACHIEVEMENTS.GET,
    errorBinding: getAchievementsError,
    loadingAction: actionTypes.GET_ACHIEVEMENTS_REQUEST,
    successBinding: getAchievementsSuccess,
  });

export default {
  getAchievements,
};
