export const omitProperties = <OriginDataType extends Record<string, any>, DestinationType extends Record<string, any>>(
  original: OriginDataType,
  properties: (keyof OriginDataType)[],
): DestinationType => {
  return Object.fromEntries(
    Object.entries(original).filter(([k]) => {
      return properties.every(propertie => propertie !== k);
    }),
  ) as DestinationType; // TS can't deduce that we are filtering only propertis from a record, so we are casting it.
};
