import stateCreator from 'helpers/stateCreator';
import type { OriginalsPublicState, OriginalsPublicActions } from './types';
import { OriginalsPublicActionTypes } from './actionTypes';
import { OriginalsPublicStateByComponentKey } from './types';

function getOriginalsPublicStateByComponentKey(
  state: OriginalsPublicStateByComponentKey = {},
  action: OriginalsPublicActions,
): OriginalsPublicStateByComponentKey {
  switch (action.type) {
    case OriginalsPublicActionTypes.FETCH_PUBLIC_ORIGINALS_REQUEST: {
      return stateCreator.getAsyncLoadingState(state, {
        filters: action.payload.filters,
      });
    }

    case OriginalsPublicActionTypes.FETCH_PUBLIC_ORIGINALS_ERROR: {
      return stateCreator.getAsyncErrorState(action, state);
    }
    case OriginalsPublicActionTypes.FETCH_PUBLIC_ORIGINALS_SUCCESS: {
      const { payload } = action;
      return stateCreator.getInitialAsyncState(state, {
        filters: payload.filters,
        frame: payload.frame,
        originals: payload.originals,
      });
    }
    default:
      return state;
  }
}

export const reducer = (state: OriginalsPublicState = {}, action: OriginalsPublicActions): OriginalsPublicState => {
  switch (action.type) {
    case OriginalsPublicActionTypes.FETCH_PUBLIC_ORIGINALS_REQUEST:
    case OriginalsPublicActionTypes.FETCH_PUBLIC_ORIGINALS_SUCCESS:
    case OriginalsPublicActionTypes.FETCH_PUBLIC_ORIGINALS_ERROR: {
      return stateCreator.getUpdatedStateByKey(
        state,
        action,
        action.metadata.componentKey,
        getOriginalsPublicStateByComponentKey,
      );
    }
    default:
      return state;
  }
};
