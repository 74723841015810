import { paginationStateNormalizer } from 'services/pagination';
import { PaginationConfiguration } from 'services/pagination/typesTS';
import { FixMe } from 'types/indexTS';
import { PaginatedQuery } from './types';
import { TagsActionTypes } from '../actionTypes';

const PAGINATION_STATE_NORMALIZER_CONFIGURATION: PaginationConfiguration<PaginatedQuery> = {
  initialQuery: {
    limit: 50,
    normalizedLabel: '',
    restricted: true,
  },
};

const paginatedState = paginationStateNormalizer.getInitial(PAGINATION_STATE_NORMALIZER_CONFIGURATION);

export default (state: FixMe = paginatedState, action: FixMe) => {
  const { payload } = action;
  switch (action.type) {
    case TagsActionTypes.TAGS_PAGINATION_REQUEST: {
      return paginationStateNormalizer.getRequest(state, payload, PAGINATION_STATE_NORMALIZER_CONFIGURATION);
    }

    case TagsActionTypes.TAGS_PAGINATION_ERROR: {
      return paginationStateNormalizer.getReceivedError(action, state);
    }

    case TagsActionTypes.TAGS_PAGINATION_SUCCESS: {
      return paginationStateNormalizer.getReceived(payload, PAGINATION_STATE_NORMALIZER_CONFIGURATION);
    }

    case TagsActionTypes.TAGS_PAGINATION_NEXT_REQUEST: {
      return paginationStateNormalizer.getFetchingNextItems(state, payload.cancelToken);
    }

    case TagsActionTypes.TAGS_PAGINATION_NEXT_SUCCESS: {
      return paginationStateNormalizer.getNextItemsReceived(state, payload);
    }

    case TagsActionTypes.TAGS_PAGINATION_NEXT_ERROR: {
      return paginationStateNormalizer.getNextReceivedError(state);
    }

    default:
      return state;
  }
};
