import axios, { CancelTokenSource, CancelToken, ResponseType } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';
import { captureApiError } from 'utils/sentry';
import config from 'config';
import type {
  AsyncActionParams,
  ApiErrorResponse,
  ApiErrorResponseData,
  ApiError,
  ApiMethod,
  ApiSuccessResponse,
  CancelRequestInfo,
  CancelError,
  AsyncActionUrlParameters,
  AsyncActionQueryParameters,
  AsyncLoadingAction,
  ActionSuccess,
} from 'types/indexTS';
import type { Experiment } from 'model/indexTS';
import experimentManager from 'utils/experiments-manager';
import { ReduxStore } from 'store/types';
import { ActionError, FixMe, isOfType } from 'types/indexTS';

type FetchOptions = {
  baseURL: string;
  headers: Record<string, string | undefined>;
  method: ApiMethod;
  url: string;
  data?: Record<string, unknown>;
  params?: Record<string, unknown>;
  responseType: ResponseType;
  cancelToken?: CancelToken;
};

const baseApiUrl = config.streamloots.api;

const sendLoadingAction = (dispatch: Dispatch, loadingAction: AsyncLoadingAction, cancelToken: CancelRequestInfo) => {
  if (typeof loadingAction === 'string') {
    dispatch({
      error: false,
      type: loadingAction,
      payload: {
        cancelToken,
      },
    });
  } else {
    dispatch(loadingAction(cancelToken));
  }
};

export const getEndPoint = (
  originalEndPoint: string,
  urlParameters: AsyncActionUrlParameters = {},
  queryParameters: AsyncActionQueryParameters = {},
): string => {
  let endpoint = originalEndPoint;

  Object.keys(urlParameters).forEach(paramName => {
    const replacePattern = new RegExp(`:${paramName}`, 'g');
    endpoint = endpoint.replace(replacePattern, urlParameters[paramName] as string);
  });

  let isFirstQueryParam = true;
  Object.keys(queryParameters).forEach(paramName => {
    if (isFirstQueryParam) {
      isFirstQueryParam = false;
      endpoint = `${endpoint}?`;
    } else {
      endpoint = `${endpoint}&`;
    }
    endpoint = `${endpoint + paramName}=${queryParameters[paramName]}`;
  });

  return endpoint;
};

const getFetchOptions = (
  url: string,
  method: ApiMethod,
  data: Record<string, unknown> = {},
  authToken?: string,
  experiments?: Experiment[] | null,
  language?: string,
  cancelToken?: CancelRequestInfo,
  contentType?: string,
): FetchOptions => {
  const fetchOptions: FetchOptions = {
    baseURL: baseApiUrl,
    headers: {
      'Accept': 'application/json',
      'Accept-Language': language,
    },
    method,
    url,
    responseType: 'json',
    cancelToken: cancelToken?.token,
  };

  if (contentType) {
    fetchOptions.headers['Content-Type'] = contentType;
    fetchOptions.data = data;
  }

  if (contentType === 'application/pdf') {
    fetchOptions.responseType = 'blob';
  }

  if (contentType && contentType.includes('text')) {
    fetchOptions.responseType = 'text';
  }

  if (method === 'post' || method === 'put' || method === 'patch' || method === 'delete') {
    fetchOptions.data = data;
  } else if (method === 'get') {
    fetchOptions.params = data;
  }

  if (authToken) {
    fetchOptions.headers.Authorization = `Bearer ${authToken}`;
  }
  if (experiments) {
    const activeExperiments = experimentManager.getActiveExperimentsFromUserExperiments(experiments);
    fetchOptions.headers.Experiments = JSON.stringify(activeExperiments);
  }

  return fetchOptions;
};

const getErrorFromResponse = (errorResponse: ApiErrorResponseData): ApiError => {
  const { data } = errorResponse;
  if (errorResponse.status === 404) {
    return {
      code: 404,
      message: 'Not Found',
      errors: [],
    };
  }

  if (!data || !data.error) {
    return {
      code: errorResponse.status,
      errors: [],
      message: errorResponse.statusText,
    };
  }

  if (!data.error.errors) {
    return data.error;
  }

  const error = data.error.errors[0];

  return { ...data.error, message: error.message };
};

const cancelPreviousRequest = (
  state: ReduxStore,
  cancelTokenSelector: (state: ReduxStore) => CancelTokenSource | undefined,
) => {
  const cancelToken = cancelTokenSelector(state);
  if (!cancelToken) {
    return;
  }
  cancelToken.cancel();
};

export default <
  Data,
  AdditionalData extends Record<string, FixMe>,
  Parameters extends Record<string, FixMe>,
  UrlParameters extends Record<string, FixMe>,
  SuccessAction extends ActionSuccess<any, any, any>,
  ErrorAction extends ActionError<any, any>
>(
  actionParams: AsyncActionParams<Data, AdditionalData, Parameters, UrlParameters, SuccessAction, ErrorAction>,
): FixMe => (dispatch: FixMe, getState: () => ReduxStore) => {
  const {
    additionalData,
    absoluteEndPoint,
    allowAnonymous,
    contentType,
    endpoint,
    errorBinding,
    loadingAction,
    method,
    parameters = {},
    successBinding,
    successAsyncSideEffects,
    urlParameters = {},
    queryParameters = {},
    cancelTokenSelector,
    includeExperiments,
  } = actionParams;
  const storeState = getState();

  if (typeof cancelTokenSelector === 'function') {
    cancelPreviousRequest(storeState, cancelTokenSelector);
  }

  const cancelToken = axios.CancelToken.source();

  if (loadingAction) {
    sendLoadingAction(dispatch, loadingAction, cancelToken);
  }

  const {
    auth: { authToken },
    culture: { language },
    user: { experiments },
  } = storeState;

  const url = absoluteEndPoint || getEndPoint(endpoint || '', urlParameters, queryParameters);

  const axiosConfig = getFetchOptions(
    url,
    method,
    parameters,
    authToken,
    includeExperiments ? experiments : null,
    language,
    cancelToken,
    contentType,
  );

  return axios(axiosConfig)
    .then((response: ApiSuccessResponse) => {
      if (successAsyncSideEffects) {
        successAsyncSideEffects.forEach(action => {
          dispatch(action);
        });
      }

      return dispatch(
        successBinding({
          additionalData: additionalData as FixMe,
          parameters: parameters as FixMe,
          response,
          data: response.data,
          urlParameters: urlParameters as FixMe,
        }),
      );
    })
    .catch((err: CancelError | ApiErrorResponse | Error) => {
      const showConsoleError = __DEV__ && !config.isTest;
      if (showConsoleError) {
        console.error(err);
      }

      if (isOfType<CancelError>(err, '__CANCEL__')) {
        return dispatch(
          errorBinding({
            additionalData: additionalData as FixMe,
            errorData: { isCancelError: true, message: err.message },
            errorMessage: err.message,
            parameters: parameters as FixMe,
            response: err.response,
            urlParameters: urlParameters as FixMe,
          }),
        );
      }

      if (!isOfType<ApiErrorResponse>(err, 'response')) {
        return dispatch(
          errorBinding({
            additionalData: additionalData as FixMe,
            errorData: err,
            errorMessage: err.message,
            parameters: parameters as FixMe,
            urlParameters: urlParameters as FixMe,
          }),
        );
      }

      const { response } = err;

      if (showConsoleError) {
        console.error(response.status);
      }

      if (response.status === 401 && !allowAnonymous) {
        // TODO
        dispatch({
          type: 'auth/TOKEN_INVALIDATED',
        });
      }

      captureApiError(response.data?.error, parameters);

      const error = getErrorFromResponse(response);
      return dispatch(
        errorBinding({
          additionalData: additionalData as FixMe,
          errorData: error,
          errorMessage: error.message,
          parameters: parameters as FixMe,
          response,
          urlParameters: urlParameters as FixMe,
        }),
      );
    });
};
