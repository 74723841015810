import stateCreator from 'helpers/stateCreator';
import { paginationStateNormalizer } from 'services/pagination';
import type { PagesState } from './typesTS';
import { PagesActionTypes } from './actionTypes';
import { DEFAULT_PAGES_QUERY, PAGINATION_STATE_NORMALIZER_CONFIGURATION } from './constants';
import { PagesStateByComponentKey } from './typesTS';

const getPagesStateByKey = (
  state: PagesStateByComponentKey = {
    query: DEFAULT_PAGES_QUERY,
  },
  action,
): PagesStateByComponentKey => {
  const { error, type, payload } = action;
  switch (type) {
    case PagesActionTypes.RECEIVED: {
      if (error) {
        return {
          ...state,
          ...paginationStateNormalizer.getReceivedError(action, state),
        };
      }
      const newPaginationState = paginationStateNormalizer.getReceived(
        payload,
        PAGINATION_STATE_NORMALIZER_CONFIGURATION,
      );
      return {
        ...state,
        ...newPaginationState,
      };
    }
    case PagesActionTypes.REQUEST:
      return paginationStateNormalizer.getRequest(state, payload, PAGINATION_STATE_NORMALIZER_CONFIGURATION);
    case PagesActionTypes.NEXT_REQUEST:
      return paginationStateNormalizer.getFetchingNextItems(state, payload.cancelToken);
    case PagesActionTypes.NEXT_RECEIVED: {
      if (error) {
        return paginationStateNormalizer.getNextReceivedError(state);
      }
      return paginationStateNormalizer.getNextItemsReceived(state, payload);
    }
    default:
      return state;
  }
};

export const reducer = (state: PagesState = {}, action): PagesState => {
  const { type, metadata } = action;
  switch (type) {
    case PagesActionTypes.INITIALIZED:
    case PagesActionTypes.RECEIVED:
    case PagesActionTypes.REQUEST:
    case PagesActionTypes.NEXT_REQUEST:
    case PagesActionTypes.NEXT_RECEIVED: {
      const { componentKey } = metadata;
      return stateCreator.getUpdatedStateByKey(state, action, componentKey, getPagesStateByKey);
    }
    case PagesActionTypes.DESTROYED: {
      const { componentKey } = metadata;
      const stateCopy = { ...state };
      delete stateCopy[componentKey];
      return stateCopy;
    }
    default:
      return state;
  }
};
