import { ValueOf } from 'types/indexTS';
import { Languages } from '@streamloots/streamloots-types';
import { CultureState } from './types';
import actionTypes from './actionTypes';

export const getInitialState = (language: Languages = Languages.English, IPCountry = 'US'): CultureState => ({
  language,
  IPCountry,
});

export default (
  state: CultureState = getInitialState(),
  action: {
    type: ValueOf<typeof actionTypes>;
    payload: {
      /* eslint-disable-next-line */
      country_code: string;
    };
  },
): CultureState => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GET_HEARTBEAT_SUCCESS: {
      return {
        ...state,
        IPCountry: payload.country_code,
      };
    }
    default:
      return state;
  }
};
