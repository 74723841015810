import { FullLayout } from 'scenes/layout';
import ConnectedValidateEmail from './ConnectedValidateEmail';

export const ValidateEmailPage = ({ match }) => {
  return (
    <FullLayout>
      <ConnectedValidateEmail match={match} />
    </FullLayout>
  );
};
