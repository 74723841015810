export const SETTINGS_PREFIX = {
  GENERAL: 'streamloots.settings.general.',
  ADVANCED: 'streamloots.settings.advanced.',
  COMMON: 'streamloots.settings.common.',
  RARE: 'streamloots.settings.rare.',
  EPIC: 'streamloots.settings.epic.',
  LEGENDARY: 'streamloots.settings.legendary.',
};

export const SETTINGS_GROUP_PREFIX = {
  MEDIA: 'media.',
  SOUND: 'sound.',
  TEXT: 'text.',
  TEXT_TO_SPEECH: 'textToSpeech.',
  ALERT_EFFECT_SOUND_CONFIGURATION: 'alertEffect.',
  SUBSCRIPTION: 'subscription.',
};

export const TEXT_CONFIGURATION_PREFIX = `${SETTINGS_PREFIX.GENERAL}${SETTINGS_GROUP_PREFIX.TEXT}`;
export const FONT_FAMILY_NAME = `${TEXT_CONFIGURATION_PREFIX}fontFamily`;
export const FONT_WEIGHT_NAME = `${TEXT_CONFIGURATION_PREFIX}fontWeight`;
export const FONT_SIZE_NAME = `${TEXT_CONFIGURATION_PREFIX}fontSize`;
export const FONT_COLOR_NAME = `${TEXT_CONFIGURATION_PREFIX}color`;

export const ALERT_PROVIDERS_FORM_KEY = 'alertProviders';

export const ALERT_PROVIDERS_SECTIONS = {
  text: 'changeTextConfiguration',
  tts: 'ttsTip',
  profanity: 'profanityTip',
  powers: 'powerSoundTip',
  legendary: 'legendary',
  automaticPromotion: 'automaticPromotion',
  cardAndPacks: 'cardAndPacks',
  subscriptions: 'subscriptions',
};
