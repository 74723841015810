import { ArticleKey } from './articles-keys';

interface Mapping {
  en: { [key in ArticleKey]: string };
  es: { [key in ArticleKey]: string };
}

const mapping: Mapping = {
  en: {
    ACHIEVEMENTS: 'https://help.streamloots.com/en/article/achievements-pb8x9j/?1606761143906',
    POWERS: 'https://help.streamloots.com/en/article/streamloots-powers-1vtv0yc/?1606760941564',
    BALANCE: 'https://help.streamloots.com/en/article/earnings-balance-current-plan-next-payout-and-currency-1r8gn9j/',
    COOLDOWN: 'https://help.streamloots.com/en/article/cooldown-configuration-16wf87h/',
    RANKINGS: 'https://help.streamloots.com/en/article/rankings-18rrev8/',
    USER_PROFILE: 'https://help.streamloots.com/en/category/user-profile-1m76vzp/',
    INTEGRATIONS:
      'https://help.streamloots.com/en/article/how-to-connect-my-account-to-twitch-mixer-youtube-or-facebook-13ag5z1/',
    MAIN_STREAMING_PLATFORM:
      'https://help.streamloots.com/en/article/how-to-change-my-main-streaming-platform-in-streamloots-15hc0u9/',
    COLLECTIONS: 'https://support.streamloots.com/hc/en-us/categories/360003683178-Cards-and-Collections',
    HYPERWALLET:
      'https://help.streamloots.com/en/article/streamloots-pay-new-payment-portal-transition-and-one-time-configuration-1plxxca/',
    SALES_AND_PAYMENTS: 'https://help.streamloots.com/en/category/sales-and-payments-19fxhy2/',
    CARD_TYPE_CONFIGURATION: 'https://help.streamloots.com/en/article/card-types-limited-and-fragmented-19p5gzb',
    HOLD_MONEY: 'https://support.streamloots.com/hc/en-us/articles/360020539778-Fraudulent-Payments-and-Disputes',
    PROBABILITIES:
      'https://help.streamloots.com/en/article/how-do-card-probabilities-work-card-rarities-and-probabilities-19zrpes/',
    ALERT_POWERS_CONFIGURATION:
      'https://help.streamloots.com/en/article/how-to-configure-the-streamloots-powers-sound-14v8yvk',
    CHRISTMAS_CAMPAIGN: 'https://blog.streamloots.com/streamloots-santa/',
    TIMER: 'https://help.streamloots.com/en/article/whats-the-card-timer-7i0n0g',
    AUCTION:
      'https://support.streamloots.com/hc/en-us/articles/4402776702225-Create-your-first-Streamloots-Auction-sucessfully',
    CRAFTING: 'https://support.streamloots.com/hc/en-us/articles/360020539438-Crafting-Mode',
    PAYOUT_FREQUENCY:
      'https://support.streamloots.com/hc/en-us/articles/360020388017-Where-Do-I-See-Money-Made-From-Sales-and-When-Do-I-Get-Paid-',
    BILLING_INFORMATION:
      'https://support.streamloots.com/hc/en-us/articles/360020387997-What-Should-My-Tax-Accountant-Know-About-Streamloots-',
    HOW_TO_PARTNER:
      'https://support.streamloots.com/hc/en-us/articles/360020539458-How-Can-I-Become-Affiliate-and-Partner-',
    COUPONS: 'https://support.streamloots.com/hc/en-us/articles/360020539178-Coupons-and-configuration',
    COMMUNITY_RANKING: 'https://blog.streamloots.com/how-to-climb-the-streamloots-community-rankings/',
    HOW_TO_ADD_BROWSER_SOURCES: 'https://support.streamloots.com/hc/en-us/articles/4407933149585-Adding-Alerts-in-OBS',
    OBS_INTEGRATION:
      'https://support.streamloots.com/hc/en-us/articles/4408957235601-Downloading-and-Installing-OBS-Studio-Plugin',
    ADD_ALERTS_OBS: 'https://support.streamloots.com/hc/en-us/articles/4407933149585-Adding-Alerts-in-OBS',
    COUPONS_HOW_TO_USE_THEM: 'https://blog.streamloots.com/benefits-of-coupons-and-promotion-widget/',
  },
  es: {
    ACHIEVEMENTS: 'https://help.streamloots.com/es/article/logros-en4sv4/?1606761219314',
    POWERS: 'https://help.streamloots.com/es/article/streamloots-powers-1ygibly/?1606761177829',
    BALANCE:
      'https://help.streamloots.com/es/article/ganancias-dinero-acumulado-balance-plan-actual-proximos-pagos-y-moneda-js7hxk/',
    COOLDOWN: 'https://help.streamloots.com/es/article/configurar-cooldown-8ifoni/',
    RANKINGS: 'https://help.streamloots.com/es/article/rankings-3c36bi/',
    USER_PROFILE: 'https://help.streamloots.com/es/category/perfil-de-usuario-1dpgesm/',
    INTEGRATIONS:
      'https://help.streamloots.com/es/article/como-conectar-mi-cuenta-de-streamloots-a-twitch-mixer-youtube-o-facebook-sfl4f/',
    MAIN_STREAMING_PLATFORM:
      'https://help.streamloots.com/es/article/como-cambiar-mi-plataforma-de-streaming-principal-en-streamloots-iny0ao/',
    COLLECTIONS: 'https://support.streamloots.com/hc/es/categories/360003683178-Cartas-y-Colecciones',
    HYPERWALLET:
      'https://help.streamloots.com/es/article/streamloots-pay-configuracion-del-nuevo-portal-de-pago-1lidmy6/',
    SALES_AND_PAYMENTS: 'https://help.streamloots.com/es/category/ventas-y-pagos-1jmzt5e/',
    CARD_TYPE_CONFIGURATION:
      'https://help.streamloots.com/es/article/tipos-de-cartas-limitadas-y-fragmentadas-49zs1y/?1595948712034',
    HOLD_MONEY: 'https://support.streamloots.com/hc/es/articles/360020539778-Pagos-fraudulentos-y-disputas',
    PROBABILITIES:
      'https://help.streamloots.com/es/article/como-funcionan-las-probabilidades-rarezas-y-probabilidad-de-una-carta-igrxn2/',
    ALERT_POWERS_CONFIGURATION:
      'https://help.streamloots.com/es/article/como-configurar-el-sonido-de-los-powers-e86tf7',
    CHRISTMAS_CAMPAIGN: 'https://blog.streamloots.com/es/santa-streamloots/',
    TIMER: 'https://help.streamloots.com/es/article/que-es-el-temporizador-de-cartas-jqnxy8',
    AUCTION:
      'https://support.streamloots.com/hc/es/articles/4402776702225-Crea-tu-primera-Streamloots-Subasta-con-%C3%A9xito-',
    CRAFTING: 'https://support.streamloots.com/hc/es/articles/360020539438-Modo-Construcci%C3%B3n',
    PAYOUT_FREQUENCY:
      'https://support.streamloots.com/hc/es/articles/360020388017--D%C3%B3nde-veo-el-dinero-de-mis-ventas-y-cu%C3%A1ndo-cobro-',
    BILLING_INFORMATION:
      'https://support.streamloots.com/hc/es/articles/360020387997--Qu%C3%A9-debe-saber-mi-asesor-contable-sobre-Streamloots-',
    HOW_TO_PARTNER:
      'https://support.streamloots.com/hc/es/articles/360020539458--C%C3%B3mo-puedo-llegar-a-ser-afiliado-y-partner-',
    COUPONS: 'https://support.streamloots.com/hc/es/articles/360020539178-Tipos-de-cupones-y-configuraci%C3%B3n',
    COMMUNITY_RANKING: 'https://blog.streamloots.com/es/como-subir-de-division-en-el-ranking-de-la-comunidad/',
    HOW_TO_ADD_BROWSER_SOURCES:
      'https://support.streamloots.com/hc/es/articles/4407933149585-A%C3%B1adir-alertas-en-OBS',
    OBS_INTEGRATION:
      'https://support.streamloots.com/hc/es/articles/4408957235601-Descargar-e-instalar-el-plugin-de-OBS-Studio',
    ADD_ALERTS_OBS: 'https://support.streamloots.com/hc/es/articles/4407933149585-A%C3%B1adir-alertas-en-OBS',
    COUPONS_HOW_TO_USE_THEM: 'https://blog.streamloots.com/es/ventajas-de-cupones-y-widget-de-cupones/',
  },
};
export default mapping;
