import loadable from 'components/dynamic-import';

export type { ChristmasExternalProps } from './types';

export const BattlePass = loadable(() => import('./BattlePass'));
export const ViewerLandingPage = loadable(() => import('./ViewerLandingPage'));
export const BattlePassAnnouncement = loadable(() => import('./BattlePassAnnouncement'));
export const BattlePassBoostProgress = loadable(() => import('./BattlePassBoostProgress'));
export const BattlePassUnlockedAlert = loadable(() => import('./BattlePassUnlockedAlert'));

export { useOpenPrizesModal } from './usoOpenPrizesModal';
