import { useContext, useEffect, useCallback, FunctionComponent } from 'react';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import type { User } from 'model/indexTS';
import type { FetchNotifications } from 'services/notifications';
import { useInterval } from 'hooks/use-interval';
import { SidebarOpenerContext } from 'contexts/sidebar-opener';
import { NOTIFICATIONS_QUERY, notificationsActions, notificationsSelectors } from 'services/notifications';
import { userSelectors } from 'services/user';
import { pageSelectors } from 'services/page';
import { permissionsSelectors } from 'services/permissions';
import { Notifications } from 'components/notifications';
import UserAuthenticatedSection from './UserAuthenticatedSection';

interface MapDispatchToProps {
  fetchNotifications: FetchNotifications;
}

interface MapStateToProps {
  isStaff: boolean;
  unreadNotificationsCount: number;
  user: User | null;
  showOnboarding: boolean;
  inOwnPage: boolean;
}

interface ConnectedAuthenticatedSectionProps extends MapStateToProps, MapDispatchToProps {}

const NOTIFICATIONS_INTERVAL = 120000;

const ConnectedAuthenticatedSection = ({
  fetchNotifications,
  inOwnPage,
  isStaff,
  showOnboarding,
  unreadNotificationsCount,
  user,
}: ConnectedAuthenticatedSectionProps): JSX.Element | null => {
  const { open } = useContext(SidebarOpenerContext);

  const openNotifications = useCallback(() => {
    open({ component: <Notifications /> });
  }, [open]);

  const fetchNewNotifications = useCallback(() => {
    if (document && document.visibilityState !== 'visible') {
      return;
    }

    fetchNotifications(NOTIFICATIONS_QUERY);
  }, [fetchNotifications]);

  useEffect(() => {
    if (!unreadNotificationsCount) {
      fetchNotifications(NOTIFICATIONS_QUERY);
    }
    // eslint-disable-next-line
  }, []);

  useInterval(fetchNewNotifications, NOTIFICATIONS_INTERVAL);

  if (!user) {
    return null;
  }

  return (
    <UserAuthenticatedSection
      inOwnPage={inOwnPage}
      isStaff={isStaff}
      openNotifications={openNotifications}
      showOnboarding={showOnboarding}
      unreadNotificationsCount={unreadNotificationsCount > 9 ? '+9' : unreadNotificationsCount}
      user={user}
    />
  );
};

const mapStateToProps = (state): MapStateToProps => {
  const inOwnPage = pageSelectors.inPageContext(state) && pageSelectors.isOwner(state);

  return {
    unreadNotificationsCount: notificationsSelectors.unreadNotificationsCount(state),
    user: userSelectors.user(state),
    showOnboarding: userSelectors.showOnboarding(state),
    isStaff: permissionsSelectors.isStaff(state),
    inOwnPage,
  };
};

export default compose<FunctionComponent>(
  connect(mapStateToProps, {
    fetchNotifications: notificationsActions.fetchNotificationsExceptFloating,
  }),
)(ConnectedAuthenticatedSection);
