import { FixMe } from 'types/indexTS';
import { ActionTypes } from './actionTypes';
import { PowersState } from './types';

const initialState: PowersState = {};

export default function alertReducer(state: PowersState = initialState, action: FixMe): PowersState {
  switch (action.type) {
    case ActionTypes.GET_EFFECTS_SUCCESS: {
      return { ...state, powers: action.payload };
    }

    default:
      return state;
  }
}
