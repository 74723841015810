import loadable from 'components/dynamic-import';

export { CardsModulePublicLandingPage } from './CardsModulePublicLandingPage';
export { SubscriptionsModulePublicLandingPage } from './SubscriptionsModulePublicLandingPage';
export { AuctionsModulePublicLandingPage } from './AuctionsModulePublicLandingPage';
export { MarathonsModulePublicLandingPage } from './MarathonsModulePublicLandingPage';
// export { DonationsModuleLandingPage } from './DonationsModuleLandingPage';

export const CardsModulesPage = loadable(() => import('./CardsModulesPage'));
export const AuctionModulesPage = loadable(() => import('./AuctionModulesPage'));
export const SubscriptionModulesPage = loadable(() => import('./SubscriptionsModulesPage'));
export const MarathonsModulesPage = loadable(() => import('./MarathonsModulesPage'));
// export const DonationsModulesPage = loadable(() => import('./DonationsModulePage'));
