import config from 'config';
import { CULTURE_COOKIE_NAME, DEFAULT_CULTURE, LANGUAGES } from './constants';

const fallbackLng = DEFAULT_CULTURE;
export const defaultNS = 'common';

export default {
  detection: {
    caches: ['localStorage', 'cookie'],
    lookupQuerystring: 'lng',
    lookupCookie: CULTURE_COOKIE_NAME,
    cookieDomain: config.streamloots.domain,
    cookieSameSite: 'lax',
    ignoreCase: true,
    order: ['path', 'querystring', 'cookie', 'header'],
  },
  defaultNS: 'common',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
  fallbackLng,
  ns: [
    'achievements',
    'adminCards',
    'adminSets',
    'adminPredefinedCards',
    'adminNotifications',
    'alert',
    'brand',
    'campaings',
    'card',
    'cardCreator',
    'collection',
    'common',
    'cooldown',
    'coupons',
    'dashboard',
    'disenchant',
    'errors',
    'notifications',
    'gallery',
    'integrations',
    'lootPacks',
    'pageTypeInfo',
    'powers',
    'probabilities',
    'ranking',
    'transactions',
    'userProfile',
    'onboarding',
    'goals',
    'auction',
    'timer',
    'streamdeck',
    'communityRanking',
    'activityFeed',
    'obs',
    'pageSubscription',
    'publicUserPage',
    'marathon',
    'landings',
    'communication',
    'my-viewers',
    'pageFeed',
    'pageDonations',
    'checkout',
  ],
  saveMissing: false,
  supportedLngs: Object.values(LANGUAGES),
  appendNamespaceToCIMode: true,
};
