import { useState, useEffect } from 'react';
import ClassNames from '@streamloots/classnames';
import type { FormElementBaseProps } from './types';
import theme from './form.scss';

const classNames = ClassNames(theme);

export interface PushInputProps extends FormElementBaseProps {
  type?: 'checkbox' | 'radio';
  checked?: boolean;
  big?: boolean;
  labelDescriptionClass?: string;
  inline?: boolean;
  colorCheckedCheckbox?: string;
  reverse?: boolean;
  checkboxClassname?: string;
}

export const PushInput = ({
  checked,
  name,
  error,
  id,
  inline,
  big,
  labelDescriptionClass = '',
  colorCheckedCheckbox = '',
  checkboxClassname = '',
  className = '',
  labelClassName = '',
  label = '',
  type = 'checkbox',
  reverse,
  ...rest
}: PushInputProps) => {
  const [isChecked, setChecked] = useState(checked);

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  return (
    <>
      <label
        className={classNames({
          'checkbox': true,
          'label__error': error,
          'checkbox__big': big,
          'checkbox--reverse': reverse,
          'checkbox__inline': inline,
          [labelClassName]: Boolean(labelClassName),
        })}
        htmlFor={id}
      >
        <input
          className={classNames({
            checkbox__input: true,
            [className]: className,
          })}
          type={type}
          checked={isChecked}
          name={name}
          id={id}
          {...rest}
        />
        <span
          className={classNames({
            checkbox__checkbox: true,
            [checkboxClassname]: Boolean(checkboxClassname),
            [colorCheckedCheckbox]: isChecked && Boolean(colorCheckedCheckbox),
          })}
        />
        <span
          className={classNames({
            checkbox__label: true,
            [labelDescriptionClass]: Boolean(labelDescriptionClass),
          })}
        >
          {label}
        </span>
      </label>
      {error && <p className={classNames('label__error')}>{error}</p>}
    </>
  );
};
