import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import ClassNames from '@streamloots/classnames';
import Icon from 'ui-library/icons';
import theme from './menu-content-components.scss';

const classNames = ClassNames(theme);

type MenuButtonProps = {
  className?: string;
  separator?: boolean;
  icon?: string;
  children: ReactNode;
  onClick?: () => void;
  to?: string;
  exact?: boolean;
  dataId?: string;
  externalLink?: string;
};

export const MenuLink = ({
  children,
  className = '',
  icon,
  separator,
  to,
  exact,
  dataId,
  externalLink,
  onClick,
  ...rest
}: MenuButtonProps): JSX.Element => {
  const buttonClassNames = classNames({
    'menu__link': true,
    'menu__link--separator': separator,
    [className]: Boolean(className),
  });

  const renderContent = () => (
    <>
      {icon && <Icon className={theme.menu__link__icon} id={icon} />} {children}
    </>
  );

  if (to) {
    return (
      <NavLink
        to={to}
        exact={exact}
        activeClassName={classNames('menu__link--active')}
        className={buttonClassNames}
        onClick={onClick}
      >
        {renderContent()}
      </NavLink>
    );
  }

  if (externalLink) {
    return (
      <a href={externalLink} rel="noopener noreferrer" target="_blank" className={buttonClassNames} onClick={onClick}>
        {renderContent()}
      </a>
    );
  }

  return (
    <button className={buttonClassNames} {...rest} data-id={dataId} type="button" onClick={onClick}>
      {renderContent()}
    </button>
  );
};
