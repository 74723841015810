import ClassNames from '@streamloots/classnames';
import { AsyncActionState } from 'types/indexTS';
import { Trans, useTranslation } from 'utils/translation';
import Spinner from 'ui-library/loading-spinner';
import { Alert } from 'ui-library/alert';
import { Link } from 'react-router-dom-v5-compat';
import img from 'scenes/auth/assets/kid.jpg';
import BRAND from 'constants/brand';
import { AuthSection } from './AuthSection';
import theme from './auth.scss';

const classNames = ClassNames(theme);

interface AuthenticationResponseProps extends AsyncActionState {
  componentKey: string;
  location: string;
}

export const AuthenticationPageContent = ({
  componentKey,
  location,
  isLoading,
  error,
}: AuthenticationResponseProps): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <div className={classNames('auth__page')}>
      <img className={classNames('auth__logo')} src={BRAND.horizontalWhite} alt="" />
      <p className="margin-bottom">{t('common:authentication.loginMessage')}</p>
      {isLoading && <Spinner fixed opaque />}

      {error && error.message !== 'authentication.errors.USER_DISABLED' && (
        <Alert type={Alert.ALERT_TYPES.ERROR}>{error.message}</Alert>
      )}
      {error && error.message === 'authentication.errors.USER_DISABLED' && (
        <Alert type={Alert.ALERT_TYPES.ERROR}>
          <Trans i18nKey="common:authentication.errors.userDisabled">
            Your account has been suspended. Please contact support
            <Link to={t('common:support.createTicket')}>here</Link>
          </Trans>
        </Alert>
      )}

      <AuthSection componentKey={componentKey} location={location} />
    </div>
  );
};
