import { History } from 'history';
import { usePermissionsSelectors } from 'services/permissions';
import { usePageSelectors } from 'services/page';
import { checkPermissions, permissions } from 'components/permissions';
import { FullLayout } from 'scenes/layout';
import { useTranslation } from 'utils/translation';
import { RouteTabs } from 'components/route-tabs';
import { ModulesPicker } from 'scenes/dashboard/components/modules-picker';
import { PAGE_SETTINGS_TABS } from './page-settings-tabs';

interface PageSettingsProps {
  history: History;
  match: {
    params: {
      tab: string;
    };
  };
}

const PageSettingsPageInternal = ({ history, match }: PageSettingsProps) => {
  const {
    page: { modules },
  } = usePageSelectors();
  const { t } = useTranslation('common');
  const { permissions: currentPermissions } = usePermissionsSelectors();

  return (
    <FullLayout>
      <ModulesPicker />
      <RouteTabs
        tabsInfo={PAGE_SETTINGS_TABS}
        history={history}
        match={match}
        t={t}
        permissions={currentPermissions}
        modules={modules}
      />
    </FullLayout>
  );
};

export const PageSettingsPage = checkPermissions({
  redirectToBasePage: true,
  requiredPermissions: [permissions.PAGE_EDIT, permissions.COOLDOWN_EDIT],
})(PageSettingsPageInternal);
