import { useContext, isValidElement, cloneElement } from 'react';
import ClassNames from '@streamloots/classnames';
import { CSSTransition } from 'react-transition-group';
import { SidebarOpenerContext } from 'contexts/sidebar-opener';
import { useDrag } from 'react-use-gesture';
import { ButtonIcon } from 'ui-library/button';
import ScrollBarPanel from 'components/scrollbar-panel';
import { isMobile } from 'is-mobile';
import { Overlay } from './Overlay';
import theme from './layout.scss';

const classNames = ClassNames(theme);

const LayoutRightSidebar = () => {
  const { attributes, isOpen, component, setComponent, setAttributes, close } = useContext(SidebarOpenerContext);
  const {
    sidebarClassName = '',
    sidebarContentClassName = '',
    toolboxClassName = '',
    sidebarToContent,
    type,
  } = attributes;

  const bindDrag = useDrag(
    state => {
      const isLeft = state.direction[0] === -1;

      if (isLeft || state.distance < 80 || !state.last) {
        return;
      }
      close();
    },
    {
      axis: 'x',
    },
  );

  const transitionClassNames = {
    enter: classNames('righttransition--enter'),
    enterActive: classNames('righttransition--enteractive'),
    exit: classNames('righttransition--exit'),
  };

  const asideClassNames = classNames({
    'layout__sidebar__right': true,
    'layout__content': sidebarToContent,
    [sidebarClassName]: Boolean(sidebarClassName),
    'layout__sidebar__right--medium': type === 'medium',
    'layout__sidebar__right--large': type === 'large',
  });

  const sideBarContentClassNames = classNames({
    layout__sidebar__right__content: true,
    [sidebarContentClassName]: Boolean(sidebarContentClassName),
  });

  const classesToolbox = classNames({
    'layout__sidebar__right__toolbox': true,
    'layout__sidebar__right__toolbox--colorful': true,
    [toolboxClassName]: Boolean(toolboxClassName),
  });

  return (
    <>
      <Overlay onClick={close} show={isOpen && !sidebarToContent} />
      <CSSTransition
        timeout={300}
        mountOnEnter
        unmountOnExit
        in={isOpen}
        classNames={transitionClassNames}
        onExited={() => {
          setComponent(null);
          setAttributes({});
        }}
      >
        <aside className={asideClassNames} {...(isMobile() ? bindDrag() : {})}>
          {!attributes.hideToolbox && (
            <div className={classesToolbox}>
              <ButtonIcon icon="close" onClick={close} className={theme.layout__sidebar__right__close} />
              {attributes.title && <h3 className={theme.layout__sidebar__right__title}>{attributes.title}</h3>}
            </div>
          )}
          <ScrollBarPanel
            renderContent={({ scrollTo }) => {
              if (isValidElement(component)) {
                return cloneElement(component, { scrollTo });
              }
              return null;
            }}
            contentClassName={sideBarContentClassNames}
          />
        </aside>
      </CSSTransition>
    </>
  );
};

export default LayoutRightSidebar;
