import cookies from 'js-cookie';
import config from '../config';

export const SHARED_USER_ID_COOKIE = 'STREAMLOOTS_SHARED_ID';
export const SHARED_SUBDOMAIN_COOKIE_NAME = 'SHARE_TOKEN_STREAMLOOTS';

function setUserAuthenticatedCookies(userId: string, authId: string): void {
  const options = {
    expires: 30,
    domain: config.streamloots.domain,
  };
  cookies.set(SHARED_USER_ID_COOKIE, userId, options);
  cookies.set(SHARED_SUBDOMAIN_COOKIE_NAME, authId, options);
}

function getUserId(): string | null {
  return cookies.get(SHARED_USER_ID_COOKIE);
}

function removeUserAuthenticatedCookie(): void {
  cookies.remove(SHARED_USER_ID_COOKIE);
}

export default {
  setUserAuthenticatedCookies,
  getUserId,
  removeUserAuthenticatedCookies: removeUserAuthenticatedCookie,
};
