import type { PaginationStateNormalizerConfiguration } from 'services/pagination/typesTS';
import type { InvoicesQuery } from './types';

export const DEFAULT_LIMIT = 30;

export const DEFAULT_QUERY: InvoicesQuery = {
  limit: DEFAULT_LIMIT,
  customerType: 'SELLER',
  year: new Date().getFullYear(),
  customerId: '',
};

export const PAGINATION_STATE_NORMALIZER_CONFIGURATION: PaginationStateNormalizerConfiguration<InvoicesQuery> = {
  initialQuery: DEFAULT_QUERY,
};
