import React from 'react';
import ClassNames from '@streamloots/classnames';
import theme from './headers.scss';

const classNames = ClassNames(theme);

type PageHeaderProps = {
  className?: string;
  children: React.ReactNode;
};

const PageHeader = ({ children, className = '' }: PageHeaderProps): JSX.Element => {
  return <h2 className={classNames({ header: true, [className]: className })}>{children}</h2>;
};

export default PageHeader;
