import type { PageBillingState } from './types';
import { PageActionTypes } from '../page';
import { PageBillingActionTypes } from './actionTypes';

export const reducer = (state: PageBillingState = {}, action): PageBillingState => {
  const { type, error, payload } = action;
  switch (type) {
    case PageActionTypes.RECEIVED: {
      if (error || !payload.billingInformation) {
        return {};
      }

      return {
        ...state,
        billingInformation: { ...payload.billingInformation },
      };
    }
    case PageActionTypes.CHANGED: {
      return {};
    }
    case PageBillingActionTypes.UPDATED_SUCCESS: {
      return {
        ...state,
        billingInformation: { ...payload },
      };
    }
    default:
      return state;
  }
};
