import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import { errorMapping } from '../../errorMapping';
import type { CouponRevokedActionSuccess, CouponRevokedActionRequest, CouponRevokedActionError } from './types';
import { CouponActionTypes } from '../../actionTypes';

const couponRevoked = ({ additionalData, urlParameters }): CouponRevokedActionSuccess =>
  actionCreator.createAction(
    CouponActionTypes.REVOKED,
    {
      couponId: urlParameters.couponId,
    },
    additionalData,
  );

const couponNotRevoked = ({ additionalData, errorData }): CouponRevokedActionError =>
  actionCreator.createErrorAction(CouponActionTypes.REVOKED, errorData, errorMapping, additionalData);

const revokingCoupon = (componentKey: string): CouponRevokedActionRequest =>
  actionCreator.createAction(CouponActionTypes.REVOKING, {}, { componentKey });

const revokeCoupon = (componentKey: string, couponId: string) =>
  actionCreator.createAsyncAction({
    additionalData: { componentKey },
    endpoint: endpoints.COUPONS.REVOKE,
    errorBinding: couponNotRevoked,
    loadingAction: () => revokingCoupon(componentKey),
    method: 'post',
    urlParameters: { couponId },
    successBinding: couponRevoked,
  });

export const revokeCouponActions = {
  revokeCoupon,
};
