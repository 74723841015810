import { useMemo } from 'react';
import ClassNames from '@streamloots/classnames';
import { UserSetLoot } from 'model/lootTS';
import { useTranslation } from 'utils/translation';
import trackEventManager from 'utils/event-manager';
import { Alert } from 'ui-library/alert';
import { calculateLootDiscount } from '../../calculateLootDiscount';
import { PackPriceWithDiscount } from '../pack-price-with-discount';
import theme from './section-packs-prices.scss';

const classNames = ClassNames(theme);

export interface SectionPacksPricesProps {
  onLootSelected: (loot: UserSetLoot) => void;
  loots: UserSetLoot[];
  buyableLoots: boolean;
  level: number;
}

const calculateOriginalPrice = (loot: UserSetLoot, quantity: number) => {
  return loot.price * quantity;
};

export const SectionPacksPrices = ({
  loots,
  buyableLoots,
  level,
  onLootSelected,
}: SectionPacksPricesProps): JSX.Element => {
  const { t } = useTranslation('lootPacks');
  const newLoots = useMemo(
    () =>
      loots.map(loot => {
        return {
          ...loot,
          discount: calculateLootDiscount(loots[0], loot),
          originalPrice: calculateOriginalPrice(loots[0], loot.quantity),
        };
      }),
    [loots],
  );

  const handleLootSelection = (loot: UserSetLoot) => {
    onLootSelected(loot);
  };

  return (
    <section>
      <header className={classNames('section-packs-prices__header')}>
        <h3>{t('lootPacks:checkout.step1.packsSection.title', { level })}</h3>
      </header>

      {!buyableLoots && (
        <Alert type={Alert.ALERT_TYPES.ERROR} className={classNames('section-packs-prices__alert')}>
          {t('lootPacks:checkout.step1.packsSection.noBuyableError')}
        </Alert>
      )}
      {buyableLoots && (
        <>
          <div
            className={classNames({
              'section-packs-prices__grid': true,
              'section-packs-prices__grid--with-rows': newLoots.length >= 4,
            })}
          >
            {newLoots.map(loot => {
              return (
                <div key={loot._id} className={classNames('section-packs-prices__grid__content')}>
                  <PackPriceWithDiscount
                    key={loot._id}
                    loot={loot}
                    referenceLoot={newLoots[0]}
                    onLootSelected={() => handleLootSelection(loot)}
                  />
                </div>
              );
            })}
          </div>
          <hr />

          <p className={classNames('section-packs-prices__footer')}>{t('lootPacks:checkout.step1.selectCardOption')}</p>
        </>
      )}
    </section>
  );
};
