import { PagesModules } from '@streamloots/streamloots-types';
import ClassNames from '@streamloots/classnames';
import { useTranslation } from 'utils/translation';
import ILLUSTRATIONS from 'constants/illustrations';
import { urls } from 'services/routing';
import { CheckPermissions, checkPermissions, permissions, staffPermissions } from 'components/permissions';
import { useSetsSelectors } from 'services/sets';
import { usePageSelectors } from 'services/page';
import { FullLayout } from 'scenes/layout';
import { PageModulesChecker } from 'components/page-modules-checker';
import { AlertsAndWidgetsTitle } from 'scenes/alerts-and-widgets/AlertsAndWidgetsTitle';
import { NoSets } from './NoSets';
import { MainPanel } from './MainPanel';
import { OtherPanel } from './OtherPanel';
import theme from './alerts-and-widgets.scss';

const classNames = ClassNames(theme);

const INTEGRATION_COMMON_PERMISSIONS = [...staffPermissions, permissions.PAGE_OWNER];

const AlertsAndWidgetsPage = () => {
  const { hasSets } = useSetsSelectors();
  const { t } = useTranslation([
    'alert',
    'integrations',
    'goals',
    'timer',
    'dashboard',
    'streamdeck',
    'obs',
    'marathon',
  ]);
  const {
    page: { modules },
  } = usePageSelectors();

  if (!modules) {
    return null;
  }

  const canSeeWidgets = modules.cards.enabled || modules.subscriptions.enabled;

  return (
    <FullLayout>
      {canSeeWidgets && (
        <CheckPermissions
          requiredPermissions={[
            permissions.ALERT_PROVIDERS_EDIT,
            permissions.GOALS_EDIT,
            ...INTEGRATION_COMMON_PERMISSIONS,
          ]}
        >
          <AlertsAndWidgetsTitle
            title={t('integrations:alertsAndWidgets.widgetsTitle')}
            tooltip={t('integrations:alertsAndWidgets.widgetsTooltip')}
          />
          <PageModulesChecker modulesRequired={[PagesModules.Cards, PagesModules.Subscriptions]}>
            <div className={classNames('widgets__main')}>
              <CheckPermissions requiredPermissions={permissions.ALERT_PROVIDERS_EDIT}>
                <MainPanel
                  buttonLabel={t('alert:goToAlerts')}
                  imageUrl={ILLUSTRATIONS.LOGO_HELLO}
                  description={t('alert:pageLinkDescription')}
                  title={t('alert:pageTitle')}
                  url={urls().ALERTS}
                />
              </CheckPermissions>
              <CheckPermissions requiredPermissions={permissions.GOALS_EDIT}>
                <MainPanel
                  isBlue
                  buttonLabel={t('goals:goToGoals')}
                  imageUrl={ILLUSTRATIONS.LOGO_GOAL}
                  description={t('goals:pageLinkDescription')}
                  title={t('goals:menuLabel')}
                  url={urls().GOALS}
                />
              </CheckPermissions>
            </div>
          </PageModulesChecker>
          <div className={classNames('widgets__others')}>
            <PageModulesChecker modulesRequired={[PagesModules.Cards]}>
              <CheckPermissions
                requiredPermissions={[permissions.ALERT_PROVIDERS_EDIT, ...INTEGRATION_COMMON_PERMISSIONS]}
              >
                <OtherPanel
                  buttonLabel={t('timer:goToTimer')}
                  icon="timelapse"
                  description={t('timer:pageLinkDescription')}
                  title={t('timer:pageTitle')}
                  url={urls().TIMER}
                  disabled={!hasSets}
                  renderDisabled={() => <NoSets t={t} />}
                />
              </CheckPermissions>
            </PageModulesChecker>
          </div>
        </CheckPermissions>
      )}
      <CheckPermissions requiredPermissions={INTEGRATION_COMMON_PERMISSIONS}>
        <AlertsAndWidgetsTitle title={t('integrations:alertsAndWidgets.integrationsTitle')} />
        <div className={classNames('widgets__others')}>
          <OtherPanel
            buttonLabel={t('dashboard:chatBot.goToChatbot')}
            icon="chatbot"
            description={t('dashboard:chatBot.explanation.text')}
            title={t('dashboard:chatBot.title')}
            url={urls().CHAT_BOT}
          />
          <PageModulesChecker modulesRequired={[PagesModules.Cards, PagesModules.Marathons]}>
            <OtherPanel
              buttonLabel={t('obs:goToObsPlugin')}
              icon="obs"
              description={t('obs:description')}
              title={t('obs:title')}
              url={urls().OBS_PLUGIN}
            />
          </PageModulesChecker>
          <PageModulesChecker modulesRequired={[PagesModules.Cards, PagesModules.Marathons]}>
            <CheckPermissions requiredPermissions={INTEGRATION_COMMON_PERMISSIONS}>
              <OtherPanel
                buttonLabel={t('streamdeck:gotToStreamDeck')}
                icon="stream-deck"
                description={t('streamdeck:description')}
                title={t('streamdeck:title')}
                url={urls().STREAMDECK}
                disabled={!hasSets}
                renderDisabled={() => <NoSets t={t} />}
              />
            </CheckPermissions>
          </PageModulesChecker>
        </div>
      </CheckPermissions>
      <AlertsAndWidgetsTitle
        title={t('integrations:alertsAndWidgets.otherUtilitiesTitle')}
        tooltip={t('integrations:alertsAndWidgets.otherUtilitiesTooltip')}
      />
      <div className={classNames('widgets__others')}>
        <OtherPanel
          buttonLabel={t('integrations:alertsAndWidgets.myLastVideo.cta')}
          icon="play"
          description={t('integrations:alertsAndWidgets.myLastVideo.description')}
          title="My Last Video"
          url={t('integrations:alertsAndWidgets.myLastVideo.link')}
          isExternalUrl
        />
      </div>
    </FullLayout>
  );
};

export default checkPermissions({
  requiredPermissions: [permissions.ALERT_PROVIDERS_EDIT, permissions.GOALS_EDIT, ...INTEGRATION_COMMON_PERMISSIONS],
})(AlertsAndWidgetsPage);
