import type { PagePayoutMethodState, PagePayoutMethodActions } from './types';
import { PageActionTypes } from '../page';
import { PagePayoutMethodActionTypes } from './actionTypes';
import normalizer from './normalizer';

export const reducer = (state: PagePayoutMethodState = {}, action): PagePayoutMethodState => {
  const { type, error, payload } = action;
  switch (type) {
    case PageActionTypes.RECEIVED: {
      if (error || !payload.paymentMethods) {
        return {};
      }
      const { paymentMethods, primaryPayoutMethod, hyperwalletRegistered } = payload;
      return {
        ...state,
        payoutMethod: normalizer.payoutMethod(paymentMethods, {
          primaryPayoutMethod,
          hyperwalletRegistered,
        }),
      };
    }
    case PageActionTypes.CHANGED: {
      return {};
    }
    case PagePayoutMethodActionTypes.UPDATE_SUCCESS: {
      return {
        ...state,
        payoutMethod: normalizer.payoutMethod(payload, {}),
      };
    }
    default:
      return state;
  }
};
