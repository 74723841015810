import * as React from 'react';
import loadable from 'components/dynamic-import';
import { FullLayout } from 'scenes/layout';

const StreamerNotFound = loadable(() => import('./StreamerNotFound'));

interface StreamerNotFoundProps {
  username: string;
  slug: string;
}

export const StreamerNotFoundPage = (props: StreamerNotFoundProps): JSX.Element => (
  <FullLayout>
    <StreamerNotFound {...props} />
  </FullLayout>
);
