import ClassNames from '@streamloots/classnames';
import { SimplifiedLayout } from 'scenes/layout';
import loadable from 'components/dynamic-import';
import theme from './onboarding.scss';

const classNames = ClassNames(theme);

const Onboarding = loadable(() => import('./ConnectedOnboarding'));

export const OnboardingPage = (): JSX.Element => (
  <SimplifiedLayout>
    <div className={classNames('onboarding')}>
      <Onboarding />
    </div>
  </SimplifiedLayout>
);
