import * as React from 'react';
import { SubmitHandler } from 'redux-form';
import { FixMe } from 'types/indexTS';
import { compose } from '@reduxjs/toolkit';
import { TranslateInterface, withTranslation } from 'utils/translation';
import Button, { ButtonIcon } from 'ui-library/button';
import { Alert } from 'ui-library/alert';
import type { WithAlertProviderProps } from 'components/react-alert-provider/typesTS';
import { withAlertProvider } from 'components/react-alert-provider';
import type { FormToolboxProps } from './typesTS';

interface OwnProps {
  button?: React.ReactNode;
  buttonClass?: string;
  buttonIcon?: string;
  children: React.ReactNode;
  hideOnSuccess?: boolean;
  successMessage?: React.ReactNode;
  hideClientSideError?: boolean;
  renderToolbox?: (toolboxProps: Partial<FormToolboxProps>) => React.ReactNode;
  warningButton?: boolean;
  ctaYellowButton?: boolean;
  avoidSubmitOnEnter?: boolean;
  ['data-testid']?: string;
  form?: string;
  className?: string;
  error?: string;
  dirty?: boolean;
  disabled?: boolean;
  handleSubmit?: SubmitHandler<Record<string, any>, any>;
  onSubmit?: () => void;
  submitSucceeded?: boolean;
  submitFailed?: boolean;
  submitting?: boolean;
  valid?: boolean;
}

interface FormProps extends OwnProps, WithAlertProviderProps, TranslateInterface {}

class Form extends React.Component<FormProps> {
  static defaultProps = {
    buttonClass: '',
    className: '',
    disabled: false,
  };

  componentDidUpdate(prevProps) {
    const { submitSucceeded, submitFailed, showSuccessAlert, showErrorAlert, successMessage, error, t } = this.props;

    if (submitSucceeded && !prevProps.submitSucceeded && successMessage) {
      showSuccessAlert(successMessage);
      return;
    }

    if (submitFailed && prevProps.submitting && error) {
      showErrorAlert(t(error));
    }
  }

  onSubmitClick = (event: React.MouseEvent<HTMLButtonElement>): FixMe => {
    const { avoidSubmitOnEnter, handleSubmit, showErrorAlert, valid, error, t } = this.props;

    if (!valid && !error) {
      showErrorAlert(t('errors:formWithErrors'));
    }

    if (avoidSubmitOnEnter && handleSubmit) {
      event.preventDefault();
      return handleSubmit(event);
    }
    return undefined;
  };

  renderButton() {
    const {
      avoidSubmitOnEnter,
      button,
      buttonClass,
      buttonIcon,
      disabled,
      submitting,
      warningButton,
      ctaYellowButton,
    } = this.props;

    if (buttonIcon) {
      return (
        <ButtonIcon
          icon={buttonIcon}
          className={buttonClass}
          data-testid={'form-submit-button'}
          disabled={disabled}
          loading={submitting}
          label={button}
          formNoValidate
          primary={!warningButton && !ctaYellowButton}
          warning={warningButton}
          color={ctaYellowButton ? 'checkout' : undefined}
          variant={ctaYellowButton ? 'filled' : undefined}
          onClick={this.onSubmitClick}
          type={avoidSubmitOnEnter ? 'button' : 'submit'}
        />
      );
    }

    return (
      <Button
        className={buttonClass}
        data-testid={'form-submit-button'}
        disabled={disabled}
        loading={submitting}
        label={button}
        formNoValidate
        primary={!warningButton && !ctaYellowButton}
        warning={warningButton}
        color={ctaYellowButton ? 'checkout' : undefined}
        variant={ctaYellowButton ? 'filled' : undefined}
        onClick={this.onSubmitClick}
        type={avoidSubmitOnEnter ? 'button' : 'submit'}
      />
    );
  }

  renderToolbox() {
    const { renderToolbox, successMessage } = this.props;

    if (renderToolbox) {
      return renderToolbox({
        submitButton: this.renderButton(),
        successMessage,
      });
    }

    return <div className="margin-top align-right">{this.renderButton()}</div>;
  }

  renderBody() {
    const { children, hideOnSuccess, submitSucceeded, successMessage } = this.props;

    if (hideOnSuccess && submitSucceeded && successMessage) {
      return (
        <Alert className="margin-top" type={Alert.ALERT_TYPES.SUCCESS}>
          {successMessage}
        </Alert>
      );
    }

    return (
      <>
        {children}
        {this.renderToolbox()}
      </>
    );
  }

  handleSubmit = event => {
    const { avoidSubmitOnEnter, handleSubmit } = this.props;
    event.preventDefault();
    event.stopPropagation();

    if (avoidSubmitOnEnter) {
      return false;
    }

    if (handleSubmit) {
      return handleSubmit(event);
    }

    return false;
  };

  render() {
    const { className, 'data-testid': dataTestId } = this.props;

    return (
      <form className={className} onSubmit={this.handleSubmit} method="POST" data-testid={dataTestId} aria-label="form">
        {this.renderBody()}
      </form>
    );
  }
}

export default compose<React.ComponentType<OwnProps>>(withAlertProvider, withTranslation('errors'))(Form);
