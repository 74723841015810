import stateCreator from 'helpers/stateCreator';
import { PageActionTypes } from 'services/page';
import type { SetListState } from './types';
import normalizer from '../../normalizer';
import setUtils from '../../setUtils';
import { SetsActionTypes } from '../../actionTypes';

export const SET_LIST_INITIAL_STATE: SetListState = {
  setsById: {},
};

export const reducer = (state: SetListState = SET_LIST_INITIAL_STATE, action): SetListState => {
  const { type, error, payload, metadata } = action;
  switch (type) {
    case SetsActionTypes.RECEIVED: {
      if (error) {
        return stateCreator.getAsyncErrorState(action, state);
      }

      const { selectedSetId } = state;
      const { data } = payload;
      const defaultSetId = setUtils.getSelectedIdOrDefaultId(data);
      return stateCreator.getInitialAsyncState(state, {
        ...normalizer.setListStateProps({
          setsData: data,
          newSetInfo: {},
        }),
        selectedSetId: selectedSetId || defaultSetId,
      });
    }
    case SetsActionTypes.REQUEST:
      return stateCreator.getAsyncLoadingState(state);
    case SetsActionTypes.SELECTED_SET_CHANGED: {
      const { setId } = payload;
      return { ...state, selectedSetId: setId };
    }
    case SetsActionTypes.PUBLISHED: {
      if (error) {
        return state;
      }

      const { setId } = payload;
      const { sets = [] } = state;

      return {
        ...state,
        ...normalizer.setListStateProps({
          setsData: sets,
          updatedSetId: setId,
          newSetInfo: { published: true },
        }),
      };
    }
    case SetsActionTypes.UNPUBLISHED: {
      if (error) {
        return state;
      }

      const { setId } = payload;
      const { sets = [] } = state;

      return {
        ...state,
        ...normalizer.setListStateProps({
          setsData: sets,
          updatedSetId: setId,
          newSetInfo: { published: false },
        }),
      };
    }
    case SetsActionTypes.RARITIES_UPDATED: {
      if (error) {
        return state;
      }

      const { setId, rarities } = payload;
      const { sets = [] } = state;

      return {
        ...state,
        ...normalizer.setListStateProps({
          setsData: sets,
          updatedSetId: setId,
          newSetInfo: {
            rarities: { ...rarities },
          },
        }),
      };
    }
    case SetsActionTypes.UPDATING_STATUS:
    case SetsActionTypes.UPDATING: {
      const { sets = [] } = state;
      const { setId } = payload;
      return {
        ...state,
        ...normalizer.setListStateProps({
          setsData: sets,
          updatedSetId: setId,
          newSetInfo: { updating: true },
        }),
      };
    }
    case SetsActionTypes.PAUSED:
    case SetsActionTypes.RESUMED:
    case SetsActionTypes.UPDATED: {
      const { sets = [] } = state;
      if (error) {
        return {
          ...state,
          ...normalizer.setListStateProps({
            setsData: sets,
            updatedSetId: metadata.setId,
            newSetInfo: {
              updating: false,
            },
          }),
        };
      }
      const { set } = payload;
      const updatedSetId = set._id;
      return {
        ...state,
        ...normalizer.setListStateProps({
          setsData: sets,
          updatedSetId,
          newSetInfo: {
            ...set,
            updating: false,
          },
        }),
      };
    }

    case SetsActionTypes.ARCHIVED: {
      if (error) {
        return state;
      }

      const { sets = [] } = state;

      const newSets = sets?.filter(set => set._id !== payload.setId);

      return {
        ...state,
        sets: newSets,
      };
    }
    case PageActionTypes.CHANGED:
      return SET_LIST_INITIAL_STATE;
    default:
      return state;
  }
};
