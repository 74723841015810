import { History } from 'history';
import loadable from 'components/dynamic-import';
import { FullLayout } from 'scenes/layout';

const Revenue = loadable(() => import('./Revenue'));

type RevenuePageProps = {
  match: {
    params: {
      tab: string;
    };
  };
  history: History;
};

export const RevenuePage = (props: RevenuePageProps): JSX.Element => (
  <FullLayout>
    <Revenue {...props} />
  </FullLayout>
);
