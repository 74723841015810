import { createSelector } from 'reselect';
import { ClaimableCampaignsSelectorOutput, ClaimableCampaignsState, ClaimableCampaignsStoreState } from './types';

const battlePassState = (state: ClaimableCampaignsStoreState): ClaimableCampaignsState => state.claimableCampaign || {};

const getClaimableCampaigns = createSelector<
  ClaimableCampaignsStoreState,
  ClaimableCampaignsState,
  ClaimableCampaignsSelectorOutput
>(
  [battlePassState],
  (state: ClaimableCampaignsState): ClaimableCampaignsSelectorOutput => {
    const { campaign, isLoading } = state;

    if (isLoading || !campaign) {
      return null;
    }

    return campaign;
  },
);

const isLoadingData = createSelector<ClaimableCampaignsStoreState, ClaimableCampaignsState, boolean>(
  [battlePassState],
  (state: ClaimableCampaignsState): boolean => {
    const { isLoading } = state;

    if (isLoading) {
      return isLoading;
    }

    return false;
  },
);

const getReconcile = createSelector<ClaimableCampaignsStoreState, ClaimableCampaignsState, boolean>(
  [battlePassState],
  (state: ClaimableCampaignsState): boolean => {
    const { reconcile } = state;

    if (reconcile) {
      return reconcile;
    }

    return false;
  },
);

export const claimableCampaignsSelectors = {
  getClaimableCampaigns,
  isLoadingData,
  getReconcile,
  battlePassState,
};
