import { PageActionTypes } from 'services/page';
import stateCreator from 'helpers/stateCreator';
import { SubscriptionGiftsActionTypes } from './actionTypes';
import { SubscriptionGiftsAction, SubscriptionGiftsState } from './types';

// Initial state.
const initialState: SubscriptionGiftsState = {};

// Reducer.
export const reducer = (
  state: SubscriptionGiftsState = initialState,
  action: SubscriptionGiftsAction,
): SubscriptionGiftsState => {
  switch (action.type) {
    case SubscriptionGiftsActionTypes.FETCH_ERROR:
      return stateCreator.getAsyncErrorState(action, state, initialState);

    case SubscriptionGiftsActionTypes.FETCH_SUCCESS: {
      return stateCreator.getInitialAsyncState(state, { subscriptionGifts: action.payload });
    }

    case SubscriptionGiftsActionTypes.CLAIM_ERROR:
      return stateCreator.getAsyncErrorState(action, state, initialState);

    case SubscriptionGiftsActionTypes.CLAIM_SUCCESS: {
      return stateCreator.getInitialAsyncState(state, initialState);
    }

    case PageActionTypes.CHANGED:
      return { ...initialState };

    default:
      return state;
  }
};
