export interface Tag {
  locales: {
    en: string;
    es: string;
  };
  normalizedLabel: string;
  restricted: boolean;
  value: string;
  _id: string;
}

export type NormalizedTag = {
  label: string;
  value: string;
  internalName: string;
};

export const mapTagsToAsyncAutocompleteByLanguage = (
  tags: Pick<Tag, '_id' | 'locales' | 'value'>[],
  language: string,
): NormalizedTag[] => {
  return tags.map(tag => {
    return {
      label: tag.locales[language],
      value: tag._id,
      internalName: tag.value,
    };
  });
};
