import { Component } from 'react';
import { History } from 'history';
import type { PageModules, Permissions } from 'model/indexTS';
import type { TranslateInterface } from 'utils/translation';
import { urls } from 'services/routing';
import { Tabs, Tab } from 'ui-library/tabs';
import { hasPermissions } from 'components/permissions';
import { areModulesRequiredEnabled } from 'components/page-modules-checker';
import ClassNames from '@streamloots/classnames';
import { NewLabel } from 'ui-library/new-indicator';
import type { RouteTabsMatchType, RouteTabInfo } from './types';
import theme from './route-tabs.scss';

const classNames = ClassNames(theme);

interface RouterTabsProps extends TranslateInterface {
  language?: string;
  history: History;
  match: RouteTabsMatchType;
  onTabChange?: (tabIndex: number) => void;
  tabsInfo: Array<RouteTabInfo>;
  permissions: Permissions;
  modules?: PageModules;
}

type RouterTabsState = {
  index: number;
};

class RouteTabs extends Component<RouterTabsProps, RouterTabsState> {
  tabs: Array<RouteTabInfo> = [];

  urlPathKeys: Array<string> = [];

  tabUrlKeys: Array<string> = [];

  constructor(props: RouterTabsProps) {
    super(props);
    const { modules, tabsInfo, permissions } = this.props;

    this.tabs = tabsInfo.filter(tabInfo => {
      if (tabInfo.requiredModules && !areModulesRequiredEnabled(modules, tabInfo.requiredModules)) {
        return false;
      }

      if (!permissions || (!tabInfo.permissions && !tabInfo.enforcePermissions)) {
        return true;
      }

      if (tabInfo.enforcePermissions && !hasPermissions(permissions, tabInfo.enforcePermissions)) {
        return false;
      }

      return hasPermissions(permissions, tabInfo.permissions);
    });

    this.urlPathKeys = this.tabs.map(tabInfo => tabInfo.urlPathKey);
    this.tabUrlKeys = this.tabs.map(tabInfo => tabInfo.urlKey);
    this.state = {
      index: this.mapParamsToIndex(),
    };
  }

  componentDidMount() {
    const { history, match } = this.props;
    const { index } = this.state;
    if (!match || !match.params) {
      return;
    }

    const {
      params: { tab },
    } = match;

    const tabIndex = this.urlPathKeys.indexOf(tab);
    if (tabIndex !== index) {
      history.replace(this.mapIndexToLocation(index));
    }
  }

  componentDidUpdate() {
    const { index } = this.state;
    const paramsIndex = this.mapParamsToIndex();
    if (index !== paramsIndex) {
      this.setState({ index: paramsIndex });
    }
  }

  mapParamsToIndex = (): number => {
    const { match } = this.props;
    if (!match || !match.params) {
      return 0;
    }
    const {
      params: { tab },
    } = match;

    const tabIndex = this.urlPathKeys.indexOf(tab);

    return tabIndex > -1 ? tabIndex : 0;
  };

  mapIndexToLocation = (index: number): string => urls()[this.tabUrlKeys[index]];

  handleTabChange = (newIndex: number) => {
    const { onTabChange, history } = this.props;
    const currentIndex = this.mapParamsToIndex();
    if (newIndex === currentIndex) {
      return;
    }
    const newLocation = this.mapIndexToLocation(newIndex);

    history.push(newLocation, { noScroll: true });
    if (onTabChange) {
      onTabChange(newIndex);
    }
  };

  renderTab = (tab: RouteTabInfo) => {
    const { language, t } = this.props;
    const {
      activeHeaderClassName,
      headerClassName,
      component: TabChildrenComponent,
      i18nKey,
      urlPathKey,
      showNew,
    } = tab;
    return (
      <Tab
        activeHeaderClassName={activeHeaderClassName}
        headerClassName={headerClassName}
        label={
          <>
            {t(i18nKey)}
            {showNew && <NewLabel className={classNames('badge')} />}
          </>
        }
        tabKey={urlPathKey}
        key={urlPathKey}
      >
        <TabChildrenComponent {...(language && { language })} t={t} />
      </Tab>
    );
  };

  render() {
    return (
      <Tabs activeTab={this.state.index} onChange={this.handleTabChange}>
        {this.tabs.map(this.renderTab)}
      </Tabs>
    );
  }
}

export { RouteTabs };
