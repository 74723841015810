import { STATIC_IMAGES_PATH } from './images';

function buildIllustrationsUrl(path: string) {
  return `${STATIC_IMAGES_PATH}ilustrations/${path}`;
}

export default {
  CRY_FLOOR: buildIllustrationsUrl('ill-char-cry_floor.png'),
  CRY_CHEST: buildIllustrationsUrl('ill-char-cry_chest.png'),
  EMPTY_CHEST: buildIllustrationsUrl('ill-prop-empty_chest.png'),
  PILE_EMPTY_CHEST: buildIllustrationsUrl('ill-prop-pile_chest.png'),
  CASTLE: buildIllustrationsUrl('castle-min.png'),
  CASTLE_STREAMERS: buildIllustrationsUrl('castle_streamers-min.png'),
  CARD_LOCKED: buildIllustrationsUrl('card-locked.png'),
  CATASTROPHE_01: buildIllustrationsUrl('catastrophe_01-min.png'),
  CATASTROPHE_02: buildIllustrationsUrl('catastrophe_02-min.png'),
  CATASTROPHE_03: buildIllustrationsUrl('catastrophe_03-min.png'),
  CATASTROPHE_04: buildIllustrationsUrl('catastrophe_04-min.png'),
  CHEST: buildIllustrationsUrl('chest-min.png'),
  GANDALF: buildIllustrationsUrl('gandalf-min.png'),
  STREAMER_CELEBRATION: buildIllustrationsUrl('streamer_celebration-min.png'),
  STREAMER_BUST_02: buildIllustrationsUrl('streamer_bust02-min.png'),
  STREAMER_BUST_01: buildIllustrationsUrl('streamer_bust01-min.png'),
  VIEWER_BUST_01: buildIllustrationsUrl('viewer_bust01-min.png'),
  VIEWER_NEGATIVE_FLAG: buildIllustrationsUrl('viewer_negative_flag-min.png'),
  VIEWER_POSITIVE_FLAG: buildIllustrationsUrl('viewer_positive_flag-min.png'),
  VIEWER1_CHECKLIST: buildIllustrationsUrl('viewer1_check_list-min.png'),
  VIEWER2_CHECKLIST: buildIllustrationsUrl('viewer2_check_list-min.png'),
  EMPTY_CARD: buildIllustrationsUrl('empty_card-min.png'),
  CONNECTION: buildIllustrationsUrl('connection.png'),
  MONEY: buildIllustrationsUrl('money.png'),
  SEARCHING: buildIllustrationsUrl('streamloots-robot-searching.png'),
  LOGO_MONOCLE: buildIllustrationsUrl('logo-monocle.png'),
  STREAME_GHOST: buildIllustrationsUrl('streame_ghost.png'),
  STREAME_CRYING_WITH_CHESTS: buildIllustrationsUrl('streame-crying-king-chests.png'),
  STREAMER_SLEEPING: buildIllustrationsUrl('sleep_streamer_250.png'),
  CARD_CONSTRUCTION: buildIllustrationsUrl('card-construction.png'),
  NO_BANS: buildIllustrationsUrl('no_ban-min.png'),
  NO_MODERATORS: buildIllustrationsUrl('no_moderator-min.png'),
  CATASTROPHIC: buildIllustrationsUrl('catastrophic.png'),
  STREAMER_NOT_FOUND: buildIllustrationsUrl('streamer_notfound-min.png'),
  SEARCHING_2: buildIllustrationsUrl('searching-min.png'),
  NOT_FOUND_STREAMER_ROLE: buildIllustrationsUrl('filter_notfound_streamer.png'),
  NOT_FOUND_VIEWER_ROLE: buildIllustrationsUrl('filter_notfound_viewer.png'),
  VIEWER_SUGGESTIONS: buildIllustrationsUrl('viewers_suggestion-min.png'),
  STREAMER_DIRECTOR: buildIllustrationsUrl('streamer_director-min.png'),
  STREAMER_DIRECTOR_STARS: buildIllustrationsUrl('streamer_director_starts.png'),
  BANK_DEALINGS: buildIllustrationsUrl('bank_dealings.png'),
  NO_CARDS: buildIllustrationsUrl('no_cards-min.png'),
  COUPONS: buildIllustrationsUrl('coupons.png'),
  LEVEL_UP_TO_UNLOCK: buildIllustrationsUrl('climb_mountain.png'),
  VIEWER_FEMALE_SUGGESTION: buildIllustrationsUrl('viewer2_suggestion-min.png'),
  VIEWER_MALE_SUGGESTION: buildIllustrationsUrl('viewer1_suggestion-min.png'),
  WELCOME_STREAMERS: buildIllustrationsUrl('welcome_streamers-min.png'),
  WELCOME_NPCS: buildIllustrationsUrl('welcome_npcs.png'),
  WELCOME_NPCS_STARS: buildIllustrationsUrl('welcome_npcs_stars.png'),
  WELCOME_STREAMERS_NO_BOTS: buildIllustrationsUrl('welcome_streamers_nobots.png'),
  STARTS: buildIllustrationsUrl('stars.png'),
  POP_CORNS: buildIllustrationsUrl('popcorns.png'),
  POP_CORNS_STARS: buildIllustrationsUrl('popcorns-starts.png'),
  ROBOT_RUNNING: buildIllustrationsUrl('robot_running-min.png'),
  STREAMER_RUNNING: buildIllustrationsUrl('streamer_running-min.png'),
  CAPTAIN_STREAMLOOTS: buildIllustrationsUrl('captain_streame.png'),
  KRYPTO: buildIllustrationsUrl('krypto.png'),
  LOGO_GOAL: buildIllustrationsUrl('strimi_goal.png'),
  LOGO_HELLO: buildIllustrationsUrl('strimi_hello.png'),
  LOGO_WARNING_STARS: buildIllustrationsUrl('logo_warning_stars-min.png'),
  LOGO_ERROR_STARS: buildIllustrationsUrl('logo_error_stars-min.png'),
  FLAT_CARDS_COMMON: buildIllustrationsUrl('common-card-flat.png'),
  FLAT_CARDS_RARE: buildIllustrationsUrl('rare-card-flat.png'),
  FLAT_CARDS_EPIC: buildIllustrationsUrl('epic-card-flat.png'),
  FLAT_CARDS_LEGENDARY: buildIllustrationsUrl('legendary-card-flat.png'),
  FLAT_CHESTS_COMMON: buildIllustrationsUrl('common-chest-flat.png'),
  FLAT_CHESTS_RARE: buildIllustrationsUrl('rare-chest-flat.png'),
  FLAT_CHESTS_EPIC: buildIllustrationsUrl('epic-chest-flat.png'),
  FLAT_CHESTS_LEGENDARY: buildIllustrationsUrl('legendary-chest-flat.png'),
  FLAT_FIRED_CARDS_COMMON: buildIllustrationsUrl('common-card-fire-flat.png'),
  FLAT_FIRED_CARDS_RARE: buildIllustrationsUrl('rare-card-fire-flat.png'),
  FLAT_FIRED_CARDS_EPIC: buildIllustrationsUrl('epic-card-fire-flat.png'),
  FLAT_FIRED_CARDS_LEGENDARY: buildIllustrationsUrl('legendary-card-fire-flat.png'),
};
