import actionCreator from 'helpers/actionCreator';
import type { MarathonConfig, MarathonConfigRequest, MarathonTracker } from 'model/indexTS';
import endpoints from 'services/endpoints';
import { MarathonData } from 'model/indexTS';
import type {
  FetchMarathon,
  MarathonActionSuccess,
  MarathonActionError,
  CreateMarathonActionSuccess,
  CreateMarathonActionError,
  UpdateMarathonActionSuccess,
  UpdateMarathonActionError,
  CreateMarathon,
  UpdateMarathon,
  StartMarathon,
  StartMarathonActionError,
  StartMarathonActionSuccess,
  StopMarathon,
  StopMarathonActionError,
  StopMarathonActionSuccess,
  FetchMarathonTracker,
  MarathonTrackerActionError,
  MarathonTrackerActionSuccess,
  AddMarathonTime,
  AddMarathonTimeActionError,
  AddMarathonTimeActionSuccess,
  UpdateMarathonStatus,
  UpdateMarathonStatusActionSuccess,
} from './types';
import { getMarathonConfigData } from './normalizer';
import { errorMapping } from './errorMapping';
import { MarathonActionTypes } from './actionTypes';
import { AddTimeMarathonParams, UpdateMarathonStatusActionError } from './types';

const marathonReceived = ({ data }: { data: MarathonConfig }): MarathonActionSuccess =>
  actionCreator.createAction(MarathonActionTypes.RECEIVED, getMarathonConfigData(data));

const marathonNotReceived = ({ errorData }): MarathonActionError =>
  actionCreator.createErrorAction(MarathonActionTypes.ERROR, errorData, errorMapping);

const fetchMarathon: FetchMarathon = (slug: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.MARATHON.GET,
    errorBinding: marathonNotReceived,
    loadingAction: MarathonActionTypes.FETCHING,
    urlParameters: { slug },
    method: 'get',
    successBinding: marathonReceived,
  });

const createMarathonSuccess = ({ data }: { data: MarathonConfig }): CreateMarathonActionSuccess =>
  actionCreator.createAction(MarathonActionTypes.CREATED, getMarathonConfigData(data) as MarathonData);

const createMarathonError = ({ errorData }): CreateMarathonActionError =>
  actionCreator.createErrorAction(MarathonActionTypes.ERROR_CREATING, errorData, errorMapping);

const createMarathon: CreateMarathon = (slug: string, data: MarathonConfigRequest) => {
  const fixedData: MarathonConfigRequest = { ...data, specialInteractions: false };
  return actionCreator.createAsyncAction({
    endpoint: endpoints.MARATHON.POST,
    errorBinding: createMarathonError,
    loadingAction: MarathonActionTypes.CREATING,
    parameters: fixedData,
    urlParameters: { slug },
    method: 'post',
    successBinding: createMarathonSuccess,
  });
};

const updateMarathonSuccess = ({ data }: { data: MarathonConfig }): UpdateMarathonActionSuccess =>
  actionCreator.createAction(MarathonActionTypes.UPDATED, getMarathonConfigData(data) as MarathonData);

const updateMarathonError = ({ errorData }): UpdateMarathonActionError =>
  actionCreator.createErrorAction(MarathonActionTypes.ERROR_UPDATING, errorData, errorMapping);

const updateMarathon: UpdateMarathon = (slug: string, data: MarathonConfigRequest) => {
  const fixedData: MarathonConfigRequest = { ...data, specialInteractions: false };
  return actionCreator.createAsyncAction({
    endpoint: endpoints.MARATHON.PUT,
    errorBinding: updateMarathonError,
    loadingAction: MarathonActionTypes.UPDATING,
    parameters: fixedData,
    urlParameters: { slug },
    method: 'put',
    successBinding: updateMarathonSuccess,
  });
};

const startMarathonSuccess = ({ data }: { data: MarathonTracker }): StartMarathonActionSuccess =>
  actionCreator.createAction(MarathonActionTypes.STARTED, data);

const startMarathonError = ({ errorData }): StartMarathonActionError =>
  actionCreator.createErrorAction(MarathonActionTypes.ERROR_STARTING, errorData, errorMapping);

const startMarathon: StartMarathon = (marathonId: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.MARATHON.START,
    errorBinding: startMarathonError,
    loadingAction: MarathonActionTypes.STARTING,
    urlParameters: { marathonId },
    method: 'post',
    successBinding: startMarathonSuccess,
  });

const stopMarathonSuccess = ({ data }: { data: MarathonTracker }): StopMarathonActionSuccess =>
  actionCreator.createAction(MarathonActionTypes.STOPPED, data);

const stopMarathonError = ({ errorData }): StopMarathonActionError =>
  actionCreator.createErrorAction(MarathonActionTypes.ERROR_STOPING, errorData, errorMapping);

const stopMarathon: StopMarathon = (marathonId: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.MARATHON.STOP,
    errorBinding: stopMarathonError,
    loadingAction: MarathonActionTypes.STOPING,
    urlParameters: { marathonId },
    method: 'post',
    successBinding: stopMarathonSuccess,
  });

const fetchMarathonTrackerSuccess = ({ data }): MarathonTrackerActionSuccess =>
  actionCreator.createAction(MarathonActionTypes.TRACKER_RECEIVED_SUCCESS, data);

const fetchMarathonTrackerError = ({ errorData }): MarathonTrackerActionError =>
  actionCreator.createErrorAction(MarathonActionTypes.TRACKER_RECEIVED_ERROR, errorData, errorMapping);

const fetchMarathonTracker: FetchMarathonTracker = (slug: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.MARATHON.GET_TRACKER,
    errorBinding: fetchMarathonTrackerError,
    loadingAction: MarathonActionTypes.FETCHING_TRACKER,
    urlParameters: { slug },
    method: 'get',
    successBinding: fetchMarathonTrackerSuccess,
  });

const addMarathonTimeSuccess = ({ data }: { data: MarathonTracker }): AddMarathonTimeActionSuccess =>
  actionCreator.createAction(MarathonActionTypes.ADDED_TIME, data);

const addMarathonTimeError = ({ errorData }): AddMarathonTimeActionError =>
  actionCreator.createErrorAction(MarathonActionTypes.ERROR_ADDING_TIME, errorData, errorMapping);

const addMarathonTime: AddMarathonTime = (slug: string, data: AddTimeMarathonParams) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.MARATHON.ADD_TIME,
    errorBinding: addMarathonTimeError,
    loadingAction: MarathonActionTypes.ADDING_TIME,
    parameters: data,
    urlParameters: { slug },
    method: 'patch',
    successBinding: addMarathonTimeSuccess,
  });

const updateMarathonStatusSuccess = ({ data, additionalData }): UpdateMarathonStatusActionSuccess =>
  actionCreator.createAction(MarathonActionTypes.MARATHON_STATUS_CHANGED_SUCCESS, data, additionalData);

const updateMarathonStatusError = ({ errorData, additionalData }): UpdateMarathonStatusActionError =>
  actionCreator.createErrorAction(
    MarathonActionTypes.MARATHON_STATUS_CHANGED_ERROR,
    errorData,
    errorMapping,
    additionalData,
  );

const updateMarathonStatus: UpdateMarathonStatus = (slug: string, isPausing: boolean) =>
  actionCreator.createAsyncAction({
    endpoint: isPausing ? endpoints.MARATHON.PAUSE : endpoints.MARATHON.RESUME,
    errorBinding: updateMarathonStatusError,
    loadingAction: MarathonActionTypes.MARATHON_STATUS_CHANGED_REQUEST,
    additionalData: {
      isPausing,
    },
    urlParameters: { slug },
    method: 'post',
    successBinding: updateMarathonStatusSuccess,
  });

export const marathonActions = {
  fetchMarathon,
  updateMarathon,
  createMarathon,
  startMarathon,
  stopMarathon,
  fetchMarathonTracker,
  addMarathonTime,
  updateMarathonStatus,
};
