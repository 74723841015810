import _clean from 'lodash-clean';
import actionCreator from 'helpers/actionCreator';
import { errorMapping } from 'services/page/errorMapping';
import { PartnershipApplicationData } from 'model/partnership-applicationTS';
import { PagesModules } from '@streamloots/streamloots-types';
import type {
  PageReceivedActionSuccess,
  PageReceivedActionError,
  PageReceivedActionRequest,
  PageContextChangedAction,
  PageUpdatedActionError,
  PageUpdatedActionSuccess,
  PagePublicContextChangedAction,
  PageConfigurationParams,
  UpdatePageModulesParams,
  PageModulesUpdatedActionSuccess,
  PageModulesUpdatedActionError,
  UpdatePageModules,
} from './types';
import endpoints from '../endpoints';
import { getNormalizedPage } from './normalizer';
import { PageActionTypes } from './actionTypes';

const pageReceivedSuccess = ({ data }): PageReceivedActionSuccess => {
  const normalizedPageData = getNormalizedPage(data);
  return actionCreator.createAction(PageActionTypes.RECEIVED, normalizedPageData);
};

const pageNotReceivedError = ({ errorData }): PageReceivedActionError =>
  actionCreator.createErrorAction(PageActionTypes.RECEIVED, errorData);

const fetchingPage = (slug: string): PageReceivedActionRequest =>
  actionCreator.createAction(PageActionTypes.REQUEST, { slug });

const fetchPage = (slug: string) => {
  return actionCreator.createAsyncAction({
    endpoint: endpoints.PAGES.GET_PAGE_CONFIGURATION,
    errorBinding: pageNotReceivedError,
    loadingAction: () => fetchingPage(slug),
    method: 'get',
    urlParameters: { slug },
    successBinding: pageReceivedSuccess,
  });
};

const pageUpdated = ({ data }): PageUpdatedActionSuccess =>
  actionCreator.createAction(PageActionTypes.UPDATED, getNormalizedPage(data));

const pageNotUpdated = ({ errorData }): PageUpdatedActionError =>
  actionCreator.createErrorAction(PageActionTypes.UPDATED, errorData);

const updatePage = (params: PageConfigurationParams, slug: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.PAGES.UPDATE_SETTINGS,
    errorBinding: pageNotUpdated,
    loadingAction: PageActionTypes.UPDATING,
    method: 'put',
    parameters: _clean(params),
    urlParameters: { slug },
    successBinding: pageUpdated,
  });

const applicationMadeSuccess = () => actionCreator.createAction(PageActionTypes.APPLIED_FOR_NEXT_TYPE);

const applicationMadeError = ({ errorData }) =>
  actionCreator.createErrorAction(PageActionTypes.APPLIED_FOR_NEXT_TYPE, errorData, errorMapping);

const applyForAffiliate = (slug: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.PAGE_TYPE.APPLY_FOR_AFFILIATE,
    errorBinding: applicationMadeError,
    loadingAction: PageActionTypes.APPLYING_FOR_NEXT_TYPE,
    method: 'post',
    urlParameters: { slug },
    successBinding: applicationMadeSuccess,
  });

const applyForPartnership = (slug: string, partnershipApplicationData: PartnershipApplicationData) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.PAGE_TYPE.APPLY_FOR_PARTNERSHIP,
    errorBinding: applicationMadeError,
    loadingAction: PageActionTypes.APPLYING_FOR_NEXT_TYPE,
    method: 'post',
    urlParameters: { slug },
    parameters: _clean(partnershipApplicationData),
    successBinding: applicationMadeSuccess,
  });

const applicationAcknowledgedSuccess = () => actionCreator.createAction(PageActionTypes.APPLICATION_ACKNOWLEDGED);

const applicationAcknowledgedError = ({ errorData }) =>
  actionCreator.createErrorAction(PageActionTypes.APPLICATION_ACKNOWLEDGED, errorData);

const acknowledgeTypeApplication = (applicationId: string, slug: string, isPartnership?: boolean) =>
  actionCreator.createAsyncAction({
    endpoint: isPartnership
      ? endpoints.PAGE_TYPE.PARTNERSHIP_APPLICATION_ACKNOWLEDGMENTS
      : endpoints.PAGE_TYPE.AFFILIATE_APPLICATION_ACKNOWLEDGMENTS,
    errorBinding: applicationAcknowledgedError,
    loadingAction: PageActionTypes.ACKNOWLEDGING_APPLICATION,
    method: 'post',
    urlParameters: { applicationId, slug },
    successBinding: applicationAcknowledgedSuccess,
  });

const pageLevelUpgradedSuccess = () => actionCreator.createAction(PageActionTypes.UPGRADED);

const pageLevelUpgradedError = ({ errorData }) => actionCreator.createErrorAction(PageActionTypes.UPGRADED, errorData);

const upgradePageLevel = (slug: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.PAGES.UPDATE_PAGE_LEVEL,
    errorBinding: pageLevelUpgradedError,
    loadingAction: PageActionTypes.UPGRADING,
    method: 'post',
    urlParameters: { slug },
    successBinding: pageLevelUpgradedSuccess,
  });

const updatePageModulesSuccess = ({ data }): PageModulesUpdatedActionSuccess =>
  actionCreator.createAction(PageActionTypes.UPDATE_PAGE_MODULES_SUCCESS, getNormalizedPage(data));

const updatePageModulesError = ({ errorData }): PageModulesUpdatedActionError =>
  actionCreator.createErrorAction(PageActionTypes.UPDATE_PAGE_MODULES_ERROR, errorData);

const updatePageModules: UpdatePageModules = (params: UpdatePageModulesParams, slug: string) => {
  const normalizedParams = { ...params, [PagesModules.Feeds]: true };

  return actionCreator.createAsyncAction({
    endpoint: endpoints.PAGES.UPDATE_MODULES,
    errorBinding: updatePageModulesError,
    loadingAction: PageActionTypes.UPDATE_PAGE_MODULES_REQUEST,
    method: 'put',
    parameters: _clean(normalizedParams),
    urlParameters: { slug },
    successBinding: updatePageModulesSuccess,
  });
};

const pageChanged = () => actionCreator.createAction(PageActionTypes.CHANGED);

const changePageContext = (inPageContext: boolean): PageContextChangedAction =>
  actionCreator.createAction(PageActionTypes.CONTEXT_CHANGED, { inPageContext });

const changePublicPageContext = (inPublicPageContext: boolean): PagePublicContextChangedAction =>
  actionCreator.createAction(PageActionTypes.PUBLIC_PAGE_CONTEXT_CHANGED, { inPublicPageContext });

export const pageActions = {
  fetchPage,
  changePageContext,
  changePublicPageContext,
  pageChanged,
  applyForAffiliate,
  applyForPartnership,
  acknowledgeTypeApplication,
  upgradePageLevel,
  updatePage,
  updatePageModules,
};
