import loadable from 'components/dynamic-import';
import { checkPermissions, permissions } from 'components/permissions';
import { SimplifiedCardSkeletons } from 'components/component-skeletons';
import { FullLayout } from 'scenes/layout';

const ConnectedAdminPredefinedFrames = loadable(
  () => import('./ConnectedAdminPredefinedFrames'),
  true,
  <SimplifiedCardSkeletons includeWrapper />,
);

const AdminPredefinedFramesPageInternal = () => (
  <FullLayout>
    <ConnectedAdminPredefinedFrames />
  </FullLayout>
);

export const AdminPredefinedFramesPage = checkPermissions({
  requiredPermissions: [
    permissions.ADMIN,
    permissions.PARTNERSHIP_MANAGER,
    permissions.ACCOUNT_MANAGER,
    permissions.SUPPORT,
  ],
  redirectToBasePage: true,
})(AdminPredefinedFramesPageInternal);
