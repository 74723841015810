export const LOCAL_STORAGE_KEYS = {
  ALERTS_HELP: 'alerts.help',
  FEED_ENCOURAGE_COMMENTS: 'publicFeed.encourageComments',
  COUPONS_CREATE_TIP: 'coupons.createTip',
  ORIGINALS_SORT_BY: 'originals.sortBy',
  FEED_RECENT_PUBLISHED_CARDS: 'feed.recentPublishedCards',
};

export const LOCAL_STORAGE_KEYS_BY_SLUG = {
  LAST_FEED_VISITED: 'lastFeedVisited',
};
