import { CreatePaymentOrderActionTypes } from './services/create-payment-order';
import { ExecutePaymentOrderActionTypes } from './services/execute-payment-order';
import { FetchPaymentOrderActionTypes } from './services/fetch-payment-oder';
import { StripePaymentActionTypes } from './services/stripe-payment';
import { SavedStripedMethodsListActionTypes } from './services/saved-stripe-payment-methods-list';
import { DeleteStripePaymentMethodActionTypes } from './services/delete-stripe-payment-method';

export const paymentActionTypes = {
  ...CreatePaymentOrderActionTypes,
  ...ExecutePaymentOrderActionTypes,
  ...FetchPaymentOrderActionTypes,
  ...StripePaymentActionTypes,
  ...SavedStripedMethodsListActionTypes,
  ...DeleteStripePaymentMethodActionTypes,
};
