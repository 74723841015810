import stateCreator from 'helpers/stateCreator';
import { TagsActionTypes } from '../actionTypes';
import { TagsState, TagActionsTypes } from './types';

const initialState: TagsState = {
  search: null,
  tags: null,
  isLoading: false,
};

export default (state: TagsState = initialState, action: TagActionsTypes) => {
  switch (action.type) {
    case TagsActionTypes.TAGS_REQUEST: {
      return stateCreator.getAsyncLoadingState(state, {
        ...initialState,
        search: action.payload.search,
      });
    }

    case TagsActionTypes.TAGS_SUCCESS: {
      return stateCreator.getInitialAsyncState(state, {
        tags: action.payload.tags,
      });
    }

    case TagsActionTypes.TAGS_ERROR: {
      return stateCreator.getAsyncErrorState(action, state, initialState);
    }

    default:
      return state;
  }
};
