import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { withTranslation, TranslateInterface } from 'utils/translation';
import discountCouponCookieManager from 'services/coupons/discountCouponCookieManager';
import theme from './dialog-buy-loot-packs-picker.scss';

const classNames = ClassNames(theme);

const DiscountInfoBase = ({ t }: TranslateInterface): JSX.Element | null => {
  if (!discountCouponCookieManager.getCouponCode()) {
    return null;
  }

  return (
    <div className={classNames('dialog-buy-loot-packs-picker__discount-code')}>
      {t('lootPacks:checkout.step1.discountCodeActive')}
    </div>
  );
};

export const DiscountInfo = withTranslation('lootPacks')(DiscountInfoBase);
