import { StrictOmit } from '@streamloots/streamloots-types';
import { PageStatics } from 'model/indexTS';
import { ActionError, ActionSuccess, AsyncComponentState } from 'types/indexTS';
import { PageActions } from 'services/page';
import { PageStatisticsActionTypes } from './actionTypes';

export const SUMMARY_KEY = 'summary';

export type AdditionalData = {
  fromDate: string;
};

// TODO for a correct management we need to convert the full state in Record<string, STATE> where string is a component key or a fetch key
// this way we will be able to manage loading & error cases propertly
export interface PageStatisticsState extends AsyncComponentState {
  lastRequest?: string;
  statics?: Record<string, PageStatics>;
}

export interface PageStatisticsReduxState {
  pageStatistics?: PageStatisticsState;
}

export type GET_PAGE_STATISTICS_REQUEST_ACTION = ActionSuccess<PageStatisticsActionTypes.GET_PAGE_STATISTICS_REQUEST>;

export type GET_PAGE_STATISTICS_SUCCESS_ACTION = ActionSuccess<
  PageStatisticsActionTypes.GET_PAGE_STATISTICS_SUCCESS,
  PageStatics,
  AdditionalData
>;

export type GET_PAGE_STATISTICS_ERROR_ACTION = ActionError<
  PageStatisticsActionTypes.GET_PAGE_STATISTICS_ERROR,
  AdditionalData
>;

export type FetchPageStatistics = (
  pageId: string,
  fromDate?: string,
) => Promise<GET_PAGE_STATISTICS_SUCCESS_ACTION | GET_PAGE_STATISTICS_ERROR_ACTION>;

export interface FetchPageStatisticsByComponentKeyParams {
  pageId: string;
  fromDate?: number;
  componentKey: string;
}
export type FetchPageStatisticsByComponentKey = (
  params: FetchPageStatisticsByComponentKeyParams,
) => Promise<GET_PAGE_STATISTICS_SUCCESS_ACTION | GET_PAGE_STATISTICS_ERROR_ACTION>;

export type GET_PAGE_STATISTICS_MONTH_REQUEST_ACTION = ActionSuccess<PageStatisticsActionTypes.GET_PAGE_STATISTICS_MONTH_REQUEST>;

export type GET_PAGE_STATISTICS_MONTH_SUCCESS_ACTION = ActionSuccess<
  PageStatisticsActionTypes.GET_PAGE_STATISTICS_MONTH_SUCCESS,
  PageStatics,
  AdditionalData
>;

export type GET_PAGE_STATISTICS_MONTH_ERROR_ACTION = ActionError<
  PageStatisticsActionTypes.GET_PAGE_STATISTICS_MONTH_ERROR,
  AdditionalData
>;

export type FetchMonthPageStatistics = (
  pageId: string,
  date: string,
) => Promise<GET_PAGE_STATISTICS_MONTH_SUCCESS_ACTION | GET_PAGE_STATISTICS_MONTH_ERROR_ACTION>;

export type PageStatisticsActions =
  | GET_PAGE_STATISTICS_REQUEST_ACTION
  | GET_PAGE_STATISTICS_SUCCESS_ACTION
  | GET_PAGE_STATISTICS_ERROR_ACTION
  | PageActions;

export type TotalSelectorFormated = {
  total: number;
  totalFormatted: string;
};
export type TotalSelectorOutput = TotalSelectorFormated | null;

export type IsSellerOuput = {
  isSeller: boolean;
  isLoading: boolean;
};

export type SummaryFormated = StrictOmit<PageStatics, 'total'> & {
  total: string;
};

export type AllStatisticsOutput = Record<string, SummaryFormated> | null;
export type GrossRevenueOutput = Pick<
  PageStatics,
  'revenueInUsd' | 'revenueInUsdFromAuctions' | 'revenueInUsdFromGiftedLoots' | 'revenueInUsdFromPurchasedLoots'
> | null;
