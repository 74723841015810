import loadable from 'components/dynamic-import';
import { checkPermissions, staffPermissions } from 'components/permissions';
import { FullLayout } from 'scenes/layout';

const ConnectedAdminReactions = loadable(() => import('./ConnectedAdminReactions'));

const AdminReactions: React.FunctionComponent = (): JSX.Element => (
  <FullLayout>
    <ConnectedAdminReactions />
  </FullLayout>
);

export default checkPermissions({
  requiredPermissions: [...staffPermissions],
  redirectToBasePage: true,
})(AdminReactions);
