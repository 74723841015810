import type { ReactNode } from 'react';
import ClassNames from '@streamloots/classnames';
import Icon from 'ui-library/icons';
import { VerticalListItemDescription } from './VerticalListItemDescription';
import theme from './vertical-list.scss';

const classNames = ClassNames(theme);

export interface VerticalListItemProps {
  title?: string | ReactNode;
  description?: string | ReactNode;
  className?: string;
  isCompleted?: boolean;
  completedVisuallyHiddenDescription?: string;
  icon?: string;
}

export const VerticalListItem = ({
  title,
  description,
  className = '',
  isCompleted = false,
  completedVisuallyHiddenDescription,
  icon,
}: VerticalListItemProps): JSX.Element => {
  return (
    <li
      className={classNames({
        'vl__item': true,
        'vl__item--completed': isCompleted,
        [className]: Boolean(className),
      })}
    >
      {icon && (
        <div className={classNames('vl__icon')}>
          <Icon id={icon} />
        </div>
      )}
      {typeof title === 'string' ? <h5 className={classNames('vl__item__title')}>{title}</h5> : title}
      {typeof description === 'string' ? (
        <VerticalListItemDescription>{description}</VerticalListItemDescription>
      ) : (
        description
      )}
      {isCompleted && <span className="visuallyhidden">{completedVisuallyHiddenDescription || 'Step completed'}</span>}
    </li>
  );
};
