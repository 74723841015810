import { urls } from 'services/routing';

// Define the bottom banner type here.
export enum BottomBannerType {
  CREATE_COLLECTION = 'CREATE_COLLECTION',
}

// Set the amplitude event and announcement key for the bottom banner based on the type.
export const BOTTOM_BANNERS = {
  [BottomBannerType.CREATE_COLLECTION]: {
    amplitudeEvent: 'createCollectionBannerClick',
    announcementKey: 'Create_Collection_Banner_Oct_2021',
    text: 'collection:createCollectionBannerBottom.text',
    cta: 'collection:createCollectionBannerBottom.button',
    url: urls().CREATE_PAGE,
  },
};
