export enum PredefinedCardsActionTypes {
  CHANGING_STATUS = 'predefined-cards/CHANGING_STATUS',
  STATUS_CHANGED = 'predefined-cards/STATUS_CHANGED',
  CREATING = 'predefined-cards/CREATING',
  CREATED = 'predefined-cards/CREATED',
  DELETING = 'predefined-cards/DELETING',
  DELETED = 'predefined-cards/DELETED',
  FETCHING = 'predefined-cards/FETCHING',
  RECEIVED = 'predefined-cards/RECEIVED',
  FETCHING_ONE = 'predefined-cards/FETCHING_ONE',
  RECEIVED_ONE = 'predefined-cards/RECEIVED_ONE',
  VISUALIZATION_MODE_UPDATED = 'predefined-cards/VISUALIZATION_MODE_UPDATED',
  UPDATING = 'predefined-cards/UPDATING',
  UPDATED = 'predefined-cards/UPDATED',
  UPDATING_BULK = 'predefined-cards/UPDATING_BULK',
  BULK_UPDATED = 'predefined-cards/BULK_UPDATED',
}
