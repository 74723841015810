export enum ActionTypes {
  SKIP_REQUEST = 'alert/SKIP_REQUEST',
  SKIP_SUCCESS = 'alert/SKIP_SUCCESS',
  SKIP_ERROR = 'alert/SKIP_ERROR',
  GET_EFFECTS_REQUEST = 'alert/GET_EFFECTS_REQUEST',
  GET_EFFECTS_SUCCESS = 'alert/GET_EFFECTS_SUCCESS',
  GET_EFFECTS_ERROR = 'alert/GET_EFFECTS_ERROR',
  UPDATE_DEFAULT_EFFECT_REQUEST = 'alert/UPDATE_DEFAULT_EFFECT',
  UPDATE_DEFAULT_EFFECT_SUCCESS = 'alert/UPDATE_DEFAULT_EFFECT_SUCCESS',
  UPDATE_DEFAULT_EFFECT_ERROR = 'alert/UPDATE_DEFAULT_EFFECT_ERROR',
}
