import { paginationStateNormalizer } from 'services/pagination';
import type { OrdersState } from './types';
import { PageActionTypes } from '../page';
import { PAGINATION_STATE_NORMALIZER_CONFIGURATION } from './services/list/constants';
import { OrdersActionTypes } from './actionTypes';

const initialState: OrdersState = {
  list: paginationStateNormalizer.getInitial(PAGINATION_STATE_NORMALIZER_CONFIGURATION),
};

export const reducer = (state = initialState, action): OrdersState => {
  const { type, payload, error } = action;
  switch (type) {
    case OrdersActionTypes.RECEIVED: {
      if (error) {
        return {
          ...state,
          list: paginationStateNormalizer.getReceivedError(action, state.list),
        };
      }
      return {
        ...state,
        list: paginationStateNormalizer.getReceived(payload, PAGINATION_STATE_NORMALIZER_CONFIGURATION),
      };
    }
    case OrdersActionTypes.REQUEST: {
      const newList = paginationStateNormalizer.getRequest(
        state.list,
        payload,
        PAGINATION_STATE_NORMALIZER_CONFIGURATION,
      );
      return {
        ...state,
        list: newList,
      };
    }
    case OrdersActionTypes.NEXT_REQUEST:
      return {
        ...state,
        list: paginationStateNormalizer.getFetchingNextItems(state.list, payload.cancelToken),
      };
    case OrdersActionTypes.NEXT_RECEIVED: {
      if (error) {
        return {
          ...state,
          list: paginationStateNormalizer.getNextReceivedError(state.list),
        };
      }
      return {
        ...state,
        list: paginationStateNormalizer.getNextItemsReceived(state.list, payload),
      };
    }
    case OrdersActionTypes.REQUEST_NEW:
      return {
        ...state,
        list: paginationStateNormalizer.getFetchingNewItems(state.list, payload.cancelToken),
      };
    case OrdersActionTypes.NEW_RECEIVED: {
      if (error) {
        return state;
      }
      return {
        ...state,
        list: paginationStateNormalizer.getNewAndShow(state.list, payload, PAGINATION_STATE_NORMALIZER_CONFIGURATION),
      };
    }
    case PageActionTypes.CHANGED:
      return initialState;
    default:
      return state;
  }
};
