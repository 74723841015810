import * as React from 'react';
import { captureErrorBoundarySentry } from 'utils/sentry';

type IgnoreChildrenOnFatalErrorProps = {
  children: React.ReactNode;
};

type IgnoreChildrenOnFatalErrorState = {
  hasError: boolean;
};

class IgnoreChildrenOnFatalError extends React.Component<
  IgnoreChildrenOnFatalErrorProps,
  IgnoreChildrenOnFatalErrorState
> {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  state = {
    hasError: false,
  };

  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    this.setState({ hasError: true });
    captureErrorBoundarySentry(error, info, true);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return null;
    }

    return children;
  }
}

export default IgnoreChildrenOnFatalError;
