import actionCreator from 'helpers/actionCreator';
import type { UserAuthenticatedAction } from './types';
import { actionTypes } from '../../actionTypes';

const authenticateUser = (accessToken: string): UserAuthenticatedAction =>
  actionCreator.createAction(actionTypes.AUTHENTICATED, {
    authToken: accessToken,
  });

export const authenticationActions = {
  authenticateUser,
};
