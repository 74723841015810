import { ReactNode } from 'react';
import { hasPermissions, usePermissionsSelectors } from 'services/permissions';
import { PermissionsFallback } from 'components/permissions/PermissionsFallback';
import { CheckPermissionsConfiguration } from './types';

export interface PermissionsCheckerProps extends CheckPermissionsConfiguration {
  children: ReactNode;
}

export const PermissionsChecker = ({
  enforcePermissions,
  requiredPermissions,
  fallbackComponent,
  redirectTo,
  redirectToBasePage,
  children,
}: PermissionsCheckerProps): JSX.Element => {
  const { permissions } = usePermissionsSelectors();

  if (!enforcePermissions && !requiredPermissions) {
    return <>{children}</>;
  }

  if (
    (enforcePermissions && !hasPermissions(permissions, enforcePermissions)) ||
    (requiredPermissions && !hasPermissions(permissions, requiredPermissions))
  ) {
    return (
      <PermissionsFallback
        fallbackComponent={fallbackComponent}
        redirectTo={redirectTo}
        redirectToBasePage={redirectToBasePage}
      />
    );
  }

  return <>{children}</>;
};
