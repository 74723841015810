import type { ReactNode } from 'react';
import ClassNames from '@streamloots/classnames';
import Icon from 'ui-library/icons';
import theme from './tabs.scss';

const classNames = ClassNames(theme);

type TabHeaderProps = {
  activeClassName?: string;
  className?: string;
  active: boolean;
  index: number;
  onTabClick?: () => void;
  onTabHeaderClick: (index: number) => void;
  disabled?: boolean;
  icon?: string;
  label: ReactNode;
};

export const TabHeader = ({
  active,
  activeClassName = '',
  className = '',
  icon,
  label,
  disabled,
  index,
  onTabHeaderClick,
  onTabClick,
}: TabHeaderProps): JSX.Element => {
  const handleTabHeaderClick = () => {
    if (active || disabled) {
      return;
    }

    onTabHeaderClick(index);

    if (onTabClick) {
      onTabClick();
    }
  };

  return (
    <div
      className={classNames({
        'tabs__header': true,
        [className]: Boolean(className),
        [activeClassName]: active && Boolean(activeClassName),
        'tabs__header--active': active,
        'tabs__header--disabled': disabled,
      })}
      onClick={handleTabHeaderClick}
      role="tab"
      aria-selected={active ? 'true' : 'false'}
    >
      {icon && <Icon id={icon} className={theme.tabs__header__icon} />}
      {label}
    </div>
  );
};
