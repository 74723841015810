import { checkPermissions, permissions } from 'components/permissions';
import loadable from 'components/dynamic-import';
import { SimplifiedCardSkeletons } from 'components/component-skeletons';
import { FullLayout } from 'scenes/layout';

const ConnectedAdminPredefinedCards = loadable(
  () => import('./ConnectedAdminPredefinedCards'),
  true,
  <SimplifiedCardSkeletons includeWrapper />,
);

const AdminPredefinedCardsPageInternal = () => (
  <FullLayout>
    <ConnectedAdminPredefinedCards />
  </FullLayout>
);

export const AdminPredefinedCardsPage = checkPermissions({
  requiredPermissions: [
    permissions.ADMIN,
    permissions.PARTNERSHIP_MANAGER,
    permissions.ACCOUNT_MANAGER,
    permissions.SUPPORT,
  ],
  redirectToBasePage: true,
})(AdminPredefinedCardsPageInternal);
