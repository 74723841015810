export enum SubscriptionAlertsActionTypes {
  FETCH_ERROR = 'subscription-alerts/FETCH_ERROR',
  FETCH_SUCCESS = 'subscription-alerts/FETCH_SUCCESS',
  FETCH_LOADING = 'subscription-alerts/FETCH_LOADING',

  CREATE_LOADING = 'subscription-alerts/CREATE_LOADING',
  CREATE_ERROR = 'subscription-alerts/CREATE_ERROR',
  CREATE_SUCCESS = 'subscription-alerts/CREATE_SUCCESS',

  DELETE_LOADING = 'subscription-alerts/DELETE_LOADING',
  DELETE_ERROR = 'subscription-alerts/DELETE_ERROR',
  DELETE_SUCCESS = 'subscription-alerts/DELETE_SUCCESS',

  EDIT_LOADING = 'subscription-alerts/EDIT_LOADING',
  EDIT_ERROR = 'subscription-alerts/EDIT_ERROR',
  EDIT_SUCCESS = 'subscription-alerts/EDIT_SUCCESS',

  MY_REACTIONS_FETCH_LOADING = 'subscription-alerts/MY_REACTIONS_FETCH_LOADING',
  MY_REACTIONS_FETCH_ERROR = 'subscription-alerts/MY_REACTIONS_FETCH_ERROR',
  MY_REACTIONS_FETCH_SUCCESS = 'subscription-alerts/MY_REACTIONS_FETCH_SUCCESS',

  MY_REACTIONS_CREATE_LOADING = 'subscription-alerts/MY_REACTIONS_CREATE_LOADING',
  MY_REACTIONS_CREATE_ERROR = 'subscription-alerts/MY_REACTIONS_CREATE_ERROR',
  MY_REACTIONS_CREATE_SUCCESS = 'subscription-alerts/MY_REACTIONS_CREATE_SUCCESS',

  MY_REACTIONS_DELETE_LOADING = 'subscription-alerts/MY_REACTIONS_DELETE_LOADING',
  MY_REACTIONS_DELETE_ERROR = 'subscription-alerts/MY_REACTIONS_DELETE_ERROR',
  MY_REACTIONS_DELETE_SUCCESS = 'subscription-alerts/MY_REACTIONS_DELETE_SUCCESS',
}
