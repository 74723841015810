import { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { LootOrderType } from '@streamloots/streamloots-types';
import ClassNames from '@streamloots/classnames';
import { UserSetLoot } from 'model/lootTS';
import { CloseOverloadedContent, OpenOverloadedContent } from 'services/overloaded-content/typesTS';
import { setsActions, ChangeSelectedSet } from 'services/sets';
import { overloadedContentActions } from 'services/overloaded-content';
import { authSelectors } from 'services/auth';
import { pageSelectors, PageState } from 'services/page';
import { userSetsSelectors } from 'services/user-sets';
import { userSetLootsSelectors, UserSetLootsBySetId } from 'services/user-set-loots';
import { FixedCloseButton } from 'ui-library/fixed-close-button';
import { Checkout, PaymentFooter } from 'scenes/loot-checkout';
import { useAuthTrackingEvents } from 'scenes/auth';
import { DiscountInfo } from 'components/buy-loot-packs-picker/DiscountInfo';
import { setIdToView, userSetsLootsToView } from 'domain/public-page';
import { SectionPacksPrices } from './components/section-packs-prices';
import SupportStreamer from './components/support-streamer';
import SectionSets from './components/section-sets';
import DialogLayout from './components/dialog-layout';
import theme from './dialog-buy-loot-packs-picker.scss';

const classNames = ClassNames(theme);

interface OwnProps {
  onClose: () => void;
  openAsGift?: boolean;
  startLocation?: string;
  typeGift?: LootOrderType.GIFT | LootOrderType.RANDOM_COMMUNITY_GIFT;
}

interface MapStateToProps {
  isUserAuthenticated: boolean;
  page: PageState;
  setId: string;
  collectionLootsFromAllUserSets: Array<UserSetLootsBySetId>;
}

interface MapDispatchToProps {
  openOverloadedContent: OpenOverloadedContent;
  changeSelectedSet: ChangeSelectedSet;
  closeOverloadedContent: CloseOverloadedContent;
}

interface DialogBuyLootPacksPickerProps extends OwnProps, MapStateToProps, MapDispatchToProps {}

const DialogBuyLootPacksPicker = ({
  onClose,
  isUserAuthenticated,
  page,
  collectionLootsFromAllUserSets,
  openOverloadedContent,
  closeOverloadedContent,
  changeSelectedSet,
  setId,
  openAsGift = false,
  startLocation,
  typeGift,
}: DialogBuyLootPacksPickerProps) => {
  const { openModalTrackingEvent, closeModalTrackingEvent } = useAuthTrackingEvents();
  const [internalSetId, setSelectedId] = useState(setId);

  const selectedLootsBySet = useMemo(() => {
    const collectionLoot = collectionLootsFromAllUserSets.find(
      collectionLootInfo => collectionLootInfo.set._id === internalSetId,
    );

    return collectionLoot?.loots ?? [];
  }, [collectionLootsFromAllUserSets, internalSetId]);

  const hasBuyableLootsSelectedSet = useMemo(() => {
    const found = collectionLootsFromAllUserSets.find(collectionLoot => collectionLoot.set._id === internalSetId);
    return found?.hasBuyableLoots ?? false;
  }, [collectionLootsFromAllUserSets, internalSetId]);

  const openLoot = (loot: UserSetLoot) => {
    changeSelectedSet(internalSetId);
    openModalTrackingEvent('checkout');

    openOverloadedContent({
      component: Checkout,
      componentProps: {
        lootId: loot._id,
        isUserAuthenticated,
        slug: page.slug,
        openAsGift,
        startLocation,
        typeGift: openAsGift ? typeGift : undefined,
      },
      wrapperProps: {
        type: isUserAuthenticated ? 'normal' : 'auth',
        noCentered: false,
        onClose: () => {
          closeOverloadedContent();
          if (!isUserAuthenticated) {
            closeModalTrackingEvent('checkout');
          }
        },
      },
    });
  };

  const onChangeSet = (activeId: string) => {
    setSelectedId(activeId);
  };

  return (
    <DialogLayout>
      <DialogLayout.Content>
        <FixedCloseButton onClose={onClose} />
        <div className={classNames('dialog-buy-loot-packs-picker__common-margin')}>
          <SupportStreamer slug={page.slug} avatarImageUrl={page.owner?.profile.avatarImageUrl} />
        </div>
        <DiscountInfo />
        {collectionLootsFromAllUserSets.length > 1 && (
          <div className={classNames('dialog-buy-loot-packs-picker__common-margin')}>
            <SectionSets
              collectionLootsFromAllUserSets={collectionLootsFromAllUserSets}
              setId={internalSetId}
              onChangeSet={activeId => onChangeSet(activeId)}
            />
          </div>
        )}
        <div className={classNames('dialog-buy-loot-packs-picker__common-margin')}>
          <SectionPacksPrices
            loots={selectedLootsBySet}
            buyableLoots={hasBuyableLootsSelectedSet}
            onLootSelected={openLoot}
            level={collectionLootsFromAllUserSets.length > 1 ? 2 : 1}
          />
        </div>
      </DialogLayout.Content>
      <div className={classNames('dialog-buy-loot-packs-picker__footer')}>
        <PaymentFooter />
      </div>
    </DialogLayout>
  );
};

const mapStateToProps = (state): MapStateToProps => {
  const defaultId = userSetsSelectors.setId(state);
  const marathonSet = userSetsSelectors.marathonSpecialSet(state);
  const modules = pageSelectors.pageModules(state);
  const setId = setIdToView(modules, marathonSet, defaultId);
  const allSetLoots = userSetLootsSelectors.collectionLootsFromAllUserSets(state);
  const collectionLootsFromAllUserSets = userSetsLootsToView(setId, modules, marathonSet, allSetLoots);

  return {
    isUserAuthenticated: authSelectors.isUserAuthenticated(state),
    page: pageSelectors.page(state),
    collectionLootsFromAllUserSets,
    setId,
  };
};
export default connect<MapStateToProps, MapDispatchToProps, OwnProps>(mapStateToProps, {
  openOverloadedContent: overloadedContentActions.openOverloadedContent,
  closeOverloadedContent: overloadedContentActions.closeOverloadedContent,
  changeSelectedSet: setsActions.changeSelectedSet,
})(DialogBuyLootPacksPicker);
