import { ValueOf } from 'types/indexTS';

export const PAYMENT_PROCESSORS = {
  PAYPAL: 'paypal',
  PAYSAFECARD: 'paysafecard',
  STRIPE: 'stripe',
} as const;

export const PAYMENT_STATUS = {
  AUTHORIZED: 'AUTHORIZED',
  CREATED: 'CREATED',
  ERROR: 'ERROR',
  EXECUTED: 'EXECUTED',
  INITIAL: 'INITIAL',
  PENDING: 'PENDING',
} as const;

export type PaymentProcessorType = ValueOf<typeof PAYMENT_PROCESSORS>;
export type PaymentStatusType = ValueOf<typeof PAYMENT_PROCESSORS>;

export type PaymentDataDetails = {
  id: string;
  status: string;
};

export type Payment = {
  _id: string;
  amount: number;
  completed: boolean;
  createdAt: string;
  currency: string;
  data: PaymentDataDetails;
  payment: {
    data: {
      links: { href: string; rel: string }[];
      // eslint-disable-next-line
      redirect: { auth_url: string };
    };
  };
};

export type StripePaymentMethod = {
  _id: string;
  brand: string;
  last4: string;
  cardholder: string;
  expMonth: number;
  expYear: number;
};
