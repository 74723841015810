import { CSSTransition } from 'react-transition-group';
import type { DialogProps } from './BaseDialog';
import { BaseDialog } from './BaseDialog';
import theme from './dialog.scss';

export const Dialog = (props: DialogProps): JSX.Element => {
  return (
    <CSSTransition
      timeout={2000}
      mountOnEnter
      unmountOnExit
      in={props.active}
      classNames={{
        enter: theme.enter,
        enterActive: theme.enterActive,
        exit: theme.exit,
        exitActive: theme.exitActive,
        exitDone: theme.exitDone,
      }}
    >
      <BaseDialog {...props} />
    </CSSTransition>
  );
};
