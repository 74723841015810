import { PageActionTypes } from 'services/page';
import type { CraftingModeState } from './types';
import { CraftingModeActionTypes } from './actionTypes';

const initialState: CraftingModeState = {
  dustBalance: 0,
};

export const craftingModeReducer = (state: CraftingModeState = initialState, action): CraftingModeState => {
  const { type, error } = action;
  switch (type) {
    case PageActionTypes.RECEIVED: {
      if (error) {
        return state;
      }
      const { dustBalance } = action.payload;
      return {
        ...state,
        dustBalance,
      };
    }
    case PageActionTypes.CHANGED:
      return initialState;
    case CraftingModeActionTypes.DISENCHANTED:
    case CraftingModeActionTypes.CRAFTED: {
      if (error) {
        return state;
      }

      const { dustBalance } = action.payload;
      return {
        ...state,
        dustBalance,
      };
    }
    default:
      return state;
  }
};
