import type { User, Experiment } from 'model/indexTS';
import reducerAsyncStateCreator from 'helpers/stateCreator';
import { alertActionTypes } from 'services/alert';
import { OnboardingActionTypes } from 'services/onboarding';
import type { UserState } from './types';
import { UserActionTypes } from './actionTypes';

const initialState: UserState = {
  user: null,
  mustAcceptTerms: false,
};

export const getInitialState = (experiments: Experiment[]): UserState => ({
  experiments: [...experiments],
});

export default (state: UserState = initialState, action): UserState => {
  const { type, error, metadata } = action;
  const { payload } = action;

  switch (type) {
    case UserActionTypes.USER_PREFERENCES_UPDATED:
    case UserActionTypes.UPDATED_PROFILE:
    case UserActionTypes.UPDATED: {
      if (error) {
        return reducerAsyncStateCreator.getInitialAsyncState(state);
      }

      const { user } = state;
      return reducerAsyncStateCreator.getInitialAsyncState(state, {
        ...payload,
        user: {
          ...user,
          ...payload.user,
        },
      });
    }
    case UserActionTypes.UPDATED_SOCIAL_MEDIA:
    case UserActionTypes.RECEIVED:
      if (error && metadata.businessCode === 'NETWORK_ERROR') {
        return reducerAsyncStateCreator.getInitialAsyncState(state);
      }

      if (error) {
        return reducerAsyncStateCreator.getAsyncErrorState(action, state);
      }

      return reducerAsyncStateCreator.getInitialAsyncState(state, {
        ...payload,
      });
    case UserActionTypes.REQUEST:
      return reducerAsyncStateCreator.getAsyncLoadingState(state);

    // TODO Use this for introduce Immer.
    case UserActionTypes.UPDATED_USER_EMAIL: {
      if (error) {
        return state;
      }

      const { email, expiredAt, status } = payload;
      const lastEmailVerification = { email, expiredAt, status };
      const user = state.user as User;

      return {
        ...state,
        user: {
          ...user,
          profile: {
            ...user.profile,
            primaryEmail: email,
            lastEmailVerification,
          },
        },
      };
    }

    case alertActionTypes.UPDATE_DEFAULT_EFFECT_SUCCESS: {
      const user = state.user as User;
      return {
        ...state,
        user: {
          ...user,
          settings: {
            ...user.settings,
            defaultAlertEffect: payload,
          },
        },
      };
    }

    case OnboardingActionTypes.FINALIZED: {
      if (error) {
        return state;
      }

      return {
        ...state,
        showOnboarding: false,
      };
    }

    default:
      return state;
  }
};
