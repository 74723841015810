import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import { errorMapping } from '../../errorMapping';
import { CouponActionTypes } from '../../actionTypes';
import type { FetchSingleCouponActionError, FetchSingleCouponActionSuccess } from './types';

const fetchSingleCouponSuccess = ({ data, parameters }): FetchSingleCouponActionSuccess =>
  actionCreator.createAction(CouponActionTypes.RECEIVED_SINGLE_COUPON, data, parameters);

const fetchSingleCouponError = ({ errorData }): FetchSingleCouponActionError =>
  actionCreator.createErrorAction(CouponActionTypes.RECEIVED_SINGLE_COUPON, errorData, errorMapping);

const fetchSingleCoupon = (slug: string, code: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.COUPONS.GET_SPECIFIC,
    errorBinding: fetchSingleCouponError,
    method: 'get',
    urlParameters: { slug, code },
    successBinding: fetchSingleCouponSuccess,
  });

export const couponFetchSingleActions = {
  fetchSingleCoupon,
};
