import * as React from 'react';
import type { AsyncActionError } from 'types/indexTS';
import Spinner, { SpinnerPanel } from 'ui-library/loading-spinner';
import { Alert } from 'ui-library/alert';
import { AsyncRender } from 'components/async-render';

interface AsyncComponentLoaderProps {
  isLoaded: boolean;
  ignoreErrorIfLoaded?: boolean;
  fullPageLoading?: boolean;
  renderComponent: () => React.ReactElement | null;
  renderError?: () => React.ReactElement | null;
  renderLoading?: () => React.ReactElement | null;
  error?: AsyncActionError | null;
  timeout?: number;
}

export const AsyncComponentLoader = ({
  error,
  isLoaded,
  renderComponent,
  ignoreErrorIfLoaded = true,
  timeout = 500,
  renderError,
  renderLoading,
  fullPageLoading,
}: AsyncComponentLoaderProps): React.ReactElement | null => {
  if (error && (!ignoreErrorIfLoaded || !isLoaded)) {
    if (renderError) {
      return renderError();
    }
    return <Alert type={Alert.ALERT_TYPES.ERROR}>{error.message}</Alert>;
  }

  if (!isLoaded) {
    if (renderLoading) {
      return (
        <AsyncRender loading timeout={timeout}>
          {renderLoading()}
        </AsyncRender>
      );
    }

    if (fullPageLoading) {
      return <Spinner opaque />;
    }

    return <SpinnerPanel />;
  }

  return renderComponent();
};
