import { ComponentType } from 'react';
import { PermissionsChecker } from './PermissionsChecker';
import { CheckPermissionsConfiguration } from './types';

export const checkPermissions = (options: CheckPermissionsConfiguration) => <WrappedComponentProps,>(
  WrappedComponent: ComponentType<WrappedComponentProps>,
) => {
  return (props: WrappedComponentProps): JSX.Element => {
    return (
      <PermissionsChecker {...options}>
        <WrappedComponent {...props} />
      </PermissionsChecker>
    );
  };
};
