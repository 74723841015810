import { Set } from 'model/indexTS';
import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import { CancelToken } from 'types/indexTS';
import type { SelectedSetChangedAction, SetsReceivedActionSuccess, SetsReceivedActionError } from './types';
import { SetsActionTypes } from '../../actionTypes';
import normalizer from '../../normalizer';

const fetchSetsSuccess = ({ data }): SetsReceivedActionSuccess =>
  actionCreator.createAction(SetsActionTypes.RECEIVED, {
    data: normalizer.sets(data.data),
  });

const fetchSetsError = ({ errorData }): SetsReceivedActionError =>
  actionCreator.createErrorAction(SetsActionTypes.RECEIVED, errorData);

const fetchSets = (slug: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.SETS.GET,
    errorBinding: fetchSetsError,
    loadingAction: SetsActionTypes.REQUEST,
    method: 'get',
    parameters: { slug },
    successBinding: fetchSetsSuccess,
  });

const changeSelectedSet = (setId: string, doNotReloadSet?: boolean): SelectedSetChangedAction =>
  actionCreator.createAction(SetsActionTypes.SELECTED_SET_CHANGED, {
    doNotReloadSet,
    setId,
  });

const setsOrderFailed = ({ errorData }): SetsReceivedActionError =>
  actionCreator.createErrorAction(SetsActionTypes.ORDER_FAILED, errorData);

const setsOrderedSuccess = ({ data }): SetsReceivedActionSuccess =>
  actionCreator.createAction(SetsActionTypes.ORDER_SUCCESS, {
    data,
  });

const orderingSets = (orderedSets: Set[], cancelToken: CancelToken) =>
  actionCreator.createAction(SetsActionTypes.RECEIVED, {
    data: orderedSets,
    cancelToken,
  });

const orderSets = (sets: Set[], pageId: string) => {
  const orderedSets = [...sets].sort(normalizer.byOrder);
  const payload = { ids: orderedSets.map(set => set._id) };

  return actionCreator.createAsyncAction({
    endpoint: endpoints.SETS.ORDER,
    errorBinding: setsOrderFailed,
    successBinding: setsOrderedSuccess,
    loadingAction: cancelToken => orderingSets(orderedSets, cancelToken),
    method: 'post',
    urlParameters: { pageId },
    parameters: payload,
  });
};

export const setListActions = {
  fetchSets,
  changeSelectedSet,
  orderSets,
};
