import { ResType } from 'types/indexTS';
import cookies from 'js-cookie';
import trackEventManager from '../event-manager';

export const GCLID_COOKIE = 'GCLID_CAMPAIGN';

export type GLink = {
  gclid?: string;
};

const setGLinkCookie = (query: GLink, res: ResType, domain: string): void => {
  if (!query || !query.gclid) {
    return;
  }

  const nextWeek = new Date();
  nextWeek.setDate(nextWeek.getDate() + 7);

  res.cookie(GCLID_COOKIE, JSON.stringify({ gclid: query.gclid }), {
    expires: nextWeek,
    domain,
    encode: String,
  });
};

const getGLinkObject = (reqCookies?: any): GLink | null => {
  const gclidString = reqCookies ? reqCookies[GCLID_COOKIE] : cookies.get(GCLID_COOKIE);

  if (!gclidString) {
    return null;
  }

  return JSON.parse(gclidString);
};

const sendComeFromGLinkEvent = (): void => {
  const cookieObject = getGLinkObject();
  if (!cookieObject) {
    return;
  }

  trackEventManager.comeFromGLink(cookieObject);
};

export default {
  setGLinkCookie,
  sendComeFromGLinkEvent,
};
