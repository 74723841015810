import { Payment } from 'model/paymentsTS';
import stateCreator from 'helpers/stateCreator';
import type { PaymentState, PaymentActions } from './types';
import { FetchPaymentOrderActionTypes } from './services/fetch-payment-oder';
import { SavedStripedMethodsListActionTypes } from './services/saved-stripe-payment-methods-list';
import { StripePaymentActionTypes } from './services/stripe-payment';
import { DeleteStripePaymentMethodActionTypes } from './services/delete-stripe-payment-method';

const initialState: PaymentState = {
  creditCardInvalidAttempt: 0,
  savedStripePaymentMethodsStatus: {},
};

const getPaymentStatus = (payload: Payment) => {
  switch (payload.data.status) {
    case 'SUCCESS':
      return 'success';
    case 'CANCELED_CUSTOMER':
    case 'CANCELED_MERCHANT':
      return 'canceled';
    default:
      return 'error';
  }
};

export const reducer = (state: PaymentState = initialState, action: PaymentActions): PaymentState => {
  switch (action.type) {
    case FetchPaymentOrderActionTypes.FETCHING_ORDER: {
      return { ...state, paymentStatus: '' };
    }
    case FetchPaymentOrderActionTypes.ORDER_RECEIVED:
      if (action.error) {
        return { ...state, paymentStatus: 'notRetrieved' };
      }

      return { ...state, paymentStatus: getPaymentStatus(action.payload) };
    case StripePaymentActionTypes.STRIPE_ORDER_EXECUTED: {
      if (action.error) {
        const { creditCardInvalidAttempt } = state;
        let errorCount = creditCardInvalidAttempt;
        const creditCardBlockedAt = new Date();
        const { metadata } = action;
        if (metadata.businessCode === 'card_error') {
          errorCount += 1;
        }
        return {
          ...state,
          creditCardBlockedAt: errorCount > 3 ? creditCardBlockedAt : undefined,
          creditCardInvalidAttempt: errorCount,
        };
      }
      return state;
    }
    case SavedStripedMethodsListActionTypes.FETCHING_SAVED_STRIPE_PAYMENT_METHODS:
      return {
        ...state,
        savedStripePaymentMethodsStatus: stateCreator.getAsyncLoadingState(state.savedStripePaymentMethodsStatus),
      };
    case SavedStripedMethodsListActionTypes.SAVED_STRIPE_PAYMENT_METHODS_RECEIVED: {
      if (action.error) {
        return {
          ...state,
          savedStripePaymentMethodsStatus: stateCreator.getAsyncErrorState(
            action,
            state.savedStripePaymentMethodsStatus,
          ),
        };
      }

      return {
        ...state,
        savedStripePaymentMethodsStatus: stateCreator.getInitialAsyncState(state.savedStripePaymentMethodsStatus, {
          savedMethods: action.payload.data,
        }),
      };
    }
    case DeleteStripePaymentMethodActionTypes.DELETE_STRIPE_PAYMENT_METHOD_SUCCESS: {
      const { savedStripePaymentMethodsStatus } = state;
      const { savedMethods = [] } = savedStripePaymentMethodsStatus;
      const { paymentMethodId } = action.payload;

      return {
        ...state,
        savedStripePaymentMethodsStatus: {
          ...savedStripePaymentMethodsStatus,
          savedMethods: savedMethods.filter(method => method._id !== paymentMethodId),
        },
      };
    }
    default:
      return state;
  }
};
