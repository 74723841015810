export enum AuctionActionTypes {
  GET_AUCTIONS_REQUEST = 'auction/GET_AUCTIONS_REQUEST',
  GET_AUCTIONS_SUCCESS = 'auction/GET_AUCTIONS_SUCCESS',
  GET_AUCTIONS_ERROR = 'auction/GET_AUCTIONS_ERROR',
  FETCH_SINGLE_AUCTION_REQUEST = 'auction/FETCH_SINGLE_AUCTION_REQUEST',
  FETCH_SINGLE_AUCTION_SUCCESS = 'auction/FETCH_SINGLE_AUCTION_SUCCESS',
  FETCH_SINGLE_AUCTION_ERROR = 'auction/FETCH_SINGLE_AUCTION_ERROR',
  POST_BID_REQUEST = 'auction/POST_BID_REQUEST',
  POST_BID_SUCCESS = 'auction/POST_BID_SUCCESS',
  POST_BID_ERROR = 'auction/POST_BID_ERROR',
  COUNTDOWN_ENDED = 'auction/COUNTDOWN_ENDED',
  EXECUTE_BID_ORDER_REQUEST = 'auction/EXECUTE_BID_ORDER_REQUEST',
  EXECUTE_BID_ORDER_ERROR = 'auction/EXECUTE_BID_ORDER_ERROR',
  EXECUTE_BID_ORDER_SUCCESS = 'auction/EXECUTE_BID_ORDER_SUCCESS',
  CREATE_BID_ORDER_REQUEST = 'auction/CREATE_BID_ORDER_REQUEST',
  CREATE_BID_ORDER_ERROR = 'auction/CREATE_BID_ORDER_ERROR',
  CREATE_BID_ORDER_SUCCESS = 'auction/CREATE_BID_ORDER_SUCCESS',
  POST_AUCTION_REQUEST = 'auction/POST_AUCTION_REQUEST',
  POST_AUCTION_SUCCESS = 'auction/POST_AUCTION_SUCCESS',
  POST_AUCTION_ERROR = 'auction/POST_AUCTION_ERROR',
  PUT_AUCTION_REQUEST = 'auction/PUT_AUCTION_REQUEST',
  PUT_AUCTION_SUCCESS = 'auction/PUT_AUCTION_SUCCESS',
  PUT_AUCTION_ERROR = 'auction/PUT_AUCTION_ERROR',
  PATCH_AUCTION_REQUEST = 'auction/PATCH_AUCTION_REQUEST',
  PATCH_AUCTION_SUCCESS = 'auction/PATCH_AUCTION_SUCCESS',
  PATCH_AUCTION_ERROR = 'auction/PATCH_AUCTION_ERROR',
}
