import { PagesModules } from '@streamloots/streamloots-types';
import type { RouteTabInfo } from 'components/route-tabs';
import { permissions } from 'components/permissions';
import loadable from 'components/dynamic-import';
import { ConfigureCooldown } from 'scenes/cooldown';

const ConnectedCustomizePage = loadable(() => import('./ConnectedPageSettings'));

export const PAGE_SETTINGS_TABS: RouteTabInfo[] = [
  {
    component: ConnectedCustomizePage,
    i18nKey: 'common:generalSettings',
    urlPathKey: 'general',
    permissions: permissions.PAGE_EDIT,
    urlKey: 'CONFIGURE_GENERAL',
  },
  {
    component: ConfigureCooldown,
    i18nKey: 'Cooldown',
    urlPathKey: 'cooldown',
    permissions: permissions.COOLDOWN_EDIT,
    requiredModules: PagesModules.Cards,
    urlKey: 'CONFIGURE_COOLDOWN',
    showNew: true,
  },
];
