import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { Link } from 'react-router-dom';
import type { UserPreferences } from 'model/userTS';
import type { UpdateUserPreferences } from 'services/user/types';
import { userActions, userSelectors } from 'services/user';
import trackEventManager from 'utils/event-manager';
import Button, { ButtonIcon } from 'ui-library/button';
import { TranslateInterface, withTranslation } from 'utils/translation';
import { UsageIntention } from '@streamloots/streamloots-types';
import { Trans } from 'react-i18next';
import theme from './bottom-banner.scss';
import { BottomBannerType, BOTTOM_BANNERS } from './bottom-banners';

interface OwnProps {
  type: BottomBannerType;
}

interface MapStateToProps {
  userPreferences: UserPreferences;
  showOnboarding: boolean;
  usageIntention: UsageIntention | null;
}

interface MapDispatchToProps {
  updateUserPreferences: UpdateUserPreferences;
}

interface ExtendedProps extends OwnProps, MapStateToProps, MapDispatchToProps, TranslateInterface {}

const classNames = ClassNames(theme);

const BottomBannerBase = ({
  type,
  userPreferences,
  updateUserPreferences,
  t,
  showOnboarding,
  usageIntention,
}: ExtendedProps): JSX.Element | null => {
  const { announcements = {} } = userPreferences;
  const { amplitudeEvent, announcementKey, text, cta, url } = BOTTOM_BANNERS[type];

  if (announcements[announcementKey] || !showOnboarding || usageIntention === UsageIntention.Viewer) {
    return null;
  }

  const handleClick = (buttonId: string): void => {
    trackEventManager.trackEvent(amplitudeEvent, { buttonId });

    updateUserPreferences({
      ...userPreferences,
      announcements: {
        ...announcements,
        [announcementKey]: true,
      },
    });
  };

  return (
    <div className={classNames('bottom-banner')}>
      <div
        className={classNames({
          'bottom-banner__wrapper': true,
          'bottom-banner__wrapper--createCollection': type === BottomBannerType.CREATE_COLLECTION,
        })}
      >
        <div className={classNames('bottom-banner__close')}>
          <ButtonIcon onClick={() => handleClick('close')} asLink icon="close" ariaLabel="Close" />
        </div>
        <p className={classNames('bottom-banner__text')}>
          <Trans i18nKey={text}>
            Are you <span>Streamer</span>? Create your own Streamloots page and start connecting with your community and
            monetizing your content.
          </Trans>
        </p>
        <Button
          className={classNames('bottom-banner__button')}
          component={Link}
          onClick={() => handleClick('cta')}
          to={url}
          primary
        >
          {t(cta)}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state): MapStateToProps => ({
  userPreferences: userSelectors.userPreferences(state),
  showOnboarding: userSelectors.showOnboarding(state),
  usageIntention: userSelectors.usageIntention(state),
});

export const BottomBanner = compose<React.FunctionComponent<OwnProps>>(
  withTranslation('collection'),
  connect(mapStateToProps, {
    updateUserPreferences: userActions.updateUserPreferences,
  }),
)(BottomBannerBase);
