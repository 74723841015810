import ClassNames from '@streamloots/classnames';
import Icon from 'ui-library/icons';
import type { BaseButtonProps } from './types';
import theme from './buttons.scss';

const classNames = ClassNames(theme);

export const BaseButtonComponent = ({
  className = '',
  type = 'button',
  component = 'button',
  variant,
  color,
  size,
  block,
  children,
  label,
  primary,
  responsive,
  selected,
  disabled,
  cta,
  loading,
  warning,
  asLink,
  asRadio,
  weird,
  small,
  withInfo,
  inverted,
  dangerous,
  safe,
  ariaLabel,
  ...rest
}: BaseButtonProps): JSX.Element => {
  const Component = component;
  return (
    <Component
      aria-label={ariaLabel}
      type={type}
      className={classNames({
        button: true,
        [`button--${size}`]: Boolean(size),
        [`button--${variant}`]: Boolean(variant),
        [`button--${color}`]: Boolean(color),
        responsive,
        disabled: disabled || loading,
        block,
        primary,
        cta,
        loading,
        warning,
        asLink,
        asRadio,
        weird,
        selected,
        small,
        withInfo,
        inverted,
        dangerous,
        safe,
        [className]: Boolean(className),
      })}
      disabled={disabled || loading}
      {...rest}
    >
      {loading && (
        <span className={classNames('button__loading-icon')}>
          <Icon id="loading" />
        </span>
      )}
      {label || children}
    </Component>
  );
};
