import cookies from 'js-cookie';
import config from '../../config';

export const REDIRECTION_COOKIE = 'redirectTo';

export default {
  setPathname(pathname?: string) {
    cookies.set(REDIRECTION_COOKIE, pathname, {
      domain: config.streamloots.domain,
    });
  },
  getPathname(): string {
    return cookies.get(REDIRECTION_COOKIE) || '';
  },
};
