import { Redirect } from 'react-router-dom';
import { usePageSelectors } from 'services/page';
import { FullLayout } from 'scenes/layout';
import { urls } from 'services/routing';
import { useTranslation } from 'utils/translation';
import { BigColumnPanel, SmallColumnPanel, TwoColumnLayout } from 'ui-library/two-column-panel';
import { checkPermissions, permissions } from 'components/permissions';
import { AuctionExplanationPanel } from 'components/auction-explanation-panel';
import { HelpOpener, articlesKeys } from 'components/help';
import loadable from 'components/dynamic-import';
import { AuctionListHeader } from './components/auction-list-header';

const ConnectedAuctionList = loadable(() => import('./ConnectedAuctionList'));

export const BaseAuctionListPage = (): JSX.Element => {
  const {
    page: { slug, modules },
  } = usePageSelectors();
  const { t } = useTranslation('auction');

  if (modules && !modules.auctions.enabled) {
    return <Redirect to={urls().ENABLE_AUCTIONS} />;
  }

  return (
    <FullLayout>
      <AuctionListHeader slug={slug} t={t} />
      <TwoColumnLayout>
        <BigColumnPanel>
          <ConnectedAuctionList />
        </BigColumnPanel>
        <SmallColumnPanel>
          <AuctionExplanationPanel
            title={t('auction:auctions.whatIsAuction')}
            description={t('auction:auctions.whatIsAuctionDetail')}
          >
            <HelpOpener asLink articleKey={articlesKeys.AUCTION} label={t('auction:tips.showMore')} />
          </AuctionExplanationPanel>
        </SmallColumnPanel>
      </TwoColumnLayout>
    </FullLayout>
  );
};

export const AuctionListPage = checkPermissions({
  requiredPermissions: [permissions.AUCTION_EDIT],
})(BaseAuctionListPage);
