import * as React from 'react';

export interface DropdownMenuRenderContentArguments {
  onHideMenu: () => void;
}

export interface DropdownMenuRenderComponentArguments {
  active: boolean;
  onClick: (event: any) => void;
}

export enum DropdownMenuPosition {
  BOTTOM_LEFT = 'bottomLeft',
  BOTTOM_RIGHT = 'bottomRight',
  TOP_LEFT = 'topLeft',
  TOP_RIGHT = 'topRight',
  CUSTOM = 'custom',
}

export type DropdownMenuRenderContentProp = (props: DropdownMenuRenderContentArguments) => React.ReactNode;
