import type { Set, SET_RECEIVED, SetRarities, SetRarity } from 'model/indexTS';
import { COLLECTION_IMAGES } from 'constants/collection-images';
import { SET_UPDATED } from 'model/setsTS';
import type { SetListsStateProps } from './services/set-list/types';
import setUtils from './setUtils';

interface SetListStateParams {
  setsData: Array<Set>;
  updatedSetId?: string;
  newSetInfo: Partial<Set>;
}

const rarities = (raritiesData: Array<SetRarity>): SetRarities => {
  const normalizedRarity: Partial<SetRarities> = {};
  raritiesData.forEach(rarity => {
    normalizedRarity[rarity.name] = rarity;
  });
  return normalizedRarity as SetRarities;
};

const set = (setData: SET_UPDATED): Partial<Set> => {
  return {
    ...setData,
    tags: setData.tagsDetails || [],
    rarities: setData.rarities && rarities(setData.rarities),
  };
};

const getFullSet = (setData: SET_RECEIVED, index = 0): Set => {
  return {
    ...setData,
    tags: setData.tagsDetails || [],
    imageUrl: setData.imageUrl || COLLECTION_IMAGES[index % 4],
    rarities: setData.rarities && rarities(setData.rarities),
    order: setData.order ?? index,
  };
};

const sets = (setsData: Array<SET_RECEIVED>): Array<Set> =>
  setsData
    .map(getFullSet)
    .filter(setReceived => !setReceived.marathonDefault)
    .sort(setUtils.byOrder);

const setListStateProps = (params: SetListStateParams): SetListsStateProps => {
  const { setsData, updatedSetId = '', newSetInfo } = params;

  const setsById = {};
  const setImagesByUrl = {};
  let setsPublishedCount = 0;
  const newSets = setsData.map(setInfo => {
    const { _id: setId } = setInfo;

    if (updatedSetId === setId) {
      setsById[setId] = {
        ...setInfo,
        resumeAt: newSetInfo.resumeAt || undefined,
        ...newSetInfo,
      };
    } else {
      setsById[setId] = setInfo;
    }

    const updatedSet = setsById[setId];

    if (updatedSet.published && !updatedSet.marathonDefault) {
      setsPublishedCount += 1;
    }

    const setImageUrl = updatedSet.imageUrl;
    setImagesByUrl[setImageUrl] = true;
    return updatedSet;
  });

  return {
    sets: newSets.sort(setUtils.byOrder),
    setsById,
    setsPublishedCount,
    setImagesByUrl,
    defaultSetId: setUtils.getDefaultId(newSets),
  };
};

export default {
  set,
  sets,
  rarities,
  byOrder: setUtils.byOrder,
  setListStateProps,
};
