import stateCreator from 'helpers/stateCreator';
import { MarathonStates } from 'model/indexTS';
import { PageActionTypes } from 'services/page';
import { MarathonActions, MarathonState } from './types';
import { MarathonActionTypes } from './actionTypes';

const initialState: MarathonState = {};

export const reducer = (state: MarathonState = initialState, action: MarathonActions): MarathonState => {
  switch (action.type) {
    case MarathonActionTypes.RECEIVED: {
      if (action.error) {
        return stateCreator.getAsyncErrorState(action, state, initialState);
      }

      if (action.payload === null) {
        return stateCreator.getInitialAsyncState(state, {
          marathonConfig: null,
          hasConfigSaved: false,
        });
      }

      return stateCreator.getInitialAsyncState(state, {
        marathonConfig: action.payload,
        hasConfigSaved: true,
      });
    }

    case MarathonActionTypes.TRACKER_RECEIVED_SUCCESS: {
      if (!action.payload) {
        return { ...state, marathonStatus: MarathonStates.STOPPED };
      }

      const marathonTracker = action.payload;
      const marathonStatus = marathonTracker.finished ? MarathonStates.STOPPED : MarathonStates.ACTIVE;

      return { ...state, marathonStatus, marathonTracker };
    }

    case MarathonActionTypes.ADDED_TIME: {
      if (action.error) {
        return state;
      }

      return { ...state, marathonTracker: action.payload };
    }

    case MarathonActionTypes.CREATED:
    case MarathonActionTypes.UPDATED: {
      if (action.error) {
        return state;
      }

      return { ...state, hasConfigSaved: true, marathonConfig: action.payload };
    }

    case MarathonActionTypes.STARTED: {
      if (action.error) {
        return state;
      }

      return { ...state, marathonTracker: action.payload, marathonStatus: MarathonStates.ACTIVE };
    }

    case MarathonActionTypes.STOPPED: {
      if (action.error) {
        return state;
      }

      return { ...state, marathonTracker: action.payload, marathonStatus: MarathonStates.STOPPED };
    }

    case MarathonActionTypes.MARATHON_STATUS_CHANGED_SUCCESS: {
      return {
        ...state,
        marathonTracker: action.payload,
      };
    }

    case MarathonActionTypes.FETCHING:
      return stateCreator.getAsyncLoadingState(state, { reconcile: false });

    case PageActionTypes.CHANGED:
      return initialState;

    default:
      return state;
  }
};
