import ClassNames from '@streamloots/classnames';
import theme from './christmas-decoration.scss';

const classNames = ClassNames(theme);

export const ChristmasDecoration = (): JSX.Element => {
  return (
    <>
      <div className={classNames('caramel')} />
      <ul className={classNames('lightrope')}>
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
      </ul>
    </>
  );
};
