import Dialog from 'ui-library/dialog';
import { SignOutManager } from '../auth';
import { UserErrorDialogContent } from './UserErrorDialogContent';

type UserInfoErrorDialogProps = {
  type: 'invalidateToken' | 'errorLoading';
};

export const UserErrorDialog = ({ type }: UserInfoErrorDialogProps): JSX.Element => {
  const handleClose = () => {
    window.location.reload();
  };

  return (
    <Dialog
      active
      onClose={handleClose}
      onEscKeyDown={handleClose}
      onOverlayClick={handleClose}
      renderDialogBody={() => (
        <SignOutManager
          refreshPage={false}
          renderComponent={signOut => <UserErrorDialogContent signOut={signOut} type={type} />}
        />
      )}
    />
  );
};
