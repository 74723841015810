import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import { SetStatusChangedActionError, SetStatusChangedActionSuccess } from '../../types';
import { errorMapping } from '../../errorMapping';
import { SetsActionTypes } from '../../actionTypes';

const setArchived = ({ urlParameters }): SetStatusChangedActionSuccess =>
  actionCreator.createAction(SetsActionTypes.ARCHIVED, {
    setId: urlParameters.setId,
  });

const setNotArchived = ({ errorData, urlParameters }): SetStatusChangedActionError =>
  actionCreator.createErrorAction(SetsActionTypes.ARCHIVED, errorData, errorMapping, {
    setId: urlParameters.setId,
  });

const archiveSet = (setId: string, giveResources: boolean) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.SETS.ARCHIVE,
    errorBinding: setNotArchived,
    loadingAction: SetsActionTypes.ARCHIVING,
    method: 'post',
    urlParameters: { setId },
    parameters: { giveResources },
    successBinding: setArchived,
  });

export const deleteSetActions = {
  archiveSet,
};
