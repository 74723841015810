import cookies from 'js-cookie';

export const AUTH_COOKIE_NAME = 'AUTH_TOKEN';

const authHelper = {
  getAuthToken(): string {
    return cookies.get(AUTH_COOKIE_NAME) as string | '';
  },
  getAuthCookieFromRequest(reqCookies: Record<string, unknown>): string {
    return (reqCookies[AUTH_COOKIE_NAME] as string) || '';
  },
  isUserAuthenticated(): boolean {
    return typeof cookies.get(AUTH_COOKIE_NAME) !== 'undefined';
  },
  signInUser(authToken: string, remember?: boolean): void {
    const expires = remember ? { expires: 30 } : {};
    cookies.set(AUTH_COOKIE_NAME, authToken, expires);
  },
  signOutUser(): void {
    cookies.remove(AUTH_COOKIE_NAME);
  },
};

export default authHelper;
