import { Tooltip, TooltipPlacement, TooltipPlacementWithStrings, TooltipProps } from './Tooltip';

interface OptionalTooltipProps extends Pick<TooltipProps, 'trigger'> {
  children: React.ReactNode;
  tooltip?: string | React.ReactNode;
  tooltipClassName?: string;
  placement?: TooltipPlacementWithStrings;
}

export const OptionalTooltip = ({
  children,
  tooltip,
  placement = TooltipPlacement.BOTTOM,
  tooltipClassName,
  trigger,
}: OptionalTooltipProps): JSX.Element => {
  if (!tooltip) {
    return <>{children}</>;
  }

  return (
    <Tooltip placement={placement} tooltip={tooltip} className={tooltipClassName} trigger={trigger}>
      {children}
    </Tooltip>
  );
};
