import config from '../config';

const paramsToQueryStream = (params: Record<string, string>): string => {
  const codifiedParams = Object.keys(params)
    .map(param => `${param}=${encodeURIComponent(params[param])}`)
    .join('&');
  return `?${codifiedParams}`;
};

const urlToParams = (urlString: string): Record<string, string> | null => {
  if (!urlString) {
    return null;
  }

  const params = urlString.substr(1);
  const paramsObject: Record<string, string> = {};
  params.split('&').forEach(param => {
    const [name, value] = param.split('=');
    paramsObject[name] = value;
  });
  return paramsObject;
};

const getSearchParams = (completeUrl?: string): Record<string, string> | null => {
  if (!completeUrl) {
    return null;
  }

  const paramsString = completeUrl.split('?')[1];
  if (!paramsString) {
    return null;
  }
  return urlToParams(`?${paramsString}`);
};

const buildDomainUrl = (relativePath: string): string => {
  return __DEV__ ? `http://localhost:3000${relativePath}` : `https://www${config.streamloots.domain}${relativePath}`;
};

const buildDomainUrlWithoutWWW = (relativePath: string): string => {
  const url = __DEV__ ? `localhost:3000${relativePath}` : `${config.streamloots.domain}${relativePath}`;
  return url[0] === '.' ? url.substr(1) : url;
};

export default {
  paramsToQueryStream,
  urlToParams,
  getSearchParams,
  buildDomainUrl,
  buildDomainUrlWithoutWWW,
};
