import { matchPath } from 'react-router-dom';
import { pageSelectors } from '../page';

export const pages = {
  ACHIEVEMENTS: '/achievements',
  ADMIN_PAYOUTS: '/admin/payouts',
  ADMIN_COUPONS: '/admin/coupons',
  ADMIN_ORDER_FRAMES: '/admin/order-frames',
  ADMIN_PREDEFINED_CARDS: '/admin/predefined-cards',
  ADMIN_PREDEFINED_CARDS_EDIT: '/admin/predefined-cards/:templateId',
  ADMIN_PREDEFINED_CARDS_CREATE: '/admin/predefined-cards/create',
  ADMIN_PREDEFINED_FRAMES: '/admin/predefined-frames',
  ADMIN_PARTNERSHIP_APPLICATIONS: '/admin/partnership-applications',
  ADMIN_TAGS: '/admin/tags',
  ADMIN_VIEWER: '/admin/viewer/:slug',
  ADMINISTRATION: '/:slug/admin',
  WIDGETS: '/:slug/widgets',
  ALERTS: '/:slug/widgets/alerts',
  GOALS: '/:slug/widgets/goals',
  GOALS_PATH: '/:slug/widgets/goals/:tab?',
  GOALS_PACKS: '/:slug/widgets/goals/packs',
  GOALS_SUBS: '/:slug/widgets/goals/subscriptions',
  TIMER: '/:slug/widgets/timer',
  CHAT_BOT: '/:slug/widgets/chat-bot',
  STREAMDECK: '/:slug/widgets/streamdeck',
  OBS_PLUGIN: '/:slug/widgets/obs-plugin',
  AUTHENTICATE_TEST: '/:slug/authenticate-tests',
  BILLING_CONFIGURATION: '/:slug/revenue/configuration',
  BILLING_INFORMATION_CONFIGURATION: '/:slug/revenue/billing-information',
  INVOICES: '/:slug/revenue/invoices',
  CARD_SUGGESTIONS: '/:slug/card-suggestions',
  ADMIN_NOTIFICATIONS: '/admin/notifications',
  MANAGE_COLLECTION: '/:slug/manage',
  CARD_LIST: '/:slug/manage/cards',
  CONFIGURE_PAGE: '/:slug/configure',
  CONFIGURE_PAGE_PATH: '/:slug/configure/:tab?',
  CONFIGURE_GENERAL: '/:slug/configure/general',
  CONFIGURE_COOLDOWN: '/:slug/configure/cooldown',
  CHRISTMAS: '/christmas',
  COOKIES_POLICY: '/cookies',
  COOKIES_POLICY_ES: '/cookies/es',
  COOKIES_POLICY_USA: '/cookies/usa',
  CREATE_PAGE: '/create-page',
  DASHBOARD: '/:slug/dashboard',
  DISENCHANT: '/:slug/manage/disenchant/:tab?',
  DISENCHANT_BASE: '/:slug/manage/disenchant',
  DISENCHANT_DEFAULT: '/:slug/manage/disenchant/default',
  DISENCHANT_CONFIGURATION: '/:slug/manage/disenchant/configuration',
  DISENCHANT_CUSTOMIZATION: '/:slug/manage/disenchant/customization',
  FAVICON: '/favicon.ico',
  HOME: '/',
  BRAND: '/streamloots-brand',
  MANAGE_COLLECTIONS: '/:slug/manage-collections',
  ORDERS: '/:slug/revenue/orders',
  PACKS_AND_PRICES: '/:slug/manage/packs-and-prices',
  PAYMENT_RESULT: '/:slug/payment/status/:status',
  PAYMENT_RETURN: '/:slug/payment',
  PAYMENTS: '/:slug/revenue/payments',
  POWERS: '/powers',
  PRIVACY_POLICY_EN: '/privacy',
  PRIVACY_POLICY_ES: '/privacy/es',
  PRIVACY_POLICY_USA: '/privacy/usa',
  PROMOTIONS_AND_RESOURCES: '/:slug/promotion-and-resources',
  RANKINGS: '/:slug/rankings',
  ACTIVITY_FEED: '/:slug/activity-feed',
  REVENUE_PATH: '/:slug/revenue/:tab?',
  REVENUE: '/:slug/revenue/',
  SIGN_IN: '/:slug/sign-in',
  SIGN_IN_ABSOLUTE: '/sign-in',
  SIGN_UP: '/sign-up',
  SIGN_OUT: '/sign-out',
  STATIC: '/p',
  STREAMERS: '/streamers',
  STRIPE_PAYMENT_RESULT: '/:slug/payment/status/success',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  TRANSACTIONS: '/:slug/revenue/transactions',
  UNAUTHORIZED: '/unauthorized',
  UPDATE_PROBABILITIES: '/:slug/manage/probabilities',
  USER_COLLECTIONS: '/collections',
  USER_GALLERY: '/:slug/gallery',
  USER_PAGE: '/:slug',
  USER_PAGE_COLLECTION: '/:slug/collection/:setId?',
  PAGE_COUPONS: '/:slug/coupons',
  REDIRECT_TO_PAGE_SECTION: '/redirect-to-section/:pageKey',
  COLLECTION_COUPON_REDEMPTION: '/:slug/collection/:setId?couponCode=:code',
  NOTIFICATIONS_IN_APP: '/notifications',
  MODERATION: '/:slug/moderation/',
  REFERRAL: '/:slug/referral',
  MY_PROFILE: '/profile',
  INTEGRATIONS_RESPONSE: '/profile/:provider',
  ADD_DEFAULT_CARDS: '/:slug/originals/add-default-cards',
  WEEKLY: '/:slug/report',
  REWIND_SOCIAL: '/rewind-2023',
  REWIND: '/rewind-2023/:type/:userId',
  VALIDATE_EMAIL: '/email-verifications/:verificationId',
  WELCOME_MIXER: '/welcome-again-mixer',
  ORIGINALS: '/:slug/originals',
  AUCTIONS: '/:slug/auctions',
  AUCTION: '/:slug/auction/:auctionId',
  CREATE_AUCTION: '/:slug/auctions/create',
  EDIT_AUCTION: '/:slug/auctions/edit/:auctionId',
  ALL_AUCTIONS: '/:slug/all-auctions',
  COMMUNITY_RANKING: '/:slug/dashboard/community-ranking',
  PAGE_SUBSCRIPTIONS_BASE_PATH: '/:slug/subscriptions/:tab?',
  PAGE_SUBSCRIPTIONS: '/:slug/subscriptions',
  PAGE_SUBSCRIPTIONS_SETTINGS: '/:slug/subscriptions/settings',
  PAGE_SUBSCRIPTIONS_REACTIONS: '/:slug/subscriptions/reactions',
  PAGE_SUBSCRIPTIONS_EMOTES: '/:slug/subscriptions/emotes',
  PAGE_SUBSCRIPTIONS_SUBSCRIBERS: '/:slug/subscriptions/subscribers',
  PAGE_SUBSCRIPTIONS_CONTENT: '/:slug/subscriptions/subs-content',
  PAGE_SUBSCRIPTIONS_GOALS: '/:slug/subscriptions/goals',
  USER_PAGE_SUBSCRIPTION: '/:slug/subscription',
  USER_PAGE_COLLECTIONS: '/:slug/collections',
  USER_PAGE_DONATIONS: '/:slug/donation',
  USER_PAGE_FEED: '/:slug/feed',
  USER_PAGE_FEED_DETAIL: '/:slug/feed/:feedId',
  RETRO_PAGE: '/retro-2022',
  TOP_STREAMERS: '/top-communities',
  ADMIN_REACTIONS: '/admin/reactions',
  ADMIN_EMOTES: '/admin/emotes',
  MARATHON: '/:slug/marathon',
  MARATHON_PATH: '/:slug/marathon/:tab?',
  MARATHON_CONFIGURATION: '/:slug/marathon/configuration',
  MARATHON_INTERACTIONS: '/:slug/marathon/interactions',
  ENABLE_CARDS: '/:slug/modules/cards',
  ENABLE_AUCTIONS: '/:slug/modules/auctions',
  ENABLE_SUBSCRIPTIONS: '/:slug/modules/subscriptions',
  ENABLE_MARATHONS: '/:slug/modules/marathons',
  ENABLE_DONATIONS: '/:slug/modules/donations',
  LANDING_CARDS: '/cards',
  LANDING_AUCTIONS: '/auctions',
  LANDING_SUBSCRIPTIONS: '/subscriptions',
  LANDING_MARATHONS: '/marathon-timer',
  LANDING_DONATIONS: '/donations',
  MY_VIEWERS_COMMUNITY: '/:slug/my-community',
  MY_VIEWERS_COMMUNITY_BASE_PATH: '/:slug/my-community/:tab?',
  MY_VIEWERS_COMMUNITY_EMAILS: '/:slug/my-community/emails',
  MY_VIEWERS_COMMUNITY_ALL: '/:slug/my-community/viewers',
  MY_VIEWERS_COMMUNITY_SUBSCRIBERS: '/:slug/my-community/subscribers',
  MODERATORS: '/:slug/my-community/mods',
  BANNED_USERS: '/:slug/my-community/bans',
  PUBLIC_ORIGINALS: '/originals/:frameId',
  PUBLIC_ORIGINALS_REDIRECT: '/originals/:frameId/redirect',
  MANAGE_COMMUNICATIONS: '/:slug/manage-communications',
  DONATIONS: '/:slug/donations',
  PAGE_DONATIONS_BASE_PATH: '/:slug/donations/:tab?',
  PAGE_DONATIONS: '/:slug/donations',
  PAGE_DONATIONS_SETTINGS: '/:slug/donations/settings',
  PAGE_DONATIONS_DONORS: '/:slug/donations/donors',
};

type URLS = Record<keyof typeof pages, string>;

let _urls = pages;

export const getUrlWithParams = (url: string, params: Record<string, string>) => {
  let urlWithParams = url;
  Object.keys(params).forEach(paramName => {
    const replacePattern = new RegExp(`:${paramName}\\??`, 'g');
    urlWithParams = urlWithParams.replace(replacePattern, params[paramName]);
  });
  return urlWithParams.replace(/\?/g, '');
};

export const updateUrls = (params: Record<string, string>) => {
  const newUrls: Record<keyof typeof pages, string> = {} as URLS;
  Object.keys(pages).forEach(page => {
    newUrls[page] = getUrlWithParams(pages[page], params);
  });
  _urls = newUrls;
};

export const getSlugFromUrl = (originalUrl: string): string | null => {
  const staticPages = Object.values(pages).filter(page => page.indexOf(':slug') === -1);
  const newUrl = originalUrl.split('?')[0];
  if (staticPages.indexOf(newUrl) > -1) {
    return null;
  }

  const match = matchPath<{ slug: string }>(newUrl, {
    path: pages.USER_PAGE,
  });

  return match?.params?.slug || null;
};

/**
 * DON'T USE THIS FUNCTION IN STATIC FILES. IT ONLY WORKS FINE INSIDE REACT COMPONENTS
 */
export const urls = () => _urls;

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'test') {
  try {
    updateUrls({
      slug: pageSelectors.slug((window as any).__INITIAL_STATE__),
    });
  } catch (e) {
    console.error('Unable to build urls');
  }
}

export default urls;
