import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from './actions';
import selectors from './selectors';

export interface UsePagePowerActions {
  fetchPowers: () => Promise<any>;
}

export const usePowerActions = (): UsePagePowerActions => {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      fetchPowers: () => dispatch(actions.getEffects()),
    }),
    [dispatch],
  );
};

export const usePowerSelectors = () => {
  const powers = useSelector(selectors.powers);
  const activePowers = useSelector(selectors.activePowers);

  return {
    powers,
    activePowers,
  };
};
