import { History } from 'history';
import { useTranslation } from 'utils/translation';
import type { RouteTabsMatchType } from 'components/route-tabs';
import { PageHeader } from 'ui-library/headers';
import { PanelSkeletons } from 'ui-library/panel';
import loadable from 'components/dynamic-import';
import { FullLayout } from 'scenes/layout';

const Disenchant = loadable(() => import('./Disenchant'), true, <PanelSkeletons />);

interface OwnProps {
  history: History;
  match: RouteTabsMatchType;
}

type DisenchantPageProps = OwnProps;

export const DisenchantPage = (props: DisenchantPageProps): JSX.Element => {
  const { t } = useTranslation('disenchant');

  return (
    <FullLayout>
      <PageHeader>{t('common:menu.disenchant')}</PageHeader>
      <Disenchant {...props} t={t} />
    </FullLayout>
  );
};
