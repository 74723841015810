import { PageActions, PageActionTypes } from 'services/page';
import { paginationStateNormalizer } from 'services/pagination';
import stateCreator from 'helpers/stateCreator';
import { SubscriptionActions } from 'services/subscriptions/types';
import { SubscriptionsActionTypes } from 'services/subscriptions';
import { FixMe } from 'types/indexTS';
import type { MyCreatorsListState, MyCreatorsListStateByComponentKey, MyCreatorsListActions } from './types';
import { PAGINATION_STATE_NORMALIZER_CONFIGURATION } from '../../constants';
import { MyCreatorsActionTypes } from '../../actionTypes';

type AcceptedActions = MyCreatorsListActions | PageActions | SubscriptionActions;

const getMyCreatorsListStateByComponentKey = (
  state: MyCreatorsListStateByComponentKey,
  action: AcceptedActions,
): MyCreatorsListStateByComponentKey => {
  switch (action.type) {
    case MyCreatorsActionTypes.FETCH_SUCCESS: {
      return {
        ...state,
        ...paginationStateNormalizer.getReceived(action.payload, PAGINATION_STATE_NORMALIZER_CONFIGURATION),
      };
    }
    case MyCreatorsActionTypes.FETCH_ERROR: {
      return paginationStateNormalizer.getReceivedError(action, state);
    }
    case MyCreatorsActionTypes.FETCH_REQUEST: {
      return paginationStateNormalizer.getRequest(state, action.payload, PAGINATION_STATE_NORMALIZER_CONFIGURATION);
    }
    case MyCreatorsActionTypes.FETCH_NEXT_REQUEST:
      return paginationStateNormalizer.getFetchingNextItems(state, action.payload.cancelToken);
    case MyCreatorsActionTypes.FETCH_NEXT_SUCCESS: {
      return paginationStateNormalizer.getNextItemsReceived(state, action.payload);
    }
    case MyCreatorsActionTypes.FETCH_NEXT_ERROR: {
      return paginationStateNormalizer.getNextReceivedError(state);
    }
    default:
      return state;
  }
};

export const reducer = (state: FixMe = {}, action: AcceptedActions): MyCreatorsListState => {
  switch (action.type) {
    case MyCreatorsActionTypes.FETCH_NEXT_ERROR:
    case MyCreatorsActionTypes.FETCH_NEXT_SUCCESS:
    case MyCreatorsActionTypes.FETCH_NEXT_REQUEST:
    case MyCreatorsActionTypes.FETCH_ERROR:
    case MyCreatorsActionTypes.FETCH_SUCCESS:
    case MyCreatorsActionTypes.FETCH_REQUEST: {
      const { componentKey } = action.metadata;
      return {
        reducerState: stateCreator.getUpdatedStateByKey(
          state.reducerState || {},
          action,
          componentKey,
          getMyCreatorsListStateByComponentKey,
        ),
        reconcile: false,
      };
    }
    case SubscriptionsActionTypes.CANCEL_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        reconcile: true,
      };
    }
    default:
      return state;
  }
};
