import { addMinutes, isAfter } from 'date-fns';
import type { UserSet, UserSetFromApi } from 'model/indexTS';
import { COLLECTION_IMAGES } from 'constants/collection-images';
import { setUtils } from 'services/sets';
import type { UserSetListStateProps } from './types';

const userSet = (userSetData: UserSetFromApi, index: number): UserSet => {
  const {
    _id,
    collectionId,
    default: defaultUserSet,
    paused,
    pausedReason,
    name,
    craftableCards,
    imageUrl,
    resumeAt,
    marathonDefault,
  } = userSetData;

  // Resume job is executed every 2 minutes. We ensure that the set is refreshed after this
  let newResumeAt = resumeAt ? addMinutes(resumeAt, 2).toISOString() : '';

  if (newResumeAt && isAfter(Date.now(), newResumeAt)) {
    newResumeAt = '';
  }

  return {
    _id,
    collectionId,
    default: defaultUserSet,
    paused,
    pausedReason,
    name,
    craftableCards: Boolean(craftableCards),
    resumeAt: newResumeAt,
    imageUrl: imageUrl || COLLECTION_IMAGES[index % 4],
    order: userSetData.order ?? index,
    marathonDefault,
  };
};

const userSets = (userSetData: Array<UserSetFromApi>): Array<UserSet> =>
  userSetData.map(userSet).sort(setUtils.byOrder);

const userSetListStateProps = (
  setsData: Array<UserSet>,
  updateSetId?: string,
  newSetInfo: Partial<UserSet> = {},
): UserSetListStateProps => {
  const userSetsById = {};

  const isNewSetDefault = newSetInfo.default;

  const newUserSets = setsData.map(setInfo => {
    if (updateSetId === setInfo._id) {
      userSetsById[setInfo._id] = { ...setInfo, ...newSetInfo };
    } else {
      userSetsById[setInfo._id] = {
        ...setInfo,
        default: isNewSetDefault ? false : setInfo.default,
      };
    }
    return userSetsById[setInfo._id];
  });

  return {
    userSets: newUserSets.sort(setUtils.byOrder),
    userSetsById,
  };
};

const getFilteredAndSortedUserSetsFromApi = (userSetsFromApi: UserSetFromApi[] = []): UserSetFromApi[] => {
  return userSetsFromApi.filter(userSetFromApi => !userSetFromApi.marathonDefault).sort(setUtils.byOrder);
};

export default {
  getFilteredAndSortedUserSetsFromApi,
  userSet,
  userSetListStateProps,
  userSets,
};
