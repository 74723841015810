import { AuthProviders } from '@streamloots/streamloots-types';
import ClassNames from '@streamloots/classnames';
import { useTranslation } from 'utils/translation';
import { AuthProviderButton } from 'components/provider-buttons';
import { authProviders } from './constants';
import theme from './auth.scss';

const classNames = ClassNames(theme);

interface AuthSectionProps {
  location: string;
  componentKey: string;
  redirectTo?: string;
  signUp?: boolean;
}

export const AuthSection = ({ componentKey, signUp, redirectTo, location }: AuthSectionProps): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <>
      {authProviders.map(({ provider, signUpI18nKey, i18nKey, icon }) => {
        return (
          <div
            key={`${provider}_${icon}`}
            className={classNames({
              'margin-bottom': true,
              'align-center': !signUp,
            })}
          >
            <AuthProviderButton
              className={classNames('auth__button')}
              redirectTo={redirectTo}
              label={signUp ? t(signUpI18nKey) : t(i18nKey)}
              provider={provider}
              componentKey={componentKey}
              location={location}
              icon={icon}
            />
          </div>
        );
      })}
    </>
  );
};
