import React from 'react';
import loadable from 'components/dynamic-import';
import { checkPermissions, permissions } from 'components/permissions';
import { FullLayout } from '../layout';

const ConnectedAdminTags = loadable(() => import('./ConnectedAdminTags'));

const AdminTagsPageInternal = () => (
  <FullLayout>
    <ConnectedAdminTags />
  </FullLayout>
);

export const AdminTagsPage = checkPermissions({
  requiredPermissions: [
    permissions.ACCOUNT_MANAGER,
    permissions.PARTNERSHIP_MANAGER,
    permissions.ADMIN,
    permissions.SUPPORT,
  ],
  redirectToBasePage: true,
})(AdminTagsPageInternal);
