import { FixMe } from 'types/indexTS';
import { typeWithoutSuffix } from 'utils/selectorUtils';
import { LoadingState, LOADING_STATES } from './types';

export function loadingStateByAction(state: FixMe, actionType: string): LoadingState | null {
  return (
    state.loadingState[typeWithoutSuffix(actionType)] ?? {
      state: LOADING_STATES.idle,
    }
  );
}

export default {
  loadingStateByAction,
};
