import produce from 'immer';
import { LOADING_STATES, LoadingState } from './types';

const loadingActionTypeRegexp = /(.*)_(REQUEST|SUCCESS|ERROR)$/;

const mappingActionsToStates = {
  REQUEST: LOADING_STATES.loading,
  SUCCESS: LOADING_STATES.success,
  ERROR: LOADING_STATES.error,
  DEFAULT: LOADING_STATES.idle,
};

const initialState = {};

const loadingReducer = produce((draft, action): Record<string, LoadingState> | void => {
  const { type, payload } = action;
  const matches = loadingActionTypeRegexp.exec(type);

  if (!matches) {
    return;
  }
  const [, requestName, requestState] = matches;

  draft[requestName] = { state: mappingActionsToStates[requestState] };
  if (requestState === 'ERROR') {
    draft[requestName].error = { message: payload.message };
  }
}, initialState);

export default loadingReducer;
