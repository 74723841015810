import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import type {
  SavedStripePaymentMethodsReceivedActionSuccess,
  SavedStripePaymentMethodsReceivedActionError,
} from './types';
import { SavedStripedMethodsListActionTypes } from './actionTypes';
import normalizer from './normalizer';

const savedStripePaymentMethodsReceived = ({ data }): SavedStripePaymentMethodsReceivedActionSuccess =>
  actionCreator.createAction(SavedStripedMethodsListActionTypes.SAVED_STRIPE_PAYMENT_METHODS_RECEIVED, {
    data: data.map(savedMethod => normalizer.createStripePaymentMethodFromSavedPaymentMethod(savedMethod)),
  });

const savedStripePaymentMethodsNotReceived = ({ errorData }): SavedStripePaymentMethodsReceivedActionError =>
  actionCreator.createErrorAction(SavedStripedMethodsListActionTypes.SAVED_STRIPE_PAYMENT_METHODS_RECEIVED, errorData);

const fetchSavedStripePaymentMethods = () =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.SAVED_STRIPE_PAYMENT_METHODS.GET,
    errorBinding: savedStripePaymentMethodsNotReceived,
    loadingAction: SavedStripedMethodsListActionTypes.FETCHING_SAVED_STRIPE_PAYMENT_METHODS,
    method: 'get',
    successBinding: savedStripePaymentMethodsReceived,
  });

export const savedStripedMethodsListActions = {
  fetchSavedStripePaymentMethods,
};
