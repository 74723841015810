import { FixMe } from 'types/indexTS';
import stateCreator from 'helpers/stateCreator';
import { AdminViewerState } from 'services/admin-viewer';
import actionTypes from './actionTypes';

const adminViewerState: AdminViewerState = {
  userInfo: null,
};

export default (state: AdminViewerState = adminViewerState, action: FixMe) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.GET_ADMIN_VIEWER_REQUEST: {
      return stateCreator.getAsyncLoadingState(state);
    }
    case actionTypes.GET_ADMIN_VIEWER_SUCCESS: {
      return stateCreator.getInitialAsyncState({
        ...state,
        userInfo: payload,
      });
    }

    case actionTypes.GET_ADMIN_VIEWER_ERROR: {
      return stateCreator.getAsyncErrorState(action, state);
    }

    default:
      return state;
  }
};
