import { PageActionTypes } from 'services/page';
import stateCreator from 'helpers/stateCreator';
import type { GoalsState, GoalActions, GoalStateByType } from './types';
import { GoalActionTypes } from './actionTypes';
import { getGoalsStateFromGoalsArray } from './normalizer';

export const getGoalsStateByType = (state: GoalStateByType = {}, action: GoalActions): GoalStateByType => {
  switch (action.type) {
    case GoalActionTypes.CREATE_ERROR:
      return stateCreator.getAsyncErrorState(action, state);
    case GoalActionTypes.CREATE_REQUEST:
      return stateCreator.getAsyncLoadingState(state);
    case GoalActionTypes.UPDATED_SUCCESS:
    case GoalActionTypes.CREATE_SUCCESS: {
      return stateCreator.getInitialAsyncState(state, {
        goal: action.payload,
      });
    }
    default:
      return state;
  }
};

export const reducer = (state: GoalsState = {}, action: GoalActions): GoalsState => {
  switch (action.type) {
    case GoalActionTypes.FETCH_REQUEST:
      return stateCreator.getAsyncLoadingState(state);
    case GoalActionTypes.FETCH_ERROR: {
      return stateCreator.getAsyncErrorState(action, state);
    }
    case GoalActionTypes.FETCH_SUCCESS: {
      return stateCreator.getInitialAsyncState(state, getGoalsStateFromGoalsArray(action.payload));
    }
    case GoalActionTypes.UPDATED_SUCCESS:
    case GoalActionTypes.CREATE_ERROR:
    case GoalActionTypes.CREATE_REQUEST:
    case GoalActionTypes.CREATE_SUCCESS: {
      const { type } = action.metadata;
      return {
        ...state,
        [type]: getGoalsStateByType(state[type], action),
      };
    }
    case PageActionTypes.CHANGED:
      return {};
    default:
      return state;
  }
};
