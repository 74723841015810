import * as React from 'react';
import mediaQueryBuilder, { MediaQueryListenerType } from 'helpers/mediaQueryBuilder';

/**
 * @param {string} media accepts media query parameter like (max-width:600px)
 *
 * It uses matchMedia API you can know more https://developer.mozilla.org/es/docs/Web/API/Window/matchMedia
 */

export const useMedia = (media: string): boolean => {
  const builder = mediaQueryBuilder(media);

  const initializeState = (mediaBuilder: MediaQueryListenerType) => {
    return mediaBuilder.match();
  };

  const [isInMedia, setIsInMedia] = React.useState(() => initializeState(builder));

  const matchMedia = React.useCallback(() => {
    const matches = builder.match();
    setIsInMedia(matches);
  }, [builder]);

  React.useEffect(() => {
    const listener = builder.addListener(matchMedia);

    return () => {
      builder.removeListener(listener);
    };
  }, [builder, matchMedia, media]);

  return isInMedia;
};
